import Box from '@weave-mui/box';
import React from 'react';
import { DESCRIPTION_INPUT_CHARACTERS_LIMIT, TABLE_VALUE_NUMBER_LIMIT, containsSpecialCharacters } from '../../../utils/dataPointsUtils';
import { InputType, InputTypeEnum } from '../../../types/input';
import { isLongerThanAllowed } from '../../../../analysis/EmbodiedCarbon/AddECDefinition/helper';
import { ErrorS } from '@weave-mui/icons-weave';
import { errorIconStyles, maximumCharactersErrorStyles } from '../../../../analysis/EmbodiedCarbon/AddECDefinition/ECDefinition.stylesheet';
import Typography, { typographyVariants } from '@weave-mui/typography';
import i18n from '../../../../i18n';
import { BENCHMARK_NAME_LIMIT } from '../../../utils/benchmarksUtils';

interface DataPointsInputErrorProps {
  value: string | number;
  type: InputType;
}

const isLongerThanAllowedCheck = (value: string | number, type: InputType): JSX.Element => {
  let charactersLimit = 0;
  switch (type) {
    case InputTypeEnum.text:
      charactersLimit = BENCHMARK_NAME_LIMIT;
      break;
    case InputTypeEnum.textArea:
      charactersLimit = DESCRIPTION_INPUT_CHARACTERS_LIMIT;
      break;
    case InputTypeEnum.number:
      charactersLimit = TABLE_VALUE_NUMBER_LIMIT-1;
      break;
  }
  if (isLongerThanAllowed(`${value}`, charactersLimit)) {
    return (
      <>
        <ErrorS data-testid='longerThanAllowed-error-icon' sx={{...errorIconStyles}}/>
        <Typography data-testid='longerThanAllowed-error-text' variant={typographyVariants.BODY_REGULAR} sx={{...maximumCharactersErrorStyles}}>
          {`${i18n.t('analysis.ec.ecDefinition.components.maximumCharactersErrorMessage')} ${
            `${value}`.length - charactersLimit
          }`}
        </Typography>
      </>
    )
  }
  return <></>;
}

const containsSpecialCharactersCheck = (value: string | number): JSX.Element => {
  if (containsSpecialCharacters(`${value}`)) {
    return (
      <Box sx={
        {
          display: 'flex',
          flexDirection: 'row'
        }
      }>
        <ErrorS data-testid='specialCharacters-error-icon' sx={{...errorIconStyles}}/>
        <Typography data-testid='specialCharacters-error-text' variant={typographyVariants.BODY_REGULAR} sx={{...maximumCharactersErrorStyles}}>
          {`${i18n.t('analysis.dataPoints.metrics.specialCharactersErrorMessage')}
          `}
        </Typography>
      </Box>
    )
  }
  return <></>
}

const DataPointsInputError: React.FC<DataPointsInputErrorProps> = ({ value, type }) => {
  return (
    <Box 
      sx={
        {
          display: 'flex',
          alignItems: 'center'
        }
      }
      data-testid='input-error-container'
    >
      { isLongerThanAllowedCheck(value, type) }
      { type === 'text' && containsSpecialCharactersCheck(value) }
    </Box>
  )
}

export default DataPointsInputError;
