import {fontFamily} from "./chartOptions";
import {defaultColor} from "../dataPoints/styles/colorCodes";

export const benchmarkLineColor: string = '#0696D7';
export const chartXValue = 1.5;

export const benchmarkSeriesStructure = {
  name: '',
  type: 'scatter',
  data: [],
  marker: {
    enabled: false,
  }
};

const getBenchmarkLabelStyle = (width: number) => `width: ${width}px; text-align: right; text-overflow: ellipsis; white-space: nowrap; overflow: hidden`;

export const putBenchmarksOnChart = (chart: any) => {
  let benchmarkSeries = chart.series.filter(s => s.type === benchmarkSeriesStructure.type);
  removeBenchmarksFromChart(chart);

  chart.plotlines = [];
  chart.chips = [];
  chart.textLabels = [];
  benchmarkSeries.forEach((series, index) => {
    series.points.forEach(point => {
      if (point.y !== null) {
        chart.plotlines.push(buildBenchmarkLine(chart, point, index).add());
        chart.textLabels.push(point.labelText = buildLabel(chart, point, index).add());
      }
    });

  });
}

const buildBenchmarkLine = (chart: any, point: any, index: number): any => {
  return chart.renderer.path(['M', chart.plotWidth + chart.plotLeft, point.plotY + chart.plotTop, 'L', chart.plotLeft, point.plotY + chart.plotTop])
    .attr({
      'stroke-dasharray': '3,3',
      'stroke-width': 1,
      stroke: benchmarkLineColor,
      zIndex: 5 + index
    });
}

const buildLabel = (chart, point, index: number) => {
  const width = chart.plotWidth - point.plotX;
  const labelText = point.series.name;
  const label = `<div style="${getBenchmarkLabelStyle(width)}">${labelText}</div>`;

  return point.labelText = chart.renderer.text(label, point.plotX + chart.plotLeft, point.plotY + chart.plotTop + 12, true)
    .attr({
      zIndex: 7 + index
    })
    .css({
      color: defaultColor,
      fontSize: '12px',
      fontWeight: 'normal',
      fontFamily: fontFamily,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: `${width}px`,
      'text-align': 'right'
    });
}


const removeBenchmarksFromChart = (chart) => {
  const drawObjects = ['textLabels', 'chips', 'plotlines'];
  drawObjects.forEach(object => {
    chart[object]?.forEach(o => o.destroy());
  });
}

export const scaleYAxis = (chart) => {
  let {dataMin, dataMax} = chart.yAxis[0].getExtremes();
  if (dataMin > 0) {
    dataMin = 0;
  }
  if (dataMax < 0) {
    dataMax = 0;
  }
  chart.yAxis[0].setExtremes(dataMin * 1.1, dataMax * 1.1);
}
