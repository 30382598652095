import React from 'react';
import '../../css/serviceUnavailable.css';
import i18n from "../../i18n";
import { typographyVariants } from '@weave-mui/enums';
import Typography from '@weave-mui/typography';
import Link, { linkVariants } from '@weave-mui/link';
import { MaintenanceSVG } from '../Error/SvgComponents';

export type MaintenanceInformationProps = {
  title: string,
  subtitle?: string,
  message: string,
  icon: JSX.Element,
  linkText?: string,
  linkUrl?: string
};

export type AppUnavailableProps = {
  message?: string;
  navButtonTitle?: string;
};

const MaintenanceInformation = ({
  title,
  subtitle,
  message,
  icon,
  linkText,
  linkUrl
}: MaintenanceInformationProps) => {
  return (
    <div style={{ 'display': 'flex', justifyContent: 'center', height: '100%' }}>
      <div style={{ width: '100%' }}>
        <div className={'serviceUnavailableContentWrapper'}>
          <Typography variant={typographyVariants.H1_BOLD}>
            {title}
          </Typography>
          {icon}
          <Typography variant={typographyVariants.H3_BOLD}>
            {subtitle}
          </Typography>
          <Typography align={'center'} sx={{ whiteSpace: 'pre-line' }}>
            {message}
          </Typography>
          <Typography fontWeight={'bold'} align={'center'} sx={{ marginTop: '2rem' }}>
            {linkText !== '' && (
              <Link
                href={linkUrl}
                variant={linkVariants.PRIMARY}
                target="_blank"
              >
                {linkText}
              </Link>
            )}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export const ApplicationUnderMaintenance = () => {
  return (
    <MaintenanceInformation
      title={i18n.t('applicationUnderMaintenance.informativeMessage.title') }
      subtitle={i18n.t('applicationUnderMaintenance.informativeMessage.subtitle')}
      message={i18n.t('applicationUnderMaintenance.informativeMessage.message')}
      icon={<MaintenanceSVG />}
      linkText={i18n.t('applicationUnderMaintenance.informativeMessage.linkText')}
      linkUrl={i18n.t('applicationUnderMaintenance.informativeMessage.linkUrl')}
    />
  );
};

