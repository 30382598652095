import React, { useEffect, useCallback } from "react";
import NotificationsToast from '@weave-mui/notification-toast';
import { NotificationsToastList } from '@hig/notifications-toast';
import { useDispatch, useSelector } from "react-redux";
import { deleteNotification } from "./actions";
import { convertObjectToarray } from "../helpers/jsHelper";

const notificationStyleSheet = (styles) => {
    return {
        ...styles,
        toastListWrapper: {
            ...styles.toastListWrapper,
            zIndex: 99999
        }
    }
};

export enum NotificationType {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export const HigNotificationToastWrapper = () =>{
    const notificationsObj = useSelector( (state: {notificationState: {notifications: {}}}) =>
        state.notificationState?.notifications ?? {}
    )
    const notificationArr = convertObjectToarray(notificationsObj);

    const dispatch = useDispatch();
    const deleteNotificationItem = useCallback((key: number) => dispatch(deleteNotification(key)), [dispatch]);

    useEffect(() => {
        let timeout;
        if (notificationArr.length > 0) {
            const notificationToRemove = notificationArr[0];
            timeout = setTimeout(() => {
                deleteNotificationItem(notificationToRemove.key);
            }, notificationArr[0].duration || 3500);
        }

        return () => {
           clearTimeout(timeout)
        }

    },[notificationArr, deleteNotificationItem]);

    return (
        <>
            {notificationArr.length > 0 &&
                <NotificationsToastList placement="top" stylesheet={notificationStyleSheet}>
                    {
                        notificationArr.map((item) =>
                            <NotificationsToast onClose={() => deleteNotificationItem(item.key)} severity={item.type === NotificationType.PRIMARY ? 'info' : item.type} key={item.key}>
                                <strong>
                                    {item.message}
                                </strong>
                            </NotificationsToast>)
                    }
                </NotificationsToastList>
            }
        </>
    );
};
