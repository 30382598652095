import React from 'react';
import Box from '@weave-mui/box';
import {
  layoutEmptyStateStylesWithEditMode,
  layoutEmptyStateContentStyles,
} from './LayoutGrid.stylesheet';
import Typography, { typographyVariants } from '@weave-mui/typography';
import i18n from '../i18n';
import { illustrationVariants } from '@weave-mui/enums';
import { Illustration } from '@weave-mui/material';
import { useCardGrid } from '@adsk/wildcard';

export const LayoutEmptyState = () => {
  const {
    state: {
      allLayouts: { desktop: cards },
    },
  } = useCardGrid();

  return (
    <>
      {cards.length === 0 && (
        <Box className="layout-empty-state" sx={layoutEmptyStateStylesWithEditMode}>
          <Box sx={layoutEmptyStateContentStyles}>
            <Illustration variant={illustrationVariants.NO_DATA} />
            <Typography
              variant={typographyVariants.BODY_MEDIUM}
              sx={{ marginTop: '62px' }}
              fontSize={20}
            >
              {i18n.t('dashboard.emptyState')}
            </Typography>
            <Typography
              variant={typographyVariants.BODY_REGULAR}
              sx={{ marginTop: '12px' }}
              fontSize={15}
            >
              {i18n.t('dashboard.emptyStateCaption')}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};
