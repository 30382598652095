import React, { useState } from 'react';
import Box from '@weave-mui/box';
import Button from '@weave-mui/button';
import Typography from '@weave-mui/typography';
import { ReportData, ReportSubmissionStatus } from '../types/reporting';
import i18n from '../../i18n';
import { CardDataLoadingProgress } from '../../layout/cards/base/CardDataLoadingProgress';
import { DataSection } from './DataSection';
import { FormSection } from './FormSection';
import { useComputed, useSignals } from '@preact/signals-react/runtime';
import { Signal } from '@preact/signals-react';
import { ReportProgress } from './ReportProgress';
import Divider, { dividerOrientations, dividerVariants } from '@weave-mui/divider';
import { useGetUserDataQueryState } from '../../state/api/user-data-api';
import { Link, linkVariants } from '@weave-mui/link';

export const ReportDataComponent: React.FC<{
  reportData: ReportData<any>;
  dataIsLoading: Signal<boolean>;
  reportSubmitted: Signal<boolean>;
}> = ({ reportData, dataIsLoading, reportSubmitted }) => {
  useSignals();
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const {
    data: { componentHelpBaseUrl: helpBaseUrl },
  } = useGetUserDataQueryState(); // help base url
  const SubmissionComponent = reportData.submissionComponent;
  const hasAllRequiredData = useComputed(() =>
    reportData.sections
      .map((x) => x.fields)
      .flat()
      .filter((x) => x.isRequired.value)
      .every((x) => !!x.data.value),
  );

  const submitReport = () => {
    if (!hasAllRequiredData.value) {
      // focus first empty field
      const errorFields = reportData.sections
        .map((x) => x.fields)
        .flat()
        .filter((x) => !x.data.value && x.type === 'text');

      errorFields.forEach((x) => {
        if (x.hasError) x.hasError.value = true;
      });

      errorFields[0]?.ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      errorFields[0]?.ref?.current?.getElementsByTagName('input')[0]?.focus();
      return;
    }

    setReadyToSubmit(true);
  };

  const renderReportSections = () => {
    return reportData.sections.map((section) => {
      switch (section.type) {
        case 'form':
          return <FormSection section={section} />;
        case 'list':
          return (
            <DataSection
              isExpanded={true}
              section={section}
              reportSubmissionUnits={reportData.submissionUnits}
            />
          );
        default:
          return null;
      }
    });
  };

  return (
    (dataIsLoading.value && (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <CardDataLoadingProgress />
      </Box>
    )) || (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            height: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            overflow: 'scroll',
          }}
        >
          {renderReportSections()}
        </Box>
        <Divider orientation={dividerOrientations.HORIZONTAL} variant={dividerVariants.FULLWIDTH} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '56px',
            padding: '16px',
            alignItems: 'center',
          }}
        >
          <Link
            href={`${helpBaseUrl}SUSTAINABILITY_INITIATIVES`}
            target="BLANK"
            variant={linkVariants.PRIMARY}
          >
            {i18n.t('reporting.links.help')}
          </Link>
          <Button
            onClick={submitReport}
            // disabled={!hasAllRequiredData.value || readyToSubmit}
            sx={{ margin: '0px !important' }}
          >
            <Typography sx={{ color: '#FFFFFF', fontweight: '600' }} variant="body1Medium">
              {i18n.t('reporting.buttons.report')}
            </Typography>
          </Button>

          {(readyToSubmit && (
            <>
              <ReportProgress
                display={readyToSubmit}
                title={reportData.heading}
                info={i18n.t('reporting.dialog.reportSubmission')}
              />
              <SubmissionComponent
                reportData={reportData}
                submit={readyToSubmit}
                complete={(status: ReportSubmissionStatus) => {
                  setReadyToSubmit(false);
                  if (status.success) {
                    reportSubmitted.value = true;
                  }
                }}
              />
            </>
          )) || <></>}
        </Box>
      </>
    )
  );
};
