import React from 'react';
import '../css/metrics.css'

interface EllipsisContentProps {
  content: string;
}

const EllipsisContent: React.FC<EllipsisContentProps> = ({ content }) => {
  return (
    <span className='ellipsisContent'>
      {content}
    </span>
  )
}

export default EllipsisContent;
