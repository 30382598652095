import {CLOSE_DIALOG, SHOW_DIALOG} from "./actionTypes";

export const showDialog = () => {
    return {
        type: SHOW_DIALOG
    }
}

export const closeDialog = () => {
    return {
        type: CLOSE_DIALOG
    }
}
