import * as consts from '../consts';
import {combineReducers} from 'redux';
import i18n from '../i18n';
import * as actionTypes from './actionTypes';
import * as helper from './helper';
import * as conv from '../conversions';
import {getConvertedResult} from '../run/reducer';

const dashboardReducer = (state = {}, action) => {
    const newState = {};

    switch (action.type) {
        case actionTypes.INIT_DASHBOARD_SUCCESS:
            return {
                ...state,
                [action.model.id]: dashboard(state[action.model.id], action)
            };        
        case actionTypes.DASHBOARD_RANGE_CHANGED:
        case actionTypes.SORT_DASHBOARD:
        case actionTypes.UPDATE_DASHBOARD_FACTORS:
        case actionTypes.UPDATE_DASHBOARD_RESULTS:
        case actionTypes.UPDATE_DASHBOARD_LMV:
            if(state[action.modelId]) {
                return {
                    ...state,
                    [action.modelId]: dashboard(state[action.modelId], action)
                };
            }
        case actionTypes.UPDATE_DASHBOARD_METRIC:
        case actionTypes.UPDATE_DASHBOARD_SETTINGS:

            for (const [key, value] of Object.entries(state)) {
                if ((value as any).projectId === action.projectId) {
                    const newStateModelId = dashboard(value, action);
                    newState[(value as any).modelId] = newStateModelId;
                } else {
                    newState[(value as any).modelId] = value;
                }
            }
            return newState;        
        case actionTypes.UPDATED_PROJECT_MODELS_ON_DASHBOARD:

            for (const [key, value] of Object.entries(state)) {
                if (action.models.some((x) => x.id === (value as any).modelId)) {
                    delete newState[(value as any).modelId];
                } else {
                    newState[(value as any).modelId] = value;
                }
            }
            return newState;
        case actionTypes.UPDATED_PROJECT_NAME_ON_DASHBOARD:

            for (const [key, value] of Object.entries(state)) {
                if ((value as any).projectId === action.project.id) {
                    const newState = dashboard(value, action);
                    newState[(value as any).modelId] = newState;
                } else {
                    newState[(value as any).modelId] = value;
                }
            }
            return newState;
        default:
            return state;
    }
}

const isFetchingReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.INIT_DASHBOARD_STARTED:
            return {
                ...state,
                [action.modelId]: true
            };

        case actionTypes.INIT_DASHBOARD_SUCCESS:
            return {
                ...state,
                [action.model.id]: false
            };

        default:
            return state;
    }
}

export const getIsFetchingDashboard = (state, modelId) => {
    return state.isFetching[modelId];
}
export const getModelDashboard = (state, modelId) => {
    return state.modelById[modelId];
}

export default combineReducers({
    modelById: dashboardReducer,
    isFetching: isFetchingReducer
});

const dashboard = (state, action) => {
    switch (action.type) {
        case actionTypes.INIT_DASHBOARD_SUCCESS:
            let initialDashboard = getInitialDashboard(action.project, action.model, action.runs);

            if (action.results && action.results.length > 0) {
                initialDashboard = updateDashboard(initialDashboard, action.results);
            }

            return initialDashboard;
        case actionTypes.DASHBOARD_RANGE_CHANGED:
            return updateDashboardRange(state, action.widgetKey, action.left, action.right);
        case actionTypes.SORT_DASHBOARD:
            return {
                ...state,
                widgets: [...sortWidgets(state.widgets, action.sortBy)],
                sortBy: action.sortBy
            };
        case actionTypes.UPDATE_DASHBOARD_FACTORS:
            return updateDashboardFactors(state, action.factors, action.runs);
        case actionTypes.UPDATE_DASHBOARD_RESULTS:
            return updateDashboard(state, action.results);
        case actionTypes.UPDATE_DASHBOARD_METRIC:
            return updateDashboardMetric(state, action.metric);
        case actionTypes.UPDATE_DASHBOARD_LMV:
            return {
                ...state,
                urn: action.urn,
                lmvStatus: action.lmvStatus
            };
        case actionTypes.UPDATE_DASHBOARD_SETTINGS:
            return updateDashboardSettings(state, action.useSiUnits, action.currencyIso, action.currencyRate, action.fuelRate, action.electricityRate);
        case actionTypes.UPDATED_PROJECT_NAME_ON_DASHBOARD:
            return updateDashboarProjectName(state, action.project.name);

        default:
            return state;
    }
}

const getInitialDashboard = (project, model, runs) => {

    const dashboard = {
        modelId: model.id,
        projectId: model.projectId,
        projectTitle: project.name,
        lmvStatus: model.lmvStatus,
        locality: model.locality,
        latitude: model.latitude,
        longitude: model.longitude,
        type: model.type,
        baseRunStatus: 'Processing',
        useSI: project.useSIUnits,
        urn: model.urn,
        widgets: [],
        selectedPointGroup: actionTypes.DASHBOARD_COST_METRIC,
        sortBy: 'Impact',
        showEcr: false,
        //this rates are always expected in kWh and USD
        //NOTE: other currencies will be handled with a conversionRate field so this will always be in USD.
        //default ElectRate: $0.1/kWh
        elecRate: project.electricityRate ?? 0.1,
        //default Gas: $0.027/kWh
        fuelRate: project.fuelRate ?? 0.0273,
        isUncategorizeProject: project.isUncategorized,
        currencyIso: project.currencyIso,
        currencyRate: project.currencyRate,
        //maps a combination group to a list of simulation ids that we are still waiting to be completed.
        pendingSimulations: {},
        //maps a combination group to a list of simulation results already loaded (api result 'Completed' or 'Error').
        simulations: {},
        //maps a simulation id to another map that contains the factors parameters values for that simulation.
        //eg for HVAC simulation, mapped value looks like {HvacSystem: 'PTAC'}
        //eg for WWR and Shading combination, mapped value looks like {Wwr: 0.15, OhProjectionFactor: 0.33}
        parameters: {},
        //maps a combination group to another map that contains a 'key' for each available metric type (energy or cost),
        //and the value for that key in the map is the list of runs (with simulated and predicted values) for that combination group.
        //This is needed for the complete ECR calculation logic.
        runs: {},
        factors: model.factors
    };

    runs.forEach((x) => dashboard.parameters[x.runId] = x.parameters);

    model.factors.forEach((factor) => {

        const { name, combinationGroup } = factor;

        if (!dashboard.pendingSimulations[combinationGroup]) {
            dashboard.pendingSimulations[combinationGroup] = runs?.filter((x) => x.tag === combinationGroup).map((x) => x.runId);
            dashboard.simulations[combinationGroup] = [];
        }

        if (name !== consts.BASE &&
            name !== consts.INTERNAL_LOAD_MIN_MAX &&
            name !== consts.FORM_MIN_MAX &&
            name !== consts.ENVELOPE_CALCULATION_MIN_MAX) {

            dashboard.widgets.push(getInitialWidget(factor, dashboard.useSI, dashboard.selectedPointGroup, dashboard.currencyIso));
        }
    });

    sortWidgets(dashboard.widgets, dashboard.sortBy);

    return dashboard;
}

const getInitialWidget = (factor, useMetricSystem, selectedPointGroup, currency) => ({
    key: factor.name,
    type: getWidgetType(factor.name),
    title: getWidgetTitle(factor.name),
    xTitle: getWidgetXTitle(factor.name, useMetricSystem),
    yTitle: getWidgetYTitle(useMetricSystem, selectedPointGroup, currency),
    status: 'Loading',
    rangeEnabled: false,
    pointGroups: {},
    combinationGroup: factor.combinationGroup,
    categoryOrder: factor.order ? factor.order : 0 //Code Smell: is 0 required
});

const updateDashboard = (current, results) => {
    const groupByCombination = results.reduce((acc, value) => {
        (acc[value.tag] = acc[value.tag] || []).push(value);
        return acc;
    }, {});


    if (groupByCombination[consts.BASE]) {
        current.baseRun = groupByCombination[consts.BASE][0];
        current.baseRunStatus = groupByCombination[consts.BASE][0].status;
    }

    const updatedRuns = {

    };
    const updatedWidgets = [];

    for (let [combinationGroup, value] of Object.entries(groupByCombination)) {
        const resultIds = (value as any).map((x) => x.runId);
        current.pendingSimulations[combinationGroup] = current.pendingSimulations[combinationGroup]?.filter((id) => !resultIds.includes(id));
        (value as any).forEach((x) => current.simulations[combinationGroup]?.push(x));

        if (current.pendingSimulations[combinationGroup]?.length === 0 &&
            combinationGroup !== consts.BASE &&
            combinationGroup !== consts.INTERNAL_LOAD_MIN_MAX &&
            combinationGroup !== consts.FORM_MIN_MAX &&
            combinationGroup !== consts.ENVELOPE_CALCULATION_MIN_MAX) {

            let combinationGroupWidgets = current.widgets?.filter((x) => x.combinationGroup === combinationGroup);

            const { widgets, runs } = loadCombinationGroupWidgets(current, combinationGroupWidgets, combinationGroup);
            widgets?.forEach(w => updatedWidgets?.push(w));
            updatedRuns[combinationGroup] = runs;
        }
    }

    const widgets = [
        ...current.widgets?.filter(w => !updatedWidgets?.some(uw => uw.key === w.key)),
        ...updatedWidgets
    ];

    const newDashboard = {
        ...current,
        runs: {
            ...current?.runs,
            ...updatedRuns
        },
        widgets: sortWidgets(widgets, current.sortBy)
    };

    // Requires updating meter limits: true
    updateEcr(newDashboard, true);
    updateLimits(newDashboard, current?.selectedPointGroup);

    return newDashboard;
}

const updateDashboardSettings = (current, useMetricSystem, currency, currencyRate, fuelRate, elecRate) => {
    //Rates received here must be always in kwh.
    const dashboard = {
        ...current,
        useSI: useMetricSystem,
        currencyIso: currency,
        elecRate,
        fuelRate,
        currencyRate
    };

    const widgetsByCombinationGroup = dashboard.widgets.reduce((acc, widget) => {
        (acc[widget.combinationGroup] = acc[widget.combinationGroup] || []).push(widget);
        return acc;
    }, {});

    const widgets = [];
    const runs = {};
    for (let [combinationGroup, combinationGroupWidgets] of Object.entries(widgetsByCombinationGroup)) {
        const { widgets: updatedWidgets, runs: updatedRuns } = loadCombinationGroupWidgets(dashboard, combinationGroupWidgets, combinationGroup);
        updatedWidgets.forEach(w => widgets.push(w));
        runs[combinationGroup] = updatedRuns;
    }

    dashboard.widgets = sortWidgets(widgets, dashboard.sortBy);
    dashboard.runs = runs;

    // Requires updating meter limits: true
    updateEcr(dashboard, true);
    updateLimits(dashboard, dashboard.selectedPointGroup);

    return dashboard;
}

const updateDashboarProjectName = (current, projectTitle) => {
    const dashboard = {
        ...current,
        projectTitle
    };

    return dashboard;
}

const updateDashboardFactors = (dashboard, factors, runs) => {
    const newWidgets = [];
    const updatedFactors = [
        ...dashboard.factors,
        ...factors
    ];

    runs.forEach((x) => dashboard.parameters[x.runId] = x.parameters);

    const updatedMap = {}
    //Initialize a widget for each new factor
    factors.forEach((factor) => {
        const { name, combinationGroup } = factor;

        if (!updatedMap[combinationGroup]) {
            //mark the combination group as updated, we only need to update dashboard pending simulations once
            //for combination group.
            updatedMap[combinationGroup] = true;
            dashboard.pendingSimulations[combinationGroup] = runs?.filter((x) => x.tag === combinationGroup).map((x) => x.runId);
            dashboard.simulations[combinationGroup] = [];
        }

        if (name !== consts.BASE &&
            name !== consts.INTERNAL_LOAD_MIN_MAX &&
            name !== consts.FORM_MIN_MAX &&
            name !== consts.ENVELOPE_CALCULATION_MIN_MAX) {

            newWidgets.push(getInitialWidget(factor, dashboard.useSI, dashboard.selectedPointGroup, dashboard.currencyIso));
        }
    });

    //Update existing widgets that belong to the same combination group
    //of the newly added factor. We need to wait for the simulations to finish
    //so all widgets of the same combination group are set with initial 'Loading' status.
    const updatedWidgets = [];
    const factorsByCombination = factors.reduce((acc, value) => {
        (acc[value.combinationGroup] = acc[value.combinationGroup] || []).push(value);
        return acc;
    }, {});
    for (const [combinationGroup, factors] of Object.entries(factorsByCombination)) {
        //Only widgets that were present previous to the update are considered.
        const widgets = dashboard.widgets.filter((w) =>
            w.combinationGroup === combinationGroup &&
            !(factors as any).some((f) => f.name === w.key)
        );

        for (const widget of widgets) {
            const factor = dashboard.factors.find((f) => f.name === widget.key);
            updatedWidgets.push(getInitialWidget(factor, dashboard.useSI, dashboard.selectedPointGroup, dashboard.currencyIso));
        }
    }

    const widgets = [
        ...newWidgets,
        ...dashboard.widgets.filter(w => !updatedWidgets.some(uw => uw.key === w.key)),
        ...updatedWidgets
    ];

    const newDashboard = {
        ...dashboard,
        factors: updatedFactors,
        widgets: sortWidgets(widgets, dashboard.sortBy)
    };

    return newDashboard;
}

export const updateDashboardRange = (dashboard, widgetKey, left, right) => {
    const widgets = [];

    for (const widget of dashboard.widgets) {
        if (widget.key !== widgetKey) {
            widgets.push(widget);
        } else {
            widgets.push(updateWidgetSelectedRange({ ...widget }, left, right));
        }
    }

    updateGlazingRelatedFactors(dashboard, widgetKey, widgets);

    let updatedDashboard = {
        ...dashboard,
        widgets
    };

    // Requires updating meter limits: false
    updateEcr(updatedDashboard, false);
    updateLimits(updatedDashboard, updatedDashboard.selectedPointGroup);

    return updatedDashboard;
}

const updateGlazingRelatedFactors = (dashboard, widgetKey, widgets) => {

    // Get current widget from dashboard
    const currentWidget = widgets.filter((x) => x.key === widgetKey)[0];


    // If the current widget is a Glazing related factor, the new values are generated for the points of the Y axis of the dependent charts.
    if (currentWidget.combinationGroup === consts.GLAZING_COMBINATION_GROUP_WEST || currentWidget.combinationGroup === consts.GLAZING_COMBINATION_GROUP_NORTH ||
        currentWidget.combinationGroup === consts.GLAZING_COMBINATION_GROUP_EAST || currentWidget.combinationGroup === consts.GLAZING_COMBINATION_GROUP_SOUTH) {

        const runsInRange = {};
        const outOfRange = {};
        const onlyBaseSelected = [];        

        // Filter only the 3 widgets in the group
        widgets.filter(w => w.status === 'Loaded' && !w.hasErrors && w.combinationGroup === currentWidget.combinationGroup).forEach(w => {
            const points = w.pointGroups[dashboard.selectedPointGroup];
           
            runsInRange[w.key] = Object.assign({}, dashboard.runs[w.combinationGroup]);
            outOfRange[w.key] = points.filter(p => !p.inRange).map(p => p.key)

            // Get points in range to validate to validate if the base sumulation is the only one selected.
            const pointstInRange = points.filter(p => p.inRange);
            const x = { "widget": w.key, "baseSelected": false }

            if (pointstInRange.length === 1) {
                x.baseSelected = pointstInRange[0].key === consts.BASE;
            }

            onlyBaseSelected.push(x);
        });

        // Loop - Out of range points object by widget
        Object.keys(outOfRange).forEach(function (key) {

            if (outOfRange[key].length > 0) {

                // Loop - Runs Object by widget
                Object.keys(runsInRange).forEach(function (keyRuns) {

                    // Update Runs of widgets other than the current one
                    if (keyRuns !== key) {

                        outOfRange[key].map((value) => {
                            let splitValue = getParameterAndValueSim(dashboard.baseRun, key, value);                               

                            // Runs out of range are removed
                            if (splitValue.length === 2) {
                                runsInRange[keyRuns][actionTypes.DASHBOARD_ENERGY_METRIC] = runsInRange[keyRuns][actionTypes.DASHBOARD_ENERGY_METRIC].filter(s => s.parameter[splitValue[0]] != splitValue[1]);
                                runsInRange[keyRuns][actionTypes.DASHBOARD_COST_METRIC] = runsInRange[keyRuns][actionTypes.DASHBOARD_COST_METRIC].filter(s => s.parameter[splitValue[0]] != splitValue[1]);
                            }
                        })
                    }
                })
            }
        })

        // Get Y-axis base values
        const energyBaseRunY = resultConversions[actionTypes.DASHBOARD_ENERGY_METRIC](dashboard.baseRun, dashboard.elecRate, dashboard.fuelRate, dashboard.currencyRate);
        const costBaseRunY = resultConversions[actionTypes.DASHBOARD_COST_METRIC](dashboard.baseRun, dashboard.elecRate, dashboard.fuelRate, dashboard.currencyRate);

        widgets.forEach(widget => {
            if (widget.key !== currentWidget.key && widget.combinationGroup === currentWidget.combinationGroup) {

                //Get the number of widgets by combination group to define how many factors will affect the behavior of base simulation (MyModel).
                const numWidgetsByCombination = widgets.filter(w => w.status === 'Loaded' && !w.hasErrors && w.combinationGroup === currentWidget.combinationGroup).length - 1;

                //If the other factors of the group have only the base selected, the value of the base simulation should not be predicted
                const needToPredictBaseRun = onlyBaseSelected.filter(w => w.widget !== widget.key && w.baseSelected).length !== numWidgetsByCombination;

                const energyRuns = runsInRange[widget.key][actionTypes.DASHBOARD_ENERGY_METRIC];
                const costRuns = runsInRange[widget.key][actionTypes.DASHBOARD_COST_METRIC];
                const baseRunX = widget.type === 'continuous' ? getContinuousFactorInputBaseRun(widget.key, dashboard.baseRun) : consts.BASE;

                // Get values to display
                const energyDisplayValues = helper.getDisplayValues(energyRuns, baseRunX, energyBaseRunY, widget.key, dashboard.baseRun, needToPredictBaseRun);
                const costDisplayValues = helper.getDisplayValues(costRuns, baseRunX, costBaseRunY, widget.key, dashboard.baseRun, needToPredictBaseRun);

                const newPoints = {
                    "DASHBOARD_ENERGY_METRIC": getPoints(energyDisplayValues, widget.key, widget.type, actionTypes.DASHBOARD_ENERGY_METRIC, dashboard.useSI, null),
                    "DASHBOARD_COST_METRIC": getPoints(costDisplayValues, widget.key, widget.type, actionTypes.DASHBOARD_COST_METRIC, dashboard.useSI, dashboard.currencyIso)
                }

                // Update the Y-axis values of the current widgets according to the values generated in the new widgets.
                Object.keys(widget.pointGroups).forEach(function (key) {
                    widget.pointGroups[key].map((x) => {
                        newPoints[key].map((y) => {
                            if (x.key === y.key) {
                                x.tooltipValue = y.tooltipValue;
                                x.y = y.y;
                            }
                        });
                    });
                });

                // Send true in parameter changeSettings to update the range selector according to the selected points
                updateWidgetMinMax(widget, dashboard.selectedPointGroup, true);
            }
        });
    }
}

const updateDashboardMetric = (model, metric) => {

    const newDashboard = {
        ...model,
        selectedPointGroup: metric,
        widgets: model.widgets.map(w => ({
            ...w,
            yTitle: getWidgetYTitle(model.useSI, metric, model.currencyIso)
        }))
    };

    updateLimits(newDashboard, newDashboard.selectedPointGroup);

    return newDashboard;
}

const updateLimits = (model, pointGroup) => {
    const allPoints = model.widgets.reduce((acc, widget) => {

        if (widget.status == 'Loaded' && !widget.hasErrors) {
            for (const p of widget.pointGroups[pointGroup]) {
                acc.push(p);
            }
        }

        return acc;
    }, []);

    if (allPoints.length > 0) {
        model.limitMinY = Math.min(...allPoints.map(p => p.y));
        model.limitMaxY = Math.max(...allPoints.map(p => p.y));
    }

    return model;
}

const loadCombinationGroupWidgets = (dashboard, widgets, combinationGroup) => {
    let newWidgets = [];
    let runs = {};

    //Any simulation error causes all widgets in the combination group to display empty or error.
    if (dashboard.simulations[combinationGroup].some((x) => x.status === 'Error')) {
        widgets.forEach((x) => {
            newWidgets.push({
                ...x,
                hasErrors: true,
                status: 'Loaded'
            });
        });
    } else {
        const factors = widgets.map(x => x.key);
        runs = getRuns(dashboard, combinationGroup, factors);

        const energyRuns = runs[actionTypes.DASHBOARD_ENERGY_METRIC];
        const costRuns = runs[actionTypes.DASHBOARD_COST_METRIC];
        const energyBaseRunY = resultConversions[actionTypes.DASHBOARD_ENERGY_METRIC](dashboard.baseRun, dashboard.elecRate, dashboard.fuelRate, dashboard.currencyRate);
        const costBaseRunY = resultConversions[actionTypes.DASHBOARD_COST_METRIC](dashboard.baseRun, dashboard.elecRate, dashboard.fuelRate, dashboard.currencyRate);

        widgets.forEach((widget) => {

            const baseRunX = widget.type === 'continuous' ? getContinuousFactorInputBaseRun(widget.key, dashboard.baseRun) : consts.BASE;

            const energyDisplayValues = helper.getDisplayValues(energyRuns, baseRunX, energyBaseRunY, widget.key, dashboard.baseRun, true);     //needToPredictBaseRun : true
            const costDisplayValues = helper.getDisplayValues(costRuns, baseRunX, costBaseRunY, widget.key, dashboard.baseRun, true);           //needToPredictBaseRun: true

            const energyPoints = getPoints(energyDisplayValues, widget.key, widget.type, actionTypes.DASHBOARD_ENERGY_METRIC, dashboard.useSI, null);
            const costPoints = getPoints(costDisplayValues, widget.key, widget.type, actionTypes.DASHBOARD_COST_METRIC, dashboard.useSI, dashboard.currencyIso);
            const yTitle = getWidgetYTitle(dashboard.useSI, dashboard.selectedPointGroup, dashboard.currencyIso);
            const xTitle = getWidgetXTitle(widget.key, dashboard.useSI);

            const pointGroups = {
                [actionTypes.DASHBOARD_ENERGY_METRIC]: energyPoints,
                [actionTypes.DASHBOARD_COST_METRIC]: costPoints
            };

            const updatedWidget = {
                ...widget,
                pointGroups,
                hasErrors: false,
                status: getWidgetStatus(widget.key, energyDisplayValues, costDisplayValues),
                rangeEnabled: true,
                yTitle,
                xTitle
            };

            updateWidgetMinMax(updatedWidget, dashboard.selectedPointGroup);

            if (!updatedWidget.rangeLeft || !updatedWidget.rangeRight) {
                updateWidgetAllRangeSelected(updatedWidget);
            } else {
                updateWidgetSelectedRange(updatedWidget, updatedWidget.rangeLeft, updatedWidget.rangeRight)
            }

            newWidgets.push(updatedWidget);
        });
    }

    return {
        widgets: newWidgets,
        runs
    };
}

const getWidgetStatus = (factor, energyDisplayValues, costDisplayValues) => {

    const expectedRunLength = helper.getParametersForFactor(factor).length + 1; //+ baserun
    const statuses = {
        loading: 'Loading',
        loaded: 'Loaded'
    };

    if (energyDisplayValues.length < expectedRunLength || costDisplayValues.length < expectedRunLength) {
        return statuses.loading;
    } else if (energyDisplayValues.length === expectedRunLength && costDisplayValues.length === expectedRunLength) {
        return statuses.loaded;
    }

}

const getRuns = (dashboard, combinationGroup, factors) => {
    const runs = {};

    for (const pointGroup of [actionTypes.DASHBOARD_ENERGY_METRIC, actionTypes.DASHBOARD_COST_METRIC]) {
        runs[pointGroup] = dashboard.simulations[combinationGroup].map((simulation) => ({
            parameter: dashboard.parameters[simulation.runId],
            value: resultConversions[pointGroup](simulation, dashboard.elecRate, dashboard.fuelRate, dashboard.currencyRate)
        }));

        if (helper.needToPredictValues(factors, runs[pointGroup])) {
            let baseValue;
            if (pointGroup === actionTypes.DASHBOARD_ENERGY_METRIC) {
                baseValue = dashboard.baseRun.annualEui;
            } else {
                baseValue = resultConversions[pointGroup](dashboard.baseRun, dashboard.elecRate, dashboard.fuelRate, dashboard.currencyRate);
            }
            runs[pointGroup] = helper.predictValues(factors, runs[pointGroup], baseValue, dashboard.baseRun);
        }
    }

    // Note: run.value is already converted to SI Units
    runs[actionTypes.DASHBOARD_COST_METRIC] = runs[actionTypes.DASHBOARD_COST_METRIC].map((run) => ({
        parameter: run.parameter,
        value: run.value,
        totalEuiSiUnits: run.value,
        totalEuiIpUnits: conv.UsdPerM2toUsdPerFT2(run.value)
    }))

    // Note: run.value is not yet converted and is the same one stored in the database
    runs[actionTypes.DASHBOARD_ENERGY_METRIC] = runs[actionTypes.DASHBOARD_ENERGY_METRIC].map((run) => ({
        parameter: run.parameter,
        value: run.value,
        totalEuiSiUnits: conv.MJm2tokWhm2(run.value),
        totalEuiIpUnits: conv.MJm2tokBtuft2(run.value)
    }))

    return runs;
}

const updateEcr = (modelDashboard, updateMeterMinMax) => {

    modelDashboard.showEcr = modelDashboard.baseRunStatus === 'Completed' &&
        modelDashboard.widgets.some(w => w.status === 'Loaded' && !w.hasErrors);

    if (!modelDashboard.showEcr) {
        return modelDashboard;
    }

    modelDashboard.ecrEnergy = computeEcr(modelDashboard, actionTypes.DASHBOARD_ENERGY_METRIC, updateMeterMinMax);
    modelDashboard.ecrCost = computeEcr(modelDashboard, actionTypes.DASHBOARD_COST_METRIC, updateMeterMinMax);

    return modelDashboard;
}

export const computeEcr = (model, pointGroup, updateMeterMinMax) => {
    //All results are stored in SI in the model that is why we need to convert
    //base run to proper units for calculation.
    //Currently this logic uses the points in the charts which are already in the proper unit,
    //when this is changed to the actual implementation results must be used.
    const fn = resultConversions[pointGroup];
    let bim = fn(model.baseRun, model.elecRate, model.fuelRate, model.currencyRate);

    bim = getPointY({ y: bim }, model.useSI, pointGroup);

    const calc = {
        bim,
        ecrMean: bim,
        ecrMin: Number.MIN_VALUE,
        ecrMax: Number.MAX_VALUE,
        meterMin: bim,
        meterMax: bim,
        ecrCalculation: {
            ecrMean: 0,
            ecrMin: 0,
            ecrMax: 0,
            groups: {}
        }
    };

    const runsInRange = {};
    const runsFullRange = {};    

    model.widgets.filter(w => w.status === 'Loaded' && !w.hasErrors).forEach(w => {
        const points = w.pointGroups[pointGroup];

        if (updateMeterMinMax) {
            //Don't use minY, maxY field of the widget because that one depends on the current selection for the y axis.
            //Exclude points equal to 0, same as before.
            const minY = Math.min(...points.filter(p => p.y > 0).map(p => p.y));
            const maxY = Math.max(...points.map(p => p.y));

            calc.meterMin += getMeanValue([minY], bim);
            calc.meterMax += getMeanValue([maxY], bim);
        }

        //Exclude points where y is equal to 0, these are incorrect values that will be fixed once
        //we introduce logic to predict values from existing runs.
        const inRangeValues = points.filter(p => p.inRange && p.y > 0).map(p => p.y);

        if (inRangeValues.length === 0)
            return;

        // A new object is generated from the runs to operate the savings and losses
        if (!runsInRange[w.combinationGroup]) {
            runsInRange[w.combinationGroup] = model.runs[w.combinationGroup][pointGroup];
            runsFullRange[w.combinationGroup] = Object.assign([], model.runs[w.combinationGroup][pointGroup]);
        }

        // Out of range points are extracted.
        let outOfRange = [];

        if (w.combinationGroup === consts.GLAZING_COMBINATION_GROUP_WEST || w.combinationGroup === consts.GLAZING_COMBINATION_GROUP_NORTH ||
            w.combinationGroup === consts.GLAZING_COMBINATION_GROUP_EAST || w.combinationGroup === consts.GLAZING_COMBINATION_GROUP_SOUTH) {       
            outOfRange = points.filter(p => !p.inRange).map(p => p.key)
        }
        else {
            outOfRange = points.filter(p => !p.inRange && p.key !== consts.BASE).map(p => p.key)
        }

        if (outOfRange.length > 0) {
            outOfRange.map((value) => {
                let splitValue = getParameterAndValueSim(model.baseRun, w.key, value);

                // Runs that are out of range are removed       
                if (splitValue.length === 2) {
                    runsInRange[w.combinationGroup] = runsInRange[w.combinationGroup].filter(s => s.parameter[splitValue[0]] != splitValue[1]);
                }
            })
        }
    });

    // Generate ECR calculations
    calc.ecrCalculation = ecrCalculation(model, runsInRange, runsFullRange, bim, pointGroup, model.useSI);

    calc.ecrMean = calc.ecrCalculation.ecrMean;
    calc.ecrMin = calc.ecrCalculation.ecrMin;
    calc.ecrMax = calc.ecrCalculation.ecrMax;

    // Define meter limits - Min / Max
    [calc.meterMin, calc.meterMax] = getMeterMinMax(model, calc.ecrCalculation.groups, bim, updateMeterMinMax, pointGroup, calc.ecrCalculation.ecrMax);

    return calc;
}

// Get minimum and maximum values of the meter - Default Min : 0
export const getMeterMinMax = (model, ecrCalcByGroup, bim, updateMeterMinMax, pointGroup, ecrMax) => {

    const meterMin = 0;
    let meterMax = bim;

    if (updateMeterMinMax) {
        Object.keys(ecrCalcByGroup).forEach(function (group) {
            meterMax += ecrCalcByGroup[group].consolidated.worstCase;
        });

        if (ecrMax > meterMax) {
            meterMax = ecrMax;
        }
    }
    else {
        meterMax = pointGroup === actionTypes.DASHBOARD_ENERGY_METRIC ? model.ecrEnergy.meterMax : model.ecrCost.meterMax;
    }

    return [meterMin, meterMax];
}

const getMeanValue = (values, base) => {
    return values.reduce((acc, current) => acc + current - base, 0) / values.length;
}

// Method that genetate ECR calculations - calculates savings and losses by group and factor
const ecrCalculation = (model, runsInrange, runsFullRange, bim, pointGroup, useSI) => {

    const savingsAndLossesByGroup: any = {
        groups: {},
        sumMeanCalc: 0,
        sumMinCalc: 0,
        sumMaxCalc: 0,
        hvacFacMeanCalc: 0,
        hvacFacMinCalc: 0,
        hvacFacMaxCalc: 0,
        hvacValMeanCalc: 0,
        hvacValMinCalc: 0,
        hvacValMaxCalc: 0,
        ecrMean: 0,
        ecrMin: 0,
        ecrMax: 0
    };

    // Loop to generate group of factors and Savings and Losses by Factor
    Object.keys(model.runs).forEach(function (groupKey) {

        if (runsInrange[groupKey]) {

            const valuesFullRange = runsFullRange[groupKey].map(g => useSI ? g.totalEuiSiUnits : g.totalEuiIpUnits);
            const valuesInRange = runsInrange[groupKey].map(g => useSI ? g.totalEuiSiUnits : g.totalEuiIpUnits);

            // Internal Loads Group (Infiltration - Plug Load Efficiency - Lighting Efficiency)
            if (groupKey === consts.INFILTRATION || groupKey === consts.PLUG_LOAD_EFFICIENCY_FACTOR || groupKey === consts.LIGHTING_EFFICIENCY_FACTOR) {

                if (!savingsAndLossesByGroup.groups?.InternalLoads) {
                    savingsAndLossesByGroup.groups[consts.INTERNAL_LOADS] = { savingsAndLossesByFactor: [] };
                }

                const resultInternalFactors = calcSavingAndLosses(valuesInRange, valuesFullRange, bim, groupKey);
                savingsAndLossesByGroup.groups.InternalLoads.savingsAndLossesByFactor.push(resultInternalFactors);

            }
            // Envelope Group (Wall Construction - Roof Construction)
            else if (groupKey === consts.WALL_CONSTRUCTION || groupKey === consts.ROOF_CONSTRUCTION) {

                if (!savingsAndLossesByGroup.groups?.Envelop) {
                    savingsAndLossesByGroup.groups[consts.ENVELOP] = { savingsAndLossesByFactor: [] };
                }

                const resultEnvelopFactors = calcSavingAndLosses(valuesInRange, valuesFullRange, bim, groupKey);
                savingsAndLossesByGroup.groups.Envelop.savingsAndLossesByFactor.push(resultEnvelopFactors);

            }
            //Form Group (Glazing Combination Grou West - Glazing Combination Group North - Glazing Combination Group East - Glazing Combination Group South)
            else if (groupKey === consts.GLAZING_COMBINATION_GROUP_WEST || groupKey === consts.GLAZING_COMBINATION_GROUP_EAST ||
                groupKey === consts.GLAZING_COMBINATION_GROUP_NORTH || groupKey === consts.GLAZING_COMBINATION_GROUP_SOUTH) {

                if (!savingsAndLossesByGroup.groups?.Form) {
                    savingsAndLossesByGroup.groups[consts.FORM] = { savingsAndLossesByFactor: [] };
                }

                const resultFormFactors = calcSavingAndLosses(valuesInRange, valuesFullRange, bim, groupKey);
                savingsAndLossesByGroup.groups.Form.savingsAndLossesByFactor.push(resultFormFactors);

            }
            // Orientation Group (Contains its own factor)
            else if (groupKey === consts.BUILDING_ORIENTATION_FACTOR) {

                if (!savingsAndLossesByGroup.groups?.BuildingOrientation) {
                    savingsAndLossesByGroup.groups[consts.BUILDING_ORIENTATION_FACTOR] = { savingsAndLossesByFactor: [] };
                }

                const resultBuildingOrientation = calcSavingAndLosses(valuesInRange, valuesFullRange, bim, groupKey);
                savingsAndLossesByGroup.groups.BuildingOrientation.savingsAndLossesByFactor.push(resultBuildingOrientation);

            }
            // Hvac Group (Contains its own factor)
            else if (groupKey === consts.HVAC_FACTOR) {

                if (!savingsAndLossesByGroup.groups?.HVAC) {
                    savingsAndLossesByGroup.groups[consts.HVAC_FACTOR] = { savingsAndLossesByFactor: [] };
                }

                const resultHvac = calcSavingAndLosses(valuesInRange, valuesFullRange, bim, groupKey);
                savingsAndLossesByGroup.groups.HVAC.savingsAndLossesByFactor.push(resultHvac);
            }
        }
    });

    // Consolidate values by factor group.
    Object.keys(savingsAndLossesByGroup.groups).forEach(function (key) {
        savingsAndLossesByGroup.groups[key]["consolidated"] = consolidateSavingsAndLosses(savingsAndLossesByGroup.groups[key].savingsAndLossesByFactor, model, bim, pointGroup, key);

        // If the group has values of meanCalc, minCalc, maxCalc, the total of each must be consolidated.
        if (savingsAndLossesByGroup.groups[key].consolidated.meanCalc || savingsAndLossesByGroup.groups[key].consolidated.minCalc ||
            savingsAndLossesByGroup.groups[key].consolidated.maxCalc) {

            savingsAndLossesByGroup.sumMeanCalc += savingsAndLossesByGroup.groups[key].consolidated.meanCalc
            savingsAndLossesByGroup.sumMinCalc += savingsAndLossesByGroup.groups[key].consolidated.minCalc
            savingsAndLossesByGroup.sumMaxCalc += savingsAndLossesByGroup.groups[key].consolidated.maxCalc
        }
    });

    savingsAndLossesByGroup.hvacFacMeanCalc = 1 + (savingsAndLossesByGroup.sumMeanCalc / bim);
    savingsAndLossesByGroup.hvacFacMinCalc = 1 + (savingsAndLossesByGroup.sumMinCalc / bim);
    savingsAndLossesByGroup.hvacFacMaxCalc = 1 + (savingsAndLossesByGroup.sumMaxCalc / bim);

    // If the HVAC factor is among the factor group, the data for the Hvac values must be generated
    if (savingsAndLossesByGroup.groups.HVAC) {

        // Get all hvac results
        const hvacResults = savingsAndLossesByGroup.groups.HVAC.savingsAndLossesByFactor[0];

        // Validate if there are values in range to generate remaining data.
        if (hvacResults.lossesInRange.length > 0 && hvacResults.savingsInRange.length > 0) {

            savingsAndLossesByGroup.hvacValMeanCalc = -savingsAndLossesByGroup.hvacFacMeanCalc * hvacResults.meanSavingsInRange + savingsAndLossesByGroup.hvacFacMeanCalc * hvacResults.meanLossesInRange;
            savingsAndLossesByGroup.hvacValMinCalc = -savingsAndLossesByGroup.hvacFacMinCalc * hvacResults.maxSavingsInRange + savingsAndLossesByGroup.hvacFacMinCalc * hvacResults.minLossesInRange;
            savingsAndLossesByGroup.hvacValMaxCalc = -savingsAndLossesByGroup.hvacFacMaxCalc * hvacResults.minSavingsInRange + savingsAndLossesByGroup.hvacFacMaxCalc * hvacResults.maxLossesInRange
        }
    }

    const ecrMeanSum = bim + savingsAndLossesByGroup.sumMeanCalc + savingsAndLossesByGroup.hvacValMeanCalc;
    savingsAndLossesByGroup.ecrMean = ecrMeanSum < 0 ? 0 : ecrMeanSum;

    const ecrMinSum = bim + savingsAndLossesByGroup.sumMinCalc + savingsAndLossesByGroup.hvacValMinCalc;
    savingsAndLossesByGroup.ecrMin = ecrMinSum < 0 ? 0 : ecrMinSum;

    const ecrMaxSum = bim + savingsAndLossesByGroup.sumMaxCalc + savingsAndLossesByGroup.hvacValMaxCalc;
    savingsAndLossesByGroup.ecrMax = ecrMaxSum < 0 ? 0 : ecrMaxSum;

    return savingsAndLossesByGroup;
}

// Method to calculate savings and losses by factor
const calcSavingAndLosses = (valuesInRange, valuesFullRange, bim, key) => {

    const result = {
        key,
        savingsInRange: [],
        lossesInRange: [],
        savingsFullRange: [],
        lossesFullRange: [],
        minSavingsInRange: 0,
        maxSavingsInRange: 0,
        meanSavingsInRange: 0,
        minLossesInRange: 0,
        maxLossesInRange: 0,
        meanLossesInRange: 0,
        fullRangeMaxSavings: 0,
        fullRangeMaxLosses: 0
    };

    if (valuesInRange.length > 0) {

        valuesInRange.forEach(x => {
            x <= bim ? result.savingsInRange.push(bim - x) : result.savingsInRange.push(0);
            x >= bim ? result.lossesInRange.push(x - bim) : result.lossesInRange.push(0);
        });

        result.minSavingsInRange = Math.min(...result.savingsInRange);
        result.maxSavingsInRange = Math.max(...result.savingsInRange);
        result.meanSavingsInRange = result.savingsInRange.reduce((acc, current) => acc + current, 0) / result.savingsInRange.length;

        result.minLossesInRange = Math.min(...result.lossesInRange);
        result.maxLossesInRange = Math.max(...result.lossesInRange);
        result.meanLossesInRange = result.lossesInRange.reduce((acc, current) => acc + current, 0) / result.lossesInRange.length;
    }

    if (valuesFullRange.length > 0) {

        valuesFullRange.forEach(x => {
            x <= bim ? result.savingsFullRange.push(bim - x) : result.savingsFullRange.push(0);
            x >= bim ? result.lossesFullRange.push(x - bim) : result.lossesFullRange.push(0);
        });

        result.fullRangeMaxSavings = Math.max(...result.savingsFullRange);
        result.fullRangeMaxLosses = Math.max(...result.lossesFullRange);
    }
    return result
}

// Method to consolidate values by factor group.
const consolidateSavingsAndLosses = (savingsAndLossesByGroup, model, bim, pointGroup, key) => {

    const result: any = {
        sumMaxSavings: null,
        sumMinSavings: null,
        sumMeanSavings: null,
        sumMaxLossess: null,
        sumMinLossess: null,
        sumMeanLosses: null,
        sumMaxSavingsFullRange: null,
        sumMaxLossesFullRange: null
    }

    savingsAndLossesByGroup.forEach(item => {
        result.sumMaxSavings = getSumValue(result.sumMaxSavings, item.maxSavingsInRange);
        result.sumMinSavings = getSumValue(result.sumMinSavings, item.minSavingsInRange);
        result.sumMeanSavings = getSumValue(result.sumMeanSavings, item.meanSavingsInRange);
        result.sumMaxLossess = getSumValue(result.sumMaxLossess, item.maxLossesInRange);
        result.sumMinLossess = getSumValue(result.sumMinLossess, item.minLossesInRange);
        result.sumMeanLosses = getSumValue(result.sumMeanLosses, item.meanLossesInRange);
        result.sumMaxSavingsFullRange = getSumValue(result.sumMaxSavingsFullRange, item.fullRangeMaxSavings);
        result.sumMaxLossesFullRange = getSumValue(result.sumMaxLossesFullRange, item.fullRangeMaxLosses);
    })

    // Extract minimum and maximum simulation values
    const minMaxValues = getMinMaxValues(model, pointGroup, key);

    //If there are values of the minimum and maximum simulations, the savings and losses must be generated, and calculate minSim, maxSim, radioMin, radioMax, meanCalc, minCalc, maxCalc
    if (minMaxValues?.length > 0 || key === consts.BUILDING_ORIENTATION_FACTOR) {

        //Calculate Savings and losses according to the minimum and maximum simulation values
        const resultMinMax: any = calcSavingAndLosses(minMaxValues, minMaxValues, bim, key);

        result["minSim"] = key === consts.BUILDING_ORIENTATION_FACTOR ? result.sumMaxSavingsFullRange : resultMinMax.maxSavingsInRange;
        result["maxSim"] = key === consts.BUILDING_ORIENTATION_FACTOR ? result.sumMaxLossesFullRange : resultMinMax.maxLossesInRange;
        result["radioMin"] = result.sumMaxSavingsFullRange === 0 ? 1 : result.minSim / result.sumMaxSavingsFullRange;
        result["radioMax"] = result.sumMaxLossesFullRange === 0 ? 1 : result.maxSim / result.sumMaxLossesFullRange;
        result["meanCalc"] = (result.sumMeanSavings - result.sumMeanLosses) > 0 ? -((result.sumMeanSavings - result.sumMeanLosses) * result.radioMin) : -((result.sumMeanSavings - result.sumMeanLosses) * result.radioMax);
        result["minCalc"] = (result.sumMaxSavings - result.sumMinLossess) > 0 ? -((result.sumMaxSavings - result.sumMinLossess) * result.radioMin) : -((result.sumMaxSavings - result.sumMinLossess) * result.radioMax);
        result["maxCalc"] = (result.sumMinSavings - result.sumMaxLossess) > 0 ? -((result.sumMinSavings - result.sumMaxLossess) * result.radioMin) : -((result.sumMinSavings - result.sumMaxLossess) * result.radioMax);

        if (key === consts.BUILDING_ORIENTATION_FACTOR) {
            result["worstCase"] = result.sumMaxLossess;
        }
        else {
            result["worstCase"] = resultMinMax.maxLossesInRange;
        }
    }
    else if (key === consts.HVAC_FACTOR) {
        result["worstCase"] = result.sumMaxLossess;
    }

    return result;
}

const getSumValue = (currentValue, value) => {
    if (value !== undefined && value !== null) {
        return currentValue + value;
    }

    return currentValue;
}

// Method that extracts and converts the data of the minimum and maximum simulations of the Internal Loads, Envelope and Form groups
const getMinMaxValues = (model, pointGroup, key) => {

    let minMaxkey = null;
    const minMaxValues = [];
    const elecRate = conv.CovertFromBaseCurrency(model.currencyRate, model.elecRate);
    const fuelRate = conv.CovertFromBaseCurrency(model.currencyRate, model.fuelRate);

    switch (key) {
        case consts.INTERNAL_LOADS:
            minMaxkey = consts.INTERNAL_LOAD_MIN_MAX;
            break;
        case consts.ENVELOP:
            minMaxkey = consts.ENVELOPE_CALCULATION_MIN_MAX;
            break;
        case consts.FORM:
            minMaxkey = consts.FORM_MIN_MAX;
            break;
        default:
            minMaxkey = null;
    }

    if (model.simulations[minMaxkey]) {
        model.simulations[minMaxkey].map((sim) => {

            if (pointGroup === actionTypes.DASHBOARD_ENERGY_METRIC) {
                const result = getConvertedResult(sim, model.useSI);
                minMaxValues.push(result.annualEui);
            }
            else {
                let EuiElecCost = conv.MJm2tokWhm2(sim.annualEuiElec) * elecRate;
                let EuiFuelCost = conv.MJm2tokWhm2(sim.annualEuiFuel) * fuelRate;

                EuiElecCost = model.useSI ? EuiElecCost : conv.UsdPerM2toUsdPerFT2(EuiElecCost);
                EuiFuelCost = model.useSI ? EuiFuelCost : conv.UsdPerM2toUsdPerFT2(EuiFuelCost);
                minMaxValues.push(conv.round(EuiElecCost + EuiFuelCost, 4))
            }
        })
    }

    return minMaxValues;
}

const resultConversions = {
    [actionTypes.DASHBOARD_ENERGY_METRIC]: (apiResult, elecRate, fuelRate, currencyRate) => apiResult.annualEui, [actionTypes.DASHBOARD_COST_METRIC]: (apiResult, elecRate, fuelRate, currencyRate) => {
        const convertedElecRate = conv.CovertFromBaseCurrency(currencyRate, elecRate);
        const convertedFuelRate = conv.CovertFromBaseCurrency(currencyRate, fuelRate);

        return getCostFromEui(apiResult.annualEuiElec, convertedElecRate) + getCostFromEui(apiResult.annualEuiFuel, convertedFuelRate);
    }
};

const updateWidgetMinMax = (widget, selectedPointGroup, changeSettings?) => {
    const points = widget.pointGroups[selectedPointGroup];

    if (points.length !== 0) {
        widget.minY = Math.min(...points.map(p => p.y));
        widget.maxY = Math.max(...points.map(p => p.y));
        widget.impact = widget.maxY - widget.minY;

        if (widget.status === 'Loaded' && !changeSettings) {
            updateWidgetSelectedRange(widget, points[0].key, points[points.length - 1].key);
        } else if (changeSettings) {
            updateWidgetSelectedRange(widget, widget.rangeLeft, widget.rangeRight);
        }
    }
}

const updateWidgetAllRangeSelected = widget => {
    //either set of points is ok for this since the order is the same in all of them.
    const points = widget.pointGroups[actionTypes.DASHBOARD_ENERGY_METRIC];

    return updateWidgetSelectedRange(widget, points[0].key, points[points.length - 1].key);
}

const updateWidgetSelectedRange = (widget, left, right) => {
    widget.rangeLeft = left;
    widget.rangeRight = right;

    const pointGroups = {};
    for (const [key, points] of Object.entries(widget.pointGroups)) {
        const leftIndex = (points as any).findIndex(p => p.key === left);
        const rightIndex = (points as any).findIndex(p => p.key === right);

        pointGroups[key] = (points as any).map((el, i) => ({
            ...el,
            inRange: i >= leftIndex && i <= rightIndex
        }));
    }

    widget.pointGroups = pointGroups;

    return widget;
}

const sortWidgets = (widgets, sortBy) => {
    widgets.sort((el1, el2) => {
        if (sortBy === 'Impact') {
            if (el1.hasOwnProperty('impact') && el2.hasOwnProperty('impact')) {
                return el2.impact - el1.impact;
            }

            if (el1.hasOwnProperty('impact')) {
                return -1;
            }

            if (el2.hasOwnProperty('impact')) {
                return 1;
            }
        }

        return el1.categoryOrder - el2.categoryOrder;
    });

    return widgets;
}

const getPoints = (displayValues, factor, widgetType, pointGroup, useMetricSystem, currencyIso) => {

    const points = displayValues.map(value => {
        return getPoint(value, factor, widgetType, pointGroup, useMetricSystem, currencyIso);
    });

    sortPoints(points, widgetType);

    return points;
}

const sortPoints = (points, widgetType) => {
    const total = points.length;
    const i1 = total / 3;
    const i2 = i1 * 2;

    points.sort((p1, p2) => p2.y - p1.y);

    points.forEach((p, i) => {
        if (i < i1)
            p.impact = 'high';
        else if (i >= i1 && i <= i2)
            p.impact = 'medium';
        else
            p.impact = 'low';
    });

    if (widgetType === 'continuous') {
        points.sort((p1, p2) => p1.x - p2.x);
    } else {
        points.sort((p1, p2) => p1.key < p2.key ? -1 : 1);
    }
};

const getPoint = (displayValue, factor, widgetType, pointGroup, useMetricSystem, currencyIso) => {

    const y = getPointY(displayValue, useMetricSystem, pointGroup);
    const x = getPointX(displayValue, factor, widgetType, useMetricSystem);

    return {
        key: displayValue.isBaseRun ? consts.BASE : `${factor}-${displayValue.parameterName}=${displayValue.x.toString()}`,
        x,
        y,
        tooltipDescription: getSimulationTooltipTitle(factor, displayValue, x, useMetricSystem, widgetType),
        tooltipValue: getPointYDescription(y, useMetricSystem, pointGroup, currencyIso)
    };
};

/*
 * Helper functions to convert results, titles and tooltips to proper units and values.
 */

const getWidgetTitle = (factorName) => {
    return i18n.t(`analysis.simulations.${factorName}.title`);
}

const getWidgetXTitle = (factorName, useMetricSystem) => {
    const localizationKey = `analysis.simulations.${factorName}.xAxisTitleTemplate`;

    return i18n.t(localizationKey, {
        unit: getFactorPointXUnit(factorName, useMetricSystem)
    });
}

const getWidgetYTitle = (useMetricSystem, pointGroup, currency) => {
    const unitSystemKey = useMetricSystem ? "si" : "ip";
    let metricDescription, unit;

    switch (pointGroup) {
        case actionTypes.DASHBOARD_ENERGY_METRIC:
            metricDescription = i18n.t('analysis.metric.eui.description');
            unit = i18n.t(`symbol.${unitSystemKey}.eui`);
            break;
        case actionTypes.DASHBOARD_COST_METRIC:
            metricDescription = i18n.t('analysis.metric.cost.description');
            unit = i18n.t(`symbol.${unitSystemKey}.cost`, { currency: currency });
            break;
        default:
            metricDescription = "";
            unit = "";
    }

    return i18n.t('analysis.simulations.yAxisTitleTemplate', {
        metricDescription,
        unit
    });
}

const getWidgetType = (key) => {
    switch (key) {
        case consts.WWR_EASTERN_WALLS:
        case consts.WWR_NORTHERN_WALLS:
        case consts.WWR_SOUTHERN_WALLS:
        case consts.WWR_WESTERN_WALLS:
        case consts.LIGHTING_EFFICIENCY_FACTOR:
        case consts.PLUG_LOAD_EFFICIENCY_FACTOR:
        case consts.BUILDING_ORIENTATION_FACTOR:
        case consts.WINDOW_SHADES_EAST:
        case consts.WINDOW_SHADES_NORTH:
        case consts.WINDOW_SHADES_SOUTH:
        case consts.WINDOW_SHADES_WEST:
        case consts.INFILTRATION:
            return 'continuous';
        default:
            return 'discrete';
    }
}

const getSimulationTooltipTitle = (factor, displayValue, convertedX, useMetricSystem, widgetType) => {
    if (widgetType === 'continuous') {
        return getContinuousFactorSimulationTooltipTitle(factor, convertedX, useMetricSystem);
    }

    if (displayValue.isBaseRun) {
        return i18n.t(`analysis.simulations.${consts.BASE}.description`);
    }

    return i18n.t(`analysis.simulations.${factor}.${normalizeString(displayValue.x)}.description`);
}

const getContinuousFactorSimulationTooltipTitle = (factor, convertedX, useMetricSystem) => {
    const localizationKey = `analysis.simulations.${factor}.descriptionTemplate`;

    return i18n.t(localizationKey, {
        value: getContinuousFactorPointXRounded(factor, convertedX),
        unit: getFactorPointXUnit(factor, useMetricSystem)
    });
}

const getContinuousFactorPointXRounded = (factor, x) => {
    switch (factor) {
        case consts.INFILTRATION:
            return x === null ? "" : x.toFixed(4);
        case consts.PLUG_LOAD_EFFICIENCY_FACTOR:
        case consts.LIGHTING_EFFICIENCY_FACTOR:
            return x === null ? "" : x.toFixed(1);
        case consts.WWR_NORTHERN_WALLS:
        case consts.WWR_EASTERN_WALLS:
        case consts.WWR_SOUTHERN_WALLS:
        case consts.WWR_WESTERN_WALLS:
            return x === null ? "" : x.toFixed(0);
        default:
            return x;
    }
}

const getFactorPointXUnit = (factor, useMetricSystem) => {
    const localizationKey = `analysis.simulations.${factor}.unit.${useMetricSystem ? "si" : "ip"}`;

    if (i18n.exists(localizationKey)) {
        return i18n.t(localizationKey);
    } else {
        return "";
    }
}

const getPointY = (displayValue, useMetricSystem, pointGroup) => {
    if (useMetricSystem) {
        switch (pointGroup) {
            case actionTypes.DASHBOARD_ENERGY_METRIC:
                return conv.MJm2tokWhm2(displayValue.y);
            case actionTypes.DASHBOARD_COST_METRIC:
                return displayValue.y;
            default:
                return null;
        }
    }

    switch (pointGroup) {
        case actionTypes.DASHBOARD_ENERGY_METRIC:
            return conv.MJm2tokBtuft2(displayValue.y);
        case actionTypes.DASHBOARD_COST_METRIC:
            return conv.UsdPerM2toUsdPerFT2(displayValue.y);
        default:
            return null;
    }
}

const getPointYDescription = (y, useMetricSystem, pointGroup, currencyIso) => {
    let metricName;

    if (pointGroup === actionTypes.DASHBOARD_COST_METRIC) {
        metricName = i18n.t('analysis.metric.cost.name');
    } else {
        metricName = i18n.t('analysis.metric.eui.name');
    }

    return i18n.t('analysis.simulations.tooltipValueTemplate', {
        metricName,
        unit: getPointYUnit(useMetricSystem, pointGroup, currencyIso),
        value: y === null ? "" : y.toFixed(2)
    });
}

const getPointYUnit = (useMetricSystem, pointGroup, currencyIso) => {
    const areaSymbol = useMetricSystem ? i18n.t('symbol.unit.m2') : i18n.t('symbol.unit.ft2');

    if (pointGroup === actionTypes.DASHBOARD_COST_METRIC) {
        return `${currencyIso}/${areaSymbol}/yr`;
    }
    const unit = useMetricSystem ? i18n.t('symbol.unit.kwh') : i18n.t('symbol.unit.kbtu');

    return `${unit}/${areaSymbol}/yr`;
}

const getPointX = (displayValue, factor, widgetType, useMetricSystem) => {
    //For charts where x value is a string we just do a translation of the parameter value
    //to an in chart display text.
    if (widgetType === 'discrete') {
        if (displayValue.isBaseRun) {
            return i18n.t(`analysis.simulations.${consts.BASE}.shortDescription`);
        }
        return i18n.t(`analysis.simulations.${factor}.${normalizeString(displayValue.x)}.shortDescription`);
    }

    if (displayValue.isBaseRun) {
        return convertContinuousFactorBaseRunValue(factor, displayValue, useMetricSystem);
    }

    return convertContinuousFactorSimulationValue(factor, displayValue, useMetricSystem);
}

//X value for base runs simulations are always in SI so we check if conversion is needed since
//some factors do not have units (WWR).
export const convertContinuousFactorBaseRunValue = (factor, displayValue, useMetricSystem) => {
    let x = displayValue.x;

    //Base run is always in metric.
    if (useMetricSystem)
        return x;

    switch (factor) {
        case consts.PLUG_LOAD_EFFICIENCY_FACTOR:
        case consts.LIGHTING_EFFICIENCY_FACTOR:
            x = conv.Wm2toWft2(x);
            break;
        case consts.INFILTRATION:
            x = conv.m3hm2toCFMft2(x);
            break;
    }

    return x;
}

//The initial unit for the x value of non base runs depends on the factor, so we check if conversions apply.
export const convertContinuousFactorSimulationValue = (factor, displayValue, useMetricSystem) => {
    let x = displayValue.x;

    //Currently the x unit for factors that need conversions are all in IP
    //so only convert if SI was requested
    if (useMetricSystem) {
        switch (factor) {
            case consts.PLUG_LOAD_EFFICIENCY_FACTOR:
            case consts.LIGHTING_EFFICIENCY_FACTOR:
                x = conv.Wft2toWm2(x);
                break;
            case consts.INFILTRATION:
                x = conv.CFMft2tom3hm2(x);
                break;
        }
    }

    //aditional conversion for WWR values eg : (0.1) -> 10%
    switch (factor) {
        case consts.WWR_NORTHERN_WALLS:
        case consts.WWR_EASTERN_WALLS:
        case consts.WWR_SOUTHERN_WALLS:
        case consts.WWR_WESTERN_WALLS:
            x = x * 100;
    }

    return x;
}

const normalizeString = str => str.replace('.', '');

//annualEui must be in MJ/m2 (as returned by API) and energyRate in USD per kwh,
//returns value in USD/m2.
const getCostFromEui = (annualEui, energyRate) => {
    return conv.MJm2tokWhm2(annualEui) * energyRate;
};

const getContinuousFactorInputBaseRun = (factor, result) => {
    switch (factor) {
        case consts.PLUG_LOAD_EFFICIENCY_FACTOR:
            return result.inputPlugLoadValue;
        case consts.LIGHTING_EFFICIENCY_FACTOR:
            return result.inputLightingValue;
        case consts.WWR_NORTHERN_WALLS:
            return result.inputWwrNorthValue;
        case consts.WWR_EASTERN_WALLS:
            return result.inputWwrEastValue;
        case consts.WWR_SOUTHERN_WALLS:
            return result.inputWwrSouthValue;
        case consts.WWR_WESTERN_WALLS:
            return result.inputWwrWestValue;
        case consts.BUILDING_ORIENTATION_FACTOR:
        case consts.WINDOW_SHADES_EAST:
        case consts.WINDOW_SHADES_NORTH:
        case consts.WINDOW_SHADES_SOUTH:
        case consts.WINDOW_SHADES_WEST:
            return 0;
        case consts.INFILTRATION:
            return result.inputInfiltrationValue;
        default:
            return null;
    }
}

const getParameterAndValueSim = (baseRun, key, value) => {

    let splitValue = [];
    const factorMap = helper.factorMap[consts.WWR_NORTHERN_WALLS]

    if (value === consts.BASE) {
        if (key === consts.WINDOW_SHADES_NORTH || key === consts.WINDOW_SHADES_EAST || key === consts.WINDOW_SHADES_SOUTH || key === consts.WINDOW_SHADES_WEST) {
            splitValue = [consts.SHADING_PARAMETER_NAME, null]
        }
        else if (key === consts.GLAZING_CONSTRUCTION_NORTH || key === consts.GLAZING_CONSTRUCTION_EAST || key === consts.GLAZING_CONSTRUCTION_SOUTH || key === consts.GLAZING_CONSTRUCTION_WEST) {
            splitValue = [consts.GLAZING_PARAMETER_NAME, null]
        }
        else {
            switch (key) {
                case consts.WWR_WESTERN_WALLS: {
                    var inputWwrWest = conv.round(baseRun.inputWwrWestValue / 100, 2);
                    if (!factorMap.values.includes(inputWwrWest)) {
                        splitValue = [consts.WWR_PARAMETER_NAME, inputWwrWest]
                    }
                    break;
                }
                case consts.WWR_NORTHERN_WALLS: {
                    var inputWwrNorth = conv.round(baseRun.inputWwrNorthValue / 100, 2);
                    if (!factorMap.values.includes(inputWwrNorth)) {
                        splitValue = [consts.WWR_PARAMETER_NAME, inputWwrNorth]
                    }
                    break;
                }
                case consts.WWR_EASTERN_WALLS: {
                    var inputWwrEast = conv.round(baseRun.inputWwrEastValue / 100, 2);
                    if (!factorMap.values.includes(inputWwrEast)) {
                        splitValue = [consts.WWR_PARAMETER_NAME, inputWwrEast]
                    }
                    break;
                }
                case consts.WWR_SOUTHERN_WALLS: {
                    var inputWwrSouth = conv.round(baseRun.inputWwrSouthValue / 100, 2);
                    if (!factorMap.values.includes(inputWwrSouth)) {
                        splitValue = [consts.WWR_PARAMETER_NAME, inputWwrSouth]
                    }
                    break;
                }
                default:
                    break;
            }
        }
    }
    else {
        splitValue = value.replace(/\-/, '&').split('&')[1].split('=');
    }

    return splitValue;
}
