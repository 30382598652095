import React, { useRef, useState, useContext, useEffect } from 'react';
import Popper, { popperPlacement } from '@weave-mui/popper';
import Box from '@weave-mui/box';
import { spacings } from '@weave-mui/enums';
import '../../css/new/ecDefinition.css';
import isequal from 'lodash.isequal';
import { AlertS, CaretRightMUI } from '@weave-mui/icons-weave';
import i18n from '../../i18n';
import * as locConsts from '../localization/consts';
import { MaterialRow } from '../../analysis/dashboard.models';
import { EcDefinition } from '../../analysis/EmbodiedCarbon/types';
import TooltipContainer from '../../analysis/components/TooltipContainer/TooltipContainer';
import { DataGridContext } from '../../analysis/EmbodiedCarbon/DataGridContext';
interface ECDefinitionComponentProps {
  row: MaterialRow;
  renderEcDefinitionContentWeave: (
    row: {},
    openAdvancedSearchWeave: Function,
    ecDefinition: EcDefinition,
    setAnchorEl: Function
  ) => JSX.Element;
  openAdvancedSearchWeave: (row: MaterialRow) => void;
}

type checkTotalParams = {
  depth?: number;
  ecDefinition?: {};
};

type totalRow = {
  isTotalRow: boolean;
  total: {};
};

const ECDefinitionComponent = ({
  row,
  renderEcDefinitionContentWeave,
  openAdvancedSearchWeave,
}: any) => {

  const dataGridContext = useContext(DataGridContext);
  const { isOpenQuickModal, setIsOpenQuickModal } = dataGridContext;

  const { ecDefinition } = row;
  const selectedValue = ecDefinition?.name;
  const { M } = spacings;
  const [anchorEl, setAnchorEl] = useState(null);
  const placement = useRef<any>(popperPlacement.LEFT);
  const itemEcDefinition = useRef(null);
  
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    if(isOpenQuickModal){
      setAnchorEl(null);
    } else {
    setAnchorEl(event.currentTarget);
    setIsOpenQuickModal(true)
    }
  
    if (!itemEcDefinition && !window) return;

    if (itemEcDefinition?.current?.getBoundingClientRect().y + 324 / 1.8 > window?.innerHeight) {
      placement.current = popperPlacement.LEFT_END;
    } else {
      placement.current = popperPlacement.LEFT;
    }
   
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpenQuickModal(false)
  };

  useEffect(() => {
    const handleResize = () => {
      if (isOpenQuickModal && anchorEl) {
        setAnchorEl(null);
        setIsOpenQuickModal(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpenQuickModal, anchorEl]);

  return (
    <span
      ref={itemEcDefinition}
      className={selectedValue ? 'MuiTooltipWrapper' : 'MuiTooltipWrapperIcon'}
      style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
    >
      <span className='MuiDefinitionCellContet' onClick={handleClick}>
        {selectedValue ? (
            <TooltipContainer content={<p>{selectedValue}</p>} />
          ) : (
            <TooltipContainer
              hasIcon
              title={
                <>
                  {i18n.t(locConsts.MISSING_EC_COEFFICIENT)} <br />
                  <br />
                  {i18n.t(locConsts.MISSING_EC_DEFINITION)}
                </>
              }
              content={
                <span style={{ display: 'block' }} onClick={handleClick}>
                  <AlertS sx={{ fill: '#FAA21B !important' }} />
                </span>
              }
            />
          )}
          <div className="MuiIconWrapper" >
            <CaretRightMUI sx={{ marginRight: '0px !important' }} />
          </div>
      </span>
      <Popper
        placement={placement.current}
        arrow
        sx={{ zIndex: 1000, width: 'auto' }}
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [placement.current === 'left-end' ? 10 : 0, 0],
            },
          },
        ]}
      >
        <Box sx={{ padding: M }}>
          {renderEcDefinitionContentWeave(
            row,
            openAdvancedSearchWeave,
            row?.ecDefinition,
            setAnchorEl
          )}
        </Box>
      </Popper>
    </span>
  );
};

const checkTotalRow = (data: checkTotalParams): totalRow => {
  const isTotalRow: boolean = !data?.depth;

  return {
    isTotalRow,
    total: data?.ecDefinition,
  };
};

const ECDefinition = React.memo(
  (props: ECDefinitionComponentProps) => {
    const { 
      row,
      renderEcDefinitionContentWeave,
      openAdvancedSearchWeave, 
    } = props;
    
    const { total, isTotalRow } = checkTotalRow(row);
    return (
      <>{isTotalRow ?
        <span>{total}</span> :
        <ECDefinitionComponent
          row={row}
          renderEcDefinitionContentWeave={renderEcDefinitionContentWeave}
          openAdvancedSearchWeave={openAdvancedSearchWeave}
        />}
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      isequal(prevProps.row, nextProps.row)
    );
  }
);

export default ECDefinition;
