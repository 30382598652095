import React, { useState } from 'react';
import Typography, { typographyVariants } from '@weave-mui/typography';
import Tooltip, { tooltipSize } from '@weave-mui/tooltip';
import { overflowingText, unitSpacing } from '../layout/cards/base/Card.stylesheet';

interface ITypographyProps {
  mainValue: number | string | null,
  secondaryValue?: string,
  enableTooltip: Boolean
}

export const EllipsisTypography: React.FC<ITypographyProps> = ({ mainValue, secondaryValue, enableTooltip }: ITypographyProps) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const showToolTip = (e: any) => {
    if (e.target.scrollWidth > e.target.clientWidth) {
      setTooltipEnabled(true);
    }
  };

  return (
    <>
      <Tooltip
        arrow
        enterDelay={500}
        enterNextDelay={500}
        size={tooltipSize.MEDIUM}
        sx={{ maxWidth: '230px' }}
        title={
          <Typography variant={typographyVariants.CAPTION_REGULAR}>
            {`${mainValue} ${secondaryValue}`}
          </Typography>
        }
        open={tooltipEnabled && enableTooltip}
        onClose={() => setTooltipEnabled(false)}
      >
        <Typography
          sx={{ ...overflowingText }}
          variant={typographyVariants.H3_BOLD}
          onMouseEnter={(e) => showToolTip(e)}
        >{mainValue}</Typography>
      </Tooltip>
      <Typography
        variant={typographyVariants.H3_REGULAR}
        sx={{ ...unitSpacing, whiteSpace: 'nowrap' }}>{secondaryValue}</Typography>
    </>
  );
};
