import { FixedOption } from '../types/reporting';

export const projectPhaseOptions: FixedOption[] = [
  { name: 'Concept', isDefault: true },
  { name: 'Schematic Design' },
  { name: 'Design Development' },
  { name: 'Construction Documents' },
  { name: 'Construction Administration' },
];

export const reportingYearOptions: FixedOption[] = new Array(new Date().getFullYear() + 1 - 2010)
  .fill(0)
  .map((_, i) => {
    const year = 2010 + i;
    const isDefault = year === new Date().getFullYear();
    return { name: `${year}`, isDefault };
  });

export const climateZoneOptions: FixedOption[] = [
  { name: '1a very hot humid', isDefault: true },
  { name: '1b very hot dry' },
  { name: '2a hot humid' },
  { name: '2b hot dry' },
  { name: '3a warm humid' },
  { name: '3b warm dry' },
  { name: '3c warm marine' },
  { name: '4a mixed humid' },
  { name: '4b mixed dry' },
  { name: '4c mixed marine' },
  { name: '5a cool humid' },
  { name: '5b cool dry' },
  { name: '6a cold humid' },
  { name: '6b cold dry' },
  { name: '7 very cold' },
  { name: '8 subarctic' },
];

export const useType1TypeOptions: FixedOption[] = [
  { name: 'Bank/Financial Institution', isDefault: true },
  { name: 'Courthouse' },
  { name: 'Data Center' },
  { name: 'Education - College/University (campus-level)' },
  { name: 'Education - General' },
  { name: 'Education - K-12 School' },
  { name: 'Food Sales - Convenience Store (w/ or w/out gas station)' },
  { name: 'Food Sales - General' },
  { name: 'Food Sales - Supermarket/Grocery' },
  { name: 'Food Service - Fast Food' },
  { name: 'Food Service - General' },
  { name: 'Food Service - Restaurant/Cafeteria' },
  { name: 'Health Care - Clinic' },
  { name: 'Health Care - Hospital Inpatient' },
  { name: 'Health Care - Medical Office' },
  { name: 'Health Care - Nursing/Assisted Living' },
  { name: 'Health Care - Outpatient - General' },
  { name: 'Laboratory - recommend use of Labs21' },
  { name: 'Lodging - General' },
  { name: 'Lodging - Hotel/Motel' },
  { name: 'Lodging - Residence Hall/Dormitory' },
  { name: 'Mixed-Use' },
  { name: 'Office - Small ( < 10,000 sf)' },
  { name: 'Office - Medium (< 100,000 sf)' },
  { name: 'Office - Large' },
  { name: 'Public Assembly - Entertainment/Culture' },
  { name: 'Public Assembly - General' },
  { name: 'Public Assembly - Library' },
  { name: 'Public Assembly - Recreation' },
  { name: 'Public Assembly - Social/Meeting' },
  { name: 'Public Safety - Fire/Police Station' },
  { name: 'Public Safety - General' },
  { name: 'Religious Worship' },
  { name: 'Residential - Mid-Rise/High-Rise' },
  { name: 'Residential - Mobile Homes' },
  { name: 'Residential - Multi-Family, 2 to 4 units' },
  { name: 'Residential - Multi-Family, 5 or more units' },
  { name: 'Residential - Single-Family Attached' },
  { name: 'Residential - Single-Family Detached' },
  { name: 'Retail - Mall' },
  { name: 'Retail - Non-mall, Vehicle Dealerships, misc.' },
  { name: 'Retail Store' },
  { name: 'Service (vehicle repair/service, postal service)' },
  { name: 'Storage - Distribution/Shipping Center' },
  { name: 'Storage - General' },
  { name: 'Storage - Non-refrigerated warehouse' },
  { name: 'Storage - Refrigerated warehouse' },
  { name: 'Warehouse - Self-storage' },
  { name: 'Other' },
  { name: 'Parking' },
];

export const energyCodeOptions: FixedOption[] = [
  { name: 'ASHRAE 90.1-1999' },
  { name: 'ASHRAE 90.1-2001' },
  { name: 'ASHRAE 90.1-2004' },
  { name: 'ASHRAE 90.1-2007' },
  { name: 'ASHRAE 90.1-2010', isDefault: true },
  { name: 'ASHRAE 90.1-2013' },
  { name: 'ASHRAE 90.1-2016' },
  { name: 'California Title 24 2005 for high rise residential' },
  { name: 'California Title 24 2005 for single family residential' },
  { name: 'California Title 24 2008' },
  { name: 'California Title 24 2010' },
  { name: 'California Title 24 2013' },
  { name: 'California Title 24 2016' },
  { name: 'California Title 24 Non Residential 2008' },
  { name: 'California Title 24 Residential 2005' },
  { name: 'California Title 24 Residential 2008' },
  { name: 'IECC 2003' },
  { name: 'IECC 2006' },
  { name: 'IECC 2009' },
  { name: 'IECC 2012' },
  { name: 'IECC 2015' },
  { name: 'IECC-2018' },
  { name: 'Massachusetts Stretch Code' },
  { name: 'NECB 2011' },
  { name: 'NECB 2015' },
  { name: 'NECB 2017' },
  { name: 'Older than 1999' },
  { name: 'Oregon Energy Code 2010' },
  { name: 'Oregon Energy Code 2014' },
  { name: 'Oregon Energy Efficiency Specialty Code 2010' },
  { name: 'Oregon Energy Efficiency Specialy Code 2010' },
  { name: 'Oregon Energy Efficiency Specialy Code 2014' },
  { name: 'Washington Energy Code' },
  { name: 'Washington Energy Code 2012' },
  { name: 'Washington Energy Code 2015' },
];

export const renewablesOptions: FixedOption[] = [
  { name: 'None', isDefault: true },
  { name: 'Photovoltaic' },
  { name: 'PV' },
  { name: 'Solar thermal' },
  { name: 'Wind turbine' },
  { name: 'Heat pump' },
  { name: 'Geothermal' },
  { name: 'Micro-hydroelectric' },
  { name: 'Biomass' },
];

export const chillerTypeOptions: FixedOption[] = [
  { name: 'Electric Driven', isDefault: true },
  { name: 'Natural Gas Absorption' },
  { name: 'Natural Gas Engine Driven' },
];

export const estimatedOccupancyOptions: FixedOption[] = Array.from({ length: 20 }, (_, index) => {
  const year = new Date().getFullYear() + index;
  return { name: `${year}`, isDefault: index === 0 };
});

export const energyModellingPartyOptions: FixedOption[] = [
  { name: 'Architecture team', isDefault: true },
  { name: 'Design engineer' },
  { name: 'Modeling consultant' },
  { name: 'Other' },
];
