import { httpGet, httpPostJson } from '../shared'
import { getInsightApigeeBasePath } from '../shared/api';

export const getProject = async (projectId: string) => {
    return httpGet(`${getInsightApigeeBasePath()}/api/v1/projects/${projectId}`);
}

export const updateProjectSettings = (projectId: string, useSiUnits: boolean, currencyIso: string, fuelRate: number, electricityRate: number, electricityCeCoefficient: number, fuelCeCoefficient: number) => {
    return httpPostJson(`${getInsightApigeeBasePath()}/api/v1/projects/settings`, {
      data: { projectId, useSiUnits, currencyIso, fuelRate, electricityRate, electricityCeCoefficient, fuelCeCoefficient }
    });
}
