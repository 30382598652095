import * as api from './api';
import { apiActionCreator, defaultErrorHandler } from '../shared';
import { loadRunsSuccess } from '../run/actions';
import { Model } from '../analysis/dashboard.models';
import { modelQueries } from '../analysis/statusVerification';
import * as fromActionTypesAnalysis from '../analysis/actionTypes';
import * as fromActionTypesEC from '../analysis/EmbodiedCarbon/actionTypes';

export const LOAD_PROJECTMODELS_STARTED = 'LOAD_PROJECTMODELS_STARTED';
export const LOAD_PROJECTMODELS_SUCCESS = 'LOAD_PROJECTMODELS_SUCCESS';
export const GET_MODEL_SUCCESS = 'GET_MODEL_SUCCESS';
export const LOAD_THUMBNAIL_SUCCESS = 'LOAD_THUMBNAIL_SUCCESS';
export const LOAD_THUMBNAILS_SUCCESS = 'LOAD_THUMBNAILS_SUCCESS';
export const UPDATED_PROJECT_MODELS_ON_DASHBOARD = 'UPDATED_PROJECT_MODELS_ON_DASHBOARD';

type modelAttr = {
  lastIndex?: number,
  lastItem?: any,
  length?: number
}

export type Models = Model & modelAttr;

export const loadProjectModelsStarted = (projectId: string) => {
  return {
    type: LOAD_PROJECTMODELS_STARTED,
    projectId
  };
}

export const loadProjectModelsSuccess = (models: Models[], projectId: string) => {
  return {
    type: LOAD_PROJECTMODELS_SUCCESS,
    models,
    projectId
  };
}

export const getModelSuccess = (model: Models) => {
  return {
    type: GET_MODEL_SUCCESS,
    model
  };
}

export const updateDashboardLmv = (modelId: string, lmvStatus: string, urn: string) => ({
  type: fromActionTypesAnalysis.UPDATE_DASHBOARD_LMV,
  modelId,
  lmvStatus,
  urn
});

export const updateEcPageLmv = (modelId: string, lmvStatus: string, urn: string) => ({
  type: fromActionTypesEC.UPDATE_EC_PAGE_LMV,
  modelId,
  lmvStatus,
  urn
});

export function forceLoadProjectModelsData(projectId: string) {
  return apiActionCreator({
    api: api.getProjectModels,
    started: loadProjectModelsStarted,
    startedParams: [projectId],
    success: loadProjectModelsSuccess,
    successParams: [projectId]
  }, projectId);
}


export const loadModel = (projectId: string, modelId: string) => (dispatch, getState) => {
  if (loadModelDataNeeded(getState(), modelId, projectId)) {
    return dispatch(forceLoadProjectModelsData(projectId));
  }
}

function loadModelDataNeeded(state: any, modelId: string, projectId: string) {
  if (state.modelState.models[modelId]) {
    return false;
  }

  if (state.modelState.modelsFromProject[projectId] &&
    (state.modelState.modelsFromProject[projectId].isFetching ||
      state.modelState.modelsFromProject[projectId].isLoaded))
    return false;

  return true;
}

export function getModel(modelId: string) {
  return apiActionCreator({
    api: api.getModel,
    success: getModelSuccess
  }, modelId);
}

export function addFactors(id: string, factors: any) {
  return apiActionCreator({
    api: api.addFactors,
    success: loadRunsSuccess,
    successResultMap: (result) => result.runs,
    error: defaultErrorHandler
  }, id, factors);
}

export const getThumbnails = (models: Models[]) => (dispatch: any, getState: any) => {
  models.forEach((model) => {
    if (model.lmvStatus === "Processing" || model.lmvStatus === "Pending") {
      queryModel(model.id, model.projectId)(dispatch, getState);
    }
  });
}

const queryModel = (modelId: string, projectId: string) => (dispatch, getState) => {
  if (modelQueries[modelId]) {
    return;
  }

  modelQueries[modelId] = {
    projectId,
    intervalId: setInterval(
      modelThumbnailQueryCallback,
      10000,
      modelId,
      dispatch
    )
  };
}

const modelThumbnailQueryCallback = async (modelId: string, dispatch: any) => {
  const model: Model = await dispatch(getModel(modelId));

  if (model.lmvStatus === 'Completed' || model.lmvStatus === 'Error') {
    clearInterval(modelQueries[modelId].intervalId);
    modelQueries[modelId] = null;
    dispatch(updateEcPageLmv(modelId, model.lmvStatus, model.urn));
    dispatch(updateDashboardLmv(modelId, model.lmvStatus, model.urn));
  }
}
