import React, {useCallback, useEffect, useState} from 'react';
import Dropdown from "@hig/dropdown";
import Label from "@hig/label";
import '../../../css/new/lmvlegend.css';
import {getViewerInstance} from './LmvEcDetails';
import {DropDownVariant, LabelVariant, LmvLegendData, LmvLegendItems, LmvLegendViewByOptions} from '../types';
import {
  clearThemingColors,
  setFullIsolation,
  setThemingColor,
  showViewerDbid,
  triggerSceneChange
} from '../../../shared/lmv/lmvHelper';
import {
  BuildLegendModel,
  colorElements, MapLMVToCategoryMap,
  MapLMVToConstructionMap,
  MapLMVToDetailMap,
} from "./LMVECModel";
import LeftToggle from '../../../img/toggle_left_default.svg';
import RightToggle from '../../../img/toggle_right_default.svg';
import i18n from '../../../i18n';
import {flattenMap} from "../utils";
import {OpeningType, SurfaceType} from "../../../consts";

const ExteriorOpenings: string = "exteriorOpenings";
const InteriorOpenings: string = "interiorOpenings";
const Others: string = "others";

export interface LmvLegendProps {
  defaultValue: LmvLegendViewByOptions;
  lmvLegendData: LmvLegendData
  variant: DropDownVariant;
  label: { text: string, variant: LabelVariant };
  modelId: string,
  viewByPreference: (modelId: string, viewBy: LmvLegendViewByOptions) => void,
  setLmvElements: (lmvLegendData: LmvLegendData) => void,
  lmvStatus: boolean,
  isExpanded?: boolean
  onLegendCollapseClick?: Function,
  selectedConstructionId: string,
  onLmvLegendConstructionClick?: Function,
}

const customStylesheet = (styles) => {
  return {
    ...styles,
    label: {
      ...styles.label,
      color: 'black',
    }
  };
}

interface LmvLegendState {
  selectedOption: LmvLegendViewByOptions
}

export const addToolTip = (event: any): void => {
  const target = event.target;
  if (!target.matches('[title]')) {
    return;
  }
  if (target.offsetWidth < target.scrollWidth) {
    target.attributes.title.value = target.innerText;
  }
}
export const removeToolTip = (event: any): void => {
  const target = event.target;
  if (!target.matches('[title]')) {
    return;
  }
  target.attributes.title.value = '';
}

const LmvViewOptions: string[] = [LmvLegendViewByOptions.Constructions, LmvLegendViewByOptions.Categories, LmvLegendViewByOptions.DetailLevel];

enum LegendType {
  Others,
  ExteriorWall,
  ExteriorOpenings,
}

type LegendItemProps = {
  color: string,
  name: string,
  isSelected?: boolean
  disabled?: boolean
  onClick?: () => void
}
const emptyFn = () => {
};
const LegendItem = React.memo<LegendItemProps>(
  ({name, color, isSelected = false, disabled = false, onClick}) => {
    const addTooltipCb = useCallback((e) => {
      const target = e.target;
      if (!target) {
        return;
      }
      if (!target.matches('[title]')) {
        return;
      }
      if (target.offsetWidth < target.scrollWidth) {
        target.attributes.title.value = target.innerText;
      }
    }, []);

    const removeTooltipCb = useCallback((e) => {
      const target = e.target;
      if (!target) {
        return;
      }
      if (!target.matches('[title]')) {
        return;
      }
      target.attributes.title.value = '';
    }, []);
    let itemClass: string = "item";
    if (isSelected) {
      itemClass += " selected";
    } else if (addTooltipCb) {
      itemClass += " default";
    }
    return (<div className={itemClass} onClick={onClick ? onClick : emptyFn}>
      <div style={{...{backgroundColor: color}}}></div>
      <h1 title={''} className={disabled ? "disabled" : ""}
          onMouseEnter={addTooltipCb ? addTooltipCb : emptyFn}
          onMouseOut={removeTooltipCb ? removeTooltipCb : emptyFn}>{name}</h1>
    </div>);
  });

export const LmvLegendContainer = React.memo((props: LmvLegendProps) => {

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<LmvLegendViewByOptions>(props.defaultValue);

  const onLegendCollapseClickCb = useCallback(() => {
    props.onLegendCollapseClick();
  }, [props.isExpanded]);

  const optionOnChangeCb = useCallback((value: LmvLegendViewByOptions) => {
    // catch empty drop down selections, can be triggered by ESC keystroke
    if (!value) return;

    if (props.lmvStatus) {
      let viewer = getViewerInstance();
      if (viewer.progressbar.lastValue === 100) {
        setSelectedOption(value);
        props.viewByPreference(props.modelId, value);
        let model = viewer.model;
        clearThemingColors(viewer);
        triggerSceneChange(viewer);
        setFullIsolation(viewer);

        if (MapLMVToConstructionMap.size === 0 ||
          MapLMVToDetailMap.size === 0 ||
          MapLMVToCategoryMap.size === 0) {
          BuildLegendModel(props.modelId);
        }
        let colorArray = colorElements(value);
        colorArray.forEach((item) => {
          item.lmvElementIds.forEach((i) => {
            showViewerDbid(viewer, i);
            setThemingColor(viewer, i, item.color, model)
          });
        });
      }
    } else {
      setSelectedOption(value);
      props.viewByPreference(props.modelId, value);
    }
  }, [props.lmvStatus, props.modelId]);

  const defaultParser = (data: LmvLegendItems[]): JSX.Element[] => {
    return data.map((item, index) => {
      let color = item.color;
      if(item.viewerElements.every(e => e.isHidden)) {
        color = i18n.t(`analysis.ec.defaultColor`);
      }
      return <LegendItem key={index} color={color} name={item.name}/>; });
  }

  const getLegendItemProps = (key: string, item: LmvLegendItems, props: LmvLegendProps): LegendItemProps => {
    return {
      color: item.color,
      name: item.name.includes(i18n.t(`analysis.ec.construction.openingSkylight`)) ? i18n.t(`analysis.ec.construction.openingSkylight`) : item.name,
      isSelected: props.selectedConstructionId === item.id,
      onClick: () => props.onLmvLegendConstructionClick(item.id)
    };
  }

  const createLegendMap = (data: LmvLegendItems[]): Map<string, JSX.Element[]> =>{
    let retValue = new Map<string, JSX.Element[]>();

    // add key/value for surfaces
    let surfaces = Object.values(SurfaceType);
    surfaces.forEach( surface => {
      if (surface !== SurfaceType.Air && data.some(x => x.surfaceType === surface))
        retValue.set(surface, [<div
          className="chart-legend-title">{i18n.t(`analysis.ec.lmvLegendLabel.${surface}`)}</div>])
    });
    // add key/value for openings
    retValue.set(ExteriorOpenings,
      [<div className="chart-legend-title">{i18n.t(`analysis.ec.lmvLegendLabel.${ExteriorOpenings}`)}</div>]);
    retValue.set(InteriorOpenings,
      [<div className="chart-legend-title">{i18n.t(`analysis.ec.lmvLegendLabel.${InteriorOpenings}`)}</div>]);
    // add key/value for Others
    retValue.set(Others, [<div
      className="chart-legend-title chart-legend-title-others">{i18n.t(`analysis.ec.lmvLegendLabel.${Others}`)}</div>]);
    retValue = new Map([...retValue].sort());

    return retValue;
  }

  const parseConstructions = (data: LmvLegendItems[]): JSX.Element[] => {
    const legendMap: Map<string, JSX.Element[]> = createLegendMap(data);
    let key: string, componentProps: LegendItemProps;
    const result = data.reduce((acc, item, index) => {
      switch (item.surfaceType){
        case SurfaceType.ExteriorWall:
        case SurfaceType.InteriorWall:
        case SurfaceType.Roof:
        case SurfaceType.InteriorFloor:
        case SurfaceType.ExposedFloor:
        case SurfaceType.Shade:
        case SurfaceType.UndergroundWall:
        case SurfaceType.UndergroundSlab:
        case SurfaceType.Ceiling:
        case SurfaceType.UndergroundCeiling:
        case SurfaceType.RaisedFloor:
        case SurfaceType.SlabOnGrade:
        case SurfaceType.FreestandingColumn:
        case SurfaceType.EmbeddedColumn:
          key = item.surfaceType;
          componentProps = getLegendItemProps(key, item, props);
          break;
        case OpeningType.OperableSkylight:
          key = ExteriorOpenings
          componentProps = getLegendItemProps(key, item, props)
          break;
        case OpeningType.FixedSkylight:
          key = ExteriorOpenings
          componentProps = getLegendItemProps(key, item, props)
          break;
        case OpeningType.OperableWindow:
        case OpeningType.FixedWindow:
        case OpeningType.SlidingDoor:
        case OpeningType.NonSlidingDoor:
          key = item.id.startsWith("aimCustomOpeningexterior") ?
            ExteriorOpenings : InteriorOpenings;
          componentProps = getLegendItemProps(key, item, props);
          break;
        default:
          key = Others;
          componentProps = {
            color: item.color,
            name: item.name
          };
          break;
      }

      acc.get(key).push(<LegendItem key={index} {...componentProps}/>);
      return acc;
    }, legendMap);

    // remove empty headers
    const emptyHeadersToRemove : string[] = [Others, ExteriorOpenings, InteriorOpenings];
    emptyHeadersToRemove.forEach( header => {
      if ( result.get(header).length < 2 ){
        result.delete(header);
      }
    });

    return flattenMap(result);
  };


  const legendTypes: Map<string, { dataField: string, parser: (data: LmvLegendItems[]) => JSX.Element[] }> = new Map([
    [LmvLegendViewByOptions.Constructions, {dataField: "constructions", parser: parseConstructions}],
    [LmvLegendViewByOptions.DetailLevel, {dataField: "detailLevels", parser: defaultParser}],
    [LmvLegendViewByOptions.Categories, {dataField: "categories", parser: defaultParser}]
  ]);

  const parse = (lmvData: LmvLegendData, constructionType: string): JSX.Element[] => {
    if (!legendTypes.has(constructionType)) {
      return;
    }
    const legendType = legendTypes.get(constructionType);
    const data = lmvData[legendType.dataField];
    if (!data) {
      return;
    }
    if (legendType.parser) {
      return legendType.parser(data);
    }
  };

  useEffect(() => {
    setIsExpanded(props.isExpanded);
  }, [props.isExpanded]);

  useEffect(() => {
    setSelectedOption(props.defaultValue);
  }, [props.defaultValue]);
  const getRenderForOption = (option: LmvLegendViewByOptions): JSX.Element | JSX.Element[] => {
    props.setLmvElements(props.lmvLegendData);
    return parse(props.lmvLegendData, option);
  };

  return <div className="lmv-legend-container">
    {isExpanded &&
      <div className={props.label.variant === LabelVariant.Side && "legend"}>
        <Label variant={props.label.variant} stylesheet={customStylesheet}>{props.label.text}</Label>
        <Dropdown
          defaultValue={selectedOption}
          value={selectedOption}
          options={LmvViewOptions}
          onChange={optionOnChangeCb}
          variant={props.variant}/>
      </div>}
    {isExpanded &&
      <div className="legend-item"> {
        getRenderForOption(selectedOption)
      }</div>}
    {!isExpanded && <div className="show-legend-label">{i18n.t("analysis.ec.charts.legend.toggleTitle")}</div>}
    <div className="lmv-legend-toggle">{isExpanded ?
      <LeftToggle className="lmv-legend-toggle-icon" onClick={onLegendCollapseClickCb}/> :
      <RightToggle className="lmv-legend-toggle-icon" onClick={onLegendCollapseClickCb}/>}
    </div>
  </div>;
});
