export const PROJECT_LIST_PAGE_CREATE = "ProjectListPageCreate";

export const PROJECT_WIDGET_SELECT = "ProjectWidgetSelect";
export const PROJECT_WIDGET_SELECTED_MODEL = "ProjectWidgetSelectedModel";
export const PROJECT_WIDGET_RENAME = "ProjectWidgetRename";
export const PROJECT_WIDGET_ANNUAL = "ProjectWidgetAnnual";
export const PROJECT_WIDGET_ADD = "ProjectWidgetAdd";
export const PROJECT_WIDGET_DELETE = "ProjectWidgetDelete";
export const PROJECT_WIDGET_DELETE_TITLE = "ProjectWidgetDeleteTitle";
export const PROJECT_WIDGET_DELETE_SUBTITLE = "ProjectWidgetDeleteSubtitle";
export const PROJECT_WIDGET_CHOOSE = "ProjectWidgetChoose";
export const PROJECT_WIDGET_SUGGESTION = "ProjectWidgetSuggestion";
export const PROJECT_WIDGET_CANCEL = "ProjectWidgetCancel";
export const PROJECT_WIDGET_UPLOAD = "ProjectWidgetUpload";

export const PROJECT_SELECTOR_DONE = "ProjectSelectorDone";
export const PROJECT_SELECTOR_MOVE = "ProjectSelectorMove";

