import React, { useCallback } from 'react';
import '../../css/serviceUnavailable.css';
import i18n from "../../i18n";
import {ServiceUnavailableSVG, ErrorEcAnalysisSVG, NoEntitlementsSVG, NoSearchResultSVG} from "./SvgComponents";
import { useNavigate } from 'react-router-dom';
import Button, { buttonVariants } from '@weave-mui/button';
import { useDispatch } from 'react-redux';
import { removeAppError } from '../../state/slice/notifications-slice';
import * as locConsts from '../localization/consts';
import { typographyVariants } from '@weave-mui/enums';
import Typography from '@weave-mui/typography';


export type ErrorInformationProps = {
  title: string;
  message: string;
  icon: JSX.Element;
  buttonTitle?: string;
  isErrorModal?: boolean;
};

export type AppUnavailableProps = {
  message?: string;
  navButtonTitle?: string;
};

const breakLineText = (styles, props, themeData) => ({
    ...styles,
    typography: {
        ...styles.typography,
        whiteSpace: 'pre-line'
    }
});



const ErrorInformation = ({ title, message, icon }: ErrorInformationProps) => {
    return (
      <div className={'serviceUnavailableWrapper'}>
        <div className={'serviceUnavailableContentWrapper'}>
          {icon}
          <Typography fontWeight={'bold'}>{title}</Typography>
          <Typography align={'center'} sx={{ whiteSpace: 'pre-line' }}>
            {message}
          </Typography>
        </div>
      </div>
    );
};

const ErrorInformationWithNavigation = ({
  title,
  message,
  icon,
  buttonTitle,
  isErrorModal
}: ErrorInformationProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projectsPath = '/projects';
  const navigateToHomePage = useCallback(() => {
    dispatch(removeAppError());
    navigate(projectsPath);
  }, []);
  return (
    <div style={{'display': 'flex', justifyContent: 'center', height: '100%' }}>
      <div style={{width: '100%', height: isErrorModal ? '100%': '100vh'}}>
        <div className={'serviceUnavailableContentWrapper'}>
          {icon}
          <Typography variant={typographyVariants.H3_BOLD}>
            {title}
          </Typography>
          <Typography align={'center'} sx={{whiteSpace: 'pre-line'}}>
            {message}
          </Typography>
          <Typography fontWeight={'bold'} align={'center'}>
            {buttonTitle !== '' && (
              <Button
                title={buttonTitle}
                onClick={navigateToHomePage}
                sx={{ marginTop: '24px' }}
                variant={buttonVariants.CONTAINED}
              >
                {buttonTitle}
              </Button>
            )}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export const DataPointsModalUnavailable = ({ message }: AppUnavailableProps) => {
  return (
    <ErrorInformationWithNavigation
      title={i18n.t('appError.errorDataModalTitle')}
      message={message}
      icon={<ServiceUnavailableSVG />}
      buttonTitle=""
      isErrorModal={true}
    />
  );
};

export const ApplicationUnavailablePage = () => {
    return (
        <ErrorInformation
        title={i18n.t('application.unavailable.title')}
        message={i18n.t('application.unavailable.message')}
        icon={<ServiceUnavailableSVG />} />
    )
};

export const ApplicationUnavailablePageWithNav = ({message}: AppUnavailableProps) => {
  return (
    <ErrorInformationWithNavigation
      title={i18n.t('application.unavailable.title')}
      message={message}
      icon={<ServiceUnavailableSVG />}
      buttonTitle={i18n.t('homeButton')}
      isErrorModal={false}
    />
  );
};
export const ErrorAnalysis = () => {
    return (
        <ErrorInformation
        title={i18n.t('analysis.error.failedAnalysisTitle')}
        message={i18n.t('analysis.error.failedAnalysisMessage')}
        icon={<ErrorEcAnalysisSVG />} />
    )
};

export const UserNoEntitlements = () => {
  return (
    <ErrorInformation
      title={i18n.t('application.noentitlements.title')}
      message={i18n.t('application.noentitlements.message', {
        productName: i18n.t(locConsts.PRODUCT_NAME),
      })}
      icon={<NoEntitlementsSVG />}
    />
  );
};
export const WrongUserPage = () => {
    return (
        <ErrorInformation
            title={i18n.t('wrongUser.noAccess.title')}
            message={i18n.t('wrongUser.noAccess.message')}
            icon={<NoEntitlementsSVG/>}/>

    )
};


export const QuickSearchNoResults = () => {
    return (
      <div className="serviceUnavailableContentWrapper wrapText">
          <Typography align='center' fontWeight='bold'>
              {i18n.t('analysis.error.quickSearchNoResultsTitle')}
          </Typography>
          <Typography align='center'>
              {i18n.t('analysis.error.quickSearchNoResultsMessage')}
          </Typography>
      </div>
    )
}

export const AdvancedSearchNoResults = () => {
    return (
        <div className='serviceUnavailableContentWrapper advancedSearchNoResults'>
            <Typography fontWeight='bold' variant='h1'>
              {i18n.t('analysis.error.advancedSearchNoResultsTitle')}
            </Typography>
            <NoSearchResultSVG />
            <Typography>
              {i18n.t('analysis.error.advancedSearchNoResultsMessage')}
            </Typography>
        </div>
    )
}

export const BenchmarksModalNoResults = () => {
  return (
    <div className='serviceUnavailableContentWrapper benchmarksNoResults'>
      <Typography variant='h1'>
        {i18n.t('analysis.dataPoints.benchmarks.noAvailableBenchmarks')}
      </Typography>
      <NoSearchResultSVG />
      <Typography>
        {i18n.t('analysis.dataPoints.benchmarks.benchmarksNotFoundMessage')}
      </Typography>
    </div>
  )
}

