import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { extractUserState } from './base-queries';
import {
  LocationClient,
  Place,
  SearchPlaceIndexForPositionCommand,
} from '@aws-sdk/client-location';
import { withAPIKey } from '@aws/amazon-location-utilities-auth-helper';

export const locationDataApi = createApi({
  reducerPath: 'locationDataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
  }),
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getLocation: builder.query<Place, { lng: number; lat: number }>({
      queryFn: async (points, api) => {
        try {
          const {lng, lat} = points;
          const {
            awsLocationSettings: { region, apiKey },
          } = extractUserState(api);
          const authHelper = await withAPIKey(apiKey);

          const client = new LocationClient({
            region,
            ...authHelper.getLocationClientConfig(), // sets up the Location client to use the Cognito pool defined above
          });

          const result = await client.send(
            new SearchPlaceIndexForPositionCommand({
              IndexName: 'InsightsLocationPlaceIndexEsri', // Place index resource to use
              Position: [lng, lat], // position to search near
              MaxResults: 1, // number of results to return,
              Language: 'en', // language to use for results
            }),
          );
          return { data: result.Results[0].Place };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const { useGetLocationQuery } = locationDataApi;
