import React, { useCallback, useEffect, useState } from 'react';
import { LayoutTab, LayoutTabsConfig } from '../types/layout';
import { dashboardToLayoutTabsConfig } from './utils/layout-setup';
import { LayoutTabs } from './Tabs/LayoutTabs';
import { useGetDashboardsQuery } from '../state/api/dashboard-api';
import { LayoutContextProvider } from './LayoutContext';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@weave-mui/box';
import SideNavigation from '../shared/SideNavigation/SideNavigation';
import { useGetUserFeatureFlagDataQuery } from '../state/api/feature-flag-data-api';
import DataPointsModalWrapper from '../dataPoints/components/shared/DataPointsModal/DataPointsModalWrapper';
import { ParametersTypesEnum } from '../types/metrics';

export const LayoutContainer: React.FC = () => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const { data: featureFlagData, isSuccess: featureFlagsSuccess } = useGetUserFeatureFlagDataQuery();

  const isSideNavigationEnabled = featureFlagData?.['enable-sidebar-navigation'] as boolean;

  const [metricsModalOpen, setMetricsModalOpen] = useState<boolean>(false);
  const [parametersModalType, setParametersModalType] = useState<ParametersTypesEnum>(ParametersTypesEnum.FACTORS);

  const [layoutTabsConfig, setLayoutTabsConfig] = useState<LayoutTabsConfig>();
  const [initialActiveTab, setInitialActiveTab] = useState<LayoutTab | null>();
  // todo: resiliency handle failures and notify user
  const { data: dashboards } = useGetDashboardsQuery();
  // temoporary mock data
  // const [dashboards] = useState<StandardDashboard[]>(getMockStandardDashboards());
  useEffect(() => {
    if (dashboards?.length) {
      const tabsConfig = dashboardToLayoutTabsConfig(dashboards);
      setLayoutTabsConfig(tabsConfig);

      let tabToBeUsedAsInitial = null;
      const tabFromPath = routeParams.dashboardId && tabsConfig.tabs.find(( {value} ) => value === routeParams.dashboardId);
      if(tabFromPath) {
        tabToBeUsedAsInitial = tabFromPath;
      }

      setInitialActiveTab( tabToBeUsedAsInitial || tabsConfig.tabs[0] );
    }
  }, [dashboards, navigate, routeParams]);

  const optionClickHandler = useCallback((optionModalType: ParametersTypesEnum) => {
    setParametersModalType(optionModalType);
    setMetricsModalOpen(true);
  }, []);


  return (
    <>
      <DataPointsModalWrapper
        isOpen={metricsModalOpen}
        modalType={parametersModalType}
        setModalVisibility={setMetricsModalOpen}
      /> 
      <Box sx={
        {
          display: 'flex',
          flexDirection: 'row'
        }
      }>
        {isSideNavigationEnabled && <SideNavigation onOptionClick={optionClickHandler}/>}
        {layoutTabsConfig && initialActiveTab && (
          <LayoutContextProvider>
            <LayoutTabs {...layoutTabsConfig} initialActiveTab={initialActiveTab} ></LayoutTabs>
          </LayoutContextProvider>
        )}
      </Box>
    </>
  );
};
