import React, { Component } from 'react';
import Highcharts, { extend } from 'highcharts/highcharts.js';
import HighchartsReact from 'highcharts-react-official';
import * as CustomHighcharts from '../../typings/CustomHighchartsTypes';
import lightGrayMediumDensityTheme from '@hig/theme-data/build/json/lightGrayMediumDensityTheme/theme.json';
import * as consts from '../consts';

export type FactorChartPropsData = {
    impact?: string,
    inRange?: boolean,
    key?: string,
    tooltipTitle?: string,
    tooltipDescription?: string,
    tooltipValue?: string,
    x?: number | string,
    y?: number,
}

export type FactorChartProps = {
    barChartIsVisible?: boolean,
    rangeLeft?: string,
    rangeRight?: string,
    isContinuousChart?: boolean,
    chartLimits?: {
        limitMinY?: number,
        limitMaxY?: number,
    },
    factorKey?: string,
    xTitle?: string,
    yTitle?: string,
    data?: FactorChartPropsData[],
    useRangeHandles?: boolean,
    factorTitle?: string,
    onRangeChange?: (widgetKey: string, left: string, right: string) => void,
}

type ClassState = {
    clickX?: number,
    clickY?: number,
    lastPath?: string[],
    dragMode?: string,
    barChartIsVisible?: boolean,
    chartOptions?: CustomHighcharts.CustomOptions
}


export default class FactorChart extends Component<FactorChartProps, ClassState> {

    themeData: { pointColor: string; fontFamily: string; fontMedium: number; toolTipBackGround: string; tooltipborderColor: string; toolTipTextColor: string; bandColor: string; bandBorderColor: string; labelsColor: string; selectorsColor: string; seriesLineColor: string; };
    selectorsArrayPos: { minXTop: number; minYTop: number; minXBottom: number; minYBottom: number; maxXBottom: number; maxYBottom: number; maxXTop: number; maxYTop: number; };
    minHandle: Highcharts.SVGElement;
    maxHandle: Highcharts.SVGElement;
    chart: CustomHighcharts.CustomChart;
    minValuePlotBand: number;

    constructor(props: FactorChartProps) {
        super(props);

        this.afterRender = this.afterRender.bind(this);
        this.step = this.step.bind(this);
        this.step = this.step.bind(this);
        this.stop = this.stop.bind(this);
        this.barChart = this.barChart.bind(this);
        this.state = {
            lastPath: [],
            dragMode: "none",
            barChartIsVisible: props.barChartIsVisible
        };

        this.themeData = {
            pointColor: lightGrayMediumDensityTheme.resolvedRoles["basics.colors.autodeskBlue500"],
            fontFamily: lightGrayMediumDensityTheme.resolvedRoles["basics.fontFamilies.main"],
            fontMedium: lightGrayMediumDensityTheme.resolvedRoles["basics.fontWeights.medium"],
            toolTipBackGround: lightGrayMediumDensityTheme.resolvedRoles["tooltip.backgroundColor"],
            tooltipborderColor: lightGrayMediumDensityTheme.resolvedRoles["tooltip.borderColor"],
            toolTipTextColor: lightGrayMediumDensityTheme.resolvedRoles["tooltip.textColor"],
            bandColor: lightGrayMediumDensityTheme.resolvedRoles["basics.colors.autodeskBlue100"],
            bandBorderColor: lightGrayMediumDensityTheme.resolvedRoles["basics.colors.autodeskBlue500"],
            labelsColor: lightGrayMediumDensityTheme.resolvedRoles["basics.colors.charcoal900"],
            selectorsColor: lightGrayMediumDensityTheme.resolvedRoles["basics.colors.charcoal600"],
            seriesLineColor: lightGrayMediumDensityTheme.resolvedRoles["basics.colors.iconDarkBlueColor"]
        };

        this.selectorsArrayPos = {
            minXTop: 1,
            minYTop: 2,
            minXBottom: 4,
            minYBottom: 5,
            maxXBottom: 7,
            maxYBottom: 8,
            maxXTop: 10,
            maxYTop: 11
        };

        this.minHandle = null;
        this.maxHandle = null;
        this.chart = null;
        this.minValuePlotBand = null;
    }

    componentDidMount() {

        this.renderChart();
    }

    componentDidUpdate(prevProps: FactorChartProps, prevState: ClassState) {

        if (this.props.rangeLeft != prevProps.rangeLeft || this.props.rangeRight != prevProps.rangeRight) {
            this.setState({
                chartOptions: {
                    series: [{                        
                        data: this.getPoints(),                        
                    }] as CustomHighcharts.CustomSeriesOptionsType[],                    
                    xAxis: {
                        plotBands: this.props.isContinuousChart ? this.createContinuousPlotLabels() : null
                    }
                }
            });
        }

        if (this.props.data === prevProps.data && (this.props.chartLimits.limitMinY !== prevProps.chartLimits.limitMinY || this.props.chartLimits.limitMaxY !== prevProps.chartLimits.limitMaxY)) {
            this.updateChartLimits();
        }

        if (prevState.barChartIsVisible !== this.state.barChartIsVisible) {
            this.updateChartType();
        }

        if (this.props.data !== prevProps.data) {
            this.setState({
                chartOptions: {
                    series: [{
                        data: this.getPoints(),
                    }] as CustomHighcharts.CustomSeriesOptionsType[],
                    xAxis: {
                        plotBands: this.props.isContinuousChart ? this.createContinuousPlotLabels() : null
                    },
                    yAxis: {
                        min: this.props.chartLimits.limitMinY,
                        max: this.props.chartLimits.limitMaxY,
                        plotLines: this.createPlotLine()
                    }
                }
            });
        }

    }

    renderChart() {
        this.setState({
            chartOptions: {
                rangeSelector: {
                    enabled: false
                },
                chart: {
                    height: '80%',
                    events: {
                        redraw: function () {
                            const chart = this.chart;

                            if (this.props.useRangeHandles) {
                                this.createPlotLabels();
                                this.createBandEvent(chart);
                                this.updateHandlePosition();
                            }
                            this.renderBorderBarChart(chart);
                            this.createXAxisColor(chart);

                        }.bind(this)
                    },
                    inverted: false,
                    backgroundColor: 'none',
                    plotBorderWidth: 1,
                    plotBorderColor: 'none',
                    style: {
                        fontSize: '100%'
                    },
                    spacing: [10, 10, 10, 0]
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: null,
                },
                xAxis: {
                    width: '100%',
                    top: undefined,
                    height: undefined,
                    categories: [],
                    tickmarkPlacement: this.props.isContinuousChart ? 'on' : 'between',
                    labels: {
                        enabled: this.props.isContinuousChart,
                        y: 15,
                        rotation: 0

                    },
                    title: {
                        text: this.props.xTitle,
                        offset: 15,
                        style: {
                            color: this.themeData.labelsColor,
                            fontSize: '12px',
                            fontFamily: this.themeData.fontFamily
                        },
                        y: this.props.isContinuousChart && 5
                    },
                    lineColor: 'white',
                    plotBands: this.props.isContinuousChart ? this.createContinuousPlotLabels() : null,
                    min: this.props.isContinuousChart ? this.getMinValueX() : null,
                    max: this.props.isContinuousChart ? this.getMaxValueX() : null,
                    tickInterval: this.props.isContinuousChart && this.getTickIntervalXaxis()
                },
                yAxis: {
                    showLastLabel: false,
                    gridLineWidth: 0,
                    opposite: false,
                    title: {
                        text: this.props.yTitle,
                        align: 'middle',
                        style: {
                            color: this.themeData.labelsColor,
                            fontSize: '12px',
                            fontFamily: this.themeData.fontFamily
                        }
                    },
                    labels: {
                        style: {
                            color: this.themeData.labelsColor,
                            fontFamily: this.themeData.fontFamily,
                            fontSize: '12px'
                        },
                        x: -10
                    },
                    plotLines: this.createPlotLine(),
                    min: this.props.chartLimits.limitMinY,
                    max: this.props.chartLimits.limitMaxY,
                    tickAmount: 6
                },
                tooltip: {
                    formatter: function () {
                        var point = this.point as CustomHighcharts.CustomPoint;
                        return '<div class="factor-tooltip-value mt-1">' + point.tooltipDescription + '<div>' +
                            '<div class="mt-1">' +
                            '<span class="factor-tooltip-value">' + point.tooltipValue + '</span>' +
                            '<div>';
                    },
                    useHTML: true,
                    valueDecimals: 2,
                    backgroundColor: this.themeData.toolTipBackGround,
                    borderColor: this.themeData.tooltipborderColor,
                    borderWidth: 1,
                    borderRadius: 2,
                    shadow: false,
                    style: {
                        width: 220,
                        color: this.themeData.toolTipTextColor,
                        fontFamily: this.themeData.fontFamily
                    }
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        pointWidth: 12,
                        borderColor: 'none',
                        pointPlacement: this.props.isContinuousChart && 'on'
                    }
                } as CustomHighcharts.CustomPlotOptions,
                series: [{
                    color: "rgba(162,166,176,0.6)",
                    lineWidth: 1,
                    type: this.state.barChartIsVisible ? 'column' : 'spline',
                    animation: true,
                    data: this.getPoints(),
                    states: { hover: { enabled: false } },
                    marker: { enabledThreshold: 0 }
                }] as CustomHighcharts.CustomSeriesOptionsType[],
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                }
            }
        });
    }

    getPoints = (): CustomHighcharts.CustomPointOptionsObject[] => {

        let points: CustomHighcharts.CustomPointOptionsObject[];
        if (this.props.data) {
            points = this.props.data.map((pointData, index) => {

                var color = this.getPointColor(pointData);

                let point: CustomHighcharts.CustomPointOptionsObject = {
                    impact: pointData.impact,
                    inRange: pointData.inRange,
                    key: pointData.key,
                    tooltipDescription: pointData.tooltipDescription,
                    tooltipTitle: pointData.tooltipTitle,
                    tooltipValue: pointData.tooltipValue,
                    xValue: typeof pointData.x === "number" ? pointData.x.toString() : pointData.x,
                    y: pointData.y,
                    isBaseRun: (pointData.key === consts.BASE),
                    color: color,
                    xAxisColor: this.getPointColor(pointData, true)
                };

                if (this.props.isContinuousChart) {
                    //TODO:x should be initialized first
                    //// @ts-ignore
                    point.x = typeof pointData.x === "number" ? pointData.x : parseFloat(pointData.x);
                }

                if (point.isBaseRun) {                
                    //// @ts-ignore                    
                    point.marker = {
                        lineWidth: 2,
                        radius: 7,
                        fillColor: color,
                        lineColor: {
                            radialGradient: {
                                cx: 0.5,
                                cy: 0.5,
                                r: 0.8
                            },
                            stops: [
                                [0, color],
                                [0.6, 'white'],
                                [0.7, 'black'],
                                [0.9, 'black'],
                                [1, 'black']
                            ]
                        }
                    };
                } else {
                    //// @ts-ignore
                    point.marker = {
                        radius: 7,
                        fillColor: color,
                        lineWidth: 1,
                        lineColor: 'none'
                    };
                }

                return point;
            });
        }
        return points;
    }

    getPointColor = (point: { inRange?: boolean, impact?: string }, isAxisColor?: boolean): string => {
        let pointColor = null;

        if (point.inRange || isAxisColor) {
            if (point.impact === "high") {
                pointColor = '#ED4A41';
            } else if (point.impact === "medium") {
                pointColor = '#FAA21B';
            } else if (point.impact === "low") {
                pointColor = "#87B340";
            }
        } else {
            pointColor = '#DFDFDF';
        }

        return pointColor;
    }

    getMinValueX = (): number => {
        if (this.props.data) {
            let xValues = this.props.data.map((value) => value.x as number);
            return (Math.min(...xValues) - (this.minValuePlotBand));
        }
    }

    getMaxValueX = (): number => {
        if (this.props.data) {
            let xValues = this.props.data.map((value) => value.x as number);
            return Math.max(...xValues) + this.minValuePlotBand;
        }
    }

    getTickIntervalXaxis = (): number => {
        if (this.props.data) {
            let xValues = this.props.data.map((value) => value.x as number);
            const avg = xValues.reduce((a, b) => (a + b)) / xValues.length;

            if (avg < 10) {
                return 0.2;
            } else if (avg > 10 && avg < 100) {
                return 10;
            }

            return 100;
        }
    }

    createPlotLine = (): Highcharts.YAxisPlotLinesOptions[] => {

        if (this.props.data && this.props.data.some((item) => (item.key === consts.BASE))) {
            return [{
                color: 'rgba(60, 60, 60, 0.5)',
                width: 1,
                value: this.props.data.find((item) => (item.key === consts.BASE)).y,
                dashStyle: 'Dash',
            }]
        } else
            return null;
    }

    getPlotBandsContinuousChart = (points: FactorChartPropsData[]): number => {

        let minValues: number[] = [];

        points.forEach((element, index: number) => {

            if (index < points.length - 1) {
                const difference = ((points[index + 1].x as number) - (points[index].x as number)) / 2;

                if (difference > 0) {
                    minValues.push(difference);
                }
            }
        })

        minValues.sort((a, b) => a - b)

        const minValue = minValues[0] < minValues[1] / 3 ? (minValues[0] + minValues[1]) / 2 : minValues[0];

        this.minValuePlotBand = minValue;

        return minValue;
    }

    createContinuousPlotLabels = (): Highcharts.XAxisPlotBandsOptions[] => {

        const { data, isContinuousChart } = this.props;

        let labels: Highcharts.XAxisPlotBandsOptions[] = [];

        if (data) {

            if (isContinuousChart) {

                const minValue = this.getPlotBandsContinuousChart(data);

                data.forEach((element, index) => {
                    labels.push({
                        color: 'rgba(255,255,255,0)',
                        from: element.x as number - minValue,
                        to: element.x as number + minValue,
                        zIndex: -50,
                        className: 'custom-plot-band'
                    });
                });

                labels.push(this.createRangeBand());
            }
        }

        return labels;
    }

    createPlotLabels = () => {

        if (!this.props.isContinuousChart) {

            if (this.chart && this.chart.xAxis[0].plotLinesAndBands.length === 0) {

                const middleY = this.chart.xAxis[0].height / 2;


                this.chart.series[0].data.forEach((element, index) => {
                    this.chart.xAxis[0].addPlotBand({
                        color: 'rgba(255,255,255,0)',
                        from: -0.41 + index,
                        to: 0.41 + index,
                        zIndex: 1,
                        className: 'custom-plot-band',
                        label: {
                            rotation: -90,
                            align: 'center',
                            text: element.xValue,
                            textAlign: this.state.barChartIsVisible ? 'right' : (element.plotY >= middleY) ? 'left' : 'right',
                            ... !this.state.barChartIsVisible && { y: (element.plotY >= middleY) ? element.plotY - 10 : element.plotY + 10 },
                            style: {
                                fontWeight: (element.key === consts.BASE) && '700',
                                fontFamily: this.themeData.fontFamily,
                                color: this.themeData.labelsColor,
                                fontSize: '11px'
                            }
                        }
                    });
                });

                this.chart.xAxis[0].addPlotBand(this.createRangeBand());
            }
        }
    }

    updateHandlePosition = () => {

        const path = (this.chart.xAxis[0].plotLinesAndBands.find((plotBand) => plotBand.id === "rangeBand")).svgElem.d.split(' ');

        this.minHandle && this.translateHandle(this.minHandle, path[this.selectorsArrayPos.minXBottom], path[this.selectorsArrayPos.minYBottom]);
        this.maxHandle && this.translateHandle(this.maxHandle, path[this.selectorsArrayPos.maxXBottom], path[this.selectorsArrayPos.maxYBottom]);
    }

    start = (e: MouseEvent, forceDragMode: string) => {

        // On mouse down (start drag).
        document.addEventListener('mousemove', this.step);
        document.addEventListener('mouseup', this.stop);

        this.setState({ clickX: e.pageX, dragMode: forceDragMode, clickY: e.pageY });

        // calculate drag mode
        this.setState({ lastPath: (this.chart.xAxis[0].plotLinesAndBands.find((plotBand) => plotBand.id === "rangeBand")).svgElem.d.split(' ') });
    }

    stop = () => {
        document.removeEventListener('mousemove', this.step);
        document.removeEventListener('mouseup', this.stop);

        this.setState({ dragMode: 'none' });

        this.getSelectedPoints();
    }

    step = (e: MouseEvent) => {

        this.chart.tooltip.hide(0);

        var minBorder = null,
            maxBorder = null;
        if (this.state.dragMode == 'move') {
            minBorder = this.getMinHandlePosition(e);
            maxBorder = this.getMaxHandlePosition(e);
        } else if (this.state.dragMode == 'min') {
            minBorder = this.getMinHandlePosition(e);
        } else if (this.state.dragMode == 'max') {
            maxBorder = this.getMaxHandlePosition(e);
        }

        this.updateBandPosition(minBorder, maxBorder);

        this.updateHandlePosition();
    }

    getMinHandlePosition = (e: MouseEvent): number => {
        return parseFloat(this.state.lastPath[this.selectorsArrayPos.minXBottom]) - this.state.clickX + e.pageX;
    }

    getMaxHandlePosition = (e: MouseEvent): number => {
        return parseFloat(this.state.lastPath[this.selectorsArrayPos.maxXBottom]) - this.state.clickX + e.pageX;
    }

    updateBandPosition = (minBorder: number, maxBorder: number) => {

        const path = (this.chart.xAxis[0].plotLinesAndBands.find((plotBand) => plotBand.id === "rangeBand")).svgElem.d.split(' ');

        // Restrict borders before apply & apply new positions to svg.
        if (minBorder !== null) {
            minBorder = this.restrictMinBorder(minBorder, path);

            path[this.selectorsArrayPos.minXBottom] = minBorder;
            path[this.selectorsArrayPos.minXTop] = minBorder;

        }
        if (maxBorder !== null) {
            maxBorder = this.restrictMaxBorder(maxBorder, path);

            path[this.selectorsArrayPos.maxXBottom] = maxBorder;
            path[this.selectorsArrayPos.maxXTop] = maxBorder;

        }
        (this.chart.xAxis[0].plotLinesAndBands.find((plotBand) => plotBand.id === "rangeBand")).svgElem.attr('d', path.join(' '));
    }

    restrictMaxBorder = (maxBorder: number, path: string[]): number => {

        let distanceBetweenPoints = this.getDistanceBetweenPoints(),
            halfDistance = distanceBetweenPoints / 2;

        if (maxBorder < parseInt(path[this.selectorsArrayPos.minXBottom], 10) + distanceBetweenPoints) {
            maxBorder = parseInt(path[this.selectorsArrayPos.minXBottom], 10) + distanceBetweenPoints;
        }

        let right = this.chart.xAxis[0].left + this.chart.series[0].data[this.chart.series[0].data.length - 1].plotX + halfDistance;

        if (right < maxBorder) {
            maxBorder = right;
        }

        return Math.round(maxBorder);
    }

    restrictMinBorder = (minBorder: number, path: string[]): number => {
        var distanceBetweenPoints = this.getDistanceBetweenPoints(),
            halfDistance = distanceBetweenPoints / 2;

        if (minBorder > parseInt(path[this.selectorsArrayPos.maxXBottom], 10) - distanceBetweenPoints) {
            minBorder = parseInt(path[this.selectorsArrayPos.maxXBottom], 10) - distanceBetweenPoints;
        }

        let left = this.chart.xAxis[0].left + this.chart.series[0].data[0].plotX - halfDistance;

        if (left > minBorder) {
            minBorder = left;
        }

        return Math.round(minBorder);
    }

    getDistanceBetweenPoints(): number {
        if (this.props.isContinuousChart) {
            return (this.chart.series[0].data[0]).plotX * 2;
        }

        return Math.abs((this.chart.series[0].data[1]).plotX - (this.chart.series[0].data[0]).plotX);
    }

    getSelectedPoints = () => {

        let path = (this.chart.xAxis[0].plotLinesAndBands.find((plotBand) => plotBand.id === "rangeBand")).svgElem.d.split(' '),
            minBorder = (path[this.selectorsArrayPos.minXBottom] - this.chart.xAxis[0].left),
            maxBorder = (path[this.selectorsArrayPos.maxXBottom] - this.chart.xAxis[0].left);

        this.chart.series[0].data.forEach((item, index) => {
            item.inRange = (item.plotX > minBorder && item.plotX < maxBorder);
        });

        let inRange = this.chart.series[0].data.filter((x) => x.inRange);

        if (inRange.length === 0) {
            this.chart.series[0].data.forEach((item, index) => {
                item.inRange = true;
            });

            inRange = this.chart.series[0].data.filter((x) => x.inRange);
        }

        const left = inRange[0].key;
        const right = inRange[inRange.length - 1].key;
        this.props.onRangeChange(this.props.factorKey, left, right);
    }

    createHandleRange = (x: string, y: string, dragMode: string, callback: (e: MouseEvent, dragMode: string) => void, chart: CustomHighcharts.CustomChart): Highcharts.SVGElement => {

        let g = chart.renderer.g().add();
        g.toFront();
        g.css({
            'cursor': 'pointer',

        }).attr({
            zIndex: 10
        }).on('mousedown', function (e) {
            if (callback !== undefined && callback !== null) {
                callback(e, dragMode);
            }
        });

        //var handlePath = 'M5.5,2.5v-10l-6,-5.5l-6,5.5v10z';        
        // var handlePath = "M5.5,2.5v-9.5l-4.7,-6l-4.7,6v9.5z";

        let handlePath: Highcharts.SVGPathArray = [
            ['M', 5.5, 2.5],
            ['v', - 9.5],
            ['l', - 4.7, -6],
            ['l', - 4.7, 6],
            ['v', 9.5],
            ['z']
        ];

        var p = chart.renderer.path(handlePath).add(g);

        p.attr({
            fill: this.themeData.selectorsColor,

            zIndex: 8
        });

        this.translateHandle(p, x, y);

        return p;
    }

    translateHandle = (handle: Highcharts.SVGElement, paramx: string, paramy: string) => {
        let y = parseInt(paramy) + 6;
        let x = parseInt(paramx) - 1;
        let attrs = `translate(${x},${y}) ${''}`;
        handle.attr({
            'transform': attrs
        });
    }

    createBandEvent = (chart: CustomHighcharts.CustomChart) => {
        const rangeBand = chart.xAxis[0].plotLinesAndBands.find((plotBand) => plotBand.id === "rangeBand");

        (rangeBand).svgElem.css({
            'cursor': 'all-scroll'
        }).translate(0, 0).on('mousedown', (event) => this.start(event, 'move'));

    }

    createRangeBand = (): Highcharts.AxisPlotBandsOptions => {

        let posIniBand: number, posEndBand: number;

        if (this.props.isContinuousChart) {
            let values = [];
            this.props.data.forEach((item, index) => {
                if (item.inRange == undefined || item.inRange) {
                    values.push(item.x)
                }
            });

            posIniBand = Math.min(...values) - this.minValuePlotBand;
            posEndBand = Math.max(...values) + this.minValuePlotBand;
        }
        else {
            this.props.data.forEach((item, index) => {
                if (item.inRange == undefined || item.inRange) {
                    posIniBand = (posIniBand == undefined ? index - 0.5 : posIniBand);
                    posEndBand = index + 0.5;
                }
            });
        }

        return {
            id: "rangeBand",
            zIndex: 1,
            from: posIniBand,
            to: posEndBand,
            borderWidth: 1,
            borderColor: 'rgba(6, 150, 215, 0.5)',
            color: 'rgba(205, 234, 247, 0.6)',
            className: 'range-selector'
        };
    }

    afterRender = (chart: CustomHighcharts.CustomChart) => {
        this.chart = chart;
        if (this.props.useRangeHandles) {
            this.createPlotLabels();
            this.createBandEvent(chart);
            this.createHandles(chart);
            this.createXAxisColor(chart);
        }
    };

    createHandles = (chart: CustomHighcharts.CustomChart) => {

        let path: string[] = (chart.xAxis[0].plotLinesAndBands.find((plotBand) => plotBand.id === "rangeBand")).svgElem.d.split(' ');
        let callback: (e: MouseEvent) => void;
        let x: string;
        let y: string;

        //create min
        callback = (e: MouseEvent) => { this.start(e, 'min'); };
        x = path[this.selectorsArrayPos.minXBottom];
        y = path[this.selectorsArrayPos.minYBottom];
        this.minHandle = this.createHandleRange(x, y, 'min', callback, chart);
        //create max
        callback = (e: MouseEvent) => { this.start(e, 'max'); };
        x = path[this.selectorsArrayPos.maxXBottom];
        y = path[this.selectorsArrayPos.maxYBottom];
        this.maxHandle = this.createHandleRange(x, y, 'max', callback, chart);
    }

    createXAxisColor = (chart: CustomHighcharts.CustomChart) => {
        const isBarChart = this.state.barChartIsVisible;
        const isContinuousChart = this.props.isContinuousChart;

        var height = 3,
            offsetWidth = isContinuousChart ? (chart.xAxis[0].transA / chart.xAxis[0].plotLinesAndBands.length) / 1.80 : chart.xAxis[0].minPixelPadding / 1.80;
        let element: Highcharts.SVGElement;

        if (chart.xAxisElements) {
            chart.xAxisElements.forEach(function (elem) {
                elem.destroy();
            });
            chart.xAxisElements = null;
        }

        chart.xAxisElements = [];
                
        chart.series[0].points.forEach(function (point: CustomHighcharts.CustomPoint) {
            if (point.shapeArgs || point.graphic) {

                var pointWidth = isBarChart ? point.shapeArgs.width : point.graphic.width - 2;
                element = chart.renderer
                    .rect(
                        isContinuousChart ? point.plotX + chart.plotLeft - pointWidth : point.plotX + chart.plotLeft - pointWidth / 2 - offsetWidth,
                        chart.plotHeight + chart.plotTop,
                        isContinuousChart ? pointWidth * 2 : pointWidth + 2 * offsetWidth,
                        height
                    )
                    .attr({
                        fill: point.xAxisColor,
                        zIndex: 1
                    }).add();

                chart.xAxisElements.push(element);
            }
        });
    }

    barChart() {
        this.setState({ barChartIsVisible: !this.state.barChartIsVisible });
    }

    updateChartType() {
        if (this.props.useRangeHandles) {
            this.setState(
                {
                    chartOptions: {
                        series: [{
                            type: this.state.barChartIsVisible ? "column" : "spline"
                        }],
                        xAxis: {
                            plotBands: this.props.isContinuousChart ? this.createContinuousPlotLabels() : null
                        }
                    }
                }
            );
        }
        else {
            this.renderChart();
        }
    }

    renderBorderBarChart = (chart: CustomHighcharts.CustomChart) => {

        if (chart.customElements) {
            chart.customElements.forEach(function (elem) {
                elem.destroy();
            });
            chart.customElements = null;
        }
        chart.customElements = [];

        if (this.state.barChartIsVisible) {
            let bPoint: CustomHighcharts.CustomPoint;
            let border = 1;
            let baseRunElement: Highcharts.SVGElement;

            this.chart.series[0].points.forEach((item: CustomHighcharts.CustomPoint, index) => {
                if (item.isBaseRun) {
                    bPoint = item;
                    bPoint.borderColor = '#FFFFFF';
                }
            });

            baseRunElement = chart.renderer.rect(
                bPoint.plotX + chart.plotLeft - .50 - bPoint.pointWidth / 2,
                chart.plotTop + chart.plotHeight - bPoint.shapeArgs.height,
                bPoint.pointWidth + 1,
                bPoint.shapeArgs.height - border - .75
            ).attr({
                'stroke-width': 1.25 + border,
                stroke: '#303030',
                zIndex: 1
            }).add();
            chart.customElements.push(baseRunElement);
        }
    }

    updateChartLimits = () => {
        if (this.props.useRangeHandles) {
            this.setState(
                {
                    chartOptions: {
                        yAxis: {
                            min: this.props.chartLimits.limitMinY,
                            max: this.props.chartLimits.limitMaxY
                        }
                    }
                });
        }
    }

    render() {
        return <div className="factor-chart-s factor-chart-m factor-chart-lg">
            <div className="chart-widget">
                <div className="chart-widget-title-container">
                    <span className="chart-widget-title">{this.props.factorTitle}</span>
                    {!this.props.isContinuousChart &&
                        <button className={this.state.barChartIsVisible ? "chart-button curvechart-button" : "chart-button barchart-button"} onClick={this.barChart} ></button>
                    }
                </div>
                <div className="chart-widget-chart-container">
                    <HighchartsReact highcharts={Highcharts} options={this.state.chartOptions} callback={this.afterRender} />
                </div>
            </div>
        </div>
    }
}
