import React from 'react';
import { CardComponentProps } from '@adsk/wildcard';
import { BaseCard } from '../cards/base/BaseCard';

export const BaseCardWrapperComponent = (CardContentComponent: React.FC) => {
  const Wrapper = (props: CardComponentProps) => {
    return (
      <BaseCard {...props}>
        <CardContentComponent />
      </BaseCard>
    );
  };
  return Wrapper;
};
