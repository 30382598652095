import React, { useRef } from 'react';
import i18n from '../../../i18n';
import Stack from '@weave-mui/stack';
import { TrashS, EditS } from '@weave-mui/icons-weave';
import { CardHeaderActionButton } from './CardHeaderActionButton';

export const CardHeaderActions = (props) => {
  const { removeCard, editCard, cardSupportsEditMode } = props;
  const anchorElemRef = useRef();  
  const deleteLabel = i18n.t('cardConfig.menuItems.delete');
  const editLabel = i18n.t('cardConfig.editCard');  

  let headerControls: {
    tooltipText: string;
    icon: React.ReactNode;
    onClick: any;
  }[] = [];

  if (cardSupportsEditMode) {
    // maintain correct order of header controls
    headerControls = [
      {
        tooltipText: editLabel,
        icon: <EditS />,
        onClick: editCard,
      },
      ...headerControls,
    ];
  }

  return (
    <>
      <Stack direction="row">
        {headerControls.map((item) => (
          <CardHeaderActionButton {...item} />
        ))}
        <CardHeaderActionButton
          tooltipText={deleteLabel}
          icon={<TrashS />}
          onClick={removeCard}
          ref={anchorElemRef}
        />
      </Stack>
    </>
  );
};
