import React, { useEffect, useState, useRef } from 'react';
import Button, { buttonVariants } from '@weave-mui/button';
import Box from '@weave-mui/box';
import Checkbox from '@weave-mui/checkbox';
import FormLabel from '@weave-mui/form-label';
import Select, { selectVariants } from '@weave-mui/select';
import MenuItem from '@weave-mui/menu-item';
import TextField, { textFieldErrorTypes, textFieldVariants } from '@weave-mui/text-field';
import Typography, { typographyVariants } from '@weave-mui/typography';
import TextLink from '@hig/text-link';
import { EditS, ErrorS, TrashS} from '@weave-mui/icons-weave';
import '../../../css/new/addECDefinition.css';
import {
  buttonStyle,
  formLabelPaddingStyles,
  maximumCharactersErrorStyles,
  maximumCharactersNotesErrorStyles,
  errorIconStyles,
  textLinkStylesheet,
} from './ECDefinition.stylesheet';
import i18n from '../../../i18n';
import {
  COEFFICIENT_MAX_VALUE,
  COEFFICIENT_MIN_VALUE,
  isValidURL,
  LABEL_TEXT_INPUT_CHARACTER_LIMIT,
  SOURCE_LINK_CHARACTERS_LIMIT,
  TEXT_INPUT_CHARACTERS_LIMIT,
  TEXTAREA_INPUT_CHARACTERS_LIMIT,
} from './helper';

import {
  IAddLabelInputProps,
  IButtonsComponentProps,
  ICoefficientInputProps,
  IDeleteComponentProps,
  IEditComponentProps,
  ILabelsInputProps,
  INameInputProps,
  INotesInputProps,
  ISourceInputProps,
  ISourceComponentProps
} from './addECDefinition.types';
import { useDispatch } from 'react-redux';
import { clearNewEDefinitionIdAction } from '../actions';
import { FormControl } from '@weave-mui/form-control';
import IconButton from '@weave-mui/icon-button';
import { footerButtonStyle } from '../../../layout/LayoutGrid.stylesheet';

const { CAPTION_REGULAR, BODY_REGULAR } = typographyVariants;
const { OUTLINED } = textFieldVariants;
const { OUTLINED: OUTLINED_BUTTON } = buttonVariants;
const { FILLED: FILLED_SELECT } = selectVariants;

export const NameInput = ({ name, onChange, maximumCharactersExceeded }: INameInputProps) => {
  return (
    <div className={'nameInputContainer'}>
      <FormLabel sx={{ ...formLabelPaddingStyles }}>
        {i18n.t('analysis.ec.ecDefinition.components.labels.name')}
      </FormLabel>
      <TextField
        placeholder={i18n.t('analysis.ec.ecDefinition.components.placeholders.name')}
        value={name}
        onChange={onChange}
        error={Boolean(maximumCharactersExceeded)}
        helperText={
          maximumCharactersExceeded &&
          `${i18n.t('analysis.ec.ecDefinition.components.maximumCharactersErrorMessage')} ${
            name.length - TEXT_INPUT_CHARACTERS_LIMIT
          }`
        }
        fullWidth
      />
    </div>
  );
};

export const CoefficientInput = ({
  coefficient,
  unit,
  valueOutOfRange,
  onCoefficientChange,
  onUnitChange,
}: ICoefficientInputProps) => {
  const coefficientOptions: { value: string; name: string }[] = [
    {
      value: i18n.t('analysis.ec.ecDefinition.coefficients.m2BE'),
      name: i18n.t('analysis.ec.ecDefinition.coefficients.m2'),
    },
    {
      value: i18n.t('analysis.ec.ecDefinition.coefficients.ft2BE'),
      name: i18n.t('analysis.ec.ecDefinition.coefficients.ft2'),
    },
    {
      value: i18n.t('analysis.ec.ecDefinition.coefficients.m3BE'),
      name: i18n.t('analysis.ec.ecDefinition.coefficients.m3'),
    },
    {
      value: i18n.t('analysis.ec.ecDefinition.coefficients.ft3BE'),
      name: i18n.t('analysis.ec.ecDefinition.coefficients.ft3'),
    },
    {
      value: i18n.t('analysis.ec.ecDefinition.coefficients.kg'),
      name: i18n.t('analysis.ec.ecDefinition.coefficients.kg'),
    },
    {
      value: i18n.t('analysis.ec.ecDefinition.coefficients.lb'),
      name: i18n.t('analysis.ec.ecDefinition.coefficients.lbs'),
    },
    {
      value: i18n.t('analysis.ec.ecDefinition.coefficients.m2RsiBE'),
      name: i18n.t('analysis.ec.ecDefinition.coefficients.m2Rsi'),
    },
    {
      value: i18n.t('analysis.ec.ecDefinition.coefficients.ft2RBE'),
      name: i18n.t('analysis.ec.ecDefinition.coefficients.ft2R'),
    },
    {
      value: i18n.t('analysis.ec.ecDefinition.coefficients.item'),
      name: i18n.t('analysis.ec.ecDefinition.coefficients.item'),
    },
  ];

  const handleChange = (e) => {
    const target = e.target;
    const option = coefficientOptions.find((option) => option.value === target.value);
    onUnitChange(option);
  };

  return (
    <Box className="coefficientInputContainer">
      <FormLabel sx={{ ...formLabelPaddingStyles }}>
        {i18n.t('analysis.ec.ecDefinition.components.labels.coefficient')}
      </FormLabel>
      <Box className={'coefficientContainer'}>
        <FormControl className={'coefficientInputItems'}>
          <TextField
            value={coefficient}
            onChange={onCoefficientChange}
            placeholder={i18n.t('analysis.ec.ecDefinition.components.placeholders.coefficient')}
            error={Boolean(valueOutOfRange)}
            fullWidth
          />
          <div className={'coefficientVerticalLine'} />
          <Select
            variant={FILLED_SELECT}
            value={unit.value}
            onChange={handleChange}
            displayEmpty
            placeholder={i18n.t('analysis.ec.ecDefinition.components.placeholders.unit')}
            sx={{ width: '100%' }}
            MenuProps={{
              style: {
                zIndex: 10000,
              },
            }}
          >
            {coefficientOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                style={{ textTransform: 'initial' }}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={'errorMessageAndIcon'}>
          {valueOutOfRange && <ErrorS sx={{ ...errorIconStyles }} />}
          {valueOutOfRange && (
            <Typography variant={CAPTION_REGULAR} sx={{ ...maximumCharactersErrorStyles }}>
              {i18n.t('analysis.error.coefficientValueRangeErrorMessage', {
                minValue: COEFFICIENT_MIN_VALUE,
                maxValue: COEFFICIENT_MAX_VALUE,
              })}
            </Typography>
          )}
        </div>
      </Box>
    </Box>
  );
};

export const SourceInput = ({ source, onChange, maximumCharactersExceeded }: ISourceInputProps) => {
  return (
    <div className={'sourceInputContainer'}>
      <FormLabel sx={{ ...formLabelPaddingStyles }}>
        {i18n.t('analysis.ec.ecDefinition.components.labels.source')}
      </FormLabel>
      <TextField
        placeholder={i18n.t('analysis.ec.ecDefinition.components.placeholders.source')}
        value={source}
        onChange={onChange}
        error={Boolean(maximumCharactersExceeded)}
        helperText={maximumCharactersExceeded && `${i18n.t(
          'analysis.ec.ecDefinition.components.maximumCharactersErrorMessage'
        )} ${source.length - SOURCE_LINK_CHARACTERS_LIMIT}`}
        fullWidth
      />
    </div>
  );
};

export const NotesInput = ({ notes, onChange, maximumCharactersExceeded }: INotesInputProps) => {
  return (
    <div className={'notesInputContainer'}>
      <FormLabel className="defaultLabel" sx={{ ...formLabelPaddingStyles }}>
        {i18n.t('analysis.ec.ecDefinition.components.labels.notes')}
      </FormLabel>
      <TextField
        placeholder={i18n.t('analysis.ec.ecDefinition.components.placeholders.notes')}
        value={notes}
        onChange={onChange}
        error={Boolean(maximumCharactersExceeded)}
        multiline
        variant={OUTLINED}
        helperText={
          maximumCharactersExceeded
            ? `${i18n.t('analysis.ec.ecDefinition.components.maximumCharactersErrorMessage')} ${
                notes.length - TEXTAREA_INPUT_CHARACTERS_LIMIT
              }`
            : notes && notes.length > 0
            ? TEXTAREA_INPUT_CHARACTERS_LIMIT - notes.length
            : TEXTAREA_INPUT_CHARACTERS_LIMIT
        }
      />
    </div>
  );
};

export const AddLabelInput = ({ addNewLabel, setNewLabelHandler }: IAddLabelInputProps) => {
  const [newLabel, setNewLabel] = useState<string>('');
  const [hasExceededMaximumCharacters, setHasExceededMaximumCharacters] = useState<boolean>(false);

  useEffect(() => {
    if (newLabel.length > LABEL_TEXT_INPUT_CHARACTER_LIMIT) {
      setHasExceededMaximumCharacters(true);
    } else {
      setHasExceededMaximumCharacters(false);
    }
  }, [newLabel]);

  const addNewLabelHandler = (e) => setNewLabel(e.target.value);

  const submitHandler = (e) => {
    e.preventDefault();
    if (newLabel.trim().length > 0 && !hasExceededMaximumCharacters) {
      setNewLabelHandler(newLabel);
      addNewLabel(newLabel);
      setNewLabel('');
    }
  };

  return (
    <div className={'addLabelInputContainer'}>
      <div className={'addLabelInputSeparator'} />

      <div className={'addLabelInputWithError'}>
        <form className={'addLabelInput'} onSubmit={submitHandler}>
          <TextField
            placeholder={i18n.t('analysis.ec.ecDefinition.components.placeholders.labelInput')}
            value={newLabel}
            onChange={addNewLabelHandler}
            error={Boolean(hasExceededMaximumCharacters)}
            fullWidth
          />
          <Button
            disabled={newLabel.length <= 0 || hasExceededMaximumCharacters}
            variant={OUTLINED_BUTTON}
            type="submit"
            sx={{ ...footerButtonStyle }}
          >
            {i18n.t('analysis.ec.ecDefinition.components.buttons.add')}
          </Button>
        </form>
        <div className={'addNewLabelErrorMessageAndWarning'}>
          {hasExceededMaximumCharacters && <ErrorS sx={{ ...errorIconStyles }} />}
          {hasExceededMaximumCharacters && (
            <Typography variant={BODY_REGULAR} sx={{ ...maximumCharactersNotesErrorStyles }}>
              {`${i18n.t('analysis.ec.ecDefinition.components.maximumCharactersErrorMessage')} ${
                newLabel.length - LABEL_TEXT_INPUT_CHARACTER_LIMIT
              }`}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export const LabelsInput = ({
  labels,
  selectedLabels,
  onChange,
  addNewLabel,
}: ILabelsInputProps) => {
  const [localLabels, setLocalLabels] = useState<string[]>(labels);
  const [newLabel, setNewLabel] = useState<string>('');
  const newLabelRef = useRef(null);
  const setNewLabelHandler = (label: string): void => setNewLabel(label);
  const addNewLabelHandler = (label: string) => {
    const updatedLabels: string[] = [label, ...localLabels].sort((a, b) =>
      a.toLowerCase() > b.toLowerCase() ? 1 : -1
    );
    setLocalLabels(updatedLabels);
    addNewLabel(label);
  };

  useEffect(() => {
    if (newLabelRef && newLabelRef.current) {
      setTimeout(function () {
        newLabelRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }, 100);
    }
  }, [newLabel]);

  return (
    <div className={'labelsInputContainer'}>
      <FormLabel className="labelsHeader">
        {i18n.t('analysis.ec.ecDefinition.components.labels.labels')}
      </FormLabel>
      <div className={'labelsContainer'}>
        {localLabels.length > 0 &&
          localLabels.map((label: string, index) => {
            const isChecked: boolean = selectedLabels.indexOf(label) > -1;
            const shouldScrollIntoView: boolean = newLabel === label;
            return (
              <div
                key={index}
                ref={shouldScrollIntoView ? newLabelRef : null}
                className={'labelContainer'}
              >
                <Checkbox checked={isChecked} value={label} name={label} onChange={onChange} />
                <FormLabel
                  className={`defaultLabel ${isChecked ? 'checkedLabel' : ''}`}
                  sx={{ ...formLabelPaddingStyles }}
                >
                  {label}
                </FormLabel>
              </div>
            );
          })}
      </div>
      <AddLabelInput addNewLabel={addNewLabelHandler} setNewLabelHandler={setNewLabelHandler} />
    </div>
  );
};

export const ButtonsComponent = ({
  onAddClick,
  onCancelClick,
  isAddButtonEnabled,
  isEdit,
}: IButtonsComponentProps) => {
  const title: string = isEdit
    ? i18n.t('analysis.ec.ecDefinition.components.buttons.update')
    : i18n.t('analysis.ec.ecDefinition.components.buttons.add');
  return (
    <div className={'buttonsContainer'}>
      <Button 
        onClick={onCancelClick} 
        variant={OUTLINED_BUTTON} 
        sx={{ ...footerButtonStyle, margin: '0 !important' }}>          
        {i18n.t('analysis.ec.ecDefinition.components.buttons.cancel')}
      </Button>
      <Button 
        onClick={onAddClick} 
        disabled={!isAddButtonEnabled} 
        sx={{ ...buttonStyle }}>
        {title}
      </Button>
    </div>
  );
};

export const EditIconComponentWeave = ({
  tableProps,
  openEditModal,
  latestECDefinitionId,
}: IEditComponentProps) => {
  const dispatch = useDispatch();
  const selectedEcDefinition = tableProps.value;
  const editIconRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState<boolean>(false);
  useEffect(() => {
    setShouldScroll(tableProps.value?.id === latestECDefinitionId);
  }, [tableProps, latestECDefinitionId]);

  useEffect(() => {
    if (shouldScroll && editIconRef && editIconRef.current) {
      editIconRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
      dispatch(clearNewEDefinitionIdAction());
    }
  }, [shouldScroll]);

  const clickHandler = (e) => {
    e.stopPropagation();
    openEditModal(selectedEcDefinition);
  };
  return (
    <IconButton ref={shouldScroll ? editIconRef : null} onClick={clickHandler}>
      <EditS htmlColor="#808080" />
    </IconButton>
  );
};

export const DeleteIconComponentWeave = ({ tableProps, deleteDefinition }: IDeleteComponentProps) => {
  const selectedEcDefinition = tableProps?.value;
  const clickHandler = (e) => {
    e.stopPropagation();
    if (selectedEcDefinition && selectedEcDefinition.id) {
      deleteDefinition(selectedEcDefinition.id);
    }
  };
  return (
    <IconButton onClick={clickHandler}>
      <TrashS htmlColor='#808080' />
    </IconButton>
  );
};

export const WeaveSourceComponent = ({ tableProps } : ISourceComponentProps ) => {
  const source = tableProps.value?.source;
  const noPropagation = (e) => e.stopPropagation();

  const linkProps = {
    stylesheet: textLinkStylesheet,
    target: '_blank',
    onClick: noPropagation,
  };

  const getSourceElement = (element: string) =>
    isValidURL(element) ? (
      <TextLink {...linkProps} link={element}>
        {element}
      </TextLink>
    ) : (
      <Typography onClick={noPropagation}>{element}</Typography>
    );

  const mergeSourceWords = (sourceWords: string[]): string[] => {
    let builtSentence = '';
    let sourceElements: string[] = [];
    sourceWords.forEach((word: string, index) => {
      switch (isValidURL(word)) {
        case true:
          builtSentence.trim().length > 0 && sourceElements.push(builtSentence);
          sourceElements.push(word);
          builtSentence = '';
          break;
        case false:
          builtSentence += `${word} `;
          if (index === sourceWords.length - 1) {
            sourceElements.push(builtSentence);
            builtSentence = '';
          }
          break;
      }
    });
    return sourceElements;
  };

  const sourceBuilder = (sources: string) => {
    const sourceElements = sources.split(' ');
    const builtSource = mergeSourceWords(sourceElements);
    if (sourceElements.length > 1) {
      return (
        <div className={'sourceContentWrapper'}>
          {builtSource.map((element) => getSourceElement(element))}
        </div>
      );
    } else {
      return getSourceElement(sourceElements[0]);
    }
  };

  return source && sourceBuilder(source);
};
