import React, { useEffect, useState } from 'react';
import '../css/modelSelector.css';
import i18n from '../i18n';
import * as locConsts from "./localization/consts";
import ProgressRing from '@hig/progress-ring';
import { Model } from '../analysis/dashboard.models';
import { useGetUncategorizedModelsQuery, useCreateProjectMutation, useUpdateModelListMutation } from '../state/api/project-model-data-api';
import { useCurrentThumbnailData } from '../layout/hooks/useCurrentThumbnailData';

export type modelListSelectorProps = {
  hideModal?: any,
  projectId?: string
}

export type modelSelectorProps = {
  model: Model,
  selectModel: Function
}

const ModelListSelector: React.FC<modelListSelectorProps> = (props) => {
  const [enableOkButton, setEnableOkButton] = useState<boolean>(false);  
  const [models, setModels] = useState<Model[]>([]);

  // Redux Toolkit (Queries/Mutations)
  const [createProject] = useCreateProjectMutation();
  const [updateModelListMutation] = useUpdateModelListMutation();
  const { data: uncategorizedModels } = useGetUncategorizedModelsQuery()  

  useEffect(() => {
    if (uncategorizedModels) {
      setModels(uncategorizedModels);
    }
  }, [uncategorizedModels]);

  const selectModel = (modelClicked: Model) => {
    const modifiedModels = models?.map(function (model) {
      const modifiedModel: Model = { ...model };
      if (model.id === modelClicked.id) {
        modifiedModel.selected = !model.selected;
      }
      return modifiedModel;
    });

    const enableOkButton = modifiedModels.some(x => x.selected === true);

    setModels(modifiedModels);
    setEnableOkButton(enableOkButton);
  }

  const addModel = () => {
    const filteredModels = models.filter(function (item) {
      return item.selected === true;
    });

    if (filteredModels.length > 0) {
      if (props.projectId !== undefined) {
        updateModelListMutation({ models: filteredModels, projectId: props.projectId });
      } else {
        createProject(filteredModels);
      }
      props.hideModal();
    }
  }

  const renderModels = models?.map((model) => {    
    return (
      <ModelSelector
        model={model}
        selectModel={selectModel}        
      />
    );
  });

  const className = enableOkButton ? "" : "disabled";

  return (
    <div className="models-container">
      <div className="models-wrapper">
        <div className="models-header">
          <span className="title">{i18n.t(locConsts.MODEL_SELECTOR_ADD)}</span>
          <span className="icon-delete" onClick={props.hideModal}></span>
        </div>
        <div className="models-body">
          <div className="design-options-grid">
            <div className="container-fluid">
              <div className="row no-gutter">
                {renderModels}
              </div>
            </div>
          </div>
        </div>
        <div className="models-footer">
          <button className={`btn ok, ${className}`} onClick={addModel}>{i18n.t(locConsts.MODEL_SELECTOR_DONE)}</button>
        </div>
      </div>
    </div>
  );
}

export default ModelListSelector;

const ModelSelector: React.FC<modelSelectorProps> = (props) => {
  const { name, id, selected, lmvStatus, urn } = props?.model;  
  const { data: thumbnail } = useCurrentThumbnailData(urn, lmvStatus);
  const imgObject = thumbnail?.base64;

  return (
    <div className="col-xs-12 col-sm-6 col-lg-4" onClick={() => props.selectModel(props.model)} key={id}>
      <div className={"design-option " + (selected && 'selected')}>
        <div>
          {imgObject ? (<img src={imgObject} className="model-image" alt="" />) : (<div className="progress-ring" ><ProgressRing /></div>)}
          <div className="model-title">{name}</div>
        </div>
      </div>
    </div>
  );
}
