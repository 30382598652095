import {EcDefinition} from "../../analysis/EmbodiedCarbon/types";
import memoize from "lodash.memoize";

export const extractLabelsFromDefinitions = (ecDefinitions: EcDefinition[]): string[] => {
    const reducedLabels = ecDefinitions.reduce((acc, current) => {
        if (current.labels.length > 0) {
            current.labels.forEach(label => label.trim().length > 0 && acc.add(label));
            };
        return acc;
    }, new Set<string>());

    return Array.from(reducedLabels).sort((a,b) => a.toLowerCase() > b.toLowerCase() ? 1: -1)
};
