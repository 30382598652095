// @ts-nocheck For typedoc to work, we must exclude any files with typescript errors. When it is fixed we can remove this tag
import * as fromProject from '../project/reducer';
import * as fromProjectApi from '../project/api';
import * as fromProjectActions from '../project/actions';
import * as fromModel from '../model/reducer';
import * as fromModelApi from '../model/api';
import * as fromModelActions from '../model/actions';
import * as fromDashboardReducer from './reducer';
import * as fromRun from '../run/reducer';
import * as fromRunActions from '../run/actions';
import * as fromRunApi from '../run/api';
import * as fromActionTypes from './actionTypes';
import * as statusVerification from './statusVerification';
import { FactorDefinition, Model, ProjectModel, Results, RunModel, } from './dashboard.models';

export const initDashboardSuccess = (project: ProjectModel, model: Model, runs: RunModel[], results?: Results[]) => ({
  type: fromActionTypes.INIT_DASHBOARD_SUCCESS,
  project,
  model,
  runs,
  results
});

export const initDashboardStarted = (modelId: string) => ({
  type: fromActionTypes.INIT_DASHBOARD_STARTED,
  modelId
});

export const dashboardRangeChanged = (modelId: string, widgetKey: string, left: string, right: string) => ({
  type: fromActionTypes.DASHBOARD_RANGE_CHANGED,
  modelId,
  widgetKey,
  left,
  right
});

export const sortDashboard = (modelId: string, sortBy: string) => ({
  type: fromActionTypes.SORT_DASHBOARD,
  modelId,
  sortBy
});

export const updateDashboardFactors = (modelId: string, factors: FactorDefinition[], runs) => ({
  type: fromActionTypes.UPDATE_DASHBOARD_FACTORS,
  modelId,
  factors,
  runs
});

export const updateDashboardResults = (modelId: string, results: any) => ({
  type: fromActionTypes.UPDATE_DASHBOARD_RESULTS,
  modelId,
  results
});

export const updateDashboardMetric = (projectId: string, metric: any) => ({
  type: fromActionTypes.UPDATE_DASHBOARD_METRIC,
  projectId,
  metric
});

export const updateDashboardSettingsSuccess = (projectId: string, useSiUnits: boolean, currencyIso: string, currencyRate: number, fuelRate: number, electricityRate?: number) => ({
  type: fromActionTypes.UPDATE_DASHBOARD_SETTINGS,
  projectId,
  useSiUnits,
  currencyIso,
  currencyRate,
  fuelRate,
  electricityRate
});

export const loadDashboard = (modelId: string, projectId: string) => async (dispatch, getState) => {
  if (fromDashboardReducer.getIsFetchingDashboard(getState().dashboardState, modelId) ||
    fromDashboardReducer.getModelDashboard(getState().dashboardState, modelId)) {
    return;
  }

  dispatch(initDashboardStarted(modelId));

  let runs: RunModel[] = await getRuns(modelId, dispatch, getState);
  const project: ProjectModel = await getProject(projectId, dispatch, getState);
  const model: Model = await getModel(modelId, projectId, dispatch, getState);
  const results: Results[] = await getResults(runs, project.useSIUnits, dispatch, getState);

  if (model && runs.length === 0) {
    await fromRunApi.runSimulation(model.modelFileId, model.id, model.projectId, model.name);
    runs = await getRuns(modelId, dispatch, getState);
  }

  const pendingIds = runs.map((d) => d.runId).filter(runId => !results.some((r) => r.runId === runId));
  if (pendingIds.length > 0) {
    queryResults(modelId, model.projectId, pendingIds, project.useSIUnits)(dispatch, getState);
  }

  dispatch(initDashboardSuccess(project, model, runs, results));
};

export const updateDashboardFactorsAndQueryResults = (updateResult: any) => (dispatch: any, getState: any) => {
  const { model, runs: allRuns } = updateResult;
  const dashboard = fromDashboardReducer.getModelDashboard(getState().dashboardState, model.modelId);

  //the api returns all the factors and all the design options but we need to dispatch only
  //the new ones to update the dashboard.
  const newFactors: FactorDefinition[] = model.factors.filter((f) => !dashboard.factors.some((x) => x.name === f.name));
  const newRuns = allRuns.filter(
    (d) => !dashboard.simulations[d.tag] ||
      !dashboard.simulations[d.tag].some((r) => r.runId === d.runId)
  );

  dispatch(updateDashboardFactors(model.modelId, newFactors, newRuns));

  queryResults(model.modelId, model.projectId, newRuns.map((d) => d.runId), dashboard)(dispatch, getState);
};

export const queryResults = (modelId: string, projectId: string, ids: any, param3?: any, param4?: any) => (dispatch, getState) => {
  if (statusVerification.pendingResults[modelId]) {
    statusVerification.pendingResults[modelId].ids = [...statusVerification.pendingResults[modelId].ids, ...ids];
  } else if (ids.length > 0) {
    statusVerification.pendingResults[modelId] = {
      ids,
      projectId,
      intervalId: setInterval(
        resultsQueryCallback,
        30000,
        getState().userState.user.apiForgeBaseUrl,
        modelId,
        dispatch
      )
    };
  }
};

const resultsQueryCallback = async (apiForgeBaseUrl: string, modelId: string, dispatch: any) => {
  const ids = statusVerification.pendingResults[modelId].ids;
  const results = await fromRunApi.getSimulationsStatus(apiForgeBaseUrl, ids);

  const idsToRemove = (results as any).filter((r) => r.status === 'Error' || r.status === 'Completed').map(r => r.runId);
  statusVerification.pendingResults[modelId].ids = statusVerification.pendingResults[modelId].ids.filter(id => !idsToRemove.some(x => x === id));

  if (statusVerification.pendingResults[modelId].ids.length === 0) {
    clearInterval(statusVerification.pendingResults[modelId].intervalId);
    statusVerification.pendingResults[modelId] = null;
  }

  const newResults = (results as any).filter((r) => r.status !== 'Processing');
  if (newResults.length > 0) {
    dispatch(fromRunActions.loadSimulationsStatusSuccess(newResults));
    dispatch(updateDashboardResults(modelId, newResults.map(r => fromRun.getResultItem(r))));
  }
}

const getModel = async (modelId: string, projectId: string, dispatch: any, getState: any) => {

  dispatch(fromModelActions.loadProjectModelsStarted(projectId));
  const models: fromModelActions.Models[] = await fromModelApi.getProjectModels(projectId);
  dispatch(fromModelActions.loadProjectModelsSuccess(models, projectId));

  return (models as any).find((a) => a.id === modelId);
}

const getProject = async (projectId: string, dispatch: any, getState: any) => {
  const project = await fromProjectApi.getProject(projectId) as ProjectModel;
  dispatch(fromProjectActions.updateProjectSuccess(project));
  return project;
}

export const getRuns = async (modelId: string, dispatch: any, getState: any) => {
  //CodeSmell: doesn't map to RunModel , verify the model
  let runs: any = fromRun.getRuns(getState().runState, modelId);

  if (runs) {
    return runs;
  }

  dispatch(fromRunActions.loadRunsStarted(modelId));
  runs = await fromRunApi.getRuns(modelId);
  dispatch(fromRunActions.loadRunsSuccess(runs));

  return runs;
}

export const getResults = async (runs: runModel[], useSi: boolean, dispatch: any, getState: any) => {
  const results = fromRun.getResults(getState().runState, runs);

  if (results.length === runs.length) {
    return results;
  }

  const runIds: string[] = runs.map((d) => d.runId).filter((id) => !results.some((r) => r.runId === id));
  dispatch(fromRunActions.loadSimulationsStatusSuccess(
    await fromRunApi.getSimulationsStatus(getState().userState.user.apiForgeBaseUrl, runIds),
    useSi
  ));

  return fromRun.getResults(getState().runState, runs);
}

export const updateProjectSettings = (projectId: string, useSiUnits: boolean, currencyIso: string, fuelRate: number, electricityRate: number, electricityCeCoefficient: number, fuelCeCoefficient: number) => async (dispatch, getState) => {
  const project: ProjectModel = await dispatch(fromProjectActions.updateProjectSettings(projectId, useSiUnits, currencyIso, fuelRate, electricityRate, electricityCeCoefficient, fuelCeCoefficient));

  if (project) {
    dispatch(updateDashboardSettingsSuccess(projectId, useSiUnits, currencyIso, project.currencyRate, fuelRate, electricityRate, electricityCeCoefficient, fuelCeCoefficient));
  }
}
