import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled, { CSSObject } from 'styled-components';
import Theme from '@adsk/alloy-react-theme';
import Badge from '@adsk/alloy-react-badge';
import Checkbox from '@adsk/alloy-react-checkbox';
import i18n from '../i18n';
import Typography, { typographyVariants } from '@weave-mui/typography';
import Popper from '@weave-mui/popper';
import Box from '@weave-mui/box';
import { tooltipPlacement, tooltipSize } from '@weave-mui/enums';
import Tooltip from '@weave-mui/tooltip';
import { useCardGrid } from '@adsk/wildcard';

export interface CardPreviewContainersType {
  isHover? : boolean;
  isSelected? : boolean;
  isMultiple? : boolean;
  isInUse?: boolean;
  imgHeight?: number;
}

export interface CardPreviewProps {
  /** Card Id */
  id: string;
  /** Url preview image */
  imgUrl: string;
  /** Width of the logo **/
  imgWidth?: number;
  /** Height of the logo **/
  imgHeight?: number;
  /** Indicates if the card is already in use */
  inUse: boolean;
  /** Indicates whether the card is selected */
  isSelected: boolean;
  /** Number of used */
  timesUsed: number;
  /** Title of the card */
  title: string;
  /** Sub-title of the card */
  subtitle?: string;
  /** Tooltip of the card */
  tooltip?: string;
  /** Callback when preview card is clicked */
  onClick: () => void;
  /** Style applied to the CardPreviewWrapper component */
  style?: CSSObject;
  /** whether panal is opened or not */
  panelOpened?: boolean;
  /** register current card option to the global handled hovered cards */
  registerHoverListener?: (cardId: string, fn: Function) => void;
  /** notify which card is being hovered */
  hoveredCard?: ( cardId: string | null ) => void;
}

const CardPreviewWrapper = styled.div<CardPreviewContainersType>(props => {
  const { isInUse, isSelected } = props;
  return {
    '&:hover':{
      backgroundColor: 'rgba(128,128,128, 0.1)',
    },
    alignItems: 'center',
    backgroundColor: !isSelected ? Theme.colors.white : '#DFEFF8',
    borderRadius: '2px',
    cursor: isInUse ? 'default' : 'pointer',
    display: 'flex',
    flexDirection: 'row',
    height: '80px',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    gap: '16px',
    padding: '8px 16px'
  }
});
const CheckboxContainer = styled.div<CardPreviewContainersType>(props => {
  return {
    backgroundColor: Theme.colors.white,
    height: 'fit-content',
    width: 'fit-content',
  }
});
const TitleContainer = styled.div<CardPreviewContainersType>(props => {
  const { isMultiple } = props;

  return {
    ...Theme.typography.eyebrow,
    color: Theme.colors.charcoal900,
    overflow: 'hidden',
    padding: '0px 16px 0px 4px',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    width: isMultiple ? '85%' : '100%',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
  }
});

export const ImageContainer = styled.div<CardPreviewContainersType>(props => {
  const { imgHeight, imgWidth } = props;

  return {
    width: imgWidth ? `${imgWidth}px` : '100%',
    minWidth: imgWidth ? `${imgWidth}px` : '100%',
    height: imgHeight ? `${imgHeight}px` : '100%',
    minHeight: imgHeight ? `${imgHeight}px` : '100%',
    margin: 'auto',
    maxHeight: '80px',
    maxWidth: '180px',
  }
});
const BadgeContainer = styled.div({
  position: 'absolute',
  right: '10px',
  textTransform: 'uppercase',
  top: '10px',
});
 export const Img = styled.img({
  height: '100%',
  objectFit: 'contain',
  width: '100%',
})

const LayoutGridCardPreview = (
  {
    id: cardId,
    imgWidth,
    imgHeight,
    imgUrl,
    inUse,
    isSelected,
    title,
    subtitle = '',
    tooltip = '',
    onClick,
    timesUsed,
    style,
    panelOpened = false,
    registerHoverListener,
    hoveredCard,
  }: CardPreviewProps
) => {
  const {
    state: { cardLibraryOpen },
  } = useCardGrid();
  const [isHover, setHover] = useState(false);
  const anchorElemRef = useRef<HTMLDivElement>(null);

  const hideTooltop = () => {
    hoveredCard( null );
  }

  const showTooltip = () => {
    hoveredCard( cardId );
  }

  const handleOnClick = () => {
    if (!inUse) {
      onClick();
    }
  }

  const listenGlobalHover = useCallback((current: string) => {
    setHover( current === cardId );
  }, [])

  useEffect(() => {
    registerHoverListener?.(cardId, listenGlobalHover);
  }, []);

  const isMultiple = timesUsed > 0 ;

  return (
    <Tooltip
      arrow
      enterDelay={500}
      enterNextDelay={500}
      open={panelOpened && cardLibraryOpen && isHover}
      placement={tooltipPlacement.LEFT}
      size={tooltipSize.SMALL}
      title={
        <Typography variant={typographyVariants.CAPTION_BOLD}>
          {tooltip}
        </Typography>
      }
      leaveDelay={100}
      PopperProps={{
        style: {
          zIndex: 10000,
          width: 'auto',
        },
      }}
    >
      <CardPreviewWrapper
        onClick={handleOnClick}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltop}
        isInUse={inUse}
        isSelected={isSelected}
        data-testid="cardpreview-wrapper"
        style={style}
        ref={anchorElemRef}
      >
        {!inUse && (
          <CheckboxContainer
            isHover={isHover}
            isSelected={isSelected}
            data-testid="cardpreview-checkbox-container"
          >
            <Checkbox
              checked={isSelected}
              data-testid="cardpreview-checkbox"
            />
          </CheckboxContainer>
        )}
        {inUse && (
          <BadgeContainer data-testid="cardpreview-badge">
            <Badge size="small">
              {i18n.t('COMPONENTS.CARD_LIBRARY.CARD_PREVIEW.IN_USE')}
            </Badge>
          </BadgeContainer>
        )}
        <ImageContainer
          imgWidth={imgWidth}
          imgHeight={imgHeight}
          data-testid="cardpreview-image-container"
          style={style?.ImageContainer as CSSObject}
        >
          <Img data-testid="cardpreview-image" src={imgUrl}/>
        </ImageContainer>
        <TitleContainer
          isMultiple={isMultiple}
          data-testid="cardpreview-title"
        >
          <Typography variant={typographyVariants.BODY_BOLD}>
            {title}
          </Typography>
          {subtitle && <Typography variant={typographyVariants.CAPTION_REGULAR} sx={{whiteSpace: 'initial', color: 'rgba(60, 60, 60, 0.7)'}}>
            {subtitle}
          </Typography>}
        </TitleContainer>
      </CardPreviewWrapper>
    </Tooltip>
  );
}

export default LayoutGridCardPreview
