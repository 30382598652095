import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ApplicationDataState {
  currentModelId: string;
  currentProjectId: string;
  currentScenarioId: string;
}

const initialState: ApplicationDataState = {
  currentModelId: null,
  currentProjectId: null,
  currentScenarioId: null,
};

export const applicationDataSlice = createSlice({
  name: 'applicationDataState',
  initialState,
  reducers: {
    setCurrentModelId(state, action: PayloadAction<string>) {
      state.currentModelId = action.payload;
      state.currentScenarioId = null;
    },
    setCurrentProjectId(state, action: PayloadAction<string>) {
      state.currentProjectId = action.payload;      
      state.currentScenarioId = null;
    },
    setCurrentScenarioId(state, action: PayloadAction<string>) {
      state.currentScenarioId = action.payload;
    },
  },
});

export const { setCurrentModelId, setCurrentProjectId, setCurrentScenarioId } =
  applicationDataSlice.actions;
