import React, { Fragment, useCallback, useState } from 'react'
import Tooltip, { tooltipPlacement, tooltipSize } from '@weave-mui/tooltip';

interface TooltipIconProps {
  title: string,
  icon: JSX.Element,
  isActionForbidden: boolean;
}

const TooltipIcon: React.FC<TooltipIconProps> = ({ title, icon, isActionForbidden }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  
  const handleOnMouseEnter = useCallback(() => {
    setShowTooltip(true);
  },[]);

  const handleTooltipClose = useCallback(() => {
    setShowTooltip(false);
  },[]);
  
  return (
    <Fragment>
      <Tooltip
        arrow
        enterDelay={500}
        enterNextDelay={1000}
        leaveDelay={0}
        title={title}
        open={showTooltip}
        size={tooltipSize.SMALL}
        onClose={handleTooltipClose}
        placement={tooltipPlacement.BOTTOM}
        PopperProps={{
          style: {
            zIndex: 10000
          },
        }}
      >
        <div className={`${!isActionForbidden && 'pointer-cursor'}`} onMouseEnter={handleOnMouseEnter}>
          {icon}
        </div>
      </Tooltip>
    </Fragment>
  )
}

export default TooltipIcon;