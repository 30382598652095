export const EC_DEFINITIONS_SUCCESS = 'ecDefinitions/EC_DEFINITIONS_SUCCESS';
export const NEW_EC_DEFINITION_SUCCESS = 'ecDefinitions/NEW_EC_DEFINITION_SUCCESS';
export const EDIT_EC_DEFINITION_SUCCESS = 'ecDefinitions/EDIT_EC_DEFINITION_SUCCESS';
export const CLEAR_NEW_EC_DEFINITION_ID = 'ecDefinitions/CLEAR_NEW_EC_DEFINITION_ID';

export const INIT_EC_PAGE_SUCCESS = 'INIT_EC_PAGE_SUCCESS'
export const INIT_EC_PAGE_STARTED = 'INIT_EC_PAGE_STARTED'
export const SET_EC_PAGE_DATA_SUCCESS = 'SET_EC_PAGE_DATA_SUCCESS'
export const UPDATE_EC_PAGE_LMV = 'UPDATE_EC_PAGE_LMV'
export const UPDATE_EC_PAGE_SETTINGS = 'UPDATE_EC_PAGE_SETTINGS'
export const UPDATED_PROJECT_MODELS_ON_EC_PAGE = 'UPDATED_PROJECT_MODELS_ON_EC_PAGE'
export const UPDATED_PROJECT_NAME_ON_EC_PAGE = 'UPDATED_PROJECT_NAME_ON_EC_PAGE'
export const SET_EC_DEFINITION = 'SET_EMBODIED_CARBON_DEFINITION';
export const DELETE_EC_DEFINITION_SUCCESS = 'DELETE_EMBODIED_CARBON_DEFINITION';
export const DELETE_EC_DEFINITION_ASSIGNMENTS = 'DELETE_EC_DEFINITION_ASSIGNMENTS'
export const REMOVE_MODELS_DATA_FROM_STATE = 'REMOVE_MODELS_DATA_FROM_STATE';
export const SET_EC_ANALYSIS_ERROR = 'SET_EC_ANALYSIS_ERROR';
export const IS_UNAUTHORIZED_ACCESS_TO_RESOURCE = 'IS_UNAUTHORIZED_ACCESS_TO_RESOURCE';
export const REMOVE_MODELS_WITH_BREAKDOWN = 'REMOVE_MODELS_WITH_BREAKDOWN';
