export const HEADER_LOGIN = 'HeaderLogin';
export const HEADER_MY_PROFILE = 'HeaderMyProfile';
export const HEADER_SERVICES = 'HeaderServices';
export const HEADER_LOGOUT = 'HeaderLogout';
export const PRODUCT_NAME_TP = 'ProductNameTP';
export const PRODUCT_NAME = 'ProductName';
export const PRODUCT_NAME_TITLE = 'ProductNameTitle';
export const PRODUCT_NAME_SUB_TITLE = 'ProductNameSubTitle';
export const HEADER_LEARNING = 'HeaderLearning';
export const HEADER_SUPPORT = 'HeaderSupport';
export const HEADER_DOWNLOAD = 'HeaderDownload';
export const HEADER_RELEASE_NOTES = 'HeaderReleaseNotes';
export const HEADER_SUPPORT_FORUM = 'HeaderSupportForum';
export const HEADER_PREVIEW = 'HeaderInsightPreview';
export const HEADER_EC_NAV_BUTTON = 'HeaderECNavButton';
export const HEADER_OVERVIEW_NAV_BUTTON = 'HeaderOverviewNavButton';

export const FOOTER_PALETTE = 'FooterPalette';
export const FOOTER_COPYRIGHT = 'FooterCopyright';
export const FOOTER_LEGAL = 'FooterLegal';
export const FOOTER_PRIVACY_POLICY = 'FooterPrivacyPolicy';
export const FOOTER_PRIVATE_SETTINGS = 'FooterPrivateSettings';
export const FOOTER_ACKNOWLEDGEMENT = 'FooterAcknowledgement';
export const FOOTER_DONOTSELL = 'FooterDoNotSellMyPersonalInformation';

export const ACKNOWLEDGEMENT_TITLE = 'AcknowledgementTitle';
export const ACKNOWLEDGEMENT_DESCRIPTION = 'AcknowledgementDescription';
export const ACKNOWLEDGEMENT_SERVICES = 'AcknowledgementServices';
export const ACKNOWLEDGEMENT_PALETTE = 'AcknowledgementPalette';
export const ACKNOWLEDGEMENT_PALETTE_SUBTITLE = 'AcknowledgementPaletteSubtitle';
export const ACKNOWLEDGEMENT_PALETTE_DESC = 'AcknowledgementPaletteDesc';
export const ACKNOWLEDGEMENT_CLIMATE = 'AcknowledgementClimate';
export const ACKNOWLEDGEMENT_CLIMATE_DESC = 'AcknowledgementClimateDesc';
export const ACKNOWLEDGEMENT_CONTINUE = 'AcknowledgementContinue';

export const MISSING_EC_DEFINITION = 'MissingECDefinition';
export const MISSING_EC_COEFFICIENT = 'MissingECCoefficient';
export const MISSING_CONSTRUCTION_EC_COEFFICIENT = 'MissingConstructionECCoefficient';
