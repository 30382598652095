import React, { Component } from 'react';
import locationmarker from '../img/location-marker.png';
var map;

export type LocationMapProps = {
    bingMapKey?: string,
    latitude?: string,
    longitude?: string
}

export default class LocationMap extends Component<LocationMapProps> {

    componentDidMount() {
        if (this.props.bingMapKey && this.props.latitude && this.props.longitude)
            this.loadMap(locationmarker, this.props.bingMapKey, this.props.latitude, this.props.longitude);
    }

    componentDidUpdate(prevProps: LocationMapProps) {
        if (this.props.latitude !== prevProps.latitude ||
            this.props.longitude !== prevProps.longitude ||
            (this.props.bingMapKey && !prevProps.bingMapKey))
            this.loadMap(locationmarker, this.props.bingMapKey, this.props.latitude, this.props.longitude);
    }

    loadMap = (img: string, bingMapKey: string, latitude: string, longitude: string) => {
        var mapOptions = {
            credentials: bingMapKey,
            enableSearchLogo: false,
            enableClickableLogo: false,
            showMapTypeSelector: false,
            showCopyright: false,
            showDashboard: false,
            zoom: 11,
            center: {
                latitude: latitude,
                longitude: longitude
            }
        }

        map = new window.Microsoft.Maps.Map(document.getElementById('map'), mapOptions);

        var center = map.getCenter();

        var pin = new window.Microsoft.Maps.Pushpin(center, {
            icon: img
        });

        map.entities.push(pin);
    }

    render() {
        return (
            <div id="map"></div>
        );
    }
}