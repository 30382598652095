import React, {CSSProperties, useCallback, useEffect, useRef, useState} from 'react';
import { SearchS, CloseSUI } from '@weave-mui/icons-weave';
import TextField from '@weave-mui/text-field';
import '../css/new/search.css';
import i18n from "../i18n";

interface ISearchProps {
  isUnfocused?: boolean;
  onSearch: (query: string) => void;
  style?: CSSProperties;
  searchValue?: string;
  isReadOnly?: boolean;
  onClear?: () => void;
}

const Search = ({ onSearch, style, searchValue, isReadOnly = false, isUnfocused }: ISearchProps): JSX.Element => {
  const searchInputRef = useRef(null);

  const handleKey = React.useCallback((e) => e.stopPropagation(), []);
  const [value, setValue] = useState(searchValue);

  const onChangeCb = useCallback((e) => {
    setValue(e.target.value);
    onSearch(e.target.value);
  }, [onSearch]);

  const onClearCb = useCallback(() => {
    setValue('');
    onSearch('');
  }, []);

  useEffect(() => {
    if (isReadOnly) {
      setValue('');
      onSearch('');
    }
  },[isReadOnly]);

  useEffect(() => {
    let searchRef;
    if (searchInputRef && searchInputRef.current) {
      searchRef = searchInputRef.current;

      searchRef.addEventListener('keydown', handleKey);
      if (!isUnfocused) {
        setTimeout(
          (searchRefArg) => {
            searchRefArg.focus();
          },
          100,
          searchRef
        );
      }

    }

    return () => searchRef?.removeEventListener('keydown', handleKey);
  }, []);

  return (
    <div className="searchContainer" style={style}>
      <div className={`${isReadOnly ? 'disabledIconContainer': 'iconContainer'}`}>
        <SearchS data-testid='search-icon' sx={
          {
            color: `${isReadOnly ? '#80808066' : '#808080'}`,
          }
        }/>
      </div>
      <TextField
        inputRef={searchInputRef}
        onChange={onChangeCb}
        value={value}
        disabled={isReadOnly}
        placeholder={i18n.t("analysis.common.search")}
        fullWidth
        data-testid='search-input'
      />
      {value && (
        <div className="iconContainer">
          <CloseSUI data-testid='search-remove-icon' onClick={onClearCb} className="closeIcon" />
        </div>
      )}
    </div>
  );
};

export default Search;
