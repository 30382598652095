import React from 'react';
import {
  convertECDefinitionsKgtoPounds,
  convertECDefinitionsm2toft2,
  convertECDefinitionsm3toft3,
  convertECDefinitionsmtoft,
  convertECDefinitionsRSItoR,
} from '../../analysis/helper';
import i18n from '../../i18n';
import * as locConsts from '../../analysis/localization/consts';
import { EcValueProps, KG, M, M2, M2RSI, M3 } from '../../analysis/EmbodiedCarbon/types';

export const ECValue = React.memo(({ value, unit, useSI }: EcValueProps) => {
  // let unit : string = props.data[props.row.index].unit;
  // let value : string = props.row.values.average;
  if (isNaN(value) || !unit) {
    return <>{i18n.t(`analysis.constant.nullValue`)}</>;
  }
  let convertedUnits: (number | string)[];

  if (useSI) {
    convertedUnits = useSIFunction(value, unit);
  } else {
    convertedUnits = useImperialFunction(value, unit);
  }

  return (
    <p>{`${convertedUnits[0]}  ${i18n.t('analysis.ec.ecDefinition.units.default')}/${
      convertedUnits[1]
    }`}</p>
  );
});

const useImperialFunction = (transformedValue: number, transformedUnit: string) => {
  switch (transformedUnit) {
    case M: {
      transformedValue = convertECDefinitionsmtoft(Number(transformedValue));
      transformedUnit = i18n.t(locConsts.IMP_EC_BREAKDOWN_UNITS_FEET);
      break;
    }
    case M2: {
      transformedValue = convertECDefinitionsm2toft2(Number(transformedValue));
      transformedUnit = i18n.t(locConsts.IMP_EC_BREAKDOWN_UNITS_QUANTITY);
      break;
    }
    case M3: {
      transformedValue = convertECDefinitionsm3toft3(Number(transformedValue));
      transformedUnit = i18n.t(locConsts.IMP_EC_BREAKDOWN_UNITS_CUBEFT);
      break;
    }
    case KG: {
      transformedValue = convertECDefinitionsKgtoPounds(Number(transformedValue));
      transformedUnit = i18n.t(locConsts.IMP_EC_BREAKDOWN_UNITS_POUNDS);
      break;
    }
    case M2RSI: {
      transformedValue = convertECDefinitionsRSItoR(Number(transformedValue));
      transformedUnit = i18n.t(locConsts.IMP_EC_BREAKDOWN_UNITS_FTR);
      break;
    }
  }
  return [transformedValue, transformedUnit];
};

const useSIFunction = (transformedValue: number, transformedUnit: string) => {
  switch (transformedUnit) {
    case M: {
      break;
    }
    case M2: {
      transformedUnit = i18n.t(locConsts.SI_EC_BREAKDOWN_UNITS_QUANTITY);
      break;
    }
    case M3: {
      transformedUnit = i18n.t(locConsts.SI_EC_BREAKDOWN_UNITS_METERCUBE);
      break;
    }
    case KG: {
      break;
    }
    case M2RSI: {
      transformedUnit = i18n.t(locConsts.SI_EC_BREAKDOWN_UNITS_SQUAREMETER_RSI);
      break;
    }
  }
  return [transformedValue, transformedUnit];
};
