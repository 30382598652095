import { PayloadAction, createSlice, nanoid } from '@reduxjs/toolkit';
import { Notification } from '../../types/notification';
import { ErrorData } from '../../types/errorData';

export type NotificationsState = {
  notifications: Notification[];
  errorData: ErrorData;
  displayForceLogOutModal: boolean;
};

const initialState: NotificationsState = {
  notifications: [],
  errorData: { displayAppError: false, displayModalError: false },
  displayForceLogOutModal: false
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    /**
     * Add a notification to the list
     *
     * @param state - Current Redux state
     * @param payload - A notification item without an id, as we'll generate this.
     */
    addNotification: (state, { payload }: PayloadAction<Omit<Notification, 'id'>>) => {
      const notification: Notification = {
        id: nanoid(), //Using nano to generate unique ids
        ...payload,
      };

      state.notifications.push(notification);
    },
    /**
     * Remove a notification from the list
     *
     * @param state - Current Redux state
     * @param payload - The id of the Notification to dismiss
     */
    dismissNotification: (state, { payload }: PayloadAction<Notification['id']>) => {
      const index = state.notifications.findIndex((notification) => notification.id === payload);

      if (index !== -1) {
        state.notifications.splice(index, 1);
      }
    },
    /**
     * Add an app error
     *
     * @param state - Current Redux state
     * @param payload - The errorData of reject API call
     */
    displayAppError(state, { payload }: PayloadAction<ErrorData>) {
      state.errorData = payload;
    },
    /**
     * Remove an app error and reset the redux state
     *
     * @param state - Current Redux state
     */
    removeAppError(state) {
      state.errorData = initialState.errorData;
    },
    /**
     * Add an app error
     *
     * @param state - Current Redux state
     * @param payload - The errorData of reject API call
     */
    displayModalError(state, { payload }: PayloadAction<ErrorData>) {
      state.errorData = payload;
    },
    /**
     * Set the force logout state
     *
     * @param state - Current Redux state
     * @param payload - true or false
     */
    setForceLogout(state, action) {
      state.displayForceLogOutModal = action.payload;
    },
  },
});

export const {
  addNotification,
  dismissNotification,
  displayAppError,
  removeAppError,
  displayModalError,
  setForceLogout,
} = notificationSlice.actions;

