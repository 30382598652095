import {
  CardType,
  CardRegistryEntry,
  DataDisplayCardData,
  MetricCardData,
  FactorCardData,
} from '../../types/layout';
import { LmvCard } from './LmvCard';
import { FreeEntryTextCard } from './FreeEntryTextCard';
import { DataPointCardConfigurator } from './configuration/DataPointCardConfigurator';
import { DataDisplayCard } from './DataDisplayCard';
import { useCurrentModelViewData } from '../hooks/useCurrentModelViewData';
import { MetricCard } from './MetricCard';
import { FactorCard } from './FactorCard';
import { useDataPointValue } from '../hooks/useDataPointValue';
import i18n from '../../i18n';

export const CardTypeToCardIdMappings: Record<keyof CardType, string> = {
  modelView: 'lmvCard',
  freeEntryText: 'freeEntryTextCard',
  dataDisplay: 'dataDisplayCard',
  metric: 'metricCard',
  factor: 'factorCard',
};

/**
 * Local card registry to define card properties for use with the WildCard component
 * The registry contains all of the properties required to render a card and add a card to the dashboard
 */
export const CardRegistry: Partial<Record<keyof CardType, CardRegistryEntry>> = {
  dataDisplay: {
    component: DataDisplayCard,
    height: 1,
    width: 2,
    title: i18n.t('cardGrid.carGridLibrarycomponents.dataDisplay.title'),
    cardId: CardTypeToCardIdMappings.dataDisplay,
    cardLibrarySettings: {
      title: i18n.t('cardGrid.carGridLibrarycomponents.dataDisplay.title'),
      subtitle: i18n.t('cardGrid.carGridLibrarycomponents.dataDisplay.subtitle'),
      tooltip: i18n.t('cardGrid.carGridLibrarycomponents.dataDisplay.tooltip'),
      icon: 'layout/Data_Display.svg',
      display: true,
    },
    cardConfigurationSettings: {
      configurationComponent: DataPointCardConfigurator,
      cardHasRequiredSettingsToDisplay: (settings) => {
        const dataDisplay = settings as DataDisplayCardData;
        return !!dataDisplay?.parameterId && !!dataDisplay?.title && !!dataDisplay?.type;
      },
      supportsEditMode: true,
    },
    cardDataSourceSettings: {
      dataFetcherHook: useDataPointValue,
      dataFetcherSupportsInput: true,
      dataFetcherInputProperty: 'parameterId',
    },
  },
  factor: {
    component: FactorCard,
    height: 1,
    width: 2,
    title: i18n.t('cardGrid.carGridLibrarycomponents.factor.title'),
    cardId: CardTypeToCardIdMappings.factor,
    cardLibrarySettings: {
      title: i18n.t('cardGrid.carGridLibrarycomponents.factor.title'),
      subtitle: i18n.t('cardGrid.carGridLibrarycomponents.factor.subtitle'),
      tooltip: i18n.t('cardGrid.carGridLibrarycomponents.factor.tooltip'),
      icon: 'layout/Dropdown.svg',
      display: true,
    },
    cardConfigurationSettings: {
      configurationComponent: DataPointCardConfigurator,
      cardHasRequiredSettingsToDisplay: (settings) => {
        const metricData = settings as FactorCardData;
        return !!metricData?.parameterId && !!metricData?.title && !!metricData?.type;
      },
      supportsEditMode: true,
    },
    cardDataSourceSettings: {
      dataFetcherHook: useDataPointValue,
      dataFetcherSupportsInput: true,
      dataFetcherInputProperty: 'parameterId',
    },
  },
  metric: {
    component: MetricCard,
    height: 4,
    width: 2,
    title: i18n.t('cardGrid.carGridLibrarycomponents.metric.title'),
    cardId: CardTypeToCardIdMappings.metric,
    cardLibrarySettings: {
      title: i18n.t('cardGrid.carGridLibrarycomponents.metric.title'),
      subtitle: i18n.t('cardGrid.carGridLibrarycomponents.metric.subtitle'),
      tooltip: i18n.t('cardGrid.carGridLibrarycomponents.metric.tooltip'),
      icon: 'layout/Bar_Chart.svg',
      display: true,
    },
    cardConfigurationSettings: {
      configurationComponent: DataPointCardConfigurator,
      cardHasRequiredSettingsToDisplay: (settings) => {
        const metricData = settings as MetricCardData;
        return !!metricData?.parameterId && !!metricData?.title &&
          !!metricData?.type;
      },
      supportsEditMode: true,
    },
    cardDataSourceSettings: {
      dataFetcherHook: useDataPointValue,
      dataFetcherSupportsInput: true,
      dataFetcherInputProperty: 'parameterId',
    },
  },
  modelView: {
    component: LmvCard,
    height: 4,
    width: 4,
    title: i18n.t('cardGrid.carGridLibrarycomponents.modelView.title'),
    cardId: CardTypeToCardIdMappings.modelView,
    cardLibrarySettings: {
      title: i18n.t('cardGrid.carGridLibrarycomponents.modelView.title'),
      tooltip: i18n.t('cardGrid.carGridLibrarycomponents.modelView.tooltip'),
      icon: 'layout/3D_view.svg',
      display: true,
    },
    cardConfigurationSettings: {
      cardHasRequiredSettingsToDisplay: (settings) => {
        return !!settings?.title && !!settings?.type;
      },
      supportsEditMode: false,
    },
    cardDataSourceSettings: {
      dataFetcherHook: useCurrentModelViewData,
      dataFetcherSupportsInput: false,
    },
  },
  freeEntryText: {
    component: FreeEntryTextCard,
    height: 1,
    width: 4,
    title: i18n.t('cardGrid.carGridLibrarycomponents.freeEntryText.title'),
    cardId: CardTypeToCardIdMappings.freeEntryText,
    cardLibrarySettings: {
      title: i18n.t('cardGrid.carGridLibrarycomponents.freeEntryText.title'),
      tooltip: i18n.t('cardGrid.carGridLibrarycomponents.freeEntryText.tooltip'),
      icon: 'layout/Text.svg',
      display: true,
    },
    cardConfigurationSettings: {
      cardHasRequiredSettingsToDisplay: (settings, customizeMode) => {
        // const freeEntryTextData = settings as FreeEntryTextCardData;
        return true;
        // ToDo: Enable when fully functional text cards are implemented
        //!!freeEntryTextData?.customTitle && !!freeEntryTextData?.type && (!customizeMode ? !!freeEntryTextData?.text : true)
      },
      supportsEditMode: false, //todo: enable when implemented
    },
  }
};
