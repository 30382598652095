import React, {useCallback} from "react";
import Tooltip from '@hig/tooltip';
import {useTopLevelTooltipState} from ".";
import {TooltipContent} from "../../../shared/Tooltip";

const topLevelTooltipStyle = styles => {
    return ({
        ...styles,
        panel: {
            ...styles.panel,
            width: '230px',
            'word-break': 'break-word'
        },
        flyoutContainer: {
            ...styles.flyoutContainer,
            zIndex: 99999,
            position: "fixed",
        },
        flyoutWrapper: {
            ...styles.flyoutWrapper,
            display: "block"
        }
    })
};


export const TopLevelTooltip = () => {
    const {header, content, coordinates} = useTopLevelTooltipState()

    const {anchorPoint, containerPosition, pointerPosition} = coordinates
    const {top, left} = containerPosition
    const isOpen = !!content;


    const dislocateCoordinates = useCallback((c) => {
        const newCoordinates = {
            anchorPoint: anchorPoint,
            containerPosition: containerPosition,
            pointerPosition: {
                top: !pointerPosition.top
                    ? c.pointerPosition.top
                    : pointerPosition.top + c.pointerPosition.top,
                left: pointerPosition.left,
            }
        }

        return newCoordinates
    }, [anchorPoint, containerPosition, pointerPosition])

    return (
        <Tooltip
            openOnHover={false}
            stylesheet={topLevelTooltipStyle}
            maxHeight={0}
            anchorPoints='top-center'
            defaultOpen={false}
            open={top && left && isOpen}
            content={<TooltipContent
                header={header}
                content={content}
                coordinates={coordinates}
            />}
            alterCoordinates={dislocateCoordinates}
        />

    )
}
