import { showNotification } from '../../actions';
import * as actionTypes from '../../../analysis/EmbodiedCarbon/actionTypes';
import * as i18nConsts from '../../../analysis/localization/consts';
import i18n from '../../../i18n';

interface INotificationsToDispatch {
    [key: string]: () => void;
}

const notificationsToDispatch: INotificationsToDispatch = {
    [actionTypes.NEW_EC_DEFINITION_SUCCESS]: () => showNotification(i18n.t(i18nConsts.EC_NOTIFICATION_DEFINITION_ADDED)),
    [actionTypes.EDIT_EC_DEFINITION_SUCCESS]: () => showNotification(i18n.t(i18nConsts.EC_NOTIFICATION_DATABASE_UPDATE)),
    [actionTypes.DELETE_EC_DEFINITION_SUCCESS]: () => showNotification(i18n.t(i18nConsts.EC_NOTIFICATION_DATABASE_UPDATE))
};

export const notificationsMiddleware = store => next => action => {
    if (notificationsToDispatch.hasOwnProperty(action.type)) {
        store.dispatch(notificationsToDispatch[action.type]());
    }
    return next(action);
}
