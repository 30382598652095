import React, {createRef, useCallback, useEffect, useRef, useState} from 'react'
import { DataPoint, Factor, FormulaSelectionChangedEvent, FormulaToken, Metric } from '../../../../types/metrics';
import { useGetUnitsQuery } from '../../../../state/api/data-service-api';
import { extractParametersFromFormula, sanitizeFormulaHtml } from '../../../utils/formulaUtils';
import { v4 as uuidv4 } from "uuid";
import FormulaParameter from './FormulaParameter';
import { getDataPointUnit } from '../../../utils/unitsUtils';
import isequal from "lodash.isequal";
import { useGetDataPointMap } from '../../../../layout/hooks/useDataPoints';
import {getCursorPosition, getPreviousTokens} from "../../../utils/selectionUtils";

interface FormulaContainerProps {
  formula: string;
  isReadOnly: boolean;
  imperialUnits: boolean;
  onChange: (e: React.ChangeEvent<HTMLDivElement>) => void;
  onDelete: (value: {index: number, formula: string}) => void;
  onSelectionChanged: (data: FormulaSelectionChangedEvent) => void;
}

const FormulaContainer = React.memo(({ formula, onChange, onDelete, onSelectionChanged, isReadOnly = false, imperialUnits }: FormulaContainerProps) => {
  const [tokens, setTokens] = useState<FormulaToken[]>([]);
  const editorRef = useRef<HTMLDivElement>(null);
  const {data: dataPointsMap} = useGetDataPointMap();
  const { data: allUnits } = useGetUnitsQuery();

  useEffect(() => {
    const newTokens = extractParametersFromFormula(formula , dataPointsMap, allUnits, imperialUnits);
    setTokens(newTokens);
  }, [formula, imperialUnits]);

  useEffect(() => {
    editorRef.current?.dispatchEvent(new Event('input', { bubbles: true }));
  }, [tokens]);

  const handleInput =(event: React.KeyboardEvent) => {
    const eventKey = event.key;
    if (eventKey === 'Backspace' || eventKey === 'Delete') {
      //selectionChangedCb(eventKey);
      return;
    }

    const allowedCharsRegex = /^[0-9+\-*/.()^\s]+$/;
    if (!allowedCharsRegex.test(eventKey)) {
      event.preventDefault();
      return;
    }
  }
    const onInput = useCallback((e) => {
      onChange(e);
    }, []);

    const onDeleteHandler = useCallback((index: number) => {
      if (!isReadOnly) {
        const formula =  sanitizeFormulaHtml(editorRef.current?.innerHTML);
        onDelete({index, formula});
      }
    },[editorRef, isReadOnly]);

    const getSelectionEvent = (): FormulaSelectionChangedEvent => {
      const formula = sanitizeFormulaHtml(editorRef.current?.innerHTML);
      const selection = window.getSelection();
      if (selection?.rangeCount > 0) {
        const range = selection?.getRangeAt(0);
        if (range) {
          const previousHtmlNodes = getPreviousTokens(range);
          const cursorPositionInText = getCursorPosition(range);

          return {
            formula,
            cursorPositionInText: cursorPositionInText,
            previousNodes: previousHtmlNodes.reduce((acc, node, idx) => {
              if (idx === previousHtmlNodes.length - 1 && !node.startsWith('<div')) { //remove the last element if it's non token
                return acc;
              }
              acc = acc.concat(node);
              return acc;
            }, '')
          }
        }
      }
      return null;
    }

    const selectionChangedCb = () => {
      if(isReadOnly) {
        return;
      }
      try {
        const selectionEvent = getSelectionEvent();
        onSelectionChanged(selectionEvent);
      } catch (e) {
        console.error('Error on calculating selection event', e);
        onSelectionChanged(null);
      }
    };
    const keyUpCallback = (e: React.KeyboardEvent) => {
      selectionChangedCb();
    };
    const mouseUpCallback = (e: React.MouseEvent) => {
      selectionChangedCb();
    }

    const onContextMenuCb = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      return;
    }, []);

    let tokenCount = 0;
    return (
      <div
        className="formula-container"
        key={uuidv4()}
        ref={editorRef}
        contentEditable={!isReadOnly}
        suppressContentEditableWarning={true}
        onKeyDown={handleInput}
        onKeyUp={keyUpCallback}
        onClick={mouseUpCallback}
        onInput={onInput}
        onContextMenu={onContextMenuCb}
      >
        {
          tokens.map((token, index) => {
            return <FormulaParameter
              key={index}
              ref={token.isParameter ? createRef() : undefined}
              token={token}
              position={token.isParameter ? tokenCount++ : -1}
              unit={token.isParameter ? getDataPointUnit(token.parameter as (Metric | DataPoint | Factor), allUnits, imperialUnits) : null}
              isReadOnly={isReadOnly}
              onDelete={isReadOnly ? undefined : onDeleteHandler}
            />
          })
        }
      </div>
    );
}, (prevProps, nextProps) =>
    isequal(prevProps.formula, nextProps.formula) &&
    isequal(prevProps.isReadOnly, nextProps.isReadOnly) &&
    isequal(prevProps.imperialUnits , nextProps.imperialUnits)
);

export default FormulaContainer
