import React, { useCallback, useState } from 'react'
import Box from '@weave-mui/box';
import SideMenu from './components/menu/SideMenu';
import styles from './styles/sideNavigation.styles';
import { ParametersTypesEnum } from '../../types/metrics';
import SideToggleButton from './components/button/SideToggleButton';

interface SideNavigationProps {
  onOptionClick: (navOption:ParametersTypesEnum) => void;
}

const SideNavigation: React.FC<SideNavigationProps> = ({ onOptionClick }) => {
  const [isNavCollapsed, setIsNavCollapsed] = useState<boolean>(false);

  const onExpandClick = useCallback(() => setIsNavCollapsed(prevState => !prevState), []);

  return (
    <Box 
      sx={styles.navContainer}
      data-testid='side-nav-container'
    >
      <SideMenu collapsed={isNavCollapsed} onOptionClick={onOptionClick}/>
      <SideToggleButton onClick={onExpandClick} collapsed={isNavCollapsed}/>  
    </Box>
  )
}

export default SideNavigation;
