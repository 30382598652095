import { useMediaQuery } from 'react-responsive';

/**
 * Get a set of boolean representing which breakpoint is active
 * and which breakpoints are inactive.
 */
export default function useBreakpoints() {
  const breakpoints = {
    isXxxs: useMediaQuery({
      query: '(min-width: 150px)',
    }),
    isXxs: useMediaQuery({
      query: '(min-width: 850px)',
    }),
    isXs: useMediaQuery({
      query: '(min-width: 980px)',
    }),
    isSm: useMediaQuery({
      query: '(min-width: 1366px)',
    }),
    isMd: useMediaQuery({
      query: '(min-width: 1500px)',
    }),
    isLg: useMediaQuery({
      query: '(min-width: 1610px)',
    }),
  };
  return breakpoints;
}
