import React from 'react';
import Modal from '@weave-mui/modal';
import Box from '@weave-mui/box';
import Button from '@weave-mui/button';
import i18n from '../i18n';
import { InfoS } from '@weave-mui/icons-weave';
import { useSelector } from 'react-redux';
import { NotificationsState } from '../state/slice/notifications-slice';
import AuthClient from '../authClient';
import Typography, { typographyVariants } from '@weave-mui/typography';
import { headerIconStyle } from '../layout/LayoutGrid.stylesheet';

const LogoutModalNotification = () => {
  const redirectToLogin = () => {
    AuthClient.navigateToLogin('/');
  };

  const openModal = useSelector((state: NotificationsState) => state.notifications.displayForceLogOutModal);

  const handleClose = () => {};

  const logoutModalStyles = {
    '& .MuiModalWeave-box': {
      width: '400px'      
    },      
  };
  const logoutNotificationModalStyles = {
    ...logoutModalStyles,
    '& .MuiModalWeave-closeIcon': {
      display: 'none',
    },
  };

  const buttonBox = (
    <Box>
      <Box
        sx={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          display: 'flex',
        }}
      >
        <Button onClick={redirectToLogin}>{i18n.t('sessionLogoutModalOkButton')}</Button>
      </Box>
    </Box>
  );
  return (
    <>
      <Modal
        header={
           <Typography variant={typographyVariants.H3_MEDIUM}>
            <InfoS style={headerIconStyle} color="info" />
            {i18n.t('sessionLogoutModalHeader')}
          </Typography>
        }
        open={openModal}
        onClose={handleClose}
        actions={buttonBox}
        sx={logoutNotificationModalStyles}
      >
        <Typography>
          {i18n.t('sessionLogoutModalMessage')}
        </Typography>
      </Modal>
    </>
  );
};

export default LogoutModalNotification;
