import React, { Component } from 'react';

export class PlugLoadEfficiency extends Component {

    render() {
        return <svg
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 16 16"
            style={{
                // @ts-ignore: unrecognizable type
                enableBackground: "new 0 0 16 16"
            }}
            xmlSpace="preserve"
            className="factor-modal-icon"
        >
            <style type="text/css">
                {
                    "\n\t.st0{fill:#7C7C7C;}\n\t.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#7C7C7C;}\n"
                }
            </style>
            <path
                className="factor-modal-icon-color"
                d="M1,11c0,0.7,0.4,1.4,1,1.7V15h2v-2.3c0.6-0.4,1-1,1-1.7V9H1V11z"
            />
            <polygon className="factor-modal-icon-color" points="4,1 2,1 2,4 1,4 1,8 5,8 5,4 4,4 " />
            <path
                className="factor-modal-icon-color"
                d="M6,11c0,0.7,0.4,1.4,1,1.7V15h2v-2.3c0.6-0.4,1-1,1-1.7V9H6V11z"
            />
            <polygon className="factor-modal-icon-color factor-modal-icon-rule" points="9,1 7,1 7,4 6,4 6,8 10,8 10,4 9,4 " />
            <polygon
                className="factor-modal-icon-color"
                points="14,4 14,1 12,1 12,4 11,4 11,8 15,8 15,4 "
            />
            <path
                className="factor-modal-icon-color"
                d="M11,11c0,0.7,0.4,1.4,1,1.7V15h2v-2.3c0.6-0.4,1-1,1-1.7V9h-4V11z"
            />
        </svg>;
    }
}
