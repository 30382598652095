import React, { useEffect, useState } from 'react';
import BannerContext from "../../shared/BannerContext/BannerContext";
import AlertBanner from '../../shared/BannerContext/BannerComponent';
import { BannersMap } from '../../shared/BannerContext/BannerComponent';
import { alertSeverities } from '@weave-mui/alert';
import i18n from '../../i18n';
import moment from 'moment';
import { useGetUserFeatureFlagDataQuery } from '../../state/api/feature-flag-data-api';


export type MaintenanceBannerProps = {
  beginDate?: string,
  endDate?: string,
  scheduledMaintenance?: true,
  text: string
};

const MaintenanceBannerContext = () => {
  const [shouldDisplayBanner, setShouldDisplayBanner] = useState<boolean>(false);
  const [banners, setBanners] = useState<BannersMap>(new Map());
  const { data: featureFlagData } = useGetUserFeatureFlagDataQuery();
  const maintenanceBannerProps = featureFlagData?.['display-maintenance-window'] as MaintenanceBannerProps

  useEffect(() => {
    if (maintenanceBannerProps?.scheduledMaintenance) {
      const currentDate = moment();
      const startDate = moment(maintenanceBannerProps?.beginDate, "MM/DD/YYYY h:mm A");
      const endDate = moment(maintenanceBannerProps?.endDate, "MM/DD/YYYY h:mm A");

      if (startDate.isValid() && endDate.isValid() && currentDate.isValid()) {
        if (startDate.isBefore(endDate) && currentDate.isBefore(startDate)) {
          setShouldDisplayBanner(true);
          return;
        }
      }
    }
    setShouldDisplayBanner(false);

  }, [maintenanceBannerProps]);

  useEffect(() => {
    const key = 'underMaintenance';
    if (shouldDisplayBanner) {
      const { text, beginDate, endDate } = maintenanceBannerProps;
      const message = text
        .replace("{startDate}", moment(beginDate, "MM/DD/YYYY h:mm A").format("MM/DD/YYYY h:mm A"))
        .replace("{endDate}", moment(endDate, "MM/DD/YYYY h:mm A").format("MM/DD/YYYY h:mm A"));

      const value = <AlertBanner
        severity={alertSeverities.INFO}
        bannerText={message}
        buttonText={i18n.t('applicationUnderMaintenance.banner.learnMoreButtonText')}
        buttonUrl={i18n.t('applicationUnderMaintenance.informativeMessage.linkUrl')}
        onCloseBanner={onBannerDismiss} />;
      setBanners((prevBanners) => new Map([...prevBanners, [key, value]]));
    } else {
      setBanners((prevBanners) => {
        const newBanners = new Map(prevBanners);
        newBanners.delete(key);
        return newBanners;
      })
    }
  }, [shouldDisplayBanner]);

  const onBannerDismiss = () => {
    const key = 'underMaintenance';
    setBanners((prevBanners) => {
      const newBanners = new Map(prevBanners);
      newBanners.delete(key);
      return newBanners;
    });
  }

  return (
    <BannerContext bannersArray={Array.from(banners.values())} />
  );
};

export default MaintenanceBannerContext;
