import React, { Component } from 'react';
import Lmv from './Lmv';
import LocationMap from './LocationMap';
import Loader from '../analysis/Loader';
import '../css/new/overview.css';
import i18n from '../i18n';
import * as locConsts from "../analysis/localization/consts";

export type OverviewProps = {
    lmvStatus?: string,
    bingMapKey: string,
    model: OverViewModelObject
}

export type OverViewModelObject = {
    lmvStatus?: string,
    urn?: string, 
    locality?: string, 
    latitude?: string, 
    longitude?: string
}

export default class Overview extends Component<OverviewProps> {

    renderLoader(): JSX.Element {        
        if (this.props.lmvStatus === null || this.props.model.lmvStatus === "Error") {
            return null;
        }

        const isLoading = this.props.model.lmvStatus !== "Completed";

        return (
            <Loader isLoading={isLoading} size="l">
                <Lmv urn={this.props.model.urn} lmvStatus={this.props.model.lmvStatus} />
            </Loader>
        );
    }

    render() {

        return (
            <div className="row analysis-tab-content">
                <div className="col-8 col-padding">
                    <div className="overview-widget-wrapper">
                        <div className="overview-widget">
                            {this.renderLoader()}
                        </div>
                    </div>
                </div>
                <div className="col-4 col-padding">
                    <div className="overview-widget-wrapper">
                        <div className="overview-widget">
                            <div className="overview-widget-header">
                                <span>{i18n.t(locConsts.OVERVIEW_TAB_LOCATION)}: {this.props.model.locality}</span>
                            </div>
                            <LocationMap bingMapKey={this.props.bingMapKey} latitude={this.props.model.latitude} longitude={this.props.model.longitude} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
