import React from 'react';
import { CardConfigurationModalProps } from '../../../types/layout';
import Box from '@weave-mui/box';
import Modal from '@weave-mui/modal';
import i18n from '../../../i18n';

export const BaseCardConfigurationModal: React.FC<CardConfigurationModalProps> = ({
  currentSettings,
  updateCardSettings,
  open,
  configComponent,
  onClose,
}) => {
  const ConfigComponent = configComponent;
  const hasConfigComponentAssigned = !!ConfigComponent;
  return (
    <>
      <Modal
        header={`${i18n.t('cardConfig.edit')} ${currentSettings?.title} ${i18n.t('cardConfig.card')}`}                  
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiModalWeave-box': {
            width: '58.56%',
            height: 'auto',
            'maxWidth': '1000px',
            'minWidth': '800px',
            'minHeight': '500px',
            'maxHeight': '710px',
          },
          '& .MuiModalWeave-child-no-actions': {
            padding: '0px !important',
            margin: '0px !important',
          },
          '& .MuiModalWeave-scrolling-false': {
            pb: '0px !important'
          }            
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {(hasConfigComponentAssigned && (
            <ConfigComponent
              updateCardSettings={updateCardSettings}
              currentSettings={currentSettings}
              cancelEdit={onClose}
            />
          )) || <Box>{i18n.t('cardConfig.noConfigurationDefined')}</Box>}
        </Box>
      </Modal>
    </>
  );
};
