import React, { ReactElement } from 'react';
import PieChart from './PieChart';
import StackedColumnChart from './StackedColumnChart';
import { ChartEvents, ChartOptions, ChartType } from './types';
import VariwideChart from './VariwideChart';

export type ChartProps = {
  options: ChartOptions;
  chartRef: React.MutableRefObject<any>;
  chartKey: string;
  selectedCategoryIndex?: number;
  events?: ChartEvents;
  selectedMaterialSha?: string;
  chartWidth: number;
  yAxisMaxValue?: number;
};

const Chart: React.FC<ChartProps> = (props: ChartProps): ReactElement => {
  switch (props.options.type) {
    case ChartType.STACKED_COLUMN:
      return <StackedColumnChart {...props} options={props.options} />;
    case ChartType.PIE:
      return <PieChart {...props} options={props.options} selectedCategoryIndex={null} />;
    case ChartType.VARIWIDE:
      return <VariwideChart {...props} options={props.options} />;
    default:
      return null;
  }
};

export default Chart;
