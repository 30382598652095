// @ts-nocheck For typedoc to work, we must exclude any files with typescript errors. When it is fixed we can remove this tag
import authClient from "../authClient";

type Resolve = (value: unknown) => void;
type Reject = (reason?: any) => void;

export type SettingsData = {
  tealiumProfile: string;
  tealiumEnvironment: string;
  forgeBaseUrl: string;
  useForgeUnitService: boolean;
  loginLink: string;
};
export type ResponseError = { apiError: boolean; error: Response };

const emptyResponseStatuses = [204, 202];
const responseStatusHasBody = (response: Response): boolean => {
  // needed when 200 does not return a body. in theory though, one can return a body with no headers....
  if (response === null || !response.headers.has(`content-type`)) return false;

  return !emptyResponseStatuses.some((s) => s === response.status);
};

//TODO: There is another type of "currency" under model, but the required fields are different. consolidate?
export type ApiCurrency = {
  CurrencyIso: string;
  Name: string;
  Symbol: string;
  ConversionRate?: number;
};


// assign apigee base path to api
// uable to access store directly here with causing application issues
// requires that the path are passed when available - currently called from App.tsx
let insightApigeeBasePath = "";

export const assignInsightApigeeBasePath = (path: string): void => (insightApigeeBasePath = path);

export const getInsightApigeeBasePath = (): string => insightApigeeBasePath;

export function httpGet<T>(url: string, params?: any): Promise<T> {
  return new Promise(async (resolve: (value: T | PromiseLike<T>) => void, reject: Reject) => {
    addDefaultHandlers(
      fetch(getEncodedUrl(url, params), await getFetchConfig("GET", params)),
      resolve,
      reject
    );
  });
}

export function httpGetBlob(url: string, params) {
  return new Promise(async (resolve: Resolve, reject: Reject) => {
    addDefaultHandlersBlob(
      fetch(getEncodedUrl(url, params), await getFetchConfigBlob("GET", params)),
      resolve,
      reject
    );
  });
}

export function httpPut(url: string, params?: any) {
  return new Promise(async (resolve: Resolve, reject: Reject) => {
    addDefaultHandlers(fetch(url, await getFetchConfigJson("PUT", params)), resolve, reject);
  });
}

export function httpPost(url: string, params?: any) {
  return new Promise(async (resolve: Resolve, reject: Reject) => {
    addDefaultHandlers(fetch(url, await getFetchConfigUrlEncoded("POST", params)), resolve, reject);
  });
}

export function httpPostJson(url: string, params: any) {
  return new Promise(async (resolve: Resolve, reject: Reject) => {
    addDefaultHandlers(fetch(url, await getFetchConfigJson("POST", params)), resolve, reject);
  });
}

export function httpPostFormData(url: string, formData: FormData) {
  return new Promise(async (resolve: Resolve, reject: Reject) => {
    addDefaultHandlers(fetch(url, await getFetchConfigFormData(formData)), resolve, reject);
  });
}

export function httpDeleteJSON(url: string, params?: any) {
  return new Promise(async (resolve: Resolve, reject: Reject) => {
    addDefaultHandlers(fetch(url, await getFetchConfigJson("DELETE", params)), resolve, reject);
  });
}

export function httpDelete(url: string, params?: any) {
  return new Promise(async (resolve: Resolve, reject: Reject) => {
    addDefaultHandlers(
      fetch(url, await getFetchConfigUrlEncoded("DELETE", params)),
      resolve,
      reject
    );
  });
}

function addDefaultHandlersMain(
  request: Promise<Response>,
  resolve: Resolve,
  reject: Reject,
  handleResponse: (response: Response) => any
) {
  request
    .then((response) => {
      if (response.ok) {
        resolve(handleResponse(response));
        return;
      } else {
        reject({ apiError: true, error: response });
        return;
      }
    })
    .catch((error) => {
      reject({
        apiError: false,
        error: error,
      });
    });
}

function addDefaultHandlers(request: Promise<Response>, resolve: Resolve, reject: Reject) {
  addDefaultHandlersMain(request, resolve, reject, (response: Response) => {
    return responseStatusHasBody(response) ? response.json() : null;
  });
}

function addDefaultHandlersBlob(request: Promise<Response>, resolve: Resolve, reject: Reject) {
  addDefaultHandlersMain(request, resolve, reject, (response: Response) => {
    return response.blob();
  });
}

async function getFetchConfig(method: any, params?: any) {
  let config = {
    method: method,
    credentials: "include",
    headers: {
      Accept: "application/json",
    },
  };

  await addForgeToken(config);

  return config as any;
}

async function getFetchConfigUrlEncoded(method: any, params: any) {
  let config = {
    method: method,
    credentials: "include",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Accept: "application/json",
    },
    body: {},
  };

  await addForgeToken(config);

  if (params && params.data) config.body = getEncodedParams(params.data);

  return config as any;
}

async function getFetchConfigJson(method: any, params: any) {
  var config = {
    method: method,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: {},
  };

  await addForgeToken(config);

  if (params && params.data) config.body = JSON.stringify(params.data);

  return config as any;
}

async function getFetchConfigFormData(formData: FormData) {
  var config = {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
    },
    body: formData,
  } as any;

  await addForgeToken(config);
  return config;
}

async function addForgeToken(config: any) {
  var accessToken = await authClient.getAccessToken();
  if (accessToken !== null) {
    config.headers.Authorization = "Bearer " + accessToken;
  }
}

function getEncodedUrl(url: string, params: any) {
  if (params) if (params.data) return `${url}?${getEncodedParams(params.data)}`;

  return url;
}

function getEncodedParams(params: any) {
  let res = "";

  if (params === undefined || params === null) return res;

  Object.keys(params).forEach((value) => {
    res = `${res}${value}=${encodeURIComponent(params[value])}&`;
  });

  if (res) res = res.substring(0, res.length - 1);

  return res;
}

export function getCurrencies() {
  return httpGet<ApiCurrency>(`${getInsightApigeeBasePath()}/api/v1/projects/currencies`);
}

async function getFetchConfigBlob(method: any, params?: any) {
  let config = {
    method: method,
    credentials: "include",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + params.accessToken,
    },
  };

  return config as any;
}
