import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';
import { useGridApiRef, GridGroupNode } from '@weave-mui/data-grid';
import { CssBaseline } from '@weave-mui/material';
import i18n from '../../i18n';
import { ECDetailsConstructionDataGridProps, FilteredConstructions } from './types';
import { colDefToConstruction, getColumns, getRows, totalRowTitle } from './helperDataGrid';
import { calculateTotalsConstructionTable } from './utilsDataGrid';
import WeaveTableWrapper from './WeaveTable/WeaveTableWrapper';
import { DataGridContext } from './DataGridContext';
import { MaterialRow } from '../dashboard.models';

// It was necessary to create this component to be able to use the custom hook for the theme provider,
// the other component is a class type, and it is not possible to add the custom hook
type RowType = {
  path: string[];
};

type CustomClassCreatorParams = {
  row: {
    depth?: number;
  };
};

type handleCellClickParams = {
  rowNode: { type: string };
  field: string;
  row?: MaterialRow
}

const getTreeDataPath = (row: RowType) => row.path;

const ECDetailsConstructionDataGrid = (props: ECDetailsConstructionDataGridProps) => {
  const {
    filteredConstructions,
    useSI,
    ecDetailsPreference,
    selectConstructionWeave,
    renderEcDefinitionContentWeave,
    openAdvancedSearchWeave,
    disableVirtualization = false
  } = props;

  const dataGridContext = useContext(DataGridContext);
  const { isOpenQuickModal, setIsOpenQuickModal } = dataGridContext;
  
  const [totalsConstructionTable, setTotalsConstructionTable] = useState<FilteredConstructions>({
    constructionName: i18n.t('analysis.ec.construction.totalRowTitle'),
    area: null,
    totalEmbodiedCarbon: null,
    id: 'totalsConstructionTable',
  } as unknown as FilteredConstructions);
  const [constructions, setConstructions] = useState<FilteredConstructions[]>(filteredConstructions);
  const [selectedConstructionRow, setSelectedConstructionRow] = useState<Array<string | number>>([]);
  const [renderConstructionsId, setRenderConstructionsId] = useState<Array<string>>([]);
  const columnsProps = {
    useSI,
    renderEcDefinitionContentWeave,
    openAdvancedSearchWeave
  }
  const initialColumns = getColumns(columnsProps);
  const initialRows = getRows(constructions, totalsConstructionTable);
  const [columns, setColumns]= useState(initialColumns);
  const [rows, setRows]= useState(initialRows);

  const apiRef = useGridApiRef();
  const cellTypeRef = useRef<string>('');
  const cellColumnNameRef = useRef<string>('');
  const cellRowParentRef = useRef<string>('');

  const SUB_ROW_CLASS = 'MuiCustom-sub-row';
  // const DISABLE_ROW_CLASS = 'MuiCustom-disable-row';

  const pinnedRows = useMemo(() => ({
    bottom: rows.filter((row: MaterialRow) => row.id === totalRowTitle)
  }), [rows]);

  const { selectedConstructionId } = ecDetailsPreference;

  useEffect(() => {
    const unsubscribeRowExpansionChange = apiRef.current.subscribeEvent?.(
      'rowExpansionChange',
      (params: any) => toggleRowExpansion(params?.id.toString(), params?.childrenExpanded)
    );

    return () => {
      unsubscribeRowExpansionChange?.();
    };
  }, [apiRef]);

  useEffect(() => {
    const updatedColumns = getColumns(columnsProps);
    const updatedRows = getRows(constructions, totalsConstructionTable);
    setColumns(updatedColumns);
    setRows(updatedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useSI,totalsConstructionTable]);

  useEffect(() => {
    expandRow(selectedConstructionId);
    setSelectedConstructionRow((previousSelected: string[]) => {
      const isAlreadySelected = previousSelected.includes(selectedConstructionId);
      return isAlreadySelected ? previousSelected : [selectedConstructionId];
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConstructionId]);

  useEffect(() => {
    setTotalsConstructionTable(calculateTotalsConstructionTable(filteredConstructions));
  }, [filteredConstructions]);

  useEffect(() => {
    setConstructions(filteredConstructions);
    setIsOpenQuickModal(false)
  }, [filteredConstructions]);

  // Expands row that is currently hidden
  const expandRow = (constructionId: string) => {
    setRenderConstructionsId((previousExpanded: string[]) => {
      const isAlreadyExpanded = previousExpanded.includes(constructionId);
      return isAlreadyExpanded ? previousExpanded : [...previousExpanded, constructionId];
    });
  };

  // Hides row that is currently expanded
  const collapseRow = (constructionId: string) => {
    setRenderConstructionsId((previousExpanded: string[]) => {
      return previousExpanded.filter((id) => id !== constructionId);
    });
  };

  // Toggles the current expansion of a row
  const toggleRowExpansion = (constructionId: string, childrenExpanded: boolean) => {
    setRenderConstructionsId((previousExpanded: string[]) => {
      return childrenExpanded
        ? [...previousExpanded, constructionId]
        : previousExpanded.filter((id) => id !== constructionId);
    });
    setIsOpenQuickModal(false)
  };

  // Controls click behavior and row selection.
  const handleRowClick = (params: { row: MaterialRow }) => {
    const { id: materialId, constructionId, sha } = params.row;
    const PINNED_ROW_TITLE = i18n.t('analysis.ec.construction.constructionTotal');
    const CELL_TYPE_LEAFT = 'leaf';
    const CELL_COLUMN_NAME = 'ecDefinition';

    if (PINNED_ROW_TITLE === materialId || (cellTypeRef.current === CELL_TYPE_LEAFT && cellColumnNameRef.current === CELL_COLUMN_NAME)) {
      return;
    }

    if(constructionId === materialId){
      const isSelected = selectedConstructionRow.includes(constructionId);

      if (isSelected) {
        setSelectedConstructionRow([]);
        selectConstructionWeave({ constructionId: null, materialSha: null });
        collapseRow(constructionId);
        setIsOpenQuickModal(false);
      } else {
        setSelectedConstructionRow([constructionId, materialId])
        selectConstructionWeave({ constructionId, materialSha: sha });
        expandRow(constructionId);
        setIsOpenQuickModal(false);
      }

    } else {
      const isSelected = selectedConstructionRow.includes(constructionId) && selectedConstructionRow.includes(materialId);
      const newSelection = selectedConstructionRow.filter((id: string) => id !== materialId);
      if (isSelected) {
        setSelectedConstructionRow(newSelection);
        selectConstructionWeave({ constructionId, materialSha: null });
        setIsOpenQuickModal(false);
      } else {
        setSelectedConstructionRow([ constructionId, materialId]);
        selectConstructionWeave({ constructionId, materialSha: sha });
        setIsOpenQuickModal(false);
      }
    }
  };

  // Controls click cell behavior on ecDefinition column.
  const handleCellClick = (params: handleCellClickParams) => {
    const cellType = params.rowNode.type;
    const cellColumnName = params.field;
    const cellRowParent = params.row.constructionId;
    cellTypeRef.current = cellType;
    cellColumnNameRef.current = cellColumnName;
    cellRowParentRef.current = cellRowParent;
  }
  // Controls the automatic opening of the rows.
  const isGroupExpandedByDefault = (node: GridGroupNode) => {
    return renderConstructionsId.includes(node?.id.toString());
  }

  const customClassCreator = (params: CustomClassCreatorParams): string | null   => {
    const rowDepth = params.row.depth;

    if (rowDepth === 1) {
      return SUB_ROW_CLASS;
    }

    return null
  };

  return (
    <div
      id="construction-data-grid"
      className={isOpenQuickModal ? 'quick-is-open': ''}
      style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'hidden', height: '100%' }}
    >
      <CssBaseline/>
      <WeaveTableWrapper
        columnHeaderHeight={36}
        sortingOrder={['desc', 'asc']}
        getTreeDataPath={getTreeDataPath}
        pinnedRows={pinnedRows}
        apiRef={apiRef}
        // @ts-ignore
        groupingColDef={colDefToConstruction}
        rows={initialRows}
        columns={columns}
        onRowClick={handleRowClick}
        onCellClick={handleCellClick}
        rowSelectionModel={selectedConstructionRow}
        isGroupExpandedByDefault={isGroupExpandedByDefault}
        getRowClassName={customClassCreator}
        treeData
        disableChildrenSorting
        disableColumnMenu
        disableVirtualization={disableVirtualization}
        disableColumnReorder
      />
    </div>
  );
};

export default ECDetailsConstructionDataGrid;

