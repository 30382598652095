import React from 'react';
import { Button, buttonVariants } from '@weave-mui/button';
import i18n from '../../../../i18n';
import Box from '@weave-mui/box';
import {AlertS, CompleteS, ErrorS} from '@weave-mui/icons-weave';
import Typography from '@weave-mui/typography';
import {invalidFormulaRed, validFormulaGreen, warningFormulaOrange} from '../../../styles/colorCodes';
import {MetricEvaluationFormatedResult} from "../../../../types/metrics";

interface FormulaEvaluatorProps {
  metricEvaluationResult: MetricEvaluationFormatedResult,
  evaluateExpressionAction: () => void
}

export const FormulaEvaluator: React.FC<FormulaEvaluatorProps> = ({
  metricEvaluationResult,
  evaluateExpressionAction
  }) => {
  if (!metricEvaluationResult) {
    return <></>;
  }
  const {isError: isEvalError, isWarning, evaluationError: evalErrorString, formattedResult: evaluationResult } = metricEvaluationResult;
  const textColor = isEvalError ? invalidFormulaRed  : isWarning ? warningFormulaOrange : validFormulaGreen;

  return (
    <Box sx={
      {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pr: '2rem'
      }
    }>
      <Button
        sx={{ m: 0, p: 0 }}
        variant={buttonVariants.TEXT}
        onClick={evaluateExpressionAction}
        disabled={isEvalError}
      >
        {i18n.t('analysis.dataPoints.labels.evaluate')}
      </Button>
      {
        /* Hide text if there is an error but no explicit user facing string */
        ((isEvalError || isWarning) && evalErrorString === '') ? <div/> :
          <Box sx={{
            display: (evaluationResult !== "" || isEvalError || isWarning) ? 'flex' : 'none',
            alignItems: 'right',
            justifyContent: 'flex-end',
            gap: "0.3rem"
          }}>
            { isEvalError ? <ErrorS sx={{ color: textColor }}/> : isWarning ? <AlertS color="warning" /> : <CompleteS sx={{ color: validFormulaGreen }}/> }
            <Typography sx={{ color: textColor }}>
              { (isEvalError || isWarning) ? evalErrorString : i18n.t('analysis.dataPoints.metrics.formulaResult', { result: evaluationResult })}
            </Typography>
          </Box>
      }
    </Box>
  );
};
