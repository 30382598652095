import React, { Component } from 'react';
import {FlyoutMenuItem} from './common.models';

export type FlyoutMenuProps = {
    onItemChange: (item: FlyoutMenuItem) => void,
    items: FlyoutMenuItem[],
    menuTitle: string,
    optionSelected: string
}

export default class FlyoutMenu extends Component<FlyoutMenuProps> {

    constructor(props: FlyoutMenuProps) {
        super(props);
    }

    onItemChange = (item: FlyoutMenuItem) => {
        this.props.onItemChange(item);
    }

    render() {

        const { items, menuTitle, optionSelected } = this.props;
        return (
            <div className="flyout-menu">
                <div className="flyout-menu-title-container"><span className="flyout-menu-title">{menuTitle}</span></div>
                {
                    items.map(item => {
                        const menuItemText = item.value === optionSelected ? "flyout-menu-item-text selected" : "flyout-menu-item-text";
                        return <div key={item.value} className="flyout-menu-item-container" onClick={() => this.onItemChange(item)}>
                            <div className="flyout-menu-item-check">
                                {item.value === optionSelected && <CheckMark />}
                            </div>
                            <span className={menuItemText}>{item.text}</span>
                        </div>;
                    })
                }
            </div>
        );
    }
}

const CheckMark = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <polygon points="0.52 8.77 2.28 7 5.82 10.53 13.72 2.63 15.48 4.4 5.82 14.07 0.52 8.77"></polygon>
    </svg>;
};
