import * as consts from '../consts';
import * as conv from '../conversions';
import i18n from '../i18n';
import { KgToTons } from '../conversions';
import forgeUnitsService from '../forgeUnitsService';
import * as chartConsts from '../charts/localization/consts';
import { rsiConversion } from './rsiConversion';

export type CombinationGroupMap = {
  [consts.BASE]: string[];
  [consts.HVAC_FACTOR]: string[];
  GlazingCombinationGroupNorth: string[];
  GlazingCombinationGroupEast: string[];
  GlazingCombinationGroupSouth: string[];
  GlazingCombinationGroupWest: string[];
  [consts.BUILDING_ORIENTATION_FACTOR]: string[];
  [consts.PLUG_LOAD_EFFICIENCY_FACTOR]: string[];
  [consts.LIGHTING_EFFICIENCY_FACTOR]: string[];
  [consts.INFILTRATION]: string[];
  [consts.ROOF_CONSTRUCTION]: string[];
  [consts.WALL_CONSTRUCTION]: string[];
};

export type ECValues = {
  meanValue: number;
  maxValue: number;
  minValue: number;
  title: string;
};

export const combinationGroupMap: CombinationGroupMap = {
  [consts.BASE]: [consts.BASE],
  [consts.HVAC_FACTOR]: [consts.HVAC_FACTOR],
  GlazingCombinationGroupNorth: [
    consts.WWR_NORTHERN_WALLS,
    consts.WINDOW_SHADES_NORTH,
    consts.GLAZING_CONSTRUCTION_NORTH,
  ],
  GlazingCombinationGroupEast: [
    consts.WWR_EASTERN_WALLS,
    consts.WINDOW_SHADES_EAST,
    consts.GLAZING_CONSTRUCTION_EAST,
  ],
  GlazingCombinationGroupSouth: [
    consts.WWR_SOUTHERN_WALLS,
    consts.WINDOW_SHADES_SOUTH,
    consts.GLAZING_CONSTRUCTION_SOUTH,
  ],
  GlazingCombinationGroupWest: [
    consts.WWR_WESTERN_WALLS,
    consts.WINDOW_SHADES_WEST,
    consts.GLAZING_CONSTRUCTION_WEST,
  ],
  [consts.BUILDING_ORIENTATION_FACTOR]: [consts.BUILDING_ORIENTATION_FACTOR],
  [consts.PLUG_LOAD_EFFICIENCY_FACTOR]: [consts.PLUG_LOAD_EFFICIENCY_FACTOR],
  [consts.LIGHTING_EFFICIENCY_FACTOR]: [consts.LIGHTING_EFFICIENCY_FACTOR],
  [consts.INFILTRATION]: [consts.INFILTRATION],
  [consts.ROOF_CONSTRUCTION]: [consts.ROOF_CONSTRUCTION],
  [consts.WALL_CONSTRUCTION]: [consts.WALL_CONSTRUCTION],
};

//maps a factor to an object containing the parameter name for that factor,
//and the display values for that parameter.
export const factorMap = {
  [consts.BASE]: {},
  [consts.HVAC_FACTOR]: {
    parameterName: 'HvacSystem',
    values: ['ACVRF + DOAS', 'PTAC', 'VAV', 'FPFC + DOAS', 'PTHP', 'PVAV'],
  },
  [consts.WWR_NORTHERN_WALLS]: {
    parameterName: 'Wwr',
    values: [0, 0.15, 0.3, 0.4, 0.5, 0.65, 0.8, 0.95],
  },
  [consts.WWR_EASTERN_WALLS]: {
    parameterName: 'Wwr',
    values: [0, 0.15, 0.3, 0.4, 0.5, 0.65, 0.8, 0.95],
  },
  [consts.WWR_SOUTHERN_WALLS]: {
    parameterName: 'Wwr',
    values: [0, 0.15, 0.3, 0.4, 0.5, 0.65, 0.8, 0.95],
  },
  [consts.WWR_WESTERN_WALLS]: {
    parameterName: 'Wwr',
    values: [0, 0.15, 0.3, 0.4, 0.5, 0.65, 0.8, 0.95],
  },
  [consts.WINDOW_SHADES_NORTH]: {
    parameterName: 'OhProjectionFactor',
    values: [0.16, 0.25, 0.33, 0.5, 0.66],
  },
  [consts.WINDOW_SHADES_EAST]: {
    parameterName: 'OhProjectionFactor',
    values: [0.16, 0.25, 0.33, 0.5, 0.66],
  },
  [consts.WINDOW_SHADES_SOUTH]: {
    parameterName: 'OhProjectionFactor',
    values: [0.16, 0.25, 0.33, 0.5, 0.66],
  },
  [consts.WINDOW_SHADES_WEST]: {
    parameterName: 'OhProjectionFactor',
    values: [0.16, 0.25, 0.33, 0.5, 0.66],
  },
  [consts.GLAZING_CONSTRUCTION_NORTH]: {
    parameterName: 'WindowGlassTargetConstruction',
    values: ['Window Dbl LoE', 'Window Sgl Clr', 'Window Trp Clr', 'Window Trp LoE'],
  },
  [consts.GLAZING_CONSTRUCTION_EAST]: {
    parameterName: 'WindowGlassTargetConstruction',
    values: ['Window Dbl LoE', 'Window Sgl Clr', 'Window Trp Clr', 'Window Trp LoE'],
  },
  [consts.GLAZING_CONSTRUCTION_SOUTH]: {
    parameterName: 'WindowGlassTargetConstruction',
    values: ['Window Dbl LoE', 'Window Sgl Clr', 'Window Trp Clr', 'Window Trp LoE'],
  },
  [consts.GLAZING_CONSTRUCTION_WEST]: {
    parameterName: 'WindowGlassTargetConstruction',
    values: ['Window Dbl LoE', 'Window Sgl Clr', 'Window Trp Clr', 'Window Trp LoE'],
  },
  [consts.BUILDING_ORIENTATION_FACTOR]: {
    parameterName: 'Orientation',
    values: [0, 45, 90, 135, 180, 225, 270, 315],
  },
  [consts.PLUG_LOAD_EFFICIENCY_FACTOR]: {
    parameterName: 'ElecEquipmentLoadsEpd',
    values: [0.6, 1.0, 1.3, 1.6, 2.0, 2.6],
  },
  [consts.LIGHTING_EFFICIENCY_FACTOR]: {
    parameterName: 'LightingLoadsLpd',
    values: [0.3, 0.7, 1.1, 1.5, 1.9],
  },
  [consts.INFILTRATION]: {
    parameterName: 'SpaceInfFlowPerArea',
    values: [2.4, 2.1, 1.8, 1.5, 1.2, 0.9, 0.6, 0.3],
  },
  [consts.ROOF_CONSTRUCTION]: {
    parameterName: 'RoofConstructionTargetConstruction',
    values: [
      'Roof 10.25-inch SIP',
      'Roof R10',
      'Roof R15',
      'Roof R19',
      'Roof R38',
      'Roof R60',
      'Roof Uninsulated',
    ],
  },
  [consts.WALL_CONSTRUCTION]: {
    parameterName: 'WallConstructionTargetConstruction',
    values: [
      'Wall 12.25-inch SIP',
      'Wall 14-inch ICF',
      'Wall R13 Metal',
      'Wall R13 Wood',
      'Wall R13+R10 Metal',
      'Wall R2 CMU',
      'Wall R38 Wood',
      'Wall Uninsulated',
    ],
  },
};

//check if need to predict values - The values prediction should be done only with the factors of WWR and Shades
//factors : array of factors selected for the combination
//runs: runs simulated on backend
export const needToPredictValues = (factors, runs) => {
  if (factors.length === 1) {
    //if user only selected one factor
    const parameterName = factorMap[factors[0]].parameterName; //parameter name for the factor, this value is on factorMap
    if (
      parameterName === consts.WWR_PARAMETER_NAME ||
      parameterName === consts.SHADING_PARAMETER_NAME
    ) {
      //only predfict if factor is WWR or shading
      for (const value of factorMap[factors[0]].values) {
        if (!runs.some((x) => x.parameter[parameterName] === value)) {
          //check if all the results for this factors are simulated
          return true;
        }
      }
    } else {
      return false;
    }
  } else {
    return true;
  }
};

const extractZeroWwrRun = (runs) => {
  let storeWwrrun = null;
  runs.forEach((run) => {
    if (run.parameter.Wwr === 0) {
      storeWwrrun = run;
    }
  });
  return storeWwrrun;
};

// this method will predict values for runs no simulated on backend
// factor: array of factors selected by user for the combination
// runs: simulations from backend
// baseValue: result for base simulation
// see https://wiki.autodesk.com/pages/viewpage.action?spaceKey=aeceng&title=ECR+Calculations
export const predictValues = (factors, runs, baseValue, baseRun) => {
  // ToDo Define the type of the baseRun object which comes from the reducer. Line 623
  let glazingFactor = null;
  let shadingFactor = null;
  let wwrFactor = null;
  let shadingParameters = [null]; //null represents the no change, when the factor is no selected
  let glazingParameters = [null]; //null represents the no change, when the factor is no selected
  let wwrParameters = [0]; // 0 represents the no change, when the factor is no selected
  const wwrParameterName = consts.WWR_PARAMETER_NAME; //this is the value for the wwr parameter, to use on predicted runs
  const glazingParameterName = consts.GLAZING_PARAMETER_NAME; //this is the value for the glazing parameter, to use on predicted runs
  const shadingParameterName = consts.SHADING_PARAMETER_NAME; //this is the value for the wwr parameter, to use on predicted runs

  //set the values for the factors selected
  factors.forEach((factor) => {
    const factorConfig = factorMap[factor];
    if (factorConfig.parameterName === glazingParameterName) {
      //check if glazing was selected
      glazingFactor = factorConfig;
      glazingParameters = [null, ...glazingFactor.values]; //null represents the no change, this value is not in the array on the config
    }
    if (factorConfig.parameterName === shadingParameterName) {
      //check if shading was selected
      shadingFactor = factorConfig;
      shadingParameters = [null, ...shadingFactor.values]; //null represents the no change, this value is not in the array on the config
    }
    if (factorConfig.parameterName === wwrParameterName) {
      //check if wwr was selected
      wwrFactor = factorConfig;
      wwrParameters = wwrFactor.values;

      // Get MyModel input for WWR from the base run to include it in the parameter group
      let wwrBaseInput = 0;

      switch (factor) {
        case consts.WWR_EASTERN_WALLS:
          wwrBaseInput = conv.round(baseRun.inputWwrEastValue / 100, 2);
          break;
        case consts.WWR_NORTHERN_WALLS:
          wwrBaseInput = conv.round(baseRun.inputWwrNorthValue / 100, 2);
          break;
        case consts.WWR_SOUTHERN_WALLS:
          wwrBaseInput = conv.round(baseRun.inputWwrSouthValue / 100, 2);
          break;
        case consts.WWR_WESTERN_WALLS:
          wwrBaseInput = conv.round(baseRun.inputWwrWestValue / 100, 2);
          break;
        default:
          wwrBaseInput = 0;
      }

      if (!wwrParameters.includes(wwrBaseInput)) {
        wwrParameters.push(wwrBaseInput);

        wwrParameters = [...wwrParameters.sort((a, b) => a - b)];
      }
    }
  });

  let outPutRuns = [...runs]; //clone the runs to create the output array and later push new elements
  let inputRuns = runs.map((x) => {
    return { ...x, value: x.value - baseValue };
  }); //clone the runs to create input array and modify the value to substract the base value

  //predict Values for WWR
  if (wwrFactor) {
    //if wwr was selected
    let zeroWwrRun = null;

    // generate missing simulations
    if (
      (shadingFactor && runs.length === 10) || // if the shading factor is selected number of simulation runs required is 4 * 3 = 12 (but we generated only 10 hence we fill it)
      (glazingFactor && runs.length === 16) || // if the galzing factor is selected number of simulation runs required is 4 * 5 = 20 (but we generated only 16 hence we fill it)
      (shadingFactor && glazingFactor && runs.length === 46)
    ) {
      // if the galzing and shading factor is selected number of simulation runs required is 4 * 5 * = 20 (but we generated only 16 hence we fill it) {
      zeroWwrRun = extractZeroWwrRun(outPutRuns); // get the zero wwr run

      glazingParameters.forEach((glazingParameterValue) => {
        //iterate over the parameters for glazing, when glazing is not selected array will be [null]
        shadingParameters.forEach((shadingParameterValue) => {
          //iterate over the parameters for shading, when shading is not selected array will be [null]

          const combinationRuns = inputRuns.filter(
            (run) =>
              (!shadingFactor || //just uses the shading filter if factor was selected
                run.parameter[shadingParameterName] === shadingParameterValue) &&
              (!glazingFactor || //just uses the glazing filter if factor was selected
                run.parameter[glazingParameterName] === glazingParameterValue)
          );

          if (combinationRuns.length > 0) {
            // this means zero wwr runs are missing populate it
            // the zero wwr runs have same value
            if (combinationRuns.length === 3 && zeroWwrRun) {
              let newRun = {
                parameter: Object.assign({}, zeroWwrRun.parameter),
                value: zeroWwrRun.value,
              };
              if (shadingFactor) {
                newRun.parameter.OhProjectionFactor = shadingParameterValue;
              }
              if (glazingFactor) {
                newRun.parameter.WindowGlassTargetConstruction = glazingParameterValue;
              }

              outPutRuns.push(newRun);
            }
          }
        });
      });
    }

    inputRuns = outPutRuns.map((x) => {
      return { ...x, value: x.value - baseValue };
    }); //clone the runs to create input array and modify the value to substract the base value

    glazingParameters.forEach((glazingParameterValue) => {
      //iterate over the parameters for glazing, when glazing is not selected array will be [null]
      shadingParameters.forEach((shadingParameterValue) => {
        //iterate over the parameters for shading, when shading is not selected array will be [null]

        const combinationRuns = inputRuns.filter(
          (run) =>
            (!shadingFactor || //just uses the shading filter if factor was selected
              run.parameter[shadingParameterName] === shadingParameterValue) &&
            (!glazingFactor || //just uses the glazing filter if factor was selected
              run.parameter[glazingParameterName] === glazingParameterValue)
        );

        if (combinationRuns.length > 0) {
          // Predict values including the base run value
          const predictedValues = wwrPrediction(combinationRuns, wwrFactor, baseValue);

          predictedValues.forEach((predicted) => {
            let newRun = createNewRun(
              predicted.predictedValue,
              wwrParameterName,
              predicted.wwrParameterValue,
              glazingParameterName,
              glazingParameterValue,
              shadingParameterName,
              shadingParameterValue
            );
            outPutRuns.push(newRun);
          });
        }
      });
    });
  }

  // predict shading values
  if (shadingFactor) {
    //if shading was selected after found predicted Values for WWR
    glazingParameters.forEach((glazingParameterValue) => {
      //iterate over the parameters for glazing, when glazing is not selected array will be [null]
      wwrParameters.forEach((wwrParameterValue) => {
        //iterate over the parameters for wwr

        const shadingCombinationRuns = outPutRuns.filter(
          (run) =>
            (!wwrFactor || //just uses the wwr filter if factor was selected
              run.parameter[wwrParameterName] === wwrParameterValue) &&
            (!glazingFactor || run.parameter[glazingParameterName] === glazingParameterValue)
        ); //just uses the glazing filter if factor was selected

        if (shadingCombinationRuns.length > 0) {
          //these runs include simulated and predicted of first order

          shadingCombinationRuns.sort(
            (a, b) => a.parameter[shadingParameterName] - b.parameter[shadingParameterName]
          ); //order the parameter this way the null (no change will be first)

          const predictedValues = shadingPrediction(
            shadingCombinationRuns,
            shadingParameters,
            shadingParameterName,
            baseValue
          );
          predictedValues.forEach((predicted) => {
            //iterate over predicted values to create the new runs

            let newRun = createNewRun(
              predicted.predictedValue,
              wwrParameterName,
              wwrParameterValue, //create a new run and add it to the array for outputs
              glazingParameterName,
              glazingParameterValue,
              shadingParameterName,
              predicted.shadingParameterValue
            );
            outPutRuns.push(newRun);
          });
        }
      });
    });
  }

  return outPutRuns; //return simularted and predicted runs
};

//this method will use first order prediction
//runs: runs simulated on backend
//wwrFactor: configuration for wwr
//baseValue: result for base run simulation
const wwrPrediction = (runs, wwrFactor, baseValue) => {
  const parameters = [...wwrFactor.values];

  const wwrParameters = [...parameters.sort((a, b) => a - b)]; //order the wwr parameters this way 0 will be first
  const parameterName = wwrFactor.parameterName;
  const outPutRuns = [];

  wwrParameters.forEach((wwrParameterValue, index) => {
    //iterate over wwrParameters [0,15,30,40,50,65,80,95]

    const result = runs.find((x) => x.parameter[parameterName] === wwrParameterValue); //find the simulations from backend filtering by the wwr parameter

    if (!result) {
      //if not result will predict it, this is for parameters [15,40,50,80]
      const predictedValue = firstOrderPrediction(index, wwrParameters, runs, parameterName);
      outPutRuns.push({
        wwrParameterValue,
        predictedValue: conv.round(predictedValue + baseValue, 4), //substract the base value, this is the value to display
      });
    }
  });

  return outPutRuns; //return only predcited runs
};

//this method will use second order prediction
//runs: runs simulated on backend and predicted for WWR
//shadingParamters: parameters on the configuration for shading + null (no change) [null, 0.16, 0.25, 0.33, 0.5, 0.66]
//shading parameter name: parameter name on the configuration for shading
const shadingPrediction = (runs, shadingParameters, shadingParameterName, baseValue) => {
  const predictedValues = [];

  runs.sort((a, b) => a.parameter[shadingParameterName] - b.parameter[shadingParameterName]);

  shadingParameters.forEach((shadingParameterValue) => {
    //iterate over runs sorted according the shading parameters values (first the runs with shading == null)

    const result = runs.find(
      (run) => run.parameter[shadingParameterName] === shadingParameterValue
    ); //find the runs simulated and predcited for the shading parameter (will be null for 0.16, 0.25, 0.33)

    if (!result) {
      //if not result will predict it
      const predictedValue = secondOrderPrediction(
        runs,
        shadingParameterName,
        shadingParameterValue,
        baseValue
      );
      predictedValues.push({
        shadingParameterValue,
        predictedValue,
      });
    }
  });

  return predictedValues;
};

//apply the first order formula according wiki https://wiki.autodesk.com/pages/viewpage.action?spaceKey=aeceng&title=ECR+Calculations
//parameterIndex: gives the index on the parameter array for the value to predict this way will find X
//parameterValues: array of parameters for WWR [0,15,30,40,50,65,80,95]
//runs: runs simulated on backend
//parameterName: for this case will be wwr
const firstOrderPrediction = (parameterIndex, parameterValues, runs, parameterName) => {
  const run0 = getRun0(parameterIndex, runs, parameterValues, parameterName); //find the next run with a lower parameter value eg: if x == 15 the will be the run for parameter 0
  const run1 = getRun1(parameterIndex, runs, parameterValues, parameterName); //find the next run with a lower parameter value eg: if x == 15 the will be the run for parameter 30
  const x0 = run0.parameter[parameterName]; //get parameter value
  const y0 = run0.value; //get result value
  const x1 = run1.parameter[parameterName]; //get parameter value
  const y1 = run1.value; //get result value
  const x = parameterValues[parameterIndex];

  return y0 * ((x - x1) / (x0 - x1)) + y1 * ((x - x0) / (x1 - x0)); //apply the formula as documented in the wiki
};

//apply the second order formula according wiki https://wiki.autodesk.com/pages/viewpage.action?spaceKey=aeceng&title=ECR+Calculations
//runs: runs simulated on backend and predicted for wwr
//shadingParameter: value on the configuration for shading factors
//shadingParameterValue: parameter value for the run to predict

const secondOrderPrediction = (runs, shadingParameter, shadingParameterValue, baseValue) => {
  // justOneFactor means that the shading factor is the only factor that has been included from the group of Shading related factors (wwr, shading and glazing)
  // When only the shading factor has been selected, only 2 simulations are generated per facade, in that case the value of x0 must be null and the value of y0 must be the value of the base simulation.
  const justOneFactor = runs.length === 2;

  const x = shadingParameterValue;
  const run0 = runs[0]; //take the first simulation, will be the one with parameter === null
  const run1 = justOneFactor ? runs[0] : runs[1]; //take the second simulation, will be the one with parameter === 0.33
  const run2 = justOneFactor ? runs[1] : runs[2]; //take the third simulation, will be the one with parameter === 0.66
  const x0 = justOneFactor ? null : run0.parameter[shadingParameter]; //get parameter value eg: null
  const x1 = run1.parameter[shadingParameter]; //get parameter value eg: 0.33
  const x2 = run2.parameter[shadingParameter]; //get parameter value eg: 0.66
  const y0 = justOneFactor ? baseValue : run0.value; //get result value for null
  const y1 = run1.value; //get result value for 0.33
  const y2 = run2.value; //get result value for 0.66

  //apply the formula as documented in the wiki
  return (
    y0 * (((x - x1) * (x - x2)) / ((x0 - x1) * (x0 - x2))) +
    y1 * (((x - x0) * (x - x2)) / ((x1 - x0) * (x1 - x2))) +
    y2 * (((x - x0) * (x - x1)) / ((x2 - x0) * (x2 - x1)))
  );
};

//get the lowest run simulated for the parameter Value selected eg: Parameter Value to predict 0.15 then run0 is 0
const getRun0 = (parameterIndex, runs, parameterValues, parameterName) => {
  for (var i = parameterIndex - 1; i >= 0; i--) {
    const run = runs.find((x) => x.parameter[parameterName] === parameterValues[i]);
    if (run) {
      return run;
    }
  }
};

//get the highest run simulated for the parameter Value selected eg: Parameter Value to predict 0.15 then run0 is 0.3
const getRun1 = (parameterIndex, runs, parameterValues, parameterName) => {
  for (var i = parameterIndex + 1; i < parameterValues.length; i++) {
    const run = runs.find((x) => x.parameter[parameterName] === parameterValues[i]);
    if (run) {
      return run;
    }
  }
};

//create the new run with the parameters for factors selected
const createNewRun = (
  value,
  wwrParameter,
  wwrValue,
  glazingParameter,
  glazingValue,
  shadingParameter,
  shadingValue
) => {
  let newRun = {
    parameter: {},
    value: value,
  };

  if (wwrParameter) {
    newRun.parameter[wwrParameter] = wwrValue;
  }
  if (glazingParameter) {
    newRun.parameter[glazingParameter] = glazingValue;
  }
  if (shadingParameter) {
    newRun.parameter[shadingParameter] = shadingValue;
  }

  return newRun;
};

//Using the expanded set of runs, the display values for the current factor
//are calculated filtering the runs by the parameter values and doing an average.
export const getDisplayValues = (
  runs,
  baseRunX,
  baseRunY,
  factor,
  baseRun,
  needToPredictBaseRun
) => {
  const parameterName = factorMap[factor].parameterName;
  const displayValues = [];

  // If the factor is not Glazing related factor, the values of the initially generated base run must be assigned.
  if (
    factor !== consts.WINDOW_SHADES_NORTH &&
    factor !== consts.WINDOW_SHADES_EAST &&
    factor !== consts.WINDOW_SHADES_SOUTH &&
    factor !== consts.WINDOW_SHADES_WEST &&
    factor !== consts.GLAZING_CONSTRUCTION_NORTH &&
    factor !== consts.GLAZING_CONSTRUCTION_EAST &&
    factor !== consts.GLAZING_CONSTRUCTION_SOUTH &&
    factor !== consts.GLAZING_CONSTRUCTION_WEST &&
    factor !== consts.WWR_SOUTHERN_WALLS &&
    factor !== consts.WWR_NORTHERN_WALLS &&
    factor !== consts.WWR_WESTERN_WALLS &&
    factor !== consts.WWR_EASTERN_WALLS
  ) {
    displayValues.push({
      isBaseRun: true,
      x: baseRunX,
      y: baseRunY,
      parameterName,
    });
  } else {
    // If the factor is glazing related factor, it must be identified if the value of the base run should be predicted, otherwise the values of the initially generated base run should be assigned.
    if (needToPredictBaseRun) {
      let runsByFactorValue = [];

      // if the factor is WWR, the input must be taken from the generated base run. Otherwise, the null value is taken, which is the NoChange parameter for the glazing and shading factors.
      if (
        factor === consts.WWR_SOUTHERN_WALLS ||
        factor === consts.WWR_NORTHERN_WALLS ||
        factor === consts.WWR_WESTERN_WALLS ||
        factor === consts.WWR_EASTERN_WALLS
      ) {
        let wwrBaseInput = 0;

        switch (factor) {
          case consts.WWR_EASTERN_WALLS:
            wwrBaseInput = conv.round(baseRun.inputWwrEastValue / 100, 2);
            break;
          case consts.WWR_NORTHERN_WALLS:
            wwrBaseInput = conv.round(baseRun.inputWwrNorthValue / 100, 2);
            break;
          case consts.WWR_SOUTHERN_WALLS:
            wwrBaseInput = conv.round(baseRun.inputWwrSouthValue / 100, 2);
            break;
          case consts.WWR_WESTERN_WALLS:
            wwrBaseInput = conv.round(baseRun.inputWwrWestValue / 100, 2);
            break;
          default:
            wwrBaseInput = 0;
        }

        runsByFactorValue = runs.filter((x) => x.parameter[parameterName] === wwrBaseInput);

        //The number of WWR parameters is 8, if there is an additional one this means that the value of the MyModel input was included
        //and now it must be excluded so that it is not taken into account in the parameters of the simulations that are different from the base
        if (
          factorMap[factor].values.length === 9 &&
          factorMap[factor].values.includes(wwrBaseInput)
        ) {
          factorMap[factor].values = factorMap[factor].values.filter((x) => x !== wwrBaseInput);
        }
      } else {
        runsByFactorValue = runs.filter((x) => x.parameter[parameterName] === null);
      }

      if (runsByFactorValue.length > 0) {
        const totalValues = runsByFactorValue.reduce((previous, current) => {
          return previous + current.value;
        }, 0);

        const displayValue =
          runsByFactorValue.length > 0 ? totalValues / runsByFactorValue.length : 0;

        displayValues.push({
          isBaseRun: true,
          x: baseRunX,
          y: displayValue,
          parameterName,
        });
      } else {
        displayValues.push({
          isBaseRun: true,
          x: baseRunX,
          y: baseRunY,
          parameterName,
        });
      }
    } else {
      displayValues.push({
        isBaseRun: true,
        x: baseRunX,
        y: baseRunY,
        parameterName,
      });
    }
  }

  // generate values to display different from the base run.
  factorMap[factor].values.forEach((factorValue) => {
    const runsByFactorValue = runs.filter((x) => x.parameter[parameterName] === factorValue);

    if (runsByFactorValue.length > 0) {
      const totalValues = runsByFactorValue.reduce((previous, current) => {
        return previous + current.value;
      }, 0);

      const displayValue =
        runsByFactorValue.length > 0 ? totalValues / runsByFactorValue.length : 0;

      displayValues.push({
        isBaseRun: false,
        x: factorValue,
        y: displayValue,
        parameterName,
      });
    }
  });

  return displayValues;
};

export const getParametersForFactor = (factor) => {
  return factorMap[factor].values;
};

export const convertECValuesForMeter = (values: ECValues, useSI: boolean): ECValues => {
  const convertToImperial = (value: number): number => {
    const imperialValuePounds = forgeUnitsService.convertKGm2toPoundsFt2(value);
    const imperialValueKg = forgeUnitsService.convertPoundstoKg(imperialValuePounds);
    return Number(Math.round(imperialValueKg));
  };

  if (useSI) {
    const hasThreeDigits: boolean = values.meanValue >= 1000;

    if (hasThreeDigits) {
      return {
        meanValue: KgToTons(values.meanValue),
        maxValue: KgToTons(values.maxValue),
        minValue: KgToTons(values.minValue),
        title: i18n.t(chartConsts.ECMT_SI_UNITS),
      };
    }
    return values;
  } else {
    const imperialValues: ECValues = {
      meanValue: convertToImperial(values.meanValue),
      minValue: convertToImperial(values.minValue),
      maxValue: convertToImperial(values.maxValue),
      title: i18n.t(chartConsts.ECKG_IP_UNITS),
    };

    const hasThreeDigits: boolean = imperialValues.meanValue >= 1000;

    if (hasThreeDigits) {
      return {
        meanValue: KgToTons(imperialValues.meanValue),
        maxValue: KgToTons(imperialValues.maxValue),
        minValue: KgToTons(imperialValues.minValue),
        title: i18n.t(chartConsts.ECMT_IP_UNITS),
      };
    }
    return imperialValues;
  }
};
export const convertECDefinitionsm3toft3 = (
  value: number,
  isSelectedEcDefinition: boolean = false
) => {
  const imperialValuePounds = forgeUnitsService.convertKgm3toPoundsFt3(value);
  const imperialValueKg = forgeUnitsService.convertPoundstoKg(imperialValuePounds);
  return Number(isSelectedEcDefinition ? imperialValueKg : imperialValueKg.toFixed(2));
};
export const convertECDefinitionsm2toft2 = (
  value: number,
  isSelectedEcDefinition: boolean = false
): number => {
  const imperialValuePounds = forgeUnitsService.convertKGm2toPoundsFt2(value);
  const imperialValueKg = forgeUnitsService.convertPoundstoKg(imperialValuePounds);
  return Number(isSelectedEcDefinition ? imperialValueKg : imperialValueKg.toFixed(2));
};
export const convertECDefinitionsmtoft = (
  value: number,
  isSelectedEcDefinition: boolean = false
): number => {
  const imperialValuePounds = forgeUnitsService.convertKGmtoPoundsFt(value);
  const imperialValueKg = forgeUnitsService.convertPoundstoKg(imperialValuePounds);
  return Number(isSelectedEcDefinition ? imperialValueKg : imperialValueKg.toFixed(2));
};
export const convertECDefinitionsKgtoPounds = (
  value: number,
  isSelectedEcDefinition: boolean = false
) => {
  const imperialValuePounds = forgeUnitsService.convertKgKgToKgPounds(value);
  return Number(isSelectedEcDefinition ? imperialValuePounds : imperialValuePounds.toFixed(2));
};
export const convertECDefinitionsRSItoR = (
  value: number,
  isSelectedEcDefinition: boolean = false
): number => {
  const imperialValueFeet = forgeUnitsService.convertKGm2toPoundsFt2(value);
  const imperialValueKg = forgeUnitsService.convertPoundstoKg(imperialValueFeet);
  const imperialValueR = rsiConversion(imperialValueKg);
  return Number(isSelectedEcDefinition ? imperialValueR : imperialValueR.toFixed(2));
};
