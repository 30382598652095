import React, { ForwardedRef, useCallback } from 'react';
import { HamburgerCloseUI } from '@weave-mui/icons-weave';
import { DataPointBase, FormulaToken, Unit } from '../../../../types/metrics';
import { Chip } from '@weave-mui/material';
import { v4 as uuidv4 } from "uuid";
import { colorHandler, getTokenLabel } from '../../../utils/formulaUtils';

interface FormulaParameterProps {
  token: FormulaToken;
  isReadOnly: boolean;
  position?: number;
  onDelete: (position: number) => void;
  unit: Unit;
}

const FormulaParameter = React.forwardRef(({token, isReadOnly, position, onDelete}: FormulaParameterProps, ref: ForwardedRef<any>) => {
  const onDeleteCb = useCallback(() => {
    if (onDelete) {
      onDelete(position);
    }
  }, [position, onDelete]);

  const parameter = token.isParameter ? (token.parameter as DataPointBase) : undefined;

  return (
    parameter
      ?
        <>
          <Chip
            key={uuidv4()}
            data-param-id={parameter.id}
            ref={ref}
            label = {getTokenLabel(parameter, token.unit)}
            onDelete= {isReadOnly ? undefined : onDeleteCb}
            sx = {
              {
                ...colorHandler(parameter.type),
                textColor: 'white',
                marginLeft:'5px',
                marginRight: '5px',
                '.MuiChip-deleteIcon': {
                  color: 'white',
                  width: '30px',
                  height: '30px'
                },
                '.MuiChip-deleteIcon:hover': {
                  color: 'white'
                }
              }
            }
            contentEditable={false}
            deleteIcon={isReadOnly ? <></> : <HamburgerCloseUI/>}
          />
          &#8203;
        </> //Zero-Width Space (U+200B)
      : <>{token.parameter}</>
  )
});

export default FormulaParameter;
