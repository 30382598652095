import Box from '@weave-mui/box';
import Divider, { dividerOrientations, dividerVariants } from '@weave-mui/divider';
import IconButton from '@weave-mui/icon-button';
import { CollapsePanelS, ExpandPanelS } from '@weave-mui/icons-weave';
import React from 'react';
import styles from '../../styles/sideNavigation.styles';

interface SideToggleButtonProps {
  onClick: () => void;
  collapsed: boolean;
}

const SideToggleButton: React.FC<SideToggleButtonProps> = ({ onClick, collapsed }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={styles.toggleButtonContainer} data-testid='side-toggle-button-container'>
        <Divider
          data-testid='side-toggle-button-divider' 
          orientation={dividerOrientations.HORIZONTAL}
          variant={dividerVariants.FULLWIDTH}
          sx={styles.toggleButtonDivider}
        />
      </Box>
      <IconButton
        data-testid='side-toggle-button-icon' 
        onClick={onClick}
        disableRipple
        sx={styles.toggleButtonIcon}
      >
        {
          collapsed
            ? <ExpandPanelS/>
            : <CollapsePanelS/>
        }
      </IconButton>
    </Box>
  )
};

export default SideToggleButton;
