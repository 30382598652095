export async function customCSS(language) {

    switch (language) {
        case "zh-TW":
            await import(`./zh-TW/icons.css`);
            return;
        default:
            await import(`./default/icons.css`);
            return;
    }
}

