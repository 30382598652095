import React from 'react';
import ModalPopup, {IconHeader} from '../analysis/ModalPopup';
import i18n from '../i18n';
import * as locConsts from '../analysis/localization/consts';
export interface IModalProps {
  title: string;
  text: string;
  textPrimaryBn?: string;
  textSecondaryBn?: string;
  hideCancel?: boolean;
  iconHeader?: IconHeader;
  onLinkClick: () => void;
  onClose: () => void;
}
interface IDeleteEcDefinitionModal {
  showModal: boolean;
  modalProps: IModalProps;
}

const NotificationModal: React.FC<IDeleteEcDefinitionModal> = ({ showModal, modalProps }) => {
  const {
    title,
    text,
    hideCancel,
    textPrimaryBn,
    textSecondaryBn,
    iconHeader,
    onLinkClick,
    onClose
  } = modalProps;

  const text2ndBn = hideCancel
    ? ''
    : (textSecondaryBn ?? i18n.t(locConsts.EC_DELETE_DEFINITION_MODAL_SECONDARY_BN));

  return (
    <ModalPopup
      open={showModal}
      title={title}
      text={text}
      disablePortal
      textPrimaryBn={textPrimaryBn ?? i18n.t(locConsts.EC_DELETE_DEFINITION_MODAL_PRIMARY_BN)}
      textSecondaryBn= {text2ndBn}
      onCloseClick={onClose}
      onLinkClick={onLinkClick}
      iconHeader={iconHeader}
    />
  );
};

export default NotificationModal;
