import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import FormLabel, { formLabelVariants } from '@weave-mui/form-label';
import Box from '@weave-mui/box';
import { FormControl } from '@weave-mui/form-control';
import { Radio, RadioGroup } from '@weave-mui/material';
import { FormControlLabel } from '@mui/material';
import Dropdown from '@weave-design/dropdown';
import { unitsDropdownStylesheet } from '../../../styles/metrics.stylesheet';
import {AdvancedFactor, Factor, SimulationFactor} from '../../../../types/metrics';
import { isSimulationFactor } from '../../../utils/factorsUtils';
import i18n from '../../../../i18n';
import { newDataPointId } from '../../../utils/dataPointsUtils';

interface SimulationControlProps {
  selectedFactor: Factor | SimulationFactor;
  simulationFactors: SimulationFactor[];
  isReadOnly: boolean;
  setSelectedFactor: Dispatch<SetStateAction<Factor | SimulationFactor>>;
}

enum FactorTypes {
  SIMULATION = 'simulation',
  BASIC = 'basic',
}

type FactorType = FactorTypes.SIMULATION | FactorTypes.BASIC;

const generateFactorType = (factor: Factor | SimulationFactor): FactorType =>
  isSimulationFactor(factor)
    ? FactorTypes.SIMULATION
    : FactorTypes.BASIC;

const SimulationControl: React.FC<SimulationControlProps> = ({simulationFactors, isReadOnly, selectedFactor, setSelectedFactor }) => {
  const [factorType, setFactorType] = useState<FactorType>(selectedFactor && generateFactorType(selectedFactor));
  // const [filter, setFilter] = useState<string>('');

  useEffect(() => {
    setFactorType(generateFactorType(selectedFactor))
  }, [selectedFactor.id]);

  const toggleFactorType = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value: FactorType = event.target.value as unknown as FactorType;
    setFactorType(value);
    if (value === FactorTypes.BASIC) {
      setSelectedFactor(prevFactor => {
        const updatedFactor = { ...prevFactor } as SimulationFactor;

        if (updatedFactor.simulationParameter) {
          delete updatedFactor.simulationParameter;
        }

        return updatedFactor;
      })
    }
  }, []);

  // disabled search functionality due to a bug
  //

  // const onParametersSearch = useCallback((option) => {
  //   setFilter(option.currentTarget.value);
  // }, [setSelectedFactor]);

  // const onParameterBlur = useCallback(() => {
  //   setFilter('');
  // }, []);

  const onParameterChange = useCallback((option) => {
    const selectedOOTBFactorId = option as string;
    setSelectedFactor(prevFactor => {
      return {
        ...prevFactor,
        simulationParameter: selectedOOTBFactorId
      }
    });
  }, []);

  const getParameterNameById = useCallback((id: string): string => {
    return simulationFactors.find(p => p.id === id)?.displayName ?? '';
  },[simulationFactors]);

  return (
    <Box>
      <FormControl
        sx={
          {
            display: 'flex',
            flexDirection: 'row',
            pt: '1rem',
            width: '100%',
          }
        }
      >
        <FormLabel
          sx={
            {
              alignSelf: 'flex-start',
              p: 0,
              pt: '0.4rem',
              width: '100px',
              minWidth: '100px'
            }
          }
          variant={formLabelVariants.SIDE}
        >
          {i18n.t('analysis.dataPoints.factors.simulation.label.radioControl')}
        </FormLabel>
        <Box  sx={
          {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '50px'
          }
        }>
          <Box sx={
            {
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'nowrap'
            }
          }>
            <RadioGroup
              aria-labelledby='factors-radio-buttons-group-label'
              name='factors-radio-buttons-group'
              data-testid='factors-radio-buttons-group'
              defaultValue={false}
              value={factorType}
              onChange={toggleFactorType}
              sx={{
                '& .Mui-disabled': {
                  opacity: '0.8 !important',
                },
                '& .MuiFormControlLabel-label.Mui-disabled':{
                  color: 'rgba(0,0,0,1) !important',
              }}}
            >
              <FormControlLabel
                value={FactorTypes.BASIC}
                control={<Radio disabled={ isReadOnly || selectedFactor.id !== newDataPointId }/>}
                label={i18n.t('analysis.dataPoints.factors.simulation.radioButton.nonSimulationFactor')}
                data-testid='factors-radio-button-nonSimulationFactor'
              />
              <FormControlLabel
                value={FactorTypes.SIMULATION}
                control={<Radio disabled={ isReadOnly || selectedFactor.id !== newDataPointId }/>}
                label={i18n.t('analysis.dataPoints.factors.simulation.radioButton.simulationFactor')}
                data-testid='factors-radio-button-simulationFactor'
              />
            </RadioGroup>
          </Box>
        </Box>
      </FormControl>
      {
        factorType === FactorTypes.SIMULATION && (
          <FormControl sx={
            {
              display: 'flex',
              flexDirection: 'row',
              pt: '1rem',
              width: '50% !important',
            }
          }>
            <FormLabel
              sx={
                {
                  alignSelf: 'flex-start',
                  p: 0,
                  pt: '0.4rem',
                  width: '100px',
                  minWidth: '100px'
                }
              }
              variant={formLabelVariants.SIDE}
            >
              {i18n.t('analysis.dataPoints.factors.simulation.label.dropdown')}
            </FormLabel>
            <Box  sx={
              {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '50px'
              }
            }>
              <Box sx={
                {
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap'
                }
              }>
                <Dropdown
                  stylesheet={unitsDropdownStylesheet(isReadOnly)}
                  onChange={onParameterChange}
                  data-testid='factors-parameter-dropdown'
                  options={
                    simulationFactors.map(p => p.id)
                  }
                  formatOption={(id: string) => getParameterNameById(id)}
                  value={(selectedFactor as AdvancedFactor).simulationFactor || ''}
                  placeholder={i18n.t('analysis.dataPoints.factors.simulation.placeholder.dropdown')}
                  disabled={isReadOnly}
                  typable={true}
                  // onBlur={onParameterBlur}
                  // onInputChange={onParametersSearch}
                />
              </Box>
            </Box>
          </FormControl>
        )
      }
    </Box>
  )
}

export default SimulationControl;
