import { combineReducers } from 'redux';
import * as actionTypes from './actionTypes';
import { Dashboard, ModelEmbodiedCarbonBreakdownEntity } from "../dashboard.models";
import { deleteECDefinitionAssignments, updateEcBreakdown } from "./utils";

export type EcState = { modelECBreakdown?: ModelEmbodiedCarbonBreakdownEntity, errorInEcAnalysis?: boolean, isUnauthorizedAccess?: boolean };

const embodiedCarbonReducer = (state: { [key: string]: Dashboard & EcState } = {}, action) => {
    const newState = {};
    switch (action.type) {
        case actionTypes.INIT_EC_PAGE_SUCCESS:
            return {
                ...state,
                [action.model.id]: dashboard(state[action.model.id], action)
            };
        case actionTypes.SET_EC_PAGE_DATA_SUCCESS:
            return {
                ...state,
                [action.data.modelId]: {
                    ...state[action.data.modelId],
                    modelECBreakdown: action.data.modelECBreakdown,
                    errorInEcAnalysis: false
                }
            };
        case actionTypes.SET_EC_DEFINITION:
            const updatedState = updateEcBreakdown(state[action.data.modelId].modelECBreakdown,
                action.data.ecDefinition, action.data.updatedMaterials);
            return {
                ...state,
                [action.data.modelId]: {
                    ...state[action.data.modelId],
                    modelECBreakdown: updatedState
                }
            };
        case actionTypes.UPDATE_EC_PAGE_LMV:
            if (state[action.modelId]) {
                return {
                    ...state,
                    [action.modelId]: dashboard(state[action.modelId], action)
                };
            }
        case actionTypes.UPDATE_EC_PAGE_SETTINGS:
            for (const [key, value] of Object.entries(state)) {
                if ((value as any).projectId === action.projectId) {
                    const newStateModelId = dashboard(value, action);
                    newState[(value as any).modelId] = newStateModelId;
                } else {
                    newState[(value as any).modelId] = value;
                }
            }
            return newState;

        case actionTypes.UPDATED_PROJECT_MODELS_ON_EC_PAGE:
            for (const [key, value] of Object.entries(state)) {
                if (action.models.some((x) => x.id === (value as any).modelId)) {
                    delete newState[(value as any).modelId];
                } else {
                    newState[(value as any).modelId] = value;
                }
            }
            return newState;
        case actionTypes.UPDATED_PROJECT_NAME_ON_EC_PAGE:
            for (const [key, value] of Object.entries(state)) {
                if ((value as any).projectId === action.project.id) {
                    const newState = dashboard(value, action);
                    newState[(value as any).modelId] = newState;
                } else {
                    newState[(value as any).modelId] = value;
                }
            }
            return newState;
        case actionTypes.DELETE_EC_DEFINITION_ASSIGNMENTS:
            return {
                ...state,
                [action.data.modelId]: {
                    ...state[action.data.modelId],
                    modelECBreakdown: deleteECDefinitionAssignments(state[action.data.modelId].modelECBreakdown,
                        action.data.ecDefinitionId)
                }
            };
        case actionTypes.REMOVE_MODELS_WITH_BREAKDOWN:
            return newState;
        case actionTypes.REMOVE_MODELS_DATA_FROM_STATE:
            return Object.keys(state)
                .filter(k => !action.data.includes(k))
                .reduce((acc, key) => {
                    return { ...acc, [key]: { ...state[key] } };
                }, {});
        case actionTypes.SET_EC_ANALYSIS_ERROR:
            return {
                ...state,
                [action.data]: {
                    ...state[action.data],
                    modelECBreakdown: null,
                    errorInEcAnalysis: true
                }
            };
        case actionTypes.IS_UNAUTHORIZED_ACCESS_TO_RESOURCE:
            return {
                ...state,
                [action.data]: {
                    ...state[action.data],
                    isUnauthorizedAccess: true
                }
            };
        default:
            return state;
    }
}

const isFetchingReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.INIT_EC_PAGE_STARTED:
            return {
                ...state,
                [action.modelId]: true
            };

        case actionTypes.INIT_EC_PAGE_SUCCESS:
            return {
                ...state,
                [action.model.id]: false
            };

        default:
            return state;
    }
}

export const getIsFetchingEcPage = (state, modelId) => {
    return state?.isFetching[modelId];
}
export const getModelEcPage = (state, modelId) => {
    return state?.modelById[modelId];
}

export default combineReducers({
    modelById: embodiedCarbonReducer,
    isFetching: isFetchingReducer
});

const dashboard = (state, action) => {
    switch (action.type) {
        case actionTypes.INIT_EC_PAGE_SUCCESS:
            const initialDashboard = getInitialEcPage(action.project, action.model);
            return {
                ...state,
                ...initialDashboard
            };
        case actionTypes.UPDATE_EC_PAGE_LMV:
            return {
                ...state,
                urn: action.urn,
                lmvStatus: action.lmvStatus
            };
        case actionTypes.UPDATE_EC_PAGE_SETTINGS:
            return updateEcPageSettings(state, action.useSiUnits, action.currencyIso, action.currencyRate, action.fuelRate, action.electricityRate);

        default:
            return state;
    }
}

const getInitialEcPage = (project, model) => {

    const dashboard = {
        modelId: model.id,
        projectId: model.projectId,
        projectTitle: project.name,
        lmvStatus: model.lmvStatus,
        locality: model.locality,
        latitude: model.latitude,
        longitude: model.longitude,
        type: model.type,
        baseRunStatus: 'Processing',
        useSI: project.useSIUnits,
        urn: model.urn,
        //this rates are always expected in kWh and USD
        //NOTE: other currencies will be handled with a conversionRate field so this will always be in USD.
        //default ElectRate: $0.1/kWh
        elecRate: project.electricityRate ?? 0.1,
        //default Gas: $0.027/kWh
        fuelRate: project.fuelRate ?? 0.0273,
        isUncategorizeProject: project.isUncategorized,
        currencyIso: project.currencyIso,
        currencyRate: project.currencyRate,
        buildingArea: model.area,
        buildingType: model.buildingType
    };

    return dashboard;
}

const updateEcPageSettings = (current, useMetricSystem, currency, currencyRate, fuelRate, elecRate) => {
    //Rates received here must be always in kwh.
    const dashboard = {
        ...current,
        useSI: useMetricSystem,
        currencyIso: currency,
        elecRate,
        fuelRate,
        currencyRate
    };

    return dashboard;
}
