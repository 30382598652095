import { httpGet } from '../shared'
import { getInsightApigeeBasePath } from '../shared/api';

export type ViewerToken = {
    accessToken: string,
    expiresIn: number,
    viewerEnvironment: string
}

export const getViewerToken = () => {
    return httpGet <ViewerToken>(`${getInsightApigeeBasePath()}/api/login/getviewertoken`);
}