import React, { useCallback, useEffect, useState } from 'react';
import WeaveECDefinitionsTable from './WeaveECDefinitionsTable';
import Button, { buttonVariants } from '@weave-mui/button';
import { GridCellParams, useGridApiRef } from '@weave-mui/data-grid';
import { AddS } from '@weave-mui/icons-weave';
import '../../../css/new/ecDefinition.css';
import i18n from '../../../i18n';
import Search from '../../../shared/Search';
import { EcDefinition, IECDefinitionsState } from '../types';
import { MaterialRow } from '../../dashboard.models';
import AddECDefinitionModal from '../AddECDefinition/AddECDefinitionModal';
import { extractLabelsFromDefinitions } from '../../../shared/ECDefinition/ECDefinitionUtils';
import NotificationModal, { IModalProps } from '../../../shared/NotificationModal';
import { useDispatch, useSelector } from 'react-redux';
import { isValidURL } from '../AddECDefinition/helper';
import { buttonStyle } from '../AddECDefinition/ECDefinition.stylesheet';
import { deleteEcDefinition } from '../actions';
import * as locConsts from '../../localization/consts';
import isequal from 'lodash.isequal';
import { convertECDefinition } from '../utils';
import Link from '@weave-mui/link';
import { linkVariants } from '@weave-mui/enums';
import { useGetUserDataQueryState } from '../../../state/api/user-data-api';

interface IAdvancedSearchEcDefinitionsProps {
  modelId: string;
  ecDefinitions: EcDefinition[];
  loadEcDefinitions: () => void;
  cancelAction: () => void;
  useSI: boolean;
  applyAction: (ecDefinition: EcDefinition) => void;
  selectedRow: MaterialRow | undefined;
  shouldSort?: boolean;
  disableVirtualization?: boolean;
}

const searchColumns = ['name', 'average', 'notes', 'labels'];
const { OUTLINED, TEXT } = buttonVariants;

const search = (item: Object, searchQuery: string): boolean => {
  return (
    searchQuery.trim().length === 0 ||
    searchQuery
      .toLowerCase()
      .split(' ')
      .reduce(
        (result, token) =>
          result &&
          searchColumns.some(
            (field) => field in item && item[field] && item[field].toString().toLowerCase().indexOf(token) > -1
          ),
        true
      )
  );
};

const filterData = (data, searchQuery) => {
  if (!searchQuery || searchQuery.trim().length === 0 || data.length === 0) {
    return data;
  }
  return data.filter((item) => search(item, searchQuery.trim()));
};

const getFilteredData = (searchValue: string, ecDefinitions: EcDefinition[]): EcDefinition[] => {
  return filterData(ecDefinitions, searchValue);
};

export const AdvancedSearchEcDefinitions = React.memo<IAdvancedSearchEcDefinitionsProps>(
  ({
     modelId,
     ecDefinitions,
     loadEcDefinitions,
     cancelAction,
     useSI,
     applyAction,
     selectedRow,
     shouldSort = false,
     disableVirtualization = false
   })  => {
  const dispatch = useDispatch();
  const latestECDefinitionId = useSelector((state: {ecDefinitionsState: IECDefinitionsState}) => state.ecDefinitionsState.newECDefinitionId);
  const [ecDefinitionsData, setEcDefinitionsData] = useState<EcDefinition[]>(ecDefinitions);
  const [searchValue, setSearchValue] = useState<string>('');
  const [ecDefinitionLabels, setEcDefinitionLabels] = useState<string[]>([]);
  const [isDefinitionSelected, setIsDefinitionSelected] = useState<boolean>(false);
  const [selectedDefinition, setSelectedDefinition] = useState<EcDefinition | undefined>(undefined);
  const [selectedEditDefinition, setSelectedEditDefinition] = useState<EcDefinition | undefined>(undefined);
  const [highlightRowIndex, setHighlightRowIndex] = useState<number | null>(null);
  const [modalProps, setModalProps] = useState<IModalProps>({
      onLinkClick: () => {}, text: "", title: "", onClose: () => {}
  });
  const { data: user } = useGetUserDataQueryState();

  const clearHandler = () => {
    setSearchValue('');
    setEcDefinitionsData(ecDefinitions);
  };

  const searchCb = useCallback((query) => setSearchValue(query), []);
  const applyCb = useCallback(() => applyAction(selectedDefinition), [selectedDefinition]);
  const [showAddECDefinitionModal, setShowAddECDefinitionModal] = useState<boolean>(false);
  const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false);

  useEffect(() => {
    if (!ecDefinitions || ecDefinitions.length === 0) {
      loadEcDefinitions();
      return;
    }
    setEcDefinitionsData(ecDefinitions);
    setEcDefinitionLabels(extractLabelsFromDefinitions(ecDefinitions));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ecDefinitions, shouldSort, searchValue]);

  useEffect(() => {
    if (searchValue.trim().length > 0) {
      setEcDefinitionsData(getFilteredData(searchValue, ecDefinitions));
      if (selectedRow?.ecDefinition) {
        const selectedRowIndex = ecDefinitionsData.findIndex(
          ecDefinition => ecDefinition.id === selectedRow?.ecDefinition?.id
        );
        selectedRowIndex > -1
          ? setHighlightRowIndex(selectedRowIndex)
          : setHighlightRowIndex(null);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ecDefinitions, searchValue, selectedRow]);

  useEffect(() => {
    if (!ecDefinitions.length || (!selectedRow?.ecDefinition && !selectedDefinition)) {
      return;
    }

    if (isDefinitionSelected) {

      const editedDefinition = ecDefinitionsData.find(
        (ecDefinition: EcDefinition) => ecDefinition.id === selectedEditDefinition?.id
      );

      if (editedDefinition && !isequal(editedDefinition, selectedDefinition)) {
        setSelectedDefinition(editedDefinition);
      }
    }

    const selectedECDefinitionIndex = isDefinitionSelected
      ? ecDefinitionsData.findIndex((ecDef: EcDefinition) => ecDef?.id === selectedDefinition?.id)
      : ecDefinitionsData.findIndex(
          (ecDef: EcDefinition) => ecDef.id === selectedRow?.ecDefinition?.id
        );

    if (selectedECDefinitionIndex > -1) {
      setHighlightRowIndex(selectedECDefinitionIndex);
    } else {
      setHighlightRowIndex(null);
      setSelectedDefinition(undefined);
      setIsDefinitionSelected(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ecDefinitionsData, selectedRow, isDefinitionSelected, selectedDefinition]);

  const editECDefinitionClickHandler = (ecDefinition: EcDefinition) => {
    setSelectedEditDefinition(convertECDefinition(ecDefinition, useSI));
    setModalProps({
      title: i18n.t(locConsts.EC_EDIT_DEFINITION_MODAL_TITLE),
      text: i18n.t(locConsts.EC_EDIT_DEFINITION_MODAL_TEXT),
      onLinkClick: () => editDefinitionHandler(),
      onClose: () => setShowNotificationModal(false)
    });
    setShowNotificationModal(true);
  };

  const editDefinitionHandler = () => {
    setShowNotificationModal(false);
    setShowAddECDefinitionModal(true);
  };

  const addECDefinitionClickHandler = () => {
    setSelectedEditDefinition(undefined);
    setShowAddECDefinitionModal(true);
  }

  const deleteECDefinitionClickHandler = (ecDefinitionId: string) => {
    setModalProps({
      title: i18n.t(locConsts.EC_DELETE_DEFINITION_MODAL_TITLE),
      text: i18n.t(locConsts.EC_DELETE_DEFINITION_MODAL_TEXT),
      onLinkClick: () => deleteDefinition(ecDefinitionId),
      onClose: () => setShowNotificationModal(false)
    });
    setShowNotificationModal(true);
  };

  const removeHighlightRow = (ecDefinitionId: string) => {
    if (selectedRow?.ecDefinition?.id && ecDefinitionId === selectedRow?.ecDefinition?.id) {
      setHighlightRowIndex(null);
    }
  };

  const deleteDefinition = (ecDefinitionId: string) => {
    const removeHighlightRowAction = () => removeHighlightRow(ecDefinitionId);
    dispatch(deleteEcDefinition(modelId, ecDefinitionId, removeHighlightRowAction));
    setShowNotificationModal(false);
  };

  const apiRef = useGridApiRef();

  useEffect(() => {
      setTimeout(() => {
        const newDefinitionIndex = newElementLocation();
        apiRef?.current?.scrollToIndexes({rowIndex: newDefinitionIndex, colIndex: 1});
      }, 1000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ apiRef, ecDefinitionsData])

  const newElementLocation = (): number | undefined => {
    const element = ecDefinitionsData.find(
      (item) => item.id === latestECDefinitionId
    );

    if (element) {
      const cellRowIndex = apiRef?.current?.getRowIndexRelativeToVisibleRows(element.id);
      return cellRowIndex ?? 0;
    }
    return undefined;
  };

  const onCellClickHandler = useCallback(
    (params: GridCellParams) => {
      const editIconIndex = 5;
      const sourceIndex = 3;
      const { id, field } = params;
      const selectedRowSource: EcDefinition = params.value as EcDefinition;
      const apiRefCurrent = apiRef.current;
      const cellRowIndex = apiRefCurrent.getRowIndexRelativeToVisibleRows(id);
      const cellColumnIndex = apiRefCurrent.getColumnIndexRelativeToVisibleColumns(field);

      const isSourceLinkClicked: boolean =
        isValidURL(selectedRowSource?.source) && cellColumnIndex === sourceIndex;

      if (isSourceLinkClicked) return;

      if (cellColumnIndex !== editIconIndex || !selectedRowSource.isCustom) {
        if (selectedRow) {
          const selectedEcDefinition: EcDefinition = selectedRowSource;

          if (selectedEcDefinition) {
            setSelectedDefinition(selectedEcDefinition);
            setHighlightRowIndex(cellRowIndex);
            setIsDefinitionSelected(true);
            setSelectedEditDefinition(undefined);
          }
        }
      }

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiRef, ecDefinitionsData, selectedRow]
  );

  return (
    <div className="advancedSearchContainer">
      <div className="advancedSearchInput">
        <div className="advancedSearchAddDefinition" onClick={() => addECDefinitionClickHandler()}>
          <Button startIcon={<AddS />} variant={TEXT}>
            {i18n.t('analysis.common.addDefinition')}
          </Button>
        </div>
        <Search
          onSearch={searchCb}
          style={{ width: '25%' }}
          onClear={clearHandler}
          searchValue={searchValue}
        />
      </div>
      <div
        className="advancedSearchTable"
        style={!ecDefinitionsData.length ? { position: 'relative' } : {}}
      >
        <WeaveECDefinitionsTable
          data={ecDefinitionsData}
          onCellClick={onCellClickHandler}
          useSI={useSI}
          editECDefinition={editECDefinitionClickHandler}
          deleteECDefinition={deleteECDefinitionClickHandler}
          latestECDefinitionId={latestECDefinitionId}
          apiRef={apiRef}
          selectedRow={selectedRow?.ecDefinition ?? {}}
          disableVirtualization={disableVirtualization}
        />
      </div>
      <div className="buttonsWrapper">
        <Link
          href={user?.componentHelpBaseUrl + 'CARBON_DEFINITIONS'}
          target="BLANK"
          variant={linkVariants.PRIMARY}
        >
          {i18n.t('analysis.ec.ecDefinition.table.helpText')}
        </Link>
        <Button
          onClick={cancelAction}
          variant={OUTLINED}
          sx={{ ...buttonStyle, marginLeft: 'auto !important' }}
        >
          {i18n.t('analysis.common.cancel')}
        </Button>
        <Button disabled={!isDefinitionSelected} onClick={applyCb} sx={{ ...buttonStyle }}>
          {i18n.t('analysis.common.ok')}
        </Button>
      </div>
      <AddECDefinitionModal
        modelId={modelId}
        showModal={showAddECDefinitionModal}
        onClose={() => setShowAddECDefinitionModal(false)}
        ecDefinitionLabels={ecDefinitionLabels}
        selectedECDefinitionToAssign={selectedDefinition}
        selectedECDefinitionToEdit={selectedEditDefinition}
      />
      <NotificationModal showModal={showNotificationModal} modalProps={modalProps} />
    </div>
  );
}, (prevProps, nextProps) => {
      return isequal(prevProps.modelId, nextProps.modelId) &&
             isequal(prevProps.ecDefinitions, nextProps.ecDefinitions) &&
             isequal(prevProps.useSI, nextProps.useSI) &&
             isequal(prevProps.selectedRow, nextProps.selectedRow)
});
