import { ActiveCategory, CategoryToTreeItemPropsMap, TreeViewDataPoints } from "./dataPointsTreeView.types";
import { DataPoint, DataPoints, Metric } from "../../types/metrics";
import i18n from '../../i18n';
import { TextDataType } from "../../dataPoints/utils/dataPointsUtils";

export const generateActiveDataPoints = (dataPoints: DataPoints, activeCategories: ActiveCategory[]): TreeViewDataPoints => {
  const treeViewDataPoints: TreeViewDataPoints = {
    factorsData: [...dataPoints.factorsData],
    modelData: [...dataPoints.modelData],
    analysisResults: [
      ...dataPoints.ecAnalysisData,
      ...dataPoints.energyAnalysisData
    ],
    metricsData: [...dataPoints.metricsData]
  };

  if (activeCategories.length === 0) return treeViewDataPoints;

  return Object.fromEntries(activeCategories.map((category: keyof DataPoints) => [category, treeViewDataPoints[category]]));
};

export const filterDataPoints = (dataPoints: DataPoint[] | Metric[], query: string, displayTextDataType: boolean) => {
  return dataPoints.filter(dp =>
    dp.displayName.toLocaleLowerCase().includes(query.toLocaleLowerCase()) &&
    !dp.isHidden &&
    (
      displayTextDataType
        ? true
        : !dp.dataType.includes(TextDataType)
    )
  )
};

export const categoryToTreeItemProps: CategoryToTreeItemPropsMap = {
  modelData: {
    name: i18n.t('analysis.dataPoints.treeView.modelData'),
    color: 'orange',
    description: i18n.t('analysis.dataPoints.treeView.description.modelData')
  },
  analysisResults: {
    name: i18n.t('analysis.dataPoints.treeView.analysisResults'),
    color: 'blue',
    description: i18n.t('analysis.dataPoints.treeView.description.analysisResults')
  },
  metricsData: {
    name: i18n.t('analysis.dataPoints.treeView.metrics'),
    color: 'purple',
    description: i18n.t('analysis.dataPoints.treeView.description.metrics')
  },
  factorsData: {
    name: i18n.t('analysis.dataPoints.treeView.factors'),
    color: 'green',
    description: i18n.t('analysis.dataPoints.treeView.description.factors')
  },
}
