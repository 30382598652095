import FormControl from '@weave-mui/form-control';
import FormLabel, { formLabelVariants } from '@weave-mui/form-label';
import React, { useMemo } from 'react';
import Box from '@weave-mui/box';
import TextField from '@weave-mui/text-field';
import { textAreaStyleDescription, textAreaStyleTextField } from '../../../styles/metrics.stylesheet';
import { ParametersModalType } from '../../../../types/metrics';
import { InputType, InputTypeEnum } from '../../../types/input';
import DataPointsInputError from './DataPointsInputError';

interface DataPointsInputProps {
  value: string | number;
  name: string;
  labelName: string;
  inputType: InputType;
  modalType: ParametersModalType;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) => void;
  disabled: boolean;
  error: boolean;
}

const DataPointsInput: React.FC<DataPointsInputProps> = (props) => {
  const {labelName, inputType, modalType, children,  ...inputProps} = props;

  const inputStylesheet = inputType === InputTypeEnum.textArea
      ? {...textAreaStyleDescription}
      : {...textAreaStyleTextField}

  return (
    <FormControl sx={
      {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.5rem',
        width: '100%',
        height: '100%'
      }
    }>
      <FormLabel
        sx={
          {
            alignSelf: 'flex-start',
            pt: '0.4rem',
            width: '100px',
            minWidth: '100px'
          }
        }
        variant={formLabelVariants.SIDE}
      >
        {labelName}
      </FormLabel>
      <Box  sx={
        {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '50px'
        }
      }>
        <Box sx={
          {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'nowrap'
          }
        }>
          <TextField
            id={`${modalType.toString().toLowerCase()}-${inputProps.name}-input`}
            data-testid={`${modalType.toString().toLowerCase()}-${inputProps.name}-input`}
            sx={{...inputStylesheet, width: '100%'}}
            multiline={inputType === InputTypeEnum.textArea}
            {...inputProps}
          />
        </Box>
        <DataPointsInputError value={inputProps.value} type={inputType}/>
      </Box>
    </FormControl>
  )
}

export default DataPointsInput;
