import React, { Component } from 'react';

export class CollapseIcon extends Component<any,any> {

    constructor(props) {
        super(props);
    }

    render() {
        // @ts-ignore: unrecognizable type
        return <svg className="collapse-icon" id="Layer_1" x="0px" y="0px" viewBox="0 0 16 16" style={{ enableBackground: "new 0 0 16 16" }}
            xmlSpace="preserve" onClick={this.props.clickHandler}>
            <rect className="collapse-icon-backgound" width={16} height={16} />
            <g>
                <defs>
                    <polygon id="SVGID_1_" points="8,8 14.2,14 16,12.3 11.5,8 16,3.7 14.2,2" />
                </defs>
                <use xlinkHref="#SVGID_1_" />
                <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
                </clipPath>
            </g>
            <g>
                <defs>
                    <polygon id="SVGID_3_" points="0,8 6.2,14 8,12.3 3.5,8 8,3.7 6.2,2  " />
                </defs>
                <use xlinkHref="#SVGID_3_" style={{ overflow: "visible" }}
                />
                <clipPath id="SVGID_4_">
                    <use xlinkHref="#SVGID_3_" style={{ overflow: "visible" }} />
                </clipPath>
            </g>
        </svg>;
    }
}
