import React from 'react';
import { useSearchParams } from 'react-router-dom';
import AuthClient from './authClient';
import { setAuth0Data } from './state/slice/auth0-data-slice';
import { useDispatch } from 'react-redux';

export const LoginOAuth: React.FC = () => {
  const rootPath = '/';
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  // handle login call back path
  // extract the code to exchange for an oauth2 token
  // extract the state parameter to restore previous route if applicable
  const authCode = searchParams.get('code');
  // obtain initial oauth2 token and begin loading data - entitlement check, user/settings data etc
  AuthClient.exchangeAuthCodeForAccessToken(authCode)
    .then((hasToken) => {
      console.log('got token')
      const redirectPath = decodeURIComponent(searchParams.get('state'));
      dispatch(
        setAuth0Data({
          redirectPath: redirectPath || rootPath,
          hasToken,
        })
      );
    })
    .catch(() => {
      AuthClient.navigateToLogin(rootPath);
    })
  ;

  return<></>
};
