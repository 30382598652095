import React, { Component } from 'react';
import '../css/actionMenu.css';
import { Popover, PopoverBody } from 'reactstrap';
import { ActionMenuItem } from './common.models';


export type ActionMenuProps = {
    menuId: string | number;
    items: ActionMenuItem[];
}

type ClassState = {
    popoverOpen: boolean,
    menuId: string,
    popoverId: string
}

export class ActionMenu extends Component<ActionMenuProps, ClassState> {

    constructor(props: ActionMenuProps) {
        super(props);
        this.toggle = this.toggle.bind(this);

        this.state = {
            popoverOpen: false,
            menuId: `menu${this.props.menuId}`,
            popoverId: `popover${this.props.menuId}`
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event: MouseEvent) {
        let subtreeRoot = document.getElementById(this.state.popoverId);
        let element = event.target as Element;
        if (element.id !== this.state.menuId && subtreeRoot && (element !== subtreeRoot && !subtreeRoot.contains(element))) {
            this.toggle();
        }
    }

    handleItemClick(action: () => void) {
        action();
        this.toggle();
    }

    toggle() {
        this.setState({ popoverOpen: !this.state.popoverOpen });
    }

    render() {
        let { items } = this.props;

        return (
            <div>
                <span id={this.state.menuId} className="icon-vertical-kebab-menu action-menu-trigger" onClick={this.toggle} />
                <Popover trigger="legacy" placement="right" target={this.state.menuId} id={this.state.popoverId} isOpen={this.state.popoverOpen}>
                    <PopoverBody>
                        <ul className="action-menu">
                            {
                                items.map(item => {
                                    return menuItem({
                                        key: item.key,
                                        icon: item.icon,
                                        text: item.text,
                                        action: () => this.handleItemClick(item.action)
                                    });
                                })
                            }
                        </ul>
                    </PopoverBody>
                </Popover>
            </div>
        );
    }
}

const menuItem = (props: ActionMenuItem): JSX.Element => {
    return <li key={props.key} className="d-flex align-items-center" onClick={props.action}>
        <div className="icon">
            <span className={props.icon}></span>
        </div>
        <span className="item-text">{props.text}<span className="description"></span>
        </span>
    </li>
}

