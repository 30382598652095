import React from 'react';
import AddECDefinition from './AddECDefinition';
import { addECDefinitionModalStyles } from '../../../shared/ConnectedModalDialog.stylesheet';
import { EcDefinition } from '../types';
import WeaveModalDialog from '../../../shared/WeaveModalDialog';

interface IAddECDefinitionModal {
  modelId: string;
  showModal: boolean;
  onClose: () => void;
  ecDefinitionLabels: string[];
  selectedECDefinitionToEdit: EcDefinition | undefined;
  selectedECDefinitionToAssign: EcDefinition | undefined;
}

const AddECDefinitionModal = ({
  modelId,
  showModal,
  onClose,
  ecDefinitionLabels,
  selectedECDefinitionToEdit,
  selectedECDefinitionToAssign,
}: IAddECDefinitionModal) => {
  return (
      <WeaveModalDialog 
        sx={{ ...addECDefinitionModalStyles }}
        open={showModal}
        header="Embodied Carbon Definition"
        onClose={onClose}
        disablePortal
      >
        <AddECDefinition
          modelId={modelId}
          ecDefinitionLabels={ecDefinitionLabels}
          onClose={onClose}
          selectedECDefinitionToEdit={selectedECDefinitionToEdit}
          selectedECDefinitionToAssign={selectedECDefinitionToAssign}
        />
      </WeaveModalDialog>
  );
};

export default AddECDefinitionModal;
