import React from "react";
import { NavigateFunction, Params, useNavigate, useParams } from "react-router-dom";

/**
 * Inject class based component with React Router v6 hooks 
 *
 * @export
 * @param {*} Component
 * @return {*} 
 */
export function withRouter(Component) {
  return () => <Component routeParams={useParams()} navigate={useNavigate()} />;
}

export type RouterProps = {
  routeParams: Readonly<Params<string>>;
  navigate: NavigateFunction;
};
