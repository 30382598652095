export const menuListStyles = {
  p: '0px !important',
  my: '3px !important',
  'width': '280px !important',
  'max-height': '380px',
  'overflow': 'auto'
};

export const menuItemStyles = {
  p: '8px',
  height: '76px'
};

export const modelTitleStyles = {
  fontWeight: '600 !important',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  display: 'inline-block !important',
  minWidth: '100px',
  maxWidth: '160px',
};

export const modelSubTitleStyles = {
  fontWeight: '400 !important',
  display: 'inline-block !important',
};

export const cardMediaStyles = {
  'background-color': '#ebebeb',
  'border-radius': '2px',
  'border': '1px solid rgba(128, 128, 128, 0.6)',  
  'width': '100%',
  'height': '60px !important',
  'min-width': '80px',
  'display': 'flex',
  'justify-content': 'center'
}
