import React, { Component } from 'react';

export class Glazing extends Component {

    render() {
        return <svg
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 16 16"
            style={{
                // @ts-ignore: unrecognizable type
                enableBackground: "new 0 0 16 16"
            }}
            xmlSpace="preserve"
            className="factor-modal-icon"
        >
            <style type="text/css">{"\n\t.st0{fill:#7C7C7C;}\n"}</style>
            <polygon className="factor-modal-icon-color" points="9,8.5 14,12 14,10 16,10 16,7 14,7 14,5 " />
            <rect y={1} className="factor-modal-icon-color" width={6} height={14} />
            <polygon className="factor-modal-icon-color" points="7,1 7,15 9,15 9,8.5 9,1 " />
        </svg>;
    }
}
