import { useState, useEffect } from 'react';
import { createTheme, getTheme } from '@weave-mui/styles';
import { typeTheme, typeDensity } from '@weave-mui/enums';
interface useThemeTypes {
  activeTheme?: typeTheme;
  activeDensity?: typeDensity;
}
function useTheme({ activeTheme = 'light-gray', activeDensity = 'high' }: Partial<useThemeTypes> = {}){
  const muiTheme = createTheme({});
  const [theme, setTheme] = useState(muiTheme);
  const [loaded, setLoaded] = useState<boolean>(false)
  const fetchTokens = async () => {
    const weaveTheme = await getTheme(activeTheme, activeDensity);
    setTheme(createTheme(muiTheme, weaveTheme));
    setLoaded(true);
  };
  useEffect(() => {
    fetchTokens();
  }, [activeDensity, activeTheme]);

  return { theme, loaded };
};

export default useTheme;