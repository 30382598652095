import React from 'react';
import ProgressRing from '@hig/progress-ring';
import ModalPopup from './ModalPopup';

interface LoaderProps {
    isLoading:boolean,
    size?:string,
    title?: string,
    subtitle?: string,
    showAnimation?: boolean,
    children:any
}

export default function Loader(props: LoaderProps) {
    if (props.isLoading) {
        const style = {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        };

        let showAnimation = true;
        if (props.hasOwnProperty("showAnimation")) {
            showAnimation = props.showAnimation;
        }

        return (
            <div style={style}>
                <div>
                    {showAnimation && <ProgressRing size={props.size} />}
                    {props.title && <p className="loader-title">{props.title}</p>}
                    {props.subtitle && <p className="loader-subtitle">{props.subtitle}</p>}
                </div>
            </div>
        );
    }

    return props.children;
}
