import { useState, useEffect } from 'react';
import moment from 'moment';
import { useGetUserFeatureFlagDataQuery } from '../../state/api/feature-flag-data-api';
import { MaintenanceBannerProps } from '../BannerContext/BannerMaintenanceContext';

const useDetectMaintenancePeriod = (): boolean => {
  const [displayMaintenancePage, setDisplayMaintenancePage] = useState<boolean>(false);  
  const { data: featureFlagData } = useGetUserFeatureFlagDataQuery();
  const maintenanceBannerProps = featureFlagData?.['display-maintenance-window'] as MaintenanceBannerProps;

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    const checkMaintenanceWindow = () => {
      const currentDate = moment();
      const startDate = moment(maintenanceBannerProps?.beginDate, "MM/DD/YYYY h:mm A");
      const endDate = moment(maintenanceBannerProps?.endDate, "MM/DD/YYYY h:mm A");

      if (startDate.isValid() && endDate.isValid()) {
        if (endDate.isBefore(currentDate)) {
          setDisplayMaintenancePage(false);
          clearInterval(interval);
          return;
        }

        if (startDate.isBefore(endDate) && currentDate.isBetween(startDate, endDate, null, '[]')) {
          setDisplayMaintenancePage(true);
        } else {
          setDisplayMaintenancePage(false);
        }
      }
    };

    if (maintenanceBannerProps?.scheduledMaintenance && moment(maintenanceBannerProps?.beginDate).isSame(moment(maintenanceBannerProps?.endDate), 'day')) {
      checkMaintenanceWindow();
      interval = setInterval(checkMaintenanceWindow, 60000);
      return () => clearInterval(interval);
    } else {
      setDisplayMaintenancePage(false);
    }

    checkMaintenanceWindow();
  }, [maintenanceBannerProps]);

  return displayMaintenancePage;
};

export default useDetectMaintenancePeriod;
