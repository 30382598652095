import React, { Component } from 'react';
import i18n from '../i18n';
import * as locConsts from "../analysis/localization/consts";
import * as conversions from '../conversions';
import { getConvertedResult } from './reducer';
import { factorMap, combinationGroupMap } from '../analysis/helper';
import * as consts from '../consts';
import { Dashboard, RunModel } from '../analysis/dashboard.models';
import DataGrid, {
  GridCellParams,
  GridColDef,
  GridColumnGroupingModel,
  GridEditModes, GridGroupingColDefOverride,
  GridRowGroupingModel
} from "@weave-mui/data-grid";

export type runsTableProps = {
  dashboard: Dashboard,
  runs: RunModel[],
  downloadAction?: Function,
  isDownloadEnabled?: boolean,
  meterSelected?: string,
  results: any
}
type columns = {
  cellRenderer?: Function,
  dataKey: string,
  groupKey: string,
  key: string,
  title: string,
  width: number,
  hidden?: boolean,
  align?: string
}
type columnGroups = {
  key: string,
  title: string,
  hidden?: boolean

}

type runsTableState = {
  columnGroups: GridColumnGroupingModel,
  columns: GridColDef[],
  data: any
}
export default class RunsTablePage extends Component<runsTableProps,runsTableState> {

  constructor(props: runsTableProps) {
    super(props);
    this.state = {
      columnGroups: [],
      columns: [],
      data: []
    };
  }

  componentDidMount() {
    this.loadData(true);
  }

  componentDidUpdate(prevProps: runsTableProps, prevState: runsTableState) {
    const modelChanged = (prevProps.dashboard != null &&
        this.props.dashboard != null &&
        prevProps.dashboard.modelId != this.props.dashboard.modelId) ||
      !prevProps.dashboard && this.props.dashboard ||
      prevProps.dashboard && !this.props.dashboard;

    const runsChanged = (!prevProps.runs && this.props.runs) ||
      (prevProps.runs && !this.props.runs) ||
      (prevProps.runs?.length !== this.props.runs?.length);

    const resultsChanged = Object.keys(prevProps.results).length !== Object.keys(this.props.results).length;

    const meterChanged = this.props.meterSelected !== prevProps.meterSelected;

    const dashboardSettingsChanged = this.props.dashboard &&
      (prevProps.dashboard.useSI !== this.props.dashboard.useSI ||
        prevProps.dashboard.fuelRate !== this.props.dashboard.fuelRate ||
        prevProps.dashboard.currencyIso !== this.props.dashboard.currencyIso ||
        prevProps.dashboard.elecRate !== this.props.dashboard.elecRate);

    if (modelChanged || meterChanged || dashboardSettingsChanged) {
      this.loadData(true);
    }
    else if (runsChanged || resultsChanged) {
      this.loadData(false);
    }
  }

  getTableData = () => {
    let data = [];
    let map = {};

    let currentId = -1;
    this.props.runs?.forEach((item) => {
      const result = this.getRunResult(item);

      if (map[item.tag]) {
        map[item.tag].order = Math.max(map[item.tag].order, item.order);
        map[item.tag].children.push(result);
        return;
      }

      let newMapItem = Object.assign({}, item);
      newMapItem.runId = currentId--;
      newMapItem.name = i18n.t(`analysis.simulations.${item.tag}.title`);
      newMapItem.order = item.order;
      newMapItem.children = [];
      newMapItem.children.push(result);

      data.push(newMapItem);
      map[item.tag] = newMapItem;
    });

    for (let i = 0; i < data.length; ++i) {
      if (data[i].children.length === 1) {
        data[i] = data[i].children[0];
      } else {
        data[i].children.sort((a, b) => a.name.localeCompare(b.name));
      }
    }

    data.sort((a, b) => a.order - b.order);
    //return data;
    const data2 = data.reduce((acc, value) => {
      if (value.children) {
        const {children, ...data} = value;
        acc.push({...data, tag: [`${data.tag}-${data.runId}`]});
        children.forEach((item) => acc.push({...item, tag: [`${data.tag}-${data.runId}`,item.name]}));
      } else {
        acc.push({...value, tag: [value.tag]});
      }
      return acc;
    }, [])
    return data2;
  };

  getTableDescription = combinationGroup => {
    const tableKey = `analysis.simulations.${combinationGroup}.tableDescription`;

    if (i18n.exists(tableKey)) {
      return i18n.t(tableKey);
    }

    return i18n.t(`analysis.simulations.${combinationGroup}.title`);
  }

  getRunResult = (item: RunModel) => {
    let convertedResult;
    let result;
    const { elecRate, fuelRate, currencyRate } = this.props.dashboard;
    //Rates from model always in kwh.
    const elecRateConverted = conversions.CovertFromBaseCurrency(currencyRate, elecRate);
    const fuelRateConverted = conversions.CovertFromBaseCurrency(currencyRate, fuelRate);

    if (this.props.results[item.runId]) {
      result = this.props.results[item.runId];

      if (result.status === "Error") {
        //Code-Smell:obj updated
        let obj = {
          status: "Error",
          runId: item.runId,
          order: item.order,
          name: this.getSimulationName(item, this.props.dashboard.useSI),
          tag: item.tag
        };

        return obj;
      }
      convertedResult = getConvertedResult(result, this.props.dashboard.useSI);
    }

    if (!convertedResult) {
      //Code-Smell:obj updated
      let obj = {
        status: "Processing",
        runId: item.runId,
        order: item.order,
        name: this.getSimulationName(item, this.props.dashboard.useSI),
        tag: item.tag
      };

      return obj;
    }

    let res = {
      ...convertedResult,
      name: this.getSimulationName(item, this.props.dashboard.useSI)
    };
    res.runId = item.runId;
    res.order = item.order;
    res.simulationOrder = item.simulationOrder;
    res.annualElecCost = this.getCostFromEnergyUsed(result.annualElecUsed, elecRateConverted);
    res.annualFuelCost = this.getCostFromEnergyUsed(result.annualNaturalGas, fuelRateConverted);
    res.annualEuiElecCost = this.getCostFromEui(result.annualEuiElec, this.props.dashboard.useSI, elecRateConverted);
    res.annualEuiFuelCost = this.getCostFromEui(result.annualEuiFuel, this.props.dashboard.useSI, fuelRateConverted);
    res.annualEuiCost = conversions.round(res.annualEuiElecCost + res.annualEuiFuelCost, 4);

    return res;
  }

  getSimulationName = (run: RunModel, useMetricSystem: boolean) => {
    const parameters = run.parameters;
    const combinationGroup = run.tag;
    const key = `analysis.simulations.${combinationGroup}.tableTemplate`;
    const convertedParameters = Object.assign({}, parameters);
    const factors = combinationGroupMap[combinationGroup];

    if (combinationGroup === consts.BASE) {
      return i18n.t(key);
    }

    if (combinationGroup == consts.FORM_MIN_MAX ||
      combinationGroup == consts.INTERNAL_LOAD_MIN_MAX ||
      combinationGroup == consts.ENVELOPE_CALCULATION_MIN_MAX) {
      //Name is only set for min/max factor types.
      //TODO: localize this
      return run.name;
    }

    for (const factor of factors) {
      const paramName = factorMap[factor].parameterName;
      const paramValue = parameters[paramName];

      if (paramValue === null || paramValue === undefined) {
        convertedParameters[paramName] = '"no change"';
      }

      if (typeof paramValue === "string") {
        const simulationKey = `analysis.simulations.${factor}.${paramValue.replace('.', '')}.tableDescription`;
        convertedParameters[paramName] = i18n.t(simulationKey);
      } else if (typeof paramValue === "number") {
        const unitKey = `analysis.simulations.${factor}.unit.${useMetricSystem ? 'si' : 'ip'}`;
        convertedParameters.unit = i18n.t(unitKey);

        if (useMetricSystem) {
          //Currently measure arguments are all in IP so we convert if the user unit is metric.

          //Convert only the parameters that have units.
          switch (factor) {
            case consts.INFILTRATION:
              convertedParameters[paramName] = conversions.CFMft2tom3hm2(paramValue);
              break;
            case consts.LIGHTING_EFFICIENCY_FACTOR:
            case consts.PLUG_LOAD_EFFICIENCY_FACTOR:
              convertedParameters[paramName] = conversions.Wft2toWm2(paramValue);
              break;
          }
        }
      }
    }

    return i18n.t(key, convertedParameters);
  }

  simulationHasResults = (item) => {
    return item.status === "Completed" || item.status === "Error";
  }

  loadData(updateColumns) {

    let data = this.getTableData();
    let updatedFields: any = {
      data
    };

    if (updateColumns) {
      const { updatedColumns, updatedColumnGroups } =
        this.getUpdatedColumns2(this.state.columns, this.state.columnGroups);
      updatedFields.columns = updatedColumns;
      updatedFields.columnGroups = updatedColumnGroups;
    }
    this.setState(updatedFields);
  }

  getUpdatedColumns2 = (columns: GridColDef[], columnGroups: GridColumnGroupingModel): {updatedColumns: any, updatedColumnGroups: any} => {
    const { currencyIso } = this.props.dashboard;

    const useMetric = this.props.dashboard.useSI;
    const hideCostColumns = this.props.meterSelected === "ecr";
    const hideEnergyColumns = this.props.meterSelected === "cost";
    let updatedColumnGroups: GridColumnGroupingModel = [];
    let updatedColumns: GridColDef[] = [];
    updatedColumns = [
      {
        field: 'annualElecUsed',
        headerName: useMetric ? i18n.t(locConsts.RUNS_TABLE_ELECTRICAL_MWH) : i18n.t(locConsts.RUNS_TABLE_ELECTRICAL_KBTU),
        width: 250,
        align: 'center',
        disableReorder:true,
        hideSortIcons: true,
      },
      {
        field: 'annualNaturalGas',
        headerName: useMetric ? i18n.t(locConsts.RUNS_TABLE_FUEL_MWH) : i18n.t(locConsts.RUNS_TABLE_FUEL_KBTU),
        width: 250,
        align: 'center',
        disableReorder:true,
        hideSortIcons: true,
      },
      {
        field: 'annualEuiElec',
        headerName: useMetric ? i18n.t(locConsts.RUNS_TABLE_ELECTRICAL_KWH_M2, { squareSymbol: '²' }) : i18n.t(locConsts.RUNS_TABLE_ELECTRICAL_KBTU_FT2, { squareSymbol: '²' }),
        width: 250,
        align: 'center',
        disableReorder:true,
        hideSortIcons: true,
      },
      {
        field: 'annualEuiFuel',
        headerName: useMetric ? i18n.t(locConsts.RUNS_TABLE_FUEL_KWH_M2, { squareSymbol: '²' }) : i18n.t(locConsts.RUNS_TABLE_FUEL_KBTU_FT2, { squareSymbol: '²' }),
        width: 250,
        align: 'center',
        disableReorder:true,
        hideSortIcons: true,
      },
      {
        field: 'annualEui',
        headerName: useMetric ? i18n.t(locConsts.RUNS_TABLE_TOTAL_KWH_M2, { squareSymbol: '²' }) : i18n.t(locConsts.RUNS_TABLE_TOTAL_KBTU_FT2, { squareSymbol: '²' }),
        width: 250,
        align: 'center',
        renderCell: totalCellRenderer,
        hideSortIcons: true
      }];
    updatedColumnGroups = [
      {
        groupId: 'energy',
        headerName: i18n.t(locConsts.RUNS_TABLE_ENERGY_USE),
        children: [{field: 'annualElecUsed'}, {field: 'annualNaturalGas'}],
        freeReordering: false
      },
      {
        groupId: 'intensity',
        headerName: i18n.t(locConsts.RUNS_TABLE_INTENSITY),
        children: [{field: 'annualEuiElec'}, {field: 'annualEuiFuel'}, {field: 'annualEui'}],
        freeReordering: false
      }
    ];
    if (hideEnergyColumns && !hideCostColumns) {
      updatedColumns = [{
        field: 'annualElecCost',
        headerName: i18n.t(locConsts.RUNS_TABLE_ELECTRICAL_COST, { currencyIso: currencyIso }),
        width: 250,
        align: 'center',
        disableReorder:true,
        hideSortIcons: true,
      },
        {
          field: 'annualFuelCost',
          headerName: i18n.t(locConsts.RUNS_TABLE_FUEL_COST, { currencyIso: currencyIso }),
          width: 250,
          align: 'center',
          disableReorder:true,
          hideSortIcons: true,
        },
        {
          field: 'annualEuiElecCost',
          headerName: useMetric ? i18n.t(locConsts.RUNS_TABLE_ELECTRICAL_COST_M2, { squareSymbol: '²', currencyIso: currencyIso }) : i18n.t(locConsts.RUNS_TABLE_ELECTRICAL_COST_FT2, { squareSymbol: '²', currencyIso: currencyIso }),
          width: 250,
          align: 'center',
          disableReorder:true,
          hideSortIcons: true,
        },
        {
          field: 'annualEuiFuelCost',
          headerName: useMetric ? i18n.t(locConsts.RUNS_TABLE_FUEL_COST_MT2, { squareSymbol: '²', currencyIso: currencyIso }) : i18n.t(locConsts.RUNS_TABLE_FUEL_COST_FT2, { squareSymbol: '²', currencyIso: currencyIso }),
          width: 250,
          align: 'center',
          disableReorder:true,
          hideSortIcons: true,
        },
        {
          field: 'annualEuiCost',
          headerName: useMetric ? i18n.t(locConsts.RUNS_TABLE_TOTAL_COST_M2, { squareSymbol: '²', currencyIso: currencyIso }) : i18n.t(locConsts.RUNS_TABLE_TOTAL_COST_FT2, { squareSymbol: '²', currencyIso: currencyIso }),
          width: 250,
          align: 'center',
          renderCell: totalCellRenderer,
          hideSortIcons: true,
          disableReorder:true
        }];
      updatedColumnGroups = [
        {
          groupId: 'cost',
          headerAlign: 'center',
          headerName: i18n.t(locConsts.RUNS_TABLE_ENERGY_COST),
          children: [{field: 'annualElecCost'}, {field: 'annualFuelCost'}],
          freeReordering: false
        },
        {
          groupId: 'intensity',
          headerAlign: 'center',
          headerName: i18n.t(locConsts.RUNS_TABLE_INTENSITY),
          children: [{field: 'annualEuiElecCost'}, {field: 'annualEuiFuelCost'}, {field: 'annualEuiCost'}],
          freeReordering: false
        }
      ];
    }

    return { updatedColumns, updatedColumnGroups };
  }

  //Energy used, either for electricity or fuel, is returned from API in GJ.
  //Energy rates are always stored in kwh and USD, when changing currencies is allowed conversion rates will apply.
  getCostFromEnergyUsed = (annualEnergyUsed: number, energyRate: number) => {
    return conversions.round(conversions.GJtokWh(annualEnergyUsed) * energyRate, 4);
  };

  //EUI, either for electricity or fuel, is returned from API in MJ/m2.
  //Energy rates are always stored in kwh and USD, when changing currencies is allowed conversion rates will apply.
  getCostFromEui = (annualEui: number, useMetric: boolean, energyRate: number) => {
    let cost = conversions.MJm2tokWhm2(annualEui) * energyRate;

    if (!useMetric) {
      cost = conversions.UsdPerM2toUsdPerFT2(cost);
    }

    return conversions.round(cost, 4);
  };
  getRowId = (row) => {
    return row.runId;
  }

  render() {
    const showTable = this.state.data.length > 0;    

    const rowGroupingModel: GridRowGroupingModel = ['tag'];
    const groupingColumn: GridGroupingColDefOverride = {
      headerName: "Runs",
      sortable: false,
      disableReorder: true,
      disableColumnMenu: true,
      valueGetter: (params: GridCellParams) => {
        const {row} = params;
        if (row.children) {
          return row.name;
        }
        return <SimulationNameComponent name={row?.name?.toString()} status={row.status} enableDownload={this.props.isDownloadEnabled}
                                        runId={row.runId} download={this.props.downloadAction} useSI={this.props.dashboard.useSI} />;
      }
    };
    return (
      <div className="analysis-tab-content" style={{ height: 'calc(calc(100vh - 74px - 80px - 20px - 94px - 40px))' }}>
        {showTable &&
          <DataGrid
            experimentalFeatures={{ columnGrouping: true }}
            treeData
            getTreeDataPath={(row) => row.tag}
            disableRowSelectionOnClick
            disableMultipleColumnsSorting
            disableColumnMenu
            disableColumnReorder
            disableColumnSelector            
            disableColumnFilter
            disableChildrenSorting
            disableMultipleColumnSelection
            disableColumnResize
            rows={this.state.data}
            getRowId={this.getRowId}
            columns={this.state.columns}
            columnGroupingModel={this.state.columnGroups}
            rowGroupingModel={rowGroupingModel}
            onProcessRowUpdateError={(error) => console.log('error', error)}
            groupingColDef={groupingColumn}
            rowHeight={60}
            columnHeaderHeight={50}
          />

        }
      </div>
    );
  }
}

export const totalCellRenderer = (params: GridCellParams) => {
  const { row, value } = params;
  switch (row.status) {
    case "Error":
      return <div><strong style={{ 'color': 'red' }}>Error</strong></div>;
    case "Processing":
      return <div><strong style={{ 'color': 'cornflowerblue' }}>Processing</strong></div>;
    default:
      return <div>{value?.toString()}</div>;
  }
}
type simulationNameComponentProps = {
  download?: Function,
  enableDownload?: boolean,
  name?: string,
  runId?: string,
  status?: string,
  useSI?: boolean
}
export class SimulationNameComponent extends Component<simulationNameComponentProps,any> {

  downloadZip = (ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault();
    this.props.download(this.props.runId);
  }

  getElement = (name: string) => {
    const { status, enableDownload } = this.props;

    if (!status || status == 'Processing' || enableDownload !== true)
      return <div>{name}</div>;

    return <div><a className="download-zip" href="#" onClick={this.downloadZip}>{name}</a></div>;
  }

  render() {
    const { name } = this.props;

    const elements = name.split("||");
    if (elements.length > 1) {
      return <div>
        {
          elements.map((n, i) => {
            if (i == 0)
              return this.getElement(n.trim());

            return <div>{n.trim()}</div>
          })
        }
      </div>;
    }

    return this.getElement(name);
  }
}
