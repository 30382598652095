import React from "react";
import Modal from '@weave-mui/modal';
import { About } from "../../../shared/About";
import i18n from "../../../i18n";
import * as locConsts from "../../../shared/localization/consts";
import * as consts from "../../localization/consts";
import Box from "@weave-mui/box";
import Button, { buttonVariants } from "@weave-mui/button";
import { footerButtonStyle } from "../../../layout/LayoutGrid.stylesheet";

export enum DialogStates {
  ABOUT = "ABOUT"  
}

interface IInfoDialog {
  contentType: keyof typeof DialogStates;
  onClose: (x: keyof typeof DialogStates | null) => void;
}


export const InfoDialog = ({ contentType, onClose }: IInfoDialog) => {
  const onCloseClickHandler = (e) => {
    onClose(null)
  }  

  const renderFooter = () => {
    return (         
        <Box
          display='flex'
          justifyContent='end'          
        >
          <Button
            onClick={onCloseClickHandler}
            type='button'
          variant={buttonVariants.CONTAINED}
          sx={{ ...footerButtonStyle }}
          >
            {i18n.t(consts.MODAL_POPUP_BUTTON_SECONDARY)}
          </Button>
        </Box>      
    );
  };

  return (
    <Modal
      header={i18n.t(locConsts.HEADER_PREVIEW)}
      open={!!contentType}
      onClose={onCloseClickHandler}
      actions={renderFooter()}
      sx={{
        '& .MuiModalWeave-box': {
          width: '43.5vw',
          height: '600px',          
          'maxWidth': '1000px',
          'minWidth': '800px'        
        }
      }}
    >
      <About />
    </Modal>
  );
}

