import { ChangeEvent } from 'react';
import { EcDefinition } from '../types';

export interface IAddECDefinition {
  modelId: string;
  ecDefinitionLabels: string[];
  onClose: () => void;
  selectedECDefinitionToEdit: EcDefinition | undefined;
  selectedECDefinitionToAssign: EcDefinition | undefined;
}

export interface IUnit {
  value: string;
  name: string;
}

export interface IAddECDefinitionAction {
  type: addECDefinitionActions;
  payload?: any;
}

export interface IAddECDefinitionState {
  name: string;
  coefficient: number | string;
  unit: IUnit;
  source: string;
  notes: string;
  labels: string[];
}

export interface INameInputProps {
    name: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    maximumCharactersExceeded: boolean;
}

export interface ICoefficientInputProps {
    coefficient: number | string;
    unit: IUnit,
    onCoefficientChange: (coefficient: ChangeEvent<HTMLInputElement>) => void,
    onUnitChange: (unit: IUnit) => void;
    valueOutOfRange: boolean
}

export interface ISourceInputProps {
  source: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  maximumCharactersExceeded: boolean;
}

export interface INotesInputProps {
  notes: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  maximumCharactersExceeded: boolean;
}

export interface IAddLabelInputProps {
  addNewLabel: (label: string) => void;
  setNewLabelHandler: (label: string) => void;
}

export interface ILabelsInputProps {
  labels: string[];
  selectedLabels: string[];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  addNewLabel: (label: string) => void;
}

export interface IButtonsComponentProps {
  onAddClick: () => void;
  onCancelClick: () => void;
  isAddButtonEnabled: boolean;
  isEdit: boolean;
}

export interface IEditComponentProps {
  tableProps: any;
  openEditModal: (ecDefinition: EcDefinition) => void;
  latestECDefinitionId: string;
}

export interface IDeleteComponentProps {
  tableProps: any;
  deleteDefinition: (ecDefinitionId: string) => void;
}

export interface ISourceComponentProps {
  tableProps: any;
}

export enum addECDefinitionActions {
  setName = 'SET_NAME',
  setCoefficient = 'SET_COEFFICIENT',
  setUnit = 'SET_UNIT',
  setSource = 'SET_SOURCE',
  setNotes = 'SET_NOTES',
  setLabels = 'SET_LABELS',
  addLabel = 'ADD_LABEL',
  setSelectedECDefinition = 'SET_SELECTED_EC_DEFINITION',
  clearForm = 'CLEAR_FORM',
}
