import React from 'react';
import Popper from '@weave-mui/popper';
import { right } from '@popperjs/core/lib/enums';
import Stack from '@weave-mui/stack';
import Typography, { typographyVariants } from '@weave-mui/typography';
import { Grid } from '@weave-mui/material';

export interface MetricPopupLegendProps {
  isVisible: boolean,
  selectedDataSeries: string,
  chartData: any,
  anchor: SVGRectElement
}

export const MetricPopupLegend: React.FC<{props: MetricPopupLegendProps}> = ({props}) => {
  const {isVisible, chartData, selectedDataSeries, anchor} = props;
  return (<Popper
    sx={{
      zIndex: 3,
      "& .WeaveFlyout-arrow": { display: "none" },
      mx: '12px !important'
    }}
    placement={right}
    open={isVisible}
    anchorEl={anchor}>
    <Stack padding={2}>
      {Object.keys(chartData.data).map((key) => {
          return (
            <>
              <Typography variant={typographyVariants.BODY_BOLD}>{key}</Typography>
              {
                chartData.data[key]
                  .map((data) => {
                    const formattedValue = data.data[0].length > 0 ?
                      data.data[0][1].toLocaleString(undefined, { minimumFractionDigits: 2 }) :
                      data.data[0].toLocaleString(undefined, { minimumFractionDigits: 2 });
                    return (
                      <Stack direction={'row'}
                             alignItems={"center"}>
                        {data.color &&<svg height={17} width={17}>
                          <rect x={2} y={2} width={12} height={12} fill={data.color}
                                rx={3} ry={3}
                                className="highcharts-point"/>
                        </svg>}
                        <Grid container
                              direction={"row"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              spacing={3}>
                          <Grid item xs="auto">
                            <Typography
                              variant = {
                                data.name === selectedDataSeries ? typographyVariants.BODY_BOLD : typographyVariants.BODY_REGULAR
                              }
                            >
                              {data.name}
                            </Typography>
                          </Grid>
                          <Grid item xs="auto">
                            <Typography
                              variant={
                                data.name === selectedDataSeries ? typographyVariants.BODY_BOLD : typographyVariants.BODY_REGULAR
                              }
                            >
                              {formattedValue}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                    )}
                  )
              }
            </>
          );
        }
      )}

    </Stack>
  </Popper>)
}
