import * as api from './api';
import { apiActionCreator } from '../shared';
import { RunModel, Results } from '../analysis/dashboard.models';

export const LOAD_DESIGN_OPTIONS_STARTED = 'LOAD_DESIGN_OPTIONS_STARTED';
export const LOAD_DESIGN_OPTIONS_SUCCESS = 'LOAD_DESIGN_OPTIONS_SUCCESS';
export const LOAD_SIMULATIONSSTATUS_SUCCESS = 'LOAD_SIMULATIONSSTATUS_SUCCESS';

export type runStatus = {
    errorCode: number
    runId: string
    name: string
    result: Results
    status: string
    tag: string
}

export const loadRunsStarted = (modelId: string) => {
    return {
        type: LOAD_DESIGN_OPTIONS_STARTED,
        modelId
    };
}

export const loadRunsSuccess = (runs: RunModel[]) => {
    return {
        type: LOAD_DESIGN_OPTIONS_SUCCESS,
        runs
    };
}

export const loadRuns = (modelId: string) => (dispatch: Function, getState: any) => {
    if (loadRunsDataNeeded(getState(), modelId)) {
        return dispatch(forceLoadRunsData(modelId));
    }    
}

export const loadSimulationsStatusSuccess = (statuses: any, param2?: boolean) => {
    return {
        type: LOAD_SIMULATIONSSTATUS_SUCCESS,
        statuses
    };
}

export const loadSimulationsStatus = (forgeBaseUrl: string, ids: any, useSi: boolean) => (dispatch: Function, getState: any) => {
    return dispatch(forceLoadSimulationsStatus(forgeBaseUrl, ids, useSi));
}

function loadRunsDataNeeded(state: any, modelId: string) {
    if (!state.runState.runs[modelId]) {
        return true;
    }

    return !state.runState.runs[modelId].isFetching;
}

export function forceLoadRunsData(modelId: string) {
    return apiActionCreator({
        api: api.getRuns,
        started: loadRunsStarted,
        startedParams: [
            modelId
        ],
        success: loadRunsSuccess
    }, modelId);
}

export function getSimulationStatus(forgeBaseUrl: string, id: string) {
    return apiActionCreator({
        api: api.getSimulationStatus
    }, forgeBaseUrl, id);
}

export function forceLoadSimulationsStatus(forgeBaseUrl: string, ids: any, param4?: boolean) {
    return apiActionCreator({
        api: api.getSimulationsStatus, 
        success: loadSimulationsStatusSuccess
    }, forgeBaseUrl, ids);
}