import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import {AdvancedFactor, DataPointType, Factor, Metric, SimulationFactor, UnitSystem} from '../../../../types/metrics';
import Box from '@weave-mui/box';
import DataPointsBasicDetails from './DataPointsBasicDetails';
import FormulaWrapper from '../../metrics/Formula/FormulaWrapper';
import { FactorsTableHandle } from '../../factors/Table/FactorsTable';
import ActionButtons from '../ActionButtons/ActionButtons';
import { newDataPointId } from '../../../utils/dataPointsUtils';
import { useGetImperialSystem } from '../../../../layout/hooks/useCurrentProjectData';
import { CardDataLoadingProgress } from '../../../../layout/cards/base/CardDataLoadingProgress';
import FactorsInformation from '../../factors/FactorsInformation';

interface DataPointsInformationProps {
  selectedDataPoint: Metric | Factor | AdvancedFactor | undefined;
  originalDataPoint: Metric | Factor | AdvancedFactor | undefined;
  saveDataPoint: (modifiedDataPoint: Metric | Factor) => void;
  isSaveEnabled: boolean;
  setIsSaveEnabled: Dispatch<SetStateAction<boolean>>;
  cancelHandler: () => void;
  setCreateDataPoint: Dispatch<SetStateAction<Metric | Factor | SimulationFactor>>;
  onFormulaUpdate: Dispatch<SetStateAction<string>>;
  isReadOnly: boolean;
  setIsReadOnly: Dispatch<SetStateAction<boolean>>;
  shouldCleanFormula: boolean;
  setShouldCleanFormula: Dispatch<SetStateAction<boolean>>;
  isDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  onCloseModal: () => void;
  simulationFactors: SimulationFactor[]
}

const DataPointsInformation: React.FC<DataPointsInformationProps> = ({
  shouldCleanFormula,
  setShouldCleanFormula,
  selectedDataPoint,
  originalDataPoint,
  saveDataPoint,
  isSaveEnabled,
  setIsSaveEnabled,
  cancelHandler,
  setCreateDataPoint,
  onFormulaUpdate,
  isReadOnly,
  setIsReadOnly,
  isDirty,
  setIsDirty,
  onCloseModal,
  simulationFactors
}) => {
  let { data: useImperial, isLoading: isLoadingUnitSystem } = useGetImperialSystem();
  const [imperialUnits, setImperialUnits] = useState<boolean>(false);
  const [hasErrorInDataPointEditor, setHasErrorInDataPointEditor] = useState<boolean>(false);
  const [hasErrorInFactorsTable, setHasErrorInFactorsTable] = useState<boolean>(false);

  useEffect(() => {
    if (isLoadingUnitSystem) return;
    if (selectedDataPoint == undefined) {
      setImperialUnits(useImperial);
      return;
    }

    if (selectedDataPoint?.id !== newDataPointId && !selectedDataPoint?.isGlobal) {
      setImperialUnits(selectedDataPoint?.unitSystem === UnitSystem.Imperial);
    } else {
      setImperialUnits(useImperial);
    }
  }, [selectedDataPoint?.id, useImperial, isLoadingUnitSystem]);

  const onCancelHandler = useCallback(() => {
    cancelHandler();
  }, [cancelHandler]);

  const disableSaveByFormulaEvalState = useCallback( (hasFormulaError: boolean ) => {
    setHasErrorInDataPointEditor(hasFormulaError);
  }, []);

  const factorsTableRef = useRef<FactorsTableHandle>();
  const retrieveDataAndSave = () => {
    let modifiedDataPoint = selectedDataPoint;
    if (factorsTableRef?.current && selectedDataPoint?.type === DataPointType.Factor ){
      const editedFactorValues = factorsTableRef.current.getState();
      setCreateDataPoint( prevState => {
        modifiedDataPoint = {
          ...prevState,
          dataPointValue: {
            values: editedFactorValues
          }
        }
        return modifiedDataPoint;
      });
    }

    saveDataPoint(modifiedDataPoint);
  };

  if (selectedDataPoint === undefined) return <CardDataLoadingProgress/>;
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'hidden',
      pr: '1rem',
      height: '100%',
      width: '100%'
    }}>
      <DataPointsBasicDetails
        isReadOnly={isReadOnly}
        selectedDataPoint={selectedDataPoint}
        originalSelectedDataPoint={originalDataPoint}
        setCreateDataPoint={setCreateDataPoint}
        imperialUnits={imperialUnits}
        simulationFactors={simulationFactors}
      />
      {
        selectedDataPoint.type === DataPointType.Metric
          ?
            (
              <FormulaWrapper
                isReadOnly={isReadOnly}
                onFormulaUpdate={onFormulaUpdate}
                selectedDataPoint={selectedDataPoint as Metric}
                originalSelectedDataPoint={originalDataPoint as Metric}
                imperialUnits={imperialUnits}
                shouldCleanFormula={shouldCleanFormula}
                setShouldCleanFormula={setShouldCleanFormula}
                disableSaveButton={disableSaveByFormulaEvalState}
              />
            )
          :
            (
              <FactorsInformation
                isReadOnly={isReadOnly}
                selectedFactor={selectedDataPoint as Factor | AdvancedFactor}
                imperialUnits={imperialUnits}
                setHasErrorInFactorsTable={setHasErrorInFactorsTable}
                factorsTableRef={factorsTableRef}
                simulationFactors={simulationFactors}
                setSelectedFactor={setCreateDataPoint as Dispatch<SetStateAction<Factor | AdvancedFactor>>}
              />
            )
      }
      <Box sx={{ alignSelf: 'end' }}>
        <ActionButtons
          selectedDataPoint={selectedDataPoint}
          onCancel={onCancelHandler}
          onSaveDataPoint={retrieveDataAndSave}
          isReadOnly={isReadOnly}
          setIsReadOnly={setIsReadOnly}
          isSaveButtonEnabled={
            isSaveEnabled &&
            !hasErrorInDataPointEditor &&
            !hasErrorInFactorsTable}
          setIsDirty={setIsDirty}
          onCloseModal={onCloseModal}
        />
      </Box>
    </Box>
  )
}

export default DataPointsInformation
