import React, { useContext } from 'react';
import { CardContentContext } from './base/BaseCard';
import { simpleCardDataStyles } from './base/Card.stylesheet';
import { DataPointValue } from '../../types/metrics';
import { DataPointDisplay } from './shared/DataPointDisplay';
import Box from '@weave-mui/box';

export const DataDisplayCard: React.FC = () => {
  const { data } = useContext(CardContentContext);

  return (
    <Box sx={{ ...simpleCardDataStyles, display: 'flex', paddingRight: '4px' }} >
      <DataPointDisplay dataPointValue={data as DataPointValue} enableTooltip={true} />
    </Box >
  );
};
