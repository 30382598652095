import i18n from '../../i18n';
import { DashboardEditMenuOptions } from "../../types/layout";

export const getMenuItemLabel = (menuItem) => {
  if( menuItem === DashboardEditMenuOptions.Rename){
    return i18n.t('dashboard.menuItems.rename')
  }
  if( menuItem === DashboardEditMenuOptions.Duplicate){
    return i18n.t('dashboard.menuItems.duplicate')
  }
  if( menuItem === DashboardEditMenuOptions.Delete){
    return i18n.t('dashboard.menuItems.delete')
  }
  return menuItem;
}