import i18n from '../../i18n';
import { FilteredConstructions } from './types';

export const skylightNameHandler = (constructionName: string): string => {
  if (
    constructionName === i18n.t(`analysis.ec.construction.interiorOpeningsOperableSkylight`) ||
    constructionName === i18n.t(`analysis.ec.construction.exteriorOpeningsOperableSkylight`) ||
    constructionName === i18n.t(`analysis.ec.construction.interiorOpeningsFixedSkylight`) ||
    constructionName === i18n.t(`analysis.ec.construction.exteriorOpeningsFixedSkylight`)
  ) {
    return i18n.t(`analysis.ec.construction.openingSkylight`);
  } else {
    return constructionName;
  }
};

const removeElementDuplicateId = (ilteredConstructions: FilteredConstructions[]) => {
  let objectFilter2 = {};
  return ilteredConstructions.filter((construction) =>
    objectFilter2[construction.constructionId]
      ? false
      : (objectFilter2[construction.constructionId] = true)
  );
};

export const calculateTotalsConstructionTable = (
    filteredConstructions: FilteredConstructions[]
  ): FilteredConstructions => {
    const clearFiltered = removeElementDuplicateId(filteredConstructions);
    return {
      isExterior: false,
      constructionId: null,
      materials: null,
      totalMass:
        clearFiltered
          .reduce(
            (total: number, curr) =>
              total + (curr['totalMass'] ? parseFloat(curr['totalMass']) : 0),
            null
          )
          ?.toString() || null,
      totalVolume:
        clearFiltered
          .reduce(
            (total: number, curr) =>
              total + (curr['totalVolume'] ? parseFloat(curr['totalVolume']) : 0),
            null
          )
          ?.toString() || null,
      constructionName: i18n.t('analysis.ec.construction.totalRowTitle'),
      detailLevel: null,
      constructionDescription: null,
      totalThickness: null,
      area:
        clearFiltered
          .reduce(
            (total: number, curr) => total + (curr['area'] ? parseFloat(curr['area']) : 0),
            null
          )
          ?.toString() || null,
      totalEmbodiedCarbon: clearFiltered.reduce(
        (total: number, curr) =>
          curr['totalEmbodiedCarbon'] === null ? total : curr['totalEmbodiedCarbon'] + total,
        null
      ),
      ecIntensity: null,
      uValue: null,
    };
  };
