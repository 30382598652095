import {
  SHOW_NOTIFICATION,
  DELETE_NOTIFICATION,
  LOAD_CURRENCIES_SUCCESS,
  CLEAR_NOTIFICATIONS,
} from './actions';
import { createReducer } from 'redux-starter-kit';

const notificationState = {
  notifications: {},
  key: 0,
};

export const notificationReducer = createReducer(notificationState, {
  [SHOW_NOTIFICATION]: (state, action: any) => {
    const newKey = action.notification.id || state.key + 1;
    state.key = newKey;
    state.notifications[newKey] = Object.assign(action.notification, { key: newKey, active: true });
  },
  [DELETE_NOTIFICATION]: (state, action: any) => {
    delete state.notifications[action.key];
  },
  [CLEAR_NOTIFICATIONS]: (state, action: any) => {
    state.notifications = {};
  },
});

export type FeatureFlagsState = {
  featureFlags: { [key: string]: boolean };
  flagsLoaded: boolean;
};

const currencyState = {
  currencies: [],
  currenciesLoaded: false,
};

export const currencyReducer = createReducer(currencyState, {
  [LOAD_CURRENCIES_SUCCESS]: (state, action: any) => {
    state.currencies = action.currencies;
    state.currenciesLoaded = true;
  },
});
