import React, { forwardRef, useCallback, useState } from 'react';
import IconButton from '@weave-mui/icon-button';
import Tooltip, { tooltipPlacement, tooltipSize } from '@weave-mui/tooltip';

const { SMALL } = tooltipSize;
const { TOP } = tooltipPlacement;

export type CardHeaderActionButtonOptions = {
  tooltipText: string;
  icon: React.ReactNode;
  onClick: any;
};

export const CardHeaderActionButton = forwardRef<HTMLButtonElement, CardHeaderActionButtonOptions>(
  (props, ref) => {
    const { onClick, icon, tooltipText } = props;

    const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

    const handleOnMouseEnter = useCallback(() => {
      setIsTooltipVisible(true);
    }, []);

    const handleTooltipClose = useCallback(() => {
      setIsTooltipVisible(false);
    }, []);

    const handleOnClick = useCallback(() => {
      onClick?.();
      setIsTooltipVisible(false);
    }, [onClick]);

    return (
      <Tooltip
        arrow
        enterDelay={500}
        enterNextDelay={500}
        open={isTooltipVisible}
        placement={TOP}
        size={SMALL}
        title={tooltipText}
        leaveDelay={100}
        onClose={handleTooltipClose}
        PopperProps={{
          style: {
            zIndex: 10000,
          },
        }}
      >
        <IconButton
          ref={ref}
          onClick={handleOnClick}
          onMouseEnter={handleOnMouseEnter}
          sx={{
            width: '36px',
            height: '36px',
          }}
        >
          {icon}
        </IconButton>
      </Tooltip>
    );
  }
);
