// @ts-nocheck For typedoc to work, we must exclude any files with typescript errors. When it is fixed we can remove this tag
import { createReducer } from 'redux-starter-kit';
import { LOAD_PROJECTMODELS_STARTED, LOAD_PROJECTMODELS_SUCCESS, GET_MODEL_SUCCESS } from './actions';
import { UPLOAD_MODEL_SUCCESS } from '../project/actions';
import { convertObjectToarray } from '../helpers/jsHelper';
import { Model } from '../analysis/dashboard.models';

const initialState = {
  models: {},
  uncategorizedModels: {},
  isFetching: false,
  isError: false,
  modelsFromProject: {}
};
type reducerAction = {
  projectId: string
  type: string
  models?: Model[],
  model?: Model
}

const modelReducer = createReducer(initialState, {
  [LOAD_PROJECTMODELS_STARTED]: (state: any, action: reducerAction) => {
    if (!state.modelsFromProject[action.projectId])
      state.modelsFromProject[action.projectId] = {};

    state.modelsFromProject[action.projectId].isFetching = true;
  },
  [LOAD_PROJECTMODELS_SUCCESS]: (state: any, action: reducerAction) => {
    const updated = convertObjectToarray(state.models).filter((model) => (model as any).projectId === action.projectId);

    updated.forEach((model) => {
      Reflect.deleteProperty(state.models, (model as any).id);
    });

    action.models.forEach(model => {
      state.models[model.id] = model;
    });

    state.modelsFromProject[action.projectId].isFetching = false;
    state.modelsFromProject[action.projectId].isLoaded = true;
  },
  [GET_MODEL_SUCCESS]: (state: any, action: reducerAction) => {
    state.models[action.model.id] = action.model;
  },
  [UPLOAD_MODEL_SUCCESS]: (state: any, action: reducerAction) => {
    if (state.modelsFromProject[action.projectId]) {
      state.modelsFromProject[action.projectId].isLoaded = false;
    }
  }
})

export const getModel = (state: any, modelId: string) => state.models[modelId];

export default modelReducer;
