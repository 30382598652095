import React from 'react';
import Dropdown from '@hig/dropdown';
import '../../../../css/new/lmvlegend.css';
import i18n from '../../../../i18n';
import {
  StackedColumnChartOption,
  PieChartOption,
  VariwideChartOption,
  EcChartType,
} from '../../../../charts/types';
import { addToolTip, removeToolTip } from '../../Lmv/LmvLegendContainer';
import { getLabelForSurfaceCategory } from '../../utils';
import LeftToggle from '../../../../img/toggle_left_default.svg';
import RightToggle from '../../../../img/toggle_right_default.svg';

type chartDataType = StackedColumnChartOption | PieChartOption | VariwideChartOption;

const defaultToolTipTitleValue = '';

const dropdownStylesheet = (styles) => {
  return {
    ...styles,
    dropdownWrapper: {
      ...styles.dropdownWrapper,
      width: '90%',
    },
  };
};

export interface ChartLegendProps {
  defaultChart: string;
  value: string;
  modelId: string;
  onChange: (modelId: string, chart: string) => void;
  chartLegendItems: chartDataType;
  chartLegenedTitle: string;
  EcDropdownOptions: string[];
  isChartLegendCollapsed?: boolean;
  onChartLegendCollapseClick?: Function;
  selectedConstructionId?: string;
  currentHoveredChartMaterialSha?: number;
  selectedMaterialSha?: string;
}

const ChartLegend = (props: ChartLegendProps): JSX.Element => {
  const renderChartLegendItem = (chartData: chartDataType): JSX.Element | JSX.Element[] => {
    let chartLegendtElement: JSX.Element[] = [];
    let greyColor = i18n.t(`analysis.ec.defaultColor`);


    chartData?.data.map((items: any, index: number) => {
      let wrapperClasses = [
        props.selectedConstructionId == null
          ? 'item default'
          : items.constructionIds?.includes(props.selectedConstructionId) &&
            //@ts-ignore
            chartData.title === i18n.t('analysis.ec.charts.ecIntensityByArea.title')
          ? 'item selected'
          : 'item default',
      ];

      let materialColorOpacity = 1;
      let categoryName = items.name;

      if (props.value === EcChartType.EcByConstruction) {
        wrapperClasses.push(
          props.selectedMaterialSha != null && props.selectedMaterialSha === items.sha
            ? 'hover'
            : ' '
        );
        wrapperClasses.push(
          props.currentHoveredChartMaterialSha >= 0 &&
            props.selectedMaterialSha == null &&
            props.currentHoveredChartMaterialSha === index
            ? 'hover'
            : ' '
        );
        materialColorOpacity =
          items.constructionIds?.includes(props.selectedConstructionId) &&
          props.selectedMaterialSha == null
            ? 1
            : props.selectedMaterialSha === items.sha &&
              items.constructionIds?.includes(props.selectedConstructionId)
            ? 1
            : items.constructionIds?.includes(props.selectedConstructionId)
            ? 0.2
            : 1;
      }

      if (props.value === EcChartType.EcByCategory) {
        categoryName = getLabelForSurfaceCategory(items.name);
      }

      chartLegendtElement.push(
        <div className={wrapperClasses.join(' ')} key={index.toString()}>
          <div
            style={{
              ...{
                backgroundColor:
                  props.selectedConstructionId == null ||
                  items.constructionIds?.includes(props.selectedConstructionId)
                    ? items.color
                    : greyColor,
                opacity: materialColorOpacity,
              },
            }}
          ></div>
          <h1
            title={defaultToolTipTitleValue}
            onMouseEnter={(e) => addToolTip(e)}
            onMouseOut={(e) => removeToolTip(e)}
          >
            {categoryName}
          </h1>
        </div>
      );
    });
    return chartLegendtElement;
  };

  return (
    <div className="lmv-legend-container">
      {props.isChartLegendCollapsed && (
        <div className="legend">
          <label className="ecdetails-chart-legend-label">
            {i18n.t('analysis.ec.charts.label.title')}
          </label>
          <Dropdown
            stylesheet={dropdownStylesheet}
            defaultValue={props.defaultChart}
            value={props.value}
            options={props.EcDropdownOptions}
            onChange={(e) => {
              props.onChange(props.modelId, e);
            }}
          />
        </div>
      )}
      {props.isChartLegendCollapsed && (
        <div className="legend-item">{renderChartLegendItem(props.chartLegendItems)}</div>
      )}
      {!props.isChartLegendCollapsed && (
        <div className="show-legend-label">{i18n.t('analysis.ec.charts.legend.toggleTitle')}</div>
      )}
      <div className="lmv-legend-toggle">
        {props.isChartLegendCollapsed && (
          <div
            className="lmv-legend-toggle-icon"
            onClick={() => props.onChartLegendCollapseClick()}
          >
            <RightToggle className="lmv-legend-toggle-logo"/>
          </div>
        )}
        {!props.isChartLegendCollapsed && (
          <div
            className="lmv-legend-toggle-icon"
            onClick={() => props.onChartLegendCollapseClick()}
          >
            <LeftToggle className="lmv-legend-toggle-logo"/>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChartLegend;
