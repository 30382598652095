import adpService from "../../webAnalytics/adp";
import {MetricEvaluationResult} from "../../types/metrics";

type DataPointMap = {
  [id: string]:(paramId: string, evaluationResult: MetricEvaluationResult) => void;
};

const embodiedCarbonDataPointId = 'parameters.TotalCarbonFactoryParameters:cdcdd7de4d4541d3a51e52eb55a4f2e3-1.0.0';

export const analyticsDataPointMap: DataPointMap = {
  [embodiedCarbonDataPointId] : (modelId:string, evaluationResult: MetricEvaluationResult) => {
    const {result: totalEmbodiedCarbon} = evaluationResult;
    adpService.recordDataPointValueEvent(modelId, 'datapointValue', {modelId: modelId, totalEmbodiedCarbon: totalEmbodiedCarbon} as Record<string, unknown>)
  }
};

