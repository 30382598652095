import React, { useEffect, useMemo, useState } from 'react';
import i18n from '../../../i18n';
import { ECValue } from '../../../shared/ECDefinition/ECValue';
import {
  DeleteIconComponentWeave,
  EditIconComponentWeave,
  WeaveSourceComponent,
} from '../AddECDefinition/ECDefinitionComponents';
import { ECDefinitionsTableProps, EcDefinitionsTableColumn } from '../types';
import TooltipContainer from '../../components/TooltipContainer/TooltipContainer';
import WeaveTableWrapper from '../WeaveTable/WeaveTableWrapper';
import { GridCellParams, GridRowParams } from '@weave-mui/data-grid';

const ECDefinitionsTable = 
  ({
  data,
  onCellClick,
  useSI,
  editECDefinition,
  deleteECDefinition,
  latestECDefinitionId,
  apiRef,
  selectedRow,
  disableVirtualization = false
}: ECDefinitionsTableProps): JSX.Element => {

  const [selectedConstructionRow, setSelectedConstructionRow] = useState<string[]>([]);

  useEffect(() => {
    const selectedRowDefinition = selectedRow;

    if (selectedRowDefinition && selectedRowDefinition.id !== undefined) {
      setSelectedConstructionRow([selectedRowDefinition.id.toString()]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowClick = (params: GridRowParams) => {
    if (!selectedConstructionRow.includes(params?.id.toString())) {
      setSelectedConstructionRow([params?.id.toString()]);
    }
  };

  const columns: EcDefinitionsTableColumn[] = [
    {
      field: i18n.t('analysis.ec.ecDefinition.table.headers.name'),
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (props: GridCellParams) => {
        return (
        <TooltipContainer content={props.row['EC Coefficient'].name}/>
      )
      },
      cellWrapperOptions: { lineClamp: '1' }
    },
    {
      field: i18n.t('analysis.ec.ecDefinition.table.headers.coefficient'),
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (props: any) => {
        return (
          <TooltipContainer
            content={
              <ECValue
                value={props.value.average}
                unit={props.value.unit}
                useSI={props.value.useSI}
              />
            }
          />
        );
      },
      cellWrapperOptions: { disable: true }
    },
    {
      field: i18n.t('analysis.ec.ecDefinition.table.headers.notes'),
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (props: GridCellParams) => {
        return (
        <TooltipContainer content={props.row['Notes'].notes}/>
      )
      },
      cellWrapperOptions: { lineClamp: '1' }
    },
    {
      field: i18n.t('analysis.ec.ecDefinition.table.headers.source'),
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (props: GridCellParams) => {
        return(<WeaveSourceComponent tableProps={props}/>);
      },
      cellWrapperOptions: { lineClamp: '1' }
    },
    {
      field: i18n.t('analysis.ec.ecDefinition.table.headers.labels'),
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (props: GridCellParams) => {
        const definitionLabel = props.row['Labels'].labels;
        return(
          <TooltipContainer
          content={definitionLabel?.join(', ')} 
        />
        );
      },
      cellWrapperOptions: { disable: true }
    },
    {
      field: '',
      sortable: false,
      disableColumnMenu: true,
      minWidth: 80,
      width: 80,
      renderCell: (props: GridCellParams) => {
        const definitionButtons = props.row[''].isCustom;
        return definitionButtons ? (
          <div className={'advancedSearchDefinitionManagement'} id="advancedCellIcons" >
            <EditIconComponentWeave
              tableProps={props}
              openEditModal={editECDefinition}
              latestECDefinitionId={latestECDefinitionId}
            />
            <DeleteIconComponentWeave tableProps={props} deleteDefinition={deleteECDefinition} />
          </div>
        ) : null
    },
    cellWrapperOptions: { disable: true }
  }
  ];

  const rows = data.map((item) => {
    return {
      id: item.id,
      [`${i18n.t('analysis.ec.ecDefinition.table.headers.name')}`]: item,
      [`${i18n.t('analysis.ec.ecDefinition.table.headers.coefficient')}`]: {...item, useSI: useSI},
      [`${i18n.t('analysis.ec.ecDefinition.table.headers.notes')}`]: item,
      [`${i18n.t('analysis.ec.ecDefinition.table.headers.source')}`]: item,
      [`${i18n.t('analysis.ec.ecDefinition.table.headers.labels')}`]: item,
      '': item
    }
  })

  const memorizeColumns = useMemo(() => {
    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WeaveTableWrapper
      columns={memorizeColumns}
      onCellClick={onCellClick}
      rows={rows}
      apiRef={apiRef}
      rowSelectionModel={selectedConstructionRow}
      onRowClick={handleRowClick}
      disableVirtualization={disableVirtualization}
      disableColumnReorder
      getRowHeight={() => 'auto'}
      columnHeaderHeight={32}
      slotProps={{
        noRowsOverlay: {
          description: '',
          illustrationVariant: i18n.t(
            'analysis.ec.ecDefinition.table.placeholder.illustrationVariant'
          ),
          subject: i18n.t('analysis.ec.ecDefinition.table.placeholder.subject'),
          title: i18n.t('analysis.ec.ecDefinition.table.placeholder.title'),
        },
      }}
      sx={{
        ...(rows.length === 0 && {
          '.MuiDataGrid-virtualScroller': {
            overflow: 'hidden'
          }
        })
      }}
    />
  );
}

export default ECDefinitionsTable;


