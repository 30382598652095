import * as api from "./run/api";
import * as consts from "./consts";
import ForgeParameters from "@adsk/forge-parameters";
import ErrorLogger from "./errorLogger";
import authClient from "./authClient";
import {UNIT_BTUPERH, UNIT_BTUPERSQF} from "./consts";

type Unitsymbol = {
    prefixOrSuffix: {
        placement: { value: number };
        space: boolean;
        text: string;
    };
    typeId: string;
    unit: object;
};

const unitSchemas = [
    { scheme: consts.DIMENSION_MASS },
    { scheme: consts.DIMENSION_LENGTH },
    { scheme: consts.DIMENSION_TIME },
    { scheme: consts.DIMENSION_TEMPERATURE },
    { scheme: consts.FACTOR_PREFIX_CENTI },
    { scheme: consts.FACTOR_PREFIX_GIGA },
    { scheme: consts.FACTOR_PREFIX_KILO },
    { scheme: consts.FACTOR_PREFIX_MEGA },
    { scheme: consts.FACTOR_TEN },
    { scheme: consts.FACTOR_MILLI },
    { scheme: consts.FACTOR_NINEFIFTHS },
    { scheme: consts.SYMBOL_CFMPERFTSUP2 },
    { scheme: consts.SYMBOL_KBTU },
    { scheme: consts.SYMBOL_KBTUPERFTSUP2 },
    { scheme: consts.SYMBOL_KWH },
    { scheme: consts.SYMBOL_KWHPERMSUP2 },
    { scheme: consts.SYMBOL_MWH },
    { scheme: consts.SYMBIL_WPERFTSUP2 },
    { scheme: consts.UNIT_BRITISHTHERMALUNITS },
    { scheme: consts.UNIT_CENTIMETERS },
    { scheme: consts.UNIT_CUBICFEET },
    { scheme: consts.UNIT_CUBICFEETPERMINUTE },
    { scheme: consts.UNIT_CUBICFEETPERMINUTESQUAREMETER },
    { scheme: consts.UNIT_CUBICFEETPERMINUTESQUAREFOOT },
    { scheme: consts.UNIT_CUBICMETER },
    { scheme: consts.UNIT_CUBICMETERPERHOUR },
    { scheme: consts.UNIT_CUBICMETERPERHOURSQUAREMETER },
    { scheme: consts.UNIT_FEET },
    { scheme: consts.UNIT_GIGAJOULES },
    { scheme: consts.UNIT_HOURS },
    { scheme: consts.UNIT_INCHES },
    { scheme: consts.UNIT_JOULES },
    { scheme: consts.UNIT_KILOGRAMS },
    { scheme: consts.UNIT_KILOGRAMSPERCUBICMETER },
    { scheme: consts.UNIT_KILOWATTS },
    { scheme: consts.UNIT_KILOWATTHOURS },
    { scheme: consts.UNIT_KILOWATTHOURSPERSQUAREMETER },
    { scheme: consts.UNIT_MEGAJOULES },
    { scheme: consts.UNIT_MEGAJOULESPERSQUAREMETER },
    { scheme: consts.UNIT_MEGAWATTS },
    { scheme: consts.UNIT_MEGAWATTHOURS },
    { scheme: consts.UNIT_METERS },
    { scheme: consts.UNIT_METERSPERSECOND },
    { scheme: consts.UNIT_METERSPERSECONDSQUARED },
    { scheme: consts.UNIT_MILLIMETERS },
    { scheme: consts.UNIT_MINUTES },
    { scheme: consts.UNIT_NEWTONS },
    { scheme: consts.UNIT_NEWTONMETERS },
    { scheme: consts.UNIT_POUNDSPERCUBICFOOT },
    { scheme: consts.UNIT_SECONDS },
    { scheme: consts.UNIT_SQUAREFEET },
    { scheme: consts.UNIT_SQUAREMETERS },
    { scheme: consts.UNIT_THERMS },
    { scheme: consts.UNIT_THOUSANDBRITISHTHERMALUNITS },
    { scheme: consts.UNIT_THOUSANDBRITISHTHERMALUNITSPERSQUAREFOOT },
    { scheme: consts.UNIT_WATTS },
    { scheme: consts.UNIT_WATTSPERSQUAREMETER },
    { scheme: consts.UNIT_WATTSPERSQUAREFOOT },
    { scheme: consts.UNIT_KILOGRAMSPERSQUAREMETER },
    { scheme: consts.UNIT_POUNDSPERSQUAREFOOT },
    { scheme: consts.UNIT_POUNDMASS },
    { scheme: consts.UNIT_KILOGRAMSPERMETER },
    { scheme: consts.UNIT_POUNDSPERFEET },
    { scheme: consts.UNIT_RANKINE },
    { scheme: consts.UNIT_KELVIN },
    { scheme: consts.UNIT_FAHRENHEIT },
    { scheme: consts.UNIT_SQUAREMETERKPERWATTS },
    { scheme: consts.UNIT_HOURSQUAREFOOTFPERBTU },
    { scheme: consts.SYMBOL_MM },
    { scheme: consts.SYMBOL_IN },
    { scheme: consts.SYMBOL_KGPERMSUP3 },
    { scheme: consts.SYMBOL_POUNDSPERFTSUP3 },
    { scheme: consts.SYMBOL_MSSUP2KPERW },
    { scheme: consts.SYMBOL_HOURFTSUP2DEGREEFPERBTU },
    { scheme: consts.SYMBOL_MSUP2 },
    { scheme: consts.SYMBOL_MSUP3 },
    { scheme: consts.SYMBOL_KG },
    { scheme: consts.SYMBOL_FTSUP2 },
    { scheme: consts.SYMBOL_FTSUP3 },
    { scheme: consts.SYMBOL_LB },
    { scheme: consts.UNIT_POUNDSFORCE },
    { scheme: consts.UNIT_STANDARGRAVITY },
    { scheme: consts.UNIT_BTUPERHOURSQFOOTDEGF },
    { scheme: consts.SYMBOL_BTUPERHOURSQFOOTDEGF },
    { scheme: consts.UNIT_WPERSQMK},
    { scheme: consts.SYMBOL_WPERSQMK},
    { scheme: consts.UNIT_BTUPERSQF},
    { scheme: consts.UNIT_BTUPERH}
];

class ForgeUnitService {
    parametersEngine: any;
    unitEngine: any;
    useForgeUnitService: any;

    isParametersEngineInitialized() {
        return this.parametersEngine && this.unitEngine;
    }

    initParametersEngine(forgeBaseUrl: string, useForgeUnitService: boolean): Promise<boolean> {
        this.useForgeUnitService = useForgeUnitService;
        if (useForgeUnitService && !this.isParametersEngineInitialized()) {
            return new Promise((resolve) => {
                try {
                    // note - the 'then' function here is NOT the same as Promise.then
                    // it is part of the Forge Parameters WebAssembly module, do not try to use await
                    ForgeParameters().then(async (parametersLib) => {
                        const parametersEngine = parametersLib.CreateParametersEngine();
                        // important to wait for the schemas to be loaded and registered
                        // to prevent possible usage before fully initialised
                        const schemasRegistered = await this.registerSchemas(parametersEngine, forgeBaseUrl);
                        this.parametersEngine = parametersEngine;
                        console.log(`Forge Parameters and Units engine initialised - ${schemasRegistered}`);
                        resolve(schemasRegistered);
                    });
                } catch (error) {
                    ErrorLogger.logError(error);
                    resolve(false);
                }
            });
        } else {
            return Promise.resolve(false);
        }
    }

    async registerSchemas(parametersEngine: any, forgeBaseUrl: string): Promise<boolean> {
        const accessToken = await authClient.getAccessToken();
        if (accessToken !== null) {
            const unitEngine = parametersEngine.getUnitsEngine();
            const getSchemaRequests = unitSchemas.map((schema) => api.getUnitScheme(forgeBaseUrl, schema.scheme));
            const getSchemaResponses: any[] = await Promise.all(getSchemaRequests);
            getSchemaResponses.forEach((response) => {
                if (response?.schema) {
                    unitEngine.registerSchema(response.schema);
                }
            });

            unitEngine.resolveSchemas();
            this.unitEngine = unitEngine;
            return true;
        }

        return false;
    }

    convertGJtoMWh(value) {
        return this.convert(value, consts.UNIT_GIGAJOULES, consts.UNIT_MEGAWATTHOURS, consts.FACTOR_GJTOMWH);
    }

    convertMJm2tokWhm2(value) {
        return this.convert(value, consts.UNIT_MEGAJOULESPERSQUAREMETER, consts.UNIT_KILOWATTHOURSPERSQUAREMETER, consts.FACTOR_MJM2TOKWHM2);
    }

    convertkWhm2toMJm2(value) {
        return this.convert(value, consts.UNIT_KILOWATTHOURSPERSQUAREMETER, consts.UNIT_MEGAJOULESPERSQUAREMETER, consts.FACTOR_KWHM2TOMJM2);
    }

    convertGJtokBtu(value) {
        return this.convert(value, consts.UNIT_GIGAJOULES, consts.UNIT_THOUSANDBRITISHTHERMALUNITS, consts.FACTOR_GJTOKBTU);
    }

    convertWm2toWft2(value) {
        return this.convert(value, consts.UNIT_WATTSPERSQUAREMETER, consts.UNIT_WATTSPERSQUAREFOOT, consts.FACTOR_WM2TOWFT2);
    }

    convertCFMm2toCFMft2(value) {
        return this.convert(value, consts.UNIT_CUBICFEETPERMINUTESQUAREMETER, consts.UNIT_CUBICFEETPERMINUTESQUAREFOOT, consts.FACTOR_CFMM2TOCFMFT2);
    }

    convertMJm2tokBtuft2(value) {
        return this.convert(value, consts.UNIT_MEGAJOULESPERSQUAREMETER, consts.UNIT_THOUSANDBRITISHTHERMALUNITSPERSQUAREFOOT, consts.FACTOR_MJM2TOKBTUFT2
        );
    }

    convertWft2toWm2(value) {
        return this.convert(value, consts.UNIT_WATTSPERSQUAREFOOT, consts.UNIT_WATTSPERSQUAREMETER, consts.FACTOR_WFT2TOWM2);
    }

    convertCFMft2toCFMm2(value) {
        return this.convert(value, consts.UNIT_CUBICFEETPERMINUTESQUAREFOOT, consts.UNIT_CUBICFEETPERMINUTESQUAREMETER, consts.FACTOR_CFMFT2TOCFMM2);
    }

    convertGJtoKWh(value) {
        return this.convert(value, consts.UNIT_GIGAJOULES, consts.UNIT_KILOWATTHOURS, consts.FACTOR_GJTOKWH);
    }

    convertUsdM2toUsdFT2(value) {
        return this.convert(value, consts.UNIT_SQUAREFEET, consts.UNIT_SQUAREMETERS, consts.FACTOR_USDM2TOUSDFT2);
    }

    convertThermstoKwh(value) {
        return this.convert(value, consts.UNIT_THERMS, consts.UNIT_KILOWATTHOURS, consts.FACTOR_THERMSTOKWH);
    }

    convertm3hm2toCFMft2(value) {
        return this.convert(value, consts.UNIT_CUBICMETERPERHOURSQUAREMETER, consts.UNIT_CUBICFEETPERMINUTESQUAREFOOT, consts.FACTOR_M3HM2TOCFMFT2);
    }

    convertCFMft2tom3hm2(value) {
        return this.convert(value, consts.UNIT_CUBICFEETPERMINUTESQUAREFOOT, consts.UNIT_CUBICMETERPERHOURSQUAREMETER, consts.FACTOR_CFMFT2TOM3HM2);
    }

    convertKGm2toPoundsFt2(value: number): number {
        return this.convert(value, consts.UNIT_KILOGRAMSPERSQUAREMETER, consts.UNIT_POUNDSPERSQUAREFOOT, consts.FACTOR_KGM2TOPFT2);
    }

    convertPoundstoKg(value: number): number {
        return this.convert(value, consts.UNIT_POUNDMASS, consts.UNIT_KILOGRAMS, consts.FACTOR_POUNDSMASSTOKG);
    }

    convertKgtoPounds(value: number): number {
        return this.convert(value, consts.UNIT_KILOGRAMS, consts.UNIT_POUNDMASS, consts.FACTOR_KGTOPOUNDSMASS);
    }

    convertKgToTons(value: number): number {
        return value * this.getConversionFactor(consts.FACTOR_KGTOTONS);
    }

    convertMetersToFeets(value: number): number {
        return this.convert(value, consts.UNIT_METERS, consts.UNIT_FEET, consts.FACTOR_MTOFT);
    }

    convertKgm3toPoundsFt3(value: number): number {
        return this.convert(value, consts.UNIT_KILOGRAMSPERCUBICMETER, consts.UNIT_POUNDSPERCUBICFOOT, consts.FACTOR_KGM3TOPFT3);
    }

    convertm2toft2(value: number): number {
        return this.convert(value, consts.UNIT_SQUAREMETERS, consts.UNIT_SQUAREFEET, consts.FACTOR_M2TOFT2);
    }

    convertm3toft3(value: number): number {
        return this.convert(value, consts.UNIT_CUBICMETER, consts.UNIT_CUBICFEET, consts.FACTOR_M3TOFT3);
    }

    convertmtomm(value: number): number {
        return this.convert(value, consts.UNIT_METERS, consts.UNIT_MILLIMETERS, consts.FACTOR_MTOMM);
    }

    convertmtoin(value: number): number {
        return this.convert(value, consts.UNIT_METERS, consts.UNIT_INCHES, consts.FACTOR_MTOIN);
    }

    convertKgKgToKgPounds(value: number): number {
        return this.convert(value, consts.UNIT_POUNDMASS, consts.UNIT_KILOGRAMS, consts.FACTOR_KGKGTOKGLBS);
    }

    convertKGmtoPoundsFt(value: number): number {
        return this.convert(value, consts.UNIT_KILOGRAMSPERMETER, consts.UNIT_POUNDSPERFEET, consts.FACTOR_KGMTOLBSFT);
    }

    convertM2KWTohrFt2Btu(value: number): number {
        return this.convert(value, consts.UNIT_SQUAREMETERKPERWATTS, consts.UNIT_HOURSQUAREFOOTFPERBTU, consts.FACTOR_M2KWTOHRFT2BTU);
    }

    convertKiloWToMegaW(value: number): number {
        return this.convert(value, consts.UNIT_KILOWATTS, consts.UNIT_MEGAWATTS, consts.FACTOR_KILOTOMEGA);
    }

    convertMegaJToGiga(value: number): number {
        return this.convert(value, consts.UNIT_MEGAJOULES, consts.UNIT_GIGAJOULES, consts.FACTOR_MEGATOGIGA);
    }

    convertToFractionValue(value: number): string {
        return this.isParametersEngineInitialized() ? this.unitEngine.stringifyFraction(value, 8, null) : null;
    }

    convert(value, baseSchema, schema, factor) {
        if (value != null) {
            var convertedValue = this.isParametersEngineInitialized() ? this.unitEngine.convert(value, baseSchema, schema) : 0;

            if (convertedValue === 0 && !this.useForgeUnitService) {
                convertedValue = value * this.getConversionFactor(factor);
            }
            return this.roundValue(convertedValue, 4);
        }
    }

    getSymbol(unit: string) {
        if (this.isParametersEngineInitialized()) {
            const symbolObj: Unitsymbol = this.unitEngine.getSymbol(unit);
            return symbolObj.prefixOrSuffix.text;
        } else {
            console.warn("Forge Parameters and Units engine not initialised, unable to display unit symbol");
            return "";
        }
    }

    roundValue(value, decimals) {
        if (this.isParametersEngineInitialized()) {
            return parseFloat(this.unitEngine.stringifyFixedPoint(value, decimals, null));
        } else {
            return Number(Math.round(Number(value + "e" + decimals)) + "e-" + decimals);
        }
    }

    getConversionFactor(factor) {
        switch (factor) {
            case consts.FACTOR_GJTOMWH:
            case consts.FACTOR_MJM2TOKWHM2:
                return 0.2777777777777778;
            case consts.FACTOR_KWHM2TOMJM2:
                return 3.6;
            case consts.FACTOR_GJTOKBTU:
                return 947.8171203133172;
            case consts.FACTOR_WM2TOWFT2:
            case consts.FACTOR_CFMM2TOCFMFT2:
            case consts.FACTOR_USDM2TOUSDFT2:
                return 0.09290304;
            case consts.FACTOR_MJM2TOKBTUFT2:
                return 0.08805509184115293;
            case consts.FACTOR_WFT2TOWM2:
            case consts.FACTOR_CFMFT2TOCFMM2:
                return 10.763910416709722;
            case consts.FACTOR_GJTOKWH:
                return 277.77777777777777;
            case consts.FACTOR_THERMSTOKWH:
                return 29.307107017222222;
            case consts.FACTOR_M3HM2TOCFMFT2:
                return 0.054680664916885384;
            case consts.FACTOR_CFMFT2TOM3HM2:
                return 18.288;
            case consts.FACTOR_KGM2TOPFT2:
                return 0.204816114;
            case consts.FACTOR_POUNDSMASSTOKG:
                return 0.45359237;
            case consts.FACTOR_KGTOPOUNDSMASS:
                return 2.20462262185;
            case consts.FACTOR_KGTOTONS:
            case consts.FACTOR_KILOTOMEGA:
            case consts.FACTOR_MEGATOGIGA:
                return 0.001;
            case consts.FACTOR_MTOFT:
                return 3.28084;
            case consts.FACTOR_KGM3TOPFT3:
                return 0.062428;
            case consts.FACTOR_M2TOFT2:
                return 10.76391041671;
            case consts.FACTOR_M3TOFT3:
                return 35.3146667;
            case consts.FACTOR_MTOMM:
                return 1000;
            case consts.FACTOR_MTOIN:
                return 39.3701;
            case consts.FACTOR_KGKGTOKGLBS:
                return 0.4535;
            case consts.FACTOR_KGMTOLBSFT:
                return 0.671968;
            case consts.FACTOR_M2KWTOHRFT2BTU:
                return 5.678263337;
            default:
                return 0;
        }
    }
}

const forgeUnits = new ForgeUnitService();
export default forgeUnits;
