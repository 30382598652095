import React, { useState, useEffect } from 'react';
import DefaultProjectImage from './img/default-project-image3.png';
import DefaultProjectImage5 from './img/default-project-image5.png';
import '../css/projectSelector.css';
import i18n from '../i18n';
import * as locConsts from "./localization/consts";
import { ProjectModel } from './ProjectTypes';
import { Model } from '../analysis/dashboard.models';
import { useGetProjectsQuery, useMoveModelMutation } from '../state/api/project-model-data-api';

export type ProjectSelectorProps = {
  hideModal: () => void,
  model: Model,
  currentProjectId: string
}

const ProjectSelector : React.FC<ProjectSelectorProps> = (props) => {
  const [projects, setProjects] = useState<ProjectModel[]>([]);
  const [enableOkButton, setEnableOkButton] = useState<boolean>(false);
  const { data: projectsData } = useGetProjectsQuery()
  const [moveModelMutation] = useMoveModelMutation();
  
  useEffect(() => {
    if (projectsData) {
      setProjects(projectsData);
    }
  }, [projectsData]);

  const selectProject = (projectClicked: ProjectModel) => {   
    var projectsModified = projects.map(function (project) {
      const modifiedProject: ProjectModel = { ...project };
      if (project.id === projectClicked.id) {
        modifiedProject.selected = true;
      } else {
        modifiedProject.selected = false;
      }
      return modifiedProject;
    });

    var enableOkButton = projectsModified.some(x => x.selected === true);

    setProjects(projectsModified);
    setEnableOkButton(enableOkButton);
  }

  const moveModel = () => {
    var projectSelected: ProjectModel = null;
    const { id, name } = props.model;
    
    projectSelected = projects.find(function (project) {
      return project.selected === true;
    }, this);

    if (projectSelected != null) {
      moveModelMutation({ id, projectId: projectSelected.id, name });
      props.hideModal();
    }
  }
  const filteredProjects = projects?.filter((project) => project.id !== props.currentProjectId)
  const renderProjects = filteredProjects?.map(function (project) {
    const image = project.isUncategorized ? DefaultProjectImage : DefaultProjectImage5;
    return <div className="col-xs-12 col-sm-6 col-lg-4" onClick={(e) => selectProject(project)} key={project.id}>
      <div className={"insight " + (project.selected && 'selected')}>
        <div>
          <div title={project.name} className="scale" style={{ backgroundImage: `url('${image}')` }}></div>
          <div className="name">{project.name}</div>
        </div>
      </div>
    </div >
  }, this);

  const className = enableOkButton ? "" : "disabled";

  if (!projectsData) {
    return null;
  }

  return (
    <div className="projects-container">
      <div className="projects-wrapper">
        <div className="projects-header">
          <span className="title">{i18n.t(locConsts.PROJECT_SELECTOR_MOVE)}</span>
          <span className="icon-delete" onClick={props.hideModal}></span>
        </div>
        <div className="projects-body">
          <div className="projects-grid">
            <div className="container-fluid">
              <div className="row no-gutter">
                {renderProjects}
              </div>
            </div>
          </div>
        </div>
        <div className="projects-footer">
          <button className={`btn ok, ${className}`} onClick={moveModel}>{i18n.t(locConsts.PROJECT_SELECTOR_DONE)}</button>
        </div>
      </div>
    </div>
  );
}

export default ProjectSelector
