import { createReducer } from 'redux-starter-kit'
import { LOAD_DESIGN_OPTIONS_STARTED, LOAD_DESIGN_OPTIONS_SUCCESS, LOAD_SIMULATIONSSTATUS_SUCCESS, runStatus } from './actions';
import * as conv from '../conversions';
import { RunModel, Results } from '../analysis/dashboard.models';

export type action = {
    modelId: string,
    type: string,
    runs?: RunModel[],
    statuses?: any 
}

const initialState = {
    runs: {},
    runsResults: {}
};

const runsReducer = createReducer(initialState, {
    [LOAD_DESIGN_OPTIONS_STARTED]: (state: any, action: action) => {
        state.runs[action.modelId] = { isFetching: true };
    },
    [LOAD_DESIGN_OPTIONS_SUCCESS]: (state: any, action: action) => {
        state.runs[action.runs[0].modelId] = {
            isFetching: false,
            items: action.runs
        }
    },
    [LOAD_SIMULATIONSSTATUS_SUCCESS]: (state: any, action: action) => {
        action.statuses.forEach((item) => {
            if (item.status === "Completed" || item.status === "Error") {
                state.runsResults[item.runId] = getResultItem(item);
            }
        })
    }
});

export const getResultItem = (result: runStatus) => {
    let obj = {
        runId: result.runId,
        name: result.name,
        status: result.status,
        tag: result.tag,
        ...result.result
    };

    return obj;
}

export const getConvertedResult = (result: Results, useSi: boolean) => {
    let obj = {
        ...result
    };

    if (useSi) {
        obj.annualElecUsed = conv.GJtoMWh(obj.annualElecUsed);
        obj.annualNaturalGas = conv.GJtoMWh(obj.annualNaturalGas);
        obj.annualDistrictCooling = conv.GJtoMWh(obj.annualDistrictCooling);
        obj.annualDistrictHeating = conv.GJtoMWh(obj.annualDistrictHeating);
        obj.annualEuiElec = conv.MJm2tokWhm2(obj.annualEuiElec);
        obj.annualEuiFuel = conv.MJm2tokWhm2(obj.annualEuiFuel);
        obj.annualEui = conv.MJm2tokWhm2(obj.annualEui);
        obj.inputPlugLoadValue = obj.inputPlugLoadValue;
        obj.inputLightingValue = obj.inputLightingValue;
        obj.inputInfiltrationValue = obj.inputInfiltrationValue;
    } else {
        obj.annualElecUsed = conv.GJtokBtu(obj.annualElecUsed);
        obj.annualNaturalGas = conv.GJtokBtu(obj.annualNaturalGas);
        obj.annualDistrictCooling = conv.GJtokBtu(obj.annualDistrictCooling);
        obj.annualDistrictHeating = conv.GJtokBtu(obj.annualDistrictHeating);
        obj.annualEuiElec = conv.MJm2tokBtuft2(obj.annualEuiElec);
        obj.annualEuiFuel = conv.MJm2tokBtuft2(obj.annualEuiFuel);
        obj.annualEui = conv.MJm2tokBtuft2(obj.annualEui);
        obj.inputPlugLoadValue = obj.inputPlugLoadValue ? conv.Wm2toWft2(obj.inputPlugLoadValue) : obj.inputPlugLoadValue;
        obj.inputLightingValue = obj.inputLightingValue ? conv.Wm2toWft2(obj.inputLightingValue) : obj.inputLightingValue;
        obj.inputInfiltrationValue = obj.inputInfiltrationValue ? conv.CFMm2toCFMft2(obj.inputInfiltrationValue) : obj.inputInfiltrationValue;
    }
    obj.inputWwrNorthValue = obj.inputWwrNorthValue;
    obj.inputWwrEastValue = obj.inputWwrEastValue;
    obj.inputWwrWestValue = obj.inputWwrWestValue;
    obj.inputWwrSouthValue = obj.inputWwrSouthValue;

    return obj;
}

export const getRuns = (state: any, modelId: string) => {
    if (state.runs[modelId]) {
        return state.runs[modelId].items;
    }

    return null;
}

export const getResults = (state: any, runs: RunModel[]) => {
    const results = [];

    runs.forEach(d => {
        if (state.runsResults[d.runId]) {
            results.push(state.runsResults[d.runId]);
        }
    });

    return results;
}

export default runsReducer;