import React, { useCallback } from 'react';
import Modal, { ModalProps } from '@weave-mui/modal';
import Button, { buttonVariants } from '@weave-mui/button';
import Typography, { typographyVariants } from '@weave-mui/typography';
import TextLink from '@hig/text-link';
import Box from '@weave-mui/box';
import { AlertS, ErrorS, InfoS } from "@weave-mui/icons-weave";
import { closeModalReasons } from "../utils/constants";
import { footerButtonStyle, headerIconStyle } from '../layout/LayoutGrid.stylesheet';

const modalStyles = {
  zIndex: 15000,
  '& .MuiModalWeave-box': {
    width: '400px!important' as '400px',
    height: 'auto!important' as 'auto',
  }  
};

interface DefinitionModalPopUp {
  title: ModalProps['header'];
  onCloseClick: () => void;
  textPrimaryBn?: string;
  textSecondaryBn?: string;
  onLinkClick: () => void;
  text: string;
  textLink?: string;
  open: ModalProps['open'];
  disablePortal?: ModalProps['disablePortal'];
  props?: ModalProps;
  iconHeader?: IconHeader;
}

const { OUTLINED } = buttonVariants;

export enum IconHeader {
  Info = 0,
  Warn = 1,
  Error = 2
}

export const ModalPopup = ({
  title,
  onCloseClick,
  textPrimaryBn,
  textSecondaryBn,
  onLinkClick,
  text,
  textLink,
  open,
  iconHeader,
  ...props
}: DefinitionModalPopUp) => {
  let iconH;
  switch (iconHeader) {
    case IconHeader.Error:
      iconH = <ErrorS style={headerIconStyle} color="error" />;
      break;
    case IconHeader.Warn:
      iconH = <AlertS style={headerIconStyle} color="warning" />;
      break;
    case IconHeader.Info:
      iconH = <InfoS style={headerIconStyle} color="info" />;
      break;
    default:
      iconH = <></>;
      break;
  }

  const closeHandler = useCallback((_: {}, reason: string) => {
    if (reason === closeModalReasons.backDrop)
      return;
    onCloseClick();
  }, [onCloseClick]);

  return (
    <Modal
      {...props}
      open={open}
      sx={{ ...modalStyles }}
      header={
        <Typography variant={typographyVariants.H3_MEDIUM}>
          {iconH}
          {title}
        </Typography>
      }
      disableEscapeKeyDown
      onClose={closeHandler}
      components={{ Root: 'article' }}
      actions={
        <Box display="flex" justifyContent="flex-end" gap={3} >
          {
            (typeof textSecondaryBn != 'undefined' && textSecondaryBn) ?
              (
                <Button
                  sx={{ ...footerButtonStyle }}
                  onClick={onCloseClick}
                  variant={OUTLINED}
                >
                  {textSecondaryBn}
                </Button>
              ) :
              (<></>)
          }
          <Button 
            sx={{ ...footerButtonStyle }}  
            onClick={onLinkClick}>
            {textPrimaryBn}
          </Button>
        </Box >
      }
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <Typography align='justify'>
          {text}
          <TextLink onClick={onLinkClick}>{textLink}</TextLink>
        </Typography>
      </Box>
    </Modal >
  );
};

export default ModalPopup;
