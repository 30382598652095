import {
  BreakdownConstructionItems,
  BreakdownOpeningItems,
  ECBreakdownConstruction,
  EcBreakdownItem,
  ECBreakdownOpening,
  MaterialRow,
  ModelEmbodiedCarbonBreakdownEntity,
} from '../dashboard.models';
import {
  CategoryTypeValues,
  EcDefinition,
  KG,
  M2,
  M2RSI,
  M3,
  ITEM,
  MaterialEcDefinitionUpdate,
} from './types';
import { OpeningType, SurfaceType } from '../../consts';
import * as modelType from '../dashboard.models';
import * as locConsts from '../localization/consts';
import {
  convertECDefinitionsKgtoPounds,
  convertECDefinitionsm2toft2,
  convertECDefinitionsm3toft3,
  convertECDefinitionsmtoft,
  convertECDefinitionsRSItoR,
} from '../helper';
import i18n from '../../i18n';

export const OtherCategoryLmvElementColor = '#90928B';

const updateBreakdownItems = <T extends EcBreakdownItem>(
  breakdownElements: T[],
  ecDefinition: EcDefinition,
  updatedMaterialMap: Map<string, MaterialEcDefinitionUpdate>
): T[] => {
  return breakdownElements.reduce((acc, item, itemIndex) => {
    // update materials if necessary
    const updatedMats = item.materials.map((mat, matIndex) => {
      if (updatedMaterialMap.has(mat.uuid)) {
        const { embodiedCarbon } = updatedMaterialMap.get(mat.uuid);
        return {
          ...breakdownElements[itemIndex].materials[matIndex],
          ecDefinitionAssignment: {
            ecDefinition: ecDefinition,
            embodiedCarbon: embodiedCarbon,
          },
        };
      }
      return { ...mat };
    });

    // update constructions, if necessary
    const updatedItem = {
      ...item,
      ecDefinitionAssignment: updatedMaterialMap.has(item.uuid)
        ? {
            ecDefinition: ecDefinition,
            embodiedCarbon: updatedMaterialMap.get(item.uuid).embodiedCarbon,
          }
        : item.ecDefinitionAssignment,
      materials: updatedMats,
    };

    acc.push(updatedItem);
    return acc;
  }, []);
};

export const updateEcBreakdown = (
  modelEcBreakdown: ModelEmbodiedCarbonBreakdownEntity,
  ecDefinition: EcDefinition,
  updatedMaterials: MaterialEcDefinitionUpdate[]
): ModelEmbodiedCarbonBreakdownEntity => {
  const updatedMaterialMap = new Map(updatedMaterials.map((obj) => [obj.elementId, obj]));

  let newConstr: BreakdownConstructionItems = {};
  Object.keys(modelEcBreakdown.constructionItems).forEach((key) => {
    newConstr[key] = updateBreakdownItems<ECBreakdownConstruction>(
      modelEcBreakdown.constructionItems[key],
      ecDefinition,
      updatedMaterialMap
    );
  });

  let newOpenings: BreakdownOpeningItems = {};
  Object.keys(modelEcBreakdown.openingItems).forEach((key) => {
    newOpenings[key] = updateBreakdownItems<ECBreakdownOpening>(
      modelEcBreakdown.openingItems[key],
      ecDefinition,
      updatedMaterialMap
    );
  });

  return { ...modelEcBreakdown, constructionItems: newConstr, openingItems: newOpenings };
};

const surfaceCategoryToLabelMap = new Map<string, string>([
  [CategoryTypeValues.exteriorWall, i18n.t('analysis.ec.lmvLegendLabel.ExteriorWall')],
  [CategoryTypeValues.exteriorOpenings, i18n.t('analysis.ec.lmvLegendLabel.exteriorOpenings')],
  [CategoryTypeValues.roofs, i18n.t('analysis.ec.lmvLegendLabel.Roof')],
  [CategoryTypeValues.undergroundSlabs, i18n.t('analysis.ec.lmvLegendLabel.UndergroundSlab')],
  [CategoryTypeValues.slabsOnGrade, i18n.t('analysis.ec.lmvLegendLabel.SlabOnGrade')],
  [CategoryTypeValues.raisedFloors, i18n.t('analysis.ec.lmvLegendLabel.RaisedFloor')],
  [CategoryTypeValues.undergroundWalls, i18n.t('analysis.ec.lmvLegendLabel.UndergroundWall')],
  [CategoryTypeValues.interiorWalls, i18n.t('analysis.ec.lmvLegendLabel.InteriorWall')],
  [CategoryTypeValues.interiorOpenings, i18n.t('analysis.ec.lmvLegendLabel.interiorOpenings')],
  [CategoryTypeValues.interiorFloors, i18n.t('analysis.ec.lmvLegendLabel.InteriorFloor')],
  [CategoryTypeValues.ceilings, i18n.t('analysis.ec.lmvLegendLabel.Ceiling')],
  [CategoryTypeValues.undergroundCeilings, i18n.t('analysis.ec.lmvLegendLabel.UndergroundCeiling')],
  [CategoryTypeValues.shades, i18n.t('analysis.ec.lmvLegendLabel.Shade')],
  [CategoryTypeValues.other, i18n.t('analysis.ec.lmvLegendLabel.others')],
]);

export const getLabelForSurfaceCategory = (category: string): string => {
  if (surfaceCategoryToLabelMap.has(category)) {
    return surfaceCategoryToLabelMap.get(category);
  }
  return i18n.t('analysis.ec.lmvLegendLabel.others');
};
const deleteBreakdownDefItems = <T extends EcBreakdownItem>(
  breakdownElements: T[],
  ecDefinitionId: string
): T[] => {
  return breakdownElements.reduce((acc, item) => {
    const mats = item.materials.map((mat) => {
      if (mat.ecDefinitionAssignment?.ecDefinition?.id === ecDefinitionId) {
        return { ...mat, ecDefinitionAssignment: null };
      }
      return { ...mat };
    });

    acc.push({
      ...item,
      ecDefinitionAssignment:
        item.ecDefinitionAssignment?.ecDefinition?.id === ecDefinitionId
          ? null
          : item.ecDefinitionAssignment,
      materials: mats,
    });
    return acc;
  }, []);
};

export const deleteECDefinitionAssignments = (
  modelEcBreakdown: ModelEmbodiedCarbonBreakdownEntity,
  ecDefinitionId: string
): ModelEmbodiedCarbonBreakdownEntity => {
  let newConstr: BreakdownConstructionItems = {};
  Object.keys(modelEcBreakdown.constructionItems).forEach((key) => {
    newConstr[key] = deleteBreakdownDefItems<ECBreakdownConstruction>(
      modelEcBreakdown.constructionItems[key],
      ecDefinitionId
    );
  });

  let newOpen: BreakdownOpeningItems = {};
  Object.keys(modelEcBreakdown.openingItems).forEach((key) => {
    newOpen[key] = deleteBreakdownDefItems<ECBreakdownOpening>(
      modelEcBreakdown.openingItems[key],
      ecDefinitionId
    );
  });

  return {
    ...modelEcBreakdown,
    constructionItems: newConstr,
    openingItems: newOpen,
  };
};

export const isExteriorWall = (elementType: string): boolean => {
  return elementType === SurfaceType.ExteriorWall;
};

export const isAnalyzedOpening = (elementType: string): boolean => {
  return (
    elementType === OpeningType.OperableWindow ||
    elementType === OpeningType.FixedWindow ||
    elementType === OpeningType.SlidingDoor ||
    elementType === OpeningType.NonSlidingDoor ||
    elementType === OpeningType.FixedSkylight ||
    elementType === OpeningType.OperableSkylight
  );
};

export const convertECDefinition = (ecDefinition: EcDefinition, useSI: boolean): EcDefinition => {
  if (useSI) {
    return ecDefinition;
  }

  let convertedCoefficient = 0;
  let convertedUnit = '';

  if (ecDefinition && ecDefinition.unit) {
    switch (ecDefinition.unit) {
      case 'm': {
        convertedCoefficient = convertECDefinitionsmtoft(+ecDefinition.average, true);
        convertedUnit = i18n.t(locConsts.IMP_EC_RAW_UNITS_FEET);
        break;
      }
      case 'm2': {
        convertedCoefficient = convertECDefinitionsm2toft2(+ecDefinition.average, true);
        convertedUnit = i18n.t(locConsts.IMP_EC_RAW_UNITS_SQFEET);
        break;
      }
      case 'm3': {
        convertedCoefficient = convertECDefinitionsm3toft3(+ecDefinition.average, true);
        convertedUnit = i18n.t(locConsts.IMP_EC_RAW_UNITS_CUBEFT);
        break;
      }
      case 'kg': {
        convertedCoefficient = convertECDefinitionsKgtoPounds(+ecDefinition.average, true);
        convertedUnit = i18n.t(locConsts.IMP_EC_RAW_UNITS_POUNDS);
        break;
      }
      case 'm2 RSI': {
        convertedCoefficient = convertECDefinitionsRSItoR(+ecDefinition.average, true);
        convertedUnit = i18n.t(locConsts.IMP_EC_RAW_UNITS_SQFTR);
        break;
      }
      case 'item': {
        convertedCoefficient = +ecDefinition.average;
        convertedUnit = i18n.t('analysis.ec.ecDefinition.units.itemBE');
        break;
      }
      default: {
        convertedCoefficient = +ecDefinition.average;
        convertedUnit = ecDefinition.unit;
      }
    }
  }
  return {
    ...ecDefinition,
    unit: convertedUnit,
    average: convertedCoefficient,
  };
};

export const moveFirstSelectedEcDefinition = (
  ecDefinitions: EcDefinition[],
  selectedEcDefinitionId: string
) => {
  if (!selectedEcDefinitionId) {
    return ecDefinitions;
  }
  const selectedECDefIndex = ecDefinitions.findIndex(
    (definition: EcDefinition) => definition.id === selectedEcDefinitionId
  );
  if (selectedECDefIndex === -1) {
    return ecDefinitions;
  }
  const filteredECDefinitions = [...ecDefinitions];
  filteredECDefinitions.unshift(filteredECDefinitions.splice(selectedECDefIndex, 1)[0]);
  return filteredECDefinitions;
};

export const ecDefinitionsSelector = (
  material: modelType.MaterialRow,
  ecDefinitions: Map<string, EcDefinition[]>
): EcDefinition[] => {
  
  const availableUnits: string[] = ['none'];
  if (material.area) {
    availableUnits.push(M2);
  }
  if (material.volume) {
    availableUnits.push(M3);
  }
  if (material.density && material.volume) {
    availableUnits.push(KG);
  }
  if (material.rValue) {
    availableUnits.push(M2RSI);
  }
  if (material.ecItemCount && material.ecItemCount !== i18n.t(`analysis.constant.nullValue`)) {
    availableUnits.push(ITEM);
  }
  const ecDefinitionsResult = Object.keys(ecDefinitions).reduce(
    (acc: EcDefinition[], key: string) => {
      if (!availableUnits.includes(key)) {
        return acc;
      }
      acc = acc.concat(ecDefinitions[key]);
      return acc;
    },
    []
  );
  return ecDefinitionsResult.sort((a: EcDefinition, b: EcDefinition) =>
    a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1
  );
};

export const flattenMap = <T, U>(map: Map<T, U[]>): U[] => {
  return Array.from(map.values()).flatMap((c) => c);
};

export const flattenConstructions = (
  modelEcBreakdown: ModelEmbodiedCarbonBreakdownEntity
): EcBreakdownItem[] => {
  let retValue = [];
  if (
    !modelEcBreakdown ||
    !modelEcBreakdown.constructionItems ||
    Object.keys(modelEcBreakdown.constructionItems).length < 1
  )
    return retValue;

  retValue = Object.values(modelEcBreakdown.constructionItems).flat();
  if (modelEcBreakdown.openingItems) {
    retValue = retValue.concat(Object.values(modelEcBreakdown.openingItems).flat());
  }
  return retValue;
};

export const flattenEcDefinitions = (ecDefinitions: Map<string, EcDefinition[]>): EcDefinition[] =>
  Object.keys(ecDefinitions)
    .reduce((acc: EcDefinition[], key: string) => {
      acc = acc.concat(ecDefinitions[key]);
      return acc;
    }, [])
    .sort((a: EcDefinition, b: EcDefinition) =>
      a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1
    );

//Calculate the sum of all values in given array for given key, e.g. sumAll('area', foo) where foo is [] and area is a member of foo
export const sumAll = (key: string, array: any[]): number =>
  array.reduce(
    (prev, curr) => (curr[key] !== null ? (prev || 0) + parseFloat(curr[key]) : prev),
    null
  );
