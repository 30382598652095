import React, { useEffect, useState } from 'react';
import LmvEcDetails, { getViewerInstance, isolateViewerElements } from './LmvEcDetails';
import * as modelType from '../../dashboard.models';
import i18n from '../../../i18n';
import {
  LmvLegendViewByOptions,
  LmvLegendItems,
  LmvLegendData,
  LabelVariant,
  DropDownVariant,
  ECDetailsPreference,
  FilteredConstructions,
  ViewerElement,
  CategoryTypeValues,
} from '../types';
import ResizeListener from '../../../shared/ResizeListener/ResizeListener';
import isequal from 'lodash.isequal';
import {
  OtherCategoryLmvElementColor,
  getLabelForSurfaceCategory,
  isAnalyzedOpening,
  flattenConstructions,
} from '../utils';
import { OPENING_CONSTRUCTION_ID_PREFIX, UNKNOWN_CONSTRUCTION_ID } from '../../../consts';
import { getCategoryBySurfaceType } from '../Widgets/Charts/utils';
import { LmvLegendContainer } from './LmvLegendContainer';

export type LmvSectionProps = {
  lmvStatus: string;
  lmvUrn: string;
  setLmvElements: (lmvLegendData: LmvLegendData) => void;
  validatePreference: (modelId: string) => ECDetailsPreference;
  viewByPreference: (modelId: string, viewBy: LmvLegendViewByOptions) => void;
  filteredConstructions: FilteredConstructions[];
  modelECBreakdown: modelType.ModelEmbodiedCarbonBreakdownEntity;
  selectedConstructionId: string;
  constructionColorData: Map<string, string>;
  lmvLegendExpandedPreference: (modelId: string, legendCollapseState: boolean) => void;
  lmvLegendconstructionSelection: (constructionId: string) => void;
  categoryColorData: Map<string, string>;
};
export const getCategoryForConstructionSurfaceType = (
  surfaceType: string,
  surfaceId: string
): string => {
  let category = getCategoryBySurfaceType(surfaceType, surfaceId);
  if (!category) {
    category = CategoryTypeValues.other;
  }
  return category;
};

const loadLmvLegendData = (
  filteredConstructions: FilteredConstructions[],
  modelECBreakdown: modelType.ModelEmbodiedCarbonBreakdownEntity,
  constructionColorData: Map<string, string>,
  categoryColorData: Map<string, string>
): LmvLegendData => {
  let lmvLegendData: LmvLegendData = { constructions: [], detailLevels: [], categories: [] };
  lmvLegendData.constructions = filteredConstructions.map(
    (construction: FilteredConstructions, index: number): LmvLegendItems => {
      const isOpening = isAnalyzedOpening(construction.surfaceType);
      const viewerElement: ViewerElement = {
        id: construction.constructionId,
        surfaceIds: construction.surfaceIds.map((sId) => (isOpening ? 'Opening ' + sId : sId)),
      };

      return {
        id: construction.constructionId,
        name: construction.constructionName,
        color: constructionColorData.get(construction.constructionName),
        viewerElements: [viewerElement],
        surfaceType: construction.surfaceType,
      };
    }
  );
  let constructionsCategories = Object.keys(modelECBreakdown.constructionItems).reduce(
    (acc, c, idx) => {
      const constructions = modelECBreakdown.constructionItems[c];
      constructions.forEach((con) => {
        const category = getCategoryBySurfaceType(con.surfaceType, con.constructionId);
        if (category === null){
          return;
        }
        const isOpening = isAnalyzedOpening(con.surfaceType);
        const viewerElement: ViewerElement = {
          id: con.constructionId,
          surfaceIds: con.surfaceIds.map((sId) => (isOpening ? 'Opening ' + sId : sId)),
        };
        if (acc.has(category)) {
          const catElement = acc.get(category);
          catElement.viewerElements = [...catElement.viewerElements, ...[viewerElement]];
        } else {
          acc.set(category, {
            id: c,
            name:
              i18n.t(`surfaceTypes.name.${c}`) ?? i18n.t(`surfaceTypes.name.DefaultSurfaceName`),
            color: categoryColorData.get(category),
            viewerElements: [viewerElement],
          });
        }
      });
      return acc;
    },
    new Map<string, LmvLegendItems>()
  );

  let openingsCategories = lmvLegendData.constructions
    .filter((c) => isAnalyzedOpening(c.surfaceType))
    .reduce((acc, c, idx) => {
      let category = getCategoryForConstructionSurfaceType(c.surfaceType, c.id);
      if (acc.has(category)) {
        const catElement = acc.get(category);
        catElement.viewerElements = [...catElement.viewerElements, ...c.viewerElements];
      } else {
        acc.set(category, {
          id: category,
          name: getLabelForSurfaceCategory(category),
          color: categoryColorData.get(category),
          viewerElements: [...c.viewerElements],
        });
      }
      return acc;
    }, new Map<string, LmvLegendItems>());

  lmvLegendData.categories = Array.from(
    new Map([...constructionsCategories.entries(), ...openingsCategories.entries()].sort()).values()
  );

  const detailsLevel = Array.from(new Set(filteredConstructions.map((item) => item.detailLevel)));
  //The unknown option is only included if any construction contains the option.
  const levelsNumber: number = detailsLevel.includes(3) ? 3 : 2;
  //Note: construction color data contains the first two values generated from color algo as schematic and detailed colors
  //We are using these colors here
  const colorIterator = constructionColorData?.values();
  let detailLevelColors: string[] = [];
  detailLevelColors.push(colorIterator?.next().value);
  detailLevelColors.push(colorIterator?.next().value);

  for (let i = 1; i < levelsNumber + 1; i++) {
    const name = i18n.t(`analysis.ec.detailLevel.${i}`);
    const constructions = filteredConstructions.filter((constr) => constr.detailLevel === i);
    const item: LmvLegendItems = {
      name,
      id: i.toString(),
      color: i === 3 ? '#E0E0E1' : detailLevelColors[i - 1],
      viewerElements: [],
    };

    constructions.forEach((c) => {
      item.viewerElements.push({ id: c.constructionId, surfaceIds: c.surfaceIds });
    });

    lmvLegendData.detailLevels.push(item);
  }
  return lmvLegendData;
};

const updateLmvLegendData = (
  selectedConstructionId: string,
  lmvLegendData: LmvLegendData,
  filteredConstructions: FilteredConstructions[],
  modelECBreakdown: modelType.ModelEmbodiedCarbonBreakdownEntity,
): LmvLegendData => {
  const greyColor = i18n.t(`analysis.ec.defaultColor`);
  const isUnknownConstructionId = selectedConstructionId.startsWith(UNKNOWN_CONSTRUCTION_ID);
  const selectedConstructionName = filteredConstructions.filter(
    (x) => x.constructionId === selectedConstructionId
  )[0]?.constructionName;
  const isOpening = selectedConstructionId.startsWith(OPENING_CONSTRUCTION_ID_PREFIX);

  lmvLegendData.constructions = lmvLegendData.constructions.map(
    (item: LmvLegendItems): LmvLegendItems => {
      let disableItemElements = false;
      if (!isUnknownConstructionId) {
        if (selectedConstructionId !== item.id) {
          disableItemElements = true;
        }
      } else {
        if (selectedConstructionName !== item.name) {
          disableItemElements = true;
        }
      }
      if (disableItemElements) {
        item.color = greyColor;
        item.viewerElements.forEach((e) => (e.isHidden = true));
      }

      return item;
    }
  );

  const allConstructions = flattenConstructions(modelECBreakdown);
  const constrSurfaces = isOpening
    ? lmvLegendData.categories.filter((cat) =>
        cat.viewerElements.some((x) => x.id === selectedConstructionId)
      )
    : allConstructions.filter((s) => s.constructionId === selectedConstructionId);

  lmvLegendData.categories = lmvLegendData.categories.map(
    (item: LmvLegendItems): LmvLegendItems => {
      if (!constrSurfaces.some((x) => x.surfaceType === item.id || x.id === item.id)) {
        item.color = greyColor;
        item.viewerElements.forEach((e) => (e.isHidden = true));
      } else {
        item.viewerElements.forEach((e) => {
          if (selectedConstructionId !== e.id) e.isHidden = true;
        });
      }
      return item;
    }
  );
  
  const detailLevel = isUnknownConstructionId
    ? filteredConstructions.filter((x) => x.constructionName === selectedConstructionName)[0]
        ?.detailLevel
    : filteredConstructions.filter((x) => x.constructionId === selectedConstructionId)[0]
        ?.detailLevel;

  lmvLegendData.detailLevels = lmvLegendData.detailLevels.map(
    (item: LmvLegendItems): LmvLegendItems => {
      if (detailLevel?.toString() !== item.id) {
        item.color = greyColor;
        item.viewerElements.forEach((e) => (e.isHidden = true));
      } else {
        item.viewerElements.forEach((e) => {
          if (selectedConstructionId !== e.id) e.isHidden = true;
        });
      }
      return item;
    }
  );
  return lmvLegendData;
};

const LmvSection = (props: LmvSectionProps): JSX.Element => {
  const {
    lmvUrn,
    lmvStatus,
    setLmvElements,
    validatePreference,
    viewByPreference,
    filteredConstructions,
    modelECBreakdown,
    constructionColorData,
    selectedConstructionId,
    lmvLegendExpandedPreference,
    lmvLegendconstructionSelection,
    categoryColorData,
  } = props;
  const lmvIsComplete = props.lmvStatus === 'Completed';
  const [legendData, setLegendData] = useState<LmvLegendData>(null);
  const [ecPreference, setEcPreference] = useState<ECDetailsPreference>(null);
  const onCollapsedClick = () => {
    lmvLegendExpandedPreference(
      modelECBreakdown?.modelId,
      !ecPreference.legendCollapseState.lmvLegendState
    );
    const ecDetailsPreference = validatePreference(modelECBreakdown.modelId);
    setEcPreference(ecDetailsPreference);
  };

  const onLegendConstructionIdClick = (constructionId: string) => {
    lmvLegendconstructionSelection(constructionId);
  };

  useEffect(() => {
    if (modelECBreakdown?.modelId) {
      let lmvLegendData = loadLmvLegendData(
        filteredConstructions,
        modelECBreakdown,
        constructionColorData,
        categoryColorData
      );
      const ecDetailsPreference = validatePreference(modelECBreakdown?.modelId);

      if (ecDetailsPreference.selectedConstructionId && filteredConstructions.length > 0) {
        lmvLegendData = updateLmvLegendData(
          ecDetailsPreference.selectedConstructionId,
          lmvLegendData,
          filteredConstructions,
          modelECBreakdown
        );
      }
      setEcPreference(ecDetailsPreference);

      if (!isequal(legendData, lmvLegendData)) {
        setLegendData(lmvLegendData);
        setLmvElements(lmvLegendData);
      }
    }
  }, [filteredConstructions, selectedConstructionId]);

  useEffect(() => {
    if (modelECBreakdown?.modelId) {
      const ecDetailsPreference = validatePreference(modelECBreakdown?.modelId);
      let viewer = getViewerInstance();
      if (viewer?.progressbar.lastValue === 100) {
        isolateViewerElements(viewer, ecDetailsPreference.viewBy);
      }
    }
  }, [selectedConstructionId]);

  if (ecPreference && legendData) {
    return (
      <>
        <ResizeListener
          className={
            ecPreference.legendCollapseState.lmvLegendState
              ? 'ecdetails-widget-wrapper'
              : 'ecdetails-widget-wrapper-expanded'
          }
          onResize={() => {
            const viewer = getViewerInstance();
            viewer?.resize();
          }}
        >
          <LmvEcDetails
            urn={lmvUrn}
            lmvStatus={lmvStatus}
            modelId={modelECBreakdown?.modelId}
            validatePreference={validatePreference}
          />
        </ResizeListener>
        <div
          className={
            ecPreference.legendCollapseState.lmvLegendState
              ? 'ecdetails-content-lmv-legend'
              : 'ecdetails-content-lmv-legend-collapsed'
          }
        >
          <LmvLegendContainer
            label={{
              text: i18n.t('analysis.ec.lmvLegendDropdown.text'),
              variant: LabelVariant.Side,
            }}
            defaultValue={ecPreference.viewBy}
            lmvLegendData={legendData}
            variant={DropDownVariant.Line}
            modelId={modelECBreakdown?.modelId}
            viewByPreference={viewByPreference}
            setLmvElements={setLmvElements}
            lmvStatus={lmvIsComplete}
            onLegendCollapseClick={onCollapsedClick}
            isExpanded={ecPreference.legendCollapseState.lmvLegendState}
            selectedConstructionId={selectedConstructionId}
            onLmvLegendConstructionClick={onLegendConstructionIdClick}
          />
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default LmvSection;
