import * as api from './api';
import * as fromModelApi from '../model/api';
import { apiActionCreator } from '../shared';
import * as ProjectType from './ProjectTypes';
import { invalidFileError } from '../shared/actions';

export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPLOAD_MODEL_SUCCESS = 'UPLOAD_MODEL_SUCCESS';
export const UPDATED_PROJECT_MODELS_ON_DASHBOARD = 'UPDATED_PROJECT_MODELS_ON_DASHBOARD';
export const UPDATED_PROJECT_MODELS_ON_EC_PAGE = 'UPDATED_PROJECT_MODELS_ON_EC_PAGE';

export const updateProjectSuccess = (project: ProjectType.ProjectModel) => {
    return {
        type: UPDATE_PROJECT_SUCCESS,
        project
    };
}

export const loadProject = (id: string) => (dispatch: Function, getState: any) => {
    if (loadProjectDataNeeded(getState(), id)) {
        return dispatch(forceLoadProjectData(id, getState()));
    }
}

export const uploadModelSuccess = (dashboardPath: any, projectId: string) => {
  return {
    type: UPLOAD_MODEL_SUCCESS,
    dashboardPath,
    projectId
  };
}
function loadProjectDataNeeded(state: any, id: string) {
    if (state.projectState.isFetching)
        return false;
    if (state.projectState.projects[id])
        return false;

    return true;
}

export const getModelProject = (modelId: string) => {
    return apiActionCreator({
        api: fromModelApi.getModel
    }, modelId);
}

export function forceLoadProjectData(projectId: string, state?: any) {
    return apiActionCreator({
        api: api.getProject,
        success: updateProjectSuccess
    }, projectId);
}

export function throwInvalidFileError(error: string) {
    return invalidFileError(error);
}

export function updateProjectSettings(projectId: string, useSiUnits: boolean, currencyIso: string, fuelRate: number, electricityRate: number, electricityCeCoefficient: number, fuelCeCoefficient: number) {
    return apiActionCreator({
        api: api.updateProjectSettings,
      success: updateProjectSuccess
    }, projectId, useSiUnits, currencyIso, fuelRate, electricityRate, electricityCeCoefficient, fuelCeCoefficient);
}

