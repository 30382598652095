import React, { Component } from 'react';
import AboutHtml from './about-box.html';
import '../css/infoDialog.css';
export class About extends Component {

    private getHtml = (): { __html: string } => {
        return {
          __html: AboutHtml
        };
    }

    render() {
        return  <div className="info-dialog" dangerouslySetInnerHTML={this.getHtml()} />;
    }
}
