import React, { Component } from 'react';
import FactorChart from '../charts/FactorChart';
import Loader from './Loader';
import ProgressRing from '@hig/progress-ring';
import '../css/dashboard.css';
import i18n from '../i18n';
import * as locConsts from "./localization/consts";
import { Dashboard } from './dashboard.models';

export type OptimizationProps = {
    dashboard: Dashboard,
    onRangeChange: any,
    onShowSimulationTab?: any,
    showNotificationModal: any
}

type PrevState = {
    simulationsFailed: boolean
}

type Points = {
    impact: string,
    inRange: boolean,
    key: string,
    tooltipDescription: string,
    tooltipValue: string,
    x: string,
    y: number
}


export default class Optimization extends Component<OptimizationProps, any> {

    constructor(props: OptimizationProps) {
        super(props);
        this.state = {
            simulationsFailed: false
        };                
    }

    componentDidUpdate(prevProps:OptimizationProps, prevState: PrevState) {
        if (prevState.simulationsFailed !== this.state.simulationsFailed && this.state.simulationsFailed) {
            this.props.showNotificationModal(
                i18n.t(locConsts.OPTIMIZATION_TAB_RUNS_FAILED),
                i18n.t(locConsts.OPTIMIZATION_TAB_RUNS_FAILED_DESC),
                "");
        }
    }

    onRangeChange = (widgetKey: string, left: string, right: string) => {
        this.props.onRangeChange(this.props.dashboard.modelId, widgetKey, left, right);
    }

    renderFactorCharts = () => {

        const chartLimits = {
            limitMinY: this.props.dashboard.limitMinY,
            limitMaxY: this.props.dashboard.limitMaxY
        };

        const { modelId, selectedPointGroup, widgets, useSI, fuelRate, elecRate, currencyIso } = this.props.dashboard;
        return widgets.map((widget: any) => {

            const reactKey: string = `${modelId}-${widget.key}-${selectedPointGroup}-${useSI}-${fuelRate}-${elecRate}-${currencyIso}`;
            const isContinuousChart: boolean = (widget.type === "continuous");
            const points: Points[] = widget.pointGroups[selectedPointGroup];

            if (widget.status === "Loaded") {

                if (widget.hasErrors && !this.state.simulationsFailed) {
                    this.setState({ simulationsFailed: true });
                }

                return <FactorChart
                    key={reactKey}
                    factorTitle={widget.title}
                    xTitle={widget.xTitle}
                    yTitle={widget.yTitle}
                    data={points}
                    useRangeHandles={widget.rangeEnabled}
                    onRangeChange={this.onRangeChange}
                    barChartIsVisible={false}
                    chartLimits={chartLimits}
                    factorKey={widget.key}
                    isContinuousChart={isContinuousChart}
                    rangeLeft={widget.rangeLeft}
                    rangeRight={widget.rangeRight}
                />;
            }
            return <div key={reactKey} className="factor-chart-s factor-chart-m factor-chart-lg">
                <div className="chart-widget">
                    <div className="chart-widget-title-container">
                        <span className="chart-widget-title">{widget.title}</span>
                    </div>
                    <div className="chart-widget-chart-container-loader">
                        <ProgressRing size="l" />
                    </div>
                </div>
            </div>;
        }, this);
    }

    render() {

        return (
            <React.Fragment>
                <Loader
                    isLoading={this.props.dashboard.baseRunStatus === 'Processing'}
                    size="xl"
                    title={i18n.t(locConsts.OPTIMIZATION_TAB_BASE_PROCESSING)}
                    subtitle={i18n.t(locConsts.OPTIMIZATION_TAB_BASE_PROCESSING_DESC)}
                >
                    <Loader
                        isLoading={this.props.dashboard.baseRunStatus === 'Completed' && this.props.dashboard.widgets.length === 0}
                        showAnimation={false}
                        title={i18n.t(locConsts.OPTIMIZATION_TAB_BASE_COMPLETED)}
                        subtitle={i18n.t(locConsts.OPTIMIZATION_TAB_BASE_COMPLETED_DESC)}
                    >
                        <div className="row analysis-optimization-tab-content">
                            {this.renderFactorCharts()}
                        </div>
                    </Loader>
                </Loader>
            </React.Fragment>
        );
    }
}

