import React, { useEffect, useState } from 'react';
import BannerContext from "../../shared/BannerContext/BannerContext";
import AlertBanner, { BannersMap } from '../../shared/BannerContext/BannerComponent';
import { alertSeverities } from '@weave-mui/alert';
import i18n from '../../i18n';
import { useGetUserFeatureFlagDataQuery } from '../../state/api/feature-flag-data-api';
import moment from 'moment';

export type GenericBannerProps = {
  link?: string;
  text: string;
};

const dismissedBannerLSKey: string = 'dismissedBannerLSKey';

const GenericBannerContext = ({minutesToDismiss}) => {
  const [shouldDisplayBanner, setShouldDisplayBanner] = useState<boolean>(false);
  const [banners, setBanners] = useState<BannersMap>(new Map());
  const { data: featureFlagData } = useGetUserFeatureFlagDataQuery();
  const message = featureFlagData?.['general-banner'] as GenericBannerProps;
  const dismissedBannerLS = localStorage.getItem(dismissedBannerLSKey);

  useEffect(() => {
    const shouldDisplayBanner = message && message.text && message.text.length > 1;

    // local storage default val == null 
    if (!dismissedBannerLS) {
      setShouldDisplayBanner(shouldDisplayBanner);
      return;
    }
    
    const bannerLastDismissedTimestamp = moment(dismissedBannerLS);
    const duration = moment.duration(moment().diff(bannerLastDismissedTimestamp));
    const minutesSinceDismissal = duration.asMinutes();

    if (isNaN(minutesSinceDismissal)) {
      setShouldDisplayBanner(shouldDisplayBanner);
    } else {
      setShouldDisplayBanner(shouldDisplayBanner && minutesSinceDismissal >= minutesToDismiss);
    }

  }, [message, dismissedBannerLS, minutesToDismiss]);

  useEffect(() => {
    const key = 'Alert';
    if (shouldDisplayBanner) {
      const value = (
        <AlertBanner
          severity={alertSeverities.INFO}
          bannerText={message.text}
          buttonText={i18n.t('applicationUnderMaintenance.banner.learnMoreButtonText')}
          buttonUrl={message.link}
          onCloseBanner={onBannerDismiss}
        />
      );
      setBanners((prevBanners) => new Map([...prevBanners, [key, value]]));
    } else {
      setBanners((prevBanners) => {
        const newBanners = new Map(prevBanners);
        newBanners.delete(key);
        return newBanners;
      });
    }
  }, [message, shouldDisplayBanner]);

  const onBannerDismiss = () => {
    const key = 'Alert';
    localStorage.setItem(dismissedBannerLSKey, moment().toString());
    setBanners((prevBanners) => {
      const newBanners = new Map(prevBanners);
      newBanners.delete(key);
      return newBanners;
    });
  }

  return (
    <BannerContext bannersArray={Array.from(banners.values())} />
  );
};

export default GenericBannerContext;
