import Modal, { ModalProps } from '@weave-mui/modal';
import { FC, useEffect, useState, JSX, createElement } from 'react';
import Box, { BoxProps } from '@weave-mui/box';
import React from 'react';
import Tooltip, { tooltipPlacement } from '@weave-mui/tooltip';
import Button, { ButtonProps, buttonVariants } from '@weave-mui/button';

export type UserConsentModalProps = ModalProps & {
  onClose?: () => void
  onApplyAction?: () => void;
  cancelTitle?: string;
  applyActionTitle?: string;
  content?: string;
  customActions?: JSX.Element;
  customContent?: JSX.Element;
  disableBackdropClickClose?: boolean;
};

export const UserConsentModal: FC<UserConsentModalProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const modalProps = {
    ...props,
  };

  const buttonBox = (
    <Box
      display="flex"
      sx={{
        justifyContent: 'space-between',
        gap: 3
      }}
    >
      <Button onClick={props.onClose}
        variant={buttonVariants.OUTLINED}
        sx={{ marginLeft: 'auto !important' }}
      >
        {props.cancelTitle}
      </Button>
      <Button onClick={props.onApplyAction}>
        {props.applyActionTitle}
      </Button>
    </Box>
  );

  delete modalProps['onClose'];
  delete modalProps['onApplyAction'];
  delete modalProps['cancelTitle'];
  delete modalProps['applyActionTitle'];
  delete modalProps['content'];
  delete modalProps['customActions'];
  delete modalProps['customContent'];
  delete modalProps['disableBackdropClickClose'];


  const content = props.customContent ?? (
    <CustomBox dangerouslySetInnerHTML={{ __html: props.content }}></CustomBox>
  );

  return (
    <Modal
      {...modalProps}
      open={open}
      onClose={(e, reason) => {
        if (props.disableBackdropClickClose && reason === 'backdropClick') return;
        props.onClose?.();
      }}
      actions={props.customActions ?? buttonBox}
      sx={{
        '& .MuiModalWeave-box': {
          width: '480px',
          minWidth: '300px',
          maxHeight: '100%',
        },
      }}
    >
      {content}
    </Modal>
  );
};

export type CustomBoxProps = BoxProps;

export const CustomBox: React.FC<CustomBoxProps> = (props) => {
  return <Box {...props} />;
};

export type CustomButtonProps = ButtonProps & {
  text?: string;
  icon?: any;
  ariaLabel?: string;
  buttonClass?: string;
  tooltip?: string;
  tooltipPlacement?: (typeof tooltipPlacement)[keyof typeof tooltipPlacement];
  tooltipDescription?: string;
  id?: string;
  endIcon?: any;
};

export const CustomButton: React.FC<CustomButtonProps> = (props) => {
  const icon = props.icon && createElement(props.icon);

  const buttonProps = {
    ...props,
    endIcon: props.endIcon && createElement(props.endIcon),
  };

  if (!buttonProps.children) {
    if (props.text && icon) {
      buttonProps.children = (
        <>
          {icon}
          <Box component="span" sx={{ marginLeft: '8px' }}>
            {props.text}
          </Box>
        </>
      );
    } else {
      buttonProps.children = props.text || icon;
    }
  }

  const tooltipDescription = props.tooltipDescription ? (
    <Box component="span" sx={{ fontSize: '11px', fontWeight: 400, lineHeight: '14px' }}>
      {props.tooltipDescription}
    </Box>
  ) : null;

  delete buttonProps['ariaLabel'];
  delete buttonProps['buttonClass'];
  delete buttonProps['tooltipPlacement'];
  delete buttonProps['tooltipDescription'];

  const button = (
    <Button
      {...buttonProps}
      aria-label={props.ariaLabel}
      id={props.id}
      onClick={(event) => {
        props.onClick?.(event);
      }}
      className={props.buttonClass}
    />
  );

  return (
    <>
      {props.tooltip ? (
        <Tooltip
          title={props.tooltip}
          arrow
          size="small"
          placement={props.tooltipPlacement}
          description={tooltipDescription}
        >
          <Box component="span">{button}</Box>
        </Tooltip>
      ) : (
        button
      )}
    </>
  );
};
