import i18n from '../i18n';

export const constructioDataGridStyles = {
  height: '36px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
  padding: '2px 0',
  textTransform: 'initial',
  width: '100%',
  span: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
};

export const constructionTableStyleSheet = (styles, props, themeData, themMeta) => {
    const { cellColumnIndex, isLast, depth, columnHeaderArray, role, multiSelectedRow } = props;
    let headerTitle = null;
    role == 'cell' && (headerTitle = columnHeaderArray[cellColumnIndex]?.props?.title);
    return {
        ...styles,
        higTableCell: {
            ...styles.higTableCell,
            display: 'flex',
            ...(isLast ? {
                background: themeData["table.header.backgroundColor"],
                fontWeight: "bold"
            } : {}),
            ...(depth === 1 && !multiSelectedRow ? {
                background: themeData["colorScheme.surface.level200"],
            } : {}),
            padding: '2px 8px'
        },
        higTableCellContentWrapper: {
            ...styles.higTableCellContentWrapper,
            overflow: ((headerTitle === i18n.t("analysis.ec.construction.name") || headerTitle === i18n.t("analysis.ec.construction.definition")) ? 'visible' : styles.higTableCellContentWrapper.overflow),
            width: '100%',
            ...(headerTitle === i18n.t("analysis.ec.construction.name") && !isLast ? {
                display: 'flex',
                alignContent: 'flex-start',
                alignItems: 'center'
            } : {})
        },
        headerHolder: {
            ...styles.headerHolder,
        },
        higTable: {
            ...styles.higTable,
            maxHeight: '100%',
            display: 'block',
            overflowX: 'hidden'
        },
        higTableHeaderContentWrapper: {
            ...styles.higTableHeaderContentWrapper,
            height: 'wrap-content',
            padding: '2px 8px',
            background: themeData["table.header.backgroundColor"],
            justifyContent: 'space-between'
        }
    };
}

const defaultTableStyle = (props, styles, themeData) => {
    const { rowIndex, alternateBg, columnSelection, hasHover, isPressed, cellColumnIndex } = props;
    const rowHeight = '28px';
    const alternateBgColor =
        rowIndex % 2 === 0
            ? themeData["table.row.backgroundColor"]
            : themeData["table.zebraStripe.backgroundColor"];
    return {
        ...styles,
        inputWrapper: {
            ...styles.inputWrapper,
        },
        caret: {
            ...styles.caret,
            cursor: "default",
        },
        higTable: {
            ...styles.higTable,
            width: '100%'
        },
        higTableRow: {
            ...styles.higTableRow,
            ...(alternateBg
                ? {
                    background: alternateBgColor,
                }
                : {
                    background: themeData["table.row.backgroundColor"],
                }),
            "&:hover": {
                backgroundColor: themeData["table.row.unselected.hover.backgroundColor"],
            }
        },
        higTableHeaderWrapper: {
            ...styles.higTableHeaderWrapper,
            height: rowHeight
        },
        higTableHeaderContentWrapper: {
            ...styles.higTableHeaderContentWrapper,
            height: rowHeight,
            ...(columnSelection && hasHover
                ? {
                    backgroundColor:
                        themeData["table.header.unselected.hover.backgroundColor"],
                }
                : {}),
            ...(columnSelection && isPressed
                ? {
                    backgroundColor:
                        themeData["table.header.unselected.pressed.backgroundColor"],
                }
                : {})
        },
        higTableHeaderRow: {
            ...styles.higTableHeaderRow,
            height: rowHeight,
            "&:not(:last-child)": {
                pointerEvents: `none`,
            }
        },
        higTableCellContentWrapper: {
            ...styles.higTableCellContentWrapper,
            ...(cellColumnIndex !== 5 && {
                whiteSpace: 'normal',
                '-webkit-line-clamp': '1',
                'text-overflow': 'ellipsis',
                overflow: 'hidden',
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                'word-wrap': 'break-word'
            }),
            ...(cellColumnIndex === 5 && {
                width: `100%`
            })
        },
        headerHolder: {
            ...styles.headerHolder,
            fontWeight: 'bold'
        },
        higTableCell: {
            ...styles.higTableCell,
            height: rowHeight,
            cursor: `pointer`,
            "&:not(:last-child)": {
                'border-right': 'none'
            },
            "&:hover": {
                backgroundColor: themeData["table.row.unselected.hover.backgroundColor"]
            }
        }
    }
}

export const dynamicTableStyle = (highlightRowIndex: number) => {
    return (styles, props, themeData) => {
        if (!themeData) { return {}; }
        const { rowIndex } = props;

        const defaultStyle = defaultTableStyle(props, styles, themeData);
        return {
            ...defaultStyle,
            higTableRow: {
                ...(rowIndex === highlightRowIndex
                    ? {
                        ...styles.higTableRow,
                        backgroundColor: 'rgba(205,234,247,0.6)',
                        border: '1px solid #0696d7'
                    }
                    : { ...styles.higTableRow }
                )
            },
            higTable: {
                ...styles.higTable,
                height: "100%",
                width: "100%"
            },
            higTableHeaderWrapper: {
                ...styles.higTableHeaderWrapper,
                overflowX: "hidden"
            }
        };
    };
};

export const projectSettingsTableStyle = (styles, props, themeData, themMeta) => {
    const { cellColumnIndex, isLast, depth, columnHeaderArray, role } = props;
    let headerTitle = null;
    role == 'cell' && (headerTitle = columnHeaderArray[cellColumnIndex]?.props?.title);
    return {
        ...styles,
        higTableCell: {
            ...styles.higTableCell,
            display: 'flex',
            background: 'white',
            padding: '2px 12px',
            height: '20px',
        },
        higTableCellContentWrapper: {
            ...styles.higTableCellContentWrapper,
            width: '2OOpx',            
            ...(cellColumnIndex !== 1 && {
                fontWeight: "600"
            }),
            fontSize: '12px',
        },
        headerHolder: {
            ...styles.headerHolder,
        },
        higTable: {
            ...styles.higTable,
            maxHeight: '100%',
            display: 'block',
            overflowX: 'hidden'

        },
        higTableHeaderContentWrapper: {
            ...styles.higTableHeaderContentWrapper,
            height: 'wrap-content',            
            background: themeData["table.header.backgroundColor"],
            justifyContent: 'space-between'
        }
    };
}