import React, { Component } from 'react';

export class LightingEfficiency extends Component {

    render() {
        return <svg
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 16 16"
            style={{
                // @ts-ignore: unrecognizable type
                enableBackground: "new 0 0 16 16"
            }}
            xmlSpace="preserve"
            className="factor-modal-icon"
        >
            <style type="text/css">
                {
                    "\n\t.st0{fill:#7C7C7C;}\n\t.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#7C7C7C;}\n"
                }
            </style>
            <path
                className="factor-modal-icon-color"
                d="M10,5.5c0-1.3-0.8-2.6-2-3.2V0H5v2.3C3.3,3.2,2.5,5.3,3.3,7S6.3,9.5,8,8.7C9.2,8.1,10,6.9,10,5.5"
            />
            <rect x={6} y={10} className="factor-modal-icon-color" width={1} height={2} />
            <rect
                x={9.9}
                y={8.4}
                transform="matrix(0.7076 -0.7066 0.7066 0.7076 -3.5969 10.0931)"
                className="factor-modal-icon-color"
                width={1}
                height={2}
            />
            <rect x={11} y={5} className="factor-modal-icon-color" width={2} height={1} />
            <rect y={5} className="factor-modal-icon-color" width={2} height={1} />
            <rect
                x={1.6}
                y={8.9}
                transform="matrix(0.7076 -0.7066 0.7066 0.7076 -5.8714 4.5913)"
                className="factor-modal-icon-color"
                width={2}
                height={1}
            />
            <polygon
                className="factor-modal-icon-color factor-modal-icon-rule"
                points="16,10 12,10 13.6,11.6 11,14.3 9,12.3 6.7,14.6 7.4,15.4 9,13.7 11,15.7 14.4,12.4 16,14 "
            />
        </svg>;
    }
}
