import {DataPoint, DataPointType, Factor, ISpec, Metric, Unit} from '../../types/metrics';
import {TextDataType} from "./dataPointsUtils";

export const CustomUnitId = "autodesk.spec:spec.string-2.0.0";

export const getDataPointUnit = (dataPoint: Metric | Factor | DataPoint, allUnits: Record<string, ISpec>, useImperial: boolean): Unit => {
  const emptyRetValue = {
    id: '',
    name: '',
    symbol: '',
    useSI: true,
  };

  if (!dataPoint) {
    return emptyRetValue;
  }

  switch (dataPoint.dataType) {
    case CustomUnitId:
      return  {
        id: CustomUnitId,
        name: 'Custom',
        symbol: dataPoint?.unit,
        useSI: true,
      };
    case TextDataType:
      return {
        id: CustomUnitId,
        name: '',
        symbol: '',
        useSI: true,
      };
  }

  switch (dataPoint.type)
  {
    case DataPointType.AnalysisResult:
    case DataPointType.ModelData: {
      const analysisResult = (dataPoint as DataPoint)?.dataPointValue;
      const unitTypeId = useImperial ? analysisResult?.imperialStandardValue?.typeId : analysisResult?.industryStandardValue?.typeId;
      const spec = findSpec(dataPoint?.dataType, allUnits);
      return spec?.applicableUnits[findUnit(unitTypeId, spec?.applicableUnits)] ?? emptyRetValue;
    }
    case DataPointType.Metric:
      const metricUnitId = useImperial ? dataPoint?.imperialStandardUnitId : dataPoint?.industryStandardUnitId;
      const spec = findSpec(dataPoint?.dataType, allUnits);
      return spec?.applicableUnits[findUnit(metricUnitId, spec?.applicableUnits) ?? dataPoint?.unit] ?? emptyRetValue;
    case DataPointType.Factor: {
      const factorValue = (dataPoint as Factor)?.dataPointValue?.values[0];
      const unitTypeIdIs = useImperial ? factorValue?.imperialStandardValue?.typeId : factorValue?.industryStandardValue?.typeId;
      const unitTypeId = unitTypeIdIs ?? dataPoint.unit;
      const spec = findSpec(dataPoint?.dataType, allUnits);
      return spec?.applicableUnits[findUnit(unitTypeId, spec?.applicableUnits)] ?? emptyRetValue;
    }
    default:
      return emptyRetValue;
  }
}

export const findSpec = (dataPointDataType: string, allUnits: Record<string, ISpec>): ISpec => {
  if (!dataPointDataType) {
    return null;
  }

  const specParts = dataPointDataType?.split('-');
  const specName = specParts?.length > 0 ? specParts[0] : undefined;
  if (!specName) {
    return undefined;
  }
  const spec = Object.keys(allUnits).find(key => key.includes(specName));
  return allUnits[spec];
}

export const findUnit = (unitTypeId: string, applicableUnits: Record<string, Unit>): string => {
  const unitParts = unitTypeId?.split('-');
  const unitName = unitParts?.length > 0 ? unitParts[0] : undefined;
  if(!unitName) {
    return undefined;
  }
  return Object.keys(applicableUnits).find(u => u.includes(unitName));
}

export const flattenUnits = (allUnits:  Record<string, ISpec>): Unit[] => {
  const unitsSet = Object.keys(allUnits).reduce((acc, specKey) => {
    const unitsOfSpec = allUnits[specKey]?.applicableUnits;
    acc = Object.keys(unitsOfSpec).reduce((a, unit) => {
      acc.add(unitsOfSpec[unit]);
      return acc;
    }, acc);
    return acc;
  }, new Set<Unit>());
  return Array.from(unitsSet);
}

export const hasCustomUnit = (metric: Metric | Factor):boolean => metric?.dataType === CustomUnitId;
