
export const getCursorPosition = (range: Range) => {

  //previous node is the Zero-Width Space
  if(
    range.commonAncestorContainer.previousSibling !== (range.commonAncestorContainer as Element)?.previousElementSibling &&
    range.commonAncestorContainer.previousSibling?.textContent === ('\u200B')
  ) {
    return range.endOffset; //the offset is index within the text
  }

  //there are both html nodes --> tokens
  //means we have the entire text within the cursor (U+200Bformulatext)
  const textWithCursor = (range.commonAncestorContainer as Text)?.wholeText;
  const hasTrailingAtStart = textWithCursor?.startsWith('\u200B');
  //-1 as the first char is the Zero-Width Space
  return (hasTrailingAtStart && range.endOffset > 0) ? range.endOffset - 1 : range.endOffset;
}

export const getPreviousTokens = (range: Range) : string[] => {
  const result: string[] = [];

  if (range.commonAncestorContainer) {
    if (range.commonAncestorContainer.nodeType === 3 && (range.commonAncestorContainer as Text)?.wholeText !== '\u200B') {
      result.push((range.commonAncestorContainer as Text)?.wholeText?.replace(/\u200B/g,''));
    }
    getAllPreviousTokens(range.commonAncestorContainer.previousSibling as Element, result);
  }
  return result.reverse();
}


const getAllPreviousTokens = (element: any, result: string[]): void => {
  if (!element) {
    return;
  }
  if(element.nodeType === 1) { //push the token node
    result.push(element?.outerHTML);
  } else  { //push the text node - if it's a math operator; if it's the last one skip it as it's counted in the selection
    const text = element.wholeText?.replace(/\u200B/g,'');
    if (result.length > 0 && result[result.length -1] !== text) {
      result.push(text);
    }
  }
  if (element?.previousSibling) {
    getAllPreviousTokens(element.previousSibling, result);
  }
}
