import React, { useRef, useEffect, useState, useCallback } from 'react';
import { ECDetailsPreference, FilteredConstructions } from '../../types';
import { EcChartType } from '../../../../charts/types';
import '../../../../css/new/lmvlegend.css';
import ResizeListener from '../../../../shared/ResizeListener/ResizeListener';
import Chart from '../../../../charts/Chart';
import * as modelType from '../../../dashboard.models';
import {
  ChartData,
  EcDropdownOptions,
  getEcByCategoryChartData,
  getEcByConstructionChartData,
  getEcByMaterialChartData,
  getEcIntensityByAreaChartData,
} from './utils';
import ChartLegend from './ChartLegend';

export type ECDetailsChartSectionProps = {
  filteredConstructions?: FilteredConstructions[];
  defaultChart: string;
  modelId: string;
  onChartTypeChange: (modelId: string, chart: string) => void;
  constructionColorData: Map<string, string>;
  materialColorData: Map<string, string>;
  categoryColorData: Map<string, string>;
  useSI: boolean;
  ecModelBreakdown: modelType.ModelEmbodiedCarbonBreakdownEntity;
  selectedConstructionId: string;
  chartLegendExpandedPreference: (modelId: string, legendCollapseState: boolean) => void;
  onChartClick: (selectedConstructionId: string) => void;
  validatePreference: (modelId: string) => ECDetailsPreference;
  selectedMaterialSha: string;
  onChartMaterialClick: (selectedMaterialSha: string, selectedConstructionId: string) => void;
  chartWidth: number;
};

const ECDetailsChartSection = (props: ECDetailsChartSectionProps): JSX.Element => {
  const chartRef = useRef(null);
  const {
    filteredConstructions,
    modelId,
    onChartTypeChange,
    defaultChart,
    constructionColorData,
    materialColorData,
    ecModelBreakdown,
    categoryColorData,
    useSI,
    selectedConstructionId,
    chartLegendExpandedPreference,
    validatePreference,
    selectedMaterialSha,
    chartWidth,
  } = props;

  const [constructions, setConstructions] =
    useState<FilteredConstructions[]>(filteredConstructions);
  const [chartData, setChartData] = useState<ChartData>({} as ChartData);
  const [ecPreference, setEcPreference] = useState<ECDetailsPreference>(null);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(-1);
  const [currentHoveredChartMaterialSha, setcurrentHoveredChartMaterialSha] =
    useState<number>(null);
  const onCollapsedClick = () => {
    chartLegendExpandedPreference(modelId, !ecPreference.legendCollapseState.chartLegendState);
    var ecDetailsPreference = validatePreference(modelId);
    setEcPreference(ecDetailsPreference);
  };

  useEffect(() => {
    if (chartRef.current) chartRef.current.chart?.reflow();
  }, []);

  useEffect(() => {
    setConstructions(filteredConstructions);
  }, [filteredConstructions]);

  const onCategoryClick = useCallback(
    (event, categoryIndex) => {
      if (categoryIndex !== undefined) {
        props.onChartClick(constructions[categoryIndex]?.constructionId);
      }
    },
    [constructions]
  );

  const onSeriesMouseOver = useCallback((hoveredMaterialShaIndex) => {
    if (hoveredMaterialShaIndex !== undefined) {
      setcurrentHoveredChartMaterialSha(hoveredMaterialShaIndex);
    }
  }, []);

  const onSeriesMouseOut = useCallback((hoveredMaterialShaIndex) => {
    if (hoveredMaterialShaIndex !== undefined) {
      setcurrentHoveredChartMaterialSha(hoveredMaterialShaIndex);
    }
  }, []);

  const onChartMaterialClick = useCallback(
    (event, selectedChartMaterialSha, categoryIndex) => {
      if (selectedChartMaterialSha !== undefined && categoryIndex !== undefined) {
        props.onChartMaterialClick(
          selectedChartMaterialSha,
          constructions[categoryIndex]?.constructionId
        );
      }
    },
    [constructions]
  );

  useEffect(() => {
    const chart = !defaultChart ? EcChartType.EcByCategory : defaultChart;
    let data: ChartData;
    switch (chart) {
      case EcChartType.EcByConstruction:
        data = getEcByConstructionChartData(
          modelId,
          filteredConstructions,
          materialColorData,
          chartWidth
        );
        break;
      case EcChartType.EcByMaterial:
        data = getEcByMaterialChartData(modelId, filteredConstructions, materialColorData);
        break;
      case EcChartType.EcByCategory:
        data = getEcByCategoryChartData(
          modelId,
          filteredConstructions,
          ecModelBreakdown,
          categoryColorData
        );
        break;
      case EcChartType.EcIntensityByArea:
        data = getEcIntensityByAreaChartData(
          modelId,
          filteredConstructions,
          constructionColorData,
          useSI
        );
        break;
    }
    setChartData(data);
    const ecDetailsPreference = validatePreference(modelId);
    setEcPreference(ecDetailsPreference);
  }, [filteredConstructions, defaultChart, useSI, selectedConstructionId]);

  useEffect(() => {
    if (!selectedConstructionId) {
      setSelectedCategoryIndex(-1);
    } else {
      setSelectedCategoryIndex(
        constructions.findIndex((c) => c.constructionId === selectedConstructionId)
      );
    }
  }, [selectedConstructionId, constructions]);

  if (ecPreference) {
    return (
      <div className="ecdetails-content-chart">
        <ResizeListener
          className={
            ecPreference.legendCollapseState.chartLegendState
              ? 'ecdetails-chart-section'
              : 'ecdetails-chart-section ecdetails-chart-section-expanded'
          }
          onResize={() => chartRef.current.chart?.reflow()}
        >
          <div className="ecdetails-chart-header-container">
            <div className="ecdetails-chart-div-title">
              <span className="ecdetails-chart-title">{chartData.chartTitle}</span>
              <span className="ecdetails-chart-title-units">{chartData.chartTitleUnits}</span>
            </div>
          </div>
          <div className="ecdetails-highchart-container">
            <div className="ecdetails-highchart-wrapper">
              <Chart
                chartKey={chartData.chartKey}
                options={chartData.chartOption}
                chartRef={chartRef}
                selectedCategoryIndex={selectedCategoryIndex}
                selectedMaterialSha={selectedMaterialSha}
                events={{
                  onCategoryClick,
                  onSeriesMouseOver,
                  onSeriesMouseOut,
                  onChartMaterialClick,
                }}
                chartWidth={chartWidth}
                yAxisMaxValue={Math.max(...constructions.map((x) => x.totalEmbodiedCarbon))}
              />
            </div>
          </div>
        </ResizeListener>
        <div
          className={
            ecPreference.legendCollapseState.chartLegendState
              ? 'ecdetails-content-chart-legend'
              : 'ecdetails-content-chart-legend ecdetails-content-chart-legend-collapsed'
          }
        >
          <ChartLegend
            defaultChart={EcChartType.EcByConstruction}
            modelId={modelId}
            value={defaultChart}
            EcDropdownOptions={EcDropdownOptions}
            onChange={onChartTypeChange}
            chartLegendItems={chartData.chartOption}
            chartLegenedTitle={chartData.chartLegenedTitle}
            onChartLegendCollapseClick={onCollapsedClick}
            isChartLegendCollapsed={ecPreference.legendCollapseState.chartLegendState}
            selectedConstructionId={selectedConstructionId}
            currentHoveredChartMaterialSha={currentHoveredChartMaterialSha}
            selectedMaterialSha={selectedMaterialSha}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ECDetailsChartSection;
