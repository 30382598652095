import {createReducer} from "redux-starter-kit";
import {ApplicationState} from "./types";
import {CLOSE_DIALOG, SHOW_DIALOG} from "./actionTypes";

const defaultState: ApplicationState = {showModalDialog: false};
export const applicationReducer = createReducer<ApplicationState, any>(defaultState, {
    [SHOW_DIALOG]: (state: ApplicationState, action) => {
        state.showModalDialog = true;
    },
    [CLOSE_DIALOG]: (state: ApplicationState, action) => {
        state.showModalDialog = false;
    }
});