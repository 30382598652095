import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseQuery, QueryType } from './base-queries';
import { ViewerToken } from '../../dashboard/api'

export const viewerTokenDataApi = createApi({
  reducerPath: 'viewerTokenDataApi',
  baseQuery: BaseQuery(QueryType.Apigee),
  keepUnusedDataFor: 1800,
  endpoints: (builder) => ({
    getViewerToken: builder.query<ViewerToken, void>({
      query: () => `/login/getviewertoken`
    }),
  }),
});

export const { useGetViewerTokenQuery } = viewerTokenDataApi;
