import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid, { GridRowParams, GridRowSelectionModel } from '@weave-mui/data-grid';
import Typography from '@weave-mui/typography';
import { Benchmark, Metric } from '../../../../types/metrics';
import Box from '@weave-mui/box';
import { useGetUnitsQuery } from '../../../../state/api/data-service-api';
import { CardDataLoadingProgress } from '../../../../layout/cards/base/CardDataLoadingProgress';
import { metricsTableStlesheet, overriddenTableStyle } from '../../../styles/benchmarks.stylesheet';
import Search from '../../../../shared/Search';
import { benchmarksMetricsListColumns, filterMetricRows, generateDisabledMetricIds, generateRemovedDisabledMetricsIds, generateRowsFromMetrics, reorderRows } from '../../../utils/benchmarksUtils';
import { BenchmarkMetricsListRow, ReorderedRows } from '../../../types/benchmarks';
import i18n from '../../../../i18n';
import CustomNoRowsOverlay from './utils/CustomNoRowsOverlay';
import CustomCheckbox from './utils/CustomCheckbox';
import { CheckboxProps } from '@weave-mui/checkbox';

interface BenchmarksMetricsTableProps {
  selectedBenchmark: Benchmark;
  setSelectedBenchmark: Dispatch<SetStateAction<Benchmark>>;
  metricsList: Metric[];
  imperialUnits: boolean;
  isReadOnly: boolean;
  benchmarksList: Benchmark[]
}

const BenchmarksMetricsTable: React.FC<BenchmarksMetricsTableProps> = ({selectedBenchmark, metricsList, imperialUnits, setSelectedBenchmark, isReadOnly, benchmarksList}) => {
  const { data: allUnits } = useGetUnitsQuery();
  const disabledMetricIds = useMemo<string[]>(() => generateDisabledMetricIds(benchmarksList), [benchmarksList]);
  const [updatedDisabledMetricIds, setUpdatedDisabledMetricIds] = useState<string[]>([]);

  const rowsFromMetrics = useMemo<BenchmarkMetricsListRow[]>(
    () => generateRowsFromMetrics(metricsList, allUnits, imperialUnits),
    [metricsList, allUnits, imperialUnits]
  );

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredRows, setfilteredRows] = useState<BenchmarkMetricsListRow[]>(rowsFromMetrics);

  useEffect(() => {
    setUpdatedDisabledMetricIds(disabledMetricIds);
    setSelectionModel(selectedBenchmark?.metrics ?? []);
    if (selectedBenchmark?.metrics.length > 0) {
      setfilteredRows(prev => {
        const reorderedRows = reorderRows(prev, selectedBenchmark.metrics, true) as BenchmarkMetricsListRow[];
        return reorderedRows;
      });
    } else {
      setfilteredRows(rowsFromMetrics);
    }
  }, [disabledMetricIds, selectedBenchmark?.id]);

  useEffect(() => {
    setSelectionModel(selectedBenchmark?.metrics ?? []);
    if (isReadOnly) {
      setfilteredRows(reorderRows(rowsFromMetrics, selectedBenchmark.metrics, true) as BenchmarkMetricsListRow[]);
    }
  }, [selectedBenchmark?.metrics, isReadOnly]);

  useEffect(() => {
    if (searchValue.trim().length > 0) {
      const { selected, unselected } = reorderRows(rowsFromMetrics, selectedBenchmark?.metrics ?? [], false) as ReorderedRows;
      const filteredUnselectedRows = filterMetricRows(unselected, searchValue);
      setfilteredRows([
        ...selected,
        ...filteredUnselectedRows
      ]);
    } else {
      setfilteredRows(rowsFromMetrics)
    }
  }, [searchValue, rowsFromMetrics])

  const selectHandler = useCallback((newSelection: GridRowSelectionModel) => {
    const currentMetricIds = selectedBenchmark?.metrics;    
    const metricsIdList: string[] =  newSelection as string[];
    
    //to allow users to select and unselect disabled metricIds on edit
    const removedDisabledMetrics = generateRemovedDisabledMetricsIds(currentMetricIds, disabledMetricIds, metricsIdList);

    if (removedDisabledMetrics.length > 0) {
      setUpdatedDisabledMetricIds(prev => prev.filter(id => !removedDisabledMetrics.includes(id)));
    }
    setSelectionModel(metricsIdList);
    setSelectedBenchmark(prevBenchmark => {
      return {
        ...prevBenchmark,
        metrics: metricsIdList
      }
    });
  }, [selectedBenchmark?.metrics, disabledMetricIds]);

  const isRowSelectable = useCallback((params: GridRowParams) => {
    const id = params.id as string;

    if (isReadOnly) return false;

    if (updatedDisabledMetricIds.includes(id) && !selectedBenchmark?.metrics?.includes(id)) return false;

    return true;
  }, [isReadOnly, updatedDisabledMetricIds, selectedBenchmark?.metrics]);

  return (
    <Box sx={
      {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        px: '0.5rem',
        overflow: 'hidden'
        }
      }>
        <Box sx={
          {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            ' & > div': {
              width: '30%'
            }
          }
        }>
          <Typography>{`${i18n.t('analysis.dataPoints.benchmarks.metricsListTitle')}`}</Typography>
          <Search
            isUnfocused={true}
            isReadOnly={isReadOnly}
            searchValue={searchValue}
            key={`benchmarks-metrics-list-search`}
            onSearch={(query) => setSearchValue(query)}
          />
        </Box>
        <Box sx={{...metricsTableStlesheet}} data-testid='benchmarks-metrics-list-container'>
          {
            !selectedBenchmark || rowsFromMetrics.length === 0
              ? <CardDataLoadingProgress/>
              : <DataGrid
                  rows={filteredRows}
                  columns={benchmarksMetricsListColumns}
                  checkboxSelection
                  disableRowSelectionOnClick={false}
                  rowHeight={30}
                  rowSelectionModel={selectionModel}
                  onRowSelectionModelChange={selectHandler}
                  disableColumnMenu
                  disableColumnReorder
                  disableColumnResize
                  data-testid='benchmarks-metrics-list'
                  isRowSelectable={isRowSelectable}
                  loading={!selectedBenchmark}
                  sx={{ ...overriddenTableStyle }}
                  slots={
                    { 
                      noRowsOverlay: CustomNoRowsOverlay,
                      baseCheckbox: (props: CheckboxProps) => <CustomCheckbox checkboxProps={props} disabledMetricIds={updatedDisabledMetricIds} isReadOnly={isReadOnly} /> 
                    }
                  }
                />
            }
        </Box>
      </Box>
  )
}

export default BenchmarksMetricsTable;
