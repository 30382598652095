import React, { useEffect, useState } from 'react';
import {
  AiaDdxAuthRequest,
  AiaDdxAuthRequestKeys,
  AiaDdxImportProjectDataKeys,
  AiaDdxImportProjectDataRequest,
} from './const';
import { MISSING_DATA_POINT_VALUE, ReportData, ReportSubmissionStatus } from '../types/reporting';
import {
  useAiaDdxAuthenticateQuery,
  useAiaDdxReportQuery,
} from '../../state/api/reporting-data-api';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../state/slice/notifications-slice';
import i18n from '../../i18n';

export const SubmissionComponent: React.FC<{
  reportData: ReportData<AiaDdxImportProjectDataKeys | AiaDdxAuthRequestKeys>;
  submit: boolean;
  complete: (status: ReportSubmissionStatus) => void;
}> = ({ reportData, submit, complete }) => {
  const dispatch = useDispatch();
  const [authRequest, setAuthRequest] = useState<AiaDdxAuthRequest>(null);
  const [aiaDdxReportRequest, setAiaDdxReportRequest] =
    useState<AiaDdxImportProjectDataRequest>(null);
  const {
    data: authResponse,
    error: authError,
    isError: isAuthError,
    isSuccess: isAuthSuccess,
  } = useAiaDdxAuthenticateQuery(authRequest, { skip: !authRequest });
  const {
    data: reportResponse,
    error: reportError,
    isError: isReportError,
    isSuccess: isReportSuccess,
  } = useAiaDdxReportQuery(aiaDdxReportRequest, {
    skip: !aiaDdxReportRequest,
  });

  useEffect(() => {
    if (submit) {
      const allFields = reportData.sections.map((x) => x.fields).flat();
      const firm_key = allFields.find((x) => x.apiFieldName === 'firm_key')?.data.value;
      const user_key = allFields.find((x) => x.apiFieldName === 'user_key')?.data.value;
      setAuthRequest({ firm_key, user_key });
    }
  }, [submit]);

  useEffect(() => {
    if (isAuthSuccess) {
      const allFields = reportData.sections.map((x) => x.fields).flat();
      let request = {};
      allFields
        .filter(
          (x) =>
            x.apiFieldName !== 'firm_key' &&
            x.apiFieldName !== 'user_key' &&
            x.isRequired.value &&
            x.data.value !== MISSING_DATA_POINT_VALUE,
        )
        ?.forEach((x) => (request[x.apiFieldName] = x.submissionValue || x.data.value));
      setAiaDdxReportRequest({
        ...request,
        ...authResponse,
        energyModelingTool: 'Autodesk Insight',
      } as any);
    }

    if (isAuthError) {
      dispatch(
        addNotification({
          message: i18n.t('reporting.aiaddx.errors.auth'),
          type: 'error',
          autoHideDuration: 6000,
        }),
      );
      console.warn('aia ddx authentication failed', authError);
      complete({ success: false, error: true });
    }
  }, [isAuthSuccess, isAuthError]);

  useEffect(() => {
    if (isReportSuccess) {
      dispatch(
        addNotification({
          message: i18n.t('reporting.aiaddx.notifications.report'),
          type: 'success',
          autoHideDuration: 3500,
        }),
      );
      console.log('aia ddx report data submitted successfully', reportResponse);
      complete({ success: true, error: false });
    }

    if (isReportError) {
      dispatch(
        addNotification({
          message: i18n.t('reporting.aiaddx.errors.report'),
          type: 'error',
          autoHideDuration: 6000,
        }),
      );
      console.warn('aia ddx report data submission failed', reportError);
      complete({ success: false, error: true });
    }
  }, [isReportSuccess, isReportError]);

  return <></>;
};
