import React, { Component } from 'react';

export class WindowWallRatio extends Component {

	render() {
        return <svg
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 16 16"
            style={{
                // @ts-ignore: unrecognizable type
                enableBackground: "new 0 0 16 16"
            }}
            xmlSpace="preserve"
            className="factor-modal-icon"
        >
            <style type="text/css">
                {
                    "\n\t.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#7C7C7C;}\n\t.st1{fill:#7C7C7C;}\n"
                }
            </style>
            <path
                className="factor-modal-icon-color factor-modal-icon-rule"
                d="M2,13h2v-2H2V13z M6,13h2v-2H6V13z M2,9h2V7H2V9z M6,9h2V7H6V9z M10,14h5V6h-5V14z M2,5h2V3H2V5z M6,5h2V3H6V5z  M0,1v14h16V5h-6V1H0z"
            />
            <rect x={11} y={7} className="factor-modal-icon-color" width={3} height={2} />
            <rect x={11} y={11} className="factor-modal-icon-color" width={3} height={2} />
        </svg>;
    }
}
