import React, { Component } from 'react';

export class Hvac extends Component {

    render() {
        return <svg
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 16 16"
            style={{
                // @ts-ignore: unrecognizable type
                enableBackground: "new 0 0 16 16"
            }}
            xmlSpace="preserve" className="factor-modal-icon">
            <style type="text/css">
                {
                    "\n\t.st0hvac{clip-path:url(#SVGIDHVAC_2_);}\n\t.st1{fill:#808080;}\n\t.st2hvac{clip-path:url(#SVGIDHVAC_4_);}\n\t.st3hvac{clip-path:url(#SVGIDHVAC_6_);}\n"
                }
            </style>
            <g>
                <defs>
                    <polygon id="SVGIDHVAC_1_" points="3,9 3,13 2,13 4,16 6,13 5,13 5,9  " />
                </defs>
                <clipPath id="SVGIDHVAC_2_">
                    <use
                        xlinkHref="#SVGIDHVAC_1_"
                        style={{
                            overflow: "visible"
                        }}
                    />
                </clipPath>
                <g className="st0hvac">
                    <rect x={-442} y={-618} className="factor-modal-icon-color" width={1440} height={1024} />
                </g>
            </g>
            <g>
                <defs>
                    <polygon id="SVGIDHVAC_3_" points="7,9 7,13 6,13 8,16 10,13 9,13 9,9  " />
                </defs>
                <clipPath id="SVGIDHVAC_4_">
                    <use
                        xlinkHref="#SVGIDHVAC_3_"
                        style={{
                            overflow: "visible"
                        }}
                    />
                </clipPath>
                <g className="st2hvac">
                    <rect x={-442} y={-618} className="factor-modal-icon-color" width={1440} height={1024} />
                </g>
            </g>
            <g>
                <defs>
                    <polygon
                        id="SVGIDHVAC_5_"
                        points="11,9 11,13 10,13 12,16 14,13 13,13 13,9  "
                    />
                </defs>
                <clipPath id="SVGIDHVAC_6_">
                    <use
                        xlinkHref="#SVGIDHVAC_5_"
                        style={{
                            overflow: "visible"
                        }}
                    />
                </clipPath>
                <g className="st3hvac">
                    <rect x={-442} y={-618} className="factor-modal-icon-color" width={1440} height={1024} />
                </g>
            </g>
            <path
                className="factor-modal-icon-color"
                d="M14,1H2H0v9h2V8h12v1.9h2V1H14z M14,6H2V5h12V6z M14,4H2V3h12V4z"
            />
        </svg>;
    }
}
