import {CardsData} from "@adsk/wildcard/es/components/CardGrid/types";
import {diff} from "deep-object-diff";

//We need to clean undefined values like for example parameterId, the diff() in cadSettingsDiff will take care of knowing
// if there are any differences in length of the objects.
const removeUndefinedValues = (obj: { [key: string]: any }): { [key: string]: any } => {
  if (Array.isArray(obj)) {
    return obj
      .map(removeUndefinedValues)
      .filter(val => val !== undefined);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const cleanedValue = removeUndefinedValues(obj[key]);
      if (cleanedValue !== undefined) {
        acc[key] = cleanedValue;
      }
      return acc;
    }, {} as any);
  }
  return obj;
}

export const cardSettingsDiff = (initialCards: CardsData, currentCards: CardsData) => {
  const cleanInitialValues = Object.values(removeUndefinedValues(initialCards));
  const cleanCurrentValues = Object.values(removeUndefinedValues(currentCards));

  const sortedInitialValues = cleanInitialValues.sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
  const sortedCurrentValues = cleanCurrentValues.sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));

  return diff(sortedInitialValues, sortedCurrentValues);
}
