import { Middleware, MiddlewareAPI, isRejected } from '@reduxjs/toolkit';
import { hasAnalyticsQueryOptions } from './base-queries';
import adpService from '../../webAnalytics/adp';

/**
 * Middleware function that catches Redux actions and records them in the analytics service.
 * @param api - The Redux middleware API.
 * @returns A function that handles the next middleware in the chain.
 */
export const analyticsMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  const queryOptions = action.meta ? hasAnalyticsQueryOptions(action.meta.baseQueryMeta) : null;
  const actionIsRejected = isRejected(action);
  if (!actionIsRejected || queryOptions?.analyticsOptions?.recordFailureOperation) {
    if (queryOptions) {
      adpService.recordBackgroundCallEvent(
        queryOptions.analyticsOptions.eventType,
        queryOptions.analyticsOptions.eventName,
        queryOptions.analyticsOptions.meta,
        actionIsRejected && queryOptions?.analyticsOptions?.recordFailureOperation,
      );
    }
  }
  return next(action);
};
