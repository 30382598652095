export const CREATE_MODEL_ADD = "CreateModelAdd";

export const MODEL_SELECTOR_ADD = "ModelSelectorAdd";
export const MODEL_SELECTOR_DONE = "ModelSelectorDone";

export const MODEL_WIDGET_RENAME = "ModelWidgetRename";
export const MODEL_WIDGET_MOVE = "ModelWidgetMove";
export const MODEL_WIDGET_DELETE = "ModelWidgetDelete";
export const MODEL_WIDGET_ANALYSIS = "ModelWidgetAnalysis";
export const MODEL_WIDGET_FLEXIBLE_LAYOUT = "ModelWidgetFlexibleLayout";
export const MODEL_WIDGET_ADD_HVAC = "ModelWidgetAddHvac";
export const MODEL_WIDGET_ADD_PLUG = "ModelWidgetAddPlug";
export const MODEL_WIDGET_ADD_LIGHTING = "ModelWidgetAddLighting";
export const MODEL_WIDGET_MODAL_DELETE = "ModelWidgetModalDelete";
export const MODEL_WIDGET_MODAL_DESC = "ModelWidgetModalDesc";
export const MODEL_WIDGET_REPORT = "ModelWidgetReport";


export const PROJECT_WIDGET_SELECTED_MODEL = "ProjectWidgetSelectedModel";
export const PROJECT_WIDGET_CANCEL = "ProjectWidgetCancel";
export const PROJECT_WIDGET_UPLOAD = "ProjectWidgetUpload";
