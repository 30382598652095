import React, { useCallback, useState } from 'react';
import Box from '@weave-mui/box';
import Typography, { typographyVariants } from '@weave-mui/typography';
import Tooltip, { tooltipPlacement, tooltipSize } from '@weave-mui/tooltip';
import { InfoS } from '@weave-mui/icons-weave';
import i18n from '../../../../i18n';
import { DataPointType } from '../../../../types/metrics';

interface DataPointsDividerProps {
  title: string;
  dataPointType: DataPointType;
}

const DataPointsDivider: React.FC<DataPointsDividerProps> = ({
  title,
  dataPointType,
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  
  const handleOnMouseEnter = useCallback(() => {
    setShowTooltip(true);
  },[]);

  const handleTooltipClose = useCallback(() => {
    setShowTooltip(false);
  },[]);

  const generateTooltipDescription = useCallback((): JSX.Element => {
    if (dataPointType === DataPointType.Metric) {
      return  (
        <Box sx={{mt: 2}}>
          <Typography variant={typographyVariants.CAPTION_REGULAR}>
            {i18n.t('analysis.dataPoints.metrics.formulaInfoDescription')}
            <Typography variant={typographyVariants.CAPTION_BOLD}>
              {i18n.t('analysis.dataPoints.metrics.formulaInfoOperators')}
            </Typography>
          </Typography>
        </Box>
      )
    }
    return (
      <Box sx={{mt: 2}}>
        <Typography variant={typographyVariants.CAPTION_REGULAR}>
          {i18n.t('analysis.dataPoints.factors.tableInfoDescription')}
        </Typography>
      </Box>
    );
  }, [dataPointType]);

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        { title && <Typography>{title}</Typography>}
        <Tooltip
          arrow
          leaveDelay={0}
          enterDelay={500}
          open={showTooltip}
          enterNextDelay={500}
          size={tooltipSize.MEDIUM}
          onClose={handleTooltipClose}
          placement={tooltipPlacement.RIGHT}
          title={
            <Typography variant={typographyVariants.CAPTION_BOLD}>
              { 
                dataPointType === DataPointType.Metric
                  ? i18n.t('analysis.dataPoints.metrics.formulaInfoHeader')
                  : i18n.t('analysis.dataPoints.factors.tableInfoHeader')
              }
            </Typography>
          }
          description={generateTooltipDescription()}
          PopperProps={{
            style: {
              zIndex: 10000,
              width: '270px',
            },
          }}
        >
          <InfoS
            color="action"
            onMouseEnter={handleOnMouseEnter}
            sx={{ cursor: 'default', ml: 1 }}
          />
        </Tooltip>
      </Box>
    </Box>
  )
}

export default DataPointsDivider;
