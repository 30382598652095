import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface FactorDataState {
  factorOverrides: Record<string, number>;
}

const initialState: FactorDataState = {
  factorOverrides: {},
};

export const factorDataSlice = createSlice({
  name: 'factorDataState',
  initialState,
  reducers: {
    setFactorOverride(state, action: PayloadAction<{ factorId: string; value: number }>) {
      state.factorOverrides[action.payload.factorId] = action.payload.value;
    },
    clearAllFactorOverrides(state) {
      state.factorOverrides = {};
    },
  },
});

export const { setFactorOverride, clearAllFactorOverrides } = factorDataSlice.actions;
