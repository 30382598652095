import React from 'react';
import { AlertS } from '@weave-mui/icons-weave';
import i18n from '../../../i18n';
import * as locConsts from '../../../shared/localization/consts';
import '../../../css/new/ecdetails.css';
import '../../../css/new/ecDefinition.css';
import TooltipContainer from '../../components/TooltipContainer/TooltipContainer';
import { EcDefinition } from '../types';
import { GridRowId } from '@weave-mui/data-grid';

interface ConstructionNameCellProps {
  id: GridRowId;
  field: string;
  value?: unknown;
  row: {
    constructionName: string;
    depth: number;
    subRows: {
      ecDefinition: EcDefinition | null | {};
    }[];
  };
  rowNode: {};
  colDef: {};
  cellMode: string;
  hasFocus: boolean;
  tabIndex: number;
}

const ConstructionNameCell = (params: ConstructionNameCellProps): JSX.Element => {
  const { row } = params;
  const isTotalRow = row?.constructionName === i18n.t('analysis.ec.construction.totalRowTitle');
  const isConstruction = !row?.depth;

  let showWarning: boolean = false;

  if (isConstruction && !isTotalRow) {
    showWarning = (row?.subRows || []).some((x) => x.ecDefinition == null);
  }

  return (
    <>
      {showWarning && (
        <TooltipContainer
          hasIcon
          title={
            <>
              {i18n.t(locConsts.MISSING_EC_COEFFICIENT)} <br /> <br />
              {i18n.t(locConsts.MISSING_CONSTRUCTION_EC_COEFFICIENT)}
            </>
          }
          content={<AlertS sx={{ marginRight: '0px !important', fill: '#FAA21B !important' }} />}
        />
      )}
      <TooltipContainer content={row?.constructionName} />
    </>
  );
};

export default ConstructionNameCell;
