import * as api from './api';
import { Dispatch } from 'redux';
import { extractUUIDFromUrn } from '../utils/UrnUtils';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const LOAD_CURRENCIES_SUCCESS = 'LOAD_CURRENCIES_SUCCESS';

type ApiActionCreatorConfig = {
  startedParams?: any;
  successResultMap?: any;
  started?: any;
  api?: any;
  success?: any;
  successParams?: any;
  error?: any;
};

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
  PRIMARY = 'primary',
  WARNING = 'warning',
}

interface INotification {
  type: NotificationType;
  message: string;
  duration?: number;
  id?: string;
}

interface INotificationAction {
  type: string;
  notification: INotification;
}

export function apiActionCreator(
  config: ApiActionCreatorConfig,
  param2?: any,
  param3?: any,
  param4?: any,
  param5?: any,
  param6?: any
) {
  let params = [];

  for (let i = 1; i < arguments.length; ++i) {
    params.push(arguments[i]);
  }

  const successResultMap = config.successResultMap ?? ((result) => result);

  return (dispatch) => {
    if (config.started) {
      if (!config.startedParams) dispatch(config.started());
      else dispatch(config.started.apply(null, config.startedParams));
    }
    return config.api
      .apply(null, params)
      .then((json) => {
        if (config.success) {
          if (!config.successParams) dispatch(config.success(successResultMap(json)));
          else
            dispatch(config.success.apply(null, [successResultMap(json), ...config.successParams]));
        }
        return json;
      })
      .catch((error) => {
        if (config.error) {
          return dispatch(config.error(error));
        } else if (error.apiError) throw error;
      });
  };
}

export const invalidFileError = (error: string) => {
  return (dispatch) => {
    return dispatch(showNotification(error, NotificationType.ERROR));
  };
};

export const defaultErrorHandler = (error: any) => {
  return (dispatch) => {
    if (error.apiError) {
      return error.error.json().then((json) => {
        if (json.errorCode)
          return dispatch(showNotification(json.errorCode, NotificationType.ERROR));

        return dispatch(showNotification('UnknownError', NotificationType.ERROR));
      });
    }
    return dispatch(showNotification('UnknownError', NotificationType.ERROR));
  };
};

export const showNotificationActionCreator = (
  showNotificationArguments: INotification,
  inheritedAssignments: string[]
) => {
  const { message, type, duration, id } = showNotificationArguments;

  return (dispatch: Dispatch, getState: Function) => {
    const { modelState } = getState();
    const modelKey = Object.keys(modelState.models).find((k) => k.includes(id));
    if (!modelKey) {
      return;
    }
    const currentProjectId = modelState.models[modelKey]?.projectId;

    const isInheritedInFolder = Object.keys(modelState.models).some((modelId) => {
      const model = modelState.models[modelId];
      const isSameFolder = model.projectId === currentProjectId;

      if (isSameFolder) {
        const modelUUID = extractUUIDFromUrn(modelId);
        return inheritedAssignments.indexOf(modelUUID) !== -1;
      } else {
        return false;
      }
    });

    if (isInheritedInFolder) {
      dispatch(showNotification(message, type, duration, id));
    }
  };
};

export const showNotification = (
  message: string,
  type: NotificationType = NotificationType.SUCCESS,
  duration?: number,
  id?: string
): INotificationAction => {
  return {
    type: SHOW_NOTIFICATION,
    notification: {
      type,
      message,
      duration,
      id,
    },
  };
};

export function deleteNotification(key: string | number): { type: string; key: string | number } {
  return {
    type: DELETE_NOTIFICATION,
    key,
  };
}

export function clearNotifications() {
  return {
    type: CLEAR_NOTIFICATIONS,
  };
}

export function loadCurrencies() {
  return apiActionCreator({
    api: api.getCurrencies,
    success: loadCurrenciesSuccess,
  });
}

export const loadCurrenciesSuccess = (
  currencies: api.ApiCurrency[]
): { type: string; currencies: api.ApiCurrency[] } => {
  return {
    type: LOAD_CURRENCIES_SUCCESS,
    currencies,
  };
};
