import React from 'react';
import '../../css/new/ecDefinition.css'

interface IBannerContextProps {
    bannersArray: JSX.Element[];
}

const BannerContext = ({bannersArray}: IBannerContextProps): JSX.Element => {
    if (bannersArray.length === 0) return null;

    return (
        <div className='bannerContext'>
            {bannersArray}
        </div>
    );
};

export default BannerContext;
