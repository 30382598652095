import Box from '@weave-mui/box';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import DataPointsDivider from '../Divider/DataPointsDivider';
import i18n from '../../../../i18n';
import FormControl from '@weave-mui/form-control';
import FormLabel, { formLabelVariants } from '@weave-mui/form-label';
import TextField, { textFieldVariants } from '@weave-mui/text-field';
import {textAreaStyleDescription, textAreaStyleTextField} from '../../../styles/metrics.stylesheet';
import {DataPointType, Factor, Metric, SimulationFactor} from '../../../../types/metrics';
import UnitsContainer from './UnitsContainer';
import {ErrorS} from "@weave-mui/icons-weave";
import {
  errorIconStyles,
  maximumCharactersErrorStyles
} from "../../../../analysis/EmbodiedCarbon/AddECDefinition/ECDefinition.stylesheet";
import Typography, {typographyVariants} from "@weave-mui/typography";
import {
  isLongerThanAllowed,
  TEXT_INPUT_CHARACTERS_LIMIT
} from "../../../../analysis/EmbodiedCarbon/AddECDefinition/helper";
import {containsSpecialCharacters, DESCRIPTION_INPUT_CHARACTERS_LIMIT} from "../../../utils/dataPointsUtils";

interface DataPointsBasicDetails {
  isReadOnly: boolean;
  setCreateDataPoint: Dispatch<SetStateAction<Metric | Factor>>;
  selectedDataPoint: Metric | Factor | undefined;
  originalSelectedDataPoint: Metric | Factor | undefined;
  imperialUnits: boolean;
  simulationFactors: SimulationFactor[];
}
const { BODY_REGULAR } = typographyVariants;

const DataPointsBasicDetails: React.FC<DataPointsBasicDetails> = ({
  isReadOnly,
  setCreateDataPoint,
  selectedDataPoint,
  originalSelectedDataPoint,
  imperialUnits,
  simulationFactors
}) => {

  const handleNameChange = useCallback((e) => {
    const { value } = e.target;
    setCreateDataPoint((prevState) => {
      return {
        ...prevState,
        name: value,
        displayName: value,
      };
    });
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setCreateDataPoint((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }, []);

  const containsSpecial = containsSpecialCharacters(selectedDataPoint.name);

  return (
    <Box sx={{
      height: 'auto',
      '& .Mui-disabled': {
        opacity: '0.8 !important',
      }
    }}>
      <Box sx={
        {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%'
        }
      }>
        <Box sx={
          {
            display: 'flex',
            flexDirection: 'column',
            width: '45%',
            pt: '0.5rem',
            gap: '1.5rem'
          }
        }>
          <FormControl sx={
            {
              display: 'flex',
              flexDirection: 'row',
              gap: '0.5rem'
            }
          }>
            <FormLabel
              sx={
                {
                  alignSelf: 'center',
                  p: '0px',
                  width: '25%'
                }
              }
              variant={formLabelVariants.SIDE}
            >
              {`${i18n.t('analysis.dataPoints.labels.name')} *`}
            </FormLabel>
            <Box  sx={
              {
              display: 'flex',
              flexDirection: 'column',
              width: '100%'
            }
            }>
              <Box className={'inputWithError'}>
                <TextField
                  name="name"
                  id="metric-name-input"
                  placeholder={i18n.t('analysis.dataPoints.placeholders.name')}
                  disabled={isReadOnly || selectedDataPoint.isGlobal}
                  onChange={handleNameChange}
                  sx={{...textAreaStyleTextField}}
                  error={Boolean(isLongerThanAllowed(selectedDataPoint.name, TEXT_INPUT_CHARACTERS_LIMIT) || containsSpecial)}
                  value={
                    selectedDataPoint?.displayName.length > 0
                      ? selectedDataPoint?.displayName
                      : selectedDataPoint?.name
                  }
                />
              </Box>
              <div className={'errorMessageAndIcon'}>
                {isLongerThanAllowed(selectedDataPoint.name, TEXT_INPUT_CHARACTERS_LIMIT) && <ErrorS sx={{...errorIconStyles}}/>}
                {isLongerThanAllowed(selectedDataPoint.name, TEXT_INPUT_CHARACTERS_LIMIT) && (
                  <Typography variant={BODY_REGULAR} sx={{...maximumCharactersErrorStyles}}>
                    {`${i18n.t('analysis.ec.ecDefinition.components.maximumCharactersErrorMessage')} ${
                      selectedDataPoint.name.length - TEXT_INPUT_CHARACTERS_LIMIT
                    }`}
                  </Typography>
                )}
                {containsSpecial &&
                  <div className={'specialCharactersError'}>
                    <ErrorS sx={{...errorIconStyles}}/>
                    <Typography variant={BODY_REGULAR} sx={{...maximumCharactersErrorStyles}}>
                      {`${i18n.t('analysis.dataPoints.metrics.specialCharactersErrorMessage')}
                      `}
                    </Typography>
                  </div>}
              </div>
            </Box>
          </FormControl>
          <FormControl sx={
            {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'left',
              justifyContent: 'center',
              gap: '0.5rem'
            }
          }>
            <FormLabel
              sx={{alignSelf: 'normal', p: '0px', width: '25%'}}
              variant={formLabelVariants.SIDE}
            >
              {`${i18n.t('analysis.dataPoints.labels.description')}`}
            </FormLabel>
            <Box sx={
              {
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }
            }>
              <Box className={'inputWithError'}>
                <TextField
                  name="description"
                  id="metric-description-input"
                  placeholder={
                    selectedDataPoint?.type === DataPointType.Metric
                    ? i18n.t('analysis.dataPoints.metrics.descriptionPlaceholder')
                    : i18n.t('analysis.dataPoints.factors.descriptionPlaceholder')
                  }
                  multiline
                  sx={{...textAreaStyleDescription}}
                  disabled={isReadOnly || selectedDataPoint.isGlobal}
                  onChange={handleInputChange}
                  error={Boolean(isLongerThanAllowed(selectedDataPoint.description, DESCRIPTION_INPUT_CHARACTERS_LIMIT))}
                  variant={textFieldVariants.OUTLINED}
                  value={selectedDataPoint?.description ?? ''}
                />
              </Box>
              <div className={'errorMessageAndIcon'}>
                {isLongerThanAllowed(selectedDataPoint.description, DESCRIPTION_INPUT_CHARACTERS_LIMIT) && <ErrorS sx={{...errorIconStyles}}/>}
                {isLongerThanAllowed(selectedDataPoint.description, DESCRIPTION_INPUT_CHARACTERS_LIMIT) && (
                  <Typography variant={BODY_REGULAR} sx={{...maximumCharactersErrorStyles}}>
                    {`${i18n.t('analysis.ec.ecDefinition.components.maximumCharactersErrorMessage')} ${
                      selectedDataPoint.description.length - DESCRIPTION_INPUT_CHARACTERS_LIMIT
                    }`}
                  </Typography>
                )}
              </div>
            </Box>
          </FormControl>
        </Box>
        <Box sx={
          {
            display: 'flex',
            flexDirection: 'column',
            width: '45%',
            pt: '0.5rem',
            pr: '2rem',
            gap: '1rem'
          }
        }>
          <UnitsContainer
            isReadOnly={isReadOnly}
            selectedDataPoint={selectedDataPoint}
            setCreateDataPoint={setCreateDataPoint}
            useImperial={imperialUnits}
            simulationFactors={simulationFactors}
          />
        </Box>
      </Box>
    </Box>
)
}

export default DataPointsBasicDetails
