import React from 'react';
import { ErrorS } from '@weave-mui/icons-weave';
import Typography, { typographyVariants } from '@weave-mui/typography';
import i18n from '../../../i18n';
import {
  cardErrorContainerStyles,
  cardStateContentAlignment,
  cardStateFonts,
  cardErrorIconPadding,
  cardErrorInnerContentStyles,
} from './Card.stylesheet';

export const CardErrorState: React.FC = () => {
  return (
    <div style={cardErrorContainerStyles}>
      <div style={cardErrorInnerContentStyles}>
        <ErrorS htmlColor="#DD2222" style={cardErrorIconPadding} />
      </div>
      <div style={cardStateContentAlignment}>
        <Typography sx={cardStateFonts} variant={typographyVariants.BODY_REGULAR}>
          {i18n.t('cards.state.errorStateText')}
        </Typography>
      </div>
    </div>
  );
};
