import React from 'react';

export default function CustomTabRenderer(props) {

    const {
        active,
        icon,
        label,
        ...otherProps
    } = props;

    const {
        onMouseEnter,
        onMouseLeave,
        handleClick,
        handleKeyDown
    } = otherProps;

    let tabClasses = "analysis-tab-control-tab";

    if (active) {
        tabClasses += " active";
    }

    return (
        <li className={tabClasses}>
            <div
                role="button"
                className="analysis-tab-control-tab-button-wrapper"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={handleClick}
                onKeyDown={handleKeyDown}
            >
                <div className="analysis-tab-control-tab-button-content">
                    <span>{icon}</span>
                    <p>{label}</p>
                </div>
                <div className="analysis-tab-control-tab-halo">
                </div>
            </div>
        </li>
    );
}
