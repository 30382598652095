import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseQuery, QueryType } from './base-queries';
import { getBase64 } from "../../helpers/jsHelper";
import { GET } from './api-constants';

export const thumbnailDataApi = createApi({
  reducerPath: 'thumbnailDataApi',
  baseQuery: BaseQuery(QueryType.Forge),
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getThumbnail: builder.query<Response, { urn: string, viewerToken: string }>({
      query: (body: { urn, viewerToken }) => ({
        url: `/modelderivative/v2/designdata/${body.urn}/thumbnail?width=200&height=200`,
        method: GET,
        credentials: 'include',
        headers: {
          Accept: 'image/png',
          Authorization: `Bearer ${body.viewerToken}`
        },
        responseHandler: async (response) => {
          if (response.status !== 200) {
            throw new Error(`Request failed with status: ${response.status}`);
          }
          const blob = await response.blob();
          const base64 = await getBase64(blob);
          return { base64 };
        },
      }),
    }),
  }),
});

export const { useGetThumbnailQuery } = thumbnailDataApi;
