/**
 * This file contains three objects in charge of storing the analyses with pending result from the backend:
 * pendingResults
 * modelQueries
 * ECDataQueries
 * Also, contains the methods to remove the model from these objects and stop the recurring function that request the status
 */

export const pendingResults: {
    /**
     * Key-value pair: key is the modelId
     */
    [key: number]: {
        /**
         * Stores the value returned by setInterval and is used to clear it.
         */
        intervalId: NodeJS.Timeout,
        projectId: string,
        /**
         * Simulations Ids
         */
        ids: string[]
    }
} = {};


export const ECDataQueries: {
    /**
     * Key-value pair: key is the modelId
     */
     [key: number]: {
        /**
         * Stores the value returned by setInterval and is used to clear it.
         */
        intervalId: NodeJS.Timeout,
        projectId: string

    }
} = {};

export const modelQueries: {
    /**
     * Key-value pair: key is the modelId
     */
    [key: number]: {
        /**
         * Stores the value returned by setInterval and is used to clear it.
         */
        intervalId: NodeJS.Timeout,
        projectId: string
    }
} = {};

const removeFromPendingResults = (modelId: string | string) => {
    if (pendingResults && pendingResults[modelId]) {
        clearInterval(pendingResults[modelId].intervalId);
        delete pendingResults[modelId];
    }
}

const removeFromModelQueries = (modelId: string | string) => {
    if (modelQueries && modelQueries[modelId]) {
        clearInterval(modelQueries[modelId].intervalId);
        delete modelQueries[modelId];
    }
}

export const removeFromEcDataQueries = (modelId: string | string) => {
    if (ECDataQueries && ECDataQueries[modelId]) {
        clearInterval(ECDataQueries[modelId].intervalId);
        delete ECDataQueries[modelId]
    }
};

export const removeMultipleECModelsFromQueries = (modelIds: string[]) => {
    if (modelIds.length > 0) {
        modelIds.forEach((modelId: string) => removeFromEcDataQueries(modelId));
    }
};

/**
 * This method removes an model from the status verification process in the UI.
 *
 * @remarks
 * This prevents the UI from continuing to request status in an infinite loop
 *
 * @param modelId
 * @returns
 *
 */
export const removeModelFromStatusVerification = (modelId: string | string) => {
    removeFromPendingResults(modelId);
    removeFromModelQueries(modelId);
    removeFromEcDataQueries(modelId);
}

/**
 * This method removes all analyzes of a project from the status verification process in the UI.
 *
 * @remarks
 * This prevents the UI from continuing to request status in an infinite loop
 *
 * @param projectId 
 * @returns
 *
 */
export const removeProjectAnalysesFromStatusVerification = (id: string) => {
    let pendingResultsToDelete = Object.keys(pendingResults).filter(key => pendingResults[key].projectId === id);
    let pendingModelQueriesToDelete = Object.keys(modelQueries).filter(key => modelQueries[key].projectId === id);
    pendingResultsToDelete.forEach((modelId) => {
        removeFromPendingResults(modelId);
    });
    pendingModelQueriesToDelete.forEach((modelId) => {
        removeFromModelQueries(modelId);
    });
}