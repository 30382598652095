export const configurationModalStyles = {
  zIndex: 10000,
  '& .MuiModalWeave-box': {
    width: '80%',
    height: '100%',
    maxHeight:'calc(100vh - 20px - 20px);'
  },

  '& .MuiModalWeave-content': {
    maxHeight: 'initial',
    paddingBottom: '0',
    height: '100%'
  },
  '& .MuiModalWeave-child': {
    height: '100%'
  },

  '.MuiModalWeave-content-text': {
    marginTop: '4px',
  },

  '& .MuiModalWeave-content-text': {
    overflow:'inherit',
  },

  '& .MuiModalWeave-wrapper': {
    justifyContent: 'space-between',
  },
};

export const addECDefinitionModalStyles = {
  '& .MuiModalWeave-box': {
    width: '55%',
    height: '85%',
  },
  '& .MuiModalWeave-content': {
    maxHeight: 'initial',
    overflowY: 'auto',
  },
  '& .MuiModalWeave-wrapper': {
    justifyContent: 'space-between',
  },
};

const defaultAdvancedSearchModalTypographyStyle = {
  fontSize: '16px',
  lineHeight: '22px',
  letterSpacing: '0px',
  textAlign: 'left',
  fontFamily: 'Artifakt Element, sans-serif',
};

export const advancedSearchModalTitleMaterial = (styles) => {
  return {
    ...styles,
    typography: {
      ...styles.typography,
      ...defaultAdvancedSearchModalTypographyStyle,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '55vw',
    },
  };
};
