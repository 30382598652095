export type ILmvSurfaceType = {
    Air: string,
    Ceiling: string,
    ExteriorWall: string,
    FixedSkylight: string,
    FixedWindow: string,
    InteriorFloor: string,
    InteriorWall: string,
    NonSlidingDoor: string,
    OperableSkylight: string,
    OperableWindow: string,
    RaisedFloor: string,
    Roof: string,
    Shade: string,
    SlabOnGrade: string,
    SlidingDoor: string,
    UndergroundCeiling: string,
    UndergroundSlab: string,
    UndergroundWall: string,
    DefaultColor: string,
    Underground: string,
    Window: string,
    Skylight: string,
    Door: string,
    Collumn: string,
    Space: string,
    CampusShell: string,
    BuildingShell: string,
}


export const lmvSurfaceColor: ILmvSurfaceType = {
    Air: "rgb(190, 210, 220)",
    Ceiling: "rgb(191, 178, 255)",
    ExteriorWall: "rgb(133, 178, 44)",
    FixedSkylight: "rgb(178, 229, 255)",
    FixedWindow: "rgb(178, 229, 255)",
    InteriorFloor: "rgb(101, 81, 204)",
    InteriorWall: "rgb(195, 229, 126)",
    NonSlidingDoor: "rgb(225, 178, 178)",
    OperableSkylight: "rgb(178, 229, 255)",
    OperableWindow: "rgb(178, 229, 255)",
    RaisedFloor: "rgb(101, 81, 204)",
    Roof: "rgb(38, 15, 153)",
    Shade: "rgb(144, 146, 138)",
    SlabOnGrade: "rgb(101, 81, 204)",
    SlidingDoor: "rgb(255, 178, 178)",
    UndergroundCeiling: "rgb(178, 111, 44)",
    UndergroundSlab: "rgb(178, 111, 44)",
    UndergroundWall: "rgb(178, 111, 44)",
    DefaultColor: "rgb(144, 146, 138)",
    Underground: "rgb(178, 111, 44)",
    Window: "rgb(178, 229, 255)",
    Skylight: "rgb(178, 229, 255)",
    Door: "rgb(178, 229, 255)",
    Collumn: "rgb(195, 229, 126)",
    Space: "rgb(0, 145, 201)",
    CampusShell: "rgb(0, 100, 160)",
    BuildingShell: "rgb(0, 100, 160)"
}

/**
 * 
 * @param viewer the current lmv viewer instance
 * @param dbId id of lmv element to be colored
 * @param color rgb color string
 * @param model current lmv model
 */
export const setThemingColor = (viewer: Autodesk.Viewing.Viewer3D, dbId: number, color: string, model: Autodesk.Viewing.Model) => {
    let threeColor = new THREE.Color(color);
    viewer.setThemingColor(dbId, new THREE.Vector4(threeColor.r, threeColor.g, threeColor.b, 1), model, true);
}
/**
 * 
 * @param viewer the current lmv viewer instance
 * @param dbId id of lmv element to be colored
 */
export const showViewerDbid = (viewer: Autodesk.Viewing.Viewer3D, dbId: number) => {
    viewer.impl.visibilityManager.show(dbId);
}
/**
 * 
 * @param surface surface type to be colored
 */
export const getSurfaceColorString = (surface: string) => {
    let color = lmvSurfaceColor[surface];
    return color ?? lmvSurfaceColor["DefaultColor"];
}

/**
 * 
 * @param viewer the current lmv viewer instance
 */
export const setFullIsolation = (viewer: Autodesk.Viewing.Viewer3D) => {
    //var model = viewer.model;           
    viewer.impl.visibilityManager.isolate(-1);
}
/**
 * 
 * @param viewer the current lmv viewer instance
 */
export const clearThemingColors = (viewer: Autodesk.Viewing.Viewer3D) => {
    viewer.clearThemingColors(viewer.model);
}
/**
 * 
 * @param viewer the current lmv viewer instance
 */
export const triggerSceneChange = (viewer: Autodesk.Viewing.Viewer3D) => {
    viewer.impl.invalidate(true);
}