import React, { useEffect, useRef } from 'react';
import '../css/leftModelsSelector.css';
import { CaretRightS, HomeS } from '@weave-mui/icons-weave';
import { NavLink } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import ProgressRing from '@hig/progress-ring';
import { Model } from '../analysis/dashboard.models';
import { OverflowContent } from '../analysis/components/OverflowContent';
import { useGetProjectModelsQuery, useGetProjectQuery } from '../state/api/project-model-data-api';
import { useCurrentThumbnailData } from '../layout/hooks/useCurrentThumbnailData';
import LeftToggle from '../img/toggle_left_default.svg';
import RightToggle from '../img/toggle_right_default.svg';

export type leftModelsSelectorProp = {
  modelId: string,
  projectId: string,
  isUnauthorisedAccess?: boolean,
  isCollapsed?: boolean,
  collapseCallback?: Function,
  onModelChange?: Function,
}

export type LeftModelSelectorWidgetProps = {
  modelIdSelected: string,
  model: Model,
  onModelChange?: Function
}

const caretRightIconStyles = {
  color: '#808080',
  fontSize: '10px',
  height: '10px',
  marginRight: '4px',
  width: '10px'
};
const LeftModelsSelector: React.FC<leftModelsSelectorProp> = (props) => {
  // Redux Toolkit (Queries/Mutations)
  const { data: projectData } = useGetProjectQuery(props.projectId)
  const { data: projectModelsData } = useGetProjectModelsQuery(props.projectId)

  const onCollapseClick = () => {
    props.collapseCallback();
  }

  const renderModels = () => {
    return projectModelsData?.map((model) => {
      return <LeftModelSelectorWidget
        modelIdSelected={props.modelId}
        model={model}
        onModelChange={props.onModelChange}
      />
    });
  }

  const { isCollapsed } = props;
  let footerClass = "left-panel-footer";
  let leftPanelClass = "main-left-panel";
  if (isCollapsed) {
    footerClass = `${footerClass} collapsed`;
    leftPanelClass = `${leftPanelClass} collapsed`;
  }

  return (
    <div className={leftPanelClass}>
      <div className="d-flex align-items-center left-panel-header">
        <NavLink className="left-panel-navlink"
          to="/projects"
          tag={RRNavLink}>
          <HomeS htmlColor='#808080' />
        </NavLink>
        {!isCollapsed && <CaretRightS sx={ caretRightIconStyles }/>}
        {!isCollapsed &&
          <div className="model-widget-dropdown">
            {projectData?.name}
          </div>
        }
      </div>
      {!isCollapsed &&
        <div className="left-panel-models-container">
          {renderModels()}
        </div>
      }

      <div className={footerClass}>
        <div className="left-panel-footer-divider" />

        <div className="collapse-container">
          <div className="lmv-legend-toggle-icon" onClick={onCollapseClick} >{isCollapsed ? <RightToggle/>: <LeftToggle/>}</div>
        </div>
      </div>
    </div>
  );
}

export default LeftModelsSelector;

const LeftModelSelectorWidget: React.FC<LeftModelSelectorWidgetProps> = (props) => {
  const selectedModelRef = useRef<HTMLDivElement>(null);
  const { id, lmvStatus, urn } = props?.model;
  let thumbnailClassName = "left-panel-model-thumbnail";
  let isSelected = false;

  const { data: thumbnail } = useCurrentThumbnailData(urn, lmvStatus);
  const imgObject = thumbnail?.base64;


  if (id === props.modelIdSelected) {
    thumbnailClassName += " selected";
    isSelected = true;
  }

  useEffect(() => {
    if (selectedModelRef && selectedModelRef.current) {
      selectedModelRef.current.focus();
    }
  }, []);

  return (
    <div key={id}
      tabIndex={0}
      ref={isSelected ? selectedModelRef : null}
      className={thumbnailClassName}
      onClick={(e) => props.onModelChange(props.model)}>
      {imgObject ?
        (<img src={imgObject} />) :
        (<div className="left-panel-model-thumbnail-progress-ring">
          <ProgressRing />
        </div>)}
      <OverflowContent value={props.model.name.toString()} />
    </div>
  );
}
