/**
 * Build EC Model For LMV
 * */
import {LmvLegendItems, LmvLegendViewByOptions} from "../types";
import {validatePreference} from "../ECDetails"
import {getMeshGeometry, getViewerInstance} from "./LmvEcDetails";

/**
 * LMV Properties
 * */
type IModelProperties = {
    atttributeName: string,
    displayCategory: string,
    displayName: string,
    displayValue: string,
    hidden: number,
    precision: number,
    type: number,
    units: object
}
/**
 * LMV Object details
 * */
export type IModelObject = {
    dbId: number,
    externalId: number,
    name: string,
    properties: Array<IModelProperties>
}
/**
 * LMV elements to
 *
 * */
export type ILMVToLegend = {
    lmvElementId: number,
    property: IModelProperties
}
/**
* Associate colors with LMV ELementIDs
* **/
export type ILMVColorMapping = {
    lmvElementIds: Array<number>,
    color: string
}

/**
 * Group Array of LMV Elements to Associated construction legend
 * */
export const MapLMVToConstructionMap = new Map<string, Array<ILMVToLegend>>();
/**
 * Group LMV Elements to Associated surface Legend
 * **/
/**
 * Group LMV Elements to Associated Detail Level Legend
 * **/
export const MapLMVToDetailMap = new Map<string, Array<ILMVToLegend>>();
export const MapLMVToCategoryMap: Map<string, ILMVToLegend[]> = new Map<string, ILMVToLegend[]>();
/**
 * LMV elements in EC Land
 * **/
export const LMVViewerModel = new Array<IModelObject>();

/**
 * Map of each viewby with legend items
 * **/
export const LMVLegendMap = new Map<LmvLegendViewByOptions, Array<LmvLegendItems>>();

/**
 * Build EC Model  for LMV this takes in list of LMV ELements and returns a default
 * */
export const BuildECModel = async (e: any, DBId: Array<number>, modelId: string): Promise<Array<ILMVColorMapping>> => {
    return await processLMVElements(e, DBId).then(() => BuildLegendModel(modelId))
}
/**
* @param lmvLegendItems Add LMVlegend Items for particular viewby
 * @param viewBYType  Add ViewBy type for the  map
* **/
export const SetLMVLegend = (lmvLegendItems: Array<LmvLegendItems>, viewBYType: LmvLegendViewByOptions) => {
    LMVLegendMap.set(viewBYType, lmvLegendItems);
}

/**
 * @return Promise<Array<ILMVColorMapping>>
 * **/
export const BuildLegendModel = (modelId: string): Promise<Array<ILMVColorMapping>> => {
    if (LMVLegendMap.size == 0) {
        return Promise.resolve(null);
    }
    let viewByConstructiontems: LmvLegendItems[] = LMVLegendMap.get(LmvLegendViewByOptions.Constructions);
    let viewByCategoryTypeItems: LmvLegendItems[] = LMVLegendMap.get(LmvLegendViewByOptions.Categories);

    MapLMVToConstructionMap.clear();
    MapLMVToDetailMap.clear();
    MapLMVToCategoryMap.clear();

  return new Promise((Resolve) => {
    const lmvElements = LMVViewerModel.map(x => x.name);

    //Elements are mapped according to the construction Id of the viewerElements object
    viewByConstructiontems.map((constructionItem) => {
      constructionItem.viewerElements.map(element => {
        element.surfaceIds.map(surfId => {
          if (lmvElements.includes(surfId)) {
            const index = lmvElements.indexOf(surfId);
            const elem = LMVViewerModel[index];

            if (MapLMVToConstructionMap.has(element.id)) {
              let list = MapLMVToConstructionMap.get(element.id);
              list.push({lmvElementId: elem.dbId, property: null});
              MapLMVToConstructionMap.set(element.id, list);
              MapLMVToDetailMap.set(element.id, list);
            } else {
              let val: ILMVToLegend = {
                lmvElementId: elem.dbId,
                property: null
              };
              MapLMVToConstructionMap.set(element.id, new Array(val));
              MapLMVToDetailMap.set(element.id, new Array(val));
            }
          }
        });
      });
    });

    viewByCategoryTypeItems.map(c => {
      c.viewerElements.map(element => {
        element.surfaceIds.map(surfId => {
          if (lmvElements.includes(surfId)) {
            const index = lmvElements.indexOf(surfId);
            const elem = LMVViewerModel[index];
            if (MapLMVToCategoryMap.has(surfId)) {
              let list = MapLMVToCategoryMap.get(surfId);
              list.push({lmvElementId: elem.dbId, property: null});
              MapLMVToCategoryMap.set(surfId, list);
            } else {
              let val: ILMVToLegend = {
                lmvElementId: elem.dbId,
                property: null
              };
              MapLMVToCategoryMap.set(surfId, new Array(val));
            }
          }
        });
      });
    });

    const ecDetailsPreference = validatePreference(modelId);
    Resolve(colorElements(ecDetailsPreference.viewBy));
  });
}

/**
 * @param viewBy LmvLegendViewByOptions Enum eg construcions
 * @return Array<ILMVColorMapping> Maps LMV ELemnets to color ID
 * **/
export const colorElements = (viewBy: LmvLegendViewByOptions): Array<ILMVColorMapping> => {
    let colorArray = new Array<ILMVColorMapping>();
    const modelInstanceTree = getViewerInstance()?.model?.getInstanceTree();

    LMVLegendMap.get(viewBy).forEach((item) => {
        item.viewerElements.forEach( element => {
            if (element.id == null || element.id.includes('unknown') || element.isHidden === true)
                return;

            switch (viewBy)
            {
                case LmvLegendViewByOptions.Constructions:
                    if (MapLMVToConstructionMap.get(element.id)) {
                        colorArray.push({
                            color: item.color,
                            lmvElementIds: MapLMVToConstructionMap.get(element.id).map((lmvItem) => getMeshGeometry(modelInstanceTree, lmvItem))
                        })
                    }
                    break;
                case LmvLegendViewByOptions.DetailLevel:
                    if (MapLMVToDetailMap.get(element.id)) {
                        colorArray.push({
                            color: item.color,
                            lmvElementIds: MapLMVToDetailMap.get(element.id).map((lmvItem) => getMeshGeometry(modelInstanceTree, lmvItem))
                        })
                    }
                    break;
              case LmvLegendViewByOptions.Categories:
                element.surfaceIds.forEach(surfId => {
                  if (MapLMVToCategoryMap.get(surfId)) {
                    colorArray.push({
                      color: item.color,
                      lmvElementIds: MapLMVToCategoryMap.get(surfId).map((lmvItem) => getMeshGeometry(modelInstanceTree, lmvItem))
                    });
                  }
                });
                break;
            }
        });
    });
    return colorArray;
}

/**
 * @param e Viewing  Event
 * @param DBId Array of LMV Elements
 * @return Promise<Array<IModelObject>> promise object of IModelObject
 * */

const processLMVElements = async (e: any, DBId: Array<number>): Promise<Array<IModelObject>> => {
    return new Promise((resolve, reject) => {
        DBId.forEach((id: number, index: number) => {
            (e.model as Autodesk.Viewing.Model).getProperties(id, (result: Autodesk.Viewing.PropertyResult) => {
                LMVViewerModel.push((result as unknown) as IModelObject);
                if (index == DBId.length - 1) {
                    return resolve(LMVViewerModel);
                }
            }, (error: any) => {
                console.log(error);
            })
        })
    })
}
