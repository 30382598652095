import React, { useEffect, useRef } from "react";
import Typography from "@hig/typography";
import {useTopLevelTooltipManipulator} from "./TopLevelTooltip";

const elementContentStyle = (styles, options: OverflowCellOptions) => ({
    ...styles,
    typography: {
        ...styles.typography,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%',
        fontWeight: 'inherit',
        ...(options.align ? { textAlign: options.align } : {}),
        ...(options.lineClamp && options.lineClamp >= '2'
            ? {
                '-webkit-line-clamp': options.lineClamp,
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                whiteSpace: 'normal',
                wordWrap: 'break-word'
            }
            : {
                whiteSpace: 'nowrap'
            })
    }
});

interface ICoordinates {
    coordinates: {
        x: number,
        y: number,
        pointer: number
    }
}

interface IOverflowContentConsumer {
    value: string | number | JSX.Element,
    coordinates: ICoordinates | {}
    hoverDelay: number,
    isHover: boolean
}
export const OverflowContentConsumer = ({value, coordinates, hoverDelay, isHover}: IOverflowContentConsumer) => {
    const {showTooltip, hideTooltip}: any = useTopLevelTooltipManipulator();
    const focusTimeout = React.useRef(null);

    useEffect(() => {
        if (isHover) {
            focusTimeout.current = setTimeout(() => {
                showTooltip({
                    content: value,
                    coordinates
                })
            }, hoverDelay)
        } else {
            clearTimeout(focusTimeout.current)
            setTimeout(() => {
                hideTooltip();
            }, 100);
        }
    }, [isHover])

    return null;
}

export type OverflowCellOptions = {
    lineClamp?: string,
    align?: string,
    hoverDelay?: number,
    isFlyout?: boolean
}

export type OverflowCellProps = {
    value: string | number | JSX.Element,
    options?: OverflowCellOptions
}

export const OverflowContent = (props: OverflowCellProps) => {
    const { value, options = {} } = props;
    const { hoverDelay = 500 } = options;

    const [isHover, setIsHover] = React.useState(false)
    const [currentPosition, setCurrentPosition] = React.useState({})

    const typographyRef = useRef(null);

    const onEnter = (e) => {
        if (e.target.scrollWidth > e.target.offsetWidth) {
            const client = e.target.getBoundingClientRect()

            setCurrentPosition({
                anchorPoint: "top-left",
                containerPosition: {
                    top: client.bottom + 10, // padding
                    left: client.left
                },
                pointerPosition: {
                    left: client.width / 2,
                    top: 0 // pointer position is relative to tooltip content, therefore it should always display at -5 when tooltip goes below
                },
                elementHeight: client.height + 5
            })
            setIsHover(true);
        }
    }
    const onLeave = () => {
        setIsHover(false);
    }

    useEffect(
        () => {
            const node = typographyRef.current;
            if (node) {
                node.addEventListener("mouseover", onEnter);
                node.addEventListener("mouseout", onLeave);
                return () => {
                    node.removeEventListener("mouseover", onEnter);
                    node.removeEventListener("mouseout", onLeave);
                };
            }
        },
        [typographyRef.current]
    );

    return (
        <>
            <OverflowContentConsumer
                value={value}
                coordinates={currentPosition}
                hoverDelay={hoverDelay}
                isHover={isHover}/>
            <Typography stylesheet={(styles) => elementContentStyle(styles, options)} typographyRef={typographyRef}>
                {value}
            </Typography>
        </>
    );
}
