import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import i18n from '../i18n';
import * as locConsts from "./localization/consts";
import ProgressBar from '@weave-design/progress-bar';
import axiosInstance from '../utils/axios';
import authClient from '../authClient';
import { throwInvalidFileError as onFileError } from '../project/actions';
import { getInsightApigeeBasePath } from '../shared/api';
import { useEcSimulationsMutation } from '../state/api/project-model-data-api';
import adpService from '../webAnalytics/adp';

type ModelUploaderProps = {
  accept: string,
  text: string,
  onCancel: () => void,
  projectId: string
  showPicturePanel: () => void;  
}

const ModelUploader: React.FC<ModelUploaderProps> = (props) => {
  let fileInputRef: any;
  const { accept, text, onCancel, projectId, showPicturePanel/*, onError*/ } = props;
  const dispatch = useDispatch();
  const [uploadMessage, setUploadMessage] = useState<string>(text);
  const [disabledUpload, setDisabledUpload] = useState<boolean>(false);
  const [displayProgressBar, setDisplayProgressBar] = useState<boolean>(false);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [file, setFile] = useState<File>();
  const [EcSimulationsMutation] = useEcSimulationsMutation();

  const onChange = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      let message = `${i18n.t(locConsts.PROJECT_WIDGET_SELECTED_MODEL)}${event.target.files[0].name}`;
      setUploadMessage(message);
      setFile(event.target.files[0]);
    } else {
      setFile(null);
    }
  }

  // Temporary implementation.
  // This implementation is enabled for development purposes only.This workflow will not be visible to users.
  const onModelUpload = async (file: File, projectId: string) => {
    let formData = new FormData();
    formData.append('file', file);
    try {
      const uploadResult = await axiosInstance.post(`${getInsightApigeeBasePath()}/api/v1/models`, formData, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + await authClient.getAccessToken()
        },
        onUploadProgress: (data) => {
          const progress = Math.round((100 * data.loaded) / data.total);
          setUploadPercentage((prevState) => {
            if (prevState === progress) {
              return prevState
            }
            else {
              return progress;
            }
          });
        }
      })

      if (uploadResult) {        
        await EcSimulationsMutation({ modelFileId: uploadResult.data.id, projectId: projectId });
        showPicturePanel();
      }
    } catch (err) {
      let message = 'Invalid gbXML Document';

      if (err?.response?.data?.detail === "InvalidXmlLongitudeValue") {
        message = "Longitude out of range";
      }
      else if (err?.response?.data?.detail === "InvalidXmlLatitudeValue") {
        message = "Latitude out of range";
      }
      else if (err?.response?.data?.detail === "UnableToVerifyLocationInMapService") {
        message = "Unable to verify location in Map Service";
      }

      dispatch(onFileError(err.response.data.errorCode ?? message));
      showPicturePanel();
    }
  }

  const onChooseFileClick = () => {
    fileInputRef.click();
  }

  const onUploadClick = () => {
    if (file) {
      setDisplayProgressBar((prevState) => {
        return true;
      });
      setDisabledUpload((prevState) => {
        return true;

      });
      onModelUpload(file, projectId);

      adpService.recordClickEvent(
        'model-upload-button',
        'button',
        'upload-button'
      );
    }
  }

  return (
    <div className="upload-img" style={{ display: 'block' }}>
      <span className="icon-delete" onClick={onCancel}></span>
      <input type="file" id="imageBrowse" accept={accept} ref={input => fileInputRef = input} onChange={onChange} />
      <div className="choose-file" id="chooseFile" onClick={onChooseFileClick}>Choose file</div>
      <p>{uploadMessage}</p>
      <div className="progress-bar">
        {displayProgressBar
          ? (
            <div className="progress-bar-container">
              <ProgressBar percentComplete={uploadPercentage} />
              <p className="percentage-text">{uploadPercentage}%</p>
            </div>
          ) : (<div className="empty-progress-bar"></div>)
        }
      </div>
      <div className="button-group">
        <input type="button" value={i18n.t(locConsts.PROJECT_WIDGET_CANCEL) as any} id="cancel" className="btn cancel" onClick={onCancel} />
        <input type="submit" disabled={disabledUpload} value={i18n.t(locConsts.PROJECT_WIDGET_UPLOAD) as any} id="submit" className={`btn submit ${disabledUpload && 'disabled'}`} onClick={onUploadClick} />
      </div>
    </div>
  );
}

export default ModelUploader;
