import { memo, useCallback, useMemo, useRef, useState } from 'react';
import Box from '@weave-mui/box';
import { Button } from '@weave-mui/button';
import { Switch } from '@weave-mui/switch';
import { Typography } from '@weave-mui/typography';
import React from 'react';
import { UserConsentModal } from './user-consent-modal';
import adpService, { AdpUserConsentPreferences } from '../webAnalytics/adp';
import { footerButtonStyle } from '../layout/LayoutGrid.stylesheet';

export type AdpUserConsentModalProps = {
  open: boolean;
  userConsentPreferences?: AdpUserConsentPreferences;
  onClose?: () => void;
}

interface SwitchProps {
  checked?: boolean;
  readOnly?: boolean;
  consentName: string;
  consentText: string;
  consentDesc: string;
  consentId: string;
  onChange: (id: string, checked: boolean) => void;
}

interface PreferenceBoxProps {
  consentName: string;
  consentText: string;
  consentDesc: string;
}

const PreferenceBox = memo<PreferenceBoxProps>(({ consentName, consentText, consentDesc }) => {
  return (
    <Box gap={1} flex={1} display="flex" flexDirection="column">
      <Typography variant="body1Bold">{consentName}</Typography>
      {consentText && <Typography>{consentText}</Typography>}
      {consentDesc && <Typography>{consentDesc}</Typography>}
    </Box>
  );
});

const PreferenceCheckbox = memo<SwitchProps>(
  ({ checked, readOnly, consentName, consentText, consentDesc, consentId, onChange }) => {
    return (
      <Box display="flex" flexDirection="row" gap={1} alignItems="flex-start">
        <Switch
          checked={checked}
          disabled={readOnly}
          color={readOnly ? 'secondary' : 'primary'}
          name={consentId}
          onChange={(ev) => {
            if (readOnly) return;
            onChange(consentId, ev.target.checked);
          }}
          sx={{ width: '40px' }}
        ></Switch>
        <PreferenceBox
          consentName={consentName}
          consentText={consentText}
          consentDesc={consentDesc}
        ></PreferenceBox>
      </Box>
    );
  }
);

export const AdpUserConsentModal: React.FC<AdpUserConsentModalProps> = (props) => {
  const userConsentPreferences = props.userConsentPreferences

  const initialCheckedState = useMemo(() => {
    const state = {};
    userConsentPreferences?.userConsentPreferences.forEach((x) => {
      state[x.consentId] = x.optIn;
    });
    return state;
  }, []);

  const [checkedState, setCheckedState] = useState(initialCheckedState);

  const formRef = useRef<HTMLFormElement>();

  const handleSelectAll = useCallback(() => {
    const consentCopy = userConsentPreferences?.userConsentCopy?.copies;
    setCheckedState((prev) => {
      Object.keys(prev).forEach((key) => {
        if (consentCopy.find(x => x.consentId === key)?.enabled) {
          prev[key] = true;
        }
      });
      return { ...prev };
    });
  }, []);

  const handleSubmit = useCallback(() => {
    const data = new FormData(formRef.current);
    adpService.setUserConsentPreferences(
      userConsentPreferences?.userConsentPreferences.map(({ consentId }) => {
        const optIn = data.get(consentId) === 'on';
        return { consentId, optIn };
      })
    );
    onCloseUserConsentModal()
  }, []);

  const modalActions = useMemo(() => {
    return userConsentPreferences?.userConsentCopy ? (
      <Box
        display="flex"
        justifyContent="end"
        gap={3}>
        <Button
          type="button"
          variant="outlined"
          onClick={handleSelectAll}
        >
          {userConsentPreferences?.userConsentCopy?.selectAllText}
        </Button>
        <Button sx={{ ...footerButtonStyle }} type="button" onClick={handleSubmit}>
          {userConsentPreferences?.userConsentCopy?.okButtonText}
        </Button>
      </Box>
    ) : null;
  }, []);

  const handleCheckboxChange = useCallback((consentId, isChecked) => {
    setCheckedState((prev) => {
      prev[consentId] = isChecked;
      return { ...prev };
    });
  }, []);

  const formInputs = useMemo(() => {
    return userConsentPreferences?.userConsentCopy?.copies
      .sort((prefA, prefB) => prefA.order - prefB.order)
      .map(({ consentName, consentText, consentDesc, consentId, prechecked, enabled }) => (
        <PreferenceCheckbox
          key={consentId}
          readOnly={!enabled}
          consentName={consentName}
          consentText={consentText}
          consentDesc={consentDesc}
          consentId={consentId}
          checked={checkedState[consentId] ?? prechecked}
          onChange={handleCheckboxChange}
        ></PreferenceCheckbox>
      ));
  }, [checkedState]);

  const typography = useMemo(
    () => (
      <>
        <Typography variant="body1Bold">
          {userConsentPreferences?.userConsentCopy?.subtitle}
        </Typography>
        <div
          // safe to do because the content is coming from a trusted source
          dangerouslySetInnerHTML={{ __html: userConsentPreferences?.userConsentCopy?.richText }}
          style={{
            margin: '0px',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: 'normal',
            fontFamily: 'ArtifaktElement',
            color: 'rgb(60, 60, 60)'
          }}
        ></div>
      </>
    ),
    []
  );

  const customContent = (
    <Box
      ref={formRef}
      component="form"
      gap={2}
      display="flex"
      flexDirection="column"
      textAlign="justify">
      {typography}
      {formInputs}
    </Box>
  );

  const onCloseUserConsentModal = () => {
    props.onClose();
  };

  return (
    <UserConsentModal
      open={props.open}
      header={userConsentPreferences?.userConsentCopy?.title}
      customActions={modalActions}
      customContent={customContent}
      onClose={() => onCloseUserConsentModal()}
      disableBackdropClickClose={true}
    >
      <></>
    </UserConsentModal>
  );
};
