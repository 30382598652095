import {addECDefinitionActions, IAddECDefinitionAction, IAddECDefinitionState} from "./addECDefinition.types";

export const initialECDefinition: IAddECDefinitionState = {
    name: '',
    coefficient: '',
    unit: {
        value: '',
        name: ''
    },
    source: '',
    notes: '',
    labels: []
};

export const ecDefinitionReducer = (state: IAddECDefinitionState, action: IAddECDefinitionAction) => {
    const { type, payload } = action;
    switch(type) {
        case addECDefinitionActions.setName:
            return {
                ...state,
                name: payload
            };
        case addECDefinitionActions.setCoefficient:
            return {
                ...state,
                coefficient: payload
            };
        case addECDefinitionActions.setUnit:
            return {
                ...state,
                unit: payload
            };
        case addECDefinitionActions.setSource:
            return {
                ...state,
                source: payload
            };
        case addECDefinitionActions.setNotes:
            return {
                ...state,
                notes: payload
            };
        case addECDefinitionActions.setLabels:
            const selectedLabel = payload;
            const selectedLabelIndex = state.labels.indexOf(selectedLabel);
            let updatedLabels: string[] = [];
            if (selectedLabelIndex < 0 ) {
                updatedLabels = [...state.labels, selectedLabel];

            } else {
                updatedLabels = [...state.labels.filter((_, i) => i !== selectedLabelIndex)];
            }
            return {
                ...state,
                labels: updatedLabels
            };
        case addECDefinitionActions.addLabel:
            const newLabels = [...state.labels, payload];
            return {
                ...state,
                labels: newLabels
            };
        case addECDefinitionActions.setSelectedECDefinition:
            return payload;
        case addECDefinitionActions.clearForm:
            return initialECDefinition;
        default:
            return state;
    };
};
