import {
  httpGet,
  httpPut,
  httpPostJson,
  httpDeleteJSON,
  getInsightApigeeBasePath,
} from '../../shared/api';
import { EcDefinition, MaterialEcDefinitionUpdate, SortedEcDefinitions } from './types';

export const getEcDefinitions = async (): Promise<SortedEcDefinitions> => {
  return httpGet<SortedEcDefinitions>(`${getInsightApigeeBasePath()}/api/v1/ecDefinitions`);
};

export const addECDefinitionAPI = async (newDefinition: any): Promise<EcDefinition[]> => {
  return (await httpPostJson(`${getInsightApigeeBasePath()}/api/v1/ecDefinitions`, {
    data: [newDefinition],
  })) as unknown as EcDefinition[];
};

export const editECDefinitionAPI = async (editDefinition: any): Promise<EcDefinition[]> => {
  return (await httpPut(`${getInsightApigeeBasePath()}/api/v1/ecDefinitions`, {
    data: [editDefinition],
  })) as unknown as EcDefinition[];
};

export const getEcResultForModelMaterialsAPI = async (
  modelId: string,
  materialIds: string[]
): Promise<MaterialEcDefinitionUpdate[]> => {
  return (await httpPostJson(`${getInsightApigeeBasePath()}/api/v1/model/${modelId}/materials`, {
    data: materialIds,
  })) as unknown as MaterialEcDefinitionUpdate[];
};

export const setEcDefinitionMap = async (
  modelId: string,
  ecDefinitionId: string,
  materialSha: string,
  isCustom: boolean
): Promise<MaterialEcDefinitionUpdate[]> => {
  const assignment = {
    MaterialSha: materialSha,
    EcDefinitionId: ecDefinitionId,
    IsCustomDefinition: isCustom,
  };

  return (await httpPut(
    `${getInsightApigeeBasePath()}/api/v1/model/${modelId}/materials/ecDefinitions`,
    { data: [assignment] }
  )) as unknown as MaterialEcDefinitionUpdate[];
};

export const deleteEcDefinitionAPI = async (ecDefinitionIds: string[]) => {
  await httpDeleteJSON(`${getInsightApigeeBasePath()}/api/v1/ecDefinitions`, {
    data: ecDefinitionIds,
  });
};
