export const SQFT_UNIT_DESCRIPTION = "SqftUnitDescription";
export const WWR_UNIT_DESCRIPTION = "WwrtUnitDescription";
export const ORIENTATION_UNIT_DESCRIPTION = "OrientationUnitDescription";
export const WS_UNIT_DESCRIPTION = "WSUnitDescription";
export const INFILTRATION_UNIT_DESCRIPTION = "InfiltrationUnitDescription";

export const EUI_SI_UNITS = "EuiSiUnits";
export const EUI_IP_UNITS = "EuiIpUnits";
export const COST_SI_UNITS = "CostSiUnits";
export const COST_IP_UNITS = "CostIpUnits";
export const ECKG_SI_UNITS = "ECKgSiUnits";
export const ECKG_IP_UNITS = "ECKgIpUnits";
export const ECMT_SI_UNITS = "ECMtSiUnits";
export const ECMT_IP_UNITS = "ECMtIpUnits";
export const POINT_VALUE_TOOLTIP_TEMPLATE = "PointValueTootlipTemplate";
export const INFILTRATION_UNIT_DESCRIPTION_SI = "InfiltrationUnitDescriptionSI";

export const SQM_UNIT_DESCRIPTION = "SqMUnitDescription";
