import { AxiosError } from 'axios';
import { ResilientHttpClient, IResilientHttpOptions } from '@adsk/resilient-axios-client';
import ErrorLogger from './errorLogger';
import { v4 as uuidv4 } from 'uuid';
import { setForceLogout } from './state/slice/notifications-slice';
import store from "./state/init-store";
export type AccessToken = {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
};

class AuthClient {
  private _currentAccessToken: AccessToken = null;
  private _refreshTokenLockName = `refresh-token-lock-${uuidv4()}`;
  private _currentAccessTokenExpires = 0;
  private _httpClient: ResilientHttpClient = null;
  private _logoutSyncChannel = new BroadcastChannel('logout-sync-channel');

  constructor() {
    this.setupClient();
    this.setupLogoutSynchChannels();
  }

  public exchangeAuthCodeForAccessToken = async (code: string): Promise<boolean> => {
    if (!code) {
      this.navigateToLogin('/');
      return false;
    } else {
      const response = await this._httpClient.get<AccessToken>(`/auth/gettoken?code=${code}`);
      this._currentAccessToken = response.data;
      this.setCurrentAccessTokenExpiry();
      return true;
    }
  };

  public getAccessToken = async (): Promise<string> => {
    try {
      // lock refresh token api call to prevent any race conditions where potentially
      // multiple attempts to refresh the same token are executed
      return await navigator.locks.request(this._refreshTokenLockName, async (lock) => {
        if (this.currentTokenRequiresRefresh()) {
          // The lock is held here.
          const response = await this._httpClient.post<AccessToken>('/auth/refreshtoken', {
            refreshToken: this._currentAccessToken.refresh_token,
          });
          this._currentAccessToken = response.data;
          this.setCurrentAccessTokenExpiry();
        }

        return this._currentAccessToken?.access_token;
        // The lock will be released now.
      });
    } catch (err) {
      ErrorLogger.logError(err);
    }
  };

  public navigateToLogin = (state: string): void => {
    window.location.href = `/auth/login?state=${encodeURIComponent(state)}`;
  };

  public navigateToLogout = (): void => {
    this._logoutSyncChannel.postMessage('user_logged_out');
    window.location.href = `/auth/logout`;
  };

  private currentTokenRequiresRefresh = (): boolean => {
    return (
      this._currentAccessToken?.refresh_token &&
      this._currentAccessTokenExpires &&
      this._currentAccessTokenExpires <= Date.now()
    );
  };

  private setCurrentAccessTokenExpiry = (): void => {
    const tokenExpiresInMs = this._currentAccessToken.expires_in * 1000 - 120000;
    this._currentAccessTokenExpires = Math.floor(Date.now() + tokenExpiresInMs);
  };

  private setupClient(): void {
    const resilientHttpOptions: IResilientHttpOptions = {
      timeout: 60000,
      baseURL: '',
      logError: (error: AxiosError) => ErrorLogger.logError(error),
      resilienceOptions: {
        retries: 3,
      },
    };

    this._httpClient = new ResilientHttpClient(resilientHttpOptions);
  }

  private setupLogoutSynchChannels() {
    this._logoutSyncChannel.onmessage = (msg) => {
      if (msg.data === 'user_logged_out') {
        store.dispatch(setForceLogout(true));
      }
    };
  }
}
const authClient = new AuthClient();
export default authClient;
