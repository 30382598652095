import React, { useCallback, useEffect, useState } from 'react'
import { ParametersModalType } from '../../../../types/metrics';
import { closeModalReasons } from '../../../../utils/constants';
import WeaveModalDialog from '../../../../shared/WeaveModalDialog';
import { dataPointsModalStyles } from '../../../styles/metrics.stylesheet';
import i18n from '../../../../i18n';
import DataPointsModal from './DataPointsModal';

interface DataPointsModalWrapperProps {
  isOpen: boolean;
  modalType: ParametersModalType;
  setModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

const DataPointsModalWrapper: React.FC<DataPointsModalWrapperProps> = ({
  isOpen,
  modalType,
  setModalVisibility
}) => {
  const [modifiedDataPoint, setModifiedDataPoint] = useState<boolean>(false);
  const closeModal = useCallback(() => {
    setModalVisibility(false)
  }, []);

  useEffect(() => {
    const handleCloseOnBackButton = () => {
      if (isOpen) {
        closeModal();
      }
      window.history.pushState({ modalClosed: true}, '');
    }

    window.addEventListener('popstate', handleCloseOnBackButton);

    return () => {
      window.removeEventListener('popstate', handleCloseOnBackButton);
    }
  }, [isOpen]);

  const closeHandler = useCallback((_: {}, reason: string) => {
    if (reason === closeModalReasons.backDrop) return;
    setModifiedDataPoint(true);
  }, []);

  const modalHeader =i18n.t(`analysis.dataPoints.${modalType}.modalHeader`);

  return (
    <WeaveModalDialog
      sx={{ ...dataPointsModalStyles }}
      open={isOpen}
      header={modalHeader}
      onClose={closeHandler}
      disablePortal
      disableEscapeKeyDown
    >
      <DataPointsModal
        onCloseModal={closeModal}
        dataPointChanges={{modifiedDataPoint, setModifiedDataPoint}}
        modalType={modalType}
      />
    </WeaveModalDialog>
  );
}

export default DataPointsModalWrapper