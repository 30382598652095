import React from 'react';
import ProgressBar from '@weave-design/progress-bar';
import { progressBarCustomStylesheet, progressBarPanel } from './Card.stylesheet';

export const CardDataLoadingProgress: React.FC = () => {
  return (
    <div style={progressBarPanel}>
      <ProgressBar stylesheet={progressBarCustomStylesheet} />
    </div>
  );
};
