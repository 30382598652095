import {ITopLevelTooltipState} from "./topLevelTooltipContext";

export enum TooltipActions {
    SHOW = "SHOW",
    HIDE = "HIDE"
}

export const tooltipReducer = (state: ITopLevelTooltipState, action) => {
    const {type, payload} = action;
    const {
        coordinates,
        content,
        header
    } = payload || {}

    switch(type) {
        case TooltipActions.SHOW: {
            return {
                ...state,
                coordinates: {
                    ...state.coordinates,
                    ...coordinates,
                    containerPosition: {
                        ...state.coordinates.containerPosition,
                        ...coordinates.containerPosition
                    },
                    pointerPosition: {
                        ...state.coordinates.pointerPosition,
                        ...coordinates.pointerPosition
                    }
                },
                content,
                header
            }
        }
        case TooltipActions.HIDE: {
            return {
                ...state,
                content: ""
            }
        }
        default:
            return state
    }
}
