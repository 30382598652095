// do not remove, component from weave implementation
import React, { useCallback, useEffect, useState, useContext } from 'react';
import Search from '../../shared/Search';
import '../../css/new/ecDefinition.css';
import { EcDefinition } from './types';
import isequal from 'lodash.isequal';
import { ECValue } from '../../shared/ECDefinition/ECValue';
import { MaterialRow } from '../dashboard.models';
import i18n from '../../i18n';
import { FlyoutContainer } from '../components';
import { QuickSearchNoResults } from '../../shared/Error/ErrorComponent';
import Button from '@weave-mui/button';
import { DataGridContext } from './DataGridContext';
import { footerButtonStyle } from '../../layout/LayoutGrid.stylesheet';

export const QS_SIZE = 300;

type ECDefinitionsQuickSearchWeaveProps = {
  ecDefinitions: EcDefinition[];
  selectedEcDefinition?: EcDefinition;
  openDialog: (material: MaterialRow) => void;
  onSelectEcDefinition: (ecDefinition: EcDefinition) => void;
  useSI: boolean;
  material: MaterialRow,
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
};

type ECDefinitionItemProps = {
  ecDefinition: EcDefinition;
  isSelected: boolean;
  onSelectEcDefinition: (ecDefinition: EcDefinition) => void;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  useSI: boolean;
};

const searchColumns = ['name', 'value'];

const search = (item: object, searchQuery: string): boolean => {
  return (
    searchQuery.trim().length === 0 ||
    searchQuery
      .toLowerCase()
      .split(' ')
      .reduce(
        (result, token) =>
          result &&
          searchColumns.some(
            (field) => item[field] && item[field].toString().toLowerCase().indexOf(token) > -1
          ),
        true
      )
  );
};

const filterData = (data: EcDefinition[], searchQuery: string) =>
  data.filter((item) => search(item, searchQuery.trim()));

const moveSelectedOnTop = (ecDefinitions: EcDefinition[], selectedEcDefinition: EcDefinition): EcDefinition[] => {
  let foundSelectedECDefinition: EcDefinition;
  let filteredEcDefinitions = [];
  ecDefinitions.forEach((ecDefinition: EcDefinition) => {
    if (ecDefinition.id === selectedEcDefinition.id) {
      foundSelectedECDefinition = ecDefinition;
    } else {
      filteredEcDefinitions.push(ecDefinition);
    }
  });
  if (!foundSelectedECDefinition) {
    return ecDefinitions;
  }
  filteredEcDefinitions.unshift(foundSelectedECDefinition);
  return filteredEcDefinitions;
};

export const ECDefinitionItem: React.FC<ECDefinitionItemProps> = React.memo(
  ({ ecDefinition, isSelected, onSelectEcDefinition, useSI, setAnchorEl }: ECDefinitionItemProps) => {
    const onSelect = useCallback(
      () => {
        setAnchorEl(null);
        return onSelectEcDefinition(ecDefinition);
      }, [ecDefinition, onSelectEcDefinition]
    );
    return (
      <div
        className={` ecDefinition-item ${isSelected ? 'ecDefinition-item-selected' : ''}`}
        onClick={onSelect}
      >
        <FlyoutContainer value={<span>{ecDefinition.name}</span>} />
        <ECValue value={ecDefinition.average} unit={ecDefinition.unit} useSI={useSI} />
      </div>
    );
  },
  (prevProps: ECDefinitionItemProps, nextProps: ECDefinitionItemProps) =>
    isequal(prevProps.ecDefinition, nextProps.ecDefinition) &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.useSI === nextProps.useSI
);

const ECDefinitionsQuickSearchWeave = ({
  ecDefinitions,
  selectedEcDefinition,
  openDialog,
  onSelectEcDefinition,
  useSI,
  material,
  setAnchorEl,
}: ECDefinitionsQuickSearchWeaveProps): JSX.Element => {
  const [ecDefinitionsArray, setEcDefinitionsArray] = useState<EcDefinition[]>(ecDefinitions);
  const [searchValue, setSearchValue] = useState<string>('');

  const dataGridContext = useContext(DataGridContext);
  const { setIsOpenQuickModal } = dataGridContext;

  useEffect(() => {
    if (searchValue && typeof searchValue === 'string' && searchValue.trim().length > 0) {
      setEcDefinitionsArray(filterData(ecDefinitions, searchValue));
    } else {
      selectedEcDefinition
        ? setEcDefinitionsArray(moveSelectedOnTop(ecDefinitions, selectedEcDefinition))
        : setEcDefinitionsArray(ecDefinitions);
    }
  }, [searchValue, ecDefinitions, selectedEcDefinition]);

  useEffect(() => {
    if (selectedEcDefinition && ecDefinitions.length > 0) {
      setEcDefinitionsArray(moveSelectedOnTop(ecDefinitions, selectedEcDefinition));
    }
  }, [selectedEcDefinition, ecDefinitions]);

  const filterCb = useCallback((value: string) => {setSearchValue(value)}, []);
  const clearCb = useCallback(() => setSearchValue(''), []);

  const handleClick = () => {
    openDialog(material);
    setAnchorEl(null);
    setIsOpenQuickModal(false);
  }
  const selectEcDefinitionCb = useCallback((ecDefinition: EcDefinition) => {
    setAnchorEl(null);
    setIsOpenQuickModal(false);
    if (ecDefinition.id === selectedEcDefinition?.id) {
      return;
    }
    onSelectEcDefinition(ecDefinition);
  }, [selectedEcDefinition]);
  return (
    <div
      className="ecDefinitionQuickSearch"
      style={{
        width: `${QS_SIZE}px`,
        height: `${QS_SIZE}px`,
      }}
    >
      <Search searchValue={searchValue} onSearch={filterCb} onClear={clearCb} />
      <div className="content">
        {ecDefinitionsArray && ecDefinitionsArray.length ? (
          ecDefinitionsArray.map((ecDefinition) => (
            <ECDefinitionItem
              key={ecDefinition.id}
              useSI={useSI}
              isSelected={selectedEcDefinition?.id === ecDefinition.id}
              ecDefinition={ecDefinition}
              onSelectEcDefinition={selectEcDefinitionCb}
              setAnchorEl={setAnchorEl}
            />
          ))
        ) : (
          <QuickSearchNoResults />
        )}
      </div>
      <div className="footer">
        <Button
          sx={{ ...footerButtonStyle }}
          onClick={handleClick}>
          {i18n.t('analysis.common.advSearch')}
        </Button>
      </div>
    </div>
  );
};

export default ECDefinitionsQuickSearchWeave;
