import { createSlice } from '@reduxjs/toolkit';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { featureFlagDataApi } from './api/feature-flag-data-api';

// temp set of reducers to help bridge gap whilst we convert class to functional components
// RTK Query hooks can only be executed from functional components
export const featureFlagSlice = createSlice({
  name: 'featureFlagsState',
  initialState: { featureFlags: {}, flagsLoaded: false } as {
    featureFlags: LDClient.LDFlagSet;
    flagsLoaded: boolean;
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      featureFlagDataApi.endpoints.getUserFeatureFlagData.matchFulfilled,
      (state, { payload }) => {
        state.featureFlags = payload;
        state.flagsLoaded = true;
      }
    );
  },
});
