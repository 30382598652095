
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../../analysis/dashboard.models";

export interface Auth0State { 
  redirectPath: string;
  hasToken: boolean;
}

const initialState: Auth0State = { 
  redirectPath: null,
  hasToken: null
};

export const auth0Slice = createSlice({
  name: 'auth0State',
  initialState,
  reducers: {
    setAuth0Data(state, action: PayloadAction<typeof initialState>) {
      state.hasToken = action.payload.hasToken;
      state.redirectPath = action.payload.redirectPath;
    }
  },
});


export const { setAuth0Data } = auth0Slice.actions;