import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addFactors } from '../model/actions';
import { getSimulationStatus } from '../run/actions';
import Tabs, { alignments, Tab, variants } from "@hig/tabs";
import ThemeContext from '@hig/theme-context';
import globalunresolvedTheme from '@hig/theme-data/build/esm/unresolved/lightGrayHighDensityTheme';
import { extendTheme, resolveTheme } from '@hig/theme-data';
import LeftModelsSelector from '../model/LeftModelsSelector';
import CustomTabRenderer from './CustomTabRenderer';
import RunsTable from '../run/RunsTable';
import Optimization from './Optimization';
import Overview from '../dashboard/Overview';
import FactorsModal from './FactorsModal';
import { factorChoices } from './options';
import Button from '@hig/button';
import MeterChart from '../charts/MeterChart';
import * as consts from "../consts";
import ModalPopup from './ModalPopup';
import i18n from '../i18n';
import * as locConsts from "./localization/consts";
import * as chartConsts from "../charts/localization/consts";
import Flyout, { anchorPoints } from "@hig/flyout";
import FlyoutMenu from '../common/FlyoutMenu';
import * as actions from './actions';
import * as sharedActions from '../shared/actions';
import * as actionTypes from './actionTypes';
import * as modelType from './dashboard.models';
import { HigNotificationToastWrapper } from "../shared/HigNotificationToastWrapper";
import { withRouter } from '../utils/react-router-v6-class-utils';

type PrevState = {
    modelId: string,
    modalCategories: ModalCategories[],
    modalOpen: boolean,
    projectId: string,
    tabActivated: number,
    models: modelType.Model[]
}

type ModalCategories = {
    display: true,
    factors: modelType.Factor[],
    key: string,
    name: string
}

type DashboardState = {
    modelId: string,
    projectId: string,
    models: modelType.Model[],
    tabActivated: number,
    isLeftPanelCollapsed?: boolean,
    notificationModalOpen?: boolean,
    notificationModalTitle?: string,
    notificationModalText?: string,
    notificationModalLinkText?: string,
    modalOpen?: boolean,
    modalCategories?: choices[]
}

type choices = {
    display: boolean,
    key: string,
    name: string,
    factors: modelType.Factor[]
}

const customUnresolvedTheme = extendTheme(globalunresolvedTheme.unresolvedRoles, {
    "tabs.box.tab.active.backgroundColor": { value: "#f5f5f5" }
});
const customResolvedTheme = resolveTheme(customUnresolvedTheme);
const customTheme = {
    "metadata": {

    },
    "resolvedRoles": customResolvedTheme
};

export class AnalysisPage extends Component<modelType.DashboardProps, DashboardState> {
    constructor(props: modelType.DashboardProps) {
        super(props);

        this.state = this.init();
    }

  init() {
    if (!this.props.displayEnergyWorkflow) {
            window.location.replace("/projects");
        }
        else {
            return {
                modelId: this.props.modelId,
                projectId: this.props.projectId,
                models: [],
                tabActivated: 0
            };
        }
    }

    componentDidMount() {
        this.props.loadCurrencies();
        this.loadData();
    }

    componentDidUpdate(prevProps: modelType.DashboardProps, prevState: PrevState) {
        if (prevProps.modelId !== this.props.modelId && this.props.dashboard && this.props.dashboard.baseRunStatus === 'Error') {
            this.showNotificationModal(
                i18n.t(locConsts.ANALYSIS_PAGE_MODAL_BASE_FAILED),
                i18n.t(locConsts.ANALYSIS_PAGE_MODAL_BASE_FAILED_DESC),
                ""
            );
        }

        this.loadData();
    }

    loadData() {
        if (!this.props.dashboard) {
            this.props.loadDashboard(this.props.modelId, this.props.projectId);
        }
    }

    onModelChange = (model: modelType.Model) => {
        this.props.loadDashboard(model.id, this.props.projectId).then(() => {
            this.props.navigate(`/projects/${this.props.projectId}/model/${model.id}/runs`);
        });
    }

    onCollapsedClick = () => {
        this.setState({ isLeftPanelCollapsed: !this.state.isLeftPanelCollapsed });
    }

    downloadZipPackage = (runId: string) => {
        const { apiForgeBaseUrl } = this.props.user;
        return this.props.getSimulationStatus(apiForgeBaseUrl, runId)
            .then((simResult) => {
                if (simResult.downloadUrl) {
                    window.open(simResult.downloadUrl);
                } else {
                    alert('no result was found');
                }
            });
    }

    onTabChange = (tabIndex: number) => {
        this.setState({
            tabActivated: tabIndex
        });
    }

    onShowSimulationTab = () => {
        this.setState({
            notificationModalOpen: false,
            tabActivated: 1
        });
    }

    onRangeChange = (modelId: number, widgetKey: string, left: string, right: string) => {
        this.props.dashboardRangeChanged(modelId, widgetKey, left, right);
    }

    onMeterChange = (meterName: string) => {
        this.props.updateDashboardMetric(this.props.projectId, meterName);
    }

    renderEcrChart() {
        const dashboard = this.props.dashboard;
        const valuesForMeter: modelType.ValuesForMeter = {
            bimValue: 0,
            meanValue: 0,
            minValue: 0,
            maxValue: 0,
            title: dashboard.useSI ? i18n.t(chartConsts.EUI_SI_UNITS) : i18n.t(chartConsts.EUI_IP_UNITS),
            meterTitle: i18n.t(locConsts.CHART_OPTIONS_TEXT_ENERGY),
            minTick: 0,
            maxTick: 0,
            showEcr: dashboard.showEcr
        };

        if (dashboard.showEcr) {
            valuesForMeter.bimValue = Math.round(dashboard.ecrEnergy.bim);
            valuesForMeter.meanValue = Math.round(dashboard.ecrEnergy.ecrMean);
            valuesForMeter.minTick = Math.round(dashboard.ecrEnergy.ecrMin);
            valuesForMeter.maxTick = Math.round(dashboard.ecrEnergy.ecrMax);
            valuesForMeter.minValue = Math.round(dashboard.ecrEnergy.meterMin);
            valuesForMeter.maxValue = Math.round(dashboard.ecrEnergy.meterMax);
        }

        return (
            <MeterChart
                values={valuesForMeter}
                name={actionTypes.DASHBOARD_ENERGY_METRIC}
                selected={dashboard.selectedPointGroup === actionTypes.DASHBOARD_ENERGY_METRIC}
                onMeterChange={this.onMeterChange}
            />
        );
    }

    renderMeterCostChart() {
        const dashboard = this.props.dashboard;
        const currency = this.props.currencies.find((x) => x.currencyIso === dashboard.currencyIso);

        const valuesForMeter = {
            bimValue: 0,
            meanValue: 0,
            minValue: 0,
            maxValue: 0,
            title: dashboard.useSI ? i18n.t(chartConsts.COST_SI_UNITS, { currencyIso: dashboard.currencyIso }) : i18n.t(chartConsts.COST_IP_UNITS, { currencyIso: dashboard.currencyIso }),
            meterTitle: i18n.t(locConsts.CHART_OPTIONS_TEXT_COST),
            minTick: 0,
            maxTick: 0,
            currency: currency && currency.symbol,
            showEcr: dashboard.showEcr
        };

        if (dashboard.showEcr) {
            valuesForMeter.bimValue = this.decimalValueCost(dashboard.ecrCost.bim);
            valuesForMeter.meanValue = this.decimalValueCost(dashboard.ecrCost.ecrMean);
            valuesForMeter.minTick = this.decimalValueCost(dashboard.ecrCost.ecrMin);
            valuesForMeter.maxTick = this.decimalValueCost(dashboard.ecrCost.ecrMax);
            valuesForMeter.minValue = this.decimalValueCost(dashboard.ecrCost.meterMin);
            valuesForMeter.maxValue = this.decimalValueCost(dashboard.ecrCost.meterMax);
        }

        return (
            <MeterChart
                values={valuesForMeter}
                name={actionTypes.DASHBOARD_COST_METRIC}
                selected={dashboard.selectedPointGroup === actionTypes.DASHBOARD_COST_METRIC}
                onMeterChange={this.onMeterChange}
            />
        );
    }

    decimalValueCost(value: number) {

        const roundedValue = Math.round(value);

        if (roundedValue.toString().length === 1) {
            return Math.round(value * 100) / 100;
        } else if (roundedValue.toString().length === 2 || roundedValue.toString().length === 3) {
            return Math.round(value * 10) / 10;
        }

        return roundedValue;
    }

    onSimulateClick = (keys) => {
        return this.props.addFactors(this.props.modelId, keys).then((res) => {

            if (!res.apiError) {
                this.props.updateDashboardFactorsAndQueryResults(res);
            }

            this.setState({
                modalOpen: false,
                tabActivated: 2
            });
        });
    }

    showNotificationModal = (title: string, text: string, linkText: string) => {
        this.setState({
            notificationModalOpen: true,
            notificationModalTitle: title,
            notificationModalText: text,
            notificationModalLinkText: linkText
        });
    }

    onCloseNotificationModal = () => {
        this.setState({
            notificationModalOpen: false
        });
    }

    static getDerivedStateFromProps(props: modelType.DashboardProps, state: DashboardState) {
        const newState: any = {
            modelId: props.modelId,
            projectId: props.projectId,
            models: {}
        };

        newState.models = Object.entries(props.modelsData.models)
            .filter(([key, value]) => (value as any).projectId === props.projectId)
            .map(([key, value]) => value);

        if (state.modelId !== newState.modelId ||
            state.projectId !== newState.projectId ||
            state.models.length !== newState.models.length) {
            return newState;
        }

        const lmvStatus = newState.models.find((x) => x.id === newState.modelId)?.lmvStatus;
        if (state.models.find((z) => z.id === newState.modelId)?.lmvStatus !== lmvStatus &&
            (lmvStatus === "Completed" || lmvStatus === 'Error')) {
            return newState;
        }

        return state;
    }

    onOpenFactorsModel = () => {
        const choices = factorChoices().filter((f) => f.display);
        const factors = this.props.dashboard.factors;

        choices.forEach((c) => {
            c.factors.forEach((f) => {
                if (f.key === consts.GLAZING_CONSTRUCTION) {
                    f.disabled = factors.some((d) =>
                        d.name === consts.GLAZING_CONSTRUCTION_NORTH ||
                        d.name === consts.GLAZING_CONSTRUCTION_EAST ||
                        d.name === consts.GLAZING_CONSTRUCTION_SOUTH ||
                        d.name === consts.GLAZING_CONSTRUCTION_WEST
                    );
                }
                else if (f.key === consts.WINDOW_SHADES) {
                    f.disabled = factors.some((d) =>
                        d.name === consts.WINDOW_SHADES_NORTH ||
                        d.name === consts.WINDOW_SHADES_EAST ||
                        d.name === consts.WINDOW_SHADES_SOUTH ||
                        d.name === consts.WINDOW_SHADES_WEST
                    );
                }
                else if (f.key === consts.WWR_WALLS) {
                    f.disabled = factors.some((d) =>
                        d.name === consts.WWR_NORTHERN_WALLS ||
                        d.name === consts.WWR_EASTERN_WALLS ||
                        d.name === consts.WWR_SOUTHERN_WALLS ||
                        d.name === consts.WWR_WESTERN_WALLS
                    );
                }
                else {
                    f.disabled = factors.some((d) => d.name === f.key);
                }
            });
        });

        this.setState({
            modalOpen: true,
            modalCategories: choices
        });
    }

    onCloseFactorsModel = () => {
        this.setState({
            modalOpen: false
        });
    }

    getSortMenuItems = () => {
        return [
            {
                text: i18n.t(locConsts.SORT_MENU_OPTIONS_IMPACT),
                value: "Impact"
            },
            {
                text: i18n.t(locConsts.SORT_MENU_OPTIONS_CATEGORY),
                value: "Category"
            }
        ];
    }

    onSortMenuItemChange = (item: any) => {
        this.props.sortDashboard(this.props.modelId, item.value);
    }

    render() {
        const {
            modalOpen,
            modalCategories,
            notificationModalOpen,
            notificationModalTitle,
            notificationModalText,
            notificationModalLinkText,
            isLeftPanelCollapsed
        } = this.state;

        let analysisPageModelsClasses = "analysis-page-models";
        if (isLeftPanelCollapsed) {
            analysisPageModelsClasses += " analysis-page-models-collapsed";
        }

        const { dashboard  } = this.props;

        if (!dashboard) {
            return null;
        }

        const selectedMeter = dashboard.selectedPointGroup === actionTypes.DASHBOARD_COST_METRIC ? "cost" : "ecr";

        let runs = [];
        let bingMapKey: string;

        if (this.props.runsData) {
            runs = this.props.runsData.items;
        }

        if (this.props.user && this.props.user.bingMapKey) {
            bingMapKey = this.props.user.bingMapKey;
        }

        const flyoutMenu = <FlyoutMenu
            onItemChange={this.onSortMenuItemChange}
            items={this.getSortMenuItems()}
            optionSelected={this.props.dashboard.sortBy}
            menuTitle={i18n.t(locConsts.SORT_MENU_TITLE)}
        />;

        return (
            <ThemeContext.Provider value={customTheme}>
                <HigNotificationToastWrapper />
                <FactorsModal
                    open={modalOpen}
                    categories={modalCategories}
                    onCloseClick={this.onCloseFactorsModel}
                    onSimulateClick={this.onSimulateClick}
                />
                <ModalPopup
                    open={notificationModalOpen}
                    title={notificationModalTitle}
                    text={notificationModalText}
                    textLink={notificationModalLinkText}
                    onCloseClick={this.onCloseNotificationModal}
                    onLinkClick={this.onShowSimulationTab}
                    textPrimaryBn={i18n.t(locConsts.MODAL_POPUP_BUTTON_PRIMARY)}
                    textSecondaryBn={i18n.t(locConsts.MODAL_POPUP_BUTTON_SECONDARY)}
                />
                <div className="analysis-page-wrapper">
                    <div className="analysis-page">
                        <div className={analysisPageModelsClasses}>
                            {
                                <LeftModelsSelector
                                    modelId={this.props.modelId}
                                    projectId={this.props.projectId}
                                    isUnauthorisedAccess={this.props.dashboard.isUnauthorizedAccess }
                                    isCollapsed={this.state.isLeftPanelCollapsed}
                                    onModelChange={this.onModelChange}
                                    collapseCallback={this.onCollapsedClick}
                                />
                            }
                        </div>
                        <div className="analysis-page-content">
                            <div className="analysis-page-content-top-panel">
                                <div>
                                    <div className="analysis-page-left-top-panel">
                                        {this.renderEcrChart()}
                                        {this.renderMeterCostChart()}
                                    </div>
                                </div>
                                <div className="analysis-page-rigth-top-panel">
                                    <div className="sort-icon-container">
                                        <Flyout anchorPoint={anchorPoints.CENTER_BOTTOM}
                                            content={flyoutMenu}
                                            className="flyout-override">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className="sort-icon">
                                                <polygon points="13.54 9.38 13.54 3 10.46 3 10.46 9.38 8 9.38 12 15 16 9.38 13.54 9.38" fill-rule="evenodd"></polygon>
                                                <polygon points="4 1 0 6.63 2.46 6.63 2.46 13 5.54 13 5.54 6.63 8 6.63 4 1" fill-rule="evenodd">
                                                </polygon>
                                            </svg>
                                        </Flyout>
                                    </div>
                                    <Button
                                        size="standard"
                                        title={i18n.t(locConsts.ANALYSIS_PAGE_CHOOSE_FACTORS)}
                                        type="primary"
                                        disabled={dashboard.baseRunStatus !== 'Completed'}
                                        onClick={this.onOpenFactorsModel}
                                    />
                                </div>
                            </div>
                            <Tabs
                                className="analysis-tab-control"
                                alignment={alignments.LEFT}
                                variant={variants.BOX}
                                defaultActiveTabIndex={0}
                                onTabChange={this.onTabChange}
                                activeTabIndex={this.state.tabActivated}
                            >
                                <Tab label={i18n.t(locConsts.ANALYSIS_PAGE_OVERVIEW_TAB)} icon={<span className="analysis-tab-overview-icon"></span>} render={CustomTabRenderer}>
                                    <Overview
                                        model={dashboard}
                                        bingMapKey={bingMapKey}
                                    />
                                </Tab>
                                <Tab label={i18n.t(locConsts.ANALYSIS_PAGE_SIMULATIONS_TAB)} icon={<span className="analysis-tab-simulations-icon"></span>} render={CustomTabRenderer}>
                                    <RunsTable
                                        dashboard={dashboard}
                                        runs={runs}
                                        results={this.props.simulationData}
                                        isDownloadEnabled={this.props.downloadZip}
                                        downloadAction={this.downloadZipPackage}
                                        meterSelected={selectedMeter}
                                    />
                                </Tab>
                                <Tab label={i18n.t(locConsts.ANALYSIS_PAGE_OPTIMIZATION_TAB)} icon={<span className="analysis-tab-optimization-icon"></span>} render={CustomTabRenderer}>
                                    <Optimization
                                        dashboard={this.props.dashboard}
                                        onShowSimulationTab={this.onShowSimulationTab}
                                        showNotificationModal={this.showNotificationModal}
                                        onRangeChange={this.onRangeChange}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </ThemeContext.Provider>
        );
    }
}

const mapStateToProps = function (state, ownProps) {
    const projectId = ownProps.routeParams.projectId;
    const modelId = ownProps.routeParams.modelId

    const mappedProps = {
        modelId,
        projectId,
        dashboard: state.dashboardState.modelById[modelId],
        runsData: state.runState.runs[modelId],
        simulationData: state.runState.runsResults,
        user: state.userState.user,
        modelsData: state.modelState,
        downloadZip: state.featureFlagsState.flagsLoaded ? state.featureFlagsState.featureFlags['download-zip'] : false,
        currencies: state.currencyState.currencies,
        displayEnergyWorkflow: state.featureFlagsState.flagsLoaded ? state.featureFlagsState.featureFlags['display-energy-workflow'] : true,
    };

    return mappedProps;
};

const mapDispatchToProps = {
    loadDashboard: actions.loadDashboard,
    getSimulationStatus,
    addFactors,
    dashboardRangeChanged: actions.dashboardRangeChanged,
    updateDashboardFactorsAndQueryResults: actions.updateDashboardFactorsAndQueryResults,
    sortDashboard: actions.sortDashboard,
    updateDashboardMetric: actions.updateDashboardMetric,
    updateProjectSettings: actions.updateProjectSettings,
    loadCurrencies: sharedActions.loadCurrencies
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(AnalysisPage);
const componentWithRouterProps = withRouter(connectedComponent);
export default connectedComponent;
export const AnalysisPageWithRouter = componentWithRouterProps;
