import React from 'react';
import ProgressBar from '@weave-design/progress-bar';
import '../../css/new/progressBar.css';

type ProgressBarProps = {
    small?: boolean
}

const smallCustomStylesheet = (styles) => ({
    ...styles,
    wrapper: {
        ...styles.wrapper,
        backgroundColor: "#eeeeee",
        width: "15%"
    }
});

const mediumCustomStylesheet = (styles) => ({
    ...styles,
    wrapper: {
        ...styles.wrapper,
        backgroundColor: "#eeeeee",
        width: "30%"
    }
});

const HigProgressBar = ({ small = false }: ProgressBarProps): JSX.Element => {
    return (
        <div className="progress-bar-panel" >
            <ProgressBar stylesheet={small ? smallCustomStylesheet : mediumCustomStylesheet} />
        </div >
    )
}

export default HigProgressBar;