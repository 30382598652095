import { httpGet, httpGetBlob, httpPostJson } from "../shared";
import { getBase64 } from "../helpers/jsHelper";
import { Models } from "./actions";
import * as modelType from "../analysis/dashboard.models";
import { getInsightApigeeBasePath } from "../shared/api";

export const getProjectModels = async (projectId: string): Promise<Models[]> => {
  return httpGet<Models[]>(
    `${getInsightApigeeBasePath()}/api/v1/models/${projectId}/modelsByProject`
  );
};

export const getModel = (id: string) => {
  return httpGet<Models>(`${getInsightApigeeBasePath()}/api/v1/models/byModelId/${id}`);
};

export const addFactors = (id: string, factors) => {
  return httpPostJson(`${getInsightApigeeBasePath()}/api/v1/simulations/addFactors`, {
    data: {
      Id: id,
      Factors: factors,
    },
  });
};

export const getThumbnail = (model, accessToken, forgeBaseUrl) => {
  return httpGetBlob(
    `${forgeBaseUrl}/modelderivative/v2/designdata/${model.urn}/thumbnail?width=200&height=200`,
    { accessToken }
  ).then((blob: Blob) => {
    return getBase64(blob).then((base64) => {
      const obj = {
        modelId: model.id,
        thumbnail: base64,
      };
      return obj;
    });
  });
};

export const getModelECBreakdown = (modelId: string) => {
  return httpGet<modelType.ModelEmbodiedCarbonBreakdownEntity>(
    `${getInsightApigeeBasePath()}/api/v1/model/${modelId}/materials`
  );
};
