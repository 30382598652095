import { useMemo } from "react";
import { ParametersModalType, ParametersTypesEnum } from "../../types/metrics";
import { useFactorsStrategy } from "../services/strategies/factorsStrategy";
import { useMetricsStrategy } from "../services/strategies/metricsStrategy";
import { BaseCreateStrategyRequest, BaseEditStrategyRequest, DataPointsStrategy } from "../types/strategy";
import { useBenchmarksStrategy } from "../services/strategies/benchmarksStrategy";

interface DataPointsStrategyResult<CreateType extends BaseCreateStrategyRequest, EditType extends BaseEditStrategyRequest> {
  strategy: DataPointsStrategy<CreateType, EditType>,
  isLoading: boolean;
}
export const useDataPointsStrategy = (modalType: ParametersModalType): DataPointsStrategyResult<BaseCreateStrategyRequest, BaseEditStrategyRequest> => {
  const metricsStrategy = useMetricsStrategy();
  const factorsStrategy = useFactorsStrategy();
  const benchmarksStrategy = useBenchmarksStrategy();

  const strategy = useMemo(() => {
    switch (modalType) {
    case ParametersTypesEnum.METRICS:
      return metricsStrategy;
    case ParametersTypesEnum.FACTORS:
      return factorsStrategy
    case ParametersTypesEnum.BENCHMARKS:
      return benchmarksStrategy
    default:
      throw new Error('Invalid modal type');
  }
  }, [modalType, metricsStrategy, factorsStrategy, benchmarksStrategy]);
  

  const isLoading: boolean = 
    strategy.isCreateLoading ||
    strategy.isEditLoading ||
    strategy.isDeleteLoading;

  return { strategy, isLoading };
}