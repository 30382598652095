import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import ForgeUnits from '../../forgeUnitsService';
import { extractUserState } from './base-queries';

export const forgeUnitsDataApi = createApi({
  reducerPath: 'forgeUnitsDataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
  }),
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getForgeUnitsData: builder.query<{ loaded: boolean }, void>({
      queryFn: async (_, api) => {
        const { forgeBaseUrl } = extractUserState(api);
        try {
          let loaded = await ForgeUnits.initParametersEngine(forgeBaseUrl, true);
          return { data: { loaded } };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const { useGetForgeUnitsDataQuery, useLazyGetForgeUnitsDataQuery } = forgeUnitsDataApi;

// temp for use with class component
export const selectForgeUnitsDataApiState = forgeUnitsDataApi.endpoints.getForgeUnitsData.select();
