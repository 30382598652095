import React, { useState, useEffect } from 'react';
import ProjectWidget from './ProjectWidget';
import CreateProjectWidget from './CreateProjectWidget';
import { Modal } from '../common/Modal';
import ModelListSelector from '../model/ModelListSelector';
import '../css/project.css';
import DefaultProjectImage from './img/default-project-image3.png';
import DefaultProjectImage5 from './img/default-project-image5.png';
import i18n from '../i18n';
import * as locConsts from "./localization/consts";
import * as ProjectType from './ProjectTypes';
import { useGetProjectsQuery } from '../state/api/project-model-data-api';

export interface ProjectListPageProject extends Omit<ProjectType.ProjectModel, "useSIUnits" | "currencyIso"> {
  currencyIso?: string,
  useSIUnits?: boolean,
  projectMenuId: number
}

const ProjectListPage: React.FC = () => {
  const [isModelsModalVisible, setIsModelsModalVisible] = useState(false);

  // Redux Toolkit (Queries/Mutations)  
  const { data: projectsData, isLoading } = useGetProjectsQuery();  

  useEffect(() => {
    document.body.classList.add('project-list');
    return function cleanup() {
      document.body.classList.remove('project-list');
    };
  }, []);  

  const showModelsModal = () => {
    setIsModelsModalVisible(!isModelsModalVisible);
  }

 

  const renderWidgets = (): JSX.Element[] => {
    if (!projectsData && isLoading) {
      return null;
    }

    const array: JSX.Element[] = [];
    array.push(<CreateProjectWidget action={showModelsModal} text={i18n.t(locConsts.PROJECT_LIST_PAGE_CREATE)} key="999" />);
    for (var i = 0; i < projectsData?.length; i++) {
      const image = projectsData[i].isUncategorized ? DefaultProjectImage : DefaultProjectImage5;
      array.push(<ProjectWidget project={projectsData[i]} key={projectsData[i].id} image={image} projectMenuId={(i + 1)} />);
    }

    return array;
  };

  return (
    <div className="main-wrapper">
      <div className="main">
        <div
          className="container styled-scroll"
          id="project_list_container"
          style={{ position: 'relative' }}
        >
          <div className="project-widget-container">{renderWidgets()}</div>
        </div>
        {isModelsModalVisible && (
          <Modal hideModal={showModelsModal}>
            <ModelListSelector hideModal={showModelsModal} />
          </Modal>
        )}
      </div>
    </div>
  );
}

export default ProjectListPage;
