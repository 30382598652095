import { TooltipOptions } from 'highcharts';
import i18n from '../i18n';

export const EcChartType = {
  ['EcByConstruction']: i18n.t('analysis.ec.charts.ecByConstruction.comboBoxOption'),
  ['EcByMaterial']: i18n.t('analysis.ec.charts.ecByMaterial.comboBoxOption'),
  ['EcIntensityByArea']: i18n.t('analysis.ec.charts.ecIntensityByArea.comboBoxOption'),
  ['EcByCategory']: i18n.t('analysis.ec.charts.ecByCategory.comboBoxOption'),
};

export enum ChartType {
  STACKED_COLUMN,
  PIE,
  VARIWIDE,
}

export type ChartEvents = {
  onCategoryClick?: (event, categoryIndex) => void;
  onSeriesClick?: (event, seriesIndex) => void;
  onSeriesMouseOver?: (seriesIndex) => void;
  onSeriesMouseOut?: (seriesIndex) => void;
  onChartMaterialClick?: (event, selectedMaterialSha, categoryIndex) => void;
};

type ChartCommonOption<D> = {
  yTitle?: string;
  yShortTitle?: string;
  xTitle?: string;
  data: D;
  tooltip?: TooltipOptions;
};

export type StackedColumnChartOption = ChartCommonOption<StackedColumnChartItem[]> & {
  readonly type: ChartType.STACKED_COLUMN;
  categories: string[];
};

export type StackedColumnChartItem = {
  name: string;
  color?: string;
  data: number[];
  materialId?: string;
  sha?: string;
  constructionIds?: constructionIds;
  type?: string;
};

export type PieChartOption = ChartCommonOption<PieChartItem[]> & {
  readonly type: ChartType.PIE;
};

type constructionIds = string[];

export type PieChartItem = {
  name: string;
  color: string;
  y: number;
  constructionIds?: constructionIds;
};

export type VariwideChartOption = ChartCommonOption<VariwideChartItem[]> & {
  readonly type: ChartType.VARIWIDE;
  categories: string[];
};

export type VariwideChartItem = {
  name: string;
  color: string;
  y: number;
  z: number;
  data?: Object;
  constructionIds?: constructionIds;
};

export type MetricChartType = {
  name: string;
  mainValue?: number;
  unit?: string;
  showChartLegend?: boolean;
  data?: StackedColumnChartItem[];
  displayWarning?: boolean;
  warningMessage?: string;
};

export type ChartOptions = StackedColumnChartOption | PieChartOption | VariwideChartOption;
