import React, { Component } from 'react';

export class WindowShades extends Component {

    render() {
        return <svg
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 16 16"
            style={{
                // @ts-ignore: unrecognizable type
                enableBackground: "new 0 0 16 16"
            }}
            xmlSpace="preserve" className="factor-modal-icon"
        >
            <style type="text/css">
                {
                    "\n\t.st0ws{fill:#7C7C7C;}\n\t.st1ws{clip-path:url(#SVGIDWS_2_);}\n\t.st2ws{clip-path:url(#SVGIDWS_4_);}\n\t.st3ws{clip-path:url(#SVGIDWS_6_);}\n"
                }
            </style>
            <rect x={6} y={7} className="factor-modal-icon-color" width={4} height={2} />
            <g>
                <defs>
                    <polygon id="SVGIDWS_1_" points="2,1 1,6 3,6 5,6 11,6 13,6 15,6 14,1  " />
                </defs>
                <clipPath id="SVGIDWS_2_">
                    <use
                        xlinkHref="#SVGIDWS_1_"
                        style={{
                            overflow: "visible"
                        }}
                    />
                </clipPath>
                <g className="st1ws">
                    <rect x={-442} y={-250} className="factor-modal-icon-color" width={1440} height={1024} />
                </g>
            </g>
            <g>
                <defs>
                    <polygon
                        id="SVGIDWS_3_"
                        points="11,7 11,14 5,14 5,7 3,7 3,16 13,16 13,7  "
                    />
                </defs>
                <clipPath id="SVGIDWS_4_">
                    <use
                        xlinkHref="#SVGIDWS_3_"
                        style={{
                            overflow: "visible"
                        }}
                    />
                </clipPath>
                <g className="st2ws">
                    <rect x={-442} y={-250} className="factor-modal-icon-color" width={1440} height={1024} />
                </g>
            </g>
            <g>
                <defs>
                    <polygon
                        id="SVGIDWS_5_"
                        points="6,13 10,13 10,10 9.2,10 9,10 7,10 6.8,10 6,10  "
                    />
                </defs>
                <clipPath id="SVGIDWS_6_">
                    <use
                        xlinkHref="#SVGIDWS_5_"
                        style={{
                            overflow: "visible"
                        }}
                    />
                </clipPath>
                <g className="st3ws">
                    <rect x={-442} y={-250} className="factor-modal-icon-color" width={1440} height={1024} />
                </g>
            </g>
        </svg>;
    }
}
