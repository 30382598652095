import React, { useEffect } from "react";
import Typography from "@hig/typography";
import {useTopLevelTooltipManipulator} from "./TopLevelTooltip";

const elementContentStyle = (styles, options: FlyoutCellOptions) => ({
    ...styles,
    typography: {
        ...styles.typography,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%',
        fontWeight: 'inherit',
        ...(options.align ? { textAlign: options.align } : {}),
        ...(options.lineClamp && options.lineClamp >= '2'
            ? {
                '-webkit-line-clamp': options.lineClamp,
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                whiteSpace: 'normal',
                wordWrap: 'break-word'
            }
            : {
                whiteSpace: 'nowrap'
            })
    }
});

interface ICoordinates {
    coordinates: {
        x: number,
        y: number,
        pointer: number,
        elementHeight?: number
    }
}

interface IFlyoutContainerConsumer {
    content: string | JSX.Element,
    coordinates: ICoordinates | {}
    hoverDelay: number,
    children: JSX.Element,
    header?: string | JSX.Element,
}
export const FlyoutContainerConsumer = ({content, header, coordinates, hoverDelay, children}: IFlyoutContainerConsumer) => {
    const {showTooltip, hideTooltip}: any = useTopLevelTooltipManipulator();
    const focusTimeout = React.useRef(null);

    useEffect(() => {
        focusTimeout.current = setTimeout(() => {
            showTooltip({
                header: header,
                content: content,
                coordinates
            })
        }, hoverDelay)

        return () => {
            clearTimeout(focusTimeout.current)
            setTimeout(() => {
                hideTooltip();
            }, 100);
        }
    }, []);

    return children;
}

export type FlyoutCellOptions = {
    lineClamp?: string,
    align?: string,
    hoverDelay?: number,
}

export type FlyoutCellProps = {
    value: string | JSX.Element,
    tooltipContent?: string | JSX.Element
    tooltipHeader?: string | JSX.Element,
    options?: FlyoutCellOptions
    ignoreCondition?: boolean
}

export const FlyoutContainer = (props: FlyoutCellProps) => {
    const flyoutWidth = 230;
    const { value, ignoreCondition = false, tooltipHeader, tooltipContent, options = {} } = props;
    const { hoverDelay = 750 } = options;

    const [isHover, setIsHover] = React.useState(false)
    const [currentPosition, setCurrentPosition] = React.useState({})

    const ellipsisCondition = React.useCallback((e) => (e.target.scrollHeight > e.target.clientHeight || e.target.scrollWidth > e.target.offsetWidth), [])

    const onEnter = (e) => {
        if (ignoreCondition || ellipsisCondition(e)) {
            const client = e.target.getBoundingClientRect();
            setIsHover(true);
            setCurrentPosition({
                anchorPoint: "top-left",
                containerPosition: {
                    top: client.bottom + 10, // padding
                    left: client.left + (client.width / 2) - (flyoutWidth / 2)
                },
                pointerPosition: {
                    left: flyoutWidth / 2,
                    top: 0 // pointer position is relative to tooltip content, therefore it should always display at -5 when tooltip goes below
                },
                elementHeight: client.height + 5
            });
        }
    }
    const onLeave = () => {
        setIsHover(false);
    }

    return (
      <div style={{ display: 'contents' }} onMouseOver={onEnter} onMouseLeave={onLeave}>
        {!isHover ? (
          <Typography
            className="ecDefinition-item-text"
            stylesheet={(styles) => elementContentStyle(styles, options)}
          >
            {value}
          </Typography>
        ) : (
          <FlyoutContainerConsumer
            content={tooltipContent || value}
            coordinates={currentPosition}
            hoverDelay={hoverDelay}
            header={tooltipHeader}
          >
            <Typography
                className="ecDefinition-item-text"
                stylesheet={(styles) => elementContentStyle(styles, options)}
            >
              {value}
            </Typography>
          </FlyoutContainerConsumer>
        )}
      </div>
    );
}
