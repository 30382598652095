import React, { useEffect } from 'react';

const ResizeListener: React.FC<{ onResize: Function } & React.HTMLAttributes<HTMLDivElement>> = (props) => {

    const containerHeight = React.useRef(0);
    const containerWidth = React.useRef(0);
    const containerRef = React.useRef(null);

    useEffect(() => {
        if (!containerRef.current) return;

        containerHeight.current = containerRef.current.clientHeight;
        containerWidth.current = containerRef.current.clientWidth;
        const resizeCheckInterval = setInterval(() => {
            const newHeight = containerRef.current.clientHeight;
            const newWidth = containerRef.current.clientWidth;

            if (newHeight != containerHeight.current || newWidth != containerWidth.current) {
                containerHeight.current = newHeight;
                containerWidth.current = newWidth;
                props.onResize();
            }
        }, 500);
        return () => clearInterval(resizeCheckInterval);
    }, [containerRef.current]);

    return (
        <div className={props.className} ref={containerRef}>
            {props.children}
        </div>);
}

export default ResizeListener;