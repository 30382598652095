import Highcharts from "highcharts/highcharts.js";
import * as CustomHighcharts from '../../typings/CustomHighchartsTypes';

export function getMeterOptions(plotBands: Highcharts.YAxisPlotBandsOptions[], euiValue: number, bimValue: number, meterTitle: string): CustomHighcharts.CustomOptions {
  return {
    chart: {
      width: 115,
      height: 86,
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      margin: [0, 0, 0, 0],
      backgroundColor: null
    },
    tooltip: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    title: {
      text: undefined
    },
    dataLabels: {
      y: 5,
      style: {
        color: 'white',
        fontFamily: 'helvetica-condensed-bold, Helvetica, Arial',
        fontWeight: 'bold',
        fontSize: '5.5em',
        letterSpacing: '-0.8em',
        cursor: 'inherit'
      },
      borderWidth: 0,
      useHTML: true,
      verticalAlign: 'middle'
    },
    pane: {
      startAngle: -90,
      endAngle: 90,
      background: [{
        shape: 'arc',
        backgroundColor: 'transparent',
        borderWidth: 0
      }],
      size: '100%'
    },
    yAxis: [{
      min: -90,
      max: 90,

      minorTickWidth: 0,
      minorTickLength: 30,
      minorTickPosition: 'inside',
      minorTickColor: 'green',

      minorGridLineColor: 'blue',

      tickPixelInterval: 10,
      tickWidth: 0,
      tickPosition: 'inside',
      tickLength: 32,
      tickColor: 'black',

      labels: {
        enabled: false
      },

      title: {
        text: meterTitle,
        y: 20,
        style: {
          color: "#4B4B4B",
          fontFamily: 'ArtifaktElement,sans-serif',
          fontSize: '11px',
          fontWeight: 'bold'
        }
      },
      plotBands: plotBands
    }],
    plotOptions: {
      series: {
        states: { hover: { enabled: false } },
        animation: false
      },
      gauge: {
        dial: // simpleDialNeedle
        {
          // needle extending from pivot
          baseLength: '10', // how high the fat part rises
          baseWidth: 4, // fat part of needle
          rearLength: '0.5', // below the pivot
          radius: '100', // this is how tall the needle goes (%)
          // backgroundColor: '#f00',
          borderColor: '#fff',
          borderWidth: 0
        },
        pivot: {
          radius: 0
        }
      }
    },
    series: [
      {
        // states: { hover: { enabled: false } },
        //EUI value dial
        type: 'gauge',
        dial: {
          backgroundColor: plotBands[0].color,
          baseLength: '70%',
          baseWidth: 4,
          rearLength: '-55%',
          topWidth: 1,
          borderColor: 'white',
          borderWidth: 1
        },
        data: euiValue > -90 ? [euiValue] : [],
        dataLabels: {
          enabled: false
        }

      },
      {
        //BIM Value dial
        type: 'gauge',
        data: bimValue > -90 ? [bimValue] : [],
        name: 'ASHRAE 90.1',
        dial: {
          radius: '95%',
          backgroundColor: 'black',
          baseWidth: 3,
          topWidth: 3,
          baseLength: '90%',
          rearLength: '-76%',
          borderWidth: 1,
          borderColor: 'white'
        },
        dataLabels: {
          enabled: false
        }
      }]
  };
}

const LabelFontSize = '12';
const SmallLabelFontSize = '9';
export const fontFamily = "ArtifaktElement,sans-serif";

const ecCommonChartOptions: CustomHighcharts.CustomOptions = {
  chart: {
    type: "",
    plotBorderWidth: 1,
    reflow: true,
    style: {
      fontSize: LabelFontSize,
      fontFamily: fontFamily
    },
    spacingBottom: 24,
    events: {}
  },
  title: {
    text: null
  },
  xAxis: {
    categories: [],
    labels: {
      style: {
        fontSize: LabelFontSize,
        color: '#3C3C3C',
        cursor: "pointer"
      }
    },
    min: 0,
    max: 0,
    tickLength: 0,
    minRange: 10
  },
  yAxis: {
    min: 0,
    gridLineWidth: 1,
    title: {
      text: ""
    },
    tickAmount: 5
  },
  legend: {
    enabled: false
  },
  tooltip: {
    headerFormat: '',
    pointFormat: '',
    backgroundColor: '#535353',
    borderColor: '#535353',
    shadow: false,
    borderRadius: 2,
    style: {
      color: '#f5f5f5'
    }
  },
  series: [],
  credits: {
    enabled: false
  },
  plotOptions: {
    series: {
      minPointLength: 3
    }
  } as CustomHighcharts.CustomPlotOptions,
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          yAxis: {
            title: {
              text: "",
              style: {
                fontSize: SmallLabelFontSize
              }
            },
            labels: {
              style: {
                fontSize: SmallLabelFontSize
              }
            },
            tickAmount: 4
          },
          xAxis: {
            labels: {
              style: {
                fontSize: SmallLabelFontSize
              }
            }
          }
        }
      }
    ]
  }
}

export const stackedColumnCharOptions: CustomHighcharts.CustomOptions = {
  ...ecCommonChartOptions,
  tooltip: {
    ...ecCommonChartOptions.tooltip
  },
  xAxis: {
    ...ecCommonChartOptions.xAxis,
    crosshair: { color: "#8080801a" },
    scrollbar: {
      enabled: false,
      margin: 2,
      height: 5,
      rifleColor: 'transparent',
      buttonBackgroundColor: 'white',
      buttonBorderColor: 'white',
      buttonArrowColor: 'white',
      barBorderRadius: 4,
      barBackgroundColor: '#80808033',
      barBorderWidth: 0,
      trackBackgroundColor: 'white',
      trackBorderWidth: 0
    }
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: false
      },
    },
    series: {
      minPointLength: 3,
      states: {
        hover: {
          enabled: false //Disable default hover styling
        },
        inactive: {
          opacity: 1 //Keep opacity of inactive/unselected items as 1
        }
      }
    }
  } as CustomHighcharts.CustomPlotOptions,
  series: [] as CustomHighcharts.CustomSeriesOptionsType[]
}

export const pieChartOptions: CustomHighcharts.CustomOptions = {
  ...ecCommonChartOptions,
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.y}',
        distance: 20,
        style: {
          fontSize: SmallLabelFontSize
        }

      },
      showInLegend: true
    }
  } as CustomHighcharts.CustomPlotOptions,
  series: [{
    type: 'pie',
    name: 'Embodied Carbon',
    data: [] as CustomHighcharts.CustomPointOptionsObject[]
  }]
}

export const variwideOptions: CustomHighcharts.CustomOptions = {
  ...ecCommonChartOptions,
  xAxis: {
    type: 'category',
    categories: [],
    labels: {
      enabled: false
    },
    title: {
      text: ""
    }
  },
  yAxis: {
    title: {
      text: ""
    }
  },
  series: [{
    type: 'variwide',
    colorByPoint: true,
    legendType: 'point',
    data: [] as CustomHighcharts.CustomPointOptionsObject[]
  } as CustomHighcharts.CustomSeriesVariwideOptions],
}

export const tooltipBackgroundColor: string = 'rgba(97, 97, 97, 0.85)';
export const stackedColumnTooltipStyle = {
  color: '#FFFFFF',
  minWidth: '150px',
  margin: '0',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: '400',
  fontSize: '0.75rem',
  letterSpacing: '0.03333em',
};

export const performanceStackedChartOptions: CustomHighcharts.CustomOptions | any = {
  chart: {
    type: 'column',
    events: {
      load: undefined,
    },
  },
  title: {
    text: '',
    align: 'left',
  },
  xAxis: {
    categories: [],
    labels: {
      enabled: false,
    },
    lineColor: 'transparent',
    min: 0,
    max: 5,
  },
  yAxis: {
    //min: 0,
    title: false,
    gridLineWidth: 0,
    tickAmount: 6,
    lineWidth: 1,
    lineColor: '#CCC',
    opposite: false,
    labels: {
      enabled: true,
      x: -20,
      style: {
        color: '#CCC',
      },
    },
    tickWidth: 1,
    plotLines: [
      {
        value: 0,
        color: '#CCC',
        zIndex: 4,
        width: 1,
      },
    ],
    //max: 0
  },
  legend: {
    enabled: false,
    align: 'right',
    verticalAlign: 'top',
    floating: true,
    backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
    borderColor: '#CCC',
    borderWidth: 0,
    shadow: false,
    layout: 'vertical',
    symbol: 'square',
    symbolRadius: 3,
    itemStyle: {
      fontWeight: 'normal',
    },
    x: 8,
    width: '50%',
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: false,
      },
      pointPlacement: -0.1,
      stickyTracking: false,
    },
    scatter: {
      //enableMouseTracking: false,
      stickyTracking: false,
    },
    series: {
      events: {
        afterAnimate: undefined,
      }, 
      point: {
        events: {
          mouseOver: undefined,
          mouseOut: undefined,
          click: undefined,
        },
      },
      pointWidth: 20,
      minPointLength: 3,
    },
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    enabled: true,
    shadow: false,
    useHTML: true,
    borderRadius: 4,
    borderColor: 'transparent',
    backgroundColor: tooltipBackgroundColor,
    headerFormat: '',
    footerFormat: '',
    valueDecimals: 2,
    style: {
      ...stackedColumnTooltipStyle,
    },
  },
  series: [] as CustomHighcharts.CustomSeriesOptionsType[],
};

export const yScaleFormaterNoBenchmarks = (value: any, isNegative: boolean) => {
  return function () {
      if (Math.abs(this.value) >= 1000) {
        const suffixes = ["", "k", "M", "B", "T"];
        const suffixNum = Math.floor(("" + Math.abs(this.value)).length / 3);
        const shortValue = parseFloat((suffixNum !== 0 ? (this.value / Math.pow(1000, suffixNum)) : value as number).toPrecision(2));
        let shorValueString;
        if (shortValue % 1 != 0) {
          shorValueString = shortValue.toFixed(1);
          return shorValueString + suffixes[suffixNum];
        }
        return shortValue + suffixes[suffixNum];
      }
      return this.value.toString();
    }
};

