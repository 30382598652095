import React, { useState, useEffect } from 'react';
import Box from '@weave-mui/box';
import Button, { buttonVariants } from '@weave-mui/button';
import Typography, { typographyVariants } from '@weave-mui/typography';
import FormControl from '@weave-mui/form-control';
import FormLabel from '@weave-mui/form-label';
import Select, { SelectChangeEvent } from '@weave-mui/select';
import RadioButton from '@weave-mui/radio';
import Divider from '@weave-mui/divider';
import TextField from '@weave-mui/text-field';
import MenuItem from '@weave-mui/menu-item';
import Modal from '@weave-mui/modal';
import { spacings } from '@weave-mui/enums';
import i18n from '../i18n';
import * as locConsts from '../analysis/localization/consts';
import * as conversions from '../conversions';
import UnitEnum from '../shared/UnitConversion/UnitEnums';
import { useGetUserFeatureFlagDataQuery } from '../state/api/feature-flag-data-api';
import { useGetCurrencyDataQuery } from '../state/api/currency-data-api';
import { useGetProjectQuery, useUpdateProjectSettingsMutation } from '../state/api/project-model-data-api';
import { footerButtonStyle } from '../layout/LayoutGrid.stylesheet';

const { BODY_BOLD } = typographyVariants;

const stylesFormControl = {
  display: 'flex',
  flexDirection: 'row',
  ml: spacings?.XL,
};

export type ProjectSettingsModalProps = {
  projectId: string;
  open?: any;
  onCloseClick?: any;
  onApplyClick?: any;
};

const ProjectSettingsModal: React.FC<ProjectSettingsModalProps> = (props) => {
  const { open } = props;

  // Redux Toolkit (Queries/Mutations)
  const { data: featureFlagData } = useGetUserFeatureFlagDataQuery();
  const { data: currencyData } = useGetCurrencyDataQuery();
  const { data: projectData } = useGetProjectQuery(props?.projectId, { skip: !props?.projectId });
  const [updateProjectSettings] = useUpdateProjectSettingsMutation();


  const initialState = {
    errorElecInput: false,
    errorGasInput: false,
    applyButtonDisabled: true,
    carbonEmissionUnitSymbol: conversions.GetSymbol(UnitEnum.KgCO2e) + ' / ' + conversions.GetSymbol(UnitEnum.Mbtu)
  };

  const [conversionRate, setConversionRate] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState(null);
  const [electRate, setElectRate] = useState(0);
  const [fuelRate, setFuelRate] = useState(0);
  const [currenciesIso, setcurrenciesIso] = useState([]);
  const [isMetric, setIsMetric] = useState(false);
  const [currencyIso, setCurrencyIso] = useState(null);
  const [state, setState] = useState(initialState);

  const setDefaultState = () => {
    const currenciesIso = currencyData?.map((currency) => {
      return currency.currencyIso;
    });

    const currentCurrency = currencyData?.find((x) => x.currencyIso === projectData?.currencyIso);
    const electRateConverted = conversions.CovertFromBaseCurrency(currentCurrency.conversionRate, projectData?.electricityRate);
    const fuelRateConverted = conversions.CovertFromBaseCurrency(currentCurrency.conversionRate, projectData?.fuelRate);

    setIsMetric(projectData?.useSIUnits);
    setCurrencyIso(projectData?.currencyIso);
    setcurrenciesIso(currenciesIso);
    setConversionRate(currentCurrency?.conversionRate);
    setCurrencySymbol(currencyData?.find((x) => x.currencyIso === projectData?.currencyIso)?.symbol);
    setElectRate(electRateConverted);
    setFuelRate(projectData?.useSIUnits ? fuelRateConverted : conversions.round(fuelRateConverted * conversions.ThermstoKwh(1), 4));
  }

  useEffect(() => {
    if (!projectData || !currencyData) {
      return;
    }
    setDefaultState();

  }, [currencyData, projectData]);

  const convertToNewCurrency = (lastCurrencyIso: string, newCurrencyIso: string, value: number) => {
    const newCurrency = currencyData.find((x) => x.currencyIso === newCurrencyIso);
    const lastCurrency = currencyData.find((x) => x.currencyIso === lastCurrencyIso);
    const usdValue = conversions.CovertToBaseCurrency(lastCurrency.conversionRate, value);
    return conversions.CovertFromBaseCurrency(newCurrency.conversionRate, usdValue);
  };

  const onMetricChange = () => {
    setState((prevState) => ({
      ...prevState,
      errorElecInput: false,
      errorGasInput: false,
      applyButtonDisabled: false,
    }));

    setIsMetric(!isMetric);
    setFuelRate(!isMetric ? fuelRate / conversions.ThermstoKwh(1) : fuelRate * conversions.ThermstoKwh(1));
  };

  const onCurrencyChange = (event: SelectChangeEvent) => {
    const { value: newCurrencyIso } = event.target;
    const lastCurrencyIso = currencyIso;
    const newCurrency = currencyData?.find((x) => x.currencyIso === newCurrencyIso);

    setState((prevState) => ({
      ...prevState,
      applyButtonDisabled: false,
    }));
    setCurrencyIso(newCurrencyIso);
    setCurrencySymbol(newCurrency.symbol);
    setConversionRate(newCurrency.conversionRate);
    setElectRate(convertToNewCurrency(lastCurrencyIso, newCurrencyIso, electRate))
    setFuelRate(convertToNewCurrency(lastCurrencyIso, newCurrencyIso, fuelRate))
  };

  const onChangenElectRate = (e) => {
    const value = e.target.value.replace(/(?!-)[^0-9.]/g, '');

    setState((prevState) => ({
      ...prevState,
      errorElecInput: value === '',
      applyButtonDisabled: false,
    }));

    setElectRate(value);
  };

  const onChangeGasRate = (e) => {
    const value = e.target.value.replace(/(?!-)[^0-9.]/g, '');

    setState((prevState) => ({
      ...prevState,
      errorGasInput: value === '',
      applyButtonDisabled: false,
    }));

    setFuelRate(value);
  };

  const onApplyClickHandler = () => {
    const fuelRateUsd = conversions.CovertToBaseCurrency(conversionRate, fuelRate);
    const elecRateUsd = conversions.CovertToBaseCurrency(conversionRate, electRate);
    const fuelRateValue = isMetric
      ? fuelRateUsd
      : conversions.round(fuelRateUsd / conversions.ThermstoKwh(1), 4);

    if (fuelRate !== null && electRate !== null) {
      updateProjectSettings({
        projectId: props.projectId,
        useSiUnits: isMetric,
        currencyIso,
        electricityRate: parseFloat(String(elecRateUsd)),
        fuelRate: parseFloat(String(fuelRateValue)),

      })

      props.onApplyClick(
        isMetric,
        currencyIso,
        parseFloat(String(fuelRateValue)),
        parseFloat(String(elecRateUsd)),
      );
    }

    setState((prevState) => ({
      ...prevState,
      applyButtonDisabled: true,
    }));
  };

  const onCloseClickHandler = () => {
    setState(initialState);
    setDefaultState();
    props.onCloseClick();
  };

  if (!projectData || !currencyData) {
    return;
  }

  const renderFooter = () => {
    return (
      <Box
        display="flex"
        justifyContent="end"
        gap={3}>
        <Button
          onClick={onCloseClickHandler}
          variant={buttonVariants.OUTLINED}
        >
          {i18n.t(locConsts.FACTORS_MODAL_BUTTON_SECONDARY)}
        </Button>
        <Button sx={{ ...footerButtonStyle }} onClick={onApplyClickHandler.bind(this)} disabled={state.applyButtonDisabled}>
          {i18n.t('projectSettings.buttonPrimary')}
        </Button>
      </Box>
    )
  };

  const renderContent = () => {
    return (
      <Box>
        <Box>
          <Typography variant={BODY_BOLD} className="settings-section-title">
            {i18n.t('projectSettings.units')}
          </Typography>
          <Box className="radio-button-container">
            <RadioButton
              checked={!isMetric}
              disabled={false}
              name="unit-radio-button"
              id="imperial-radio-button"
              onChange={onMetricChange}
            />
            <FormLabel htmlFor="imperial-radio-button" className="settings-label">
              {i18n.t('projectSettings.unitsImperial')}
            </FormLabel>
          </Box>
          <Box className="radio-button-container">
            <RadioButton
              checked={isMetric}
              disabled={false}
              name="unit-radio-button"
              id="metric-radio-button"
              onChange={onMetricChange}
            />
            <FormLabel htmlFor="metric-radio-button" className="settings-label">
              {i18n.t('projectSettings.unitsMetric')}
            </FormLabel>
          </Box>
        </Box>
        {featureFlagData && featureFlagData['display-energy-workflow'] && (
          <>
            <Divider variant="fullWidth" className="settings-modal-divider" />
            <Box>
              <Typography variant={BODY_BOLD} className="settings-section-title">
                {i18n.t('projectSettings.currency')}
              </Typography>
              <FormControl sx={stylesFormControl} className="settings-input-group">
                <FormLabel sx={{ pl: '0px' }} htmlFor="currency" className="settings-label">
                  {i18n.t('projectSettings.currency')}
                </FormLabel>
                <Select
                  labelId="currency"
                  id="currency"
                  value={currencyIso}
                  onChange={onCurrencyChange}
                  sx={{ width: '100px' }}
                  MenuProps={{
                    style: {
                      zIndex: 15000,
                    },
                  }}
                >
                  {currenciesIso?.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Divider variant="fullWidth" className="settings-modal-divider" />
            <Box>
              <Typography variant={BODY_BOLD} className="settings-section-title">
                {i18n.t('projectSettings.utilityRates')}
              </Typography>
              <FormControl
                sx={{ ...stylesFormControl, mb: spacings.S }}
                className="settings-input-group"
              >
                <FormLabel sx={{ pl: '0px' }} htmlFor="elec" className="settings-label">
                  {i18n.t('projectSettings.elec')} {currencySymbol}
                </FormLabel>
                <Box className="settings-input-text">
                  <TextField
                    id="elec"
                    value={electRate}
                    onChange={onChangenElectRate}
                    error={state.errorElecInput}
                  />
                </Box>
                <FormLabel className="settings-utility-label">
                  {isMetric
                    ? i18n.t('projectSettings.elecMetricUnit')
                    : i18n.t('projectSettings.elecImperialUnit')}
                </FormLabel>
              </FormControl>
              <FormControl sx={stylesFormControl} className="settings-input-group">
                <FormLabel sx={{ pl: '0px' }} htmlFor="gas" className="settings-label">
                  {i18n.t('projectSettings.gas')} {currencySymbol}
                </FormLabel>
                <Box className="settings-input-text">
                  <TextField
                    id="gas"
                    value={fuelRate}
                    onChange={onChangeGasRate}
                    error={state.errorGasInput}
                  />
                </Box>
                <FormLabel className="settings-utility-label">
                  {isMetric
                    ? i18n.t('projectSettings.gasMetricUnit')
                    : i18n.t('projectSettings.gasImperialUnit')}
                </FormLabel>
              </FormControl>
            </Box>
          </>
        )}
      </Box>
    )
  };

  return (
    <Modal
      header={i18n.t('projectSettings.title')}
      onClose={onCloseClickHandler.bind(this)}
      open={open || false}
      sx={{ '& .MuiModalWeave-box': { width: '440px', } }}
      actions={renderFooter()}
    >
      {renderContent()}
    </Modal>
  );
};

export default ProjectSettingsModal;
