/**
 * Color utils which helps ColorGenerator.ts with various transformation
 */


/**
 * 
 * @param RGB color which connverts to HSV
 * @returns HSV format color
 */
export const RGBToHSV = (rgb: RGB): HSV => {
    let r = rgb.r / 255;
    let g = rgb.g / 255;
    let b = rgb.b / 255;

    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, v = max;

    var d = max - min;
    s = max === 0 ? 0 : d / max;

    if (max === min) {
        h = 0; // achromatic
    } else {
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }

        h /= 6;
    }

    return { h, s, v };
}

/**
 * 
 * @param hsv Gets HSV format color
 * @returns RGB object
 */
export const HSVtoRGB = (hsv: HSV): RGB => {
    let HH: number = hsv.h;
    let SS: number = hsv.s;
    let VV: number = hsv.v;

    if (SS <= 0.0) {
        return { r: 0, g: 0, b: 0 };
    }
    else if (SS > 1.0) {
        SS = 1.0;
    }


    if (HH < 0) {
        HH = 0.0;
    }
    else if (HH > 360.0) {
        HH = 360.0;
    }
    if (VV < 0) {
        VV = 0.0;
    }
    else if (VV > 1.0) {
        VV = 1.0;
    }

    HH /= 60.0;
    let H1: number = Math.floor(HH);

    let V1: number = VV * 255;
    let dH = HH - H1;
    let pp = V1 * (1.0 - SS);
    let qq = V1 * (1.0 - SS * dH);
    let tt = V1 * (1.0 - SS * (1.0 - dH));

    let RR = 0, GG = 0, BB = 0;

    switch (H1) {
        case 0:
            RR = V1;
            GG = tt;
            BB = pp;
            break;
        case 1:
            RR = qq;
            GG = V1;
            BB = pp;
            break;
        case 2:
            RR = pp;
            GG = V1;
            BB = tt;
            break;
        case 3:
            RR = pp;
            GG = qq;
            BB = V1;
            break;
        case 4:
            RR = tt;
            GG = pp;
            BB = V1;
            break;
        case 5:
        default:
            RR = V1;
            GG = pp;
            BB = qq;
            break;
    }
    return { r: Math.round(Math.min(RR + .5, 255)), g: Math.round(Math.min(GG + .5, 255)), b: Math.round(Math.min(BB + .5, 255)) };
}
/**
 * 
 * @param rgb RGB formar Color which gets converted to hex string
 * @param withNumberSign deafult is true
 * @returns hex string format
 */
export const RGBToHEX = (rgb: RGB, withNumberSign: boolean = true): string => {
    const rHex1 = rgb.r.toString(16);
    const gHex1 = rgb.g.toString(16);
    const bHex1 = rgb.b.toString(16);

    const rHex2 = `0${rHex1}`.slice(-2);
    const gHex2 = `0${gHex1}`.slice(-2);
    const bHex2 = `0${bHex1}`.slice(-2);

    return withNumberSign
        ? `#${rHex2}${gHex2}${bHex2}`
        : rHex2 + gHex2 + bHex2;
}

/**
 * "Red Green Blue" color space
 */
export type RGB = {
    /**
     * Red
     */
    r: number;

    /**
     * Green
     */
    g: number;

    /**
     * Blue
     */
    b: number;

    /**
     * Alpha
     */
    a?: number;
};

/**
 * "Hue Saturation Brightness" color space
 */
export type HSV = {
    /**
     * Hue
     */
    h: number;

    /**
     * Saturation
     */
    s: number;

    /**
     * Brightness
     */
    v: number;

    /**
     * Alpha
     */
    a?: number;
};

