export const INIT_DASHBOARD_STARTED = 'INIT_DASHBOARD_STARTED';
export const INIT_DASHBOARD_SUCCESS = 'INIT_DASHBOARD_SUCCESS';
export const DASHBOARD_RANGE_CHANGED = 'DASHBOARD_RANGE_CHANGED';
export const SORT_DASHBOARD = 'SORT_DASHBOARD';
export const UPDATE_DASHBOARD_FACTORS = 'UPDATE_DASHBOARD_FACTORS';
export const UPDATE_DASHBOARD_RESULTS = 'UPDATE_DASHBOARD_RESULTS';
export const UPDATE_DASHBOARD_METRIC = 'UPDATE_DASHBOARD_METRIC';
export const DASHBOARD_ENERGY_METRIC = 'DASHBOARD_ENERGY_METRIC';
export const DASHBOARD_COST_METRIC = 'DASHBOARD_COST_METRIC';
export const UPDATE_DASHBOARD_LMV = 'UPDATE_DASHBOARD_LMV';
export const UPDATE_DASHBOARD_SETTINGS = 'UPDATE_DASHBOARD_SETTINGS';
export const UPDATED_PROJECT_MODELS_ON_DASHBOARD = 'UPDATED_PROJECT_MODELS_ON_DASHBOARD';
export const SET_EC_DATA_SUCCESS = 'SET_EC_DATA_SUCCESS';
export const UPDATED_PROJECT_NAME_ON_DASHBOARD = 'UPDATED_PROJECT_NAME_ON_DASHBOARD';
export const SET_EC_DEFINITION = 'SET_EMBODIED_CARBON_DEFINITION';
