import React, { Component } from 'react';

export class Infiltration extends Component {

    render() {
        return <svg
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 16 16"
            style={{
                // @ts-ignore: unrecognizable type
                enableBackground: "new 0 0 16 16"
            }}
            xmlSpace="preserve"
            className="factor-modal-icon"
        >
            <style type="text/css">
                {
                    "\n\t.st0{fill:#7C7C7C;}\n\t.st1{fill:none;}\n\t.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#7C7C7C;}\n"
                }
            </style>
            <rect x={6.7} y={6} className="factor-modal-icon-color" width={2.7} height={1.3} />
            <rect x={6.7} y={6} className="factor-modal-icon-no-fill" width={2.7} height={1.3} />
            <polygon
                className="factor-modal-icon-color factor-modal-icon-rule"
                points="9.3,8 8.8,8 8.7,8 7.3,8 7.2,8 6.7,8 6.7,10 9.3,10 "
            />
            <polygon
                className="factor-modal-icon-no-fill"
                points="9.3,8 8.8,8 8.7,8 7.3,8 7.2,8 6.7,8 6.7,10 9.3,10 "
            />
            <rect x={6} y={5} className="st1" width={4} height={6} />
            <polygon className="factor-modal-icon-color factor-modal-icon-rule" points="11,1 15,5 15,1 " />
            <polygon className="factor-modal-icon-color factor-modal-icon-rule" points="1,15 5,15 1,11 " />
            <path
                className="factor-modal-icon-color factor-modal-icon-rule"
                d="M14.8,6.3l-1.4,1.4c0.2,3-2.1,5.5-5.1,5.7c-0.2,0-0.4,0-0.6,0l-1.4,1.4C6.9,14.9,7.4,15,8,15c3.9,0,7-3.1,7-7 C15,7.4,14.9,6.9,14.8,6.3"
            />
            <path
                className="factor-modal-icon-no-fill"
                d="M14.8,6.3l-1.4,1.4c0.2,3-2.1,5.5-5.1,5.7c-0.2,0-0.4,0-0.6,0l-1.4,1.4C6.9,14.9,7.4,15,8,15c3.9,0,7-3.1,7-7 C15,7.4,14.9,6.9,14.8,6.3"
            />
            <path
                className="factor-modal-icon-color factor-modal-icon-rule"
                d="M4.2,4.2c1.1-1.1,2.6-1.7,4.1-1.6l1.4-1.4C9.1,1.1,8.6,1,8,1C4.1,1,1,4.1,1,8c0,0.6,0.1,1.1,0.2,1.7l1.4-1.4 C2.5,6.8,3.1,5.3,4.2,4.2"
            />
            <path
                className="factor-modal-icon-no-fill"
                d="M4.2,4.2c1.1-1.1,2.6-1.7,4.1-1.6l1.4-1.4C9.1,1.1,8.6,1,8,1C4.1,1,1,4.1,1,8c0,0.6,0.1,1.1,0.2,1.7l1.4-1.4 C2.5,6.8,3.1,5.3,4.2,4.2"
            />
            <path className="factor-modal-icon-color factor-modal-icon-rule" d="M5,4v1v6v1h6v-1V5V4H5z M10,11H6V5h4V11z" />
        </svg>;
    }
}
