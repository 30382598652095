import { Metric, UnitSystem } from '../metrics';

/*
* API OBJECTS USED TO CALL DATA SERVICE API.
* DO NOT ADD INTERNAL LOGIC TO THIS OBJECTS
* */

export interface CreateMetricRequest {
  name: string,
  description?: string,
  formula: string,
  dataType: string,
  unit: string,
  isImperial: boolean
}

export interface UpdateMetricRequest {
  id: string;
  displayName?: string;
  description?: string;
  formula?: string;
  unit?: string;
}

export const toCreateMetricRequest = (metric: Metric): CreateMetricRequest => {
  return {
    name: metric.name,
    description: metric.description,
    formula: metric.formula,
    dataType: metric.dataType,
    unit: metric.unit,
    isImperial: metric.unitSystem === UnitSystem.Imperial
  }
}
