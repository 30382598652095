import { ForgeStandardErrorMessage } from '../types/errorData';

export const isForgeStandardError = (data: any): null | ForgeStandardErrorMessage => {
  return data?.title ? data : null;
};

export const printForgeStandardErrorDetails = (error: ForgeStandardErrorMessage): void => {
  console.error(`API Error Log - ${error?.logref}`, error)
};


