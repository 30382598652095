import Box from '@weave-mui/box';
import { typographyVariants, buttonVariants } from '@weave-mui/enums';
import Typography from '@weave-mui/typography';
import React, { useEffect, useRef } from 'react';
import { ReportData } from '../types/reporting';
import { AIA_DDX_REPORT_DATA } from '../aia-ddx/const';
import { ReportDataComponent } from '../components/ReportDataComponent';
import { useTheme } from '@weave-mui/styles';
import { ReportSidePanel } from '../components/ReportSidePanel';
import { useSelector } from 'react-redux';
import { useGetModelQuery, useGetProjectQuery } from '../../state/api/project-model-data-api';
import { ApplicationDataState } from '../../state/slice/application-data-slice';
import { useComputed, useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import Button from '@weave-mui/button';
import { CaretLeftMUI } from '@weave-mui/icons-weave';
import { useNavigate } from 'react-router-dom';
import i18n from '../../i18n';
import { useGetLocationQuery } from '../../state/api/location-data-api';
import { reportValidationSignal } from '../utils/report-validation';

export const ReportPage: React.FC = () => {
  useSignals();
  const theme = useTheme();
  const navigate = useNavigate();
  // todo: detremine report type from query string
  const reportData = useRef<ReportData<any>>(AIA_DDX_REPORT_DATA);
  const dataIsLoading = useSignal(true);
  const reportSubmitted = useSignal(false);
  const reportDataIsValid = useComputed(() => reportValidationSignal.value.includes(modelId));

  const modelId = useSelector(
    (state: { applicationDataState: ApplicationDataState }) =>
      state.applicationDataState.currentModelId
  );
  const projectId = useSelector(
    (state: { applicationDataState: ApplicationDataState }) =>
      state.applicationDataState.currentProjectId
  );

  const { data: model } = useGetModelQuery(modelId, { skip: !modelId });
  const { data: project } = useGetProjectQuery(projectId, { skip: !projectId });
  const { data: location } = useGetLocationQuery(
    { lng: Number(model?.longitude), lat: Number(model?.latitude) },
    { skip: !model }
  );

  const navigateBack = () => {
    projectId && navigate(`/projects/${projectId}/model`);
  };

  useSignalEffect(() => {
    if (reportSubmitted.value || !reportDataIsValid.value) {
      navigateBack();
    }
  });

  useEffect(() => {
    // pre populate any data that relies on model of project
    if (model && project && location && reportDataIsValid.value) {
      reportData.current.sections.forEach((section) => {
        const isMandatory = section.sectionIsMandatory;
        section.fields.forEach((field) => {
          field.isRequired.value = isMandatory;
          if (field.type === 'select') {
            field.data.value = field.options?.find((option) => option?.isDefault)?.name;
          }

          if (field.dataCalculation?.isFromModel) {
            field.data.value = field.dataCalculation.isFromModel(model).value;
          }

          if (field.dataCalculation?.isFromLocation) {
            field.data.value = field.dataCalculation.isFromLocation(location).value;
          }

          if (field.dataCalculation?.isFromProject) {
            field.data.value = field.dataCalculation.isFromProject(project).value;
          }

          if (field.dataPersistance?.get) {
            field.data.value = field.dataPersistance.get();
          }

          if (field.dataCalculation?.dataPointId && field.dataCalculation?.isUserDefined) {
            field.dataCalculation.dataPointId.value = '';
            field.dataSourceName.value = '';
            field.data.value = '';
            field.dataType = '';
          }
        });

        dataIsLoading.value = false;
      });
    }
  }, [model, project, location, reportDataIsValid.value]);

  return (
    (reportDataIsValid.value && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '80px',
            justifyContent: 'center',
            alignItems: 'flex-start',
            background: theme.tokens.generic.color['light-gray'][250][100],
            paddingLeft: '5px',
          }}
        >
          <Button variant={buttonVariants.TEXT} startIcon={<CaretLeftMUI />} onClick={navigateBack}>
            {i18n.t('reporting.buttons.back')}
          </Button>
          <Typography sx={{ paddingLeft: '15px' }} variant={typographyVariants.H3_MEDIUM}>
            {reportData.current.heading}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              width: '300px',
              display: 'flex',
              flexDirection: 'column',
              flexShrink: 0,
              background: theme.tokens.generic.color['light-gray'][200][100],
              alignItems: 'center',
            }}
          >
            <ReportSidePanel dataIsLoading={dataIsLoading} reportData={reportData.current} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              overflow: 'auto',
            }}
          >
            <ReportDataComponent
              reportData={reportData.current}
              dataIsLoading={dataIsLoading}
              reportSubmitted={reportSubmitted}
            />
          </Box>
        </Box>
      </Box>
    )) || <></>
  );
};
