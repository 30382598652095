import React, { FC, PropsWithChildren } from "react";
import { ApplicationUnavailablePage } from "../shared/Error/ErrorComponent";
import ErrorLogger from "../errorLogger";
import { ErrorBoundary } from "react-error-boundary";

const logError = (error: Error, info: { componentStack: string }) => {
  if(error){
    ErrorLogger.logError(error);
  }
};

const GlobalErrorHandler:FC<PropsWithChildren<{}>> = ({children}) => {
  return <ErrorBoundary
    FallbackComponent={ApplicationUnavailablePage}
    onError={logError}
  >
    {children}
  </ErrorBoundary>
}

export default GlobalErrorHandler;
