export const ANALYSIS_PAGE_MODAL_BASE_FAILED = 'AnalysisPageModalBaseFailed';
export const ANALYSIS_PAGE_MODAL_BASE_FAILED_DESC = 'AnalysisPageBaseFailedDesc';
export const ANALYSIS_PAGE_OVERVIEW_TAB = 'AnalysisPageOverviewTab';
export const ANALYSIS_PAGE_SIMULATIONS_TAB = 'AnalysisPageSimulationsTab';
export const ANALYSIS_PAGE_OPTIMIZATION_TAB = 'AnalysisPageOptimizationTab';
export const ANALYSIS_PAGE_ECBREAKDOWN_TAB = 'AnalysisPageECBreakdownTab';

export const ANALYSIS_PAGE_CHOOSE_FACTORS = 'AnalysisPageChooseFactors';

export const OVERVIEW_TAB_LOCATION = 'OverviewTabLocation';

export const RUNS_TABLE_RUNS = 'RunsTableRuns';
export const RUNS_TABLE_ENERGY_USE = 'RunsTableEnergyUse';
export const RUNS_TABLE_ENERGY_COST = 'RunsTableEnergyCost';
export const RUNS_TABLE_INTENSITY = 'RunsTableIntensity';
export const RUNS_TABLE_ELECTRICAL_MWH = 'RunsTableElectricalMWH';
export const RUNS_TABLE_ELECTRICAL_KBTU = 'RunsTableElectricalKBTU';
export const RUNS_TABLE_FUEL_MWH = 'RunsTableFuelMWH';
export const RUNS_TABLE_FUEL_KBTU = 'RunsTableFuelKBTU';
export const RUNS_TABLE_ELECTRICAL_KWH_M2 = 'RunsTableElectricalKWHM2';
export const RUNS_TABLE_ELECTRICAL_KBTU_FT2 = 'RunsTableElectricalKBTUFT2';
export const RUNS_TABLE_FUEL_KWH_M2 = 'RunsTableFuelKWHM2';
export const RUNS_TABLE_FUEL_KBTU_FT2 = 'RunsTableFuelKBTUFT2';
export const RUNS_TABLE_TOTAL_KWH_M2 = 'RunsTableTotalKWHM2';
export const RUNS_TABLE_TOTAL_KBTU_FT2 = 'RunsTableTotalKBTUFT2';
export const RUNS_TABLE_ELECTRICAL_COST = 'RunsTableElectricalCost';
export const RUNS_TABLE_FUEL_COST = 'RunsTableFuelCost';
export const RUNS_TABLE_ELECTRICAL_COST_M2 = 'RunsTableElectricalCostM2';
export const RUNS_TABLE_ELECTRICAL_COST_FT2 = 'RunsTableElectricalCostFT2';
export const RUNS_TABLE_FUEL_COST_MT2 = 'RunsTableFuelCostMT2';
export const RUNS_TABLE_FUEL_COST_FT2 = 'RunsTableFuelCostFT2';
export const RUNS_TABLE_TOTAL_COST_M2 = 'RunsTableTotalCostM2';
export const RUNS_TABLE_TOTAL_COST_FT2 = 'RunsTableTotalCostFT2';

export const OPTIMIZATION_TAB_RUNS_FAILED = 'OptimizationTabRunsFailed';
export const OPTIMIZATION_TAB_RUNS_FAILED_DESC = 'OptimizationTabRunsFailedDesc';
export const OPTIMIZATION_TAB_Y_TITLE = 'OptimizationTabYTitle';
export const OPTIMIZATION_TAB_MY_MODEL = 'OptimizationTabMyModel';
export const OPTIMIZATION_TAB_BASE_PROCESSING = 'OptimizationTabBaseProcessing';
export const OPTIMIZATION_TAB_BASE_PROCESSING_DESC = 'OptimizationTabBaseProcessingDesc';
export const OPTIMIZATION_TAB_BASE_COMPLETED = 'OptimizationTabBaseCompleted';
export const OPTIMIZATION_TAB_BASE_COMPLETED_DESC = 'OptimizationTabBaseCompletedDesc';

export const SIMULATIONS_MAP_BASE_SHORT = 'SimulationsMapBaseShort';
export const SIMULATIONS_MAP_ACVRF_DOAS_SHORT = 'SimulationsMapAcvrfDoasShort';
export const SIMULATIONS_MAP_ACVRF_DOAS_LONG = 'SimulationsMapAcvrfDoasLong';
export const SIMULATIONS_MAP_PTAC_SHORT = 'SimulationsMapPtacShort';
export const SIMULATIONS_MAP_PTAC_LONG = 'SimulationsMapPtacLong';
export const SIMULATIONS_MAP_VAV_SHORT = 'SimulationsMapVavShort';
export const SIMULATIONS_MAP_VAV_LONG = 'SimulationsMapVavLong';
export const SIMULATIONS_MAP_FPFC_DOAS_SHORT = 'SimulationsMapFpfcDoasShort';
export const SIMULATIONS_MAP_FPFC_DOAS_LONG = 'SimulationsMapFpfcDoasLong';
export const SIMULATIONS_MAP_PTHP_SHORT = 'SimulationsMapPthpShort';
export const SIMULATIONS_MAP_PTHP_LONG = 'SimulationsMapPthpLong';
export const SIMULATIONS_MAP_PVAV_SHORT = 'SimulationsMapPvavShort';
export const SIMULATIONS_MAP_PVAV_LONG = 'SimulationsMapPvavLong';
export const SIMULATIONS_MAP_0_SHORT = 'SimulationsMap0Short';
export const SIMULATIONS_MAP_45_SHORT = 'SimulationsMap45Short';
export const SIMULATIONS_MAP_90_SHORT = 'SimulationsMap90Short';
export const SIMULATIONS_MAP_135_SHORT = 'SimulationsMap135Short';
export const SIMULATIONS_MAP_180_SHORT = 'SimulationsMap180Short';
export const SIMULATIONS_MAP_225_SHORT = 'SimulationsMap225Short';
export const SIMULATIONS_MAP_270_SHORT = 'SimulationsMap270Short';
export const SIMULATIONS_MAP_315_SHORT = 'SimulationsMap315Short';
export const SIMULATIONS_MAP_PLE03_SHORT = 'SimulationsMapPle03Short';
export const SIMULATIONS_MAP_PLE03_SHORT_SI = 'SimulationsMapPle03ShortSI';
export const SIMULATIONS_MAP_PLE07_SHORT = 'SimulationsMapPle07Short';
export const SIMULATIONS_MAP_PLE07_SHORT_SI = 'SimulationsMapPle07ShortSI';
export const SIMULATIONS_MAP_PLE11_SHORT = 'SimulationsMapPle11Short';
export const SIMULATIONS_MAP_PLE11_SHORT_SI = 'SimulationsMapPle11ShortSI';
export const SIMULATIONS_MAP_PLE15_SHORT = 'SimulationsMapPle15Short';
export const SIMULATIONS_MAP_PLE15_SHORT_SI = 'SimulationsMapPle15ShortSI';
export const SIMULATIONS_MAP_PLE19_SHORT = 'SimulationsMapPle19Short';
export const SIMULATIONS_MAP_PLE19_SHORT_SI = 'SimulationsMapPle19ShortSI';
export const SIMULATIONS_MAP_LE06_SHORT = 'SimulationsMapLe06Short';
export const SIMULATIONS_MAP_LE06_SHORT_SI = 'SimulationsMapLe06ShortSI';
export const SIMULATIONS_MAP_LE1_SHORT = 'SimulationsMapLe1Short';
export const SIMULATIONS_MAP_LE1_SHORT_SI = 'SimulationsMapLe1ShortSI';
export const SIMULATIONS_MAP_LE13_SHORT = 'SimulationsMapLe13Short';
export const SIMULATIONS_MAP_LE13_SHORT_SI = 'SimulationsMapLe13ShortSI';
export const SIMULATIONS_MAP_LE16_SHORT = 'SimulationsMapLe16Short';
export const SIMULATIONS_MAP_LE16_SHORT_SI = 'SimulationsMapLe16ShortSI';
export const SIMULATIONS_MAP_LE19_SHORT = 'SimulationsMapLe19Short';
export const SIMULATIONS_MAP_LE19_SHORT_SI = 'SimulationsMapLe19ShortSI';
export const SIMULATIONS_MAP_LE2_SHORT = 'SimulationsMapLe2Short';
export const SIMULATIONS_MAP_LE2_SHORT_SI = 'SimulationsMapLe2ShortSI';
export const SIMULATIONS_MAP_WC_ALL_12_25_SHORT = 'SimulationsMapWcAll1225Short';
export const SIMULATIONS_MAP_WC_ALL_14_SHORT = 'SimulationsMapWcAll14Short';
export const SIMULATIONS_MAP_WC_ALL_R13M_SHORT = 'SimulationsMapWcAllR13mShort';
export const SIMULATIONS_MAP_WC_ALL_R13W_SHORT = 'SimulationsMapWcAllR13wShort';
export const SIMULATIONS_MAP_WC_ALL_R13_R10_SHORT = 'SimulationsMapWcAllR13R10Short';
export const SIMULATIONS_MAP_WC_ALL_R2_SHORT = 'SimulationsMapWcAllR2Short';
export const SIMULATIONS_MAP_WC_ALL_R38_SHORT = 'SimulationsMapWcAllR38Short';
export const SIMULATIONS_MAP_WC_ALL_UNINSULATED_SHORT = 'SimulationsMapWcAllUninsulatedShort';
export const SIMULATIONS_MAP_RC_1025INCHSIP_SHORT = 'SimulationsMapRc1025inchsipShort';
export const SIMULATIONS_MAP_RC_R10_SHORT = 'SimulationsMapRcR10Short';
export const SIMULATIONS_MAP_RC_R15_SHORT = 'SimulationsMapRcR15Short';
export const SIMULATIONS_MAP_RC_R19_SHORT = 'SimulationsMapRcR19Short';
export const SIMULATIONS_MAP_RC_R38_SHORT = 'SimulationsMapRcR38Short';
export const SIMULATIONS_MAP_RC_R60_SHORT = 'SimulationsMapRcR60Short';
export const SIMULATIONS_MAP_RC_UNINSULATED_SHORT = 'SimulationsMapRcUninsulatedShort';
export const SIMULATIONS_MAP_GC_DBL_LOE_SHORT = 'SimulationsMapGc-Dbl-LoeShort';
export const SIMULATIONS_MAP_GC_SGL_CLR_SHORT = 'SimulationsMapGc-Sgl-ClrShort';
export const SIMULATIONS_MAP_GC_TRP_CLR_SHORT = 'SimulationsMapGc-Trp-ClrShort';
export const SIMULATIONS_MAP_GC_TRP_LOE_SHORT = 'SimulationsMapGc-Trp-LoeShort';
export const SIMULATIONS_MAP_WS_16 = 'SimulationsMapWs1/6';
export const SIMULATIONS_MAP_WS_14 = 'SimulationsMapWs1/4';
export const SIMULATIONS_MAP_WS_13 = 'SimulationsMapWs1/3';
export const SIMULATIONS_MAP_WS_12 = 'SimulationsMapWs1/2';
export const SIMULATIONS_MAP_WS_23 = 'SimulationsMapWs2/3';
export const SIMULATIONS_MAP_WWR_95 = 'SimulationsMapWwr95';
export const SIMULATIONS_MAP_WWR_80 = 'SimulationsMapWwr80';
export const SIMULATIONS_MAP_WWR_65 = 'SimulationsMapWwr65';
export const SIMULATIONS_MAP_WWR_50 = 'SimulationsMapWwr50';
export const SIMULATIONS_MAP_WWR_40 = 'SimulationsMapWwr40';
export const SIMULATIONS_MAP_WWR_30 = 'SimulationsMapWwr30';
export const SIMULATIONS_MAP_WWR_15 = 'SimulationsMapWwr15';
export const SIMULATIONS_MAP_WWR_0 = 'SimulationsMapWwr0';
export const SIMULATIONS_MAP_INF_24 = 'SimulationsMapInf24';
export const SIMULATIONS_MAP_INF_24_SI = 'SimulationsMapInf24SI';
export const SIMULATIONS_MAP_INF_21 = 'SimulationsMapInf21';
export const SIMULATIONS_MAP_INF_21_SI = 'SimulationsMapInf21SI';
export const SIMULATIONS_MAP_INF_18 = 'SimulationsMapInf18';
export const SIMULATIONS_MAP_INF_18_SI = 'SimulationsMapInf18SI';
export const SIMULATIONS_MAP_INF_15 = 'SimulationsMapInf15';
export const SIMULATIONS_MAP_INF_15_SI = 'SimulationsMapInf15SI';
export const SIMULATIONS_MAP_INF_12 = 'SimulationsMapInf12';
export const SIMULATIONS_MAP_INF_12_SI = 'SimulationsMapInf12SI';
export const SIMULATIONS_MAP_INF_09 = 'SimulationsMapInf09';
export const SIMULATIONS_MAP_INF_09_SI = 'SimulationsMapInf09SI';
export const SIMULATIONS_MAP_INF_06 = 'SimulationsMapInf06';
export const SIMULATIONS_MAP_INF_06_SI = 'SimulationsMapInf06SI';
export const SIMULATIONS_MAP_INF_03 = 'SimulationsMapInf03';
export const SIMULATIONS_MAP_INF_03_SI = 'SimulationsMapInf03SI';
export const SIMULATIONS_MAP_INTERNAL_LOAD_MIN = 'SimulationsMapInternalLoadMin';
export const SIMULATIONS_MAP_INTERNAL_LOAD_MAX = 'SimulationsMapInternalLoadMax';
export const SIMULATIONS_MAP_ENVELOPE_CALCULATION_MIN = 'SimulationsMapEnvelopeCalculationMin';
export const SIMULATIONS_MAP_ENVELOPE_CALCULATION_MAX = 'SimulationsMapEnvelopeCalculationMax';
export const SIMULATIONS_MAP_FORM_NORTH_MIN = 'SimulationsMapFormNorthMin';
export const SIMULATIONS_MAP_FORM_NORTH_MAX = 'SimulationsMapFormNorthMax';
export const SIMULATIONS_MAP_FORM_EAST_MIN = 'SimulationsMapFormEastMin';
export const SIMULATIONS_MAP_FORM_EAST_MAX = 'SimulationsMapFormEastMax';
export const SIMULATIONS_MAP_FORM_SOUTH_MIN = 'SimulationsMapFormSouthMin';
export const SIMULATIONS_MAP_FORM_SOUTH_MAX = 'SimulationsMapFormSouthMax';
export const SIMULATIONS_MAP_FORM_WEST_MIN = 'SimulationsMapFormWestMin';
export const SIMULATIONS_MAP_FORM_WEST_MAX = 'SimulationsMapFormWestMax';

export const FACTORS_MAP_BASE = 'FactorsMapBase';
export const FACTORS_MAP_HVAC = 'FactorsMapHvac';
export const FACTORS_MAP_HVAC_SIZING = 'FactorsMapHvacSizing';
export const FACTORS_MAP_ORIENTATION = 'FactorsMapOrientation';
export const FACTORS_MAP_PLUG_LOAD = 'FactorsMapPlugLoad';
export const FACTORS_MAP_LIGHTING = 'FactorsMapLighting';
export const FACTORS_MAP_WALL = 'FactorsMapWall';
export const FACTORS_MAP_ROOF = 'FactorsMapRoof';
export const FACTORS_MAP_GLAZING = 'FactorsMapGlazing';
export const FACTORS_MAP_GLAZING_NORTH = 'FactorsMapGlazingNorth';
export const FACTORS_MAP_GLAZING_EAST = 'FactorsMapGlazingEast';
export const FACTORS_MAP_GLAZING_SOUTH = 'FactorsMapGlazingSouth';
export const FACTORS_MAP_GLAZING_WEST = 'FactorsMapGlazingWest';
export const FACTORS_MAP_WWR = 'FactorsMapWwrWalls';
export const FACTORS_MAP_WWR_SOUTH = 'FactorsMapWwrSouthernWalls';
export const FACTORS_MAP_WWR_NORTH = 'FactorsMapWwrNorthernWalls';
export const FACTORS_MAP_WWR_WEST = 'FactorsMapWwrWesternWalls';
export const FACTORS_MAP_WWR_EAST = 'FactorsMapWwrEasternWalls';
export const FACTORS_MAP_WINDOW_SHADES = 'FactorsMapWindowShades';
export const FACTORS_MAP_WINDOW_SHADES_NORTH = 'FactorsMapWindowShadesNorth';
export const FACTORS_MAP_WINDOW_SHADES_EAST = 'FactorsMapWindowShadesEast';
export const FACTORS_MAP_WINDOW_SHADES_SOUTH = 'FactorsMapWindowShadesSouth';
export const FACTORS_MAP_WINDOW_SHADES_WEST = 'FactorsMapWindowShadesWest';
export const FACTORS_MAP_INFILTRATION = 'FactorsMapInfiltration';
export const FACTORS_MAP_INTERNAL_LOAD_MIN_MAX = 'FactorsMapInternalLoadMinMax';
export const FACTORS_MAP_ENVELOPE_CALCULATION_MIN_MAX = 'FactorsMapEnvelopeCalculationMinMax';
export const FACTORS_MAP_FORM_MIN_MAX = 'FactorsMapFormMinMax';
export const WIDGET_TITLE_MAP_HVAC = 'WidgetTitleMapHvac';
export const WIDGET_TITLE_MAP_ORIENTATION = 'WidgetTitleMapOrientation';
export const WIDGET_TITLE_MAP_PLUG_LOAD = 'WidgetTitleMapPlugLoad';
export const WIDGET_TITLE_MAP_LIGHTING = 'WidgetTitleMapLighting';
export const WIDGET_TITLE_MAP_WALL = 'WidgetTitleMapWall';
export const WIDGET_TITLE_MAP_ROOF = 'WidgetTitleMapRoof';
export const WIDGET_TITLE_MAP_GLAZING = 'WidgetTitleMapGlazing';
export const WIDGET_TITLE_MAP_GLAZING_NORTH = 'WidgetTitleMapGlazingNorth';
export const WIDGET_TITLE_MAP_GLAZING_EAST = 'WidgetTitleMapGlazingEast';
export const WIDGET_TITLE_MAP_GLAZING_SOUTH = 'WidgetTitleMapGlazingSouth';
export const WIDGET_TITLE_MAP_GLAZING_WEST = 'WidgetTitleMapGlazingWest';
export const WIDGET_TITLE_MAP_WINDOW_SHADES = 'WidgetTitleMapWindowShades';
export const WIDGET_TITLE_MAP_WINDOW_SHADES_NORTH = 'WidgetTitleMapWindowShadesNorth';
export const WIDGET_TITLE_MAP_WINDOW_SHADES_EAST = 'WidgetTitleMapWindowShadesEast';
export const WIDGET_TITLE_MAP_WINDOW_SHADES_SOUTH = 'WidgetTitleMapWindowShadesSouth';
export const WIDGET_TITLE_MAP_WINDOW_SHADES_WEST = 'WidgetTitleMapWindowShadesWest';
export const WIDGET_TITLE_MAP_WWR_SOUTH = 'WidgetTitleMapWwrSouth';
export const WIDGET_TITLE_MAP_WWR_NORTH = 'WidgetTitleMapWwrNorth';
export const WIDGET_TITLE_MAP_WWR_WEST = 'WidgetTitleMapWwrWest';
export const WIDGET_TITLE_MAP_WWR_EAST = 'WidgetTitleMapWwrEast';
export const WIDGET_TITLE_MAP_INFILTRATION = 'WidgetTitleInfiltration';

export const XAXIS_TITLE_MAP_HVAC = 'XaxisTitleMapHvac';
export const XAXIS_TITLE_MAP_ORIENTATION = 'XaxisTitleMapOrientation';
export const XAXIS_TITLE_MAP_EFFICIENCY = 'XaxisTitleMapEfficiency';
export const XAXIS_TITLE_MAP_EFFICIENCY_SI = 'XaxisTitleMapEfficiencySI';
export const XAXIS_TITLE_MAP_CONSTRUCTION = 'XaxisTitleMapConstruction';
export const XAXIS_TITLE_MAP_GLAZING = 'XaxisTitleMapGlazing';
export const XAXIS_TITLE_WINDOW_SHADES = 'XaxisTitleMapWindowShades';
export const XAXIS_TITLE_WWR_SOUTH = 'XaxisTitleMapWwrSouth';
export const XAXIS_TITLE_WWR_NORTH = 'XaxisTitleMapWwrNorth';
export const XAXIS_TITLE_WWR_WEST = 'XaxisTitleMapWwrWest';
export const XAXIS_TITLE_WWR_EAST = 'XaxisTitleMapWwrEast';
export const XAXIS_TITLE_INFILTRATION = 'XaxisTitleMapInfiltration';
export const XAXIS_TITLE_INFILTRATION_SI = 'XaxisTitleMapInfiltrationSI';

export const FACTORS_MODAL_TITLE = 'FactorsModalTitle';
export const FACTORS_MODAL_BUTTON_PRIMARY = 'FactorsModalButtonPrimary';
export const FACTORS_MODAL_BUTTON_SECONDARY = 'FactorsModalButtonSecondary';

export const OPTIONS_CHOICES_CONSTRUCTIONS_TITLE = 'OptionsChoicesConstructionsTitle';
export const OPTIONS_CHOICES_GLAZING_CONSTRUCTION_DESC = 'OptionsChoicesGlazingConstructionDesc';
export const OPTIONS_CHOICES_WALL_CONSTRUCTION_DESC = 'OptionsChoicesWallConstructionDesc';
export const OPTIONS_CHOICES_ROOF_CONSTRUCTION_DESC = 'OptionsChoicesRoofConstructionDesc';
export const OPTIONS_CHOICES_ENGINEERING_TITLE = 'OptionsChoicesEngineeringTitle';
export const OPTIONS_CHOICES_HVAC_DESC = 'OptionsChoicesHvacDesc';
export const OPTIONS_CHOICES_LIGHTING_EFFICIENCY_DESC = 'OptionsChoicesLightingEfficiencyDesc';
export const OPTIONS_CHOICES_PLUG_LOAD_EFFICIENCY_DESC = 'OptionsChoicesPlugLoadEfficiencyDesc';
export const OPTIONS_CHOICES_ARCHITECTURAL_TITLE = 'OptionsChoicesArchitecturalTitle';
export const OPTIONS_CHOICES_ORIENTATION_DESC = 'OptionsChoicesOrientationDesc';
export const OPTIONS_CHOICES_WINDOW_SHADES_DESC = 'OptionsChoicesWindowShadesDesc';
export const OPTIONS_CHOICES_WWR_DESC = 'OptionsChoicesWWRDesc';
export const OPTIONS_CHOICES_INFILTRATION_TITLE = 'OptionsChoicesInfiltrationTitle';
export const OPTIONS_CHOICES_INFILTRATION_DESC = 'OptionsChoicesInfiltrationDesc';

export const MODAL_POPUP_BUTTON_PRIMARY = 'ModalPopupButtonPrimary';
export const MODAL_POPUP_BUTTON_SECONDARY = 'ModalPopupButtonSecondary';

export const CHART_OPTIONS_TEXT_ENERGY = 'ChartOptionsTextEnergy';
export const CHART_OPTIONS_TEXT_COST = 'ChartOptionsTextCost';
export const CHART_OPTIONS_TEXT_CARBON = 'ChartOptionsTextCarbon';

export const SORT_MENU_TITLE = 'SortMenuTitle';
export const SORT_MENU_OPTIONS_IMPACT = 'SortMenuOptionsImpact';
export const SORT_MENU_OPTIONS_CATEGORY = 'SortMenuOptionsCategory';

export const SI_EC_BREAKDOWN_UNITS_QUANTITY = 'SiECBreakdownUnitsQuantity';
export const SI_EC_BREAKDOWN_UNITS_INTENSITY = 'SiECBreakdownUnitsIntensity';
export const SI_EC_BREAKDOWN_UNITS_CONSTRUCTIONKG = 'SiECBreakdownUnitsConstructionKg';
export const SI_EC_BREAKDOWN_UNITS_METERCUBE = 'SiECBreakdownUnitsMeterCube';
export const SI_EC_BREAKDOWN_UNITS_SQUAREMETER_RSI = 'SiECBreakdownUnitsSquareMeterRSI';

export const IMP_EC_BREAKDOWN_UNITS_QUANTITY = 'ImpECBreakdownUnitsQuantity';
export const IMP_EC_BREAKDOWN_UNITS_INTENSITY = 'ImpECBreakdownUnitsIntensity';
export const IMP_EC_BREAKDOWN_UNITS_CONSTRUCTIONKG = 'ImpECBreakdownUnitsConstructionKg';
export const IMP_EC_BREAKDOWN_UNITS_CUBEFT = 'ImpECBreakdownUnitsCubeFt';
export const IMP_EC_BREAKDOWN_UNITS_POUNDS = 'ImpECBreakdownUnitsPounds';
export const IMP_EC_BREAKDOWN_UNITS_FEET = 'ImpECBreakdownUnitsFeet';
export const IMP_EC_BREAKDOWN_UNITS_FTR = 'ImpECBreakdownUnitsSquareFeetR';

export const IMP_EC_RAW_UNITS_FEET = 'ImpECRawUnitsFeet';
export const IMP_EC_RAW_UNITS_SQFEET = 'ImpECRawUnitsSqFeet';
export const IMP_EC_RAW_UNITS_CUBEFT = 'ImpECRawUnitsCubeFt';
export const IMP_EC_RAW_UNITS_POUNDS = 'ImpECRawUnitsPounds';
export const IMP_EC_RAW_UNITS_SQFTR = 'ImpECRawUnitsSqFtR';

export const EC_BREAKDOWN_UNITS_CONSTRUCTIONT = 'ECBreakdownUnitsConstructionT';
export const EC_BREAKDOWN_UNITS_PERCENTAGE = 'ECBreakdownUnitsPercentage';

export const EC_BREAKDOWN_TABLE_HEADER_SURFACE_TYPE = 'ECBreakdownTableHeaderSurfaceType';
export const EC_BREAKDOWN_TABLE_HEADER_CONSTRUCTION_NAME = 'ECBreakdownTableHeaderConstructionName';
export const EC_BREAKDOWN_TABLE_HEADER_QUANTITY = 'ECBreakdownTableHeaderQuantity';
export const EC_BREAKDOWN_TABLE_HEADER_INTENSITY = 'ECBreakdownTableHeaderIntensity';
export const EC_BREAKDOWN_TABLE_HEADER_RESULT = 'ECBreakdownTableHeaderResult';
export const EC_BREAKDOWN_TABLE_HEADER_PERCENTAGE = 'ECBreakdownTableHeaderPercentage';

export const EC_BREAKDOWN_TABLE_ACCESSOR_SURFACE_TYPE = 'ecSurfaceType';
export const EC_BREAKDOWN_TABLE_ACCESSOR_CONSTRUCTION_NAME = 'ecConstructionName';
export const EC_BREAKDOWN_TABLE_ACCESSOR_QUANTITY = 'ecQuantity';
export const EC_BREAKDOWN_TABLE_ACCESSOR_INTENSITY = 'ecIntensity';
export const EC_BREAKDOWN_TABLE_ACCESSOR_RESULT = 'ecCRKg';
export const EC_BREAKDOWN_TABLE_ACCESSOR_PERCENTAGE = 'ecPercentage';
export const EC_DEFINITION_ASSIGNMENT_FAILURE = 'ecDefinitionAssignFailed';
export const EC_DEFINITION_DELETION_FAILED = 'ecDefinitionDeletionFailed';
export const EC_DELETE_DEFINITION_MODAL_TITLE = 'ecDeleteDefinitionModalTitle';
export const EC_DELETE_DEFINITION_MODAL_TEXT = 'ecDeleteDefinitionModalText';
export const EC_EDIT_DEFINITION_MODAL_TITLE = 'ecEditDefinitionModalTitle';
export const EC_EDIT_DEFINITION_MODAL_TEXT = 'ecEditDefinitionModalText';
export const EC_DELETE_DEFINITION_MODAL_PRIMARY_BN = 'ecDeleteDefinitionModalPrimaryBn';
export const EC_DELETE_DEFINITION_MODAL_SECONDARY_BN = 'ecDeleteDefinitionModalSecondaryBn';

export const EC_NOTIFICATION_DEFINITION_ADDED = 'ecNotificationDefinitionAdded';
export const EC_NOTIFICATION_DATABASE_UPDATE = 'ecNotificationDatabaseUpdate';
export const EC_NOTIFICATION_CHANGES_FAILURE = 'ecNotificationChangesFailure';
export const EC_NOTIFICATION_RESULTS_UPDATE_FAILURE = 'ecNotificationResultsUpdateFailure';
export const EC_NOTIFICATION_INHERITED_MAPPINGS =
  'Some materials in this model inherited embodied carbon (EC) definitions from other models in the current Insight folder.';
