import React from 'react';
import Typography, { typographyVariants } from '@weave-mui/typography';
import i18n from '../../../i18n';
import {
  cardErrorContainerStyles,
  cardStateContent,
  cardStateFonts,
} from './Card.stylesheet';
import { UnConfiguredCardProps } from '../../../types/layout';
import { ImageContainer, Img } from '../../LayoutGridCardPreview';

export const CardEmptyState: React.FC<UnConfiguredCardProps> = ({ currentSettings, iconUrl }) => {
  return (
    <div style={cardErrorContainerStyles}>
      <div style={cardStateContent}>
        <ImageContainer
          imgWidth={32}
          imgHeight={32}
          data-testid="emptycard-image-container"
          style={{}}
        >
          <Img data-testid="emptycard-preview-image" src={iconUrl} />
        </ImageContainer>
      </div>
      <div style={cardStateContent}>
        <Typography sx={cardStateFonts} variant={typographyVariants.BODY_REGULAR}>
          {i18n.t('cards.state.emptyStateText')}
        </Typography>
      </div>
    </div>
  );
};
