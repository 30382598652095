import HigProgressBar from '../../shared/higProgressBar/higProgressBar';
import React, { Component } from 'react';
import i18n from '../../i18n';
import ECDefinitionsQuickSearchWeave from './ECDefinitionsQuickSearchWeave';
import isequal from 'lodash.isequal';
import {
  EcDefinition,
  LmvLegendViewByOptions,
  LmvLegendData,
  ECDetailsPreference,
  FilteredConstructions,
  SortedColumn,
} from './types';
import { categoryColorsMap, getNextColor } from '../../shared/ColorGenerator/colorGenerator';
import { SetLMVLegend } from './Lmv/LMVECModel';
import * as modelType from '../dashboard.models';
import ECDetailsChartSection from './Widgets/Charts/ECDetailsChartSection';
import { EcChartType } from '../../charts/types';
import { RGB, RGBToHEX } from '../../shared/ColorGenerator/colorUtils';
import LmvSection from './Lmv/LmvSection';
import '../../css/new/ecdetails.css';
import '../../css/new/overview.css';
import GripperResize from '../../shared/GripperResize/GripperResize';
import {
  ecDefinitionsSelector,
  flattenConstructions,
  isAnalyzedOpening,
  sumAll,
} from './utils';
import {
  OpeningType,
  OPENING_CONSTRUCTION_ID_PREFIX,
  UNKNOWN_CONSTRUCTION_ID,
  SurfaceType,
} from '../../consts';
import {
  ECBreakdownConstruction,
  ECBreakdownOpening,
  MaterialEntity,
  ModelEmbodiedCarbonBreakdownEntity,
} from '../dashboard.models';
import { v4 as uuidv4 } from 'uuid';
import ECDetailsConstructionDataGrid from './ECDetailsConstructionDataGrid';
import DataGridContextProvider from './DataGridContext';

export type ECDetailsProps = {
  useSI: boolean;
  lmvStatus: string;
  urn: string;
  openAdvancedSearchWeave: (row: modelType.MaterialRow) => void;
  modelECBreakdown: modelType.ModelEmbodiedCarbonBreakdownEntity;
  ecDefinitions: Map<string, EcDefinition[]>;
  setEcDefinition: (materialSha: string, ecDefinition: EcDefinition) => void;
  forgeUnitsLoaded: boolean;
};

type DetailLevelList = {
  schematic: string[];
  detail: string[];
  unKnown: string[];
};

type ClassState = {
  legendConstructions: FilteredConstructions[];
  analyzedConstructions: FilteredConstructions[];
  isLoadingECBreakdown: boolean;
  ecDetailsPreference?: ECDetailsPreference;
  modelIdECBreakdown: string;
  lmvLegendData: LmvLegendData;
  constructionColorData: Map<string, string>;
  materialColorData: Map<string, string>;
  categoryColorData: Map<string, string>;
  selectedConstructionId: string;
  selectedMaterialSha: string;
};

export const INITIAL_LMV_WIDTH = 50;

const materialColumns = [
  i18n.t('analysis.ec.construction.density'),
  i18n.t('analysis.ec.construction.thermalPerformance'),
  i18n.t('analysis.ec.construction.definition'),
  i18n.t('analysis.ec.construction.coefficient'),
];

// breakdownItems: the list of constructions that we need to use to compute tha sum of areas, ec, etc
// mainItem: the construction that exists as "template"
// this pattern exists because:
// - normal surfaces, like exterior wall, have a number "x" of constructions "foo",
// with the same number of materials but different areas. We take a construction,
// usually first, as "template", copy the stuff that is identical across foo instances, and sum the rest:
// e.g, area = Σ(foo[n].area), n = [0, x]
// - openings are a special case. They usually contain one material per construction.
// However, because we aggregate them under a special construction,
// we have all materials gathered under one fake construction as mainItem, that will be shown in UI.
// Let's take 2 openings, foo and goo. They each have one material. The mainItem thus becomes fooGooConstruction with
// fooGooConstruction.materials = [foo.materials[0], goo.materials[0]] - because of this,
// the sum of data will no longer be computed across constructions like for surfaces, but across materials:
// e.g., area = Σ(fooGooConstruction.materials[n].area), n = [0, 2]
const createConstruction = <T extends ECBreakdownConstruction | ECBreakdownOpening>(
  breakdownItems: T[],
  mainItem: T,
  colorArr: RGB[]
): FilteredConstructions => {
  // Calculate the summary of each material by index
  const materialsSummary = Array.from({ length: mainItem.materials.length }, (x) => ({
    volume: null,
    mass: null,
    embodiedCarbon: null,
  }));
  const isExtWnd = isAnalyzedOpening((mainItem as ECBreakdownOpening).openingType);
  for (const i in breakdownItems) {
    for (const j in breakdownItems[i].materials) {
      const material = breakdownItems[i].materials[j];
      // because the openings have a fake construction that aggregates materials, the "main item" will have a bigger material number
      // than any item in breakdownItems; in the case of any other construction, they should be equal.
      const matSumIndex = isExtWnd ? i : j;
      if (!materialsSummary[matSumIndex]) {
        materialsSummary[matSumIndex] = { volume: 0, mass: 0, embodiedCarbon: 0 };
      }
      material.volume != null &&
        (materialsSummary[matSumIndex].volume += parseFloat(material.volume));
      material.mass != null && (materialsSummary[matSumIndex].mass += parseFloat(material.mass));
      material.ecDefinitionAssignment != null &&
        material.ecDefinitionAssignment.embodiedCarbon != null &&
        (materialsSummary[matSumIndex].embodiedCarbon +=
          material.ecDefinitionAssignment.embodiedCarbon);
    }
  }

  const totalArea = sumAll('area', breakdownItems);
  const totalEC = sumAll('embodiedCarbon', materialsSummary);
  let surfaceIdsArray: string[] = [];

  breakdownItems.forEach((c) => {
    surfaceIdsArray = [...surfaceIdsArray, ...c.surfaceIds];
  });
  let materialListColor: Map<string, string> = new Map<string, string>();

  const filtConstruction: FilteredConstructions = {
    constructionName: mainItem.constructionName,
    constructionId: mainItem.constructionId ?? UNKNOWN_CONSTRUCTION_ID + uuidv4(),
    constructionDescription: mainItem.constructionDescription,
    surfaceIds: surfaceIdsArray,
    detailLevel: mainItem.isSchematic === null ? 3 : mainItem.isSchematic === 'true' ? 1 : 2,
    totalThickness: mainItem.totalThickness,
    area: totalArea.toString(),
    totalVolume: sumAll('volume', materialsSummary)?.toString() || null,
    totalMass: sumAll('mass', materialsSummary)?.toString() || null,
    totalEmbodiedCarbon: totalEC,
    ecIntensity: totalEC === null ? null : (totalEC / totalArea).toString(),
    materials: [],
    surfaceType:
      (mainItem as ECBreakdownConstruction).surfaceType ??
      (mainItem as ECBreakdownOpening).openingType,
    isExterior: mainItem.isExterior,
    uValue: mainItem.uValue,
  };

  mainItem.materials.forEach((material, i) => {
    const mat: modelType.MaterialEntity = {
      density: material.density,
      description: material.description,
      id: material.id,
      name: material.name,
      sha: material.sha,
      thickness: material.thickness,
      uuid: material.uuid,
      ecDefinitionAssignment: material.ecDefinitionAssignment,
      volume: materialsSummary[i].volume,
      mass: materialsSummary[i].mass,
      ecIntensity:
        materialsSummary[i].embodiedCarbon === null
          ? null
          : materialsSummary[i].embodiedCarbon / parseFloat(material.area ?? filtConstruction.area),
      rValue: material.rValue,
      order: i,
      area: material.area,
      count: material.count,
    };
    if (!materialListColor.has(mat.name)) {
      let nextColor = getNextColor(colorArr);
      colorArr.push(nextColor);
      mat.color = RGBToHEX(nextColor);
      materialListColor.set(mat.name, mat.color);
    } else {
      mat.color = materialListColor.get(mat.name);
    }
    filtConstruction.materials.push(mat);
  });

  return filtConstruction;
};

const createFilteredOpening = (
  breakdownOpenings: ECBreakdownOpening[],
  isExterior: boolean,
  colorArr: RGB[]
): FilteredConstructions => {
  // filter items by requested category
  let openingItems = breakdownOpenings?.filter((m) => m.isExterior === isExterior);
  if (!openingItems || openingItems.length < 1) {
    return null;
  }

  const openingType = openingItems[0].openingType;
  // compose "fake" main construction
  // gather all materials
  let allMaterials: MaterialEntity[];
  if (openingType === OpeningType.SlidingDoor || openingType === OpeningType.NonSlidingDoor) {
    // change the breakdown to remove the materials of doors and
    // add them a single new material that is identical to the construction name,
    // basically transforming the door in a normal Opening with WindowType
    openingItems = openingItems.map((openItem) => {
      return {
        ...openItem,
        materials: [
          {
            id: openItem.constructionId,
            uuid: openItem.uuid,
            name: openItem.constructionName,
            description: openItem.constructionDescription,
            thickness: openItem.totalThickness,
            density: null,
            mass: null,
            area: openItem.area,
            volume: null,
            rValue: openItem.uValue,
            ecDefinitionAssignment: openItem.ecDefinitionAssignment,
            ecIntensity: null,
            sha: openItem.sha,
            count: openItem.surfaceIds.length,
          },
        ],
      };
    });
  }

  // aggregate materials as openings have one and only one material that has all the necessary data
  allMaterials = openingItems.reduce((acc, item) => {
    const materials = item.materials.map((mat) => {
      return {
        ...mat,
        area: item.area,
        rValue: item.uValue,
      };
    });
    return acc.concat(materials);
  }, []);

  const exterior = isExterior ? 'exterior' : 'interior';
  const firstBreakdown: ECBreakdownOpening = {
    ...openingItems[0],
    constructionId: OPENING_CONSTRUCTION_ID_PREFIX + exterior + openingType,
    constructionName: i18n.t(`analysis.ec.construction.${exterior}Openings${openingType}`),
    constructionDescription: null,
    materials: allMaterials,
    totalThickness:
      parseFloat(openingItems[0].totalThickness) <= 0 ? null : openingItems[0].totalThickness,
    uValue: null,
  };

  const newConstruction = createConstruction<ECBreakdownOpening>(
    openingItems,
    firstBreakdown,
    colorArr
  );
  newConstruction.detailLevel = null;
  return newConstruction;
};

const getFilteredOpenings = (
  breakdownOpenings: ECBreakdownOpening[],
  colorArr: RGB[]
): FilteredConstructions[] => {
  let openings: FilteredConstructions[] = [];
  if (!breakdownOpenings || breakdownOpenings.length === 0) {
    return openings;
  }

  const exteriorOpening = createFilteredOpening(breakdownOpenings, true, colorArr);
  if (exteriorOpening) {
    openings.push(exteriorOpening);
  }

  const interiorOpening = createFilteredOpening(breakdownOpenings, false, colorArr);
  if (interiorOpening) {
    openings.push(interiorOpening);
  }

  return openings;
};

const assignSortValue = (val: string | number, columnTitle: string): string | number => {
  if (val === null) {
    switch (columnTitle) {
      case i18n.t('analysis.ec.construction.description'):
        // zzz is assigned as the highest value in the list
        return 'zzz';
      case i18n.t('analysis.ec.construction.embodiedCarbon'):
      case i18n.t('analysis.ec.construction.ecIntensity'):
        // Infinity is assigned to be the highest value in the list
        return Number.NEGATIVE_INFINITY;
    }
  } else {
    switch (columnTitle) {
      case i18n.t('analysis.ec.construction.ecIntensity'):
        return parseFloat(val.toString());
      case i18n.t('analysis.ec.construction.detail'):
        // The value of the DetailLevel is returned since it is validating the id
        return i18n.t(`analysis.ec.detailLevel.${val}`).toString();
    }
  }
  return val;
};

export const sortFilteredConstructions = (
  filteredConstructions: FilteredConstructions[],
  column: SortedColumn
): FilteredConstructions[] => {
  const constructions: FilteredConstructions[] = filteredConstructions.slice(0);
  if (constructions.length > 0) {
    switch (column.columnTitle) {
      case i18n.t('analysis.ec.construction.name'):
        if (column.isSorted) {
          constructions.sort((a, b) => (a.constructionName > b.constructionName ? 1 : -1));
        } else {
          constructions.sort((a, b) => (a.constructionName < b.constructionName ? 1 : -1));
        }
        break;
      case i18n.t('analysis.ec.construction.detail'):
        if (column.isSorted) {
          constructions.sort((a, b) =>
            assignSortValue(a.detailLevel, column.columnTitle) >
            assignSortValue(b.detailLevel, column.columnTitle)
              ? 1
              : -1
          );
        } else {
          constructions.sort((a, b) =>
            assignSortValue(a.detailLevel, column.columnTitle) >
            assignSortValue(b.detailLevel, column.columnTitle)
              ? -1
              : 1
          );
        }
        break;
      case i18n.t('analysis.ec.construction.description'):
        if (column.isSorted) {
          constructions.sort((a, b) =>
            assignSortValue(a.constructionDescription, column.columnTitle) >
            assignSortValue(b.constructionDescription, column.columnTitle)
              ? 1
              : -1
          );
        } else {
          constructions.sort((a, b) =>
            assignSortValue(a.constructionDescription, column.columnTitle) >
            assignSortValue(b.constructionDescription, column.columnTitle)
              ? -1
              : 1
          );
        }
        break;
      case i18n.t('analysis.ec.construction.thickness'):
        if (column.isSorted) {
          constructions.sort((a, b) => (a.totalThickness > b.totalThickness ? 1 : -1));
        } else {
          constructions.sort((a, b) => (a.totalThickness < b.totalThickness ? 1 : -1));
        }
        break;
      case i18n.t('analysis.ec.construction.area'):
        if (column.isSorted) {
          constructions.sort((a, b) => (parseFloat(a.area) > parseFloat(b.area) ? 1 : -1));
        } else {
          constructions.sort((a, b) => (parseFloat(a.area) < parseFloat(b.area) ? 1 : -1));
        }
        break;
      case i18n.t('analysis.ec.construction.volume'):
        if (column.isSorted) {
          constructions.sort((a, b) =>
            parseFloat(a.totalVolume) > parseFloat(b.totalVolume) ? 1 : -1
          );
        } else {
          constructions.sort((a, b) =>
            parseFloat(a.totalVolume) < parseFloat(b.totalVolume) ? 1 : -1
          );
        }
        break;
      case i18n.t('analysis.ec.construction.mass'):
        if (column.isSorted) {
          constructions.sort((a, b) =>
            parseFloat(a.totalMass) > parseFloat(b.totalMass) ? 1 : -1
          );
        } else {
          constructions.sort((a, b) =>
            parseFloat(a.totalMass) < parseFloat(b.totalMass) ? 1 : -1
          );
        }
        break;
      case i18n.t('analysis.ec.construction.embodiedCarbon'):
        if (column.isSorted) {
          constructions.sort((a, b) =>
            assignSortValue(a.totalEmbodiedCarbon, column.columnTitle) >
            assignSortValue(b.totalEmbodiedCarbon, column.columnTitle)
              ? 1
              : -1
          );
        } else {
          constructions.sort((a, b) =>
            assignSortValue(a.totalEmbodiedCarbon, column.columnTitle) >
            assignSortValue(b.totalEmbodiedCarbon, column.columnTitle)
              ? -1
              : 1
          );
        }
        break;
      case i18n.t('analysis.ec.construction.ecIntensity'):
        if (column.isSorted) {
          constructions.sort((a, b) =>
            assignSortValue(a.ecIntensity, column.columnTitle) >
            assignSortValue(b.ecIntensity, column.columnTitle)
              ? 1
              : -1
          );
        } else {
          constructions.sort((a, b) =>
            assignSortValue(a.ecIntensity, column.columnTitle) >
            assignSortValue(b.ecIntensity, column.columnTitle)
              ? -1
              : 1
          );
        }
        break;
    }
  }

  return constructions;
};

export const validatePreference = (modelId: string): ECDetailsPreference => {
  let preference = getEcDetailsPreference(modelId);

  if (preference === null) {
    let defaultPreference: ECDetailsPreference = {
      viewBy: LmvLegendViewByOptions.Constructions,
      sorting: {
        columnTitle: i18n.t('analysis.ec.construction.name'),
        isSorted: true,
      },
      selectedConstructionId: null,
      defaultChart: EcChartType.EcByCategory,
      legendCollapseState: {
        lmvLegendState: true,
        chartLegendState: true,
      },
      chartLMVGripper: INITIAL_LMV_WIDTH,
      selectedMaterialSha: null,
    };
    setEcDetailsPreference(modelId, defaultPreference);
    return defaultPreference;
  } else {
    //Note: dynamically add the legend collapse state for the older models which has older preference
    if (!preference.legendCollapseState) {
      preference.legendCollapseState = {
        lmvLegendState: true,
        chartLegendState: true,
      };
    }
    if (typeof preference.chartLMVGripper === 'undefined') {
      preference.chartLMVGripper = INITIAL_LMV_WIDTH;
    }
    if (preference.viewBy === LmvLegendViewByOptions.SurfaceType) {
      preference.viewBy = LmvLegendViewByOptions.Categories;
    }
  }
  return preference;
};

export const viewByPreference = (modelId: string, viewBy: LmvLegendViewByOptions) => {
  let preference = validatePreference(modelId);
  preference.viewBy = viewBy;
  setEcDetailsPreference(modelId, preference);
};

export const lmvLegendTogglePreference = (modelId: string, legendCollapseState: boolean) => {
  let preference = validatePreference(modelId);
  preference.legendCollapseState.lmvLegendState = legendCollapseState;
  setEcDetailsPreference(modelId, preference);
};

export const chartLegendTogglePreference = (modelId: string, chartlegendCollapseState: boolean) => {
  let preference = validatePreference(modelId);
  preference.legendCollapseState.chartLegendState = chartlegendCollapseState;
  setEcDetailsPreference(modelId, preference);
};

export const constructionMaterialPreference = (
  modelId: string,
  constructionId: string,
  materialSha: string
): ECDetailsPreference => {
  let preference = validatePreference(modelId);

  preference.selectedConstructionId = constructionId;
  preference.selectedMaterialSha = materialSha;

  setEcDetailsPreference(modelId, preference);
  return preference;
};
export const chartLMVGripperPreference = (
  modelId: string,
  leftWidth: number
): ECDetailsPreference => {
  let preference = validatePreference(modelId);

  preference.chartLMVGripper = leftWidth;

  setEcDetailsPreference(modelId, preference);
  return preference;
};

const getEcDetailsPreference = (modelId: string): ECDetailsPreference => {
  return JSON.parse(localStorage.getItem(`ecDetailsPreference_${modelId}`));
};

const setEcDetailsPreference = (modelId: string, preference: ECDetailsPreference): void => {
  localStorage.setItem(`ecDetailsPreference_${modelId}`, JSON.stringify(preference));
};

export const setLmvElements = (lmvLegendData: LmvLegendData): void => {
  SetLMVLegend(lmvLegendData.constructions, LmvLegendViewByOptions.Constructions);
  SetLMVLegend(lmvLegendData.detailLevels, LmvLegendViewByOptions.DetailLevel);
  SetLMVLegend(lmvLegendData.categories, LmvLegendViewByOptions.Categories);
};

export default class ECDetails extends Component<ECDetailsProps, ClassState> {
  constructor(props: ECDetailsProps) {
    super(props);
    this.state = this.init();
  }

  init() {
    return {
      ecDetailsPreference: null,
      legendConstructions: [],
      analyzedConstructions: [],
      isLoadingECBreakdown: true,
      modelIdECBreakdown: null,
      lmvLegendData: null,
      constructionColorData: new Map(),
      materialColorData: new Map(),
      categoryColorData: new Map(),
      selectedConstructionId: null,
      selectedMaterialSha: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  shouldComponentUpdate(
    nextProps: Readonly<ECDetailsProps>,
    nextState: Readonly<ClassState>,
    nextContext: any
  ): boolean {
    if (!isequal(nextState, this.state)) {
      return true;
    }

    return (
      nextProps.useSI !== this.props.useSI ||
      nextProps.urn !== this.props.urn ||
      !isequal(nextProps.modelECBreakdown, this.props.modelECBreakdown) ||
      !isequal(nextProps.ecDefinitions, this.props.ecDefinitions) ||
      nextProps.lmvStatus !== this.props.lmvStatus ||
      nextProps.forgeUnitsLoaded !== this.props.forgeUnitsLoaded
    );
  }

  componentDidUpdate(prevProps: ECDetailsProps) {
    if (!this.props.modelECBreakdown && prevProps.modelECBreakdown) {
      const initialState = this.init();
      this.setState({
        legendConstructions: initialState.legendConstructions,
        isLoadingECBreakdown: initialState.isLoadingECBreakdown,
        modelIdECBreakdown: initialState.modelIdECBreakdown,
        ecDetailsPreference: initialState.ecDetailsPreference,
        lmvLegendData: initialState.lmvLegendData,
      });
      return;
    }
    if (!isequal(prevProps.modelECBreakdown, this.props.modelECBreakdown)) {
      this.loadData();
    }
  }

  render() {
    const useSI = this.props.useSI;
    const { ecDetailsPreference, modelIdECBreakdown, legendConstructions } = this.state;
    const isLoadingLmv = !(this.props.lmvStatus === 'Completed' && ecDetailsPreference);
    const isLoadingECBreakdown = !(
      legendConstructions?.length > 0 &&
      ecDetailsPreference &&
      this.props.forgeUnitsLoaded
    );
    const width = isLoadingECBreakdown
      ? INITIAL_LMV_WIDTH
      : this.state.ecDetailsPreference?.chartLMVGripper;

    return (
      <div className="ecdetail-main">
        <div className="ecdetails-content-lmv-container">
          <GripperResize
            hideGripper={isLoadingECBreakdown}
            onResize={(leftWidth: number) => {
              chartLMVGripperPreference(this.props.modelECBreakdown.modelId, leftWidth);
              this.setState({
                ecDetailsPreference: {
                  ...this.state.ecDetailsPreference,
                  chartLMVGripper: leftWidth,
                },
              });
            }}
            width={width}
            firstComponent={
              <div className="ecdetails-lmv-container">
                {isLoadingLmv ? (
                  <HigProgressBar />
                ) : (
                  <LmvSection
                    lmvStatus={this.props.lmvStatus}
                    lmvUrn={this.props.urn}
                    setLmvElements={setLmvElements}
                    validatePreference={validatePreference}
                    viewByPreference={viewByPreference}
                    lmvLegendExpandedPreference={lmvLegendTogglePreference}
                    filteredConstructions={this.state.legendConstructions}
                    modelECBreakdown={this.props.modelECBreakdown}
                    selectedConstructionId={this.state.selectedConstructionId}
                    constructionColorData={this.state.constructionColorData}
                    lmvLegendconstructionSelection={this.selectConstructionAndMaterial}
                    categoryColorData={this.state.categoryColorData}
                  />
                )}
              </div>
            }
            secondComponent={
              <div className="ecdetails-content-chart-container">
                {isLoadingECBreakdown ? (
                  <HigProgressBar />
                ) : (
                  <ECDetailsChartSection
                    filteredConstructions={this.state.legendConstructions}
                    defaultChart={ecDetailsPreference.defaultChart}
                    modelId={modelIdECBreakdown}
                    onChartTypeChange={this.chartPreference}
                    constructionColorData={this.state.constructionColorData}
                    materialColorData={this.state.materialColorData}
                    categoryColorData={this.state.categoryColorData}
                    useSI={useSI}
                    ecModelBreakdown={this.props.modelECBreakdown}
                    selectedConstructionId={this.state.selectedConstructionId}
                    chartLegendExpandedPreference={chartLegendTogglePreference}
                    onChartClick={this.onChartClick}
                    validatePreference={validatePreference}
                    selectedMaterialSha={this.state.selectedMaterialSha}
                    chartWidth={100 - width}
                    onChartMaterialClick={this.onChartMaterialClick}
                  />
                )}
              </div>
            }
          />
        </div>
        {isLoadingECBreakdown ? (
          <div className="ecdetail-construction-table-container" style={{ height: '50%' }}>
            <div className="ecdetail-title">
              <h1 className="breadcrumb">{''}</h1>
            </div>
            <HigProgressBar small={true} />
          </div>
        ) : (
          <div className="ecdetail-construction-table-container">
            <div className="ecdetail-title">
              <h1 className="breadcrumb">{i18n.t('analysis.ec.construction.title')}</h1>
            </div>
              <DataGridContextProvider>
                <ECDetailsConstructionDataGrid
                modelIdECBreakdown={this.state.modelIdECBreakdown}
                useSI={useSI}
                filteredConstructions={this.state.legendConstructions}
                ecDetailsPreference={ecDetailsPreference}
                selectConstructionWeave={this.selectConstructionWeave}
                renderEcDefinitionContentWeave={this.renderEcDefinitionContentWeave}
                openAdvancedSearchWeave={this.props.openAdvancedSearchWeave}
              />
              </DataGridContextProvider>
          </div>
        )}
      </div>
    );
  }

  //TODO: Need to refactor this since it's loosing surface types
  getFilteredConstructions = (
    modelECBreakdown: modelType.ModelEmbodiedCarbonBreakdownEntity
  ): FilteredConstructions[] => {
    let colorArr: RGB[] = [];
    let openings: FilteredConstructions[];

    // get opening constructions
    openings = getFilteredOpenings(
      modelECBreakdown.openingItems[OpeningType.OperableWindow],
      colorArr
    );

    openings = openings.concat(
      getFilteredOpenings(
        modelECBreakdown.openingItems[OpeningType.FixedWindow],
        colorArr
      )
    );

    let breakdownDoors: ECBreakdownOpening[] = [];
    breakdownDoors = breakdownDoors
      .concat(modelECBreakdown.openingItems[OpeningType.SlidingDoor] ?? [])
      .concat(modelECBreakdown.openingItems[OpeningType.NonSlidingDoor] ?? []);
    openings = openings.concat(
      getFilteredOpenings(breakdownDoors, colorArr)
    );

    let breakdownSkylights: ECBreakdownOpening[] = [];
    breakdownSkylights = breakdownSkylights
      .concat(modelECBreakdown.openingItems[OpeningType.OperableSkylight] ?? [])
      .concat(modelECBreakdown.openingItems[OpeningType.FixedSkylight] ?? []);
    openings = openings.concat(
      getFilteredOpenings(breakdownSkylights, colorArr)
    );

    // get all uniques construction names. this is needed as in the lmv legend we must show only the uniques constructions,
    // regardless or the surface type they were applied to
    let constructionNames = Object.values(modelECBreakdown.constructionItems)
      .flat()
      .filter((s) => s.surfaceType !== SurfaceType.Air)
      .map((s) => s.constructionName);
    let uniqueBreakdownNames = Array.from(new Set(constructionNames));
    // get the rest of the constructions that are not openings and concatenate with the ones from openings
    let groupedConstructions: FilteredConstructions[] = uniqueBreakdownNames.reduce(
      (acc: FilteredConstructions[], breakdownItemName) => {
        const constructions = Object.values(modelECBreakdown.constructionItems)
          .flat()
          .filter((s) => s.constructionName === breakdownItemName);
        if (constructions.length < 1) {
          return acc;
        }
        constructions.forEach(
          c => {
            const newConstruction = createConstruction<ECBreakdownConstruction>(
              constructions,
              c,
              colorArr
            );
            acc.push(newConstruction);
          }
        );
        return acc;
      },
      openings
    );

    groupedConstructions.sort((a, b) => (a.constructionName > b.constructionName ? 1 : -1));
    return groupedConstructions;
  };

  getExteriorWallConstructions = (
    modelECBreakdown: ModelEmbodiedCarbonBreakdownEntity
  ): FilteredConstructions[] => {
    const constrItems = modelECBreakdown.constructionItems[SurfaceType.ExteriorWall]?.filter(
      (c) => c.isExterior
    );
    return this.getFilteredConstructions({
      ...modelECBreakdown,
      openingItems: {},
      constructionItems: constrItems ? { [SurfaceType.ExteriorWall]: constrItems } : {},
    });
  };

  getExteriorWindowsConstruction = (
    modelECBreakdown: ModelEmbodiedCarbonBreakdownEntity
  ): FilteredConstructions[] => {
    let operableItems = [];
    let fixedItems = [];

    const openItems = modelECBreakdown.openingItems[OpeningType.OperableWindow]?.filter(
      (o) => o.isExterior
    );
    operableItems = this.getFilteredConstructions({
      ...modelECBreakdown,
      openingItems: openItems ? { [OpeningType.OperableWindow]: openItems } : {},
      constructionItems: {},
    });

    const cwItems = modelECBreakdown.openingItems[OpeningType.FixedWindow]?.filter(
      (o) => o.isExterior
    );
    fixedItems = fixedItems.concat(
      this.getFilteredConstructions({
        ...modelECBreakdown,
        openingItems: fixedItems ? { [OpeningType.FixedWindow]: cwItems } : {},
        constructionItems: {},
      })
    );

    const sdDoors = modelECBreakdown.openingItems[OpeningType.SlidingDoor]?.filter(
      (o) => o.isExterior
    );
    fixedItems = fixedItems.concat(
      this.getFilteredConstructions({
        ...modelECBreakdown,
        openingItems: sdDoors ? { [OpeningType.SlidingDoor]: sdDoors } : {},
        constructionItems: {},
      })
    );

    const fixedDoors = modelECBreakdown.openingItems[OpeningType.NonSlidingDoor]?.filter(
      (o) => o.isExterior
    );
    fixedItems = fixedItems.concat(
      this.getFilteredConstructions({
        ...modelECBreakdown,
        openingItems: fixedDoors ? { [OpeningType.NonSlidingDoor]: fixedDoors } : {},
        constructionItems: {},
      })
    );

    return operableItems.concat(fixedItems);
  };

  loadData() {
    if (this.props.modelECBreakdown?.modelId) {
      const ecDetailsPreference = validatePreference(this.props.modelECBreakdown.modelId);
      const legendConstructions = sortFilteredConstructions(
        this.getFilteredConstructions(this.props.modelECBreakdown),
        ecDetailsPreference.sorting
      );
      const constructionColorData: Map<string, string> =
        this.setConstructionColorData(legendConstructions);
      const exteriorWallConstructions = sortFilteredConstructions(
        this.getExteriorWallConstructions(this.props.modelECBreakdown),
        ecDetailsPreference.sorting
      );
      const exteriorWindows = sortFilteredConstructions(
        this.getExteriorWindowsConstruction(this.props.modelECBreakdown),
        ecDetailsPreference.sorting
      );
      const analyzedConstructions = exteriorWallConstructions.concat(exteriorWindows);
      const materialColorData: Map<string, string> = this.setMaterialColorData(legendConstructions);

      const categoryColorData: Map<string, string> = categoryColorsMap;
      const selectedMaterialSha: string = ecDetailsPreference.selectedMaterialSha;
      let selectedConstructionId: string = null;
      if (ecDetailsPreference.selectedConstructionId && legendConstructions.length > 0) {
        selectedConstructionId = ecDetailsPreference.selectedConstructionId;
      }

      this.setState({
        ecDetailsPreference,
        modelIdECBreakdown: this.props.modelECBreakdown.modelId,
        legendConstructions: legendConstructions,
        analyzedConstructions: analyzedConstructions,
        constructionColorData,
        materialColorData,
        categoryColorData,
        selectedConstructionId,
        selectedMaterialSha,
      });
    }
  }

  private setMaterialColorData = (constructions: FilteredConstructions[]): Map<string, string> => {
    let materialColorData: Map<string, string> = new Map<string, string>();
    let colorArr: RGB[] = [];

    constructions.forEach((construction) => {
      construction.materials.forEach((mat) => {
        if (!materialColorData.has(mat.name)) {
          let t = getNextColor(colorArr);
          colorArr.push(t);
          materialColorData.set(mat.name, RGBToHEX(t));
        } else {
          materialColorData.set(mat.name, materialColorData.get(mat.name));
        }
      });
    });

    return materialColorData;
  };

  private setConstructionColorData = (
    constructions: FilteredConstructions[]
  ): Map<string, string> => {
    constructions.sort((a, b) => (a.constructionName > b.constructionName ? 1 : -1));
    let constructionColorData: Map<string, string> = new Map<string, string>();
    let colorArr: RGB[] = [];

    constructions.forEach((cons) => {
      if (!constructionColorData.has(cons.constructionName)) {
        let t = getNextColor(colorArr);
        colorArr.push(t);
        constructionColorData.set(cons.constructionName, RGBToHEX(t));
      } else {
        constructionColorData.set(
          cons.constructionName,
          constructionColorData.get(cons.constructionName)
        );
      }
    });

    return constructionColorData;
  };

  // do not remove, function from weave implementation
  private onSelectEcDefinitionWeave = (
    material: modelType.MaterialRow,
    ecDefinition: EcDefinition
  ) => {
    this.props.setEcDefinition(material.sha, ecDefinition);
  };

  // do not remove, function from weave implementation
  private renderEcDefinitionContentWeave = (
    material: modelType.MaterialRow,
    openAdvancedSearchWeave: (row: modelType.MaterialRow) => void,
    selectedEcDefinition: EcDefinition,
    setAnchorEl: (anchorEl: HTMLElement | null) => void,
  ) => {

    return Object.keys(this.props.ecDefinitions)?.length ? (
      <ECDefinitionsQuickSearchWeave
        ecDefinitions={material && ecDefinitionsSelector(material, this.props.ecDefinitions)}
        openDialog={openAdvancedSearchWeave}
        selectedEcDefinition={selectedEcDefinition}
        useSI={this.props.useSI}
        onSelectEcDefinition={(ecDefinition) =>
          this.onSelectEcDefinitionWeave(material, ecDefinition)
        }
        material={material}
        setAnchorEl={setAnchorEl}
      />
    ) : (
      <></>
    );
  };

  // do not remove, weave table selection logic
  selectConstructionWeave = (rowsIndexs: any) => {

    const { constructionId, materialSha } = rowsIndexs;

    let selectedConstructionId = constructionId;
    let selectedMaterialSha = materialSha;

    const newPreference = constructionMaterialPreference(
        this.state.modelIdECBreakdown,
        selectedConstructionId,
        selectedMaterialSha
    );

    this.setState({
      ecDetailsPreference: newPreference,
      selectedConstructionId,
      selectedMaterialSha,
    });
  }

  selectConstruction(rowInfo: any) {
    const { cellRowIndex, rowTypeToMap } = rowInfo.props;
    const rowData = rowTypeToMap[cellRowIndex].original;

    if (rowInfo.props.rowTypeToMap[rowInfo.props.cellRowIndex]?.original?.materialId) {
      //If a material is selected, allow user to select the material and construction
      this.selectConstructionAndMaterial(rowData.constructionId, rowData.sha);
    } else {
      this.selectConstructionAndMaterial(rowData.constructionId, null);
    }
  }

  selectConstructionAndMaterial = (constructionId: string, materialSha?: string) => {
    let selectedConstructionId = constructionId;
    let selectedMaterialSha = materialSha;

    if (selectedMaterialSha) {
      //If a material is selected
      if (
        selectedMaterialSha === this.state.selectedMaterialSha &&
        selectedConstructionId === this.state.selectedConstructionId
      ) {
        //Deselect material
        selectedMaterialSha = null;
      }
    } else {
      //If a construction is being selected
      if (selectedConstructionId === this.state.selectedConstructionId) {
        //Deselect construction
        selectedConstructionId = null;
      }
      selectedMaterialSha = null;
    }

    const newPreference = constructionMaterialPreference(
      this.state.modelIdECBreakdown,
      selectedConstructionId,
      selectedMaterialSha
    );
    this.setState({
      ecDetailsPreference: newPreference,
      selectedConstructionId,
      selectedMaterialSha,
    });
  };

  private onChartClick = (selectedConstructionId: string) => {
    this.selectConstructionAndMaterial(selectedConstructionId, null);
  };

  private onChartMaterialClick = (
    selectedChartMaterialSha: string,
    selectedConstructionId: string
  ) => {
    this.selectConstructionAndMaterial(selectedConstructionId, selectedChartMaterialSha);
  };

  getDetailLevelSurfaceIdList(
    modelECBreakdown: ModelEmbodiedCarbonBreakdownEntity
  ): DetailLevelList {
    let detailLevelList: DetailLevelList = { schematic: [], detail: [], unKnown: [] };

    let allConstructions = flattenConstructions(modelECBreakdown);
    allConstructions.forEach((item) => {
      if (item.isSchematic === null) {
        item.surfaceIds.forEach((surfaceId) => {
          detailLevelList.unKnown.push(surfaceId);
        });
      }
      if (item.isSchematic === 'true') {
        item.surfaceIds.forEach((surfaceId) => {
          detailLevelList.schematic.push(surfaceId);
        });
      }
      if (item.isSchematic === 'false') {
        item.surfaceIds.forEach((surfaceId) => {
          detailLevelList.detail.push(surfaceId);
        });
      }
    });
    return detailLevelList;
  }

  private chartPreference = (modelId: string, chart: string) => {
    if (modelId && chart) {
      let preference = validatePreference(modelId);
      preference.defaultChart = chart;
      setEcDetailsPreference(modelId, preference);
      this.setState({ ecDetailsPreference: preference });
    }
  };
}
