import i18next from 'i18next';
import sharedEnglish from "./shared/localization/en.json";
import projectEnglish from "./project/localization/en.json";
import projectChinese from "./project/localization/zh.json";
import modelEnglish from "./model/localization/en.json";
import analysisEnglish from "./analysis/localization/en.json";
import chartsEnglish from "./charts/localization/en.json";
import analysis from "./localization/en/analysis.json";
import symbols from "./localization/en/symbols.json";
import surfaceTypes from "./localization/en/surfaceTypes.json";
import ecTabs from "./localization/en/ecTabs.json";
import layout from "./localization/en/layout.json";
import projectSettings from "./localization/en/projectSettings.json";
import { customCSS } from './css/localization/customCss';
import notifications from './localization/en/notifications.json';
import reporting from './localization/en/reporting.json';
import app from './localization/en/app.json';

// use browser language
const language = navigator.language;
const supportedLngs = ['en-US', 'zh-TW'];
const fallbackLng = 'en-US';

i18next
    .init({
        lng: supportedLngs.includes(language) ? language : fallbackLng,
        fallbackLng: fallbackLng,
        supportedLngs: supportedLngs
    })
    .then(() => {
        customCSS(i18next.language)
    });

//English
i18next.addResourceBundle('en-US', 'translation', analysis);
i18next.addResourceBundle('en-US', 'translation', symbols);
i18next.addResourceBundle('en-US', 'translation', sharedEnglish);
i18next.addResourceBundle('en-US', 'translation', projectEnglish);
i18next.addResourceBundle('en-US', 'translation', modelEnglish);
i18next.addResourceBundle('en-US', 'translation', analysisEnglish);
i18next.addResourceBundle('en-US', 'translation', chartsEnglish);
i18next.addResourceBundle('en-US', 'translation', surfaceTypes);
i18next.addResourceBundle('en-US', 'translation', ecTabs);
i18next.addResourceBundle('en-US', 'translation', layout);
i18next.addResourceBundle('en-US', 'translation', projectSettings);
i18next.addResourceBundle('en-US', 'translation', notifications);
i18next.addResourceBundle('en-US', 'translation', reporting);
i18next.addResourceBundle('en-US', 'translation', app);

//Chinese
i18next.addResourceBundle('zh-TW', 'translation', projectChinese);

export default i18next;
