import React from 'react';
import { GridCellParams, GridColDef, GridGroupingColDefOverride, GridRenderCellParams } from '@weave-mui/data-grid';
import i18next from '../../i18n';
import {
  AreaField,
  DensityField,
  DescriptionField,
  DetailLevelField,
  EcCoefficientField,
  EcDefinitionField,
  EcIntensityField,
  EcItemCount,
  EmbodiedCarbonField,
  MassField,
  ThermalPerformanceField,
  ThicknessField,
  VolumeField,
} from './ConstructionTableConstants';
import ConstructionDataGridHeader from './Components/ConstructionDataGridHeader';
import i18n from '../../i18n';
import * as conv from '../../conversions';
import UnitEnum from '../../shared/UnitConversion/UnitEnums';
import ThermalPerfValue from './Components/ThermalPerfValue';
import { ECValue } from '../../shared/ECDefinition/ECValue';
import { FilteredConstructions } from './types';
import { higTableRow } from '../../shared/HigTable/HigTableWrapper';
import { skylightNameHandler } from './utilsDataGrid';
import ConstructionNameCell from './Components/ConstructionNameCell';
import ECDefinition from '../../shared/ECDefinition/ECDefinitionDataGrid';
import { UnitConversion } from './Components/UnitConversion';
import TooltipContainer from '../components/TooltipContainer/TooltipContainer';
import { MaterialRow } from '../dashboard.models';

const NULL_VALUE = i18n.t(`analysis.constant.nullValue`);
export const totalRowTitle = i18n.t('analysis.ec.construction.totalRowTitle');

const sortComparatorRows = (v1: number, v2: number): number => v1 - v2;
export const getColumns = (columnsProps): GridColDef[] => {

  const { 
    useSI,
    renderEcDefinitionContentWeave,
    openAdvancedSearchWeave,
   } = columnsProps;

  return [
    {
      field: DescriptionField,
      headerName: i18next.t('analysis.ec.construction.description'),
      sortable: true,
      renderHeader: () => (
        <ConstructionDataGridHeader title={i18n.t('analysis.ec.construction.description')} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <TooltipContainer content={params.row.description} />
      ),
      flex: 3,
      disableReorder: true,
    },
    {
      field: DetailLevelField,
      headerName: i18n.t('analysis.ec.construction.detail'),
      sortable: true,
      renderHeader: () => (
        <ConstructionDataGridHeader title={i18n.t('analysis.ec.construction.detail')} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <TooltipContainer content={params.row.detailLevel} />
      ),
      flex: 1.5,
      disableReorder: true,
    },
    {
      field: EcItemCount,
      headerName: i18n.t('analysis.ec.construction.ecItemCount'),
      sortable: true,
      align: 'right',
      renderHeader: () => (
        <ConstructionDataGridHeader title={i18n.t('analysis.ec.construction.ecItemCount')} />
      ),
      flex: 1,
      disableReorder: true,
    },
    {
      field: ThicknessField,
      headerName: i18n.t('analysis.ec.construction.thickness'),
      sortable: true,
      align: 'right',
      renderHeader: () => (
        <ConstructionDataGridHeader
          title={i18n.t('analysis.ec.construction.thickness')}
          unit={useSI ? conv.GetSymbol(UnitEnum.Millimeter) : conv.GetSymbol(UnitEnum.Inch)}
        />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <TooltipContainer
          content={
            <UnitConversion
              value={params.row.thickness}
              sourceUnit={UnitEnum.Meter}
              targetUnit={useSI ? UnitEnum.Millimeter : UnitEnum.Inch}
              round={1}
              useFraction={useSI ? false : true}
            />
          }
        />
      ),
      flex: 1.5,
      disableReorder: true,
    },
    {
      field: DensityField,
      headerName: i18n.t('analysis.ec.construction.density'),
      sortable: false,
      align: 'right',
      renderHeader: () => (
        <ConstructionDataGridHeader
          title={i18n.t('analysis.ec.construction.density')}
          unit={useSI ? conv.GetSymbol(UnitEnum.KgPerM3) : conv.GetSymbol(UnitEnum.PoundsPerFt3)}
        />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <TooltipContainer
          content={
            <UnitConversion
              value={params.row.density}
              sourceUnit={UnitEnum.KgPerM3}
              targetUnit={useSI ? UnitEnum.KgPerM3 : UnitEnum.PoundsPerFt3}
            />
          }
        />
      ),
      flex: 1,
      disableReorder: true,
    },
    {
      field: ThermalPerformanceField,
      headerName: i18n.t('analysis.ec.construction.thermalPerformance'),
      sortable: false,
      align: 'right',
      renderHeader: () => (
        <ConstructionDataGridHeader
          title={i18n.t('analysis.ec.construction.thermalPerformance')}
          unit={
            `${
              useSI
                ? 'RSI: ' + conv.GetSymbol(UnitEnum.M2KPerW)
                : 'R: ' + conv.GetSymbol(UnitEnum.HrFt2PerBtu)
            }, ` +
            `U: ${useSI ? conv.GetSymbol(UnitEnum.WPerM2K) : conv.GetSymbol(UnitEnum.BtuPerHrFt2F)}`
          }
        />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <TooltipContainer
          content={<ThermalPerfValue data={params} fromUnit={UnitEnum.M2KPerW} useSI={useSI} />}
        />
      ),
      disableReorder: true,
    },
    {
      field: AreaField,
      headerName: i18n.t('analysis.ec.construction.area'),
      sortable: true,
      align: 'right',
      renderHeader: () => (
        <ConstructionDataGridHeader
          title={i18n.t('analysis.ec.construction.area')}
          unit={useSI ? conv.GetSymbol(UnitEnum.MeterSq) : conv.GetSymbol(UnitEnum.FeetSq)}
        />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <TooltipContainer
          content={
            <UnitConversion
              value={params.row.area}
              sourceUnit={UnitEnum.MeterSq}
              targetUnit={useSI ? UnitEnum.MeterSq : UnitEnum.FeetSq}
            />
          }
        />
      ),
      flex: 1.5,
      disableReorder: true,
      sortComparator: sortComparatorRows,
    },
    {
      field: VolumeField,
      headerName: i18n.t('analysis.ec.construction.volume'),
      sortable: true,
      align: 'right',
      renderHeader: () => (
        <ConstructionDataGridHeader
          title={i18n.t('analysis.ec.construction.volume')}
          unit={useSI ? conv.GetSymbol(UnitEnum.Meter3) : conv.GetSymbol(UnitEnum.Feet3)}
        />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <TooltipContainer
          content={
            <UnitConversion
              value={params.row.volume}
              sourceUnit={UnitEnum.Meter3}
              targetUnit={useSI ? UnitEnum.Meter3 : UnitEnum.Feet3}
            />
          }
        />
      ),
      flex: 1.5,
      sortComparator: sortComparatorRows,
      disableReorder: true,
    },
    {
      field: MassField,
      headerName: i18n.t('analysis.ec.construction.mass'),
      sortable: true,
      align: 'right',
      renderHeader: () => (
        <ConstructionDataGridHeader
          title={i18n.t('analysis.ec.construction.mass')}
          unit={useSI ? conv.GetSymbol(UnitEnum.Kg) : conv.GetSymbol(UnitEnum.Pounds)}
        />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <TooltipContainer
          content={
            <UnitConversion
              value={params.row.mass}
              sourceUnit={UnitEnum.Kg}
              targetUnit={useSI ? UnitEnum.Kg : UnitEnum.Pounds}
            />
          }
        />
      ),
      disableReorder: true,
      sortComparator: sortComparatorRows,
    },
    {
      field: EcDefinitionField,
      headerName: i18n.t('analysis.ec.construction.definition'),
      minWidth: 70,
      sortable: false,
      renderHeader: () => (
        <ConstructionDataGridHeader title={i18n.t('analysis.ec.construction.definition')} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <ECDefinition
          {...params}
          renderEcDefinitionContentWeave={renderEcDefinitionContentWeave}
          openAdvancedSearchWeave={openAdvancedSearchWeave}
        />
      ),
      flex: 2.5,
      disableReorder: true,
    },
    {
      field: EcCoefficientField,
      headerName: i18n.t('analysis.ec.construction.coefficient'),
      sortable: false,
      align: 'right',
      renderHeader: () => (
        <ConstructionDataGridHeader title={i18n.t('analysis.ec.construction.coefficient')} />
      ),
      renderCell: (params: GridRenderCellParams) => {
        return params.row?.depth ? (
          <TooltipContainer
            content={
              <ECValue
                value={params.row.ecCoefficient}
                unit={params.row.ecCoefficientUnit}
                useSI={useSI}
              />
            }
          />
        ) : (
          <></>
        );
      },
      flex: 1,
      disableReorder: true,
    },
    {
      field: EmbodiedCarbonField,
      headerName: i18n.t('analysis.ec.construction.embodiedCarbon'),
      sortable: true,
      align: 'right',
      renderHeader: () => (
        <ConstructionDataGridHeader
          title={i18n.t('analysis.ec.construction.embodiedCarbon')}
          unit={conv.GetSymbol(UnitEnum.KgCO2e)}
        />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <TooltipContainer
          content={
            <UnitConversion
              value={params.row.embodiedCarbon}
              sourceUnit={UnitEnum.KgCO2e}
              targetUnit={UnitEnum.KgCO2e}
              useFraction={false}
            />
          }
        />
      ),
      disableReorder: true,
    },
    {
      field: EcIntensityField,
      headerName: i18n.t('analysis.ec.construction.ecIntensity'),
      sortable: true,
      align: 'right',
      renderHeader: () => (
        <ConstructionDataGridHeader
          title={i18n.t('analysis.ec.construction.ecIntensity')}
          unit={
            useSI ? conv.GetSymbol(UnitEnum.KgCO2ePerM2) : conv.GetSymbol(UnitEnum.KgCO2ePerFt2)
          }
        />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <TooltipContainer
          content={
            <UnitConversion
              value={params.row.ecIntensity}
              sourceUnit={UnitEnum.KgCO2ePerM2}
              targetUnit={useSI ? UnitEnum.KgCO2ePerM2 : UnitEnum.KgCO2ePerFt2}
              useFraction={false}
            />
          }
        />
      ),
      flex: 1.5,
      disableReorder: true,
      sortComparator: sortComparatorRows,
    },
  ];};

const constructionComparator = (v1: any, v2: any): any =>
  v1.props.row.constructionName > v2.props.row.constructionName;

export const colDefToConstruction: GridGroupingColDefOverride = {
  headerName: i18n.t('analysis.ec.construction.name'),
  renderHeader: () => (
    <ConstructionDataGridHeader title={i18n.t('analysis.ec.construction.name')} />
  ),
  sortable: true,
  valueGetter: (params: GridCellParams) => <ConstructionNameCell {...params} />,
  sortComparator: constructionComparator,
  flex: 3,
  minWidth: 80,
  disableReorder: true,
};

export const getRows = (
  constructionArray: FilteredConstructions[],
  totalsConstructionTable: FilteredConstructions,
) => {
  
  const rowsV1 = [...constructionArray, totalsConstructionTable]?.map((construction) => {
    const isTotalRow: boolean = construction.constructionName === totalRowTitle;

    if (isTotalRow) {
      return formatterTotalConstruction(construction);
    } else {
      return formatterDataConstruction(constructionArray, construction);
    }
  });

  const rows = removeElementDuplicateId(rowsV1);
  const subRows = subRowsCreator(rows);

  return [...rows, ...subRows];
};

const subRowsCreator = (rows: higTableRow[]) => {
  return rows.flatMap(row => row.subRows ?? []);
};

const removeElementDuplicateId = (arr: higTableRow) => {
  let objectFilter2 = {};

  return arr.filter((o: higTableRow) =>
    objectFilter2[o.id] ? false : (objectFilter2[o.id] = true)
  );
};

const materialTotalEc = (filteredConstructions, constructionName, materialId)=>{
  const sameNameConstructions = filteredConstructions.filter(constr => constr.constructionName === constructionName);
  const allMaterialsFromConstruction = sameNameConstructions.flatMap(constr => constr.materials);

  return allMaterialsFromConstruction
    .filter(mat => mat.id === materialId)
    .reduce((acc, mat) => {
      if ( mat.ecDefinitionAssignment?.embodiedCarbon){
        return acc + mat.ecDefinitionAssignment.embodiedCarbon
      }
      else {
        return acc;
      }
  }, 0);
};

const formatterDataConstruction = (constructionArray: FilteredConstructions[], constructionData: higTableRow): higTableRow => ({
  path: [constructionData.constructionName + constructionData.constructionId],
  id: constructionData.constructionId,
  constructionId: constructionData.constructionId,
  constructionName: constructionData.constructionName,
  detailLevel: constructionData.detailLevel
    ? i18n.t(`analysis.ec.detailLevel.${constructionData.detailLevel}`)
    : '--',
  description: constructionData.constructionDescription || NULL_VALUE,
  thickness: constructionData.totalThickness,
  area: constructionData.area,
  volume: constructionData.totalVolume,
  mass: constructionData.totalMass,
  ecDefinition: null,
  embodiedCarbon: constructionData.totalEmbodiedCarbon,
  ecIntensity: constructionData.ecIntensity,
  rValue: constructionData.uValue ?? null,
  depth: 0,
  subRows: constructionData?.materials?.map((materialItem: MaterialRow) =>
    formatterDataMaterial(constructionArray, constructionData, materialItem)
  ),
});

const formatterDataMaterial = (
  constructionArray: FilteredConstructions[],
  constructionData: higTableRow,
  materialData: higTableRow
): higTableRow => ({
  path: [constructionData.constructionName + constructionData.constructionId, materialData.name + `-${materialData.uuid}`],
  id: materialData.id + constructionData.constructionId + materialData.uuid,
  constructionId: constructionData.constructionId,
  constructionName: materialData.name,
  description: materialData.description ? materialData.description : NULL_VALUE,
  thickness: materialData.thickness,
  area: materialData.area ?? constructionData.area,
  volume: materialData.volume,
  mass: materialData.mass,
  density: materialData.density,
  rValue: materialData.rValue ?? null,
  ecDefinition: materialData.ecDefinitionAssignment?.ecDefinition,
  ecCoefficient: materialData.ecDefinitionAssignment?.ecDefinition?.average ?? NULL_VALUE,
  ecCoefficientUnit: materialData.ecDefinitionAssignment?.ecDefinition?.unit ?? NULL_VALUE,
  embodiedCarbon: materialTotalEc(constructionArray, constructionData.constructionName, materialData.id) === 0 ? null : materialTotalEc(constructionArray, constructionData.constructionName, materialData.id) ?? null,
  ecIntensity: materialData.ecIntensity,
  uuid: materialData.uuid,
  sha: materialData.sha,
  ecItemCount: materialData.count ?? NULL_VALUE,
  depth: 1,
  original: constructionData,
});

const formatterTotalConstruction = (construction: higTableRow) => ({
  path: [totalRowTitle],
  id: totalRowTitle,
  constructionId: construction.constructionId,
  constructionName: skylightNameHandler(construction.constructionName),
  detailLevel: '',
  description: '',
  thickness: '',
  area: construction.area,
  volume: construction.totalVolume,
  mass: construction.totalMass,
  ecDefinition: null,
  embodiedCarbon: construction.totalEmbodiedCarbon,
  ecIntensity: '',
});

