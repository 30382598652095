import React, { useContext, useEffect, useRef } from 'react';
import { CardContentContext } from './base/BaseCard';
import { DataPointValue, FactorValue } from '../../types/metrics';
import { factorCardDataStyles } from './base/Card.stylesheet';
import { FactorDisplay } from './shared/FactorDisplay';
import { FactorCardData } from '../../types/layout';
import { useCardGrid } from '@adsk/wildcard';
import { useDispatch } from 'react-redux';
import { setFactorOverride } from '../../state/slice/factor-data-slice';
import Box from '@weave-mui/box';
import { getFactorValueForCurrentUnitSystem } from '../utils/layout-setup';

/**
 * Used to locate the factor value in the factor data point
 * TODO: This should be handled by an id field on the factor value
 */
export type FactorValueLocator = {
  posn: number;
  value: number;
  name: string;
};

export const FactorCard: React.FC = () => {
  const { data, settings, cardId } = useContext(CardContentContext);
  const { state, saveSetting } = useCardGrid();
  const factorSettings = settings as FactorCardData;
  const editFactorValuePosn = useRef<FactorValueLocator>(null);
  const viewOnlyFactorValue = useRef<FactorValueLocator>(null);
  const factorDataPoint = data as DataPointValue;
  const dispatch = useDispatch();

  const dispatchFactorOverride = (value: number) => {
    dispatch(
      setFactorOverride({
        factorId: factorSettings.parameterId,
        value: getFactorValueForCurrentUnitSystem(value, factorDataPoint),
      })
    );
  };

  const getFactorLocatorFromValue = (factorValue: number): FactorValueLocator => {
    const factorDataPointValues = factorDataPoint?.value as FactorValue[];
    const factorPosn = factorDataPointValues.findIndex((fv) => fv.value === factorValue);
    const posn = factorPosn > -1 ? factorPosn : 0;
    const { name, value } = factorDataPointValues[posn];
    return { posn, value, name };
  };

  const checkFactorLocatorChanges = (
    factorLocator: FactorValueLocator,
    dispatchUpdate: boolean
  ) => {
    const factorDataPointValues = factorDataPoint?.value as FactorValue[];
    const factorNameMatchPosn = factorDataPointValues.findIndex(
      (x) => x.name === factorLocator.name
    );
    const factorValueMatchPosn = factorDataPointValues.findIndex(
      (x) => x.value === factorLocator.value
    );

    if (factorNameMatchPosn > -1) {
      factorLocator.value = factorDataPointValues[factorNameMatchPosn].value;
      factorLocator.posn = factorNameMatchPosn;
    } else if (factorValueMatchPosn > -1) {
      factorLocator.value = factorDataPointValues[factorValueMatchPosn].value;
      factorLocator.name = factorDataPointValues[factorValueMatchPosn].name;
      factorLocator.posn = factorValueMatchPosn;
    } else {
      factorLocator.posn = 0;
      factorLocator.value = factorDataPointValues[0].value;
      factorLocator.name = factorDataPointValues[0].name;
    }

    if (dispatchUpdate) {
      dispatchFactorOverride(factorLocator.value);
    }
  };

  useEffect(() => {
    editFactorValuePosn.current = getFactorLocatorFromValue(factorSettings?.value);
    viewOnlyFactorValue.current = editFactorValuePosn.current;
    dispatchFactorOverride(editFactorValuePosn.current.value);
  }, [settings]);

  useEffect(() => {
    checkFactorLocatorChanges(editFactorValuePosn.current, state.customizeMode);
    checkFactorLocatorChanges(viewOnlyFactorValue.current, !state.customizeMode);
  }, [factorDataPoint]);

  useEffect(() => {
    if (state.customizeMode) {
      dispatchFactorOverride(editFactorValuePosn.current.value);
    } else if (
      !state.customizeMode &&
      viewOnlyFactorValue.current.value !== editFactorValuePosn.current.value
    ) {
      dispatchFactorOverride(viewOnlyFactorValue.current.value);
    }
  }, [state.customizeMode]);

  // this is unique behaviour to the factor card
  // not really best practice to have the card content updating it's settings
  const onFactorValueChange = (factorValue: number) => {
    if (state.customizeMode) {
      saveSetting({ i: cardId, key: 'value', value: factorValue }); // factor values saved in settings as industry standard values
      editFactorValuePosn.current = getFactorLocatorFromValue(factorValue);
    } else {
      viewOnlyFactorValue.current = getFactorLocatorFromValue(factorValue);
    }

    dispatchFactorOverride(factorValue);
  };

  return (
    <Box sx={factorCardDataStyles}>
      <FactorDisplay
        dataPointValue={factorDataPoint}
        currentValue={
          state.customizeMode
            ? editFactorValuePosn.current?.value
            : viewOnlyFactorValue.current?.value
        }
        onFactorValueChange={onFactorValueChange}
      />
    </Box>
  );
};
