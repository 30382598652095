import { HSV, RGB, RGBToHSV, HSVtoRGB, RGBToHEX } from './colorUtils';
import {CategoryTypeValues} from "../../analysis/EmbodiedCarbon/types";

/**
 * Defult color which is contrasting enough for Differentiation
 *
 */
const DefaultColors: HSV[] = [
  {
    //RED
    h: 0,
    s: 0.8,
    v: 0.98,
  },
  {
    // Orange
    h: 30,
    s: 0.8,
    v: 0.98,
  },
  {
    // Yellow
    h: 54,
    s: 0.8,
    v: 0.98,
  },
  {
    // Y-Green
    h: 88,
    s: 0.8,
    v: 0.98,
  },
  {
    //Green
    h: 120,
    s: 0.8,
    v: 0.98,
  },
  {
    // B-Green
    h: 150,
    s: 0.8,
    v: 0.98,
  },
  {
    // Cyan
    h: 180,
    s: 0.8,
    v: 0.98,
  },
  {
    //B-Cyan
    h: 209,
    s: 0.8,
    v: 0.98,
  },
  {
    //Blue
    h: 224,
    s: 0.8,
    v: 0.98,
  },
  {
    //Purple
    h: 262,
    s: 0.8,
    v: 0.98,
  },
  {
    // Magenta
    h: 300,
    s: 0.8,
    v: 0.98,
  },
  {
    //Rose
    h: 334,
    s: 0.8,
    v: 0.98,
  },
];

/**
 * Color Ramdomizer which generates color randomly from a given number of seed colors
 * The detail explanation on how this algorithm works is in this wiki
 * https://wiki.autodesk.com/display/aeceng/Color+palette+generator
 */
export const getNextColor = (ColorSet: RGB[]): RGB => {
  if (ColorSet.length < 12) {
    return HSVtoRGB(DefaultColors[ColorSet.length]);
  }
  let hues: number[] = [];
  for (var c of ColorSet) {
    hues.push(Math.round(RGBToHSV(c).h * 360));
  }
  hues.sort(function (a, b) {
    return a - b;
  });
  let lowH: number = 0.0,
    interval: number = 0.0;
  if (hues.length === 1) {
    interval = 360.0;
    lowH = lowH * hues[hues.length - 1];
  } else {
    let count: number = hues.length;
    for (let ii = 0; ii < count; ++ii) {
      let currH: number = hues[ii];
      let nextH: number = ii === count - 1 ? hues[0] : hues[ii + 1];
      let newInterval: number = nextH - currH;
      if (newInterval < 0) {
        newInterval = 360.0 - currH + nextH;
      }
      if (newInterval > interval) {
        lowH = currH;
        interval = newInterval;
      }
    }
  }
  let hue: number = lowH + interval * 0.5;
  if (hue > 360) {
    hue -= 360;
  }
  return HSVtoRGB({ h: hue, s: 0.68, v: 0.98 });
};

export const getColorPallete = (colorsNumber: number) => {
  let colorPallete: string[] = [];
  let temp: RGB[] = [];

  for (let j = 0; j < colorsNumber; j++) {
    temp.push(getNextColor(temp));
  }
  temp.forEach((item) => {
    colorPallete.push(RGBToHEX(item));
  });

  return colorPallete;
};

export const categoryColorsMap = new Map<string, string>([
  [CategoryTypeValues.ceilings, '#D1D1D1'],
  [CategoryTypeValues.exteriorOpenings, '#35A3B9'],
  [CategoryTypeValues.interiorOpenings, '#6DCDE0'],
  [CategoryTypeValues.exteriorWall, '#299129'],
  [CategoryTypeValues.interiorWalls, '#3BD23B'],
  [CategoryTypeValues.interiorFloors, '#BE29BE'],
  [CategoryTypeValues.raisedFloors, '#DA7DDA'],
  [CategoryTypeValues.roofs, '#2A42B6'],
  [CategoryTypeValues.shades, '#767673'],
  [CategoryTypeValues.slabsOnGrade, '#E7B1BA'],
  [CategoryTypeValues.undergroundCeilings, '#801628'],
  [CategoryTypeValues.undergroundSlabs, '#D0863B'],
  [CategoryTypeValues.undergroundWalls, '#EDD768'],
]);
