import React from 'react';
import UnitEnum from '../../../shared/UnitConversion/UnitEnums';
import * as conversions from '../../../conversions';
import i18n from '../../../i18n';

import { convertECDefinitionsm2toft2 } from '../../helper';

export type UnitConversionProps = {
  value: string | number;
  sourceUnit: UnitEnum;
  targetUnit: UnitEnum;
  round?: number;
  useFraction?: boolean;
};

const unitConversionMap = {
  [UnitEnum.Meter]: {
    [UnitEnum.Foot]: conversions.MetersToFeets,
    [UnitEnum.Millimeter]: conversions.MetersToMillimeters,
    [UnitEnum.Inch]: conversions.MeterstoInches,
  },
  [UnitEnum.KgPerM3]: {
    [UnitEnum.PoundsPerFt3]: conversions.Kgm3toPoundsFt3,
  },
  [UnitEnum.M2KPerW]: {
    [UnitEnum.HrFt2PerBtu]: conversions.M2KWTohrFt2Btu,
  },
  [UnitEnum.MeterSq]: {
    [UnitEnum.FeetSq]: conversions.M2ToFt2,
  },
  [UnitEnum.Meter3]: {
    [UnitEnum.Feet3]: conversions.M3ToFt3,
  },
  [UnitEnum.Kg]: {
    [UnitEnum.Pounds]: conversions.KgToPounds,
  },
  [UnitEnum.KgCO2ePerM2]: {
    [UnitEnum.KgCO2ePerFt2]: convertECDefinitionsm2toft2,
  },
};

export const getConvertedUnitValue = ({
  value,
  sourceUnit,
  targetUnit,
  round = 2,
  useFraction = false,
}: UnitConversionProps): number => {
  if (value === null || value === '' || typeof value === 'undefined') {
    return null;
  }

  //Convert to number if type string
  if (typeof value === 'string') {
    value = Number(value);
  }

  //Check if conversion is possible
  if (
    isNaN(value) ||
    (sourceUnit !== targetUnit &&
      (!unitConversionMap[sourceUnit] || !unitConversionMap[sourceUnit][targetUnit]))
  ) {
    return null;
  }

  if (sourceUnit !== targetUnit) {
    const converter = unitConversionMap[sourceUnit][targetUnit];
    value = converter(value);
  }

  if (round != null && !useFraction) {
    value = conversions.round(value, round);
  }

  return value as number;
};

export const padZero = (value: number, round: number) => {
  if (!round) {
    return value.toString();
  }

  let convertedValue = value.toString();
  let decimalIndex = convertedValue.indexOf('.');
  if (decimalIndex == -1) {
    convertedValue += '.';
    decimalIndex = convertedValue.length - 1;
  }
  return convertedValue.padEnd(decimalIndex + round + 1, '0');
};

export const UnitConversion = ({
  value,
  sourceUnit,
  targetUnit,
  round = 2,
  useFraction = false,
}: UnitConversionProps): JSX.Element => {
  if (value === null) {
    //adding the empty tag inorder to preserve the default hig table cell behaviour
    return <>{i18n.t(`analysis.constant.nullValue`)}</>;
  }

  value = getConvertedUnitValue({ value, sourceUnit, targetUnit, round, useFraction });

  if (value === null) {
    return null;
  }

  if (useFraction) {
    return <>{conversions.getFractionValue(value)}</>;
  }

  //adding the empty tag inorder to preserve the default hig table cell behaviour
  return <>{padZero(value, round)}</>;
};
