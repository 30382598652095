import { httpGet, httpPostJson } from '../shared';
import { getInsightApigeeBasePath } from '../shared/api';

export const getRuns = (modelId: string) => {
    return httpGet(`${getInsightApigeeBasePath()}/api/v1/simulations`, {
        data: { modelId }
    });
}

export const runSimulation = (modelFileId: string, modelId: string, projectId: string, name: string) => {
    return httpPostJson(`${getInsightApigeeBasePath()}/api/v1/simulations`, {
        data: {
            ModelId: modelFileId,
            ModelInstanceModelId: modelId,
            ProjectId: projectId,
            Name: name
        }
    });
}

export const getSimulationStatus = (apiForgeBaseUrl: string, id: string) => {
    return httpGet(`${apiForgeBaseUrl}/api/v1/simulations/status`, {
        data: { id }
    });
}

//todo: identify id's ideally looks like to be a string array 
export const getSimulationsStatus = (apiForgeBaseUrl: string,ids: any) => {
    return httpPostJson(`${apiForgeBaseUrl}/api/v1/simulations/status`, {
        data: ids
    });
}

export const getUnitScheme = (apiForgeBaseUrl: string, schemaId: string) => {
    return httpGet(`${apiForgeBaseUrl}/schema/v3/schemas/propertySets:${schemaId}`);
}