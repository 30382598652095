import React, { Component } from 'react';

export class ExpandIcon extends Component<any,any> {

    render() {
        // @ts-ignore: unrecognizable type
        return <svg id="Layer_1" x="0px" y="0px" viewBox="0 0 16 16" style={{ enableBackground: "new 0 0 16 16" }}
            xmlSpace="preserve" className="expand-icon" onClick={this.props.clickHandler}>
            <rect className="expand-icon-backgound" width={16} height={16} />
            <g>
                <defs>
                    <polygon id="SVGID_1_" points="8,8 1.8,2 0,3.7 4.5,8 0,12.3 1.8,14  " />
                </defs>
                <use xlinkHref="#SVGID_1_"/>
                <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }}/>
                </clipPath>
            </g>
            <g>
                <defs>
                    <polygon id="SVGID_3_" points="16,8 9.8,2 8,3.7 12.5,8 8,12.3 9.8,14"/>
                </defs>
                <use xlinkHref="#SVGID_3_" style={{ overflow: "visible" }}
                />
                <clipPath id="SVGID_4_">
                    <use xlinkHref="#SVGID_3_" style={{ overflow: "visible" }}/>
                </clipPath>
            </g>
        </svg>;
    }
}
