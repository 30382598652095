import i18n from '../../../i18n';
import { EcDefinition } from '../types';

export type ValidationResponse = {
  name: boolean;
  coefficient: boolean;
  source: boolean;
  notes: boolean;
};
export const LABEL_TEXT_INPUT_CHARACTER_LIMIT: number = 50;
export const TEXT_INPUT_CHARACTERS_LIMIT: number = 100;
export const SOURCE_LINK_CHARACTERS_LIMIT: number = 2048;
export const TEXTAREA_INPUT_CHARACTERS_LIMIT: number = 600;
export const COEFFICIENT_MIN_VALUE: number = -99999;
export const COEFFICIENT_MAX_VALUE: number = 99999;

export const isValidURL = (source: string): boolean => {
  const urlExpression: RegExp = new RegExp('^(http|https)://', 'i');
  return !!urlExpression.test(source);
};

const isFieldFilled = (name): boolean => name.trim().length > 0;

const isCoefficientFilled = (coefficient: number | string, unit: string): boolean => {
  if (isFieldFilled(unit)) {
    if (
      (typeof coefficient === 'string' && isFieldFilled(coefficient)) ||
      (typeof coefficient === 'number' && coefficient !== null)
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isLongerThanAllowed = (field: string, maximumCharacters: number): boolean => {
  return field && field.length > maximumCharacters;
};

const isInRange = (value: number, minimumValue: number, maximumValue): boolean => {
  if (value === 0 || isNaN(value)) {
    return true;
  }
  return value && minimumValue <= value && value <= maximumValue;
};

export const areValidValues = (
  name: string,
  coefficient: number,
  source: string,
  notes: string
): ValidationResponse => {
  return {
    name: isLongerThanAllowed(name, TEXT_INPUT_CHARACTERS_LIMIT),
    coefficient: !isInRange(coefficient, COEFFICIENT_MIN_VALUE, COEFFICIENT_MAX_VALUE),
    source: isLongerThanAllowed(source, SOURCE_LINK_CHARACTERS_LIMIT),
    notes: isLongerThanAllowed(notes, TEXTAREA_INPUT_CHARACTERS_LIMIT),
  };
};

export const checkMandatoryFields = (
  name: string,
  coefficient: string | number,
  unit: string
): boolean => {
  return isFieldFilled(name) && isCoefficientFilled(coefficient, unit);
};

export const checkMaximumCharactersExceeded = (
  name: string,
  coefficient: number,
  source: string,
  notes: string
): boolean =>
  isLongerThanAllowed(name, TEXT_INPUT_CHARACTERS_LIMIT) ||
  isLongerThanAllowed(source, SOURCE_LINK_CHARACTERS_LIMIT) ||
  isLongerThanAllowed(notes, TEXTAREA_INPUT_CHARACTERS_LIMIT) ||
  !isInRange(coefficient, COEFFICIENT_MIN_VALUE, COEFFICIENT_MAX_VALUE);

export const unitBuilder = (unit: string): { value: string; name: string } => {
  let unitName: string = '';
  switch (unit) {
    case i18n.t('analysis.ec.ecDefinition.units.m2BE'):
      unitName = i18n.t('analysis.ec.ecDefinition.units.m2');
      break;
    case i18n.t('analysis.ec.ecDefinition.units.ft2BE'):
      unitName = i18n.t('analysis.ec.ecDefinition.units.ft2');
      break;
    case i18n.t('analysis.ec.ecDefinition.units.m3BE'):
      unitName = i18n.t('analysis.ec.ecDefinition.units.m3');
      break;
    case i18n.t('analysis.ec.ecDefinition.units.ft3BE'):
      unitName = i18n.t('analysis.ec.ecDefinition.units.ft3');
      break;
    case i18n.t('analysis.ec.ecDefinition.units.lbsBE'):
      unitName = i18n.t('analysis.ec.ecDefinition.units.lbs');
      break;
    case i18n.t('analysis.ec.ecDefinition.units.m2RsiBE'):
      unitName = i18n.t('analysis.ec.ecDefinition.units.m2Rsi');
      break;
    case i18n.t('analysis.ec.ecDefinition.units.ft2RBE'):
      unitName = i18n.t('analysis.ec.ecDefinition.units.ft2R');
      break;
    case i18n.t('analysis.ec.ecDefinition.units.itemBE'):
      unitName = i18n.t('analysis.ec.ecDefinition.units.itemBE');
      break;
    default:
      unitName = unit;
      break;
  }
  return {
    value: `kgCO₂e/${unit}`,
    name: `kgCO₂e/${unitName}`,
  };
};

export const buildApiObjectFromECDefinition = (ecDefinition: EcDefinition) => ({
  id: ecDefinition.id,
  source: ecDefinition.source,
  name: ecDefinition.name,
  average: ecDefinition.average,
  description: ecDefinition.notes,
  labels: ecDefinition.labels,
  averageUncertainty: ecDefinition.averageUncertainty,
  conservativeEstimate: ecDefinition.conservativeEstimate,
  achievableEstimate: 0,
  declaredUnit: `1 ${ecDefinition.unit.substring(ecDefinition.unit.indexOf('/') + 1)}`,
});
