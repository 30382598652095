import React, { Component } from 'react';
import '../css/textEditor.css';

export type TextEditorProps = {
    updateValue?: (newValue: string) => void,
    value: string,
}

type ClassState = {
    value: string;
}

export class TextEditor extends Component<TextEditorProps, ClassState> {

    constructor(props: TextEditorProps) {
        super(props);
        this.updateValue = this.updateValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = { value: props.value };
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    updateValue(event) {
        this.props.updateValue(this.state.value);
        event.preventDefault();
    }

    render() {
        return (
            <div className="texteditor-container">
                <form onSubmit={this.updateValue}>
                    <input autoComplete="off" name="value" maxLength={50} autoCapitalize="off" autoCorrect="off"
                        spellCheck="false" style={{ width: '258px', height: '23px', 'fontSize': '16px', 'fontFamily': 'RobotoCondensed' }} value={this.state.value} onChange={this.handleChange} />
                    <button className="icon-ok" type="submit"></button>
                </form>
            </div>
        );
    }
}