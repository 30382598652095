import React, { useEffect } from 'react';
import GripIcon from '../../img/grip.svg';

type GripperResizeProps = {
    firstComponent: JSX.Element,
    secondComponent: JSX.Element,
    hideGripper?: boolean,
    width?: number,
    onResize?: (leftWidth: number) => void
}

const GripperResize = (props: GripperResizeProps) => {

    const gripRef = React.useRef(null);
    const x = React.useRef(0);
    const y = React.useRef(0);

    const leftWidth = React.useRef(0);

    const mouseMoveHandler = React.useCallback((e) => {
        const leftSide = gripRef.current.previousElementSibling;
        const rightSide = gripRef.current.nextElementSibling;

        const dx = e.clientX - x.current;

        const newLeftWidth = ((leftWidth.current + dx) * 100) / gripRef.current.parentNode.getBoundingClientRect().width;
        leftSide.style.width = `${newLeftWidth}%`;

        document.body.style.cursor = 'col-resize';
        gripRef.current.firstChild.style.cursor = 'col-resize';

        leftSide.style.userSelect = 'none';
        leftSide.style.pointerEvents = 'none';

        rightSide.style.userSelect = 'none';
        rightSide.style.pointerEvents = 'none';
    }, []);

    const mouseUpHandler = function () {
        const leftSide = gripRef.current.previousElementSibling;
        const rightSide = gripRef.current.nextElementSibling;

        gripRef.current.firstChild.style.cursor = 'ew-resize';
        document.body.style.removeProperty('cursor');

        leftSide.style.removeProperty('user-select');
        leftSide.style.removeProperty('pointer-events');

        rightSide.style.removeProperty('user-select');
        rightSide.style.removeProperty('pointer-events');

        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);

        if (props.onResize) {
            props.onResize(parseFloat(leftSide.style.width.replace("%", "")));
        }
    };

    const gripMouseDownHandler = React.useCallback((e) => {
        x.current = e.clientX;
        y.current = e.clientY;
        leftWidth.current = gripRef.current.previousElementSibling.getBoundingClientRect().width;

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    }, [])

    useEffect(() => {
        if (gripRef.current) {
            gripRef.current.previousElementSibling.style.width = `${props.width}%`;
        }
    }, [props.width]);

    return (
        <div style={{ width: '100%', display: 'flex' }}>
            {props.firstComponent}
            <div ref={gripRef} style={{ flexShrink: 0, padding: '6px', display: 'flex', alignItems: 'center' }}>
              <GripIcon style={{ height: '62px', width: '8px', cursor: 'ew-resize', visibility: props.hideGripper ? 'hidden' : 'unset' }} onMouseDown={gripMouseDownHandler}/></div>
            {props.secondComponent}
        </div>
    );
}


export default GripperResize;
