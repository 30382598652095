import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '@hig/button';
import i18n from '../i18n';
import * as locConsts from "./localization/consts";
import { Orientation, Glazing, Hvac, Infiltration, LightingEfficiency, PlugLoadEfficiency, RoofConstruction, WallConstruction, WindowShades, WindowWallRatio } from '../svg/index';
import { footerButtonStyle } from '../layout/LayoutGrid.stylesheet';

export type FactorsModalProps = {
    open:boolean,
    categories?:Categories[],
    onCloseClick:any,
    onSimulateClick:any   
}

type Categories = {
    display: boolean,
    factors: CategoriesFactor[],
    key: string,
    name: string
}
type CategoriesFactor = {
    background: string,
    description: string,
    disabled: boolean
    key: string,
    name: string,
    svgComponent: string
}

export default class FactorsModal extends Component<FactorsModalProps,any> {
    preventDoubleClick: any;
    constructor(props:FactorsModalProps) {
        super(props);

        this.state = {
            selected: {

            },
            title: null,
            description: null
        };
    }

    componentDidUpdate(prevProps: FactorsModalProps) {
        if (prevProps.open !== this.props.open) {
            this.setState({ selected: {} });
        }
    }

    onFactorClick = (f:CategoriesFactor) => {
        if (f.disabled) {
            return;
        }

        const value = this.state.selected[f.key];
        const newSelected = {
            ...this.state.selected,
            [f.key]: !value
        };

        this.setState({
            selected: newSelected
        });
    };

    onSimulateClick = (e: any) => {
        if (this.preventDoubleClick) {
            return;
        }

        this.preventDoubleClick = true;

        const selectedKeys = [];

        for (const [k, v] of Object.entries(this.state.selected)) {
            if (v) {
                selectedKeys.push(k);
            }
        }

        this.props.onSimulateClick(selectedKeys).finally(() => {
            this.preventDoubleClick = false;
        });
    }

    onUpdatePanelContent = (f: CategoriesFactor) => {

        this.setState({
            title: f.name,
            description: f.description,
            background: f.background
        });
    }

    renderList() {
        if (!this.props.categories) {
            return null;
        }

        return (
            <ul className="factors-category-list">
                {
                    this.props.categories.map((c) => {
                        return (
                            <li key={c.key}>
                                <p className="factor-category-label">{c.name}</p>
                                <ul className="factors-list">
                                    {
                                        c.factors.map((f) => {
                                            let checkMarkClass, classes;

                                            if (f.disabled) {
                                                classes = "factor-button disabled";
                                                checkMarkClass = "factor-checkmark-svg";
                                            } else if (this.state.selected[f.key]) {
                                                classes = "factor-button selected";
                                            } else {
                                                classes = "factor-button";
                                            }

                                            return (
                                                <li key={f.key}>
                                                    <div
                                                        role="button"
                                                        className={classes}
                                                        onClick={(e) => this.onFactorClick(f)}
                                                        onMouseEnter={(e) => this.onUpdatePanelContent(f)}
                                                    >
                                                        <SvgComponent componentName={f.svgComponent}/>
                                                        <p>{f.name} </p>
                                                        <span className={checkMarkClass}></span>
                                                    </div>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </li>
                        );
                    })
                }
            </ul>
        );
    }

    render() {
        const { title, description, background } = this.state;
        const enableSimulate = Object.entries(this.state.selected).some(([key, value]) => value);
        let panelClasses = "factors-modal-factor-description";

        if (background) {
            panelClasses = `${panelClasses} background ${background}`;
        }

        return (
            <Modal
                toggle={this.props.onCloseClick}
                isOpen={this.props.open}
                className="factors-modal"
            >
                <ModalHeader
                    toggle={this.props.onCloseClick}
                    className="insightx-modal-header"
                >
                    {i18n.t(locConsts.FACTORS_MODAL_TITLE)}
                </ModalHeader>
                <ModalBody>
                    <div className="factors-modal-content">
                        <div className="factors-modal-list-wrapper">
                            {this.renderList()}
                        </div>
                        <div className={panelClasses}>
                            <p className="factor-title">{title}</p>
                            <p className="factor-description">{description}</p>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="insightx-modal-footer">
                    <Button
                        sx={{ ...footerButtonStyle }}
                        title={i18n.t(locConsts.FACTORS_MODAL_BUTTON_SECONDARY)}
                        type="secondary"
                        onClick={this.props.onCloseClick}
                    />
                    <Button
                        sx={{ ...footerButtonStyle }}
                        disabled={!enableSimulate}
                        title={i18n.t(locConsts.FACTORS_MODAL_BUTTON_PRIMARY)}
                        type="primary"
                        onClick={this.onSimulateClick}
                    />
                </ModalFooter>
            </Modal>
        );
    }
}

class SvgComponent extends Component<any,any> {

    render() {

        const svgComponents = {
            glazing: Glazing,
            hvac: Hvac,
            infiltration: Infiltration,
            lightingEfficiency: LightingEfficiency,
            plugLoadEfficiency: PlugLoadEfficiency,
            roofConstruction: RoofConstruction,
            wallConstruction: WallConstruction,
            windowShades: WindowShades,
            windowWallRatio: WindowWallRatio,
            orientation: Orientation
        };

        const TagName = svgComponents[this.props.componentName];
        return <TagName/>;
    }
}
