import { Collapse, ListItemIcon, ListItemText } from '@mui/material';
import { DashboardM, BookmarkM, DeviceDesktopM } from '@weave-mui/icons-weave';
import MenuItem from '@weave-mui/menu-item';
import MenuList from '@weave-mui/menu-list';
import { SxProps, Theme } from '@weave-mui/styles';
import React, { useMemo } from 'react';
import i18n from '../../../../i18n';
import TooltipMenuItem from './TooltipMenuItem';
import { ParametersTypesEnum } from '../../../../types/metrics';
import navLinks, { NavigationOption } from '../../utils/sideNavLinks';

interface SideMenuProps {
  collapsed: boolean;
  onOptionClick: (navOption:ParametersTypesEnum) => void;
}

export interface SideNavigationOption extends NavigationOption {
  icon: JSX.Element;
}

const dataPointsIconMap: Record<ParametersTypesEnum, JSX.Element> = {
  factors: <BookmarkM/>,
  metrics: <DashboardM/>,
  benchmarks: <DeviceDesktopM/>
};


const SideMenu: React.FC<SideMenuProps> = ({ collapsed, onOptionClick }) => {
  const sideNavLinks: SideNavigationOption[] = useMemo(() => {
    return navLinks.map(link => {
      return {
        ...link,
        icon: dataPointsIconMap[link.modalType]
      }
    })
  }, [navLinks]);

  return (
    <MenuList data-testid='side-nav-menu-list'>
      {
        sideNavLinks.map(link => {
          return <TooltipMenuItem collapsed={collapsed} navigationOption={link} onOptionClick={onOptionClick}/>
        })
      }
    </MenuList>
  )
};

export default SideMenu;
