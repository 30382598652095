import { useSignals } from '@preact/signals-react/runtime';
import React from 'react';
import Modal from '@weave-mui/modal';
import Box from '@weave-mui/box';
import { linearProgressVariants, typographyVariants } from '@weave-mui/enums';
import Typography from '@weave-mui/typography';
import LinearProgress from '@weave-mui/linear-progress';

export const ReportProgress: React.FC<{
  display: boolean;
  title: string;
  info: string;
}> = ({ display, title, info }) => {
  useSignals();

  return (
    <Modal
      header={title}
      open={display}
      sx={{
        '& .MuiModalWeave-closeIcon': {
          display: 'none',
        },
        '& .MuiModalWeave-box': {
          width: '400px',
          height: '150px'
        },
      }}
      disableEscapeKeyDown
    >
      <>
        <Typography sx={{padding: '10px 0 5px 0'}} variant={typographyVariants.BODY_REGULAR}>{info}</Typography>
        <Box width="100%">
          <LinearProgress variant={linearProgressVariants.INDETERMINATE} />
        </Box>
      </>
    </Modal>
  );
};
