export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const PATCH = 'PATCH';
export const DELETE = 'DELETE';
export const UncategorizedModels = 'UncategorizedModels';
export const Project = 'Project';
export const Projects = 'Projects';
export const ProjectModels = 'ProjectModels';
export const Models = 'Models';
export const ModelFiles = 'ModelFiles';
export const ModelsEcData = 'ModelsEcData';
export const EcDefinitions = 'EcDefinitions';
export const dashboardTagType = 'Dashboards';
export const dashboardTagTypes = [dashboardTagType];
