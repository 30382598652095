import React from 'react';
import Alert from '@weave-mui/alert';
import Button from '@weave-mui/button';
import Typography, { typographyVariants } from '@weave-mui/typography';

export type BannersMap = Map<string, JSX.Element>;

interface IAlertBannerProps {
  severity: any,
  buttonUrl?: string,
  buttonText?: string,
  bannerText: string,
  onCloseBanner: () => void
}

const { BODY_REGULAR } = typographyVariants;

const AlertBanner = ({ severity, buttonText, buttonUrl, bannerText, onCloseBanner }: IAlertBannerProps): JSX.Element => {

  return (
    <Alert
      severity={severity}
      onClose={onCloseBanner}
      action={
        (buttonText && buttonUrl) &&
        <Button
          href={buttonUrl}
          target='_blank'
        >
          {buttonText}
        </Button>
      }
    >
      <Typography variant={BODY_REGULAR} sx={{ fontWeight: '600' }}>
        {bannerText}
      </Typography>
    </Alert>
  )
};

export default AlertBanner;
