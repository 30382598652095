export const convertArrayToObjects = (array: Array<object>, index: string) => {
    var newObject = array.reduce(function (obj, item) {
        var a = item[index].valueOf();
        obj[a] = item;
        return obj;
    }, {});
    
    return newObject;
}

export const convertObjectToarray = (object: object) => {
    var array = Object.entries(object).map(([key, value]) => {
        return value;
    });

    return array;
}

export const isEmpty = (obj: object) => {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export const getBase64 = (blob: Blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}