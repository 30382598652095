import { createApi } from '@reduxjs/toolkit/query/react';
import { Currency } from '../../types/currency';
import { BaseQuery, QueryType } from './base-queries';

export const currencyDataApi = createApi({
  reducerPath: 'currencyDataApi',
  baseQuery: BaseQuery(QueryType.Apigee),
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getCurrencyData: builder.query<Currency[], void>({
      query: () => `/v1/projects/currencies`,
    }),
  }),
});

export const { useGetCurrencyDataQuery, useLazyGetCurrencyDataQuery } = currencyDataApi;
