export const isElementVisible = (el: HTMLElement) => {
  const rect     = el.getBoundingClientRect(),
    vWidth   = window.innerWidth || document.documentElement.clientWidth,
    vHeight  = window.innerHeight || document.documentElement.clientHeight,
    efp      = (x: number, y: number) => document.elementFromPoint(x, y);

  // Return false if it's not in the viewport
  if (rect.right < 0 || rect.bottom < 0
          || rect.left > vWidth || rect.top > vHeight)
    return false;

  // Return true if any of its four corners are visible
  return (
    // check if at least half of the card is visible
    el.contains(efp(rect.left,  rect.top + el.offsetHeight / 2 ))
    // check if at least half of the card is visible
    ||  el.contains(efp(rect.right, rect.top + el.offsetHeight / 2))
    ||  el.contains(efp(rect.right, rect.bottom))
    ||  el.contains(efp(rect.left,  rect.bottom))
  );
}
