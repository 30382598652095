import React, { Component } from 'react';

export class RoofConstruction extends Component {

    render() {
        return <svg
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 16 16"
            style={{
                // @ts-ignore: unrecognizable type
                enableBackground: "new 0 0 16 16"
            }}
            xmlSpace="preserve"
            className="factor-modal-icon"
        >
            <style type="text/css">
                {
                    "\n\t.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#7C7C7C;}\n\t.st1{fill:#7C7C7C;}\n"
                }
            </style>
            <polygon
                className="factor-modal-icon-color factor-modal-icon-rule"
                points="3,2 0,10 11,10 13,4 13.5,4 15,10 16,10 14,2 "
            />
            <polygon className="factor-modal-icon-color factor-modal-icon-rule" points="8,11 1,11 1,15 8,15 10,15 10,11 " />
            <rect x={14} y={11} className="factor-modal-icon-color" width={1} height={4} />
        </svg>;
    }
}
