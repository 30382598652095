import React, { Component } from "react";
import ReactDOM from "react-dom";
import '../css/modal.css';

type ModalProps = {
    title?: string,
    subTitle?: string,
    name?: string,
    buttonText?: string,
    isDialog?: boolean,
    hideModal?: any,
    buttonAction?: () => void,
    visible?:boolean
}

export class Modal extends Component<ModalProps> {

    render() {

        const dialog = (
            <div>
                <div className="confirm-deletion-popup">
                    <div className="title">{this.props.title}</div>
                    <div className="sub-title">{this.props.subTitle}</div>
                    <span className="icon-delete" onClick={this.props.hideModal}></span>
                    <div className="name">{this.props.name}</div>
                    <div className="delete-btn btn" onClick={this.props.buttonAction}>{this.props.buttonText}</div>
                </div>
                <div className="modal-fade" onClick={this.props.hideModal} />
            </div>
        );

        const modal = (
            <div>
                {this.props.children}
                <div className="modal-fade" onClick={this.props.hideModal} />
            </div>
        );

        return (ReactDOM.createPortal(this.props.isDialog ? dialog : modal, document.querySelector("#modal")));

    }
}