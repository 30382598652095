import { useState } from 'react';
import { PatchMetricRequest } from '../../types/metrics';

type ApiCall<T> = (params: T) => Promise<any>;
type ValidateRequest<T> = (params: PatchMetricRequest) => boolean;

interface UseCustomAPICallResult<PatchMetricRequest> {
  data: PatchMetricRequest | null;
  error: string | null;
  callEditApi: (params: any) => Promise<void>;
}

const useValidateApiCall = (
  apiCall: ApiCall<PatchMetricRequest>,
  validateRequest: ValidateRequest<PatchMetricRequest>
): UseCustomAPICallResult<PatchMetricRequest> => {
  const [data, setData] = useState<PatchMetricRequest | null>(null);
  const [error, setError] = useState<string | null>(null);

  const callEditApi = async (params: PatchMetricRequest) => {
    try {
      if (!validateRequest(params)) {
      const response = await apiCall(params);
      setData(response.data);
      }
    } catch (err: any) {
      setError(err.message || 'An error occurred');
    }
  };

  return { data, error, callEditApi };
};

export default useValidateApiCall;
