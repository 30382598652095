  export const buttonStyle = {
    minWidth:0,
    margin:'0 0 0 10px !important',
}

export const maximumCharactersErrorStyles = {
    marginTop: '3px'
}

export const maximumCharactersNotesErrorStyles = {
    padding: '0px'
};

export const textLinkStylesheet = (styles, props, themeData) => ({
    ...styles,
    ...(props.hasFocus && {
        outline: 'none'
    })
});

export const formLabelPaddingStyles = {
  padding: '0 8px 0 0'
};

export const errorIconStyles = {
  color: '#DD2222',
  marginRight: '4px',
  marginTop: '2px'
};
