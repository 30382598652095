import React from 'react';
import Typography from '@weave-mui/typography';
import { typographyVariants } from '@weave-mui/enums';
import Box from '@weave-mui/box';
import { ConstructionDataGridHeaderProps } from '../types';
import { constructioDataGridStyles } from '../../../shared/HigTableStylesheet';

const { BODY_REGULAR, BODY_BOLD } = typographyVariants;

const ConstructionDataGridHeader = ({ title, unit }: ConstructionDataGridHeaderProps) => {

  return (
    <Box className="MuiDataGrid-columnHeaderTitle" sx={{ ...constructioDataGridStyles }}>
      <>
        <Typography className="construction-table-header-tile" component="span" variant={BODY_BOLD}>
          {title}
        </Typography>
        {unit &&  <Typography component="span" variant={BODY_REGULAR}>({unit})</Typography>}
      </>
    </Box>
  );
};

export default ConstructionDataGridHeader;
