import i18n from "../../../i18n";
import { useCreateMetricsMutation, useDeleteMetricMutation, useEditMetricMutation } from "../../../state/api/data-service-api";
import { DataPointsNotification, Metric, PatchMetricRequest, RenameDataPointRequest } from "../../../types/metrics";
import { toCreateMetricRequest } from "../../../types/requests/parameters";
import useValidateApiCall from "../../hooks/useValidateApiCall";
import { CreateMetricStrategyRequest, DataPointsStrategy, EditMetricStrategyRequest } from "../../types/strategy";
import { generateEditMetricObject, isRequestEmpty } from "../../utils/dataPointsUtils";

export const useMetricsStrategy = (): DataPointsStrategy<CreateMetricStrategyRequest, EditMetricStrategyRequest> => {
  const [createMetric, {isLoading: isCreateMetricLoading}] = useCreateMetricsMutation();
  const [editMetric, {isLoading: isEditMetricLoading}] = useEditMetricMutation();
  const [deleteMetric, {isLoading: isDeleteMetricLoading}] = useDeleteMetricMutation();
  const { data, error, callEditApi } = useValidateApiCall(editMetric, isRequestEmpty);

  const createMetricMethod = async (request: CreateMetricStrategyRequest, additionalParam: string): Promise<Metric> => {
    const { dataPoint, modelId } =  request;
    const updatedFormula = additionalParam;
    const updatedMetric = {
      ...dataPoint,
      formula: updatedFormula
    }
    const metricRequest = toCreateMetricRequest(updatedMetric);

    try {
      const newMetricResponse = await createMetric({
        ...metricRequest,
        modelId
      }).unwrap();

      const createdMetric: Metric = {
        ...updatedMetric,
        id: newMetricResponse.id,
      };

      return createdMetric;
    } catch (err) {
      console.log('Error in create metric strategy ', err)
    }
  };

  const editMetricMethod = async (request: EditMetricStrategyRequest, additionalParam: string): Promise<Metric> => {
    const { originalDataPoint, updatedDataPoint, modelId } = request;
    const updatedFormula = additionalParam;
    
    const dataPointToCompare: Metric = {
      ...updatedDataPoint,
      formula: updatedFormula
    }

    const propertiesToUpdate: PatchMetricRequest = generateEditMetricObject(dataPointToCompare, originalDataPoint);
    propertiesToUpdate.modelId = modelId;
    
    try {
      await callEditApi(propertiesToUpdate);
      return dataPointToCompare;
    } catch (err) {
      console.log('Error in edit metric strategy ', err)
    }
  };

  const renameMetricMethod =  async (parameterId: string, newName: string, modelId: string): Promise<void> => {
    const renameDataPointRequest: RenameDataPointRequest = {
      id: parameterId,
      displayName: newName,
      modelId
    };

    try {
      await editMetric(renameDataPointRequest);
    } catch (err) {
      console.log('Error in rename metric strategy ', err)
    }
  }

  const deleteMetricMethod = async (parameterId: string, modelId: string): Promise<void> => {
    try {
      await deleteMetric({parameterId, modelId});
    } catch (err) {
      console.log('Error in delete metric strategy ', err)
    }
  }

  const generateMetricNotificationMethod = (isSelectAction: boolean): DataPointsNotification => {
    return {
      title: i18n.t('analysis.dataPoints.labels.changeNotificationTitle'),
      text: i18n.t('analysis.dataPoints.metrics.changeNotificationText'),
      textSecondaryBn: i18n.t('analysis.dataPoints.labels.changeNotificationCloseButton'),
      textPrimaryBn: isSelectAction
          ? i18n.t('analysis.dataPoints.metrics.changeNotificationExitButton')
          : i18n.t('analysis.dataPoints.metrics.changeNotificationExitModalButton')
    };
  }


  return {
    createDataPoint: createMetricMethod,
    editDataPoint: editMetricMethod,
    deleteDataPoint: deleteMetricMethod,
    renameDataPoint: renameMetricMethod,
    isCreateLoading: isCreateMetricLoading,
    isEditLoading: isEditMetricLoading,
    isDeleteLoading: isDeleteMetricLoading,
    generateDataPointsNotificationObject: generateMetricNotificationMethod
  }
}