import i18n from "../../../i18n";
import {
  useCreateBenchmarkMutation,
  useDeleteBenchmarkMutation,
  useEditBenchmarkMutation
} from "../../../state/api/data-service-api";
import {
  Benchmark,
  DataPointsNotification,
  PatchBenchmarkRequest,
  PostBenchmarkRequest,
  RenameDataPointRequest
} from "../../../types/metrics";
import { CreateBenchmarkStrategyRequest, DataPointsStrategy, EditBenchmarkStrategyRequest } from "../../types/strategy";
import { generateCreateBenchmarkRequest } from "../../utils/benchmarksUtils";

export const useBenchmarksStrategy = (): DataPointsStrategy<CreateBenchmarkStrategyRequest, EditBenchmarkStrategyRequest> => {
  const [createBenchmark, {isLoading: isCreateBenchmarkLoading}] = useCreateBenchmarkMutation();
  const [deleteBenchmark,  { isLoading: isDeleteBenchmarkLoading }] = useDeleteBenchmarkMutation();
  const [editBenchmark, { isLoading: isEditBenchmarkLoading }] = useEditBenchmarkMutation();
  const createBenchmarkMethod = async (request: CreateBenchmarkStrategyRequest): Promise<Benchmark> => {
    const {dataPoint, modelId} = request;
    const benchmarkRequest: PostBenchmarkRequest = generateCreateBenchmarkRequest(dataPoint, modelId);
    try {
      const newBenchmarkResponse = await createBenchmark(benchmarkRequest).unwrap();
      return newBenchmarkResponse;
    } catch (err) {
      console.log('Error in create benchmark strategy', err);
    }
  }

  const editBenchmarkMethod = async (request: EditBenchmarkStrategyRequest): Promise<Benchmark> => {
    const {updatedDataPoint, modelId} = request;

    const benchmarkToUpdate: PatchBenchmarkRequest = {
      id: updatedDataPoint.id,
      displayName: updatedDataPoint.displayName,
      description: updatedDataPoint.description,
      value: updatedDataPoint.value,
      metrics: updatedDataPoint.metrics,
      modelId
    };
    try {
      await editBenchmark(benchmarkToUpdate);
      return updatedDataPoint;
    } catch (err) {
      console.log('Error in edit benchmark strategy', err);
    }
  }

  const renameBenchmarkMethod = async (parameterId: string, newName: string, modelId: string): Promise<void> => {
    const renameDataPointRequest: RenameDataPointRequest = {
      id: parameterId,
      displayName: newName,
      modelId
    };

    try {
      await editBenchmark(renameDataPointRequest);
      return;
    } catch (err) {
      console.log('Error in rename metric strategy ', err)
    }
  }

  const deleteBenchmarkMethod = async (parameterId: string, modelId: string): Promise<void> => {
    try {
      await deleteBenchmark({parameterId, modelId});
      return;
    } catch (error) {

    }
  }

  const generateBenchmarkNotificationMethod = (isSelectAction: boolean): DataPointsNotification => {
    return {
      title: i18n.t('analysis.dataPoints.labels.changeNotificationTitle'),
      text: i18n.t('analysis.dataPoints.benchmarks.changeNotificationText'),
      textSecondaryBn: i18n.t('analysis.dataPoints.labels.changeNotificationCloseButton'),
      textPrimaryBn: isSelectAction
          ? i18n.t('analysis.dataPoints.benchmarks.changeNotificationExitButton')
          : i18n.t('analysis.dataPoints.benchmarks.changeNotificationExitModalButton')
    };
  }

  return {
    createDataPoint: createBenchmarkMethod,
    editDataPoint: editBenchmarkMethod,
    deleteDataPoint: deleteBenchmarkMethod,
    renameDataPoint: renameBenchmarkMethod,
    generateDataPointsNotificationObject: generateBenchmarkNotificationMethod,
    isCreateLoading: isCreateBenchmarkLoading,
    isEditLoading: isEditBenchmarkLoading,
    isDeleteLoading: isDeleteBenchmarkLoading
  }
}
