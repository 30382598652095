enum UnitEnum {
    Meter,
    Foot,
    Millimeter,
    Inch,
    KgPerM3,
    PoundsPerFt3,
    M2KPerW,
    HrFt2PerBtu,
    MeterSq,
    FeetSq,
    Meter3,
    Feet3,
    Kg,
    Pounds,
    KgCO2e,
    KgCO2ePerM2,
    KgCO2ePerFt2,
    Mbtu,
    WPerM2K,
    BtuPerHrFt2F
}

export default UnitEnum;
