import { useSelector } from 'react-redux';
import { useGetProjectQuery } from '../../state/api/project-model-data-api';
import { ApplicationDataState } from '../../state/slice/application-data-slice';
import { LayoutHookReturnType } from '../../types/layout';
import { ProjectModel } from '../../project/ProjectTypes';

export const useCurrentProjectData = (): LayoutHookReturnType<ProjectModel> => {
  const projectId = useSelector(
    (state: { applicationDataState: ApplicationDataState }) =>
      state.applicationDataState.currentProjectId
  );

  const { data, isLoading, isError, isSuccess } = useGetProjectQuery(projectId, {
    skip: !projectId,
  });

  return {
    data,
    isError,
    isSuccess,
    isLoading,
  };
};

export const useGetImperialSystem = (): LayoutHookReturnType<boolean> => {
  const { data: projectData, isSuccess, isLoading, isError, isFetching } = useCurrentProjectData();
  return {
    data: projectData ? !projectData.useSIUnits : false,
    isLoading,
    isError,
    isFetching,
    isSuccess,
  };
};
