import React from 'react';

export type CreateProjectWidgetProps = {
  action?: () => void;
  text?: string;
};

const CreateProjectWidget: React.FC<CreateProjectWidgetProps> = (props) => {  
  return (
    <div
      className="project-widget clearfix"
      id="create_project"
      onClick={props.action}
      key="999"
    >
      <div className="create-btn">
        <span className="icon-plus"></span>
        <label>{props.text}</label>
      </div>
    </div>
  );
};

export default CreateProjectWidget;
