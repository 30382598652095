import * as modelType from '../dashboard.models';
import { MutableRefObject } from 'react';
import { GridApi, GridCellParams } from '@weave-mui/data-grid';

export const M: string = 'm';
export const M2: string = 'm2';
export const M3: string = 'm3';
export const KG: string = 'kg';
export const M2RSI: string = 'm2 RSI';
export const ITEM: string = 'item';

export type EcDefinition = {
  id: string;
  source: string;
  name: string;
  notes: string;
  labels: string[];
  average: number;
  averageUncertainty: number;
  conservativeEstimate: number;
  unit: string;
  isCustom: boolean;
  inheritedModelId?: string;
};

export type EcDefinitionsTableColumn = {
  field: string;
  flex?: number;
  sortable: boolean;
  disableColumnMenu: boolean;
  renderCell: (props: GridCellParams) => JSX.Element | Element | null;
  cellWrapperOptions?: {
    lineClamp?: string;
    isFlyout?: boolean;
    disable?: boolean;
  };
  minWidth?: number;
  width?: number;
};

export type SortedEcDefinitions = Map<string, EcDefinition[]>;

export type IECDefinitionsState = {
  ecDefinitions: SortedEcDefinitions;
  newECDefinitionId: string;
};
export interface ECDefinitionsTableProps {
  data: EcDefinition[];
  onRowClick?: () => void;
  useSI: boolean;
  editECDefinition: (ecDefinition: EcDefinition) => void;
  deleteECDefinition: (ecDefinitionId: string) => void;
  latestECDefinitionId: string;
  selectedRow: {[key: string]: string | number | Array<string> | boolean } | undefined;
  apiRef: MutableRefObject<GridApi>;
  onCellClick: (params: GridCellParams) => void;
  disableVirtualization?: boolean;
};

export type EcDefinitionValueRow = {
  cell?: any;
  data: { unit: string }[];
  row: { index: number; values: any };
};

export type EcValueProps = {
  value: number;
  unit: string;
  useSI: boolean;
};

export enum LmvLegendViewByOptions {
  Constructions = 'Constructions',
  DetailLevel = 'Detail Level',
  Categories = 'Categories',
  SurfaceType = 'Surface Type',
}

export enum LabelVariant {
  Top = 'top',
  Side = 'side',
}

export enum DropDownVariant {
  Line = 'line',
  Box = 'box',
}

export type LmvLegendItems = {
  id: string;
  name: string;
  color: string;
  viewerElements?: ViewerElement[];
  surfaceType?: string;
};

export type ViewerElement = {
  id: string;
  surfaceIds?: string[];
  isHidden?: boolean;
};

export type DetailLevelItem = {
  surfaceId: string;
  viewerElements?: ViewerElement[];
};

export type LmvLegendData = {
  constructions: LmvLegendItems[];
  detailLevels: LmvLegendItems[];
  categories: LmvLegendItems[];
};

export type SortedColumn = {
  columnTitle: string;
  isSorted: boolean;
};

export type ECDetailsPreference = {
  viewBy: LmvLegendViewByOptions;
  sorting: SortedColumn;
  selectedConstructionId: string;
  defaultChart: string;
  legendCollapseState: LegendCollapseState;
  chartLMVGripper: number;
  selectedMaterialSha: string;
};

export type LegendCollapseState = {
  lmvLegendState: boolean;
  chartLegendState: boolean;
};

export type FilteredConstructions = {
  constructionId: string;
  surfaceIds?: string[];
  constructionName: string;
  detailLevel?: number;
  constructionDescription: string;
  totalThickness: string;
  area: string;
  totalVolume: string;
  totalMass: string;
  totalEmbodiedCarbon: number;
  ecIntensity: string;
  materials: modelType.MaterialEntity[];
  surfaceType?: string;
  isExterior: boolean;
  uValue: number;
};

export type MaterialEcDefinitionUpdate = {
  elementId: string;
  embodiedCarbon: number;
};

export enum CategoryTypeValues {
  exteriorWall = 'ExteriorWall',
  roofs = 'Roofs',
  undergroundSlabs = 'UndergroundSlabs',
  slabsOnGrade = 'SlabsOnGrade',
  raisedFloors = 'RaisedFloors',
  exteriorOpenings = 'ExteriorOpenings',
  undergroundWalls = 'UndergroundWalls',
  interiorWalls = 'InteriorWalls',
  interiorOpenings = 'InteriorOpenings',
  interiorFloors = 'InteriorFloor',
  ceilings = 'Ceilings',
  undergroundCeilings = 'UndergroundCeilings',
  shades = 'Shades',
  other = 'Other',
}



export type ECDetailsConstructionDataGridProps = {
  disableVirtualization?: boolean;
  ecDetailsPreference: ECDetailsPreference;
  filteredConstructions: FilteredConstructions[];
  modelIdECBreakdown: string;
  openAdvancedSearchWeave: (row: modelType.MaterialRow) => void;
  renderEcDefinitionContentWeave: (
    material: modelType.MaterialRow,
    openAdvancedSearchWeave: (row: modelType.MaterialRow) => void,
    selectedEcDefinition: EcDefinition,
    setAnchorEl: (anchorEl: HTMLElement | null) => void,
  ) => JSX.Element;
  selectConstructionWeave: ({ constructionId, materialSha}) => void;
  useSI: boolean;
};

export type ConstructionDataGridHeaderProps = {
  title: string;
  unit?: string;
};
