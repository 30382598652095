import React, { Fragment, useCallback, useState } from 'react';
import Box from '@weave-mui/box';
import { ListItemIcon } from '@mui/material';
import Typography, { typographyVariants } from '@weave-mui/typography';
import { SideNavigationOption } from './SideMenu';
import Tooltip, { tooltipPlacement, tooltipSize } from '@weave-mui/tooltip';

interface TooltipMenuIconProps {
  navigationOption: SideNavigationOption;
}

const TooltipMenuIcon: React.FC<TooltipMenuIconProps> = ({ navigationOption }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  
  const handleOnMouseEnter = useCallback(() => {
    setShowTooltip(true);
  },[]);

  const handleTooltipClose = useCallback(() => {
    setShowTooltip(false);
  },[]);
  
  return (
    <Fragment>
      <Tooltip
        arrow
        enterDelay={500}
        enterNextDelay={1000}
        leaveDelay={0}
        title={
          <Box>
            <Typography
              data-testid={`menu-item-tooltip-title-${navigationOption.modalType}`}
              variant={typographyVariants.CAPTION_BOLD}
            >
              {navigationOption.descriptionTitle}
              </Typography>
          </Box>
        }
        description={
          <Box>
            <div style={{ marginTop: '14px' }}></div>
            <Typography 
              data-testid={`menu-item-tooltip-description-${navigationOption.modalType}`}
              variant={typographyVariants.CAPTION_REGULAR}
            >
              {navigationOption.descriptionBody}
            </Typography>
          </Box>
        }
        open={showTooltip}
        size={tooltipSize.MEDIUM}
        onClose={handleTooltipClose}
        placement={tooltipPlacement.RIGHT}
        PopperProps={{
          style: {
            zIndex: 10000
          },
        }}
      >
        <ListItemIcon onMouseEnter={handleOnMouseEnter} data-testid={`menu-item-icon-${navigationOption.modalType}`}>
          {navigationOption.icon}
        </ListItemIcon>
      </Tooltip>
    </Fragment>
  )
}

export default TooltipMenuIcon;
