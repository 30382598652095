import React, { useContext, useEffect, useRef } from 'react';
import { CardContentContext } from './base/BaseCard';
import { FreeEntryTextCardData } from '../../types/layout';
import Typography, { typographyVariants } from '@weave-mui/typography';
import { freeEntryTextCardStyles } from './base/Card.stylesheet';
import { useCardGrid } from '@adsk/wildcard';
import {Editor, EditorState, ContentState} from 'draft-js';
import 'draft-js/dist/Draft.css';
import i18n from '../../i18n';
import Box from '@weave-mui/box';
import Divider, { dividerOrientations } from '@weave-mui/divider';

const textCardLimit = 2000;

export const FreeEntryTextCard: React.FC = () => {
  const { settings, saveCardSetting } = useContext(CardContentContext);
  const { text = '' } = settings as unknown as FreeEntryTextCardData;

  const debounceRef = useRef<NodeJS.Timeout | null>(null)

  const { state: { customizeMode } } = useCardGrid();

  const [editorState, setEditorState] = React.useState(
    () => EditorState.createWithContent(ContentState.createFromText(text)),
  );

  useEffect(() => {
    setEditorState(EditorState.createWithContent(ContentState.createFromText(text)));
    return () => clearTimeout(debounceRef.current);
  }, [customizeMode, text])

  const editorOnChange = (ev) => {
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => saveCardSetting('text', ev.getCurrentContent().getPlainText()), 10_000);
    setEditorState(ev);
  }

  const handleOnBlur = () => {
    clearTimeout(debounceRef.current);
    saveCardSetting('text', editorState.getCurrentContent().getPlainText());
  }

  const handleBeforeInput = (
    chars: string,
    editorState: EditorState
  ) => {
    const currentLength = editorState.getCurrentContent().getPlainText().length + chars.length;
    return currentLength > textCardLimit ? 'handled' : 'not-handled';
  }

  const handlePastedText = (
    text: string,
    html: string,
    editorState: EditorState
  ) => {
    const currentLength = editorState.getCurrentContent().getPlainText().length + text.length;
    return currentLength > textCardLimit ? 'handled' : 'not-handled';
  }

  const currentTextCount = editorState.getCurrentContent().getPlainText().length;

  return customizeMode && (<>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}>
              <Box sx={{
                flexGrow: 1,
                overflow: 'auto',
                cursor: 'text',
                '& .public-DraftEditorPlaceholder-inner': {
                  whiteSpace: 'nowrap !important',
                },
                '& .DraftEditor-root': {
                  width: '100%',
                  height: '100%',
                }
              }}>
                <Typography variant={typographyVariants.BODY_REGULAR} sx={{ ...freeEntryTextCardStyles, height: '100%', pl:'0px' }}>
                  <Editor
                    editorState={editorState}
                    onChange={editorOnChange}
                    placeholder={i18n.t('tabConfiguration.editModel.freeEntryTextEditorPlaceholder')}
                    handleBeforeInput={handleBeforeInput}
                    handlePastedText={handlePastedText}
                    onBlur={handleOnBlur}
                  />
                </Typography>
              </Box>
              <Box sx={{alignSelf: 'stretch'}}>
                <Divider orientation={dividerOrientations.HORIZONTAL} />
                <Typography variant={typographyVariants.BODY_REGULAR} sx={{ ...freeEntryTextCardStyles, pl: '0px' }}>
                  {i18n.t('tabConfiguration.editModel.freeEntryTextEditorCharactersRemaining')} {textCardLimit - currentTextCount}
                </Typography>
              </Box>
    </Box>
  </>) || <Typography variant={typographyVariants.BODY_REGULAR} sx={{ ...freeEntryTextCardStyles, pl:'0px' }}>
        {text}
    </Typography>
  ;
};
