import Box from '@weave-mui/box';
import React, {Dispatch, SetStateAction, useCallback} from 'react';
import BenchmarksBasicDetails from '../BenchmarksBasicDetails/BenchmarksBasicDetails';
import {Benchmark, Factor, Metric, ParametersModalType} from '../../../../types/metrics';
import ActionButtons from '../../shared/ActionButtons/ActionButtons';
import {CardDataLoadingProgress} from "../../../../layout/cards/base/CardDataLoadingProgress";
import BenchmarksMetricsTable from '../BenchmarksMetricsList/BenchmarksMetricsTable';
import {BenchmarksModalNoResults} from "../../../../shared/Error/ErrorComponent";

interface BenchmarksInformation {
  selectedDataPoint: Benchmark | undefined;
  setSelectedDataPoint: Dispatch<SetStateAction<Metric | Factor |Benchmark>>;
  metricsList: Metric[];
  imperialUnits: boolean;
  cancelHandler: () => void;
  saveDataPoint: (modifiedDataPoint: Benchmark) => void;
  isReadOnly: boolean;
  setIsReadOnly: Dispatch<SetStateAction<boolean>>;
  isSaveEnabled: boolean;
  setIsSaveEnabled: Dispatch<SetStateAction<boolean>>;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  onCloseModal: () => void;
  modalType: ParametersModalType;
  benchmarksList: Benchmark[];
}

const BenchmarksInformation: React.FC<BenchmarksInformation> = ({
  selectedDataPoint,
  setSelectedDataPoint,
  metricsList,
  imperialUnits,
  cancelHandler,
  saveDataPoint,
  isReadOnly,
  setIsReadOnly,
  isSaveEnabled,
  setIsDirty,
  onCloseModal,
  modalType,
  benchmarksList
}) => {

  const onSaveHandler = useCallback(() => {
    saveDataPoint(selectedDataPoint);
  }, [saveDataPoint])

  const onCancelHandler = useCallback(() => {
    cancelHandler();
  }, [cancelHandler]);

  if (metricsList.length === 0) return <CardDataLoadingProgress/>

  if (!selectedDataPoint) return <CardDataLoadingProgress/>;

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'hidden',
      height: '100%',
      width: '100%',
      pr: '0.5rem',
      pt: '0.5rem'
    }}>
      {
        (benchmarksList.length === 0 && selectedDataPoint.name.length === 0)
          ?
        <BenchmarksModalNoResults/>
          :
      <Box sx={
        {
          display: 'flex',
          flexDirection: 'column',
          height: '90%',
          width: '100%',
          overflow: 'hidden'
        }
      }>
        <BenchmarksBasicDetails
          selectedBenchmark={selectedDataPoint}
          setSelectedBenchmark={setSelectedDataPoint}
          isReadOnly={isReadOnly}
          modalType={modalType}
        />
        <BenchmarksMetricsTable
          selectedBenchmark={selectedDataPoint}
          setSelectedBenchmark={setSelectedDataPoint}
          metricsList={metricsList}
          imperialUnits={imperialUnits}
          isReadOnly={isReadOnly}
          benchmarksList={benchmarksList}
        />
      </Box>
      }
      <Box sx={
        {
          alignSelf: 'end'
        }
      }>
        <ActionButtons
          selectedDataPoint={selectedDataPoint}
          onCancel={onCancelHandler}
          onSaveDataPoint={onSaveHandler}
          isReadOnly={isReadOnly}
          setIsReadOnly={setIsReadOnly}
          isSaveButtonEnabled={isSaveEnabled}
          setIsDirty={setIsDirty}
          onCloseModal={onCloseModal}
        />
      </Box>
    </Box>
  )
}

export default BenchmarksInformation;
