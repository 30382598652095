import React from 'react';
import DataGrid, { DataGridPremiumProps } from '@weave-mui/data-grid';

const WeaveTableWrapper: React.FC<DataGridPremiumProps> = ({ ...props}: DataGridPremiumProps) => {

  return (
    <DataGrid {...props} disableVirtualization />
  )
}

export default WeaveTableWrapper;
