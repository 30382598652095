import React, { memo, useEffect } from 'react';
import { LayoutHookReturnType } from '../../../types/layout';

type CardDataLoaderProps = {
  hook: (input?: string) => LayoutHookReturnType<unknown>;
  hookParam: string | null;
  outputResult: (result: LayoutHookReturnType<unknown>) => void;
};

// important that this component does not re-render unnessecarily
// it is responsible to loading and listening to data changes
export const CardDataFetcher = memo<CardDataLoaderProps>(
  (props) => {
    const { outputResult, hook, hookParam } = props;
    const { data, isLoading, isError, isSuccess, isFetching } = hook(hookParam);

    useEffect(() => {
      outputResult({ data, isLoading, isError, isSuccess, isFetching });
    }, [data, isError, isLoading, isSuccess, isFetching]);

    return <></>;
  },
  () => true
);
