import React from 'react';
import Modal, { ModalProps } from '@weave-mui/modal';
import '../css/new/ecDefinition.css';


const WeaveModalDialog = ({
  ...props
}: ModalProps): JSX.Element => {
  return (

        <Modal {...props}>
          {props.open && props.children}
        </Modal>    
  );
};

export default WeaveModalDialog;
