import { createApi } from '@reduxjs/toolkit/query/react';
import { ProjectModel } from '../../project/ProjectTypes';
import { QueryOptions, BaseQuery, QueryType } from './base-queries';
import { Model, ModelFile } from '../../analysis/dashboard.models';
import {
  POST,
  DELETE,
  UncategorizedModels,
  Project,
  Projects,
  ProjectModels,
  Models,
  ModelFiles,
} from './api-constants';

const EVENT_TYPE = 'project';

export const projectModelDataApi = createApi({
  reducerPath: 'projectDataApi',
  baseQuery: BaseQuery(QueryType.Apigee),
  tagTypes: [Project, Projects, UncategorizedModels, ProjectModels, Models, ModelFiles],
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    // ---------------Models---------------------
    getUncategorizedModels: builder.query<Model[], void>({
      query: () => `/v1/models/uncategorized`,
      providesTags: [UncategorizedModels],
      extraOptions: {
        errorOptions: { notificationType: 'fullPage' },
        analyticsOptions: {
          eventType: EVENT_TYPE,
          eventName: 'getUncategorizedModels',
        },
      } as QueryOptions,
    }),
    getModel: builder.query<Model, string>({
      query: (id) => `/v1/models/byModelId/${id}`,
      providesTags: (result, error, id) => [{ type: Models, id }],
      extraOptions: {
        errorOptions: { notificationType: 'fullPage' },
        analyticsOptions: {
          eventType: EVENT_TYPE,
          eventName: 'getModel',
          parseResponseMeta: (data: Model) => {
            const { id: modelId, area, useSiUnits, buildingType, factors } = data;
            return { modelId, area, useSiUnits, buildingType, factors } as unknown;
          },
        },
      } as QueryOptions,
    }),
    getModelFile: builder.query<ModelFile, string>({
      query: (id) => `/v1/models/${id}`,
      providesTags: (result, error, id) => [{ type: ModelFiles, id }],
      extraOptions: {
        errorOptions: { notificationType: 'toast' },
        analyticsOptions: {
          eventType: EVENT_TYPE,
          eventName: 'getModelFile',
        },
      } as QueryOptions,
    }),
    getProjectModels: builder.query<Model[], string>({
      query: (projectId) => `/v1/models/${projectId}/modelsByProject`,
      providesTags: (result, error, projectId) => [{ type: ProjectModels, id: projectId }],
      extraOptions: {
        errorOptions: { notificationType: 'fullPage' },
        analyticsOptions: { eventType: EVENT_TYPE, eventName: 'getProjectModels' },
      } as QueryOptions,
    }),
    updateModel: builder.mutation<Model[], { id: string; projectId: string; name: string }>({
      query: (body: { id; projectId; name }) => ({
        url: '/v1/models/update',
        method: POST,
        body,
      }),
      invalidatesTags: (result, error, { projectId, id }) => {
        return [
          { type: ProjectModels, id: projectId },
          { type: Models, id },
        ];
      },
    }),
    moveModel: builder.mutation<Model[], { id: string; projectId: string; name: string }>({
      query: (body: { id; projectId; name }) => ({
        url: '/v1/models/update',
        method: POST,
        body,
      }),
      invalidatesTags: [ProjectModels, UncategorizedModels],
    }),
    updateModelList: builder.mutation<Model[], { models: Model[]; projectId: string }>({
      query: (body: { models; projectId }) => ({
        url: '/v1/models/update/list',
        method: POST,
        body,
      }),
      invalidatesTags: [UncategorizedModels, ProjectModels],
    }),
    deleteModel: builder.mutation<void, string>({
      query: (projectId) => ({
        url: `/v1/models/delete/${projectId}`,
        method: DELETE,
      }),
      invalidatesTags: [ProjectModels, UncategorizedModels],
    }),
    // ---------------Projects-------------------
    getProject: builder.query<ProjectModel, string>({
      query: (projectId) => `/v1/projects/${projectId}`,
      providesTags: (result, error, projectId) => [{ type: Projects, id: projectId }],
      extraOptions: {
        errorOptions: { notificationType: 'fullPage' },
        analyticsOptions: { eventType: EVENT_TYPE, eventName: 'getProject' },
      } as QueryOptions,
    }),
    getProjects: builder.query<ProjectModel[], void>({
      query: () => `/v1/projects`,
      providesTags: [Projects],
      extraOptions: {
        errorOptions: { notificationType: 'fullPage' },
        analyticsOptions: { eventType: EVENT_TYPE, eventName: 'getProjects' },
      } as QueryOptions,
    }),
    createProject: builder.mutation<ProjectModel, Model[]>({
      query: (models) => ({
        url: '/v1/projects/createModelsBased',
        method: POST,
        body: { models },
      }),
      invalidatesTags: [Projects, UncategorizedModels, ProjectModels],
    }),
    updateProject: builder.mutation<ProjectModel, ProjectModel>({
      query: (project) => ({
        url: `/v1/projects/${project.id}/update`,
        method: POST,
        body: { project },
      }),
      invalidatesTags: (result, error, project) => {
        return [{ type: Projects, id: project.id }, Projects];
      },
    }),
    deleteProject: builder.mutation<Model[], string>({
      query: (projectId) => ({
        url: `/v1/projects/${projectId}`,
        method: DELETE,
      }),
      invalidatesTags: [Projects],
    }),
    updateProjectSettings: builder.mutation<
      ProjectModel,
      {
        projectId: string;
        useSiUnits: boolean;
        currencyIso: string;
        fuelRate: number;
        electricityRate: number;
      }
    >({
      query: (body: { projectId; useSiUnits; currencyIso; fuelRate; electricityRate }) => ({
        url: `/v1/projects/settings`,
        method: POST,
        body,
      }),
      invalidatesTags: (result, error, { projectId }) => {
        return [{ type: Projects, id: projectId }];
      },
    }),

    // ---------------EC-------------------
    ecSimulations: builder.mutation<Model[], { modelFileId: string; projectId: string }>({
      query: (body: { modelFileId; projectId }) => ({
        url: '/v1/ecsimulations',
        method: POST,
        body,
      }),
      invalidatesTags: (result, error, { projectId }) => {
        return [{ type: ProjectModels, id: projectId }, UncategorizedModels];
      },
    }),
  }),
});

export const {
  useGetUncategorizedModelsQuery,
  useGetModelQuery,
  useGetModelFileQuery,
  useGetProjectModelsQuery,
  useUpdateModelMutation,
  useMoveModelMutation,
  useUpdateModelListMutation,
  useDeleteModelMutation,
  useGetProjectQuery,
  useGetProjectsQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useUpdateProjectSettingsMutation,
  useEcSimulationsMutation,
} = projectModelDataApi;

export const {
  useLazyGetUncategorizedModelsQuery,
  useLazyGetModelQuery,
  useLazyGetProjectModelsQuery,
  useLazyGetProjectQuery,
} = projectModelDataApi;
