import { useSignals } from '@preact/signals-react/runtime';
import Box from '@weave-mui/box';
import { typographyVariants, spacings, formLabelVariants } from '@weave-mui/enums';
import FormControl from '@weave-mui/form-control';
import FormLabel from '@weave-mui/form-label';
import TextField from '@weave-mui/text-field';
import Typography from '@weave-mui/typography';
import React, { RefObject } from 'react';
import { AiaDdxAuthRequestKeys, AiaDdxImportProjectDataKeys } from '../aia-ddx/const';
import { ReportDataSection } from '../types/reporting';
import i18n from '../../i18n';

export const FormSection: React.FC<{
  section: ReportDataSection<AiaDdxAuthRequestKeys | AiaDdxImportProjectDataKeys>;
}> = ({ section }) => {
  useSignals();

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '300px 1fr',
        gridTemplateRows: 'auto',
        rowGap: '20px',
        paddingBottom: '5px',
      }}
    >
      <Box
        sx={{
          gridColumn: '1 / span 2',
        }}
      >
        <Typography variant={typographyVariants.BODY_BOLD}>{section.name}</Typography>
      </Box>

      {section.fields.map((field) => {
        return (
          <Box sx={{ height: '65px' }}>
            <FormControl>
              <FormLabel sx={{ mr: spacings.S }} variant={formLabelVariants.TOP}>
                {field.name} *
              </FormLabel>
              <TextField
                ref={field?.ref && (field.ref as RefObject<HTMLInputElement>)}
                type={field.maskField ? 'password' : 'text'}
                value={field.data}
                error={field.hasError.value}
                helperText={
                  field.hasError.value ? `${i18n.t('reporting.validation.required')}` : ''
                }
                onChange={(event) => {
                  field.hasError.value = false; // clear error on re-input, error only shown on submit
                  field.data.value = event.target.value.toString();
                  field?.dataPersistance?.set(event.target.value.toString());
                }}
                sx={{ minWidth: '240px' }}
              />
            </FormControl>
          </Box>
        );
      })}
    </Box>
  );
};
