import React from 'react';
import Box from "@weave-mui/box";
import Typography, { typographyVariants } from "@weave-mui/typography";
import i18n from '../../../../../i18n';

const CustomNoRowsOverlay: React.FC = () => {
  return (
    <Box sx={{
      p: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }}>
      <Typography variant={typographyVariants.H3_BOLD}>{ i18n.t('analysis.dataPoints.benchmarks.noMetricsFoundTitle') }</Typography>
      <Typography variant={typographyVariants.BODY_MEDIUM}>{ i18n.t('analysis.dataPoints.benchmarks.noMetricsFoundText') }</Typography>
    </Box>
  )
};

export default CustomNoRowsOverlay;
