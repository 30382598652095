import React, { useState, useRef, useEffect, useLayoutEffect, useMemo } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Typography,
  typographyVariants,
} from '@weave-mui/material';
import Modal from '@weave-mui/modal';
import TextField from '@weave-mui/text-field';
import i18n from '../../i18n';
import { maximumCharactersErrorStyles } from '../../analysis/EmbodiedCarbon/AddECDefinition/ECDefinition.stylesheet';
import type { DialogProps } from '@mui/material';
import LinearProgress, { linearProgressVariants } from '@weave-mui/linear-progress';
import { AlertS, ErrorS, InfoS } from '@weave-mui/icons-weave';
import { DashboardEditMenuOptions } from '../../types/layout';
import { RENAME_TEXT_INPUT_CHARACTERS_MAX_LIMIT, RENAME_TEXT_INPUT_CHARACTERS_MIN_LIMIT } from './LayoutTabs';
import { errorIconTabModelStyles, } from './LayoutTabs.stylesheet';
import { isDuplicateName, isLongerThanAllowedText } from '../../utils/dashboard';
import { footerButtonStyle, headerIconStyle } from '../LayoutGrid.stylesheet';

export const LayoutTabsConfigurationModal: React.FC<any> = ({
  open,
  onClose,
  editMode,
  tabName,
  onConfigModelButtonClick,
  dashboardOperationStatus,
  tabs,
  isDashboardDeleting = false,
  namePromptState = null,
  currentTab = null
}) => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const [renameDashboardText, setRenameDashboardText] = useState(null);
  const [maximumCharactersExceeded, setMaximumCharactersExceeded] = useState<boolean>(false);
  const [isEmptyRenameTextField, setIsEmptyRenameTextField] = useState<boolean>(false);
  const [isDuplicateTabName, setIsDuplicateTabName] = useState<boolean>(false);

  const getRenameDashboardText = () => {
    return editMode === DashboardEditMenuOptions.NamePrompt ? namePromptState?.value || '' : tabName;
  }

  const tabsNames = useMemo(() => tabs
    ?.filter((tab) => editMode === DashboardEditMenuOptions.NamePrompt || tab.value !== currentTab?.value)
    .map((tab) => tab.wildCardConfig.dashboard.name) || [], [tabs, editMode, currentTab]);

  const onModalClose = () => {
    setRenameDashboardText(null);
    onClose()
  }

  const onModalOkClick = () => {
    onConfigModelButtonClick(editMode, renameDashboardText);
    if (editMode !== DashboardEditMenuOptions.Rename) {
      setRenameDashboardText(null);
    }
  }

  useEffect(() => {
    setMaximumCharactersExceeded(isLongerThanAllowedText(renameDashboardText, RENAME_TEXT_INPUT_CHARACTERS_MAX_LIMIT));
    setIsEmptyRenameTextField(renameDashboardText?.length < RENAME_TEXT_INPUT_CHARACTERS_MIN_LIMIT);
    setIsDuplicateTabName(isDuplicateName(renameDashboardText, tabsNames));
  }, [renameDashboardText, tabName, tabsNames]);

  useEffect(() => {
    if (editMode) {
      const name = getRenameDashboardText()
      setRenameDashboardText(name);
      setIsDuplicateTabName(isDuplicateName(name, tabsNames));
    }

    setTimeout(() => {
      textFieldRef.current?.querySelector('input')?.focus();
      textFieldRef.current?.querySelector('input')?.select();
    }, 0)
  }, [editMode]);
  const buttonBox = (buttonTitle) => {
    return dashboardOperationStatus ? (
      <></>
    ) : (
      <Box>
        <Box
          display="flex"
          justifyContent="end"
          gap={3}>
          {editMode !== DashboardEditMenuOptions.MaxLimit && (
            <Button sx={{ ...footerButtonStyle }} onClick={onModalClose} variant="outlined">
              {i18n.t('tabConfiguration.editModel.cancelButton')}
            </Button>
          )}
          <Button
            sx={{ ...footerButtonStyle }}
            onClick={onModalOkClick}
            disabled={
              editMode !== DashboardEditMenuOptions.Delete && (maximumCharactersExceeded ||
                isEmptyRenameTextField ||
                isDuplicateTabName || (editMode === DashboardEditMenuOptions.Rename && renameDashboardText === tabName))
            }
          >
            {buttonTitle}
          </Button>
        </Box>
      </Box>
    );
  };

  const renderModalContent = (editMode) => {
    let modalTitle;
    let modalContent;
    let buttonTitle = i18n.t('tabConfiguration.editModel.okButton');
    switch (editMode) {
      case DashboardEditMenuOptions.Delete:
        modalTitle = (
          <Typography variant={typographyVariants.H3_MEDIUM}>
            {!isDashboardDeleting && <AlertS color="warning" style={headerIconStyle} />}
            {i18n.t('tabConfiguration.editModel.deleteTitle')}
          </Typography>
        );
        buttonTitle = i18n.t('tabConfiguration.editModel.deleteButton');
        modalContent = renderDeleteModalContent();
        break;
      case DashboardEditMenuOptions.Duplicate:
        modalTitle = i18n.t('tabConfiguration.editModel.duplicateTitle');
        modalContent = renderDuplicateModalContent();
        break;
      case DashboardEditMenuOptions.Rename:
        modalTitle = i18n.t('tabConfiguration.editModel.renameTitle');
        modalContent = renderRenameModalContent();
        break;
      case DashboardEditMenuOptions.NamePrompt:
        modalTitle = i18n.t(
          namePromptState?.op === DashboardEditMenuOptions.Add ?
            'tabConfiguration.editModel.addTitle' :
            'tabConfiguration.editModel.duplicateTitle'
        );
        modalContent = renderRenameModalContent();
        break;
      case DashboardEditMenuOptions.Add:
        modalTitle = i18n.t('tabConfiguration.editModel.addTitle');
        break;
      case DashboardEditMenuOptions.Update:
        modalTitle = i18n.t('tabConfiguration.editModel.updateTitle');
        break;
      case DashboardEditMenuOptions.MaxLimit:
        modalTitle = (
          <Typography variant={typographyVariants.H3_MEDIUM}>
            <InfoS color="info" style={headerIconStyle} />
            {i18n.t('tabConfiguration.editModel.maxDashboardLimitTitle')}
          </Typography>
        );
        modalContent = renderMaxDashboardModalContent();
        break;
    }

    const handleCustomModelOnClose: DialogProps['onClose'] = (event, reason) => {
      if (reason && reason === 'backdropClick' && dashboardOperationStatus) return;
      onClose();
    };

    const getEditModeActionText = (editMode) => {
      let textTitle = '';
      switch (editMode) {
        case DashboardEditMenuOptions.Delete:
          textTitle = i18n.t('tabConfiguration.editModel.deleteActionTitle');
          break;
        case DashboardEditMenuOptions.Duplicate:
          textTitle = i18n.t('tabConfiguration.editModel.duplicateActionTitle');
          break;
        case DashboardEditMenuOptions.Rename:
          textTitle = i18n.t('tabConfiguration.editModel.renameActionTitle');
          break;
        case DashboardEditMenuOptions.Add:
          textTitle = i18n.t('tabConfiguration.editModel.addActionTitle');
          break;
        case DashboardEditMenuOptions.Update:
          textTitle = i18n.t('tabConfiguration.editModel.updateActionTitle');
          break;
      }
      return textTitle;
    };

    return (
      <Modal
        header={modalTitle}
        open={open}
        onClose={handleCustomModelOnClose}
        actions={buttonBox(buttonTitle)}
        sx={{
          '& .MuiModalWeave-box': {
            width: '400px'
          },
        }}
        disableEscapeKeyDown
      >
        {dashboardOperationStatus ? (
          <Box>
            <Box sx={{ minHeight: '50px', paddingTop: '20px' }}>
              <Typography variant={typographyVariants.BODY_REGULAR}>
                {getEditModeActionText(editMode)}
              </Typography>
              <LinearProgress variant={linearProgressVariants.INDETERMINATE} />
            </Box>
          </Box>
        ) : (
          <Box>
            {modalContent}
          </Box>
        )}
      </Modal>
    );
  };
  const renderMaxDashboardModalContent = () => {
    return (
      <Typography>
        {i18n.t('tabConfiguration.editModel.maxDashboardLimit')}
      </Typography >
    );
  };
  const renderDeleteModalContent = () => {
    return (
      <Typography>
        {i18n.t('tabConfiguration.editModel.deleteContent')}
      </Typography>
    );
  };

  const renderDuplicateModalContent = () => {
    return (
      <Typography>
        {i18n.t('tabConfiguration.editModel.duplicateContent')}
      </Typography >
    );
  };

  const renderRenameModalContent = () => {
    return (
      <Box sx={{ minHeight: '60px' }}>
        <FormLabel sx={{ padding: '0px' }}>{i18n.t('tabConfiguration.menuItems.name')}</FormLabel>
        <FormControl sx={{ width: '99%' }}>
          <div className={'inputWithError'}>
            <TextField
              ref={textFieldRef}
              value={renameDashboardText}
              onChange={(e) => setRenameDashboardText(e.target.value)}
              sx={{ margin: '5px 0 0 0 !important' }}
              fullWidth
            />
          </div>
          <div className={'errorMessageAndIcon'}>
            {(maximumCharactersExceeded || isDuplicateTabName) && (
              <ErrorS sx={{ ...errorIconTabModelStyles }} />
            )}
            {maximumCharactersExceeded && (
              <Typography
                variant={typographyVariants.BODY_REGULAR}
                sx={{ ...maximumCharactersErrorStyles }}
              >
                {i18n.t('tabConfiguration.editModel.maxTextLimit')}
              </Typography>
            )}
            {isDuplicateTabName && (
              <Typography
                variant={typographyVariants.BODY_REGULAR}
                sx={{ ...maximumCharactersErrorStyles }}
              >
                {i18n.t('tabConfiguration.editModel.duplicateTabName')}
              </Typography>
            )}
          </div>
        </FormControl>
      </Box>
    );
  };

  return <>{renderModalContent(editMode)}</>;
};
