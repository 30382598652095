import Typography from '@weave-mui/typography';
import React from 'react';
import { useCurrentThumbnailData } from '../../layout/hooks/useCurrentThumbnailData';
import { useSelector } from 'react-redux';
import { useGetModelQuery } from '../../state/api/project-model-data-api';
import { ApplicationDataState } from '../../state/slice/application-data-slice';
import Box from '@weave-mui/box';
import { useTheme } from '@weave-mui/styles';
import { ReportData, ReportDataField } from '../types/reporting';
import { AiaDdxImportProjectDataKeys, AiaDdxImportProjectDataRequest } from '../aia-ddx/const';
import { typographyVariants, spacings } from '@weave-mui/enums';
import { Signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { useDataPointValue } from '../../layout/hooks/useDataPointValue';

export const ReportSidePanel: React.FC<{
  dataIsLoading: Signal<boolean>;
  reportData: ReportData<AiaDdxImportProjectDataKeys>;
}> = ({ dataIsLoading, reportData }) => {
  useSignals();
  const theme = useTheme();
  const currentModelId = useSelector(
    (state: { applicationDataState: ApplicationDataState }) =>
      state.applicationDataState.currentModelId
  );
  const { data: model } = useGetModelQuery(currentModelId, { skip: !currentModelId });
  const { data: thumbnail } = useCurrentThumbnailData(model?.urn, model?.lmvStatus);
  const imgObject = thumbnail?.base64;
  const summaryFields = reportData.sections
    .map((x) => x.fields)
    .flat()
    .filter((x) => x.displayOnSummary);

  return (
    (!dataIsLoading.value && (
      <>
        <Box
          sx={{
            width: '260px',
            marginTop: '16px',
            background: theme.tokens.generic.color['light-gray'][100][100],
            '& img': {
              width: '260px',
              height: '130px',
              objectFit: 'cover',
            },
          }}
        >
          <img src={imgObject} alt={model?.name} title={model?.name} />
          <Box
            sx={{
              background: theme.tokens.generic.color.charcoal[600][15],
              height: '50px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              paddingLeft: spacings.S,
            }}
          >
            <Typography
              sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
              variant={typographyVariants.BODY_BOLD}
            >
              {model?.name}
            </Typography>
            <Typography variant={typographyVariants.CAPTION_MEDIUM}>
              {(model?.createdAt && new Date(model?.createdAt).toLocaleDateString()) || ''}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: '260px',
            display: 'flex',
            flexDirection: 'column',
            paddingTop: spacings.S,
          }}
        >
          {summaryFields.map((field, idx) => <SummaryField key={idx} field={field} />)}
        </Box>
      </>
    )) || <></>
  );
};

const SummaryField: React.FC<{ field: ReportDataField<keyof AiaDdxImportProjectDataRequest> }> = ({ field }) => {
  const { data: dataPointValue } = useDataPointValue(field.dataCalculation.dataPointId.value);

  return  <>
            <Typography
              sx={{ paddingTop: '8px' }}
              variant={typographyVariants.CAPTION_REGULAR}
            >
              {field.name}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
              }}
            >  
              <Typography
                sx={{ paddingTop: spacings.XS, fontSize: 16 }}
                variant={typographyVariants.BODY_BOLD}
              >
                {field.data}
              </Typography>
              <Typography
                sx={{ paddingTop: spacings.XS, fontSize: 16 }}
                variant={typographyVariants.BODY_REGULAR}
              >
                {dataPointValue?.unitSymbol ?? ''}
              </Typography>
            </Box>
          </>;
};
