import React, {useCallback} from "react";
import {TooltipActions, tooltipReducer} from "./topLevelTooltipReducer";

export interface ITopLevelTooltipState {
    coordinates: {
        anchorPoint: string,
        containerPosition: {
            top: number,
            left: number
        },
        pointerPosition: {
            top: number,
            left: number
        }
        elementHeight: number,
    },
    header: string,
    content: string
}

interface ITopLevelTooltipManipulator {
    showTooltip: (state: ITopLevelTooltipState) => void
    hideTooltip: () => void
}

const initialTooltipState: ITopLevelTooltipState = {
    coordinates: {
        anchorPoint: "top-left",
        containerPosition: {
            top: 0,
            left: 0
        },
        elementHeight: 0,
        pointerPosition: {
            top: 0,
            left: 0
        }
    },
    header: "",
    content: "",
}
const initialTooltipManipulatorState: ITopLevelTooltipManipulator = {
    showTooltip: () => {
    },
    hideTooltip: () => {
    }
}

const TopLevelTooltipStateContext = React.createContext<ITopLevelTooltipState>(initialTooltipState)
const TopLevelTooltipManipulatorContext = React.createContext<ITopLevelTooltipManipulator>(initialTooltipManipulatorState)

export function useTopLevelTooltipState() {
    const context = React.useContext<ITopLevelTooltipState>(TopLevelTooltipStateContext);
    if (context === undefined) {
        throw new Error("Cannot useTooltipState without TooltipProvider");
    }

    const {header, content, coordinates} = context;

    return {
        header,
        content,
        coordinates
    };
}

export function useTopLevelTooltipManipulator() {
    const context = React.useContext<ITopLevelTooltipManipulator>(TopLevelTooltipManipulatorContext);
    if (context === undefined) {
        throw new Error("Cannot useTooltipState without TooltipProvider");
    }

    const {
        showTooltip,
        hideTooltip
    } = context;

    return {showTooltip, hideTooltip};
}


export const TopLevelTooltipProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(tooltipReducer, initialTooltipState)

    const showTooltip = useCallback(({coordinates, content, header}: ITopLevelTooltipState) => {
        dispatch({
            type: TooltipActions.SHOW,
            payload: {
                coordinates,
                content,
                header
            }
        })
    }, [])

    const hideTooltip = useCallback(() => {
        dispatch({
            type: TooltipActions.HIDE,
        })
    }, [])

    const {
        coordinates,
        content,
        header
    } = state;

    return (
        <TopLevelTooltipStateContext.Provider value={{header, content, coordinates}}>
            <TopLevelTooltipManipulatorContext.Provider value={{showTooltip, hideTooltip}}>
                {children}
            </TopLevelTooltipManipulatorContext.Provider>
        </TopLevelTooltipStateContext.Provider>
    )
}
