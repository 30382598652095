import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
  FetchBaseQueryMeta,
  BaseQueryApi,
  retry,
} from '@reduxjs/toolkit/dist/query';
import { User } from '../../analysis/dashboard.models';
import authClient from '../../authClient';
import { RootState } from '../store';
import { RetryOptions } from '@reduxjs/toolkit/dist/query/retry';
import {
  BaseQueryArg,
  BaseQueryExtraOptions,
  QueryReturnValue,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { AppErrorTypes } from '../../types/errorData';
import {DataPoints} from "../../types/metrics";

export enum QueryType {
  AIADDX,
  Apigee,
  DataService,
  TaskService,
  Forge,
  UserData
}

export const BaseQuery = (type: QueryType) => {
  return generateBaseQueryFn(type);
};

const addOauthAuthorizationHeader = async (headers: Headers) => {
  headers.set('Authorization', `Bearer ${await authClient.getAccessToken()}`);
  return headers;
};

function generateBaseQueryFn(
  queryType: QueryType
): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta> {
  return retry(async (args, api, extraOptions) => {
    const root = api.getState() as RootState;
    const result = await fetchBaseQuery({
      baseUrl: getBaseUrl(queryType, root),
      prepareHeaders: queryType !== QueryType.Forge ? addOauthAuthorizationHeader : undefined,
    })(args, api, extraOptions);
    result.meta = await handleQueryExtraOptions(
      result.meta,
      extraOptions,
      result,
      api.getState as () => RootState
    );
    return result;
  }, retryOptions as any);
}

function getBaseUrl(queryType: QueryType, root: RootState): string {
  switch (queryType) {
    case QueryType.AIADDX:
      return 'https://2030ddx.aia.org​​​​​​​/';
    case QueryType.Apigee:
      return `${root.userState.user.apiApigeePath}/api`;
    case QueryType.TaskService:
      return `${root.userState.user.insightTaskServiceUrl}/api`;
    case QueryType.DataService:
      return `${root.userState.user.insightDataServiceUrl}/api`;
    case QueryType.Forge:
      return `${root.userState.user.forgeBaseUrl}`;
    case QueryType.UserData:
      return '/auth';
    default:
      return assertQueryType(queryType); // if type error here, add case for missing QueryType
  }
}

/**
 * Function that tells the compiler if switch case in getBaseUrl is exhaustive
 * of all types in QueryType. Unreachable during runtime.
 */
function assertQueryType(queryType: never): never {
  throw new Error("Add Url for QueryType");
}

export type QueryOptions = {
  errorOptions?: {
    notificationType: AppErrorTypes;
    messageOverride?: string;
  };
  analyticsOptions?: {
    eventType: string;
    eventName: string;
    recordFailureOperation?: boolean;
    parseResponseMeta?: <T>(responseData: T, params?: DataPoints) => Record<string, string>;
    parseRequestMeta?: <T>(requestData: T) => Record<string, string>;
    meta?: Record<string, string>;
  };
};

export const hasQueryOptions = (options: any): QueryOptions | null => {
  return (options as QueryOptions)?.errorOptions?.notificationType ? options : null;
};

export const hasAnalyticsQueryOptions = (options: any): QueryOptions | null => {
  return (options as QueryOptions)?.analyticsOptions?.eventType ? options : null;
};

export const handleQueryExtraOptions = async (
  meta: FetchBaseQueryMeta,
  extraOptions: QueryOptions,
  queryReturnValue: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>,
  getState: () => RootState,
): Promise<FetchBaseQueryMeta & QueryOptions> => {
  if (hasAnalyticsQueryOptions(extraOptions)) {
    const currentModelId = getState().applicationDataState?.currentModelId;
    extraOptions.analyticsOptions.meta = (currentModelId && { currentModelId }) || {};
    // Parse meta using the API response
    if (extraOptions.analyticsOptions?.parseResponseMeta && queryReturnValue.data) {
      extraOptions.analyticsOptions.meta = {
        ...extraOptions.analyticsOptions.parseResponseMeta(queryReturnValue.data),
        ...extraOptions.analyticsOptions.meta,
      };
    }

    // Parse meta using the API request
    if (extraOptions.analyticsOptions?.parseRequestMeta) {
      const requestData = await queryReturnValue.meta.request.json();
      extraOptions.analyticsOptions.meta = {
        ...extraOptions.analyticsOptions.parseRequestMeta(requestData),
        ...extraOptions.analyticsOptions.meta,
      };
    }
  }
  return { ...meta, ...(extraOptions ?? {}) };
};

// RTK retry options
const MAX_RETRIES = 3;
const RETRY_CODES = [429, 500, 502, 503, 504];
const retryConditionFunction = (
  error: FetchBaseQueryError,
  args: BaseQueryArg<BaseQueryFn>,
  extraArgs: {
    attempt: number;
    baseQueryApi: BaseQueryApi;
    extraOptions: BaseQueryExtraOptions<BaseQueryFn> & RetryOptions;
  },
): boolean => {
  return (
    typeof error.status === 'number' &&
    RETRY_CODES.includes(error.status as number) &&
    extraArgs.attempt <= MAX_RETRIES
  );
};
const retryOptions = { maxRetries: MAX_RETRIES, retryCondition: retryConditionFunction };

export const extractUserState = (api: BaseQueryApi): User =>
  (api.getState() as RootState).userState?.user;
