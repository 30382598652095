import Button, { buttonVariants } from '@weave-mui/button';
import React, { Dispatch, SetStateAction, useCallback } from 'react'
import i18n from '../../../../i18n';
import { Benchmark, DataPointType, Factor, Metric } from '../../../../types/metrics';
import { newDataPointId } from '../../../utils/dataPointsUtils';
import Box from '@weave-mui/box';
import { footerButtonStyle } from '../../../../layout/LayoutGrid.stylesheet';

interface ActionButtonsProps {
  isReadOnly: boolean;
  isSaveButtonEnabled: boolean;
  selectedDataPoint: Metric | Factor | Benchmark;
  onCancel: () => void;
  onCloseModal: () => void;
  onSaveDataPoint: () => void;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  setIsReadOnly: Dispatch<SetStateAction<boolean>>;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  isReadOnly,
  onCancel,
  onSaveDataPoint,
  setIsReadOnly,
  isSaveButtonEnabled,
  setIsDirty,
  selectedDataPoint,
  onCloseModal
}) => {
  const saveClickHandler = useCallback(() => onSaveDataPoint(), [onSaveDataPoint]);

  const closeHandler = useCallback(() => onCloseModal(), [onCloseModal]);

  const cancelClickHandler = useCallback(() => {
    onCancel();
    setIsReadOnly(true);
    setIsDirty(false);
  }, [onCancel, setIsReadOnly, setIsDirty]);

  if (selectedDataPoint?.id === newDataPointId) {
    return (
      <Button
        sx={{...footerButtonStyle}}
        className='noMargin'
        onClick={saveClickHandler}
        disabled={!isSaveButtonEnabled}
        type='submit'
        variant={buttonVariants.CONTAINED}
      >
        {i18n.t('analysis.dataPoints.button.add')}
      </Button>
    );
  }

  if ( isReadOnly ||
        (selectedDataPoint?.type === DataPointType.Metric
          && (selectedDataPoint as Metric | Factor)?.isGlobal
        )
    ) {
    return (
      <Button
        sx={{...footerButtonStyle}}
        className='noMargin'
        onClick={closeHandler}
        type='button'
        variant={buttonVariants.CONTAINED}
      >
        {i18n.t('analysis.dataPoints.button.close')}
      </Button>
    )
  } else {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '0.75rem'
      }}>
        <Button
          sx={{...footerButtonStyle}}
          className='noMargin'
          onClick={cancelClickHandler}
          type="button"
          variant={buttonVariants.OUTLINED}
        >
          {i18n.t('analysis.dataPoints.button.cancel')}
        </Button>
        <Button
          sx={{...footerButtonStyle}}
          className='noMargin'
          onClick={saveClickHandler}
          type="submit"
          variant={buttonVariants.CONTAINED}
          disabled={!isSaveButtonEnabled}
        >
          {i18n.t('analysis.dataPoints.button.save')}
        </Button>
      </Box>
    );
  }
}

export default ActionButtons
