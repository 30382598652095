import {
  ChartOptions,
  ChartType,
  EcChartType,
  PieChartItem,
  PieChartOption,
  StackedColumnChartItem,
  StackedColumnChartOption,
  VariwideChartOption,
} from '../../../../charts/types';
import i18n from '../../../../i18n';
import { CategoryTypeValues, FilteredConstructions } from '../../types';
import * as locConsts from '../../../../shared/localization/consts';
import * as conversions from '../../../../conversions';
import UnitEnum from '../../../../shared/UnitConversion/UnitEnums';
import { getConvertedUnitValue, padZero } from '../../../../shared/UnitConversion/UnitConversion';
import { OPENING_CONSTRUCTION_ID_PREFIX, OpeningType, SurfaceType } from '../../../../consts';
import { ModelEmbodiedCarbonBreakdownEntity } from '../../../dashboard.models';
import { isAnalyzedOpening } from '../../utils';
import { getCategoryForConstructionSurfaceType } from '../../Lmv/LmvSection';

export const EcDropdownOptions: string[] = [
  EcChartType.EcByCategory,
  EcChartType.EcByConstruction,
  EcChartType.EcByMaterial,
  EcChartType.EcIntensityByArea,
];

export type ChartData = {
  chartTitle: string;
  chartTitleUnits: string;
  chartKey: string;
  chartLegenedTitle: string;
  chartOption: ChartOptions;
  chartWidth?: number;
  yAxisMaxValue?: number;
};

export const getEcByConstructionChartData = (
  modelId: string,
  filteredConstructions: FilteredConstructions[],
  materialColor: Map<string, string>,
  chartWidth: number
): ChartData => {
  const categories = filteredConstructions.map((x) => x.constructionName);

  const totalEC = filteredConstructions.reduce(
    (prev, curr) =>
      prev +
      curr.materials.reduce(
        (prevMat, currMat) => prevMat + (currMat.ecDefinitionAssignment?.embodiedCarbon || 0),
        0
      ),
    0
  );

  const chartOption: StackedColumnChartOption = {
    type: ChartType.STACKED_COLUMN,
    yTitle: i18n.t('analysis.ec.charts.ecByConstruction.yTitle'),
    yShortTitle: i18n.t('analysis.ec.charts.ecByConstruction.yShortTitle'),
    categories: categories,
    tooltip: {
      formatter: function () {
        let result =
          '<div style="max-width:240px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;font-family:ArtifaktElement">';
        //@ts-ignore
        if (this.forTotal) {
          result += `<span style="font-weight:bold;font-size:12px">${this.x}</span><br/>`;
          if (this.total === undefined) {
            result += `<span style="font-size:12px">${i18n.t(
              locConsts.MISSING_EC_COEFFICIENT
            )}</span>`;
          } else {
            result += `<span style="font-size:20px">${
              conversions.round(this.total, 2)
            }</span><span style="font-size:12px"> (${i18n.t(
              'analysis.ec.charts.ecByConstruction.units'
            )})</span><span style="font-size:20px">, ${padZero(
              conversions.round((this.total / totalEC) * 100, 2),
              2
            )}%</span>`;
          }
        } else {
          result += `<span style="font-weight:bold;font-size:12px">${
            this.series.name
          }</span><br/><span style="font-size:20px">${
            conversions.round(this.y, 2)
          }</span><span style="font-size:12px"> (${i18n.t(
            'analysis.ec.charts.ecByConstruction.units'
          )})</span><span style="font-size:20px">, ${padZero(
            conversions.round((this.y / totalEC) * 100, 2),
            2
          )}%</span>`;
        }
        return result + '</div>';
      },
      useHTML: true,
    },
    data: [],
  };

  filteredConstructions.forEach((construction, index) => {
    construction.materials.forEach((material) => {
      const materialIndex = chartOption.data.map((mat) => mat.name).indexOf(material.name);
      const ec = material.ecDefinitionAssignment?.embodiedCarbon
        ? conversions.round(material.ecDefinitionAssignment?.embodiedCarbon, 2)
        : null;
      if (materialIndex === -1) {
        let item: StackedColumnChartItem = {
          name: material.name,
          color: materialColor.get(material.name),
          data: new Array(chartOption.categories.length).fill(null),
          materialId: material.id,
          sha: material.sha,
          constructionIds: []
        };
        item.data[index] = ec;
        item.constructionIds.push(construction.constructionId);
        chartOption.data.push(item);
      } else {
        const currentValue = chartOption.data[materialIndex].data[index];
        chartOption.data[materialIndex].constructionIds.push(construction.constructionId);
        if (currentValue === null) {
          chartOption.data[materialIndex].data[index] = ec;
        } else {
          chartOption.data[materialIndex].data[index] = conversions.round(ec + currentValue, 2);
        }
      }
    });
  });

  chartOption.data.sort((a, b) => (a.name > b.name ? 1 : -1));

  const chartData: ChartData = {
    chartTitle: i18n.t('analysis.ec.charts.ecByConstruction.title'),
    chartTitleUnits: null,
    chartKey: `${EcChartType.EcByConstruction}-${modelId}`,
    chartOption: chartOption,
    chartLegenedTitle: i18n.t('analysis.ec.charts.legend.materialTitle'),
    chartWidth,
  };

  return chartData;
};

export const getCategoryBySurfaceType = (surfaceType: string, surfaceId: string): string => {
  const isExterior = surfaceId.startsWith(OPENING_CONSTRUCTION_ID_PREFIX + 'exterior');
  switch (surfaceType) {
    case SurfaceType.ExteriorWall:
      return CategoryTypeValues.exteriorWall;
    case SurfaceType.UndergroundSlab:
      return CategoryTypeValues.undergroundSlabs;
    case SurfaceType.SlabOnGrade:
      return CategoryTypeValues.slabsOnGrade;
    case SurfaceType.RaisedFloor:
      return CategoryTypeValues.raisedFloors;
    case SurfaceType.UndergroundWall:
      return CategoryTypeValues.undergroundWalls;
    case SurfaceType.InteriorWall:
      return CategoryTypeValues.interiorWalls;
    case SurfaceType.InteriorFloor:
      return CategoryTypeValues.interiorFloors;
    case SurfaceType.Ceiling:
      return CategoryTypeValues.ceilings;
    case SurfaceType.UndergroundCeiling:
      return CategoryTypeValues.undergroundCeilings;
    case SurfaceType.Roof:
      return CategoryTypeValues.roofs;
    case SurfaceType.Shade:
      return CategoryTypeValues.shades;
    case OpeningType.FixedSkylight:
    case OpeningType.OperableSkylight:
      return CategoryTypeValues.exteriorOpenings;
    case OpeningType.SlidingDoor:
    case OpeningType.NonSlidingDoor:
    case OpeningType.FixedWindow:
    case OpeningType.OperableWindow:
      return isExterior ? CategoryTypeValues.exteriorOpenings : CategoryTypeValues.interiorOpenings;
    default:
      return null;
  }
};

export const getEcByCategoryChartData = (
  modelId: string,
  filteredConstructions: FilteredConstructions[],
  ecModelBreakdown: ModelEmbodiedCarbonBreakdownEntity,
  categoryColor: Map<string, string>
) => {
  const chartOption: PieChartOption = {
    type: ChartType.PIE,
    tooltip: {
      formatter: function () {
        let result =
          '<div style="max-width:240px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;font-family:ArtifaktElement">';
        result += `<span style="font-weight:bold;font-size:12px">${
          this.point.name
        }</span><br/><span style="font-size:15px">${
          conversions.round(this.y,2)
        }</span><span style="font-size:12px"> (${i18n.t(
          'analysis.ec.charts.ecByMaterial.units'
        )})</span><span style="font-size:15px"> <br> ${padZero(
          conversions.round(this.percentage, 2),
          2
        )}%</span>`;
        return result + '</div>';
      },
      useHTML: true,
    },
    data: [],
  };

  let constructionChartItems: PieChartItem[] = [];

  Object.keys(ecModelBreakdown.constructionItems).forEach((key) => {
    const constructions = ecModelBreakdown.constructionItems[key];
    constructions.forEach((construction) => {
      const category = getCategoryBySurfaceType(
        construction.surfaceType,
        construction.constructionId
      );
      if (category === null){
        return;
      }

      const existingCategoryIndex = constructionChartItems
        .map((data) => data.name)
        .indexOf(category);

      let initialConstructionEC = 0;
      const constructionEmbodiedCarbon = construction.materials
        .map((mat) =>
          mat.ecDefinitionAssignment?.embodiedCarbon
            ? conversions.round(mat.ecDefinitionAssignment?.embodiedCarbon, 2)
            : 0
        )
        .reduce((acc, c) => acc + c, initialConstructionEC);

      if (existingCategoryIndex === -1) {
        let newCategoryItem: PieChartItem = {
          name: category,
          color: categoryColor.get(category),
          y: constructionEmbodiedCarbon,
          constructionIds: [],
        };

        newCategoryItem.constructionIds.push(construction.constructionId);
        constructionChartItems.push(newCategoryItem);
      } else {
        const currentConstructionEc = constructionChartItems[existingCategoryIndex].y;
        constructionChartItems[existingCategoryIndex].constructionIds.push(
          construction.constructionId
        );
        if (currentConstructionEc === null) {
          constructionChartItems[existingCategoryIndex].y = constructionEmbodiedCarbon;
        } else {
          constructionChartItems[existingCategoryIndex].y = conversions.round(
            constructionEmbodiedCarbon + currentConstructionEc,
            2
          );
        }
      }
    });
  });

  let openingChartItems: PieChartItem[] = [];
  filteredConstructions
    .filter((construction) => isAnalyzedOpening(construction.surfaceType))
    .forEach((construction) => {
      const openingCategory = getCategoryForConstructionSurfaceType(
        construction.surfaceType,
        construction.constructionId
      );
      const existingCategoryIndex = openingChartItems
        .map((opening) => opening.name)
        .indexOf(openingCategory);

      let initialOpeningEc = 0;
      const openingEmbodiedCarbon = construction.materials
        .map((mat) =>
          mat.ecDefinitionAssignment?.embodiedCarbon
            ? conversions.round(mat.ecDefinitionAssignment?.embodiedCarbon, 2)
            : 0
        )
        .reduce((acc, c) => acc + c, initialOpeningEc);

      if (existingCategoryIndex === -1) {
        let newCategoryItem: PieChartItem = {
          name: openingCategory,
          color: categoryColor.get(openingCategory),
          y: openingEmbodiedCarbon,
          constructionIds: [],
        };

        newCategoryItem.constructionIds.push(construction.constructionId);
        openingChartItems.push(newCategoryItem);
      } else {
        const currentOpeningEc = openingChartItems[existingCategoryIndex].y;
        openingChartItems[existingCategoryIndex].constructionIds.push(
          construction.constructionId
        );
        if (currentOpeningEc === null) {
          openingChartItems[existingCategoryIndex].y = openingEmbodiedCarbon;
        } else {
          openingChartItems[existingCategoryIndex].y = conversions.round(
            openingEmbodiedCarbon + currentOpeningEc,
            2
          );
        }
      }
    });

  chartOption.data = [...constructionChartItems, ...openingChartItems];

  chartOption.data.sort((a, b) => (a.name > b.name ? 1 : -1));

  const chartData: ChartData = {
    chartTitle: i18n.t('analysis.ec.charts.ecByCategory.title'),
    chartTitleUnits: `(${i18n.t('analysis.ec.charts.ecByCategory.units')})`,
    chartKey: `${EcChartType.EcByCategory}-${modelId}`,
    chartOption: chartOption,
    chartLegenedTitle: i18n.t('analysis.ec.charts.legend.categoryTitle'),
  };

  return chartData;
};

export const getEcByMaterialChartData = (
  modelId: string,
  filteredConstructions: FilteredConstructions[],
  materialColor: Map<string, string>
): ChartData => {
  const chartOption: PieChartOption = {
    type: ChartType.PIE,
    tooltip: {
      formatter: function () {
        let result =
          '<div style="max-width:240px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;font-family:ArtifaktElement">';
        result += `<span style="font-weight:bold;font-size:12px">${
          this.point.name
        }</span><br/><span style="font-size:20px">${
          this.y
        }</span><span style="font-size:12px"> (${i18n.t(
          'analysis.ec.charts.ecByMaterial.units'
        )})</span><span style="font-size:20px">, ${padZero(
          conversions.round(this.percentage, 2),
          2
        )}%</span>`;
        return result + '</div>';
      },
      useHTML: true,
    },
    data: [],
  };

  filteredConstructions.forEach((construction, index) => {
    construction.materials.forEach((material) => {
      const materialIndex = chartOption.data.map((mat) => mat.name).indexOf(material.name);
      const ec =
        material.ecDefinitionAssignment?.embodiedCarbon &&
        conversions.round(material.ecDefinitionAssignment?.embodiedCarbon, 2);
      if (materialIndex === -1) {
        let item: PieChartItem = {
          name: material.name,
          color: materialColor.get(material.name),
          y: ec,
          constructionIds: [],
        };
        item.constructionIds.push(construction.constructionId);
        chartOption.data.push(item);
      } else {
        const currentValue = chartOption.data[materialIndex].y;
        chartOption.data[materialIndex].constructionIds.push(construction.constructionId);
        if (currentValue === null) {
          chartOption.data[materialIndex].y = ec;
        } else {
          chartOption.data[materialIndex].y = conversions.round(ec + currentValue, 2);
        }
      }
    });
  });
  chartOption.data.sort((a, b) => (a.name > b.name ? 1 : -1));

  const chartData: ChartData = {
    chartTitle: i18n.t('analysis.ec.charts.ecByMaterial.title'),
    chartTitleUnits: ' (' + i18n.t('analysis.ec.charts.ecByMaterial.units') + ')',
    chartKey: `${EcChartType.EcByMaterial}-${modelId}`,
    chartOption: chartOption,
    chartLegenedTitle: i18n.t('analysis.ec.charts.legend.materialTitle'),
  };
  return chartData;
};

export const getEcIntensityByAreaChartData = (
  modelId: string,
  filteredConstructions: FilteredConstructions[],
  constructionColorData: Map<string, string>,
  useSI: boolean
): ChartData => {
  const constructionsWithEc = filteredConstructions;

  const areaUnit = `(${conversions.GetSymbol(useSI ? UnitEnum.MeterSq : UnitEnum.FeetSq)})`;
  const ecIntensityUnit =
    '(' +
    i18n.t('analysis.ec.charts.ecIntensityByArea.ecIntensityUnit.' + (useSI ? 'si' : 'ip')) +
    ')';
  const ecUnit = '(' + i18n.t('analysis.ec.charts.ecIntensityByArea.ecUnit') + ')';

  const chartOption: VariwideChartOption = {
    type: ChartType.VARIWIDE,
    xTitle: i18n.t('analysis.ec.charts.ecIntensityByArea.xTitle') + areaUnit,
    yTitle: i18n.t('analysis.ec.charts.ecIntensityByArea.yTitle') + ecIntensityUnit,
    yShortTitle: i18n.t('analysis.ec.charts.ecIntensityByArea.yShortTitle') + ecIntensityUnit,
    tooltip: {
      formatter: function () {
        let result =
          '<div style="max-width:240px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;font-family:ArtifaktElement">';
        result += `<span style="font-weight:bold;font-size:12px">${this.point.name}</span><br/><span style="font-size:20px">${this.y}</span><span style="font-size:12px"> ${ecIntensityUnit}</span>`;
        result += '<hr style="margin:2px 0px;background-color:#f5f5f5"/>';
        //@ts-ignore
        result += `<span style="font-size:12px">EC: ${this.point.data.embodiedCarbon}${ecUnit}</span><br/><span style="font-size:12px">Area: ${this.point.data.actualArea}${areaUnit}</span>`;
        return result + '</div>';
      },
      useHTML: true,
    },
    categories: constructionsWithEc.map((construction) => construction.constructionName),
    data: [],
  };

  //get total area of the model to be used for sizing the z-axis
  let totalArea = 0;
  constructionsWithEc.forEach((construction) => {
    totalArea += getConvertedUnitValue({
      value: construction.area,
      sourceUnit: UnitEnum.MeterSq,
      targetUnit: useSI ? UnitEnum.MeterSq : UnitEnum.FeetSq,
    });
  });

  constructionsWithEc.forEach((construction, index) => {
    const ecIntensity = getConvertedUnitValue({
      value: construction.ecIntensity,
      sourceUnit: UnitEnum.KgCO2ePerM2,
      targetUnit: useSI ? UnitEnum.KgCO2ePerM2 : UnitEnum.KgCO2ePerFt2,
    });
    const area = getConvertedUnitValue({
      value: construction.area,
      sourceUnit: UnitEnum.MeterSq,
      targetUnit: useSI ? UnitEnum.MeterSq : UnitEnum.FeetSq,
    });
    const zValue = (area / totalArea) * 100; //get percentage of the construction area to use as the representation in the chart instead of the actual area.
    const embodiedCarbon = getConvertedUnitValue({
      value: construction.totalEmbodiedCarbon,
      sourceUnit: UnitEnum.KgCO2e,
      targetUnit: UnitEnum.KgCO2e,
    });
    chartOption.data.push({
      name: construction.constructionName.includes(i18n.t(`analysis.ec.construction.openingSkylight`)) ? i18n.t(`analysis.ec.construction.openingSkylight`) : construction.constructionName,
      y: ecIntensity,
      z: zValue < 1.0 ? 1.0 : zValue, // set the minimum to 1.0 if value is less than 1.0 so it is will show in the chart.
      color: constructionColorData.get(construction.constructionName),
      data: {
        embodiedCarbon: embodiedCarbon,
        actualArea: area,
      },
      constructionIds: [construction.constructionId],
    });
  });

  const chartData: ChartData = {
    chartTitle: i18n.t('analysis.ec.charts.ecIntensityByArea.title'),
    chartTitleUnits: null,
    chartKey: `${EcChartType.EcIntensityByArea}-${modelId}`,
    chartOption: chartOption,
    chartLegenedTitle: i18n.t('analysis.ec.charts.legend.constructionTitle'),
  };
  return chartData;
};

export const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength - 3) + '...';
  } else {
    return text;
  }
}
