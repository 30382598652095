import { v4 as uuidv4 } from "uuid";

/**
 * Log errors to New Relic
 * @class ErrorLogger
 */
class ErrorLogger {
  private _logErrorsToNewRelic = false;

  private _errorIdLabel = "insightsErrorId";

  constructor() {
    this.setup();
  }

  private setup = (): void => {
    this._logErrorsToNewRelic = !!window.newrelic;
  };

  /**
   * Log errors to New Relic when available or to the console in local environments
   * @param {Error} error
   * @memberof ErrorLogger
   */
  public logError = (error: Error): void => {
    if (this._logErrorsToNewRelic) {
      const errorId = uuidv4();
      newrelic.noticeError(error, { [this._errorIdLabel]: errorId });
      console.error(`An unhandled exception has been logged, ${this._errorIdLabel} = ${errorId}`);
    } else {
      console.error("A local unhandled exception was thrown", error);
    }
  };
}

const errorLogger = new ErrorLogger();
export default errorLogger;
