import React from 'react';
import isequal from 'lodash.isequal';
import { OPENING_CONSTRUCTION_ID_PREFIX } from '../../../consts';
import UnitEnum from '../../../shared/UnitConversion/UnitEnums';
import { UnitConversion } from '../../../shared/UnitConversion/UnitConversion';
import i18next from 'i18next';

type DataType = {
  row: {
    constructionId: string;
    depth: number;
    rValue: number;
  };
};

const ThermalPerfValue = React.memo(
  ({ fromUnit, useSI, data }: { fromUnit: UnitEnum; useSI: boolean; data: DataType }) => {
    const isChildRow: boolean = Boolean(data.row?.depth);
    const isOpening: boolean = data.row?.constructionId?.startsWith(OPENING_CONSTRUCTION_ID_PREFIX);
    const toUnit = useSI ? UnitEnum.M2KPerW : UnitEnum.HrFt2PerBtu;
    const rPrefix = useSI ? i18next.t('prefix.rsi') : i18next.t('prefix.r');
    return Number(data.row.rValue) ? (
      <>
        {isChildRow && !isOpening ? <>{rPrefix} </> : <>{i18next.t('prefix.u')} </>}
        <UnitConversion
          value={data.row.rValue}
          sourceUnit={fromUnit}
          targetUnit={toUnit}
          round={null}
        />
      </>
    ) : (
      <></>
    );
  },
  (prevProps, nextProps) => isequal(prevProps.data, nextProps.data)
);

export default ThermalPerfValue;
