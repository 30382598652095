import { useSelector } from 'react-redux';
import { useGetModelQuery } from '../../state/api/project-model-data-api';
import { ApplicationDataState } from '../../state/slice/application-data-slice';
import { LayoutHookReturnType } from '../../types/layout';
import { useEffect, useState } from 'react';
import { Status } from '../../types/state';
import { ModelViewData } from '../../types/Lmv';

export const useCurrentModelViewData = (): LayoutHookReturnType<ModelViewData> => {
  const [forceRefetch, setForceRefetch] = useState(false);

  const modelId = useSelector(
    (state: { applicationDataState: ApplicationDataState }) =>
      state.applicationDataState.currentModelId
  );

  const {
    data: modelData,
    isLoading: modelDataIsLoading,
    isSuccess: modelDataIsSuccess,
    isError,
    refetch,
  } = useGetModelQuery(modelId, { skip: !modelId });

  useEffect(() => {
    if (
      modelData &&
      modelData?.lmvStatus !== Status?.Completed &&
      modelData?.lmvStatus !== Status.Error
    ) {
      const refetchWithDelay = setTimeout(() => {
        setForceRefetch(!forceRefetch);
        refetch();
      }, 20000);
      return () => clearTimeout(refetchWithDelay);
    }
  }, [modelData, forceRefetch, refetch]);

  const isProcessing =
    (modelData?.lmvStatus !== Status?.Completed && modelData?.lmvStatus !== Status.Error) ||
    modelData?.id !== modelId;

  return {
    data: { urn: isProcessing ? null : modelData?.urn, modelName: modelData?.name },
    isLoading: isProcessing ||  modelDataIsLoading,
    isError,
    isSuccess: !isProcessing && modelDataIsSuccess,
  };
};
