import { v4 as uuidv4 } from 'uuid';
import { Dashboard } from "../types/layout";
import {DataPoint, Factor, Metric} from "../types/metrics";

export const createNewDashboard = ({
  name,
  dashboardId = uuidv4()
}): Dashboard => ({
  description: '',
  cards: [],
  name,
  dashboardId,
});

export const getNewDashboardName = (dashboardNames: string[]) => {
  const reg = new RegExp('New Dashboard (\\d+)');
  const currentCopies = dashboardNames.filter((dashboardName) => reg.test(dashboardName)).length;
  return 'New Dashboard ' + (currentCopies + 1);
};

export const getDuplicatedName = (name: string, dashboardNames: string[]) => {
  const reg = new RegExp(escapeRegExp(name) + ' Copy (\\d+)');
  const currentCopies = dashboardNames.filter((dashboardName) => reg.test(dashboardName)).length;
  return name + ' Copy ' + (currentCopies + 1);
};

export const getExistingName = (name: string, dashboardNames: string[]) => {
  const count = dashboardNames.filter((tabName) => tabName === name ).length;
  return `${name}${count > 0 ? ` ${count}` : ''}`;
};

export const isLongerThanAllowedText = (field: string, maximumCharacters: number): boolean => {
  return field?.length > maximumCharacters;
};

export const isDataPointGlobal = (dataPointId:string, dataPoints: any[]) => {
  const dataPoint = dataPoints.find(d => d.id === dataPointId)
  if (dataPoint) {
    return dataPoint.isGlobal;
  }
}

export const isDuplicateName = (name: string, dashboardNames: string[], original?: string | null): boolean =>
  dashboardNames.some((tab) => tab === name) && name !== original;

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
