import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getViewerToken } from './actions';
import { ViewerToken } from './api';
var viewer;

export type LmvProps = {
    lmvStatus: string,
    urn: string,
    getViewerToken: () => Promise<ViewerToken>,
    store?: any
}

export class Lmv extends Component<LmvProps> {

    componentDidMount() {
        this.startViewerProcess();
    }

    componentDidUpdate(prevProps: LmvProps) {
        if (prevProps.lmvStatus !== this.props.lmvStatus ||
            prevProps.urn !== this.props.urn) {
            this.startViewerProcess();
        }
    }

    startViewerProcess = () => {
        if (this.props.lmvStatus === 'Completed') {
            this.props.getViewerToken()
                .then((response: ViewerToken) => {
                    const { accessToken, expiresIn, viewerEnvironment } = response;
                    const urn = this.props.urn;

                    this.launchViewer(accessToken, expiresIn, viewerEnvironment, urn);
                });
        }
    }

    launchViewer = (accessToken: string, expiresIn: number, viewerEnvironment: string, urn: string) => {
        var documentLoadSuccess = this.onDocumentLoadSuccess;
        var documentLoadFailure = this.onDocumentLoadFailure;

        var options = {
            env: viewerEnvironment,
            api: 'derivativeV2',
            getAccessToken: function (onTokenReady) {
                onTokenReady(accessToken, expiresIn);
            }
        };

        if (urn !== null) {
            window.Autodesk.Viewing.Initializer(options, function onInitialized() {
                var htmlDiv = document.getElementById('lmv');

                if (htmlDiv) {
                    if (viewer) {
                        viewer.finish();
                    }
                    viewer = new window.Autodesk.Viewing.GuiViewer3D(htmlDiv);
                    viewer.start();

                    var documentId = 'urn:' + urn;
                    window.Autodesk.Viewing.Document.load(documentId, documentLoadSuccess, documentLoadFailure);
                }
            });
        }
    }

    onDocumentLoadSuccess = (viewerDocument: any) => {
        var defaultModel = viewerDocument.getRoot().getDefaultGeometry();
        viewer.loadDocumentNode(viewerDocument, defaultModel);
    }

    onDocumentLoadFailure = () => {
        console.error('Failed fetching Forge manifest');
    }

    render() {
        const status = this.props.lmvStatus;

        if (status === "Completed") {
            return (<div id="lmv"></div>);
        }

        return (
            <div className="overview-widget-header">
                <span>{status}...</span>
            </div>
        );
    }
}

const mapDispatchToProps = {
    getViewerToken
};

export default connect(null, mapDispatchToProps)(Lmv);
