import React from "react";
import {Text} from '@hig/tooltip';
import '../css/new/ecDefinition.css';
import {useTopLevelTooltipManipulator, useTopLevelTooltipState} from "../analysis/components/TopLevelTooltip";
export const TOOLTIP_WIDTH = '230px'

export const tooltipStyle = styles => ({
    ...styles,
    panel: {
        ...styles.panel,
        width: TOOLTIP_WIDTH
    },
});

export const textStyles = styles => ({
    ...styles,
    textContent: {
        ...styles.textContent,
        whiteSpace: 'normal',
    },
});

interface ITooltipContent {
    coordinates?: Record<string, any>
    header?: React.ReactNode
    content: React.ReactNode
}
export const TooltipContent: React.FC<ITooltipContent> = ({header, content, coordinates = {}}) => {
    const ref = React.useRef(null);
    const {showTooltip}: any = useTopLevelTooltipManipulator();
    const {elementHeight} = coordinates;

    React.useLayoutEffect( () => {
        const element = ref.current;
        const elementClient = element.getBoundingClientRect();

        const tooltipPositionY = elementClient.top + element.clientHeight
        const isTooltipOutOfPage = window.innerHeight < tooltipPositionY

        if (isTooltipOutOfPage && elementHeight) {
            showTooltip({
                content: content,
                header: header,
                coordinates: {
                    anchorPoint: "bottom-left",
                    containerPosition: {
                        top: elementClient.top - element.clientHeight - elementHeight - 10 // padding
                    },
                    pointerPosition: {
                        top: element.clientHeight
                    }
                }
            })
        }
    })

    return (
        <div ref={ref} className='ecDefinitionTooltipContent'>
            { header && <Text stylesheet={textStyles}>{header}</Text>}
            <Text stylesheet={textStyles} >{content}</Text>
        </div>
    )
}
