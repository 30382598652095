import React, { Component } from 'react';

export class Orientation extends Component {

    render() {
        return <svg
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 16 16"
            style={{
                // @ts-ignore: unrecognizable type
                enableBackground: "new 0 0 16 16"
            }}
            xmlSpace="preserve"
            className="factor-modal-icon"
        >
            <style type="text/css">
                {"\n\t.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#808080;}\n"}
            </style>
            <path className="factor-modal-icon-color"
                d="M16,7h-1.1C14.5,3.9,12.1,1.5,9,1.1V0H7v1.1C3.9,1.5,1.5,3.9,1.1,7H0v2h1.1c0.4,3.1,2.9,5.5,5.9,5.9V16h2v-1.1 c3.1-0.4,5.5-2.9,5.9-5.9H16V7z M3,8c0-1.2,0.5-2.4,1.3-3.3l2.6,4.4l4.4,2.6c-2.1,1.8-5.2,1.7-7.1-0.4C3.5,10.4,3,9.2,3,8z M8.3,7.7 l1,1.7l-1.7-1L8.3,7.7z M11.7,11.3L9.1,6.9L4.7,4.3C5.6,3.5,6.8,3,8,3c2.8,0,5,2.3,5,5C13,9.2,12.5,10.4,11.7,11.3z"
            />
        </svg>;
    }
}
