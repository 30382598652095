
import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../analysis/dashboard.models";
import { userDataApi } from "../api/user-data-api";

export const userSlice = createSlice({
  name: 'userState',
  initialState: { user: null } as { user: User },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(userDataApi.endpoints.getUserData.matchFulfilled, (state, { payload }) => {
      state.user = payload;
    });
  },
});
