import Box from '@weave-mui/box';
import React from 'react';
import { InfoTooltipIcon } from '../../../../../shared/InfoTooltipIcon';
import { InfoS } from '@weave-mui/icons-weave';
import { tooltipPlacement } from '@weave-mui/tooltip';
import i18n from '../../../../../i18n';
import Checkbox, { CheckboxProps } from '@weave-mui/checkbox';
 
interface CustomCheckboxProps {
  checkboxProps: CheckboxProps;
  disabledMetricIds: string[];
  isReadOnly: boolean;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checkboxProps, disabledMetricIds, isReadOnly }) => {
  const { id, checked } = checkboxProps;

  const shouldDisplayInfoIcon = () => {
    if (isReadOnly && disabledMetricIds.includes(id) && !checked) return true;

    if (!isReadOnly && disabledMetricIds.includes(id) && !checked) return true;

    return false;
  } 

  if (shouldDisplayInfoIcon()) {
    return (
      <Box sx={{
        '& svg': {
          margin: '0 !important'
        }
      }}>
        <InfoTooltipIcon
          icon={<InfoS sx={{ cursor: 'default' }} color="action"/>}
          tooltiPlacement={tooltipPlacement.RIGHT}
          tooltipContent={i18n.t('analysis.dataPoints.benchmarks.disabledMetricTooltipContent')}
        />
      </Box>
    )
  }

  return (
    <Checkbox
      {...checkboxProps}
    />
  );
};

export default CustomCheckbox;
