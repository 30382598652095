import * as consts from '../consts';
import i18n from '../i18n';
import * as locConsts from "./localization/consts";

const getFactorName = factor => {
    return i18n.t(`analysis.simulations.${factor}.title`);
}

export function factorChoices() {
    return [
        {
            display: true,
            key: "1",
            name: i18n.t(locConsts.OPTIONS_CHOICES_ARCHITECTURAL_TITLE),
            factors: [
                {
                    key: consts.BUILDING_ORIENTATION_FACTOR,
                    name: getFactorName(consts.BUILDING_ORIENTATION_FACTOR),
                    description: i18n.t(locConsts.OPTIONS_CHOICES_ORIENTATION_DESC),
                    background: 'building-orientation-background',
                    svgComponent: 'orientation',
                    disabled: false,//Code SMELL
                },
                {
                    key: consts.WWR_WALLS,
                    name: getFactorName(consts.WWR_WALLS),
                    description: i18n.t(locConsts.OPTIONS_CHOICES_WWR_DESC),
                    background: 'wwr-walls-background',
                    svgComponent: 'windowWallRatio',
                    disabled: false,
                },
                {
                    key: consts.WINDOW_SHADES,
                    name: getFactorName(consts.WINDOW_SHADES),
                    description: i18n.t(locConsts.OPTIONS_CHOICES_WINDOW_SHADES_DESC),
                    background: 'window-shades-background',
                    svgComponent: 'windowShades',
                    disabled: false,
                }
            ]
        },
        {
            display: true,
            key: "2",
            name: i18n.t(locConsts.OPTIONS_CHOICES_CONSTRUCTIONS_TITLE),
            factors: [
                {
                    key: consts.GLAZING_CONSTRUCTION,
                    name: getFactorName(consts.GLAZING_CONSTRUCTION),
                    description: i18n.t(locConsts.OPTIONS_CHOICES_GLAZING_CONSTRUCTION_DESC),
                    background: 'glazing-construction-background',
                    svgComponent: 'glazing',
                    disabled: false,
                },
                {
                    key: consts.WALL_CONSTRUCTION,
                    name: getFactorName(consts.WALL_CONSTRUCTION),
                    description: i18n.t(locConsts.OPTIONS_CHOICES_WALL_CONSTRUCTION_DESC),
                    background: 'wall-construction-background',
                    svgComponent: 'wallConstruction',
                    disabled: false,
                },
                {
                    key: consts.ROOF_CONSTRUCTION,
                    name: getFactorName(consts.ROOF_CONSTRUCTION),
                    description: i18n.t(locConsts.OPTIONS_CHOICES_ROOF_CONSTRUCTION_DESC),
                    background: 'roof-construction-background',
                    svgComponent: 'roofConstruction',
                    disabled: false,
                }
            ]
        },
        {
            display: true,
            key: "3",
            name: "Building Operations & Control",
            factors: [
                {
                    key: consts.INFILTRATION,
                    name: getFactorName(consts.INFILTRATION),
                    description: i18n.t(locConsts.OPTIONS_CHOICES_INFILTRATION_DESC),
                    background: 'infiltration-background',
                    svgComponent: 'infiltration',
                    disabled: false,
                }
            ]
        },
        {
            display: true,
            key: "4",
            name: i18n.t(locConsts.OPTIONS_CHOICES_ENGINEERING_TITLE),
            factors: [
                {
                    key: consts.HVAC_FACTOR,
                    name: getFactorName(consts.HVAC_FACTOR),
                    description: i18n.t(locConsts.OPTIONS_CHOICES_HVAC_DESC),
                    background: 'hvac-background',
                    svgComponent: 'hvac',
                    disabled: false,
                },
                {
                    key: consts.LIGHTING_EFFICIENCY_FACTOR,
                    name: getFactorName(consts.LIGHTING_EFFICIENCY_FACTOR),
                    description: i18n.t(locConsts.OPTIONS_CHOICES_LIGHTING_EFFICIENCY_DESC),
                    background: 'lighting-background',
                    svgComponent: 'lightingEfficiency',
                    disabled: false,
                },
                {
                    key: consts.PLUG_LOAD_EFFICIENCY_FACTOR,
                    name: getFactorName(consts.PLUG_LOAD_EFFICIENCY_FACTOR),
                    description: i18n.t(locConsts.OPTIONS_CHOICES_PLUG_LOAD_EFFICIENCY_DESC),
                    background: 'plug-loads-background',
                    svgComponent: 'plugLoadEfficiency',
                    disabled: false,
                }
            ]
        }
    ];
}

