import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import '../../../css/new/addECDefinition.css';
import {
  ButtonsComponent,
  CoefficientInput,
  LabelsInput,
  NameInput,
  NotesInput,
  SourceInput,
} from './ECDefinitionComponents';
import * as actions from '../actions';
import {
  buildApiObjectFromECDefinition,
  checkMandatoryFields,
  checkMaximumCharactersExceeded,
  areValidValues,
  unitBuilder,
  ValidationResponse,
} from './helper';
import {
  addECDefinitionActions,
  IAddECDefinition,
  IAddECDefinitionState,
  IUnit,
} from './addECDefinition.types';
import { ecDefinitionReducer, initialECDefinition } from './addECDefinition.reducer';
import { EcDefinition } from '../types';
import Link from '@weave-mui/link';
import { linkVariants } from '@weave-mui/enums';
import i18n from '../../../i18n';
import { useGetUserDataQueryState } from '../../../state/api/user-data-api';

const AddECDefinition = ({
  modelId,
  ecDefinitionLabels,
  onClose,
  selectedECDefinitionToEdit,
  selectedECDefinitionToAssign,
}: IAddECDefinition) => {
  const dispatch = useDispatch();
  const [ecDefinitionForm, dispatchForm] = useReducer(ecDefinitionReducer, initialECDefinition);
  const { name, coefficient, unit, source, notes, labels } = ecDefinitionForm;
  const [isAddButtonEnabled, setIsAddButtonEnabled] = useState<boolean>(false);
  const [maximumCharactersExceeded, setMaximumCharactersExceeded] = useState<ValidationResponse>({
    name: false,
    coefficient: false,
    source: false,
    notes: false,
  });

    const { data: user } = useGetUserDataQueryState();

  useEffect(() => {
    if (selectedECDefinitionToEdit) {
      const selectedECDefinitionPayload: IAddECDefinitionState = {
        name: selectedECDefinitionToEdit.name,
        coefficient: selectedECDefinitionToEdit.average,
        unit: unitBuilder(selectedECDefinitionToEdit.unit),
        source: selectedECDefinitionToEdit.source,
        notes: selectedECDefinitionToEdit.notes,
        labels: selectedECDefinitionToEdit.labels,
      };
      dispatchForm({
        type: addECDefinitionActions.setSelectedECDefinition,
        payload: selectedECDefinitionPayload,
      });
    } else {
      dispatchForm({ type: addECDefinitionActions.clearForm });
    }
  }, [selectedECDefinitionToEdit]);

  useEffect(() => {
    setMaximumCharactersExceeded(areValidValues(name, Number(coefficient), source, notes));
    if (
      checkMandatoryFields(name, coefficient, unit.value) &&
      !checkMaximumCharactersExceeded(name, Number(coefficient), source, notes)
    ) {
      setIsAddButtonEnabled(true);
    } else {
      setIsAddButtonEnabled(false);
    }
  }, [name, source, notes, coefficient, unit]);

  const nameChangeHandler = useCallback(
    (e) =>
      dispatchForm({
        type: addECDefinitionActions.setName,
        payload: e.target.value,
      }),
    []
  );

  const coefficientChangeHandler = useCallback((event) => {
    let value = event.target.value;
    value = value.replace(/[^0-9.\-]/g, '');

    const indexes = [...value.matchAll(/-/g)].map((a) => a.index);
    if (indexes.length > 0 && indexes[0] > 0) {
      indexes.splice(1);

      for (let i = indexes.length - 1; i >= 0; i--) {
        const index = indexes[i];
        value = value.substring(0, index) + value.substring(index + 1);
      }
    }
    if (value.length > 0 && value.charAt(0) === '-' && value.indexOf('-', 1) !== -1) {
      value =
        value.substring(0, value.indexOf('-', 1)) + value.substring(value.indexOf('-', 1) + 1);
    }
    dispatchForm({
      type: addECDefinitionActions.setCoefficient,
      payload: value,
    });
  }, []);

  const unitChangeHandler = useCallback(
    (unit: IUnit) =>
      dispatchForm({
        type: addECDefinitionActions.setUnit,
        payload: unit,
      }),
    []
  );

  const sourceChangeHandler = useCallback(
    (e) =>
      dispatchForm({
        type: addECDefinitionActions.setSource,
        payload: e.target.value,
      }),
    []
  );

  const notesChangeHandler = useCallback(
    (e) =>
      dispatchForm({
        type: addECDefinitionActions.setNotes,
        payload: e.target.value,
      }),
    []
  );

  const labelsChangeHandler = useCallback(
    (e) => {
      dispatchForm({
        type: addECDefinitionActions.setLabels,
        payload: e.target.name,
      });
    },
    [labels]
  );

  const addNewLabel = useCallback((label: string) => {
    dispatchForm({
      type: addECDefinitionActions.addLabel,
      payload: label,
    });
  }, []);

  const addHandler = useCallback(() => {
    if (name.trim().length > 0 && coefficient !== null && unit.value.trim().length > 0) {
      if (selectedECDefinitionToEdit) {
        const updatedECDefinition: EcDefinition = {
          id: selectedECDefinitionToEdit.id,
          source: source ? source : '',
          name: name,
          notes: notes ? notes : '',
          labels: labels,
          average: coefficient,
          averageUncertainty: selectedECDefinitionToEdit.averageUncertainty,
          conservativeEstimate: selectedECDefinitionToEdit.conservativeEstimate,
          unit: unit.value.substring(unit.value.indexOf('/') + 1),
          isCustom: true,
        };

        dispatch(
          actions.editECDefinition(modelId, buildApiObjectFromECDefinition(updatedECDefinition))
        );
      } else {
        const newECDefinitionObject = {
          Id: '',
          Source: source,
          Name: name,
          Average: coefficient,
          Description: notes,
          Labels: labels,
          AverageUncertainty: 0,
          ConservativeEstimate: 0,
          AchievableEstimate: 0,
          DeclaredUnit: `1 ${unit.value.substring(unit.value.indexOf('/') + 1)}`,
        };
        dispatch(actions.addECDefinition(newECDefinitionObject));
      }
      dispatchForm({ type: addECDefinitionActions.clearForm });
      onClose();
    }
  }, [
    name,
    source,
    notes,
    coefficient,
    labels,
    unit,
    selectedECDefinitionToEdit,
    selectedECDefinitionToAssign,
  ]);

  return (
    <div className={'addECDefinitionContainer'}>
      <div className="addECDefinitionPanelsContainer">
        <div className="addECDefinitionLeftPanel">
          <NameInput
            name={name}
            onChange={nameChangeHandler}
            maximumCharactersExceeded={maximumCharactersExceeded.name}
          />
          <CoefficientInput
            coefficient={coefficient}
            unit={unit}
            onCoefficientChange={coefficientChangeHandler}
            onUnitChange={unitChangeHandler}
            valueOutOfRange={maximumCharactersExceeded.coefficient}
          />
          <SourceInput
            source={source}
            onChange={sourceChangeHandler}
            maximumCharactersExceeded={maximumCharactersExceeded.source}
          />
          <NotesInput
            notes={notes}
            onChange={notesChangeHandler}
            maximumCharactersExceeded={maximumCharactersExceeded.notes}
          />
        </div>
        <div className="addECDefinitionRightPanel">
          <LabelsInput
            labels={ecDefinitionLabels}
            selectedLabels={labels}
            onChange={labelsChangeHandler}
            addNewLabel={addNewLabel}
          />
        </div>
      </div>
      <div className="addECDefinitionButtons">
        <Link
          href={user?.componentHelpBaseUrl + 'EDIT_CARBON_DEFINITIONS'}
          target="BLANK"
          variant={linkVariants.PRIMARY}
        >
          {i18n.t('analysis.ec.ecDefinition.components.helpText')}
        </Link>
        <ButtonsComponent
          onAddClick={addHandler}
          onCancelClick={onClose}
          isAddButtonEnabled={isAddButtonEnabled}
          isEdit={!!selectedECDefinitionToEdit}
        />
      </div>
    </div>
  );
};

export default AddECDefinition;
