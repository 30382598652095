import {DataPointType} from "../../types/metrics";
import {MaybeDrafted} from "@reduxjs/toolkit/dist/query/core/buildThunks";

const datapointTypeFieldMap = new Map<DataPointType, string>([
  [DataPointType.Metric, "metricsData"],
  [DataPointType.Factor, "factorsData"],
  [DataPointType.Benchmark, "benchmarksData"]
]);

export const removeDataPointFromCache = (parameterId: string, draft: MaybeDrafted<any>, dataType: DataPointType) => {
  const field = datapointTypeFieldMap.get(dataType);
  if(!field) return;
  const dataPointIndex = draft[field].findIndex((m) => m.id === parameterId);
  if (dataPointIndex > -1) {
    draft[field].splice(dataPointIndex, 1);
  } else {
    console.log(`${dataType} not found ${parameterId}`);
  }
}
const editMetricFunction = (body: any, draft: MaybeDrafted<any>, dataPointIndex: number) => {
  // custom metrics apply updated unit (if present) to both imperial and industry standard units
  const applyUnitFields = !draft.metricsData[dataPointIndex].isGlobal && body?.unit;
  Object.assign(draft.metricsData[dataPointIndex], {
    ...draft.metricsData[dataPointIndex],
    ...body,
    ...(applyUnitFields && {
      imperialStandardUnitId: body.unit,
      industryStandardUnitId: body.unit,
    }),
  });
}

const editFactorFunction = (body: any, draft: MaybeDrafted<any>, datapointIndex: number, data: any) => {
  Object.assign(draft.factorsData[datapointIndex], { ...body });
  draft.factorsData[datapointIndex].dataPointValue.values =
    data?.values ?? body.values ?? draft.factorsData[datapointIndex].dataPointValue.values;
}

const editBenchmarkFunction = (body: any, draft: MaybeDrafted<any>, datapointIndex: number) => {
  Object.assign(draft.benchmarksData[datapointIndex], { ...body });
  draft.benchmarksData[datapointIndex].metrics =
    body.metrics ?? draft.benchmarksData[datapointIndex].metrics;
}

export const updateDatapointFromCache = (parameterId: string, draft: MaybeDrafted<any>, dataType: DataPointType, body: any, data?: any) => {
  const field = datapointTypeFieldMap.get(dataType);
  if(!field) return;
  const dataPointIndex = draft[field].findIndex((m) => m.id === parameterId);
  if (dataPointIndex > -1) {
    switch (dataType){
      case DataPointType.Metric:
        editMetricFunction(body, draft, dataPointIndex);
        break;
      case DataPointType.Factor:
        editFactorFunction(body, draft, dataPointIndex, data);
        break;
      case DataPointType.Benchmark:
        editBenchmarkFunction(body, draft, dataPointIndex);
    }
  }
}


