import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import adpService from '../../webAnalytics/adp';
import { extractUserState } from './base-queries';

export const adpWebSdkApi = createApi({
  reducerPath: 'adpWebSdkApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
  }),
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getAdpWebSdk: builder.query<{ loaded: boolean }, void>({
      queryFn: async (_, api) => {
        const { adpSettings } = extractUserState(api);
        try {
          let loaded = await adpService.initAdpService(adpSettings);
          if (loaded) {
            adpService.recordSessionStart();
          }
          return { data: { loaded } };
        } catch (error) {
          return { error };
        }
      },
    }),
    recordSessionEnd: builder.query<void, void>({
      queryFn: async (_, api) => {       
        try {
          await adpService.recordSessionEnd();          
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const { useGetAdpWebSdkQuery, useLazyGetAdpWebSdkQuery, useLazyRecordSessionEndQuery } = adpWebSdkApi;
