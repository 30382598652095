import * as fromProjectApi from '../../project/api';
import * as fromProjectActions from '../../project/actions';
import * as fromModelApi from '../../model/api';
import * as fromModelActions from '../../model/actions';
import * as fromRunApi from '../../run/api';
import * as fromAnalisisActions from '../../analysis/actions';
import * as embodiedCarbonReducer from './reducerEcPage';
import * as fromActionTypes from './actionTypes';
import * as statusVerification from '../statusVerification';
import {
  Model,
  ProjectModel,
  ModelEmbodiedCarbonBreakdownEntity,
  MaterialEntity,
  RunModel,
  Results, EcBreakdownItem,
} from '../dashboard.models';
import {
  getEcDefinitions,
  setEcDefinitionMap,
  addECDefinitionAPI,
  editECDefinitionAPI,
  getEcResultForModelMaterialsAPI,
  deleteEcDefinitionAPI,
} from './api';
import { EcDefinition, MaterialEcDefinitionUpdate, SortedEcDefinitions } from './types';
import { Dispatch } from 'redux';
import { showNotification } from '../../shared';
import i18n from '../../i18n';
import * as locConsts from '../localization/consts';
import { NotificationType } from '../../shared/actions';
import * as fromAnalysisActions from '../../analysis/actions';
import {flattenConstructions} from "./utils";
import {dataPointsServiceApi} from "../../state/api/data-service-api";

export const initEcPageSuccess = (project: ProjectModel, model: Model) => ({
  type: fromActionTypes.INIT_EC_PAGE_SUCCESS,
  project,
  model,
});

export const initEcPageStarted = (modelId: string) => ({
  type: fromActionTypes.INIT_EC_PAGE_STARTED,
  modelId,
});

export const updateEcPageLmv = (modelId: string, lmvStatus: string, urn: string) => ({
  type: fromActionTypes.UPDATE_EC_PAGE_LMV,
  modelId,
  lmvStatus,
  urn,
});

export const loadEcPage = (modelId: string, projectId: string) => async (dispatch, getState) => {
  if (
    embodiedCarbonReducer.getIsFetchingEcPage(getState().embodiedCarbonState, modelId) ||
    embodiedCarbonReducer.getModelEcPage(getState().embodiedCarbonState, modelId)
  ) {
    return;
  }

  dispatch(initEcPageStarted(modelId));

  const project: ProjectModel = await getProject(projectId, dispatch, getState);
  const model: Model = await getModel(modelId, projectId, dispatch, getState);

  let runs: RunModel[] = await fromAnalysisActions.getRuns(modelId, dispatch, getState);
  const results: Results[] = await fromAnalysisActions.getResults(
    runs,
    project.useSIUnits,
    dispatch,
    getState
  );

  if (model && runs.length === 0) {
    await fromRunApi.runSimulation(model.modelFileId, model.id, model.projectId, model.name);
    runs = await fromAnalysisActions.getRuns(modelId, dispatch, getState);
  }

  const pendingIds = runs
    .map((d) => d.runId)
    .filter((runId) => !results.some((r) => r.runId === runId.toString()));
  if (pendingIds.length > 0) {
    fromAnalisisActions.queryResults(
      modelId,
      model.projectId,
      pendingIds,
      project.useSIUnits
    )(dispatch, getState);
  }


  dispatch(initEcPageSuccess(project, model));
};

const getModel = async (modelId: string, projectId: string, dispatch: any, getState: any) => {
  dispatch(fromModelActions.loadProjectModelsStarted(projectId));
  const models: fromModelActions.Models[] = await fromModelApi.getProjectModels(projectId);
  dispatch(fromModelActions.loadProjectModelsSuccess(models, projectId));
  return (models as any).find((a) => a.id === modelId);
};

const getProject = async (projectId: string, dispatch: any, getState: any) => {
  const project = (await fromProjectApi.getProject(projectId)) as ProjectModel;
  dispatch(fromProjectActions.updateProjectSuccess(project));
  return project;
};

export const setEcPageDataSuccess = (
  modelId: string,
  modelECBreakdown: ModelEmbodiedCarbonBreakdownEntity
) => ({
  type: fromActionTypes.SET_EC_PAGE_DATA_SUCCESS,
  data: {
    modelECBreakdown,
    modelId,
  },
});

export const loadECBreakdown =
  (modelId: string, projectId: string, waitInterval: number = 30000) =>
  async (dispatch, getState) => {
    if (statusVerification.ECDataQueries[modelId]) {
      return;
    }
    try {
      const modelECBreakdown: ModelEmbodiedCarbonBreakdownEntity | { status: number } =
        await fromModelApi.getModelECBreakdown(modelId);

      if (modelECBreakdown?.constructionItems && Object.keys(modelECBreakdown?.constructionItems).length) {
        dispatch(setEcPageDataSuccess(modelId, modelECBreakdown));
      } else {
        poolForECBreakdown(modelId, projectId, waitInterval)(dispatch);
      }
    } catch (e: any) {
      statusVerification.removeFromEcDataQueries(modelId);
      handleEcBreakdownResponse(e.error, modelId, dispatch);
    }
  };

const poolForECBreakdown =
  (modelId: string, projectId: string, waitInterval: number = 30000) =>
  (dispatch) => {
    if (statusVerification.ECDataQueries[modelId]) {
      return;
    }

    statusVerification.ECDataQueries[modelId] = {
      projectId,
      intervalId: setInterval(ECBreakdownQueryCallback, waitInterval, modelId, dispatch),
    };
  };

const ECBreakdownQueryCallback = async (modelId: string, dispatch: any, getState: any) => {
  try {
    const modelECBreakdown: ModelEmbodiedCarbonBreakdownEntity | { status: number } =
      await fromModelApi.getModelECBreakdown(modelId);

    if (modelECBreakdown?.constructionItems) {
      statusVerification.removeFromEcDataQueries(modelId);
      dispatch(setEcPageDataSuccess(modelId, modelECBreakdown));
    }
  } catch (ex: any) {
    //typescript rule ex must be of any type
    statusVerification.removeFromEcDataQueries(modelId);
    handleEcBreakdownResponse(ex.error, modelId, dispatch);
  }
};

const handleEcBreakdownResponse = (response: Response, modelId: string, dispatch: Dispatch) => {
  if (response.status === 404) {
    dispatch(setEcAnalysisError(modelId));
  }
  if (response.status === 403) {
    dispatch(setUnauthorizedAccess(modelId));
  }
};

export const updateEcPageSettingsSuccess = (
  projectId: string,
  useSiUnits: boolean,
  currencyIso: string,
  currencyRate: number,
  fuelRate: number,
  electricityRate?: number
) => ({
  type: fromActionTypes.UPDATE_EC_PAGE_SETTINGS,
  projectId,
  useSiUnits,
  currencyIso,
  currencyRate,
  fuelRate,
  electricityRate,
});

export const updateProjectSettings =
  (
    projectId: string,
    useSiUnits: boolean,
    currencyIso: string,
    fuelRate: number,
    electricityRate: number,
    electricityCeCoefficient: number,
    fuelCeCoefficient: number
  ) =>
  async (dispatch, getState) => {
    const project: ProjectModel = await dispatch(
      fromProjectActions.updateProjectSettings(
        projectId,
        useSiUnits,
        currencyIso,
        fuelRate,
        electricityRate,
        electricityCeCoefficient,
        fuelCeCoefficient
      )
    );

    if (project) {
      dispatch(
        updateEcPageSettingsSuccess(
          projectId,
          useSiUnits,
          currencyIso,
          project.currencyRate,
          fuelRate,
          electricityRate
        )
      );
    }
  };

export const setEcDefinitionsAction = (data: SortedEcDefinitions) => ({
  data,
  type: fromActionTypes.EC_DEFINITIONS_SUCCESS,
});

export const loadEcDefinitions = () => async (dispatch: Dispatch, getState: Function) => {
  if (getState().ecDefinitionsState && getState().ecDefinitionsState.length > 0) {
    return;
  }
  const ecDefinitionsResponse = await getEcDefinitions();
  dispatch(setEcDefinitionsAction(ecDefinitionsResponse));
};

export const removeModelsWithBreakdownAction = () => ({
  type: fromActionTypes.REMOVE_MODELS_WITH_BREAKDOWN,
});

export const removeModelsWithBreakdown = () => (dispatch: Dispatch) =>
  dispatch(removeModelsWithBreakdownAction());

export const setNewDefinitionAction = (data) => ({
  data,
  type: fromActionTypes.NEW_EC_DEFINITION_SUCCESS,
});

export const clearNewEDefinitionIdAction = () => ({
  type: fromActionTypes.CLEAR_NEW_EC_DEFINITION_ID,
});

export const addECDefinition =
  (newECDefinition) => async (dispatch: Dispatch, getState: Function) => {
    try {
      const result: EcDefinition[] = await addECDefinitionAPI(newECDefinition);
      if (result && result.length > 0) {
        const newEcDefinition = result[0];
        newEcDefinition.isCustom = true;
        dispatch(setNewDefinitionAction(newEcDefinition));
      }
    } catch (error) {
      dispatch(
        showNotification(i18n.t(locConsts.EC_NOTIFICATION_CHANGES_FAILURE), NotificationType.ERROR)
      );
    }
  };

export const setEditedDefinitionAction = (data) => ({
  data,
  type: fromActionTypes.EDIT_EC_DEFINITION_SUCCESS,
});

export const editECDefinition =
  (modelId: string, editECDefinition) => async (dispatch: Dispatch, getState: Function) => {
    try {
      const result: EcDefinition[] = await editECDefinitionAPI(editECDefinition);
      if (result && result.length > 0) {
        const editedEcDefinition = result[0];
        dispatch(setEditedDefinitionAction(editedEcDefinition));
        dispatch(dataPointsServiceApi.util.resetApiState());
        if (modelId) {
          await recalculateMaterialsEc(modelId, editedEcDefinition)(dispatch, getState);
        }
      }
    } catch (error) {
      dispatch(
        showNotification(i18n.t(locConsts.EC_NOTIFICATION_CHANGES_FAILURE), NotificationType.ERROR)
      );
    }
  };

export const deleteEcDefinitionAction = (ecDefinitionId: string) => ({
  type: fromActionTypes.DELETE_EC_DEFINITION_SUCCESS,
  data: { ecDefinitionId },
});

export const deleteEcDefinitionAssignmentsAction = (modelId: string, ecDefinitionId: string) => ({
  type: fromActionTypes.DELETE_EC_DEFINITION_ASSIGNMENTS,
  data: { modelId, ecDefinitionId },
});

export const removeModelDataFromState = (modelIds: string[]) => ({
  type: fromActionTypes.REMOVE_MODELS_DATA_FROM_STATE,
  data: modelIds,
});

const recalculateMaterialsEc = (modelId: string, ecDefinition) => async (dispatch, getState) => {
  const state = getState().embodiedCarbonState;
  const embodiedCarbonState = state.modelById[modelId];
  const filterMaterials = (material: MaterialEntity) =>
    material.ecDefinitionAssignment?.ecDefinition?.id === ecDefinition.id;
  if (embodiedCarbonState?.modelECBreakdown) {
    let materialIds: string[] = flattenEcBreakdownMaterialsBy(
      embodiedCarbonState?.modelECBreakdown,
      filterMaterials
    ).map((material: MaterialEntity) => material.uuid);
    const constrIds = flattenConstructions(embodiedCarbonState.modelECBreakdown)
      .filter( (c: EcBreakdownItem) => c.ecDefinitionAssignment?.ecDefinition?.id === ecDefinition.id)
      .map( (c: EcBreakdownItem) => c.uuid);
    if (constrIds.length > 0) {
      materialIds = materialIds.concat(constrIds);
    }
    if (materialIds.length > 0) {
      const updatedMaterials: MaterialEcDefinitionUpdate[] = await getEcResultForModelMaterialsAPI(
        modelId,
        materialIds
      );
      if (updatedMaterials?.length > 0) {
        dispatch(setEcDefinitionValue(modelId, ecDefinition, updatedMaterials));
      }
    }
  }
  const { [modelId]: deleted, ...withoutModelId } = state?.modelById;
  const modelsUsingEcDefinition = getModelsUsingECDefinition(
    { modelById: withoutModelId },
    ecDefinition.id
  );
  if (modelsUsingEcDefinition.length > 0) {
    dispatch(removeModelDataFromState(modelsUsingEcDefinition));
  }
};

const flattenEcBreakdownMaterialsBy = (
  ecBreakdown: ModelEmbodiedCarbonBreakdownEntity,
  filterFn: (material: MaterialEntity) => boolean
): MaterialEntity[] => {
  const allConstr = flattenConstructions(ecBreakdown);
  return allConstr.reduce((acc: MaterialEntity[], construction: EcBreakdownItem) => {
    const materials = construction.materials.filter(filterFn);
    acc = acc.concat(materials);
    return acc;
  }, []);
};

const getModelsUsingECDefinition = (ecState: any, ecDefinitionId: string): string[] => {
  return Object.keys(ecState.modelById).filter((modelId) => {
    const modelMaterials = flattenEcBreakdownMaterialsBy(
      ecState?.modelById[modelId]?.modelECBreakdown,
      (material: MaterialEntity) => material.ecDefinitionAssignment?.ecDefinition?.id === ecDefinitionId
    );
    return modelMaterials.length > 0;
  });
};

export const setEcDefinitionValue = (
  modelId: string,
  ecDefinition: EcDefinition,
  updatedMaterials: MaterialEcDefinitionUpdate[]
) => ({
  type: fromActionTypes.SET_EC_DEFINITION,
  data: { modelId, ecDefinition, updatedMaterials },
});

export const putEcDefinitionMap =
  (modelId: string, materialSha: string, ecDefinition: EcDefinition) =>
  async (dispatch: Dispatch, getState: Function) => {
    try {
      const updatedMaterials = await setEcDefinitionMap(
        modelId,
        ecDefinition.id,
        materialSha,
        ecDefinition.isCustom
      );
      dispatch(setEcDefinitionValue(modelId, ecDefinition, updatedMaterials));
      dispatch(dataPointsServiceApi.util.resetApiState());
    } catch (e) {
      dispatch(
        showNotification(
          i18n.t(locConsts.EC_NOTIFICATION_RESULTS_UPDATE_FAILURE),
          NotificationType.ERROR
        )
      );
    }
  };

export const deleteEcDefinition =
  (modelId: string, deletedEcDefinitionId: string, removeHighlightRow: () => void) =>
  async (dispatch, getState) => {
    try {
      await deleteEcDefinitionAPI([deletedEcDefinitionId]);
      removeHighlightRow();
      dispatch(deleteEcDefinitionAssignmentsAction(modelId, deletedEcDefinitionId));
      dispatch(deleteEcDefinitionAction(deletedEcDefinitionId));
      dispatch(dataPointsServiceApi.util.resetApiState());
      const state = getState().embodiedCarbonState;
      const { [modelId]: deleted, ...withoutModelId } = state?.modelById;
      const modelsUsingEcDefinition = getModelsUsingECDefinition(
        { modelById: withoutModelId },
        deletedEcDefinitionId
      );
      if (modelsUsingEcDefinition.length > 0) {
        dispatch(removeModelDataFromState(modelsUsingEcDefinition));
      }
    } catch (e) {
      dispatch(
        showNotification(i18n.t(locConsts.EC_NOTIFICATION_CHANGES_FAILURE), NotificationType.ERROR)
      );
    }
  };

const setEcAnalysisError = (data: string) => ({
  type: fromActionTypes.SET_EC_ANALYSIS_ERROR,
  data,
});

const setUnauthorizedAccess = (data: string) => ({
  type: fromActionTypes.IS_UNAUTHORIZED_ACCESS_TO_RESOURCE,
  data,
});
