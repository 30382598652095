import {MetricEvaluationResult} from "../../types/metrics";
import {useSelector} from "react-redux";
import {ApplicationDataState} from "../../state/slice/application-data-slice";
import {analyticsDataPointMap} from "../utils/dataPointValueUtils";

const doNothing = (data: any) => {};

export const useDataPointsAnalytics = (dataPointId: string): {
  isLoading: boolean;
  isError: boolean;
  data: (data: {analyticsData: any}) => void;
  isSuccess: boolean
} => {
  const modelId = useSelector(
    (state: { applicationDataState: ApplicationDataState }) =>
      state.applicationDataState.currentModelId
  );

  let sendAnalytics: (data: {analyticsData: any}) => void = doNothing;
  if (analyticsDataPointMap.hasOwnProperty(dataPointId)) {
    sendAnalytics = (
      data: {analyticsData: any}
    ) => {
      analyticsDataPointMap[dataPointId](modelId, data.analyticsData);
    }
  }


  return {
    data: sendAnalytics,
    isLoading: false,
    isError: false,
    isSuccess: true
  }
}
