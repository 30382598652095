import React from 'react';
import i18n from '../i18n';
import * as locConsts from "./localization/consts";

type createModelProps = {
  action?: any
}

const CreateModelWidget: React.FC<createModelProps> = (props) => {
  return (
    <div className="col-xs-4 col-sm-4 col-md-3 col-lg-2 widget design-option-widget" id="add_model" onClick={props.action}>
      <div className="create-btn"><span className="icon-plus"></span><label>{i18n.t(locConsts.CREATE_MODEL_ADD)}</label></div>
    </div>
  );
}

export default CreateModelWidget;
