import {
  Adp,
  AdpAnalytics,
  TrackInfo,
  UserConsentCopy,
  UserConsentPreference,
  UserConsentPreferenceInput,
} from '@adsk/adp-web-analytics-sdk';
import { AdpConfig } from '../analysis/dashboard.models';
import AuthClient from '../authClient';
import { SERVERS } from '@adsk/adp-web-analytics-sdk/lib/Common';

export interface AdpUserConsentPreferences {
  userConsentCopy: UserConsentCopy;
  userConsentPreferences: UserConsentPreference[];
}

/**
 * Represents the AdpService class that handles analytics tracking using ADP (Analytics Data Platform).
 */
class AdpService {
  adp: AdpAnalytics;
  isServiceInitialized: boolean = false;

  /**
   * Handles user consent for analytics tracking.
   */
  private userConsent = async () => {
    const token = await AuthClient.getAccessToken();
    const preferences = [];
    if (token) {
      const consentPreferences = (await this.adp.getUserConsentPreferences(
        token,
      )) as UserConsentPreference[];
      consentPreferences.forEach((c) => {
        preferences.push({
          consentId: c.consentId,
          optIn: c.optIn !== null ? c.optIn : true,
        });
      });
      await this.adp.setUserConsentPreferences(preferences, token);
    }
  };

  /**
   * Initializes the AdpService with the provided ADP configuration settings.
   *
   * @param adpSettings - The ADP configuration settings.
   * @returns A promise that resolves to a boolean indicating whether the service was successfully initialized.
   */
  public async initAdpService(adpSettings: AdpConfig): Promise<boolean> {
    try {
      this.adp = Adp();
      const {
        id,
        name,
        lineName: line_name,
        releaseId: release_id,
        buildId: build_id,
        analyticsEnv: env,
      } = adpSettings;

      await this.adp.setEnv(env as keyof typeof SERVERS);
      //  .enableDebug(); // enable debug mode for development

      await this.adp.load({
        id,
        name,
        line_name,
        release_id,
        build_id,
        id_provider: 'o2',
      });

      await this.userConsent();
      this.isServiceInitialized = true;
      return Promise.resolve(true);
    } catch (e) {
      return Promise.resolve(false);
    }
  }

  /**
   * Records a click event.
   *
   * @param id - The ID of the event.
   * @param type - The type of the event.
   * @param name - The name of the event.
   */
  public recordClickEvent(id: string, type: string, name: string) {
    if (this.isServiceInitialized) {
      this.adp.track('CLICK_COMPONENT', {
        id,
        type,
        name,
      });
    }
  }

  /**
   * Records a background API call event.
   *
   * @param id - The ID of the event.
   * @param type - The type of the event.
   * @param meta - Additional metadata for the event.
   */
  public recordBackgroundCallEvent(
    id: string,
    type: string,
    meta: Record<string, string>,
    isFailure = false,
  ) {
    if (this.isServiceInitialized) {
      this.adp.track('BACKGROUND_CALL', {
        id,
        type,
        meta,
        status: (isFailure && 'R') || 'C',
        stage: '',
      } as TrackInfo<'BACKGROUND_CALL'>);
    }
  }
  /**
   * Records values of datapoints.
   *
   * @param modelId - The ID of the model.
   * @param type - The type of the event.
   * @param meta - Additional metadata for the event i.e. value.
   */
  public recordDataPointValueEvent(modelId: string, type: string, meta: Record<string, unknown>) {
    if (this.isServiceInitialized) {
      this.adp.track('DOCUMENT_FULL', {
        id: modelId,
        type,
        meta,
      } as TrackInfo<'DOCUMENT_FULL'>);
    }
  }

  /**
   * Records a session end event.
   */
  public async recordSessionEnd() {
    if (this.isServiceInitialized) {
      this.adp.track('SESSION_END');
    }
  }

  /**
   * Records a session start event.
   */
  public recordSessionStart() {
    if (this.isServiceInitialized) {
      this.adp.track('SESSION_START');
    }
  }

  /**
   * Retrieves the user's consent preferences for analytics tracking.
   *
   * @returns A promise that resolves to the user's consent preferences or null if the service is not initialized.
   */
  public async getUserConsentPreferences(): Promise<AdpUserConsentPreferences | null> {
    if (this.isServiceInitialized) {
      const token = await AuthClient.getAccessToken();
      if (token) {
        const preferences: AdpUserConsentPreferences = {
          userConsentCopy: (await this.adp.getCurrentUserConsentCopy()) as UserConsentCopy,
          userConsentPreferences: (await this.adp.getUserConsentPreferences(
            token,
          )) as UserConsentPreference[],
        };
        return preferences;
      }
    }
  }

  /**
   * Sets the user's consent preferences for analytics tracking.
   *
   * @param preferences - The user's consent preferences.
   */
  public async setUserConsentPreferences(preferences: UserConsentPreferenceInput[]) {
    if (this.isServiceInitialized) {
      const token = await AuthClient.getAccessToken();
      if (token) {
        await this.adp.setUserConsentPreferences(preferences, token);
      }
    }
  }
}

const adpService = new AdpService();
export default adpService;
