import React, { useContext, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CardContentContext } from './base/BaseCard';
import { ModelViewData } from '../../types/Lmv';
import { getViewerToken, ViewerToken } from '../../dashboard/api';

export const LmvCard: React.FC = () => {
  const { data } = useContext(CardContentContext);
  const lmvData = data as ModelViewData;
  const containerId = useRef<string>(`lmv-container-${uuidv4()}`);
  const viewer = useRef<Autodesk.Viewing.Viewer3D>();

  useEffect(() => {
    if (lmvData) {
      startViewerProcess();
    }

    return () => {
      viewer.current?.finish();
    };
  }, []);

  const startViewerProcess = () => {
    getViewerToken()
      .then((response: ViewerToken) => {
        const { accessToken, expiresIn, viewerEnvironment } = response;
        launchViewer(accessToken, expiresIn, viewerEnvironment, lmvData.urn);
      });
  };

  const launchViewer = (
    accessToken: string,
    expiresIn: number,
    viewerEnvironment: string,
    urn: string
  ) => {
    const options = {
      env: viewerEnvironment,
      api: 'derivativeV2',
      getAccessToken: (onTokenReady) => {
        onTokenReady(accessToken, expiresIn);
      },
      loaderExtensions: { svf: 'Autodesk.MemoryLimited' },
    };
    let documentLoadSuccess = onDocumentLoadSuccess;
    let documentLoadFailure = onDocumentLoadFailure;
    if (urn !== null) {
      window.Autodesk.Viewing.Initializer(options, function onInitialized() {
        let htmlDiv = document.getElementById(containerId.current);
        if (htmlDiv) {
          if (viewer.current) {
            // todo - if viewer already exists remove exisitng event handlers
            viewer.current.finish();
          }
          viewer.current = new window.Autodesk.Viewing.GuiViewer3D(htmlDiv);
          viewer.current.start();
          window.Autodesk.Viewing.Document.load(
            'urn:' + urn,
            documentLoadSuccess,
            documentLoadFailure
          );
          return viewer.current;
        }
      });
    }
  };

  const onDocumentLoadSuccess = (viewerDocument: Autodesk.Viewing.Document) => {
    const defaultModel = viewerDocument.getRoot().getDefaultGeometry();
    viewer.current.loadDocumentNode(viewerDocument, defaultModel);
    //setting the dafault profile to aec
    const profileSettings = (Autodesk.Viewing as any).ProfileSettings.AEC;
    const profile = new (Autodesk.Viewing as any).Profile(profileSettings);
    (viewer.current as any).setProfile(profile);
    viewer.current.setTheme('light-theme');
  };

  const onDocumentLoadFailure = (
    errorCode: Autodesk.Viewing.ErrorCodes,
    errorMsg: string,
    messages: any[]
  ) => {
    console.error(errorMsg);
  };

  return (
    <div id={containerId.current} style={{ width: '100%', height: '100%', position: 'relative' }} />
  );
};
