import React, { useEffect, useState, ReactElement, useCallback } from 'react';
import Highcharts from 'highcharts/highstock.js';
import HighchartsReact from 'highcharts-react-official';
import * as CustomHighcharts from '../../typings/CustomHighchartsTypes';
import { VariwideChartOption } from './types';
import { variwideOptions } from './chartOptions';
import _ from 'lodash';

require('highcharts/modules/variwide')(Highcharts);

export type VariwideChartProps = {
    options: VariwideChartOption,
    chartRef: React.MutableRefObject<any>,
    chartKey?: string,
    selectedCategoryIndex?: number
}

type VariwideChartEvents = {
    onLoad: (chart) => void
}

const getVariwideOptions = (chartData: VariwideChartOption, chartEvents: VariwideChartEvents): CustomHighcharts.CustomOptions => {
    let options: CustomHighcharts.CustomOptions | any;
    options = _.cloneDeep(variwideOptions);
    options.chart.type = "variwide";
    options.chart.events.load = function (event) {
        chartEvents.onLoad(this);
    }
    options.xAxis.categories = chartData?.categories;
    options.xAxis.title.text = chartData.xTitle;
    options.yAxis.title.text = chartData.yTitle;
    options.tooltip = { ...options.tooltip, ...chartData?.tooltip };
    options.series[0].data = chartData?.data;
    options.responsive.rules[0].chartOptions.yAxis.title.text = chartData.yTitle;
    return options;
}

const VariwideChart: React.FC<VariwideChartProps> = (props: VariwideChartProps): ReactElement => {
    const [chartOptions, setChartOptions] = useState<CustomHighcharts.CustomOptions>(null);

    const filterCategory = useCallback((chart, chartData, selectedCategoryIndex) => {
        if (!chart) {
            return;
        }        

        chart.series.forEach((currentSeries, seriesIndex) => {
            currentSeries.data.forEach((currentData, index) => {
                if (selectedCategoryIndex !== null && selectedCategoryIndex !== -1 && index !== selectedCategoryIndex) {
                    currentData.update({
                        color: "#EEEEEE",
                        states: {
                            hover: {   
                                color: "#EEEEEE",
                                opacity: 1
                            }
                        },
                    }, false);
                } else {
                    currentData.update({
                        color: chartData[index]?.color,
                        states: {
                            hover: {                                
                                opacity: 0.2
                            }
                        },
                    }, false);
                }
            });
            chart.redraw();
        });
    }, []);

    useEffect(() => {
        setTimeout(() => {
            filterCategory(props.chartRef.current?.chart, props.options.data, props.selectedCategoryIndex);
        }, 200);
    }, [props.selectedCategoryIndex, props.options]);

    const onLoad = useCallback((chart) => {
        filterCategory(chart, props.options.data, props.selectedCategoryIndex);
    }, []);

    useEffect(() => {
        const options = getVariwideOptions(props.options, { onLoad });
        setChartOptions(options);
    }, [props.options]);

    return <HighchartsReact key={props.chartKey} highcharts={Highcharts} options={chartOptions} ref={props.chartRef} />;
}

export default VariwideChart;