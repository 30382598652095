import React, { Component } from 'react';

export class WallConstruction extends Component {

    render() {
        return <svg
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 16 16"
            style={{
                // @ts-ignore: unrecognizable type
                enableBackground: "new 0 0 16 16"
            }}
            xmlSpace="preserve"
            className="factor-modal-icon"
        >
            <style type="text/css">
                {"\n\t.st0{fill:#7C7C7C;}\n\t.st1wc{clip-path:url(#SVGIDWC_2_);}\n"}
            </style>
            <rect x={1} y={4} className="factor-modal-icon-color" width={11} height={11} />
            <g>
                <defs>
                    <polygon
                        id="SVGIDWC_1_"
                        points="5,1 5,2 5,3 13,3 13,11 13,12 14,12 15,12 15,1  "
                    />
                </defs>
                <clipPath id="SVGIDWC_2_">
                    <use
                        xlinkHref="#SVGIDWC_1_"
                        style={{
                            overflow: "visible"
                        }}
                    />
                </clipPath>
                <g className="st1wc">
                    <rect x={-442} y={-354} className="factor-modal-icon-color" width={1440} height={1024} />
                </g>
            </g>
        </svg>;
    }
}
