import { gridClasses } from "@weave-mui/data-grid";

export const overriddenTableStyle = {
  '.MuiDataGrid-row.Mui-selected': {
    backgroundColor: 'transparent !important',
  },
  '.MuiDataGrid-row.Mui-selected > div::before': {
    backgroundColor: ' transparent !important'
  },
  '.MuiDataGrid-cell::before': {
    borderBottomColor: 'rgba(60,60,60, 0.1) !important',
    borderTopColor: 'rgba(60,60,60, 0.1) !important',
  },
  '.MuiDataGrid-cell:focus::before': {
    border: '1px solid rgba(60,60,60, 0.1) !important',
    boxShadow: 'none !important'
  }
}

export const metricsTableStlesheet = {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  [`& .${gridClasses.cell}`]: {
    border: 'none !important'
  },
  '& .MuiDataGrid-columnHeaders--firstSelected': {
    border: 'none !important'
  },
  [`& .${gridClasses.row}`]: {
    border: 'none !important'
  },
  [`& .${gridClasses.root}`]: {
    border: 'none !important'
  },
  '& .MuiCustomCheckboxColumn': {
    display: 'none !important'
  },
  '& > div': {
    height: 'inherit',
    overflowY: 'auto'
  }
};
