import React, { useEffect, useRef, useState } from 'react';
import TreeItem, { treeItemClasses } from '@weave-mui/tree-item';
import RootNodeLabel from './RootNodeLabel';
import { TreeViewDataPoints } from '../dataPointsTreeView.types';
import { DataPoint, Factor, Metric } from '../../../types/metrics';
import { categoryToTreeItemProps } from '../treeViewUtils';
import { typographyClasses } from '@weave-mui/typography';
import EllipsisContent from '../../EllipsisContent';

interface CategoryTreeItemProps {
  category: keyof TreeViewDataPoints;
  index: number;
  dataPoints: DataPoint[] | Metric[] | Factor[];
  isReadOnly?: boolean;
  selectedId: string;
  disabledDataPointIds?: string[];
}

const CategoryTreeItem: React.FC<CategoryTreeItemProps> = ({
  category,
  index,
  dataPoints,
  selectedId,
  isReadOnly = false,
  disabledDataPointIds = [],
}) => {
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;
  const [shouldScroll, setShouldScroll] = useState<boolean>(false);
  const categoryColor: string = categoryToTreeItemProps[category].color;
  const treeItemRef = useRef(null);

  useEffect(() => {
    forceUpdate();
  }, [isReadOnly]);

  useEffect(() => {
    if (disabledDataPointIds?.length > 0) {
      forceUpdate();
    }
  }, [disabledDataPointIds]);

  useEffect(() => {
    if (selectedId.length > 0) {
      setShouldScroll(!!dataPoints.find((dp) => dp.id === selectedId));
    }
  }, [selectedId, dataPoints]);

  useEffect(() => {
    if (shouldScroll && treeItemRef && treeItemRef.current) {
      treeItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  }, [shouldScroll]);

  return (
    <TreeItem
      key={index}
      nodeId={`root-${index}`}
      rootNode
      guidelines
      labelText={categoryToTreeItemProps[category].name}
      disabled={isReadOnly}
      label={<RootNodeLabel category={category} categoryColor={categoryColor} />}
    >
      {dataPoints &&
        dataPoints.map((dataPoint) => (
          <TreeItem
            sx={{
              [`& .${treeItemClasses.label}`]: {
                whiteSpace: 'nowrap',
              },
              [`& .${typographyClasses.root}`]: {
                width: '100%'
              }

            }}
            key={dataPoint.id}
            nodeId={dataPoint.id}
            disabled={disabledDataPointIds.length && disabledDataPointIds.includes(dataPoint.id)}
            labelText={<EllipsisContent content={dataPoint.displayName} />}
            guidelines
            ref={dataPoint.id === selectedId ? treeItemRef : null}
          />
        ))}
    </TreeItem>
  );
};

export default CategoryTreeItem;
