export const ConstructionNameField = "constructionName";
export const DescriptionField = "description";
export const DetailLevelField = "detailLevel";
export const ThicknessField = "thickness";
export const DensityField = "density";
export const ThermalPerformanceField = "rValue";
export const AreaField = "area";
export const VolumeField = "volume";
export const MassField = "mass";
export const EcDefinitionField = "ecDefinition";
export const EcCoefficientField = "ecCoefficient";
export const EmbodiedCarbonField = "embodiedCarbon";
export const EcIntensityField = "ecIntensity";
export const EcItemCount = "ecItemCount";
