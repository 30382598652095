import React, { useRef, useEffect, useState, useCallback, ReactElement } from 'react';
import Highcharts from 'highcharts/highstock.js';
import HighchartsReact from 'highcharts-react-official';
import AnnotationsFactory from "highcharts/modules/annotations";
import { ChartEvents, StackedColumnChartOption } from './types';
import * as CustomHighcharts from '../../typings/CustomHighchartsTypes';
import { stackedColumnCharOptions } from './chartOptions';
import _ from 'lodash';

AnnotationsFactory(Highcharts);

export type StackedColumnChartProps = {
    options: StackedColumnChartOption,
    chartRef: React.MutableRefObject<any>,
    chartKey?: string,
    selectedCategoryIndex?: number,
    events?: ChartEvents,
    selectedMaterialSha?: string,
    chartWidth: number,
    yAxisMaxValue?: number
}

type StackedColumnChartEvents = {
    onSeriesClick: (event) => void,
    onLoad: (chart) => void,
    onSeriesPointMouseOver: (event) => void,
    onSeriesPointMouseOut: (event) => void,
    tooltipFormatter: (data) => string    
}

const HOVER_ANNOTATION_ID = "HOVER_ANNOTATION_ID";

const getStackedColumnOptions = (chartData: StackedColumnChartOption, chartEvents: StackedColumnChartEvents, chartWidth: number, yAxisMaxValue: number): CustomHighcharts.CustomOptions => {
    let options: CustomHighcharts.CustomOptions | any;
    let categories: number = 1;
    let minWidthPercentage = 0;
    let increment: number = 5; // Percentage increment value     

    options = _.cloneDeep(stackedColumnCharOptions);
    options.chart.type = "column";
    options.chart.events.load = function (event) {
        chartEvents.onLoad(this);
    }    
    options.xAxis.categories = chartData?.categories;
    for (var i = 1; i <= 20; i++) { // Since 5 is the percent increment value, 20 is the number of segments that can be validated.                
        if (_.inRange(chartWidth, minWidthPercentage, (i * increment))) {            
            const enableXAxisScrollbar = chartData.categories?.length > categories * i;
            options.xAxis.scrollbar.enabled = enableXAxisScrollbar;
            options.xAxis.max = enableXAxisScrollbar ? (categories * i - 1) : chartData.categories?.length - 1;
            options.xAxis.minRange = enableXAxisScrollbar ? (categories * i - 1) : chartData.categories.length - 1;            
        }
        minWidthPercentage += increment;
    }

    options.yAxis.title.text = chartData?.yTitle;
    options.yAxis.max = yAxisMaxValue * 1.05;
    options.tooltip = { ...options.tooltip, ...chartData?.tooltip };
    options.tooltip.formatter = function () { return chartEvents.tooltipFormatter(this); }
    options.series = chartData?.data;
    options.responsive.rules[0].chartOptions.yAxis.title.text = chartData.yShortTitle;
    options.plotOptions.series = {
        ...options.plotOptions.series,
        cursor: 'pointer'        
    }

    options.plotOptions.series.point = {
        ...options.plotOptions.series.point,
        events: {
            click: (event) => {
                chartEvents.onSeriesClick(event);
           }
        }
    }
    return options;
}

const StackedColumnChart: React.FC<StackedColumnChartProps> = (props: StackedColumnChartProps): ReactElement => {
    const [chartOptions, setChartOptions] = useState<CustomHighcharts.CustomOptions>(null);
    const selectedCategoryIndex = useRef<number>(props.selectedCategoryIndex);
    const selectedMaterialSha = useRef<string>(props.selectedMaterialSha);
    const xAxisLabelText = useRef<string>('');
    const xAxisLabelIndex = useRef<number>(-1);

    const onSeriesClick = (event) => {
        const clickedCategoryIndex = event.point?.index;
        if (selectedCategoryIndex.current !== clickedCategoryIndex) {
            //Trigger category click
            props.events?.onCategoryClick(event, clickedCategoryIndex);
        }
        else {
            //Trigger series click
            props.events?.onChartMaterialClick(event, event.point?.series?.userOptions?.sha, clickedCategoryIndex);
        }
    };

    const onLabelClick = (event, labelIndex) => {
        if (props.events?.onCategoryClick) {
            props.events.onCategoryClick(event, labelIndex);
        }
    };   

    const showTotalLabel = useCallback((point) => {
        const chart = props.chartRef.current.chart;
        chart.removeAnnotation(HOVER_ANNOTATION_ID);
        chart.addAnnotation({
            id: HOVER_ANNOTATION_ID,
            draggable: "",
            labelOptions: {
                backgroundColor: '#535353',
                borderColor: '#535353',
                borderRadius: 2,
                color: '#f5f5f5',
                distance: 10
            },
            crop: false,
            labels: [{
                point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: point.x,
                    y: point.stackTotal || 0
                },
                formatter: () => {
                    const labelData = {
                        x: point.category,
                        total: point.stackTotal,
                        forTotal: true
                    }
                    return props.options.tooltip.formatter.call(labelData);
                },
                useHTML: true
            }]
        });
    }, [props.options]);

    const hideTotalLabel = useCallback(() => {
        props.chartRef.current.chart.removeAnnotation(HOVER_ANNOTATION_ID);
    }, [])

    const tooltipFormatter = useCallback((data) => {
        //Show construnction tooltip if the category is not selected
        if (data.point.index !== selectedCategoryIndex.current) {
            const labelData = {
                x: data.point.category,
                total: data.point.stackTotal,
                forTotal: true
            }
            return props.options.tooltip.formatter.call(labelData);
        }
        return props.options.tooltip.formatter.call(data);
    }, [props.options]);

    const onSeriesPointMouseOver = useCallback((event) => {
        const chart = props.chartRef.current.chart;
        const hoveredCategoryIndex = event.target.index;
        const hoveredSeriesIndex = event.target.series.index;
        if (selectedCategoryIndex.current === hoveredCategoryIndex && selectedMaterialSha.current === null) {
            chart.series.forEach(function (currentSeries, seriesIndex) {
                if (seriesIndex === hoveredSeriesIndex) {
                    chart.update({
                        opacity: 1
                    }, false)
                } else {
                    currentSeries.data[hoveredCategoryIndex].update({
                        opacity: 0.2
                    }, false);
                }

            });
            chart.redraw();
        }
    }, []);

    const onSeriesPointMouseOut = (event) => {
        const chart = props.chartRef.current.chart;
        const hoveredCategoryIndex = event.target.index;

        if (selectedCategoryIndex.current == hoveredCategoryIndex && selectedMaterialSha.current === null) {
            chart.series.forEach(function (currentSeries) {
                currentSeries.data[hoveredCategoryIndex].update({
                    opacity: 1
                }, false);
            });
            chart.redraw();
        } else {
            chart.removeAnnotation(HOVER_ANNOTATION_ID);
        }
    };

    const filterCategory = useCallback((chart, selectedCategoryIndex) => {
        if (!chart) {
            return;
        }

        chart.series.forEach((currentSeries) => {          
            currentSeries.data.forEach((currentData, index) => {
                if (selectedCategoryIndex !== null && selectedCategoryIndex !== -1 && index !== selectedCategoryIndex) {
                    currentData.update({
                        color: "#EEEEEE",
                        opacity: 1
                    }, false);
                } else {
                    currentData.update({
                        color: currentSeries.color,
                        opacity: 1
                    }, false);
                }
            });
        });
        chart.redraw();
    }, []);

    const highLightSelectedMaterial = useCallback((chart, selectedCategoryIndex, selectedMaterialSha) => {
        if (!chart) {
            return;
        }
        chart.series?.forEach((currentSeries, seriesIndex) => {
            if (selectedCategoryIndex !== null && selectedCategoryIndex !== -1 && currentSeries.userOptions.sha === selectedMaterialSha) {
                chart.series[seriesIndex].points[selectedCategoryIndex]?.update({ opacity: 1 }, false);
                if (props.events?.onSeriesMouseOver) {
                    props.events.onSeriesMouseOver(seriesIndex);
                }
            }
            else {
                chart?.series[seriesIndex]?.points[selectedCategoryIndex]?.update({ opacity: .1 }, false);
            }
        });
        chart.redraw();
    }, []);

    const resetChartOpacity = useCallback((chart, selectedCategoryIndex) => {
        if (!chart) {
            return;
        }

        if (props.events?.onSeriesMouseOver) {
            props.events.onSeriesMouseOver(null);
        }
        chart.series?.forEach((currentSeries, seriesIndex) => {
            if (selectedCategoryIndex !== null && selectedCategoryIndex !== -1) {
                chart.series[seriesIndex].points[selectedCategoryIndex]?.select(false,false);
                chart.series[seriesIndex]?.points[selectedCategoryIndex]?.update({ opacity: 1 }, false);
            }
        });
        chart.redraw();
    }, []);   

    const enableXaxisLabelEvents = useCallback(() => {      
        props.chartRef.current?.chart?.xAxis[0]?.labelGroup?.element?.childNodes.forEach(function (label, labelIndex) {            

            label.onclick = function (event) {
                onLabelClick(event, xAxisLabelIndex.current);                
            }

            //Show total label on mouse hover
            label.onmouseover = function (event) {
                let index: number = labelIndex;
                let labelText: string = null;
                const labelTitle = label.getElementsByTagName('title');
                
                // In the event that the scroll bar is active, we must identify the correct index that we wish to modify.
                if (labelTitle.length > 0) {
                    labelText = labelTitle[0].textContent;                   
                    xAxisLabelText.current = labelText;                     
                    labelTitle[0].textContent = ''; // The title is removed so that it is not displayed ther default tooltip in the xaxis label hover event
                    
                } else {
                    labelText = label.innerHTML;                    
                }
                index = props.chartRef.current.chart.series[0].points.map(x => x.category).indexOf(labelText);
                index = index === -1 ? labelIndex : index;
                xAxisLabelIndex.current = index;

                label.style.textDecoration = "underline"; // underline text label

                for (const series of props.chartRef.current.chart.series) {
                    if (series.points[index].y !== null) {
                        props.chartRef.current?.chart?.xAxis[0].drawCrosshair(event, series.points[index])
                        props.chartRef.current?.chart?.tooltip.refresh(series.points[index]);
                        return;
                    }
                }

                props.chartRef.current?.chart?.xAxis[0].drawCrosshair(null, props.chartRef.current.chart.series[0].points[index])
                showTotalLabel(props.chartRef.current.chart.series[0].points[index]);
            }

            //Hide total label on mouse out
            label.onmouseout = function (event) {
                
                const labelTitle = label.getElementsByTagName('title');
                if (labelTitle.length > 0) {
                    labelTitle[0].textContent = xAxisLabelText.current;
                }

                label.style.textDecoration = ""; // remove underline text label
                props.chartRef.current?.chart?.xAxis[0].hideCrosshair(null, props.chartRef.current.chart.series[0].points[xAxisLabelIndex.current])
                props.chartRef.current?.chart?.tooltip.hide();
                hideTotalLabel();
            }
        });
    }, [onLabelClick]);

    const onLoad = useCallback((chart) => {
        filterCategory(chart, props.selectedCategoryIndex);
    }, []);

    useEffect(() => {
        selectedCategoryIndex.current = props.selectedCategoryIndex;
        selectedMaterialSha.current = props.selectedMaterialSha;
        filterCategory(props.chartRef.current?.chart, props.selectedCategoryIndex);
        if (props.selectedMaterialSha !== null) {
            highLightSelectedMaterial(props.chartRef.current?.chart, props.selectedCategoryIndex, props.selectedMaterialSha);
        }
        else {
            resetChartOpacity(props.chartRef.current?.chart, props.selectedCategoryIndex);
        }
    }, [props.selectedCategoryIndex, props.selectedMaterialSha]);

    useEffect(() => {
        if (props.chartRef.current?.chart) {
            props.chartRef.current?.chart.xAxis[0].update({
                events: {
                    afterSetExtremes: function () {
                        enableXaxisLabelEvents();
                    }
                }
            });
            enableXaxisLabelEvents();
        }

        if (props.selectedMaterialSha !== null) {
            highLightSelectedMaterial(props.chartRef.current?.chart, props.selectedCategoryIndex, props.selectedMaterialSha);
        }
        else {
            resetChartOpacity(props.chartRef.current?.chart, props.selectedCategoryIndex);
        }
    })

    useEffect(() => {
        const options = getStackedColumnOptions(props.options, { onSeriesClick, onLoad, onSeriesPointMouseOver, onSeriesPointMouseOut, tooltipFormatter }, props.chartWidth, props.yAxisMaxValue);
        setChartOptions(options);
    }, [props.options, props.chartWidth]);

    return <HighchartsReact key={props.chartKey} highcharts={Highcharts} options={chartOptions} ref={props.chartRef} />;
}

export default StackedColumnChart;
