import React, { useEffect, useState, ReactElement } from 'react';
import Highcharts from 'highcharts/highstock.js';
import HighchartsReact from 'highcharts-react-official';
import { PieChartOption } from './types';
import * as CustomHighcharts from '../../typings/CustomHighchartsTypes';
import { pieChartOptions } from './chartOptions';
import _ from 'lodash';

export type PieChartProps = {
  options: PieChartOption;
  chartRef: React.MutableRefObject<any>;
  chartKey?: string;
  selectedCategoryIndex?: number;
};

const getPieOptions = (chartData: PieChartOption): CustomHighcharts.CustomOptions => {
  let options: CustomHighcharts.CustomOptions | any;
  options = _.cloneDeep(pieChartOptions);
  options.chart.type = 'pie';
  options.tooltip = { ...options.tooltip, ...chartData?.tooltip };
  options.series[0].data = chartData?.data;
  options.plotOptions.pie.dataLabels.enabled = false;
  return options;
};

const PieChart: React.FC<PieChartProps> = (props: PieChartProps): ReactElement => {
  const [chartOptions, setChartOptions] = useState<CustomHighcharts.CustomOptions>(null);
  useEffect(() => {
    const options = getPieOptions(props.options);
    setChartOptions(options);
  }, [props.options]);

  return (
    <HighchartsReact
      key={props.chartKey}
      highcharts={Highcharts}
      options={chartOptions}
      ref={props.chartRef}
    />
  );
};

export default PieChart;
