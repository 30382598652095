import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ActionMenu } from '../common/ActionMenu';
import { TextEditor } from '../common/TextEditor';
import { Modal } from '../common/Modal';
import ModelListSelector from '../model/ModelListSelector';
import i18n from '../i18n';
import * as locConsts from "./localization/consts";
import * as ProjectType from './ProjectTypes';
import { useUpdateProjectMutation, useDeleteProjectMutation } from '../state/api/project-model-data-api';
import { useGetUserFeatureFlagDataQuery } from '../state/api/feature-flag-data-api';
import ModelUploader from '../model/ModelUploader';
import adpService from '../webAnalytics/adp';

export enum SimulationType {
  Default = "default",
  Annual = "Annual"
}

export interface ProjectWidgetProject extends Omit<ProjectType.ProjectModel, "useSIUnits" | "currencyIso"> {
  useSIUnits?: boolean,
  currencyIso?: string
}


export type ProjectWidgetProps = {
  image?: string,
  project: ProjectWidgetProject,
  projectMenuId?: number
}

const ProjectWidget: React.FC<ProjectWidgetProps> = (props) => {
  const { project, image, projectMenuId } = props;
  const [currentProject, setCurrentProject] = useState(project);
  const [isChangeNameVisible, setIsChangeNameVisible] = useState<boolean>(false);
  const [uploadPanelMode, setUploadPanelMode] = useState<string>('picture');
  const [isModelsModalVisible, setIsModelsModalVisible] = useState<boolean>(false);
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);

  // Redux Toolkit (Queries/Mutations)
  const { data: featureFlagData } = useGetUserFeatureFlagDataQuery()
  const [deleteProjectMutation] = useDeleteProjectMutation();
  const [updateProjectMutation] = useUpdateProjectMutation();  

  const showChangeName = () => {
    setIsChangeNameVisible(true);
  }

  const showPicturePanel = () => {
    setUploadPanelMode('picture');
  }

  const showUploadXmlPanel = () => {
    setUploadPanelMode('upload-xml');
  }

  const showModelsModal = () => {
    setIsModelsModalVisible(!isModelsModalVisible);
  }

  const showDialog = () => {
    setIsDialogVisible(!isDialogVisible);
  }

  const updateProjectTitle = (newValue: string) => {
    let project = { ...currentProject };
    project.name = newValue;
    updateProjectMutation(project);
    setCurrentProject(project);
    setIsChangeNameVisible(false);

    adpService.recordClickEvent(
      'project-name-change-button',
      'button',
      'name-change-button'
    );
  }

  const onCancelUploadPanel = () => {
    showPicturePanel();
  }

  const renderUploadPanel = (): JSX.Element => {

    if (uploadPanelMode === 'picture') {     
      return (<Picture image={image} projectId={currentProject.id} />);
    }
    
    if (uploadPanelMode === 'upload-xml')     
      return (
        <ModelUploader
          onCancel={onCancelUploadPanel}
          accept="text/xml"
          text={i18n.t(locConsts.PROJECT_WIDGET_SELECT)}
          projectId={id}
          showPicturePanel={showPicturePanel}
        />
      );
  }

  const deleteCurrentProject = (projectId: string) => {
    deleteProjectMutation(projectId);
    setIsDialogVisible(false)
  }
  const { isUncategorized, updatedAt, id, name } = currentProject;
  const title = name;
  const uploadGbxml = featureFlagData ? featureFlagData['upload-gbxml'] : false; 

  let menu: JSX.Element;
  let menuItems: {
    key: string,
    icon: string,
    text: string,
    action: () => void
  }[] = [];

  if (isUncategorized && uploadGbxml) {
    menuItems = [
      { key: 'runAnnual', icon: "icon-camera", text: i18n.t(locConsts.PROJECT_WIDGET_ANNUAL), action: () => showUploadXmlPanel() }
    ];
  }
  else if (!isUncategorized && uploadGbxml) {
    menuItems = [
      { key: 'edit', icon: "icon-edit", text: i18n.t(locConsts.PROJECT_WIDGET_RENAME), action: showChangeName },
      { key: 'runAnnual', icon: "icon-camera", text: i18n.t(locConsts.PROJECT_WIDGET_ANNUAL), action: () => showUploadXmlPanel() },
      { key: 'add', icon: "icon-plus", text: i18n.t(locConsts.PROJECT_WIDGET_ADD), action: showModelsModal },
      { key: 'delete', icon: "icon-trash", text: i18n.t(locConsts.PROJECT_WIDGET_DELETE), action: showDialog }
    ];
  }
  else if (!isUncategorized && !uploadGbxml) {
    menuItems = [
      { key: 'edit', icon: "icon-edit", text: i18n.t(locConsts.PROJECT_WIDGET_RENAME), action: showChangeName },
      { key: 'add', icon: "icon-plus", text: i18n.t(locConsts.PROJECT_WIDGET_ADD), action: showModelsModal },
      { key: 'delete', icon: "icon-trash", text: i18n.t(locConsts.PROJECT_WIDGET_DELETE), action: showDialog }
    ];
  }

  if (menuItems.length > 0 && projectMenuId) {
    menu = <ActionMenu menuId={projectMenuId} items={menuItems} />
  }

  return (
    <div>
      <div className="widget project-widget clearfix">
        {renderUploadPanel()}
        <div className="summary">
          {isChangeNameVisible ? <TextEditor value={title} updateValue={updateProjectTitle} /> : <Link to={{ pathname: `/projects/${id}/model` }}>{title}</Link>}
          <div className="last-update-time"><span className="text">{moment(updatedAt).format("YYYY-MM-DD")}</span></div>
          {menu}
        </div>
      </div>
      {isModelsModalVisible && <Modal hideModal={showModelsModal}><ModelListSelector hideModal={showModelsModal} projectId={id} /></Modal>}
      {isDialogVisible && <Modal isDialog={true} hideModal={showDialog} title={i18n.t(locConsts.PROJECT_WIDGET_DELETE_TITLE)} subTitle={i18n.t(locConsts.PROJECT_WIDGET_DELETE_SUBTITLE)} name={title} buttonText={i18n.t(locConsts.PROJECT_WIDGET_DELETE)} buttonAction={() => deleteCurrentProject(id)} />}
    </div>
  )
}

const Picture = (props: { image: string, projectId: string }): JSX.Element =>
  <Link to={{ pathname: `${props.projectId}/model` }}>
    <img src={props.image} alt="" />
  </Link>;


export default ProjectWidget;
