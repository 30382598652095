import React, { useCallback } from 'react';
import MenuItem from '@weave-mui/menu-item';
import TooltipMenuIcon from './TooltipMenuIcon';
import { Collapse, ListItemText } from '@mui/material';
import { ParametersTypesEnum } from '../../../../types/metrics';
import { SideNavigationOption } from './SideMenu';
import styles from '../../styles/sideNavigation.styles';

interface TooltipMenuItemProps {
  collapsed: boolean;
  navigationOption: SideNavigationOption;
  onOptionClick: (navOption: ParametersTypesEnum) => void;
}

const TooltipMenuItem: React.FC<TooltipMenuItemProps> = ({ collapsed,  navigationOption, onOptionClick }) => {
  const optionClickHandler = useCallback(() => onOptionClick(navigationOption.modalType), []);

  return (
    <MenuItem 
      key={navigationOption.title}
      onClick={optionClickHandler}
      data-testid={`menu-item-${navigationOption.modalType}`}
      sx={{
        ...styles.menuItem,
        padding: `0.5rem ${collapsed ? '0.5rem' : '2rem'} 0.5rem 0.5rem !important`,
      }}
    >
      <TooltipMenuIcon navigationOption={navigationOption}/>
      <Collapse
        in={!collapsed}
        orientation='horizontal'
        timeout={1}
        unmountOnExit
      >
        <ListItemText
          data-testid={`menu-item-text-${navigationOption.modalType}`}
        >
          {navigationOption.title}
        </ListItemText>
      </Collapse>
    </MenuItem>
  );
};

export default TooltipMenuItem;
