import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { extractUserState } from './base-queries';

export const featureFlagDataApi = createApi({
  reducerPath: 'featureFlagDataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
  }),
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getUserFeatureFlagData: builder.query<LDClient.LDFlagSet, void>({
      queryFn: async (_, api) => {
        try {
          const { autodeskId: key, name, email, launchDarklyKey } = extractUserState(api);
          const ldclient = LDClient.initialize(
            launchDarklyKey,
            { key, name, email },
            {
              allAttributesPrivate: true,
            }
          );
          await ldclient.waitForInitialization();

          // Listen for changes to any feature flag
          ldclient.on('change', (flagChanges) => {
            // flagChanges is an object where keys are flag names and values are their new values
            api.dispatch(
              featureFlagDataApi.util.updateQueryData('getUserFeatureFlagData', undefined, (draft) => {
                Object.entries(flagChanges).forEach(([flagKey, flagValue]) => {                  
                  const current = flagValue?.current;                  
                  if ((typeof current === 'object' && current !== null && Object.keys(current).length > 0) || (typeof current === 'boolean')) {                    
                    draft[flagKey] = current;
                  }
                });
              })
            );
          });

          return { data: ldclient.allFlags() };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const { useGetUserFeatureFlagDataQuery } = featureFlagDataApi;
