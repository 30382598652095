import Box from '@weave-mui/box'
import React, { useCallback, useState } from 'react'
import Badge from "@weave-design/badge";
import Typography from '@weave-mui/typography';
import Tooltip from '@weave-mui/tooltip';
import { tooltipPlacement, tooltipSize, typographyVariants } from '@weave-mui/enums';
import { categoryToTreeItemProps } from '../treeViewUtils';
import { TreeViewDataPoints } from '../dataPointsTreeView.types';
import IconButton, {iconButtonVariants} from '@weave-mui/icon-button';
import { InfoS } from '@weave-mui/icons-weave';

interface RootNodeLabelProps {
  categoryColor: string;
  category: keyof TreeViewDataPoints;
}

const { MEDIUM } = tooltipSize;
const { RIGHT } = tooltipPlacement;
const { CAPTION_BOLD, CAPTION_REGULAR, BODY_MEDIUM } = typographyVariants;
const { DYNAMIC } = iconButtonVariants;

const RootNodeLabel: React.FC<RootNodeLabelProps> = React.memo(({ categoryColor, category }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  
  const handleOnMouseEnter = useCallback(() => {
    setShowTooltip(true);
  },[]);

  const handleTooltipClose = useCallback(() => {
    setShowTooltip(false);
  },[]);

  const disableIconClick = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);
  
  return (
    <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
      <Badge 
        color={'custom'}
        customColors={{
          backgroundColor: categoryColor
        }}
      />
      <Typography variant={BODY_MEDIUM}>{categoryToTreeItemProps[category].name}</Typography>
      <Tooltip
        arrow
        enterDelay={500}
        enterNextDelay={500}
        open={showTooltip}
        placement={RIGHT}
        size={MEDIUM}
        title={<Typography variant={CAPTION_BOLD}>{categoryToTreeItemProps[category].name}</Typography>}
        description={
          <Box sx={{mt: 2}}>
            <Typography variant={CAPTION_REGULAR}>
              {categoryToTreeItemProps[category].description}
            </Typography>
          </Box>
        }
        leaveDelay={100}
        onClose={handleTooltipClose}
        PopperProps={{
          style: {
            zIndex: 10000,
            width: '230px',
          },
        }}
      >
        <IconButton
          variant={DYNAMIC}
          onMouseEnter={handleOnMouseEnter}
          onClick={disableIconClick}
        >
          <InfoS />
        </IconButton>
      </Tooltip>
    </Box>
  )
});

export default RootNodeLabel;
