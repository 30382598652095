import i18n from "../../../i18n";
import { useGetImperialSystem } from "../../../layout/hooks/useCurrentProjectData";
import { useCreateFactorsMutation, useDeleteFactorMutation, useEditDefaultFactorMutation, useEditFactorMutation } from "../../../state/api/data-service-api";
import { DataPointsNotification, Factor, PatchDefaultFactorRequest, PatchFactorRequest, RenameDataPointRequest } from "../../../types/metrics";
import { CreateFactorRequest } from "../../../types/requests/factors";
import { CreateFactorStrategyRequest, DataPointsStrategy, EditFactorStrategyRequest } from "../../types/strategy";
import { updateFactor } from "../../utils/dataPointsUtils";

export const useFactorsStrategy = (): DataPointsStrategy<CreateFactorStrategyRequest, EditFactorStrategyRequest> => {
  const [createFactor, { isLoading: isCreateFactorLoading }] = useCreateFactorsMutation();
  const [editDefaultFactor, { isLoading: isEditDefaultFactorLoading }] = useEditDefaultFactorMutation();
  const [editFactor, { isLoading: isEditFactorLoading }] = useEditFactorMutation();
  const [deleteFactor, { isLoading: isDeleteFactorLoading }] = useDeleteFactorMutation();
  const {
    data: useImperial,
    isLoading: isLoadingUnitSystem,
    isError: isUnitSystemError,
  } = useGetImperialSystem();
  
  
  const createFactorMethod = async (request: CreateFactorStrategyRequest): Promise<Factor> => {
    const {dataPoint, modelId} = request;

    const factorRequest: CreateFactorRequest = {
      dataType: dataPoint.dataType,
      description: dataPoint.description,
      name: dataPoint.name,
      values: dataPoint.dataPointValue.values,
      unit: dataPoint.unit,
      isImperial: useImperial,
    };

    try {
      const newFactorResponse = await createFactor({
        ...factorRequest,
        modelId,
      }).unwrap();

      return newFactorResponse;
    } catch (err) {
      console.log('Error in create factor strategy', err)
    }
  };
  const editFactorMethod = async (request: EditFactorStrategyRequest): Promise<Factor> => {
    const { updatedDataPoint, modelId } = request;
    if (updatedDataPoint.isGlobal) {
      const editRequest: PatchDefaultFactorRequest = {
        modelId,
        id: updatedDataPoint.id,
        values: updatedDataPoint.dataPointValue.values,
        isImperial: useImperial
      }
      try {
        const defaultFactorResponse = await editDefaultFactor(editRequest).unwrap();
        if (defaultFactorResponse.values.length > 0) {
          const updatedFactor: Factor = {
            ...updatedDataPoint,
            dataPointValue: {
              values: defaultFactorResponse.values,
            },
          };

          return updatedFactor;
        }
      } catch (err) {
        console.log('Error in edit default factor strategy', err)
      }
    } else {
      const updatedFactorRequest: PatchFactorRequest = {
        id: updatedDataPoint?.id,
        description: updatedDataPoint.description,
        displayName: updatedDataPoint.displayName,
        values: updatedDataPoint.dataPointValue.values,
        unit: updatedDataPoint.unit,
        modelId
      };
      try {
        await editFactor(updatedFactorRequest);
        const updatedFactor = updateFactor(updatedDataPoint, updatedFactorRequest);
        return updatedFactor;
      } catch (err) {
        console.log('Error in edit custom factor strategy', err)
      }
      
    }
  };

  const renameFactorMethod =  async (parameterId: string, newName: string, modelId: string): Promise<void> => {
    const renameDataPointRequest: RenameDataPointRequest = {
      id: parameterId,
      displayName: newName,
      modelId
    };

    try {
      await editFactor(renameDataPointRequest);
    } catch (err) {
      console.log('Error in rename metric strategy ', err)
    }
  }

  const deleteFactorMethod = async (parameterId: string, modelId: string): Promise<void> => {
    try {
      await deleteFactor({ parameterId, modelId });
    } catch (err) {
      console.log('Error in delete factor strategy', err)
    }
  };

  const generateFactorNotificationMethod = (isSelectAction: boolean): DataPointsNotification => {
    return {
      title: i18n.t('analysis.dataPoints.labels.changeNotificationTitle'),
      text: i18n.t('analysis.dataPoints.factors.changeNotificationText'),
      textSecondaryBn: i18n.t('analysis.dataPoints.labels.changeNotificationCloseButton'),
      textPrimaryBn: isSelectAction
          ? i18n.t('analysis.dataPoints.factors.changeNotificationExitButton')
          : i18n.t('analysis.dataPoints.factors.changeNotificationExitModalButton')
    };
  }
  
  return {
    createDataPoint: createFactorMethod,
    editDataPoint: editFactorMethod,
    deleteDataPoint: deleteFactorMethod,
    renameDataPoint: renameFactorMethod,
    generateDataPointsNotificationObject: generateFactorNotificationMethod,
    isCreateLoading: isCreateFactorLoading,
    isEditLoading: isEditDefaultFactorLoading || isEditFactorLoading,
    isDeleteLoading: isDeleteFactorLoading
  }
}