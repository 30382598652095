import {
  DELETE_EC_DEFINITION_SUCCESS,
  EC_DEFINITIONS_SUCCESS,
  EDIT_EC_DEFINITION_SUCCESS,
  NEW_EC_DEFINITION_SUCCESS,
  CLEAR_NEW_EC_DEFINITION_ID,
} from './actionTypes';
import { createReducer } from 'redux-starter-kit';
import { EcDefinition, IECDefinitionsState } from './types';

const initialState: IECDefinitionsState = {
  ecDefinitions: new Map<string, EcDefinition[]>(),
  newECDefinitionId: '',
};

const ecDefinitionsReducer = createReducer<IECDefinitionsState, any>(initialState, {
  [EC_DEFINITIONS_SUCCESS]: (state: IECDefinitionsState, action) => {
    return {
      ...state,
      ecDefinitions: action.data,
    };
  },
  [CLEAR_NEW_EC_DEFINITION_ID]: (state: IECDefinitionsState, action) => {
    return {
      ...state,
      newECDefinitionId: '',
    };
  },
  [NEW_EC_DEFINITION_SUCCESS]: (state: IECDefinitionsState, action) => {
    if (!state.ecDefinitions.hasOwnProperty(action.data.unit)) {
      return state;
    }
    return {
      ...state,
      newECDefinitionId: action.data.id,
      ecDefinitions: {
        ...state.ecDefinitions,
        [action.data.unit]: [action.data, ...state.ecDefinitions[action.data.unit]],
      },
    };
  },
  [EDIT_EC_DEFINITION_SUCCESS]: (state: IECDefinitionsState, action) => {
    const { id, unit } = action.data;
    const oldUnit = Object.keys(state.ecDefinitions).find((key) =>
      state.ecDefinitions[key].some((ecDefinition: EcDefinition) => ecDefinition.id === id)
    );

    if (oldUnit === unit) {
      const updatedEcDefinitions = state.ecDefinitions[unit].map((ecDefinition: EcDefinition) =>
        ecDefinition.id === id ? { ...ecDefinition, ...action.data } : ecDefinition
      );

      return {
        ...state,
        ecDefinitions: {
          ...state.ecDefinitions,
          [unit]: updatedEcDefinitions,
        },
      };
    } else {
      const updatedOldUnitEcDefinitions = state.ecDefinitions[oldUnit].filter(
        (ecDefinition: EcDefinition) => ecDefinition.id !== id
      );

      const updatedNewUnitEcDefinitions = [...state.ecDefinitions[unit], action.data];

      return {
        ...state,
        ecDefinitions: {
          ...state.ecDefinitions,
          [oldUnit]: updatedOldUnitEcDefinitions,
          [unit]: updatedNewUnitEcDefinitions,
        },
      };
    }
  },
  [DELETE_EC_DEFINITION_SUCCESS]: (state: IECDefinitionsState, action) => {
    const updateECDefinitions = Object.keys(state.ecDefinitions).reduce((acc, key) => {
      acc = {
        ...acc,
        [key]: [
          ...state.ecDefinitions[key].filter(
            (ecDefinition: EcDefinition) => ecDefinition.id !== action.data.ecDefinitionId
          ),
        ],
      };
      return acc;
    }, {});
    return {
      ...state,
      ecDefinitions: updateECDefinitions,
    };
  },
});

export default ecDefinitionsReducer;
