import React, { Component } from 'react';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from "highcharts-react-official";
import { getMeterOptions } from './chartOptions';


highchartsMore(Highcharts);
solidGauge(Highcharts);

export type MeterChartProps = {
    values: {
        bimValue?: number,
        meanValue: number,
        minValue: number,
        maxValue: number,
        title: string,
        meterTitle?: string,
        minTick?: number,
        maxTick?: number,
        showEcr?: boolean,
        currency?: string,
    },
    name?: string,
    selected?: boolean,
    onMeterChange?: (meterName: string) => void,
}

type ClassState = {
    options:
    Highcharts.Options |
    {
        series: ({
            data: Array<(number | null | Highcharts.PointOptionsObject)>,
            dial: {
                backgroundColor: Highcharts.ColorString | Highcharts.GradientColorObject | Highcharts.PatternObject,
            }
        } | {
            data: number[]
        })[],
        yAxis: {
            plotBands: Highcharts.YAxisPlotBandsOptions[]
        }[]
    }
}

export default class MeterChart extends Component<MeterChartProps, ClassState> {

    constructor(props: MeterChartProps) {
        super(props);
        this.state = { options: null };
    }

    componentDidMount() {
        this.setOptions();
    }

    componentDidUpdate(prevProps: MeterChartProps, prevState: ClassState) {
        if (prevProps.values.meanValue !== this.props.values.meanValue) {

            const plotBandsObj = this.getPlotBands();

            this.setState({
                options: {
                    series: [{
                        data: this.props.values.meanValue > 0 ? [this.getRadialValueFromMax(this.props.values.meanValue)] : [],
                        dial: {
                            backgroundColor: plotBandsObj[0].color
                        }
                    },
                    {
                        data: this.props.values.bimValue > 0 ? [this.getRadialValueFromMax(this.props.values.bimValue)] : []
                    }],
                    yAxis: [{
                        plotBands: plotBandsObj
                    }]
                }
            });
        }
    }

    getPlotBands = (): Highcharts.YAxisPlotBandsOptions[] => {

        const radialMeanValue = this.getRadialValueFromMax(this.props.values.meanValue);
        const radialMinTick = this.getRadialValueFromMax(this.props.values.minTick);
        const radialMaxTick = this.getRadialValueFromMax(this.props.values.maxTick);
        const colorRange = this.getColorRange(radialMeanValue);

        const plotBands: Highcharts.YAxisPlotBandsOptions[] = [
            //red band
            {
                from: -90,
                to: radialMeanValue,
                color: colorRange,
                borderColor: colorRange,
                borderWidth: 1,
                innerRadius: '100%',
                outerRadius: '70%',
                className: 'meter-red-plot-band'
            },
            //white band
            {
                from: radialMeanValue,
                to: 90,
                color: 'white',
                borderColor: 'rgba(60, 60, 60, 0.2)',
                borderWidth: 1,
                innerRadius: '100%',
                outerRadius: '70%',
                className: 'meter-white-plot-band'
            }
            //plot band for benchmark
            //{
            //    from: -90,
            //    to: -54,
            //    color: '#c6c6c6',
            //    borderColor: 'black',
            //    borderWidth: 0,
            //    innerRadius: '102%',
            //    outerRadius: '108%'
            //},
        ];

        //create ticks
        const ticks: Highcharts.YAxisPlotBandsOptions[] = [];
        const minTick = radialMinTick > -90 ? radialMinTick : -90;
        const maxTick = radialMaxTick < 90 ? radialMaxTick : 90;

        for (var i = minTick; i <= maxTick; i += 8) {
            ticks.push({
                from: i,
                to: i + 2,
                color: i < radialMeanValue ? '#FFFFFF' : '#C4C4C4',
                borderColor: 'white',
                borderWidth: 0,
                innerRadius: '80%',
                outerRadius: '90%'
            });
        }

        if (ticks.length > 0) {
            plotBands.push(...ticks);
        }

        return plotBands;
    }

    setOptions = () => {

        const euiValue = this.getRadialValueFromMax(this.props.values.meanValue);
        const bimValue = this.getRadialValueFromMax(this.props.values.bimValue);
        const meterTitle = this.props.values.meterTitle;

        this.setState({
            options: getMeterOptions(this.getPlotBands(), euiValue, bimValue, meterTitle)
        });
    }

    //calculate the value position 
    getRadialValueFromMax = (value: number): number => {

        const { minValue, maxValue } = this.props.values;

        //calculate the value for each point 
        const diffMaxMin = (maxValue - minValue);

        let pointValue = 0;

        if (diffMaxMin > 0)
            pointValue = 180 / diffMaxMin;

        //calculate difference to base value (min)
        const diffToMin = value - minValue;

        //calculate how much will sum to chart base value (-90)
        return -90 + (diffToMin * pointValue);
    }

    getColorRange = (radialValue: number): string => {
        let color: string;

        if (radialValue <= -30)
            color = '#87B340';
        else if (radialValue > -30 && radialValue <= 30)
            color = '#FAA21B';
        else
            color = '#EC4A41';

        return color;
    }

    onMeterClicked = () => {
        this.props.onMeterChange(this.props.name);
    }

    render(): JSX.Element {

        const { minValue, maxValue, meanValue, title, currency, showEcr } = this.props.values;
        const containerClassName = `meter-container ${this.props.selected ? 'selected' : ''}`;


        return <div className={containerClassName} onClick={this.onMeterClicked}>
            <div className="meter-chart-container">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state.options}
                    //Code Smell - Might need to remove or update this
                    //this.chartRef = React.createRef(); create reference first componentDidMount() {this.chartRef = React.createRef();}
                    // @ts-ignore: unrecognizable type
                    ref="chartComponent1"
                />
                <div className="meter-min-max-labels">
                    <span>{currency} {minValue}</span>
                    <span>{currency} {maxValue}</span>
                </div>
            </div>
            <div className="meter-values-container">
                <div>
                    <span className="meter-value">{meanValue !== 0 ? meanValue : '-'}</span>
                </div>
                <div>
                    <span className="meter-value-label">{title}</span>
                </div>
            </div>
        </div>;
    }

}
