import {createApi} from '@reduxjs/toolkit/query/react';
import {BaseQuery, QueryType, QueryOptions} from './base-queries';
import {
  Benchmark,
  DataPoints,
  DataPointType,
  Factor,
  ISpec,
  PatchBenchmarkRequest,
  PatchDefaultFactorRequest,
  PatchFactorRequest,
  PatchMetricRequest,
  PostBenchmarkRequest,
  PostFactorRequest,
  PostMetricRequest
} from '../../types/metrics';
import {DELETE, PATCH, POST} from './api-constants';
import {generateBenchmarkFromRequest} from '../../dataPoints/utils/dataPointsUtils';
import i18n from '../../i18n';
import {CreateMetricResponse} from '../../types/responses/parameters';
import {CreateFactorResponse, OverrideFactorResponse, toFactor} from '../../types/responses/factors';
import {CreateBenchmarkResponse} from '../../types/responses/benchmarks';
import {removeDataPointFromCache, updateDatapointFromCache} from "../../dataPoints/utils/dataServiceApiUtils";
import {benchmarkAnalytic} from "../../utils/analytics";
import {CreateBenchmarkRequest} from "../../types/requests/benchmarks";

const EVENT_TYPE_FACTOR = 'factor';
const EVENT_TYPE_METRIC = 'metric';
const EVENT_TYPE_BENCHMARK = 'benchmark';
export const AnalyzeInProgress = 'Processing';

export const dataPointsServiceApi = createApi({
  reducerPath: 'dataPointsApi',
  baseQuery: BaseQuery(QueryType.DataService),
  keepUnusedDataFor: 3600,
  tagTypes: ['DataPoints'],
  endpoints: (builder) => ({
    getDataPoints: builder.query<DataPoints & { status?: string }, string>({
      query: (modelId) => `/v1/parameters/dataset/${modelId}`,
      transformResponse: (response: DataPoints, meta, arg): DataPoints & { status?: string } => {
        if (meta.response.status === 202) {
          return { ...response, status: AnalyzeInProgress };
        }
        return response;
      },
      providesTags: ['DataPoints'],
      extraOptions: {
        errorOptions: {
          notificationType: 'none',
          messageOverride: i18n.t('appError.errorDataModalMessage'),
        },
        analyticsOptions: {
          eventType: EVENT_TYPE_METRIC,
          eventName: 'getDataPoints',
        },
      } as QueryOptions,
    }),
    createMetrics: builder.mutation<CreateMetricResponse, PostMetricRequest>({
      query: ({ modelId: _, ...body }: PostMetricRequest) => ({
        url: `/v1/parameters`,
        method: POST,
        body,
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: {
          eventType: EVENT_TYPE_METRIC,
          eventName: 'createMetrics',
        },
      } as QueryOptions,
      async onQueryStarted(
        { modelId, ...body },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          const query = await queryFulfilled;
          const { id} = query.data;
          dispatch(
            dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
              draft.metricsData.push({
                ...body,
                id,
                description: body.description ?? "",
                displayName: body.name,
                type: DataPointType.Metric,
                isGlobal: false,
                imperialStandardUnitId: body.unit,
                industryStandardUnitId: body.unit,
              });
            })
          );
        } catch (e) {
          console.log(`Error updating`);
        }
      },
    }),
    editMetric: builder.mutation<void, PatchMetricRequest>({
      query: (patch: PatchMetricRequest) => {
        const { modelId: _, ...payload } = patch;
        return {
          url: `/v1/parameters`,
          method: PATCH,
          body: { ...payload },
        };
      },
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_METRIC, eventName: 'editMetric' },
      } as QueryOptions,
      async onQueryStarted(
        { modelId, ...body },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          await queryFulfilled;
          dispatch(
            dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
              updateDatapointFromCache(body.id, draft, DataPointType.Metric, body);
            })
          );
        } catch (e) {
          console.log(`Error updating`);
        }
      }
    }),
    deleteMetric: builder.mutation<void, { parameterId: string; modelId: string }>({
      query: ({ parameterId }: { parameterId: string; modelId: string }) => ({
        url: `/v1/parameters`,
        method: DELETE,
        body: {
          id: parameterId,
        },
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_METRIC, eventName: 'deleteMetric' },
      } as QueryOptions,
      async onQueryStarted(
        { parameterId, modelId },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          await queryFulfilled;
          dispatch(
            dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) =>
              removeDataPointFromCache(parameterId, draft, DataPointType.Metric))
          );
        } catch (e) {
          console.log(`Error updating`);
        }
      },
    }),
  }),
});
export const dataServiceUnitsApi = createApi({
  reducerPath: 'dataUnitsApi',
  baseQuery: BaseQuery(QueryType.DataService),
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getUnits: builder.query<Record<string, ISpec>, void>({
      query: () => `/v1/units`,
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('notifications.errorMessage'),
        },
      } as QueryOptions,
    }),
  }),
});

export const dataServiceBenchmarksApi = createApi({
  reducerPath: 'dataBenchmarksApi',
  baseQuery: BaseQuery(QueryType.DataService),
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    createBenchmark: builder.mutation<Benchmark, PostBenchmarkRequest>({
      query: ({modelId, ...body}: PostBenchmarkRequest) => ({
        url: `/v1/benchmarks`,
        method: POST,
        body
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: {
          eventType: EVENT_TYPE_BENCHMARK,
          eventName: 'createBenchmark',
          parseRequestMeta: (body: CreateBenchmarkRequest) => {
            return benchmarkAnalytic(body);
          }
      }
      } as QueryOptions,
      transformResponse(res: CreateBenchmarkResponse, meta, arg: PostBenchmarkRequest): Benchmark {
        return generateBenchmarkFromRequest(res.data, arg);
      },
      async onQueryStarted({modelId, ...body}, { dispatch, getState, queryFulfilled }): Promise<void> {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
              draft.benchmarksData.push({
                id: data.id,
                type: DataPointType.Benchmark,
                displayName: body.name,
                ...body,});
            })
          );
        } catch (e) {
          console.log(`Error adding benchmark to cache`);
        }
      }
    }),
    editBenchmark: builder.mutation<void, PatchBenchmarkRequest>({
      query: (patch: PatchBenchmarkRequest) => {
        //remove the modelId
        const { modelId: _, ...payload } = patch;
        return {
          url: `/v1/benchmarks`,
          method: PATCH,
          body: { ...payload },
        };
      },
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_BENCHMARK, eventName: 'editBenchmark' },
      } as QueryOptions,
      async onQueryStarted(
        { modelId, ...body },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          await queryFulfilled;
          dispatch(
            dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
              updateDatapointFromCache(body.id, draft, DataPointType.Benchmark, body);
            })
          );
        } catch (e) {
          console.log(`Error updating benchmark cache`);
        }
      },
    }),
    deleteBenchmark: builder.mutation<void, { parameterId: string; modelId: string }>({
      query: ({ parameterId }: { parameterId: string; modelId: string }) => ({
        url: `/v1/benchmarks`,
        method: DELETE,
        body: {
          id: parameterId,
        },
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_BENCHMARK, eventName: 'deleteBenchmark' },
      } as QueryOptions,
      async onQueryStarted(
        { parameterId, modelId },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          await queryFulfilled;
          dispatch(
            dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) =>
              removeDataPointFromCache(parameterId, draft, DataPointType.Benchmark)
            )
          );
        } catch (e) {
          console.log(`Error deleting`);
        }
      },
    }),
  })
});

export const dataServiceFactorsApi = createApi({
  reducerPath: 'dataFactorsApi',
  baseQuery: BaseQuery(QueryType.DataService),
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    createFactors: builder.mutation<Factor, PostFactorRequest>({
      query: ({ modelId: _, ...body }: PostFactorRequest) => ({
        url: `/v1/factors`,
        method: POST,
        body,
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_FACTOR, eventName: 'createFactors' },
      } as QueryOptions,
      transformResponse(response: CreateFactorResponse, meta, arg): Factor {
        return toFactor(response);
      },
      async onQueryStarted(
        { modelId, ...body },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
              draft.factorsData.push(data);
            })
          );
        } catch (e) {
          console.log(`Error adding factor to cache`);
        }
      },
    }),
    editDefaultFactor: builder.mutation<OverrideFactorResponse, PatchDefaultFactorRequest>({
      query: ({ modelId: _, ...body }: PatchDefaultFactorRequest) => ({
        url: '/v1/factors:override-default-values',
        method: POST,
        body,
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_FACTOR, eventName: 'editDefaultFactor' },
      } as QueryOptions,
      async onQueryStarted(
        { modelId, ...body },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          const { data }: { data: OverrideFactorResponse } = await queryFulfilled;
          dispatch(
            dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
              updateDatapointFromCache(body.id, draft, DataPointType.Factor, body, data);
            })
          );
        } catch (e) {
          console.log('Error editing default factor', e);
        }
      },
    }),
    editFactor: builder.mutation<void, PatchFactorRequest>({
      query: (patch: PatchFactorRequest) => {
        //remove the modelId
        const { modelId: _, ...payload } = patch;
        return {
          url: `/v1/factors`,
          method: PATCH,
          body: { ...payload },
        };
      },
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_FACTOR, eventName: 'editFactor' },
      } as QueryOptions,
      async onQueryStarted(
        { modelId, ...body },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          await queryFulfilled;
          dispatch(
            dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) => {
              updateDatapointFromCache(body.id, draft, DataPointType.Factor, body);
            })
          );
        } catch (e) {
          console.log(`Error updating factors cache`);
        }
      },
    }),
    deleteFactor: builder.mutation<void, { parameterId: string; modelId: string }>({
      query: ({ parameterId }: { parameterId: string; modelId: string }) => ({
        url: `/v1/factors`,
        method: DELETE,
        body: {
          id: parameterId,
        },
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDatapoint'),
        },
        analyticsOptions: { eventType: EVENT_TYPE_FACTOR, eventName: 'deleteFactor' },
      } as QueryOptions,
      async onQueryStarted(
        { parameterId, modelId },
        { dispatch, getState, queryFulfilled }
      ): Promise<void> {
        try {
          await queryFulfilled;
          dispatch(
            dataPointsServiceApi.util.updateQueryData('getDataPoints', modelId, (draft) =>
              removeDataPointFromCache(parameterId, draft, DataPointType.Factor)
            )
          );
        } catch (e) {
          console.log(`Error deleting`);
        }
      },
    }),
  }),
});

export const {
  useGetDataPointsQuery,
  useCreateMetricsMutation,
  useEditMetricMutation,
  useDeleteMetricMutation,
} = dataPointsServiceApi;

export const { useGetUnitsQuery } = dataServiceUnitsApi;

export const {
  useCreateFactorsMutation,
  useEditFactorMutation,
  useDeleteFactorMutation,
  useEditDefaultFactorMutation,
} = dataServiceFactorsApi;

export const {useCreateBenchmarkMutation, useDeleteBenchmarkMutation , useEditBenchmarkMutation} = dataServiceBenchmarksApi;
