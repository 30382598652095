import React, { createContext, useState } from "react";

const DataGridContext = createContext(
  {
    isOpenQuickModal: false,
    setIsOpenQuickModal: (isOpenQuickModal: boolean) => {}
  }
);

const DataGridContextProvider = ({ children }) => {

  const [isOpenQuickModal, setIsOpenQuickModal]= useState<boolean>(false);  

  return (
    <DataGridContext.Provider value={{isOpenQuickModal, setIsOpenQuickModal}}>
      {children}
    </DataGridContext.Provider>
  );
}

export { DataGridContext };
export default DataGridContextProvider;